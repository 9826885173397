import './index.scss'
import { ContractOutputModel } from '../../../../../../models/Ui/Contract'
import { ContractActionTypes } from '../../../../CustomerDetail'
import ContractInfoCart from './ContractInfoCart'

interface NewProductDetailsProps {
    contract: ContractOutputModel
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ContractActionTypes, data?: ContractOutputModel) => void
    onError: (error: any) => void
    handleEdit: () => void
}

function NewContractDetails(props: NewProductDetailsProps) {
    // const deleteProductImageHandler = async (imageUrl: string) => {
    //     try {
    //         props.onLoading()
    //         const contract: UpdateProductModel = props.contract
    //         const newImages = contract.images && contract?.images.filter((image) => image.imageUrl !== imageUrl)
    //         const response = await ContractService.update({ images: newImages }, props.product.id)
    //         props.onSuccess(ProductActionTypes.DELETE_IMAGE, response)
    //     } catch (error: any) {
    //         props.onError(error)
    //     } finally {
    //         props.onCompleted()
    //     }
    // }
    //
    // const updateProductImageHandler = async (imageUrl: string, isFeatured: boolean) => {
    //     try {
    //         props.onLoading()
    //         const images: ProductImageModel[] = props.product.images ? props.product.images : []
    //         const newImages = images.map((image: ProductImageModel) => {
    //             if (isFeatured) {
    //                 if (image.imageUrl === imageUrl) return { ...image, isFeatured }
    //                 else return { ...image, isFeatured: !isFeatured }
    //             }
    //             return { ...image, isFeatured: false }
    //         })
    //         const response = await ProductService.update({ images: newImages }, props.product.id)
    //         props.onSuccess(ProductActionTypes.DELETE_IMAGE, response)
    //     } catch (error: any) {
    //         props.onError(error)
    //     } finally {
    //         props.onCompleted()
    //     }
    // }

    // const createProductImageHandler = async (fileList: FileList) => {
    //     try {
    //         props.onLoading()
    //         const uploadedFiles = await uploadFileServices.uploadMultiple(fileList)
    //         const newProductImages: ProductImageModel[] = uploadedFiles.map((file) => ({
    //             imageUrl: file.url,
    //             name: file.name,
    //             isFeatured: false,
    //         }))
    //         const response = await ProductService.update(
    //             {
    //                 images: [...(props.product.images ?? []), ...newProductImages],
    //             },
    //             props.product.id
    //         )
    //         props.onSuccess(ProductActionTypes.ADD_IMAGE, response)
    //     } catch (error: any) {
    //         props.onError(error)
    //     } finally {
    //         props.onCompleted()
    //     }
    // }
    return (
        <div className="product-details-tab-container">
            <>
                <div className="product-info-cart">
                    <ContractInfoCart handleEdit={props.handleEdit} contract={props.contract} />
                </div>

                <div className={'contract-details-below'}>
                    <div className="contract-description">
                        <div className="contract-description-wrapper">
                            <div className={props.contract.type ? `header header--has-description` : `header`} style={{ flexDirection: props.contract.type ? 'row' : 'column' }}>
                                <span className="title">Type</span>
                                <div className="add-button" onClick={props.handleEdit}>
                                    {!props.contract.type && '+update'}
                                </div>
                            </div>
                            <span className="description">{props.contract.type}</span>
                        </div>
                    </div>

                    <div className="contract-description">
                        <div className="contract-description-wrapper">
                            <div className={props.contract.description ? `header header--has-description` : `header`} style={{ flexDirection: props.contract.description ? 'row' : 'column' }}>
                                <span className="title">Description</span>
                                <div className="add-button" onClick={props.handleEdit}>
                                    {!props.contract.description && '+update'}
                                </div>
                            </div>
                            <span className="description">{props.contract.description}</span>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default NewContractDetails
