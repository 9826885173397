import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Size } from '../../models/enums'
import Button, { ButtonType } from '../Button'
import './ThreeDotButton.scss'

export interface ActionModel {
    text: string
    onClick: () => void
    permissions?: string[]
    disabled?: boolean
}

interface Props {
    actions: ActionModel[]
}

function ThreeDotButton(props: React.PropsWithChildren<Props>) {
    const [visibleAccountMenu, setVisibleAccountMenu] = useState(false)
    const buttonWrapperRef = useRef<any>(null)

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (buttonWrapperRef.current && !buttonWrapperRef.current.contains(event.target)) {
                setVisibleAccountMenu(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('click', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    window.onclick = (event: any) => {
        const className = event.target.className
        if (typeof className === 'string' && !className.includes(className)) setVisibleAccountMenu(false)
    }
    let className: string = 'dropdown-inner-button'

    const itemHandleClick = (action: () => void) => {
        setVisibleAccountMenu(false)
        action()
    }
    return (
        <div className="dropdown-button" ref={buttonWrapperRef}>
            <Button className={className} type={ButtonType.Primary} size={Size.md} onClick={() => setVisibleAccountMenu(!visibleAccountMenu)}>
                {props.children}
                <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
            {visibleAccountMenu && (
                <div className="dropdown-button-transparent-container">
                    <div className="dropdown-button-content">
                        {props.actions.map((action, index) => {
                            return (
                                <div className={`anchor ${action.disabled && 'disabled'}`} key={index} onClick={() => itemHandleClick(action.onClick)}>
                                    {action.text}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ThreeDotButton
