import Storage from '../../utils/Storage'
import { AxiosResponse } from 'axios'
import AuthService from '../../services/UiService/Auth'

export interface TokenResponseInterceptorOptions {
    document: any
    tokenKey: string
    eventBus: any
    originalRequestTryCount?: number
}

export default class TokenResponseInterceptor {
    private storage: Storage
    private options: TokenResponseInterceptorOptions

    constructor(options: TokenResponseInterceptorOptions) {
        this.options = options
        this.storage = new Storage(options.document)
    }

    private logOut(): any {
        const token = this.storage.getCookie(this.options.tokenKey || 'token')

        if (token) {
            new AuthService().logout()
            window.location.reload()
        }
    }

    onFulfilled(conf: AxiosResponse): AxiosResponse {
        return conf
    }

    async onRejected(error: any): Promise<any> {
        const response = error.response
        if (response.status === 401) {
            this.logOut()
            // const originalRequest = error.config
            // const threshold = this.options.originalRequestTryCount || 3
            // originalRequest.tryCount = originalRequest.tryCount || 1
            //
            // if (originalRequest.tryCount <= threshold) {
            //     const token = this.storage.getCookie(this.options.tokenKey || 'token')
            //     originalRequest._retry = true
            //     originalRequest.headers['authorization'] = 'Bearer ' + token
            //
            //     return Promise.reject(error)
            // } else {
            //     this.logOut()
            // }
        } else {
            return Promise.reject(error)
        }
    }
}
