import React, { useEffect, useRef, useState } from 'react'
import './PageWrapper.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Icons, { IconType } from '../../Icon'
import { Breadcrumb, BreadcrumbModel } from '../../components/Breadcrumb'
import Cart from '../../components/Cart/Cart'
import NotificationPopup from './NotificationPopup'
import AuthService from '../../services/UiService/Auth'
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { useContext } from 'react'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'
import { ICartInformation, TechnicianViewType } from '../../store/models'
import { showActiveMenuItem } from '../../utils/helpers'

interface Props {
    breadcrumb?: BreadcrumbModel[]
    name: string
    headerInfo?: string
    onLogout: () => void
    onSetting: () => void
}

function ManufacturerPageWrapper(props: React.PropsWithChildren<Props>) {
    const [visibleAccountSwitcher, setVisibleAccountSwitcher] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [visibleAccountMenu, setVisibleAccountMenu] = useState(false)
    const accountWrapperRef = useRef<any>(null)
    const auth = new AuthService().getAuthUser()
    const checkoutContext = useContext(CheckoutContext)
    const location = useLocation()

    useEffect(() => {
        function changeVisibilityAccountSwitcherHandler(event: any) {
            if (event.ctrlKey && event.altKey && event.key.toLowerCase() === 'l') {
                setVisibleAccountSwitcher(!visibleAccountSwitcher)
            } /* else not needed */
        }

        function handleClickOutside(event: any) {
            if (accountWrapperRef.current && !accountWrapperRef.current.contains(event.target)) {
                setVisibleAccountMenu(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('click', handleClickOutside)
        document.addEventListener('keydown', changeVisibilityAccountSwitcherHandler)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('keydown', changeVisibilityAccountSwitcherHandler)
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('click', handleClickOutside)
        }
    }, [visibleAccountSwitcher])

    window.onclick = (event: any) => {
        const className = event.target.className
        if (typeof className === 'string' && !className.includes(className)) setVisibleAccountMenu(false)
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />)(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#354a63',
            color: 'white',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
            borderRadius: '8px',
            lineHeight: 'normal',
        },
        [`.MuiTooltip-arrow::before`]: {
            backgroundColor: '#354a63',
            left: '55px',
        },
    }))

    const handleCart = () => {
        const url = window.location.pathname
        if ((url && url.includes('/customer/')) || url.includes('custom-equipment/') || url.includes('/quick')) {
            switch (checkoutContext.cartInformation.technicianViewType) {
                case TechnicianViewType.PurchaseAs:
                    checkoutContext.toggleCartView()
                    break

                case TechnicianViewType.Quick:
                    checkoutContext.toggleQuickCartView()
                    break

                default:
                    break
            }
        } else {
            checkoutContext.toggleCartView()
        }
    }

    return (
        <>
            <div className="page-header">
                <div className="header-left">
                    <Link to="/">
                        {props.headerInfo && <h1>{props.headerInfo}</h1>}
                        <div className="left-bottom">
                            {props.headerInfo && <span className="powered">POWERED BY</span>}
                            <img
                                className={props.headerInfo ? 'logo-mini' : 'logo-margin'}
                                height={props.headerInfo ? '' : 24}
                                src={props.headerInfo ? '/mini-logo.png' : '/logo.png'}
                                alt="monittor"
                            />
                            {/* <img className='logo-margin' height={24} src={"/logo.png"} alt="monittor" /> */}
                        </div>
                    </Link>
                </div>
                <div className="header-right">
                    <div className="right-user-info">
                        <span className="company-name">{auth.company.name}</span>
                        <span className="user-name">{`${auth.firstName} ${auth.lastName}`}</span>
                    </div>
                    <div className="divider" />
                    <NavLink className={(e) => `${e.isActive ? 'active' : ''}`} to="/quick">
                        <Tooltip title="Search">
                            <div className="header-button">
                                <Icons className="icon search" type={IconType.Search} />
                            </div>
                        </Tooltip>
                    </NavLink>
                    <div className="right-notification">
                        <NotificationPopup />
                    </div>
                    {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && (
                        <div className="cart-button-container" onClick={() => handleCart()}>
                            <Tooltip title="Ongoing order">
                                <div className="header-button">
                                    <Icons className="icon" type={IconType.Cart} />
                                </div>
                            </Tooltip>
                            {checkoutContext.getCount() > 0 && <div className="order-counter">{checkoutContext.getCount()}</div>}
                        </div>
                    )}
                </div>
            </div>
            <div className="page-breadcrumb">{props.breadcrumb && <Breadcrumb items={props.breadcrumb} />}</div>
            <div className="page-content">
                <div style={{ width: checkoutContext.cartInformation.show ? '75%' : '100%' }} className="page-main animation">
                    <div className="bottom">
                        <div className="side-bar-container">
                            <div className="side-bar">
                                <div className="side-bar-top">
                                    <NavLink className={(e) => `${e.isActive ? 'buttons active' : 'buttons'}`} to="/">
                                        <HtmlTooltip title="Activities">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.Activities} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>

                                    <NavLink className={(e) => `buttons ${showActiveMenuItem(location, 'customer') && 'active'}`} to="/customers">
                                        <HtmlTooltip title="Customers">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.UserFriends} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>

                                    <NavLink className={(e) => `${showActiveMenuItem(location, 'equipment') ? 'buttons active' : 'buttons'}`} to="/equipments">
                                        <HtmlTooltip title="Equipment">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.Equipment} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>

                                    <NavLink className={(e) => `${showActiveMenuItem(location, 'product') ? 'buttons active' : 'buttons'}`} to="/products">
                                        <HtmlTooltip title="Catalog">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.Flask} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>

                                    <NavLink className={(e) => `${showActiveMenuItem(location, 'contract') ? 'buttons active' : 'buttons'}`} to="/contracts">
                                        <HtmlTooltip title="Contracts">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.Contract} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>

                                    <NavLink className={(e) => `${showActiveMenuItem(location, 'document') ? 'buttons active' : 'buttons'}`} to="/documents">
                                        <HtmlTooltip title="Documents">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.Folder} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>

                                    <NavLink className={(e) => `${showActiveMenuItem(location, 'order') ? 'buttons active' : 'buttons'}`} to="/orders">
                                        <HtmlTooltip title="Transactions">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.OrderBag} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>
                                </div>
                                <div className="side-bar-bottom">
                                    <span className="divider"></span>

                                    <NavLink
                                        onClick={async () => {
                                            await checkoutContext.handleCartInformation({
                                                technicianViewType: TechnicianViewType.Quick,
                                                quickCartShow: true,
                                                purchaseForCustomerId: checkoutContext.quickCartInformation.id,
                                                purchaseForCustomerName: checkoutContext.quickCartInformation.name,
                                            } as ICartInformation)
                                        }}
                                        className={(e) => `quick ${showActiveMenuItem(location, 'quick') ? 'buttons active' : 'buttons'}`}
                                        to="/quick"
                                    >
                                        <HtmlTooltip title="Quick Cart">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.AddToCart} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>

                                    <a className="buttons" target="_blank" href="https://direct.lc.chat/14467245/" rel="noreferrer">
                                        <HtmlTooltip title="Monittor Tech Support">
                                            <div className="side-bar-button-wrapper">
                                                <Icons className="icon" type={IconType.Support} />
                                            </div>
                                        </HtmlTooltip>
                                    </a>
                                    <NavLink className={(e) => `${showActiveMenuItem(location, 'setting') ? 'buttons active' : 'buttons'}`} to="/settings">
                                        <HtmlTooltip title="Settings">
                                            <div className="side-bar-button-wrapper">
                                                <FontAwesomeIcon className="icon" icon={faCog} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>
                                    <NavLink onClick={props.onLogout} className={(e) => `${e.isActive ? 'buttons active' : 'buttons'}`} to="/login">
                                        <HtmlTooltip title="Log out">
                                            <div className="side-bar-button-wrapper">
                                                <FontAwesomeIcon className="icon" icon={faSignOutAlt} />
                                            </div>
                                        </HtmlTooltip>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className={`children ${checkoutContext.cartInformation.show && 'show'}`}>{props.children}</div>
                    </div>
                </div>
                <div style={{ width: checkoutContext.cartInformation.show ? '25%' : 0 }} className="cart-content-container animation">
                    <div className="cart-content-wrapper">
                        <Cart />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManufacturerPageWrapper
