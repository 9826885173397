/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef } from 'react'
import './PageWrapper.scss'
import { useNavigate } from 'react-router-dom'
import CustomerPageWrapper from './Customer'
import ManufacturerPageWrapper from './Manufacturer'
import { useMobileHook } from '../../hooks/useMobileHook'
import MobilePageWrapper from './Mobile'
import { BreadcrumbModel } from '../../components/Breadcrumb'
import AuthService from '../../services/UiService/Auth'
import { CompanyType } from '../../models/Ui/CompanyType'
import { SocketContext } from '../../store/SocketContext'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'
import { useTableBodyEmptyCellSetter } from '../../hooks/useTableHook'

interface Props {
    className?: string
    showViewCartButton?: boolean
    breadcrumb?: BreadcrumbModel[]
    headerInfo?: string
}

function PageWrapper(props: React.PropsWithChildren<Props>) {
    const { resetSocket } = useContext(SocketContext)
    const pageWrapperRef = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()
    const { isMobile } = useMobileHook()
    const auth = new AuthService().getAuthUser()
    const checkoutContext = useContext(CheckoutContext)

    const onLogout = async () => {
        await checkoutContext.clearAll()
        new AuthService().logout()
        navigate('/login')
        resetSocket()
    }

    useTableBodyEmptyCellSetter(pageWrapperRef)

    if (auth) {
        if (!isMobile) {
            return (
                <div className={props.className ? props.className + ' page-wrapper' : 'page-wrapper'} ref={pageWrapperRef}>
                    {auth.company.type === CompanyType.Customer ? (
                        <>
                            <div className="customer-page-wrapper-space"></div>
                            <CustomerPageWrapper breadcrumb={props.breadcrumb} headerInfo={props.headerInfo} onLogout={onLogout} onSetting={() => navigate('/settings')} name={auth.company.name}>
                                {props.children}
                            </CustomerPageWrapper>
                        </>
                    ) : auth.company.type === CompanyType.Manufacturer ? (
                        <>
                            <div className="page-wrapper-space"></div>
                            <ManufacturerPageWrapper breadcrumb={props.breadcrumb} name={auth.company.name} headerInfo={props.headerInfo} onLogout={onLogout} onSetting={() => navigate('/settings')}>
                                {props.children}
                            </ManufacturerPageWrapper>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )
        } else
            return (
                <MobilePageWrapper className={props.className} type={auth.company.type} onLogout={onLogout}>
                    {props.children}
                </MobilePageWrapper>
            )
    } else {
        return <></>
    }
}

export default PageWrapper
