import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEventHandler, ReactElement, useEffect, useRef, useState } from 'react'
import Icons, { IconType } from '../../Icon'
import { Size } from '../../models/enums'
import Checkbox from '../Checkbox'
import PopoverItem from '../PopoverItem'
import './CheckBoxDropdownInput.scss'

interface Props {
    selectedItems: string[]
    listItems: any[]
    className?: string
    wrapperClass?: string
    size?: Size
    placeholder?: string
    type?: string
    labelName?: string
    required?: boolean
    disabled?: boolean
    outlinePlaceholder?: boolean
    startIcon?: IconDefinition
    dropIcon?: boolean
    startIconColor?: string
    endIcon?: IconDefinition
    endIconColor?: string
    inputLabel?: string
    customInputLabel?: string
    showCustomInputLabelWhenEmpty?: boolean
    customIcon?: ReactElement
    isContentAbsolute?: boolean
    showAllCheckbox?: boolean
    onDataChange?: (selectedItem: string[], allChecked?: boolean) => void
    onBlur?: ChangeEventHandler<HTMLInputElement>
    onFocus?: ChangeEventHandler<HTMLInputElement>
}

function CheckBoxDropdownInput({ showCustomInputLabelWhenEmpty = false, showAllCheckbox = false, outlinePlaceholder = true, ...props }: React.PropsWithChildren<Props>) {
    if (props.listItems == null) {
        throw new Error('List items must be provided!')
    } /* else not needed */

    const [showContent, setShowContent] = useState<boolean>(false)
    const [selectedIds, setSelectedIds] = useState([] as string[])
    const wrapperRef = useRef<any>(null)
    const [searchInput, setSearchInput] = useState<string>('')
    const [allChecked, setAllChecked] = useState(false)

    useEffect(() => {
        setAllChecked(selectedIds.length === props.listItems.length)
    }, [selectedIds.length, props.listItems.length])

    const onChangeAll = () => {
        if (allChecked) {
            setSelectedIds([])
            setAllChecked(false)
        } else {
            setSelectedIds(props.listItems.map((child) => child.id))
            setAllChecked(true)
        }
    }

    let defaultClassName: string = 'input input-' + (props.size ? props.size : Size.lg)
    let hasIconClass: string = props.startIcon ? ' start-input-icon' : ''
    hasIconClass += props.endIcon ? ' end-input-icon' : ''

    const onChangeCheckbox = async (id: string) => {
        const index = selectedIds.indexOf(id)
        if (index > -1) {
            await setSelectedIds(selectedIds.filter((p) => p !== id))
        } else {
            await setSelectedIds([...selectedIds, id])
        }
    }
    const handleDone = () => {
        props.onDataChange!(selectedIds, allChecked)
        setShowContent(false)
        setSearchInput('')
    }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowContent(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    useEffect(() => {
        setSelectedIds(props.selectedItems)
    }, [props.selectedItems, showContent])

    const setInputValue = () => {
        let output = showCustomInputLabelWhenEmpty ? props.customInputLabel : ''
        if (showContent) {
            output = searchInput
        } else if (selectedIds.length > 0) {
            output = props.customInputLabel || `${selectedIds.length} ${props.inputLabel ? props.inputLabel : 'Record(s) Selected'}`
        }
        return output
    }

    return (
        <div className="block-dropdown-input" ref={wrapperRef}>
            <div className="block-dropdown-input-container">
                <div className="input-container">
                    <div className={props.wrapperClass ? props.wrapperClass + hasIconClass + ' input-wrapper' : 'input-wrapper' + hasIconClass}>
                        <input
                            disabled={props.disabled}
                            required={props.required}
                            type={props.type ? props.type : 'text'}
                            value={setInputValue()}
                            //itemID={selectedItem.id}
                            className={props.wrapperClass ? props.wrapperClass + ' ' + defaultClassName : defaultClassName}
                            onBlur={props.onBlur}
                            onFocus={(event: React.ChangeEvent<HTMLInputElement>) => {
                                props.onFocus && props.onFocus(event)
                                setShowContent(true)
                            }}
                            onChange={(e) => setSearchInput(e.target.value)}
                            placeholder={!outlinePlaceholder ? props.placeholder : ' '}
                        />
                        {props.dropIcon && (
                            <div className="down-icon">
                                <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0L8 8L16 0H0Z" fill="#767676" />
                                </svg>
                            </div>
                        )}
                        {outlinePlaceholder && <span className="label">{props.placeholder}</span>}
                        {props.endIcon && (
                            <span className="end-icon">
                                <FontAwesomeIcon icon={props.endIcon} color={props.endIconColor} />
                            </span>
                        )}
                    </div>
                    {showContent && (
                        <div onClick={() => handleDone()} className={`check-button ${props.selectedItems === selectedIds && 'disabled'}`}>
                            <PopoverItem popoverContent="Done">
                                <Icons type={IconType.Check} />
                            </PopoverItem>
                        </div>
                    )}
                </div>
                <div className="dropdown-input-transparent-container">
                    <div
                        className="dropdown-input-content"
                        style={{
                            display: showContent ? 'block' : 'none',
                            position: props.isContentAbsolute === undefined || props.isContentAbsolute ? 'absolute' : undefined,
                        }}
                    >
                        {showAllCheckbox && (
                            <div onClick={onChangeAll} className="item-content">
                                <Checkbox onChange={onChangeAll} checked={allChecked} />
                                <span>All</span>
                            </div>
                        )}
                        {props.listItems
                            .filter((item: any) => item.text.toLowerCase().includes(searchInput.toLowerCase()))
                            .map((item: any, index: number) => {
                                if (item.id === -1) return undefined
                                return (
                                    <div
                                        key={index}
                                        itemID={item.id}
                                        onClick={() => {
                                            onChangeCheckbox(item.id)
                                        }}
                                        className="item-content"
                                    >
                                        <Checkbox onChange={() => onChangeCheckbox(item.id)} checked={selectedIds.includes(item.id)} />
                                        {Object.keys(item).indexOf('serialNo') ? (
                                            <div className="item-content__item-group">
                                                <span>{item[props.labelName || 'text']}</span>
                                                <span className="serial-number">{item['serialNo'] || '__'}</span>
                                            </div>
                                        ) : (
                                            <span>{item[props.labelName || 'text']}</span>
                                        )}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckBoxDropdownInput
