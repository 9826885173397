import { useState, useContext, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icons, { IconType } from '../../../../Icon'
import { arraySort, isAbbreviation } from '../../../../utils/helpers'
import './style.scss'
import Button from '../../../../components/Button'
import { priceFormatter } from '../../../../utils/helpers'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import { TechnicianViewType } from '../../../../store/models'
import { ContractOutputModel } from '../../../../models/Ui/Contract'
import { ignoreDashClassname } from '../../../../hooks/useTableHook'
import PopoverItem from '../../../../components/PopoverItem'
import { useNavigate } from 'react-router'
import { Switch } from '@mui/material'
import moment from 'moment'

interface Data {
    title: string
    status: string
    startDate: Date | null

    endDate: Date | null

    totalPrice: string

    isVisibleToCustomer: boolean

    actions: string
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    data: ContractOutputModel[]
    setSearch: (key: string) => void

    createAction: () => void

    updateVisible: (id: string, visible: boolean) => void
}

const columns: HeadCell[] = [
    {
        id: 'title',
        label: 'Title',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'startDate',
        label: 'Start Date',
    },
    {
        id: 'endDate',
        label: 'End Date',
    },
    {
        id: 'totalPrice',
        label: 'Price',
    },
    {
        id: 'actions',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}

interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: React.Dispatch<React.SetStateAction<boolean>>
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const checkoutContext = useContext(CheckoutContext)
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'actions'}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection((prevState) => !prevState)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
                {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && <TableCell />}
            </TableRow>
        </TableHead>
    )
}

export default function ContractsTable(props: Props) {
    const [orderBy, setOrderBy] = useState('title')
    const [direction, setDirection] = useState(true)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const navigate = useNavigate()
    const [dataForSorting, setDataForSorting] = useState<ContractOutputModel[]>([])

    const getStatusBadge = (contract: ContractOutputModel) => {
        let contractStatus = 'Offer'

        if (contract.startDate) {
            const today = new Date().getTime()

            const from = new Date(contract.startDate).getTime()
            const to = new Date(contract.endDate!).getTime()

            if ((today >= from && today <= to) || today < from) {
                contractStatus = 'Active'
            } else if (today > to) {
                contractStatus = 'Expired'
            }
        }

        return [contractStatus, <div className={'status-badge badge-' + contractStatus.toLowerCase()}>{contractStatus}</div>]
    }

    useEffect(() => {
        setDataForSorting(props.data.map((child) => ({ ...child, status: getStatusBadge(child)[0] as string, totalPrice: child.totalPrice ? Number(child.totalPrice) : child.totalPrice })))
    }, [props.data])

    useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    if (props.data.length === 0)
        return (
            <div className="no-data">
                <span>You currently do not have any contracts for this customer yet!</span>
                <Button onClick={props.createAction} className="add-button">
                    <Icons type={IconType.BoldPlus} /> Add New
                </Button>
            </div>
        )

    return (
        <>
            <TableContainer className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {arraySort(dataForSorting, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: ContractOutputModel, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell className="font-bold" style={{ width: '40%' }} align="left">
                                            <span className="ellipsis-text">{row.title}</span>
                                            {isAbbreviation(row.title.length) && <div className="table-hover">{row.title}</div>}
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }} align="left">
                                            {getStatusBadge(row)[1]}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} align="left">
                                            {row.startDate ? moment(row.startDate).format('ll') : ''}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} align="left">
                                            {row.endDate ? moment(row.endDate).format('ll') : ''}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} align="left">
                                            {priceFormatter(row.totalPrice || '-')}
                                        </TableCell>
                                        <TableCell className={ignoreDashClassname} style={{ width: '8%', paddingLeft: 0 }} onClick={() => navigate('/contracts/' + row.id)}>
                                            <div className={'contracts-table-actions'}>
                                                <PopoverItem popoverContent={!row.isVisibleToCustomer ? 'Hidden from customer' : 'Show to customer'}>
                                                    <Switch
                                                        disabled={false}
                                                        onClick={(e) => e.stopPropagation()}
                                                        checked={row.isVisibleToCustomer}
                                                        onChange={(e) => {
                                                            props.updateVisible(row.id, e.target.checked)
                                                        }}
                                                        // inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </PopoverItem>
                                                <span className="action-cell">
                                                    <PopoverItem popoverContent="View Details">
                                                        <Icons type={IconType.ChevronRight} />
                                                    </PopoverItem>
                                                </span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
