import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { CreateDocumentModel, DeleteDocumentModel, PushPullEquipmentDocumentModel, UpdateDocumentModel } from '../../models/Ui/Document'
import { DocumentListModel } from '../../models/New/Document'
import { PaginationInputModel, PaginationResponseModel } from '../../models/BaseModel'

class DocumentService {
    async createDocument(data: CreateDocumentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateDocument, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async updateDocument(data: UpdateDocumentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.patch(`${ServiceFunctionLookupTable.UpdateDocument}/${data.documentId}`, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async deleteDocument(data: DeleteDocumentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(`${ServiceFunctionLookupTable.DeleteDocument}/${data.documentId}`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async updateEquipmentsDocument(data: PushPullEquipmentDocumentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.UpdateEquipmentsDocument, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async getDocuments(params: PaginationInputModel): Promise<PaginationResponseModel<DocumentListModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetDocuments, { params })
            return response as any
        } catch (error: any) {
            return error
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DocumentService()
