import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router'
import Button from '../../../../../../components/Button'
import { EquipmentModel } from '../../../../../../models/New/Equipment'
import './EquipmentCart.scss'

interface Props {
    equipmentInfo: EquipmentModel
}

function EquipmentCart(props: Props) {
    const navigate = useNavigate()

    const warrantyInfo = (warrantyEnds?: string) => {
        let warranty: ReactNode
        if (!warrantyEnds) {
            warranty = (
                <>
                    <div className="manufacturer-name">{props.equipmentInfo?.manufacturerName}</div>
                    <div className="info">{props.equipmentInfo?.category}</div>
                    <div className="info">{props.equipmentInfo?.brand}</div>
                </>
            )
        } else if (new Date(warrantyEnds).getTime() > new Date().getTime()) {
            warranty = 'Active Warranty'
        } else {
            warranty = 'Warranty Expired'
        }
        return warranty as ReactNode
    }

    return (
        <div className="equipment-cart-container">
            {props.equipmentInfo.warrantyEnds || props.equipmentInfo.serialNo ? (
                <>
                    <div className="equipment-info">
                        <span className="name">{props.equipmentInfo?.name}</span>
                        <span className="serial-number">{props.equipmentInfo.serialNo}</span>
                    </div>
                    {props.equipmentInfo.warrantyEnds && (
                        <div className="warranty-info">
                            <span
                                className={`warranty ${
                                    warrantyInfo(props.equipmentInfo.warrantyEnds) === 'Active Warranty'
                                        ? 'active'
                                        : warrantyInfo(props.equipmentInfo.warrantyEnds) === 'Warranty Expired'
                                        ? 'expired'
                                        : 'equipment-info-container'
                                }`}
                            >
                                {warrantyInfo(props.equipmentInfo.warrantyEnds)}
                            </span>
                            {props.equipmentInfo.warrantyEnds && <span className="exp">Exp.: {dayjs(props.equipmentInfo.warrantyEnds).format('MM/DD/YYYY')}</span>}
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="equipment-info">
                        <span className="name">{props.equipmentInfo?.name}</span>
                        <span className="serial-number">{props.equipmentInfo.serialNo}</span>
                    </div>
                    <div className="equipment-detail">
                        <span className="equipment-detail__manufacturer">{props.equipmentInfo.manufacturer?.name}</span>
                        <span className="equipment-detail__secondary">{props.equipmentInfo.brand}</span>
                        <span className="equipment-detail__secondary">{props.equipmentInfo.partNumber}</span>
                    </div>
                </>
            )}
            <Button onClick={() => navigate(`/equipment/${props.equipmentInfo.id}?activeTab=2`)}>View Drawing / BOM</Button>
        </div>
    )
}

export default EquipmentCart
