import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { CreatePartModel, GetPartDto } from '../../models/Ui/Part'

class PartListService {
    async delete(id: string): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeletePartList + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }
    async create(partListId: string, data: CreatePartModel): Promise<GetPartDto> {
        try {
            const response = await MonitorApi.post('/partList/' + partListId + '/add-part', data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async update(partListId: string, name: string): Promise<GetPartDto> {
        try {
            const response = await MonitorApi.patch('/partList/' + partListId, { name: name }, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new PartListService()
