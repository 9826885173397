import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import {
    AuthModel,
    AuthUserModel,
    MailVerificationOutputModel,
    ResetPasswordInputModel,
    ResetPasswordOutputModel,
    SignupInputModel,
    UpdatePasswordModel,
    UserVerificationModel,
} from '../../models/Ui/Auth'
import { CommonResponse } from '../../models/Ui/Common'
import { CompanyModel } from '../../models/Ui/Company'
import TokenService from '../NewServices/TokenService'

export default class AuthService {
    async login(email: string, password: string): Promise<AuthModel> {
        try {
            const authResponse = await MonitorApi.post(ServiceFunctionLookupTable.Login, { email: email, password: password }, {})
            TokenService.setAuth(authResponse as unknown as AuthModel)
            return authResponse as any
        } catch (error: any) {
            console.error(error.response)
            return Promise.reject(error.response.data)
        }
    }

    async signUp(input: SignupInputModel): Promise<CompanyModel> {
        try {
            return (await MonitorApi.post(ServiceFunctionLookupTable.SignUp, input, {})) as unknown as CompanyModel
        } catch (error: any) {
            return Promise.reject(error.response)
        }
    }

    async forgotPassword(email: string): Promise<ResetPasswordOutputModel> {
        try {
            return (await MonitorApi.get(`${ServiceFunctionLookupTable.ForgotPassword}/${email}`, {})) as unknown as ResetPasswordOutputModel
        } catch (error: any) {
            throw error
        }
    }

    async resetPassword(input: ResetPasswordInputModel): Promise<ResetPasswordOutputModel> {
        try {
            return (await MonitorApi.put(ServiceFunctionLookupTable.ResetPassword, input, {})) as unknown as ResetPasswordOutputModel
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async verifyMail(key: string): Promise<MailVerificationOutputModel> {
        try {
            const data = (await MonitorApi.post(ServiceFunctionLookupTable.MailVerification, { key }, {})) as unknown as MailVerificationOutputModel
            return data
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async loginWithToken(token: string) {
        try {
            TokenService.clearAuth()
            TokenService.setToken(token)
            const user: AuthUserModel = (await MonitorApi.get(ServiceFunctionLookupTable.User, {})) as any as AuthUserModel
            TokenService.setUser(user)
            return 'authResponse'
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async updatePassword(data: UpdatePasswordModel): Promise<CommonResponse> {
        try {
            const authResponse = await MonitorApi.put(ServiceFunctionLookupTable.ChangePassword, data, {})
            return authResponse as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async userVerification(data: UserVerificationModel): Promise<CommonResponse> {
        try {
            const userVerificationResponse = await MonitorApi.post(ServiceFunctionLookupTable.UserVerification, data, {})
            return userVerificationResponse as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    logout() {
        TokenService.clearAuth()
        localStorage.clear()
    }

    getAuthUser() {
        return TokenService.getAuth()
    }

    isAuthenticated() {
        return TokenService.isAuth()
    }
}
