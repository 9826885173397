import { Checkbox } from '@mui/material'
import { FC } from 'react'
import Icons, { IconType } from '../../../../../../Icon'
import { ActivityTypes } from '../../../../../../models/Ui/Activity'
import './style.scss'

interface Props {
    type: ActivityTypes | null
    onChangeType: (value: ActivityTypes | null) => void
    isBillable: boolean
    onChangeBillable: (value: boolean) => void
}

const ActivityType: FC<Props> = ({ type, onChangeType, isBillable, onChangeBillable }) => {
    return (
        <div className="activity-type-container">
            <div className="types">
                <div
                    onClick={() => {
                        onChangeType(ActivityTypes.Service)
                        onChangeBillable(false)
                    }}
                    className={`activity-type service-type ${type === ActivityTypes.Service && 'active'}`}
                >
                    <Icons type={IconType.Maintenance} />
                    Service
                </div>
                <div
                    onClick={() => {
                        onChangeType(ActivityTypes.ProductReplensh)
                        onChangeBillable(false)
                    }}
                    className={`activity-type replenishment-type ${type === ActivityTypes.ProductReplensh && 'active'}`}
                >
                    <Icons type={IconType.Replenishment} />
                    Product Replenishment
                </div>
                <div
                    onClick={() => {
                        onChangeType(ActivityTypes.Ticket)
                        onChangeBillable(false)
                    }}
                    className={`activity-type ticket-type ${type === ActivityTypes.Ticket && 'active'}`}
                >
                    <Icons type={IconType.Ticket} />
                    Ticket
                </div>
            </div>
            {type === ActivityTypes.Service && (
                <div className="billable">
                    <Checkbox checked={isBillable} onChange={() => onChangeBillable(!isBillable)} />
                    Billable
                </div>
            )}
        </div>
    )
}

export default ActivityType
