import React, { useContext } from 'react'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'
import { TechnicianViewType } from '../../store/models'

interface Props {
    children: JSX.Element
}

const ViewAsCustomer: React.FC<Props> = ({ children }) => {
    const { cartInformation } = useContext(CheckoutContext)
    return cartInformation.technicianViewType !== TechnicianViewType.ViewAs ? children : null
}

export default ViewAsCustomer
