import React, { useEffect, useState } from 'react'
import { ActivityModel } from '../../../../../../models/Ui/Activity'
import { Tabs } from '../../../../../../models/Ui/Tab'
import { arraySort, standardDate } from '../../../../../../utils/helpers'
import './Card.scss'

interface IProps {
    activities: ActivityModel[]
    handleSeeAll: (id: Tabs) => void
    handleCreateActivity: () => void
}

function NextActivity(props: IProps) {
    const { activities, handleSeeAll, handleCreateActivity } = props
    const [filteredActivities, setFilteredActivities] = useState<ActivityModel[]>([])

    useEffect(() => {
        setFilteredActivities(
            arraySort(
                activities.filter((activity) => new Date(activity.dueDate) > new Date()),
                'dueDate'
            ).slice(0, 4)
        )
    }, [activities])

    return (
        <div className="overview-card">
            <div className="item">
                <div className="item-header">
                    <span className="title">Next Due</span>
                    {activities.length > 0 && (
                        <div onClick={() => handleSeeAll(Tabs.Activities)} className="action-button">
                            See All
                        </div>
                    )}
                </div>
                {filteredActivities.length > 0 ? (
                    filteredActivities.map((activity) => {
                        return (
                            <div className="line">
                                <span className="date">{standardDate(activity.dueDate)}</span>
                                <span className="value">{activity.title}</span>
                            </div>
                        )
                    })
                ) : (
                    <>
                        <span className="value">You currently do not have any activities. Start by adding one now!</span>
                        <div onClick={handleCreateActivity} className="action-button center">
                            +add new activity
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default NextActivity
