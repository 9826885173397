import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import './OrderPdf.scss'
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { ManufacturerSummaryModel } from '../../models/Ui/Manufacturer'
import { priceFormatter } from '../../utils/helpers'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'

export default function OrderPdf() {
    let { id } = useParams()

    const { getGroupedItemsByManufacturer, contextEquipments, contextProducts, contextCustomParts } = useContext(CheckoutContext)

    const [manufacturer, setManufacturer] = useState<ManufacturerSummaryModel>()
    const groupedItems = getGroupedItemsByManufacturer()
    const userInfo = JSON.parse(localStorage?.auth)
    const { address, billingAddress } = JSON.parse(localStorage?.checkout)
    // @ts-ignore
    const items = groupedItems[id] || { products: [], equipments: [], customParts: [] }

    const { user } = userInfo

    const fileName = user.name + '_via_Monittor_' + dayjs().format('YYYYMMDD')

    useEffect(() => {
        async function getManufacturer() {
            setManufacturer(manufacturer)
        }

        getManufacturer().then(() => {
            printDocument(fileName)
        })
    }, [fileName, id, manufacturer])

    const getTotalPrice = () => {
        // @ts-ignore
        const currentEqTotalPrice = contextEquipments.map((e) => {
            // @ts-ignore
            return e.parts.reduce((acc, item) => {
                let p: number | string = 0

                if (item?.part?.price) p = item.part.price

                if (p === '-') p = 0

                return acc + +p * item.count
            }, 0)
        })

        // const currentCustomTotalPrice = contextCustomParts.reduce((acc, item) => {
        //     return acc + (item?.price || 0) * parseInt(item.qty);
        // }, 0)
        // @ts-ignore
        const currentProductTotalPrice = contextProducts.reduce((acc, p) => {
            // @ts-ignore
            return acc + (p.product.price || 0) * p.count
        }, 0)
        // @ts-ignore
        const sumAllProduct =
            currentEqTotalPrice.reduce((acc: any, item: any) => {
                return acc + item
            }, 0) + currentProductTotalPrice

        return sumAllProduct.toFixed(2)
    }

    const printDocument = (fileName = '') => {
        const input: any = document.getElementById('divToPrint')
        input.style.zoom = '1'
        html2canvas(input).then((canvas: any) => {
            const imgData = canvas.toDataURL('image/png')
            input.style.zoom = '0.5'
            const pdf = new jsPDF({
                format: 'a4',
            })
            // @ts-ignore
            // pdf.page=1;
            // pdf.setFontSize(8)
            const imgProps = pdf.getImageProperties(imgData)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
            // function footer(){
            //     // @ts-ignore
            //     pdf.text(150,285, 'page ' + pdf.page);
            //     // @ts-ignore
            //     pdf.page ++;
            // };
            // footer();
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
            pdf.addImage('/assets/images/pdf-logo.png', 'PNG', 10, 280, 50, 5)
            pdf.save(`${fileName}.pdf`)
        })
    }

    function OrderProductRowItem(products: any) {
        return products.map((item: any, index: number) => {
            return (
                <div className="item-container" key={index}>
                    <div className="section">
                        <span className="section-title">{item.product.name}</span>
                        <span className="total-price">{priceFormatter(item.count * item.product.price)}</span>
                    </div>

                    <div className="row">
                        <div className="column">
                            <div className="section">
                                <div>
                                    <span className="gray-text">Product category: </span>
                                    <span className="gray-text">{item.product.category} </span>
                                    <span className="gray-text">• </span>
                                    <span className="gray-text">Brand: </span>
                                    <span className="gray-text">{item.product.brand + ' / ' + item.product.manufacturer.name} </span>
                                </div>
                                <span className="gray-text">Unit price: {priceFormatter(item.product.price)}</span>
                            </div>
                            <div>
                                <span className="gray-text"> Part number: </span>
                                <span className="gray-text">{item.product.partNo}</span>
                                <span className="gray-text"> • </span>
                                <span className="gray-text"> Qty.: </span>
                                <span className="gray-text">{item.count}</span>
                            </div>
                            <span className="gray-text"> Unit of measure: </span>
                            <span className="gray-text">{item.product.uom || '-'} </span>
                            <span className="gray-text">•</span>
                            <span className="gray-text"> Packaging: </span>
                            <span className="gray-text">{item.product.pkg || '-'} </span>
                            <span className="gray-text">•</span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    function OrderEquipmentItem(equipments: any) {
        return equipments.map((equipmentItem: any) => {
            return equipmentItem.parts.map((item: any, index: number) => {
                return (
                    <div className="item-container" key={index}>
                        <div className="section">
                            <span className="section-title">{item.part.description}</span>
                            <span className="total-price">{priceFormatter(item.count * item.part.price)}</span>
                        </div>

                        <div className="row">
                            <div className="column">
                                <div className="section">
                                    <div>
                                        <span className="gray-text"> Part number: </span>
                                        <span className="gray-text">{item.part.part_no}</span>
                                        <span className="gray-text"> • </span>
                                        <span className="gray-text"> Qty.: </span>
                                        <span className="gray-text">{item.part.qty}</span>
                                    </div>
                                    <span className="gray-text">Unit price: {priceFormatter(item.part.price)}</span>
                                </div>
                                <div>
                                    <span className="gray-text">Equipment name:</span>
                                    <span className="gray-text">{equipmentItem.equipment.name}</span>
                                    <span className="gray-text"> • </span>
                                    <span className="gray-text"> Serial Number: </span>
                                    <span className="gray-text">{equipmentItem.equipment?.serialNumber || '-'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        })
    }

    const renderAddress = () => {
        return (
            <>
                {address.site_address && (
                    <div className="column">
                        <div className="title">Vendor</div>
                        <div className="content">{manufacturer?.name}</div>
                        <div className="content">{manufacturer?.email}</div>
                    </div>
                )}
                {billingAddress.site_address && (
                    <div className="column">
                        <div className="title">Shipping Address:</div>
                        <div className="content">
                            {address.site_address}, {address.city}, {address.state}, {address.postal_code}
                        </div>
                        <div className="title">Billing Address:</div>
                        <div className="content">
                            {billingAddress.site_address}, {billingAddress.city}, {billingAddress.state}, {billingAddress.postal_code}
                        </div>
                    </div>
                )}
            </>
        )
    }

    function OrderRfqItem(additionalParts: any) {
        return additionalParts.map((item: any, index: number) => {
            return (
                <div className="item-container" key={index}>
                    <div className="section">
                        <span className="section-title">{item.description}</span>
                        <span className="total-price">{item.price ? '$' + parseInt(String(item.price)).toFixed(2) : '-'}</span>
                    </div>

                    <div className="row">
                        <div className="column">
                            <div className="section">
                                <div>
                                    <span className="gray-text"> Part number: </span>
                                    <span className="gray-text">{item.part_no}</span>
                                    <span className="gray-text"> • </span>
                                    <span className="gray-text"> Qty.: </span>
                                    <span className="gray-text">{item.qty}</span>
                                </div>
                            </div>
                            <div>
                                <span className="gray-text">Manufacturer name:</span>
                                <span className="gray-text">{item.manufacturer.name}</span>
                                <span className="gray-text"> • </span>
                                <span className="gray-text">Manufacturer email:</span>
                                <span className="gray-text">{item.manufacturer.email}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            <div className="order-pdf" id="divToPrint" style={{ zoom: 0.5 }}>
                <div className="order-pdf-header">
                    <div className="title">User’s Company Name</div>
                    <div className="section">
                        <div className="section-label">Customer Name:</div>
                        <div className="section-value">{user.name}</div>
                    </div>
                    <div className="section">
                        <div className="section-label">Customer Email:</div>
                        <div className="section-value">{user.email}</div>
                    </div>
                    <div className="section">
                        <div className="section-label">Date:</div>
                        <div className="section-value">{dayjs().format('MM/DD/YYYY hh:mm')}</div>
                    </div>
                </div>
                <div className="separator"></div>
                <div className="address-wrapper">{renderAddress()}</div>
                <div className="separator"></div>
                <div className="orders">
                    <div className="title">Items requested</div>
                    <div className="orders-list">
                        {items?.products?.length > 0 && OrderProductRowItem(contextProducts)}
                        {items?.equipments?.length > 0 && OrderEquipmentItem(contextEquipments)}
                        <div className="total-price-wrapper">
                            <div className="total-price">
                                <span>Total Price:</span>
                                {getTotalPrice()}
                            </div>
                        </div>
                    </div>
                    <div className="title">Items for RFQ</div>
                    <div className="orders-list">
                        <div className="orders-list">{items?.customParts?.length > 0 && OrderRfqItem(contextCustomParts)}</div>
                    </div>
                </div>
                <div className="separator"></div>
                {/*<div className='footer'>*/}
                {/*  <img src="/assets/images/pdf-logo.png" alt="brand"/>*/}
                {/*</div>*/}
            </div>
        </>
    )
}
