import { FC, useCallback, useContext, useEffect, useState } from 'react'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import EasyIcon from '../../../components/EasyIcon'
import Icons, { IconType } from '../../../Icon'
import './ActivityList.scss'
import { ActivityModel } from '../../../models/Ui/Activity'
import { toast } from 'react-toastify'
import ActivityService from '../../../services/UiService/Activity'
import { priceFormatter, standardDate } from '../../../utils/helpers'
import dayjs from 'dayjs'
import { CompanyType } from '../../../models/Ui/CompanyType'
import SearchInput from '../../../components/SearchInput'
import Button from '../../../components/Button'
import { CustomerManufacturerContext } from '../../../store/CustomerDashboardContext'
import ActivityListTable, {
    assigneeColorSetter,
    assigneeShortNameSetter,
    assigneeFirstNameSetter,
    AssigneeWithColorModel,
    HeadCell,
    HeadCellType,
    useUniqueAssigneesWithColorsSetter,
} from '../../ManufacturerPages/ActivityList/ActivityListTable'
import BasicDropdown from '../../../components/Dropdown/BasicDropdown'

interface ActivityListProps {
    companyType: CompanyType
}

interface FilterType {
    id: string | any
    text: string
}

enum StatusFilterType {
    Open = 'open',
    InProgress = 'in-progress',
    OnHold = 'on-hold',
    Closed = 'closed',
}

interface DueDateFilter extends FilterType {
    key?: dayjs.OpUnitType
}

interface StatusFilter extends FilterType {
    type?: StatusFilterType
}

interface FilterState {
    dueDate: DueDateFilter
    type: FilterType
    status: FilterType
}

enum FilterKeys {
    DueDate = 'dueDate',
    Type = 'type',
    Status = 'status',
}

const ActivityList: FC<ActivityListProps> = ({ companyType }) => {
    const { selectedManufacturerInfo: selectedManufacturer } = useContext(CustomerManufacturerContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [activities, setActivities] = useState<ActivityModel[]>([])
    const [filteredActivitiesData, setFilteredActivitiesData] = useState<ActivityModel[]>([])
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [uniqueAssigneesWithColors, setUniqueAssigneesWithColors] = useState<AssigneeWithColorModel[]>([])

    useUniqueAssigneesWithColorsSetter(activities, setUniqueAssigneesWithColors)

    const typeFilterTypes = [
        {
            id: ' ',
            text: 'All',
        },
        {
            id: 'Ticket',
            text: 'Tickets',
            icon: IconType.Ticket,
        },
        {
            id: 'Service',
            text: 'Service',
            icon: IconType.Maintenance,
        },
        {
            id: 'Product Replenishment',
            text: 'Product Replenishment',
            icon: IconType.Product,
        },
    ]
    const dueDateFilterTypes: DueDateFilter[] = [
        { id: ' ', text: 'All Time' },
        {
            id: dayjs(),
            text: 'Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'day'),
            text: 'Before Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'week'),
            text: 'Last Week',
            key: 'week',
        },
        {
            id: dayjs().add(1, 'week'),
            text: 'Next Week',
            key: 'week',
        },
        {
            id: dayjs(),
            text: 'This Week',
            key: 'week',
        },
        {
            id: dayjs().subtract(1, 'month'),
            text: 'Last Month',
            key: 'month',
        },
        {
            id: dayjs().add(1, 'month'),
            text: 'Next Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Year',
            key: 'year',
        },
    ]
    const statusFilterTypes: StatusFilter[] = [
        { id: ' ', text: 'All' },
        { id: 'Open', text: 'Open', type: StatusFilterType.Open },
        { id: 'In Progress', text: 'In Progress', type: StatusFilterType.InProgress },
        { id: 'On Hold', text: 'On Hold', type: StatusFilterType.OnHold },
        { id: 'Closed', text: 'Closed', type: StatusFilterType.Closed },
    ]
    const initialFilter = {
        dueDate: { ...dueDateFilterTypes[0] } as DueDateFilter,
        type: { ...typeFilterTypes[0] } as FilterType,
        status: { ...statusFilterTypes[0] } as FilterType,
    }
    const [filterState, setFilterState] = useState<FilterState>(initialFilter)

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Activities',
        },
    ]

    const setFilter = (newState: any, key: FilterKeys) =>
        setFilterState((prevState) => ({
            ...prevState,
            [key]: newState,
        }))

    useEffect(() => {}, [filterState])

    useEffect(() => {}, [showFilter])

    const getActivitiesCallback = useCallback(async () => {
        try {
            if (companyType === CompanyType.Manufacturer) {
                setLoading(true)
                const response = await ActivityService.getActivitiesForManifacturer()
                setActivities(response)
            } else {
                setLoading(true)
                const response = await ActivityService.getVisibleActivitiesForCutomer()
                setActivities(response)
            }
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }, [companyType])

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getActivitiesCallback()
            setLoading(false)
        }

        loadComponent()
    }, [getActivitiesCallback, selectedManufacturer])

    const handleSearchedData = (data: any[]) => {
        setFilteredActivitiesData(data)
    }

    const toggleFilterMenu = () => {
        setFilterState(initialFilter)
        setShowFilter(!showFilter)
    }

    const activityTableColumns: HeadCell[] = [
        {
            id: '',
            label: '',
            type: HeadCellType.Empty,
        },
        {
            id: 'title',
            label: 'Title',
            type: HeadCellType.Title,
        },
        {
            id: 'assignee',
            label: 'Assignee',
            type: HeadCellType.Assignee,
        },
        {
            id: 'status',
            label: 'Status',
            type: HeadCellType.Status,
        },
        {
            id: 'dueDate',
            label: 'Due Date',
            type: HeadCellType.DueDate,
        },
        {
            id: 'action',
            label: '',
            type: HeadCellType.Action,
        },
    ]

    return (
        <PageWrapper className="master-page" breadcrumb={breadcrumb}>
            {loading && <Loading />}

            <ContentBox
                title="Activities"
                headerIcon={<EasyIcon icon={IconType.Activities} />}
                headerRight={
                    <div className="activity-right-buttons">
                        <SearchInput
                            data={activities}
                            handleSearch={handleSearchedData}
                            searchKeys={['title', 'customer.name', 'assignedBy.firstName', 'assignedBy.lastName', 'startDate', 'frequencyUnit', 'type']}
                        />
                        <Button className="filter-button" onClick={toggleFilterMenu}>
                            <Icons type={IconType.FilterSettings} />
                        </Button>
                    </div>
                }
            >
                {showFilter && (
                    <div className="filter-menu">
                        <BasicDropdown
                            label="Due Date"
                            menuItems={dueDateFilterTypes}
                            leftIcon={IconType.Calendar}
                            onChange={(e) => setFilter(e, FilterKeys.DueDate)}
                            selectedItem={{ id: filterState.dueDate.id, text: filterState.dueDate.text }}
                        />
                        <BasicDropdown
                            label="Type"
                            leftIcon={IconType.ThreeShape}
                            menuItems={typeFilterTypes}
                            onChange={(e) => setFilter(e, FilterKeys.Type)}
                            selectedItem={{ text: filterState.type.text, id: filterState.type.id }}
                        />
                        <BasicDropdown
                            label="Status"
                            menuItems={statusFilterTypes}
                            leftIcon={IconType.DottedCircle}
                            onChange={(e) => setFilter(e, FilterKeys.Status)}
                            selectedItem={{ id: filterState.status.id, text: filterState.status.text }}
                        />
                    </div>
                )}

                <div className="content">
                    <div className="activity-table">
                        <ActivityListTable
                            columns={activityTableColumns}
                            sortKey="dueDate"
                            data={filteredActivitiesData
                                // .filter((item: ActivityModel) => (filterBy.id === ' ' ? item : item.type === filterBy.id))
                                .filter((item: ActivityModel) => {
                                    if (filterState.dueDate.id === ' ') {
                                        return item
                                    } else if (filterState.dueDate.text === 'Before Today') {
                                        return filterState.dueDate.id >= dayjs(item.dueDate)
                                    }
                                    return filterState.dueDate.id.isSame(dayjs(item.dueDate), filterState.dueDate.key)
                                })
                                .filter((item: ActivityModel) => (filterState.type.id === ' ' ? item : item.type === filterState.type.id))
                                .filter((item: ActivityModel) => (filterState.status.id === ' ' ? item : item.status === filterState.status.id))
                                .map((p) => {
                                    return {
                                        isWorkOrder: p.isWorkOrder,
                                        recurring: p.isRecurring,
                                        title: p.title,
                                        customer: p.customer?.name,
                                        assignee: p.assignee && p.assignee.firstName + ' ' + p.assignee.lastName,
                                        status: p.status,
                                        dueDate: standardDate(p.dueDate),
                                        opportunity: priceFormatter(p.revenueOpportunity || '-'),
                                        id: p.id,
                                        groupId: p.groupId,
                                        isVisibleToCustomer: p.isVisibleToCustomer,
                                        description: p.description,
                                        type: p.type,
                                        attachments: p.attachments,
                                        equipments: p.equipments,
                                        products: p.products,
                                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, p),
                                        assigneeShortName: assigneeShortNameSetter(p.assignee),
                                        assigneeFirstName: assigneeFirstNameSetter(p),
                                    }
                                })}
                            isManufacturer={false}
                        />
                    </div>
                </div>
            </ContentBox>
        </PageWrapper>
    )
}

export default ActivityList
