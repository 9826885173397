import React, { PropsWithChildren, useContext, useState } from 'react'
import Button from '../../../components/Button'
import Icons, { IconType } from '../../../Icon'
import './TechnicianView.scss'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import OrderService from '../../../services/UiService/Order'
import AuthService from '../../../services/UiService/Auth'
import { useNavigate } from 'react-router'
import Modal from '../../../components/Modal'
import Input from '../../../components/Input'
import { CreateOrderOrQuoteInput } from '../../../models/Ui/Order'
import { CheckoutContext } from '../../../store/NewCheckoutContext/CheckoutContext'
import { ICartInformation, ISavedCartInformation, TechnicianViewType } from '../../../store/models'
import QuickCart from './../../../components/QuickCart/QuickCart'
import { SearchPartContext } from '../../../store/SearchPartContext'
import { Tooltip } from '@mui/material'
import { CheckoutType } from '../../../models/Ui/Equipment'

interface Props {
    title?: string
    subTitle?: React.ReactNode
    description?: JSX.Element | string
    customerName?: string
    className?: string
    goBackButtonDisabled?: boolean
    disabled?: boolean
    savedButtonDisabled?: boolean
    customerId?: string
    notEditable?: boolean
    isQuick?: boolean
    onLoading: () => void
    onCompleted: () => void
}

function TechnicianView(props: PropsWithChildren<Props>) {
    const checkoutContext = useContext(CheckoutContext)
    const searchPartContext = useContext(SearchPartContext)
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const auth = new AuthService().getAuthUser()
    const navigate = useNavigate()

    const onClickSavedButton = async () => {
        try {
            props.onLoading()
            const checkoutPayload = checkoutContext.createCheckoutPayload(auth.id, CheckoutType.UpdateCart) as CreateOrderOrQuoteInput

            if (checkoutContext.savedCartInformation.id) {
                await OrderService.updateSave(checkoutContext.savedCartInformation.id, checkoutPayload)
                // await checkoutContext.clearAll()
                // await checkoutContext.clearAll()
                //  await checkoutContext.toggleCartView()
            } else {
                // await checkoutContext.setSavedCartInfo({
                //     name: `Saved cart ${standardDate(new Date())}`,
                // } as ISavedCartInformation)
                setEditModalVisible(true)
            }
        } catch (e: any) {
            toast.error(e)
        } finally {
            props.onCompleted()
        }
    }
    const handleCreateSavedCart = async () => {
        try {
            props.onLoading()
            const checkoutPayload = checkoutContext.createCheckoutPayload(auth.id, CheckoutType.DraftCart) as CreateOrderOrQuoteInput
            await OrderService.save(checkoutPayload)
            // await checkoutContext.clearAll()
            await checkoutContext.clearAll()
            await checkoutContext.toggleCartView()
            navigate('/orders')
        } catch (e: any) {
            toast.error(e)
        } finally {
            props.onCompleted()
        }
    }
    const getClassName = () => {
        switch (props.notEditable ? true : checkoutContext.cartInformation.technicianViewType || props.notEditable) {
            case props.notEditable ? false : TechnicianViewType.ViewAs:
                return 'technician-view-as'
            case props.notEditable ? true : TechnicianViewType.PurchaseAs:
                return 'technician-purchase-as'
            case props.notEditable ? true : TechnicianViewType.Quick:
                return 'technician-purchase-as'
            default:
                return ''
        }
    }

    const getInfoText = () => {
        switch (props.notEditable ? true : checkoutContext.cartInformation.technicianViewType || props.notEditable) {
            case props.notEditable ? false : TechnicianViewType.ViewAs:
                return (
                    <div>
                        You are viewing this page as <b>{props?.customerName}</b>
                    </div>
                )
            case props.notEditable ? true : TechnicianViewType.PurchaseAs:
                return <div>Exit cart without saving</div>
            case props.notEditable ? true : TechnicianViewType.Quick:
                return <div>Your quick cart is active.</div>
            default:
                return ``
        }
    }

    if (
        ((props.customerId !== checkoutContext.cartInformation.purchaseForCustomerId || checkoutContext.cartInformation.technicianViewType === TechnicianViewType.None) &&
            !props.notEditable &&
            (!props.isQuick || checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.Quick)) ||
        (!props.isQuick && checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick)
    ) {
        return <div className="technician-view-content">{props.children}</div>
    } else if (!props.disabled && checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick) {
        return (
            <div className="quick-container">
                <div className="header">
                    <div className="header-item">
                        <Tooltip title="Delete and exit cart">
                            <div
                                className="close-button"
                                onClick={() => {
                                    checkoutContext.handleCartInformation({
                                        quickCartShow: false,
                                        technicianViewType: TechnicianViewType.None,
                                        purchaseForCustomerId: '',
                                        purchaseForCustomerName: '',
                                    } as ICartInformation)
                                    checkoutContext.setQuickCartInfo({
                                        id: '',
                                        name: '',
                                    })
                                    searchPartContext.reset()
                                    checkoutContext.reset()
                                    checkoutContext.quickCartReset()
                                }}
                            >
                                <Icons type={IconType.Close} />
                            </div>
                        </Tooltip>

                        <span className="title">{getInfoText()}</span>
                    </div>
                    {/* {!checkoutContext.cartInformation.quickCartShow && (
                        <div className="header-item">
                            <div className="quick-cart-button-container">
                                <PopoverItem popoverContent="Expand cart">
                                    <Button onClick={() => checkoutContext.toggleQuickCartView()} className="quick-cart-button" type={ButtonType.Tertiary}>
                                        <Icons type={IconType.Cart} />
                                    </Button>
                                </PopoverItem>
                                {checkoutContext.getCount() > 0 && <div className="order-counter">{checkoutContext.getCount()}</div>}
                            </div>
                        </div>
                    )} */}
                </div>
                <div className="content">
                    <div className="content-children">{props.children}</div>
                    {checkoutContext.cartInformation.quickCartShow && <QuickCart />}
                </div>
            </div>
        )
    }

    return !props.disabled ? (
        <div className={`technician-view ${getClassName()} ${props.className}`}>
            <div className="technician-info-wrapper">
                {/* {
                    <div className="technician-info-header">
                        <div className="technician-info-header-title-container">
                            {props.title && <span className="technician-view-title">{props.title}</span>}
                            {props.subTitle && <span className="technician-view-subTitle">{props.subTitle}</span>}
                        </div>
                        {props.description && <span className="technician-view-description">{props.description}</span>}
                    </div>
                } */}
                <div className="technician-info-bar">
                    <div className="left">
                        {!props.goBackButtonDisabled && (
                            <div className="close-button" onClick={() => checkoutContext.reset()}>
                                <Icons type={IconType.Close} />
                            </div>
                        )}
                        <span>{getInfoText()}</span>
                    </div>
                    <div className="right">
                        {!props.savedButtonDisabled && (
                            <Button disabled={checkoutContext.getCount() === 0} onClick={() => onClickSavedButton()} className="technician-save-wrapper">
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        )}
                        {/* {((props.customerId === checkoutContext.cartInformation.purchaseForCustomerId && checkoutContext.cartInformation.technicianViewType === TechnicianViewType.PurchaseAs) ||
                            props.notEditable) && (
                            <div onClick={() => checkoutContext.toggleCartView()} className="technician-cart-wrapper">
                                <Icons type={IconType.Cart} />
                                {checkoutContext.getCount() > 0 && <div className="counter-dot">{checkoutContext.getCount()}</div>}
                            </div>
                        )} */}
                    </div>
                    {
                        <Modal
                            visible={editModalVisible}
                            title="Save cart"
                            primaryButtonText="Save"
                            onClickPrimary={() => {
                                handleCreateSavedCart()
                            }}
                            secondaryButtonText="Cancel"
                            onClickSecondary={() => {
                                setEditModalVisible(false)
                            }}
                        >
                            <div className="modal-line">
                                <Input
                                    value={checkoutContext.savedCartInformation.name}
                                    onChange={(e) =>
                                        checkoutContext.setSavedCartInfo({
                                            name: e.target.value,
                                        } as ISavedCartInformation)
                                    }
                                    placeholder="NAME"
                                />
                            </div>
                        </Modal>
                    }
                </div>
            </div>
            <div className="technician-view-content">{props.children}</div>
        </div>
    ) : (
        <div>{props.children}</div>
    )
}

export default TechnicianView
