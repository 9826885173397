import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Tab from '../../../components/Tab'
import Icons, { IconType } from '../../../Icon'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import DetailsTab from './Tabs/DetailsTab/DetailsTab'
import './ProductDetails.scss'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import Button from '../../../components/Button'
import ProductService from '../../../services/UiService/Product'
import { ProductListModel } from '../../../models/New/Product'
import DocumentsTab from './Tabs/DocumentsTab/DocumentsTab'
import SearchInput from '../../../components/SearchInput'
import { DocumentListModel } from '../../../models/New/Document'
import { useNavigate } from 'react-router'
import CustomersTab from './Tabs/CustomerTab/CustomersTab'
import ActivityTab from './Tabs/ActivityTab'
import OrderTab from './Tabs/OrderTab'
import ProductEditModal from './Modals/ProductEditModal'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import { CompanyModel } from '../../../models/Ui/Company'
import { OrderModel } from '../../../models/New/Order'
import PopoverItem from '../../../components/PopoverItem'

const tabItems: TabItem[] = [
    {
        id: Tabs.Details,
        name: 'Details',
    },
    {
        id: Tabs.Documents,
        name: 'Documents',
    },
    {
        id: Tabs.Customers,
        name: 'Customers',
    },
    {
        id: Tabs.Orders,
        name: 'Orders',
    },
    {
        id: Tabs.Activities,
        name: 'Activities',
    },
]

function ProductDetailForManufacturer() {
    let { productId } = useParams()
    let [searchParams] = useSearchParams()
    const activeTabIndex = parseInt(searchParams.get('action') ? searchParams.get('action')!.toString() : '0')
    const { confirm } = useContext(ConfirmationContext)

    const noteRef = useRef<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(tabItems[activeTabIndex].id)
    const [product, setProduct] = useState<ProductListModel | any>()
    const [editProductModalVisible, setEditProductModalVisible] = useState<boolean>(false)
    const [documents, setDocuments] = useState<DocumentListModel[]>([])
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentListModel[]>([])
    const [createDocumentModalVisible, setCreateDocumentModalVisible] = useState<boolean>(false)
    const [refetch, setRefetch] = useState<boolean>(false)
    const [customers, setCustomers] = useState<CompanyModel[]>([])
    const [filteredCustomers, setFilteredCustomers] = useState<CompanyModel[]>([])
    const [orders, setOrders] = useState<OrderModel[]>([])
    const [filteredOrders, setFilteredOrders] = useState<OrderModel[]>([])
    const [activityLengthChanged, setActivityLengthChanged] = useState<boolean>(false)
    const [showAcitivityFilter, setShowActivityFilter] = useState<boolean>(false)
    const [showActivityProductFilter, setShowActivityProductFilter] = useState<boolean>(false)
    const navigate = useNavigate()

    const refreshActivites = () => {
        setActivityLengthChanged((prevState) => !prevState)
    }

    const getBreadcrumbTabName = () => {
        switch (activeTab) {
            case Tabs.Details:
                return 'Details'
            case Tabs.Documents:
                return 'Documents'
            case Tabs.Customers:
                return 'Customers'
            case Tabs.Orders:
                return 'Orders'
            case Tabs.Activities:
                return 'Activities'
            default:
                return ''
        }
    }

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Product',
            url: '/products',
        },
        {
            name: product?.name!,
            url: '/products/' + product?.id,
        },
        {
            name: getBreadcrumbTabName(),
        },
    ]

    const getProductCallback = useCallback(async () => {
        try {
            setLoading(true)
            const productResponse = await ProductService.getProductDetail(productId!)
            noteRef.current = productResponse.notes
            setProduct(productResponse)
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    useEffect(() => {
        getProductCallback()
    }, [getProductCallback])

    useEffect(() => {}, [editProductModalVisible])

    const onDocumentHandleSearch = (value: DocumentListModel[]) => {
        setFilteredDocuments(value)
    }

    const onCustomerHandleSearch = (value: CompanyModel[]) => setFilteredCustomers(value)

    const onOrderHandleSearch = (value: OrderModel[]) => setFilteredOrders(value)

    const deleteProductHandler = (productId: string) => {
        confirm({
            func: async (successCallback, _errCallback) => {
                try {
                    setLoading(true)
                    await ProductService.delete([productId])
                    successCallback()
                    navigate('/products')
                } catch (error: any) {
                    toast.error(error)
                } finally {
                    setLoading(false)
                }
            },
        })
    }

    const onLoadingCallback = useCallback(() => setLoading(true), [])
    const onCompletedCallback = useCallback(() => setLoading(false), [])
    const onErrorCallback = useCallback((error: any) => toast.error(error), [])

    const onSuccessCallback = () => {
        setRefetch(!refetch)
    }

    const onAddDemand = () => {
        setActiveTab(Tabs.Activities)
        setShowActivityFilter(true)
        setShowActivityProductFilter(true)
    }

    return (
        <PageWrapper className="product-detail-page" breadcrumb={breadcrumb}>
            {loading && <Loading />}
            {product && (
                <ContentBox
                    title={<div className="product-name">{product && product.name}</div>}
                    subTitle={
                        <div className="subtitle">
                            {product.brand.trim(' ') ? (
                                <div className="brand-container">
                                    <span className="title">{product.brand}</span>
                                </div>
                            ) : (
                                <div className="brand-container--empty" onClick={() => setEditProductModalVisible(true)}>
                                    +add brand
                                </div>
                            )}
                            <span className="part-number">
                                Part Number: <b>{product.partNumber}</b>
                                <span className="add-button" onClick={() => setEditProductModalVisible(true)}>
                                    {!product.partNumber && '+add'}
                                </span>
                            </span>
                        </div>
                    }
                >
                    <div className="tab-container">
                        <Tab
                            activeTab={activeTab}
                            tabs={tabItems}
                            onTabClick={(id: number) => {
                                setProduct({ ...product, note: noteRef.current || '' })
                                if (activeTab !== Tabs.Details && id === Tabs.Details) {
                                    getProductCallback()
                                }
                                setActiveTab(id)
                                setShowActivityProductFilter(false)
                            }}
                        />
                        {activeTab === Tabs.Customers && (
                            <div className="right-actions-documents">
                                <SearchInput data={customers} searchKeys={['name', 'email']} handleSearch={onCustomerHandleSearch} />
                            </div>
                        )}
                        {activeTab === Tabs.Orders && (
                            <div className="right-actions-documents">
                                <SearchInput data={orders} searchKeys={['customer', 'userName', 'transactionNumber']} handleSearch={onOrderHandleSearch} />
                            </div>
                        )}
                        {activeTab === Tabs.Documents ? (
                            <div className="right-actions-documents">
                                <SearchInput data={documents} searchKeys={['id', 'name', 'type', 'description']} handleSearch={onDocumentHandleSearch} />
                                <PopoverItem popoverContent="Add new">
                                    <Button className="create-document-button" onClick={() => setCreateDocumentModalVisible(true)}>
                                        <Icons type={IconType.PlusCircle} />
                                    </Button>
                                </PopoverItem>
                            </div>
                        ) : (
                            <div className="right-actions">
                                <PopoverItem popoverContent="Edit">
                                    <Icons type={IconType.Pen} onClick={() => setEditProductModalVisible(true)} />
                                </PopoverItem>
                                <PopoverItem popoverContent="Delete">
                                    <Icons type={IconType.Trash} onClick={() => deleteProductHandler(productId!)} />
                                </PopoverItem>
                            </div>
                        )}
                    </div>

                    <DetailsTab
                        product={product}
                        visible={activeTab === Tabs.Details}
                        modalVisible={editProductModalVisible}
                        onModalOpen={() => setEditProductModalVisible(true)}
                        onModalClose={() => setEditProductModalVisible(false)}
                        onSuccess={onSuccessCallback}
                        setProduct={(data: ProductListModel) => setProduct(data)}
                        onAddDemand={onAddDemand}
                        onClickManageCustomer={() => setActiveTab(Tabs.Customers)}
                        activityLengthChanged={activityLengthChanged}
                    />
                    <DocumentsTab
                        visible={activeTab === Tabs.Documents}
                        modalVisible={createDocumentModalVisible}
                        onModalOpen={() => setCreateDocumentModalVisible(true)}
                        onModalClose={() => setCreateDocumentModalVisible(false)}
                        onSearch={onDocumentHandleSearch}
                        filteredDocuments={filteredDocuments}
                        setDocuments={setDocuments}
                    />
                    <CustomersTab visible={activeTab === Tabs.Customers} filteredCustomers={filteredCustomers} onSearch={onCustomerHandleSearch} setCustomers={setCustomers} />
                    {activeTab === Tabs.Activities && (
                        <ActivityTab
                            showActivityProductFilter={showActivityProductFilter}
                            showFilter={showAcitivityFilter}
                            setShowFilter={setShowActivityFilter}
                            visible={activeTab === Tabs.Activities}
                            refreshActivites={refreshActivites}
                        />
                    )}
                    <OrderTab visible={activeTab === Tabs.Orders} filteredOrders={filteredOrders} onSearch={onOrderHandleSearch} setOrders={setOrders} />
                    {editProductModalVisible && (
                        <ProductEditModal
                            product={product}
                            selectedProductId={product.id}
                            closeModal={() => setEditProductModalVisible(false)}
                            visible={editProductModalVisible}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onError={onErrorCallback}
                            onSuccess={onSuccessCallback}
                        />
                    )}
                </ContentBox>
            )}
        </PageWrapper>
    )
}

export default ProductDetailForManufacturer
