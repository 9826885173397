import { ReactNode, FC, createContext, useEffect } from 'react'
import { useState } from 'react'
import { CompanyManufacturerModel } from '../models/New/Customer'
import { ManufacturerId } from '../Config'

interface CustomerDashboardContexProviderProps {
    children: ReactNode
}

const useContext = () => {
    const [selectedManufacturerInfo, setSelectedManufacturerInfo] = useState<CompanyManufacturerModel | undefined>(undefined)
    const [manufacturerList, setManufacturerList] = useState<CompanyManufacturerModel[]>([])

    const handleSelect = (manufacturer: CompanyManufacturerModel) => {
        localStorage.setItem(ManufacturerId, manufacturer.id)
        setSelectedManufacturerInfo(manufacturer)
    }
    const handleManufacturerList = (manufacturers: CompanyManufacturerModel[]) => {
        setManufacturerList(manufacturers)
        if (manufacturers.length > 0) {
            setSelectedManufacturerInfo(manufacturers[0])
        }
    }

    useEffect(() => {
        const manufacturerId = localStorage.getItem(ManufacturerId)
        const selectedManufacturer = manufacturerList.find((manufacturer) => manufacturerId === manufacturer.id)
        if (manufacturerId && selectedManufacturer) {
            setSelectedManufacturerInfo(selectedManufacturer)
        }
    }, [manufacturerList])

    return {
        selectedManufacturerInfo,
        manufacturerList,
        handleSelect,
        handleManufacturerList,
    }
}

export const CustomerManufacturerContext = createContext({} as ReturnType<typeof useContext>)

const CustomerDashboardContextProvider: FC<CustomerDashboardContexProviderProps> = ({ children }) => {
    return <CustomerManufacturerContext.Provider value={useContext()}>{children}</CustomerManufacturerContext.Provider>
}

export default CustomerDashboardContextProvider
