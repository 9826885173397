import { toast } from 'react-toastify'
import CustomerOrderListTable from '../../../../../components/CustomerOrderListTable'
import { OrderStatus } from '../../../../../components/Dropdown/dropdown.interface'
import { OrderModel, UpdateOrderDetailModel } from '../../../../../models/New/Order'
import OrderService from '../../../../../services/UiService/Order'
import './style.scss'

interface Props {
    orderData: OrderModel[]
    visible: boolean
    onLoading?: () => void
    onCompleted?: () => void
}

function PurchaseRequests(props: React.PropsWithChildren<Props>) {
    const { orderData, visible } = props

    async function updateStatusHandler(e: OrderStatus, orderId: string) {
        if (props.onCompleted && props.onLoading) {
            try {
                const data: UpdateOrderDetailModel = {
                    status: e,
                }
                await OrderService.updateOrderDetail(orderId, data)
                toast.success('Status updated!')
            } catch (error: any) {
                toast.error(error.message)
            } finally {
            }
        }
    }

    const orderStatusChangeHandler = (item: { id: string; text: string }, orderId: string) => {
        switch (item.id) {
            case OrderStatus.CANCELLED:
                updateStatusHandler(OrderStatus.CANCELLED, orderId)
                break
            case OrderStatus.ON_HOLD:
                updateStatusHandler(OrderStatus.ON_HOLD, orderId)
                break
            case OrderStatus.PROCESSING:
                updateStatusHandler(OrderStatus.PROCESSING, orderId)
                break
            case OrderStatus.SHIPPED:
                updateStatusHandler(OrderStatus.SHIPPED, orderId)
        }
    }

    return <div style={{ display: visible ? 'block' : 'none' }}>{<CustomerOrderListTable orderStatusChangeHandler={orderStatusChangeHandler} data={orderData} />}</div>
}

export default PurchaseRequests
