import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from '@mui/material'
import React from 'react'
import Icons, { IconType } from '../../Icon'
import { isIconDefinition } from '../../utils/helpers'
import './style.scss'

interface Props {
  disabled?:boolean
  tooltip?: string
  icon: IconDefinition | IconType
  className?: string
  color?: string
  onClick: () => void
}

function CircleIconButton(props: React.PropsWithChildren<Props>) {
  if (props.tooltip && !props.disabled) {
    return (
      <Tooltip  title={props.tooltip}>
        <Button className="circle-button" onClick={props.onClick}>
          <div className="circle-button-icon">
            {
              props.icon &&
                isIconDefinition(props.icon) ?
                <FontAwesomeIcon icon={props.icon as IconDefinition} color={props.color} />
                :
                <Icons className={props.className} type={props.icon as IconType} color={props.color} />
            }
          </div>
        </Button>
      </Tooltip>

    )
  }
  return (
    <Button disabled={props.disabled} className="circle-button" onClick={props.onClick}>
      <div className="circle-button-icon-disabled">
        {
          props.icon &&
            isIconDefinition(props.icon) ?
            <FontAwesomeIcon icon={props.icon as IconDefinition} color={props.color} />
            :
            <Icons className={props.className} type={props.icon as IconType} color={props.color} />
        }
      </div>
    </Button>
  )
}

export default CircleIconButton
