import { Tooltip } from '@mui/material'
import { FC } from 'react'
import Icons, { IconType } from '../../../../../../../Icon'
import './style.scss'

interface Props {
    photoUrl: string
    onDelete: () => void
}

const DrawingModalUploadedImage: FC<Props> = ({ photoUrl, onDelete }) => {
    return (
        <div
            className="drawing-modal-uploaded-image"
            style={{
                backgroundImage: `url(${photoUrl})`,
            }}
        >
            <div className="delete-button" onClick={onDelete}>
                <Tooltip title="Delete">
                    <span>
                        <Icons type={IconType.Trash} />
                    </span>
                </Tooltip>
            </div>
        </div>
    )
}

export default DrawingModalUploadedImage
