import React, { useContext } from 'react'
import Button from '../../../../../../components/Button'
import ImageWithModal from '../../../../../../components/ImageWithModal'
import Icons, { IconType } from '../../../../../../Icon'
import { ProductListModel } from '../../../../../../models/New/Product'
import { ProductModel } from '../../../../../../models/Ui/Product'
import { CustomerCheckoutContext } from '../../../../../../store/CustomerCheckoutContext/CustomerCheckoutContext'
import { priceFormatter } from '../../../../../../utils/helpers'
import './ProductCart.scss'

interface Props {
    productInfo: ProductListModel
}

function ProductCart(props: Props) {
    const checkoutContext = useContext(CustomerCheckoutContext)

    const onAddToCart = (product: ProductListModel) => {
        const newData: ProductModel = {
            id: product.id,
            part_no: product.partNumber,
            name: product.name,
            category: product.category,
            vendor: product.brand,
            uom: product.uom,
            pkg: product.pkg,
            price: product.price ? product.price : '-',
            adjustedPrice: product.price ? product.price : 0,
            qty: product.quantity,
            manufacturer: {
                name: '',
                id: product.companyId,
                email: '',
            },
            attachments: [],
        }
        checkoutContext.addProduct(newData)
    }
    return (
        <div className="product-cart-container">
            <div className="body">
                {props.productInfo.drawingUrl && (
                    <div className="cart-img">
                        <ImageWithModal src={props.productInfo.drawingUrl} />
                    </div>
                )}
                <div className="cart-info">
                    <span className="brand">{props.productInfo.brand}</span>
                    <span className="part-number">{props.productInfo.partNumber}</span>
                    <span className="part-name">{props.productInfo.name}</span>
                </div>
            </div>
            <div className="footer">
                <span className="price-info">{priceFormatter(props.productInfo.price)}</span>
                <Button onClick={() => onAddToCart(props.productInfo)}>
                    <Icons type={IconType.GoToShopping} /> Add to Cart
                </Button>
            </div>
        </div>
    )
}

export default ProductCart
