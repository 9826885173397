import { Tooltip } from '@mui/material'
import { FC, RefObject, useEffect, useRef, useState } from 'react'
import Icons, { IconType } from '../../Icon'
import Checkbox from '../Checkbox'
import Input from '../Input'
import './style.scss'
import { Size } from '../../models/enums'
import { differenceBetweenTwoArray, formatQuantity } from '../../utils/helpers'

export type DropdownWithQuantityValueModel = {
    id: string | number
    quantity: number
}

export type DropdownWithQuantityOptionModel = {
    id: string | number
    label: string
    subText?: string
}

interface Props {
    value?: DropdownWithQuantityValueModel[]
    onChange?: (value: DropdownWithQuantityValueModel[]) => void
    saveTooltipText?: string
    placeholder?: string
    options?: DropdownWithQuantityOptionModel[]
    selectionDescription?: string
}

const DropdownWithQuantity: FC<Props> = ({ selectionDescription = 'items', saveTooltipText = 'Done', placeholder = '', value = [], onChange = () => null, options = [] }) => {
    const dropdownRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const [focused, setFocused] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [dropdownValue, setDropdownValue] = useState<DropdownWithQuantityValueModel[]>(value)
    const [filteredOptions, setFilteredOptions] = useState<DropdownWithQuantityOptionModel[]>(options)

    useEffect(() => {
        setFilteredOptions(options?.filter((child) => searchText === '' || child?.label?.toLowerCase()?.includes(searchText.toLowerCase())) ?? [])
    }, [options, searchText])

    useEffect(() => {
        setDropdownValue([...value])
    }, [value])

    useEffect(() => {
        document.addEventListener('mousedown', (e: MouseEvent) => {
            if (focused && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
                setFocused(false)
                clearForm()
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused])

    const onClickSave = () => {
        onChange(dropdownValue)
        setSearchText('')
        setFocused(false)
    }

    const clearForm = () => {
        setSearchText('')
        setFocused(false)
        setDropdownValue([...value])
    }

    const isChecked = (id: string | number) => dropdownValue.some((child) => child.id === id)

    const showQuantity = (id: string | number) => dropdownValue.some((child) => child.id === id)

    const onCheck = (id: string | number) => {
        if (dropdownValue.some((child) => child.id === id)) {
            setDropdownValue((prevState) => [...prevState.filter((child) => child.id !== id)])
        } else {
            setDropdownValue((prevState) => [...prevState, { id, quantity: 1 }])
        }
    }

    const setQuantity = (id: string | number) => dropdownValue.find((child) => child.id === id)?.quantity ?? 1

    const onChangeQuantity = (id: string | number, val: number) => {
        let existValue = dropdownValue
        existValue = existValue.filter((child) => child.id !== id)
        existValue = [...existValue, { id, quantity: val }]
        setDropdownValue([...existValue])
    }

    const onClickSearchInput = () => {
        if (options.length > 0) {
            setFocused(true)
        }
    }

    const setInputPlaceHolder = () =>
        focused || (!focused && value.length === 0)
            ? placeholder
            : value.length === 1
            ? `${options.find((child) => child.id === value[0]?.id)?.label ?? ''}`
            : `${value.length} ${selectionDescription} selected`

    return (
        <div className="dropdownWithChecklistContainer" ref={dropdownRef}>
            <div className={`dropdownWithChecklistSearchContainer ${focused && 'focusedDropdown'}`} onClick={onClickSearchInput}>
                {searchText && <div className="placeholder">{placeholder}</div>}
                <input type="text" placeholder={setInputPlaceHolder()} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <div className="chevronDown">
                    <Icons type={IconType.ChevronDown} />
                </div>
            </div>
            {focused && (
                <div className="saveContainer">
                    <Tooltip title={saveTooltipText}>
                        <div onClick={onClickSave} className={`saveCircle ${!differenceBetweenTwoArray(value, dropdownValue).length && 'disabled'}`}>
                            <Icons type={IconType.Check} />
                        </div>
                    </Tooltip>
                </div>
            )}
            {focused && filteredOptions.length > 0 && (
                <div className="searchList">
                    {filteredOptions?.map((option, index) => (
                        <div className={`searchListItem ${option.subText && 'with-sub-tex'}`} key={index}>
                            <Checkbox checked={isChecked(option.id)} onChange={() => onCheck(option.id)} />
                            <span className="product-option-label">
                                <b>{option.label}</b>
                                {option.subText && <div className="product-option-sub-text">{option.subText}</div>}
                            </span>
                            {showQuantity(option.id) && (
                                <Input
                                    placeholder="QTY"
                                    type="number"
                                    size={Size.sm}
                                    value={setQuantity(option.id)}
                                    onBlur={() => {
                                        if (!setQuantity(option.id) || setQuantity(option.id) < 1) {
                                            onChangeQuantity(option.id, 1)
                                        }
                                    }}
                                    onChange={(e) => onChangeQuantity(option.id, formatQuantity(e.target.value))}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default DropdownWithQuantity
