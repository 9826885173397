import { FC, useEffect, useRef, useState } from 'react'
import BasicDatePicker from '../../../../../../components/DatePicker'
import Icons, { IconType } from '../../../../../../Icon'
import { standardDate } from '../../../../../../utils/helpers'
import './style.scss'

interface Props {
    value: null | Date
    onChange: (val: null | Date) => void
    popperPlacement?: 'top-end' | 'bottom-end' | 'left-start'
    disabled?: boolean
    placeholder?: 'Start Date' | 'Due Date'
    onFocused?: (active: boolean) => void
}

const ActivityStartDueDate: FC<Props> = ({ value, onChange, popperPlacement = 'top-end', disabled, placeholder = 'Due Date', onFocused }) => {
    const dropdownContainerRef = useRef<HTMLDivElement>(null)
    const [focused, setFocused] = useState(false)

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
                setFocused(false)
            }
        }

        document.addEventListener('mousedown', (e) => handleClickOutside(e))
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (onFocused) onFocused(focused)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focused])

    return (
        <div className="activity-due-date-container" ref={dropdownContainerRef}>
            <BasicDatePicker disabled={disabled} popperPlacement={popperPlacement} startDate={value} onDateChange={(val) => onChange(val)} onChangeVisibility={(visible) => setFocused(visible)} />
            <div className={`selected-one ${!value && 'empty-due-date'} ${focused && 'focused-dropdown'} ${disabled ? 'disabled-dropdown' : 'enabled-dropdown'}`}>
                <Icons type={IconType.Calendar} />
                {(value && standardDate(value.toISOString())) || placeholder}
            </div>
        </div>
    )
}

export default ActivityStartDueDate
