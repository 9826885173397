import { ReactNode, FC, createContext, useState } from 'react'
import { ModalProps } from '../components/Modal'
import ConfirmationModal from '../modals/confirmationModal'

interface ConfirmationContextProviderProps {
    children: ReactNode
}

interface ConfirmationModel extends Pick<ModalProps, 'buttonOrder' | 'titleColor'> {
    show: boolean
    onClose: () => void
    func: (successCallback: (successMessage?: string, showSuccessMessage?: boolean) => void, errorCallback: (error: Error) => void) => void
    contentText?: string
    contentNode?: ReactNode
    successText?: string
    modalClassName?: string
    modalTitle?: string
    primaryButtonText?: string
    secondaryButtonText?: string
    doesPrimaryButtonSubmit?: boolean
    endLine?: boolean
    secondaryFunc?: (successCallback: (successMessage?: string) => void) => void
}

interface ConfirmInputModel extends Omit<ConfirmationModel, 'show' | 'onClose'> {}

const useContext = () => {
    const confirmationDefaultValues: Omit<ConfirmationModel, 'onClose'> = {
        show: false,
        func: () => null,
        secondaryFunc: undefined,
        contentText: 'Are you sure you would like to proceed?',
        successText: 'Action successful!',
        primaryButtonText: 'Ok',
        secondaryButtonText: 'Cancel',
        doesPrimaryButtonSubmit: true,
        endLine: false,
        contentNode: undefined,
        modalClassName: undefined,
        modalTitle: undefined,
        buttonOrder: undefined,
        titleColor: undefined,
    }

    const [confirmation, setConfirmation] = useState<ConfirmationModel>({
        ...confirmationDefaultValues,
        onClose: () =>
            setConfirmation((prevState) => ({
                ...prevState,
                ...confirmationDefaultValues,
            })),
    })

    const confirm = (args: ConfirmInputModel) => {
        setConfirmation((prevState) => ({
            ...prevState,
            ...args,
            show: true,
        }))
    }

    return {
        confirmation,
        confirm,
    }
}

export const ConfirmationContext = createContext({} as ReturnType<typeof useContext>)

const ConfirmationContextProvider: FC<ConfirmationContextProviderProps> = ({ children }) => {
    return (
        <ConfirmationContext.Provider value={useContext()}>
            {children}
            <ConfirmationModal />
        </ConfirmationContext.Provider>
    )
}

export default ConfirmationContextProvider
