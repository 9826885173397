/* eslint-disable @typescript-eslint/no-unused-vars */
import { faBell, faInfo, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import { RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Tab from '../../components/Tab'
import Icons from '../../Icon'
import Icon, { IconType } from '../../Icon'
import { Color } from '../../models/enums'
import NotificationModel from '../../models/Ui/Notification'
import { TabItem, Tabs } from '../../models/Ui/Tab'
import NotificationService from '../../services/UiService/Notification'
import { SocketContext } from '../../store/SocketContext'
import { standardDate } from '../../utils/helpers'
import './NotificationPopup.scss'

const tabItems: TabItem[] = [
    {
        id: Tabs.UnreadNotifications,
        name: 'New',
        customIcon: <Icons type={IconType.UnReadNotification} />,
    },
    {
        id: Tabs.ReadNotifications,
        name: 'Read',
        customIcon: <Icons type={IconType.ReadNotification} />,
    },
]

function NotificationPopup() {
    const { notifications, setNotifications, setNotificationsUpdated } = useContext(SocketContext)
    const popupBoxRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const [showPopup, setShowPopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)
    const navigate = useNavigate()

    const notificationService = useMemo(() => {
        return new NotificationService()
    }, [])

    useEffect(() => {
        document.addEventListener('mousedown', (e: MouseEvent) => {
            if (popupBoxRef.current && !popupBoxRef.current.contains(e.target as Node)) {
                setShowPopup(false)
            }
        })
    }, [])

    const getItemClassName = (notification: NotificationModel) => {
        let className = 'notification-item'

        if (!notification.isRead) {
            className += ' new'
        }

        if (notification.path) {
            className += ' pointer'
        }

        return className
    }

    const onClickGoToButton = async (notification: NotificationModel) => {
        if (notification.path) {
            markAsReadNotification(notification, true)
            setShowPopup(false)
            navigate(notification.path!)
        }
    }

    const markReadAllNotifications = async () => {
        try {
            setLoading(true)
            await notificationService.markAsAllRead()
            let existNotifications = notifications
            existNotifications = existNotifications.map((child) => ({ ...child, isRead: true }))
            setNotifications([...existNotifications])
            setNotificationsUpdated((prevState) => !prevState)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const markAsReadNotification = async (notification: NotificationModel, isRead: boolean) => {
        try {
            await notificationService.markAsRead(notification.id, isRead)
            const newNotifications = [...notifications]
            const index = notifications.findIndex((p) => p.id === notification.id)
            newNotifications[index].isRead = isRead
            setNotifications(newNotifications)
            setNotificationsUpdated((prevState) => !prevState)
        } catch (error) {
            console.log(error)
        }
    }

    const onClickShowAllButton = () => {
        setShowPopup(false)
        navigate('/notifications')
    }

    const newestCount = notifications.filter((p) => !p.isRead).length

    if (!showPopup) {
        return (
            <div className="notification-popup-wrapper">
                <Tooltip title="Notifications">
                    <div onClick={() => setShowPopup(true)} className="notification-icon-wrapper">
                        <FontAwesomeIcon className="icon" color={Color.grey600} icon={faBell} />
                        {newestCount > 0 && <div className="counter" />}
                    </div>
                </Tooltip>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="notification-popup-wrapper">
                <Tooltip title="Notifications">
                    <div onClick={() => setShowPopup(true)} className="notification-icon-wrapper">
                        <FontAwesomeIcon className="icon" color={Color.grey600} icon={faBell} />
                        {newestCount > 0 && <div className="counter" />}
                    </div>
                </Tooltip>
                <div className="notification-panel">
                    <div className="notification-panel-header">
                        <span>Notifications</span>
                        <div onClick={() => setShowPopup(false)} className="close-panel">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>

                    <div className="notification-panel-body">
                        <div className="notification-info-message">
                            <FontAwesomeIcon icon={faInfo} /> <span>Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="notification-popup-wrapper">
            <Tooltip title="Notifications">
                <div onClick={() => setShowPopup(true)} className="notification-icon-wrapper openBell">
                    <FontAwesomeIcon className="icon" color={Color.grey600} icon={faBell} />
                    {newestCount > 0 && <div className="counter" />}
                </div>
            </Tooltip>

            <div className="notification-panel" ref={popupBoxRef}>
                <Tab
                    activeTab={activeTab}
                    tabs={tabItems}
                    onTabClick={(id) => {
                        setActiveTab(id)
                    }}
                ></Tab>
                <div className="notification-panel-body">
                    {notifications.length > 0 ? (
                        notifications
                            .filter((child) => {
                                if (activeTab === Tabs.UnreadNotifications) {
                                    return !child.isRead
                                } else {
                                    return child.isRead
                                }
                            })
                            .map((n, i) => {
                                return (
                                    <div key={i} className={getItemClassName(n)}>
                                        <div className="notificationItemLeftSide">{!n.isRead && <div className="redDot" />}</div>
                                        <div className="notificationItemMiddleSide">
                                            <div className={`notification-title ${n.isRead && 'readTitle'}`}>
                                                <span style={{ cursor: n.path ? 'pointer' : 'default' }} onClick={() => onClickGoToButton(n)}>
                                                    {n.title}
                                                </span>
                                                {n.creationDate && <span className="date-info">{standardDate(n.creationDate)}</span>}
                                            </div>
                                            {n.description && <div className="notification-description">{n.description}</div>}
                                        </div>
                                        <div className="notificationItemRightSide">
                                            <div className="notification-button-wrapper">
                                                {!n.isRead && (
                                                    <Tooltip title="Mark as read">
                                                        <div className="notification-button" onClick={() => markAsReadNotification(n, true)}>
                                                            <Icon type={IconType.UnReadNotification} />
                                                        </div>
                                                    </Tooltip>
                                                )}
                                                {n.isRead && (
                                                    <Tooltip title="Mark as unread">
                                                        <div className="notification-button" onClick={() => markAsReadNotification(n, false)}>
                                                            <Icon type={IconType.ReadNotification} />
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    ) : (
                        <div className="notification-info-message">
                            <FontAwesomeIcon icon={faInfo} /> <span>No notifications yet</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NotificationPopup
