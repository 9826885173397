import { useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import ContentBox from '../../../components/ContentBox'
import EasyIcon from '../../../components/EasyIcon'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Icons, { IconType } from '../../../Icon'
import { arraySort } from '../../../utils/helpers'
import './OrderListForManufacturers.scss'
import OrderService from './../../../services/UiService/Order'
import { OrderModel } from '../../../models/New/Order'
import OrderListTable, { ActionButtons } from '../../../components/OrderTableList'
import SearchInput from '../../../components/SearchInput'
import UpdateShippingModal from './Modals/UpdateShippingInformation'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import Tab from '../../../components/Tab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import SavedCardTable from './Tables/SavedCardTable'
import Modal from '../../../components/Modal'
import { CheckoutContext } from '../../../store/NewCheckoutContext/CheckoutContext'
import { useNavigate } from 'react-router'
import { ICartInformation, TechnicianViewType } from '../../../store/models'
import Button from '../../../components/Button'
import NoData from '../../../components/NoData'
import { CheckoutType } from '../../../models/Ui/Equipment'

const tabItems: TabItem[] = [
    {
        id: Tabs.SavedCarts,
        name: 'Saved Carts',
        customIcon: <FontAwesomeIcon icon={faSave} />,
    },
    {
        id: Tabs.Quotes,
        name: 'Quotes',
        customIcon: <Icons type={IconType.MessageDollar} />,
    },
    {
        id: Tabs.Orders,
        name: 'Purchase Requests',
        customIcon: <Icons type={IconType.OrderBag} />,
    },
]

interface DeleteModalModel {
    visible: boolean
    id: string | undefined
}

function OrderListForManufacturers() {
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)
    const [orders, setTransactionsData] = useState<OrderModel[]>([])
    const [filteredData, setFilteredData] = useState<OrderModel[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [updateShippingModalVisible, setUpdateShippingModalVisible] = useState<boolean>(true)
    const [deleteModalInfo, setDeleteModalInfo] = useState<DeleteModalModel>({ visible: false, id: undefined })
    const [updateModalInfo, setUpdateModalInfo] = useState<OrderModel | undefined>(undefined)
    const checkoutContext = useContext(CheckoutContext)
    const navigate = useNavigate()

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Transactions',
        },
    ]

    const ordersActionButtons: ActionButtons[] = [
        {
            icon: IconType.GoToPage,
            popoverContent: 'View attachment',
            onClick: () => {},
            type: 'link',
        },
        {
            icon: IconType.ReOrder,
            popoverContent: 'Reorder',
            className: 'yellow-button',
            onClick: (data: OrderModel) => {
                handleReORder(data)
            },
            type: 'button',
        },
    ]
    const quotesActionButtons: ActionButtons[] = [
        {
            icon: IconType.GoToShopping,
            popoverContent: 'Re-quote or Purchase',
            onClick: (data: OrderModel) => {
                handleCart({ ...data, isQuote: true, checkoutType: CheckoutType.Quote })
            },
            type: 'button',
            tooltipPlacement: 'left',
        },
    ]
    const savedCartsActionButtons: ActionButtons[] = [
        {
            icon: IconType.Trash,
            popoverContent: 'Delete',
            onClick: (data: OrderModel) => setDeleteModalInfo({ visible: true, id: data.id }),
            type: 'button',
        },
    ]

    const handleReORder = async (orderModel: OrderModel) => {
        try {
            setLoading(true)
            const response = await OrderService.getReOrder(orderModel.id)
            await handleCart(response)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }

    const handleCart = async (order: OrderModel) => {
        try {
            checkoutContext.clearAll().then(() => checkoutContext.fillOrder(order))
        } catch (error) {}
    }

    const getTransactionsDataCallback = useCallback(async () => {
        try {
            setLoading(true)
            switch (activeTab) {
                case Tabs.Orders:
                    const orders = await OrderService.getPurchaseRequests()
                    setTransactionsData(arraySort(orders, 'creationDate', 'descending'))
                    break
                case Tabs.SavedCarts:
                    const savedCarts = await OrderService.getSavedCarts()
                    setTransactionsData(arraySort(savedCarts, 'creationDate', 'descending'))
                    break
                case Tabs.Quotes:
                    const quotes = await OrderService.getVendorQuotes()
                    setTransactionsData(arraySort(quotes, 'creationDate', 'descending'))
                    break
                default:
                    break
            }
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }, [activeTab])

    async function handleDelete(id: string) {
        try {
            setLoading(true)
            await OrderService.delete(id)
            setDeleteModalInfo({ visible: false, id: undefined })
            await getTransactionsDataCallback()
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleSearchedData = (data: OrderModel[]) => {
        setFilteredData(data)
    }
    const handleQuickCart = async () => {
        await checkoutContext.handleCartInformation({
            technicianViewType: TechnicianViewType.Quick,
            quickCartShow: true,
        } as ICartInformation)
        navigate('/quick')
    }

    useEffect(() => {
        getTransactionsDataCallback()
    }, [getTransactionsDataCallback])

    return (
        <PageWrapper breadcrumb={breadcrumb}>
            <ContentBox title="Transactions" headerIcon={<EasyIcon icon={IconType.OrderBag} />}>
                {loading && <Loading />}
                <div className="tab-container">
                    <Tab
                        activeTab={activeTab}
                        tabs={tabItems}
                        onTabClick={(id: number) => {
                            setActiveTab(id)
                            if (activeTab !== id) setTransactionsData([])
                        }}
                    />
                    <SearchInput data={orders} handleSearch={handleSearchedData} searchKeys={['customer', 'transactionNumber', 'creationDate']} />
                </div>
                {!loading && !orders.length ? (
                    <NoData
                        disabledWrapper
                        handleClick={handleQuickCart}
                        breadcrumb={breadcrumb}
                        pageTitle="Transactions"
                        title={`You currently do not have ${
                            activeTab === Tabs.SavedCarts ? 'any saved carts' : activeTab === Tabs.Quotes ? 'quotes created' : 'any purchase requests'
                        } on Monittor. Start by creating a cart.`}
                        customizedButton={
                            <Button className="no-data__button" onClick={handleQuickCart}>
                                Open Quick Cart
                            </Button>
                        }
                    />
                ) : (
                    <>
                        {activeTab === Tabs.Quotes && (
                            <OrderListTable
                                actionButtons={quotesActionButtons}
                                onLoading={() => setLoading(true)}
                                onCompleted={() => setLoading(false)}
                                setModalInfo={(e) => setUpdateModalInfo(e)}
                                // handleEditShippingModal={() => setUpdateShippingModalVisible(true)}
                                handleEditShippingModal={() => {}}
                                data={filteredData}
                                isQuote={true}
                                onClick={(data) => handleCart(data)}
                            />
                        )}
                        {activeTab === Tabs.Orders && orders.length > 0 && (
                            <OrderListTable
                                shippingInfo
                                statusInfo
                                actionButtons={ordersActionButtons}
                                onLoading={() => setLoading(true)}
                                onCompleted={() => setLoading(false)}
                                setModalInfo={(e) => setUpdateModalInfo(e)}
                                handleEditShippingModal={() => {}}
                                data={filteredData}
                            />
                        )}
                        {activeTab === Tabs.SavedCarts && orders.length > 0 && (
                            <SavedCardTable
                                onClick={(data) => handleCart(data)}
                                actionButtons={savedCartsActionButtons}
                                onLoading={() => setLoading(true)}
                                onCompleted={() => setLoading(false)}
                                setModalInfo={(e) => setUpdateModalInfo(e)}
                                handleEditShippingModal={() => setUpdateShippingModalVisible(true)}
                                data={filteredData}
                            />
                        )}
                    </>
                )}
                {updateShippingModalVisible && updateModalInfo && (
                    <UpdateShippingModal
                        visible={updateShippingModalVisible}
                        data={updateModalInfo.orderDetails[0]}
                        closeModal={() => setUpdateShippingModalVisible(false)}
                        onLoading={() => setLoading(true)}
                        onCompleted={() => setLoading(false)}
                        onSuccess={() => getTransactionsDataCallback()}
                        onError={(e) => toast.error(e.message)}
                    />
                )}
                {deleteModalInfo.id && (
                    <Modal
                        visible={deleteModalInfo.visible}
                        title="Delete cart?"
                        primaryButtonText="Yes"
                        onClickPrimary={() => handleDelete(deleteModalInfo.id || '')}
                        secondaryButtonText="No"
                        onClickSecondary={() => setDeleteModalInfo({ visible: false, id: undefined })}
                    >
                        <span>This action cannot be undone. Are you sure you want to proceed?</span>
                    </Modal>
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default OrderListForManufacturers
