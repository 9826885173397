import * as React from 'react'
import { ChangeEvent, useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icons, { IconType } from '../../Icon'
import { Color } from '../../models/enums'
import { arraySort, calculateTotalPrice, priceFormatter, standardDate } from '../../utils/helpers'
import { OrderModel } from '../../models/New/Order'
import { Collapse, IconButton, TooltipProps } from '@mui/material'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import OrderListExpandItem from '../OrderCardList/OrderListExpandItem'
import './style.scss'
import PopoverItem from '../PopoverItem'
import Button from '../Button'
import { ignoreDashClassname } from '../../hooks/useTableHook'
import NoDataInfo from '../NoDataInfo'
import TablePagination from '@mui/material/TablePagination/TablePagination'
import BasicDropdown, { IDropdownItem } from '../Dropdown/BasicDropdown'
import { OrderStatus } from '../Dropdown/dropdown.interface'

export interface ActionButtons {
    icon: IconType
    popoverContent: string
    className?: string
    onClick: (value: OrderModel) => void
    type: 'button' | 'link'
    tooltipPlacement?: TooltipProps['placement']
}
interface HeadCell {
    id: string
    label: string
}

interface Props {
    shippingInfo?: boolean
    statusInfo?: boolean
    actionButtons?: ActionButtons[]
    data: OrderModel[]
    handleEditShippingModal?: () => void
    setModalInfo?: (orderModel: OrderModel) => void
    onLoading?: () => void
    onCompleted?: () => void
    onClick?: (orderModel: OrderModel) => void
    isQuote?: boolean
    orderStatusChangeHandler?: (e: IDropdownItem, orderId: string) => void
}

const columns: HeadCell[] = [
    {
        id: 'transactionNumber',
        label: 'Ref. no.',
    },
    {
        id: 'userName',
        label: 'Placed By',
    },
    {
        id: 'creationDate',
        label: 'Date Created',
    },
    {
        id: 'price',
        label: 'Amount',
    },
    {
        id: 'status',
        label: 'Status',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return !props.disable ? (
        <div className="sorting-menu">{props.children}</div>
    ) : (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}

interface TableHeadType {
    status?: boolean
    actionButtons?: ActionButtons[]
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: React.Dispatch<React.SetStateAction<boolean>>
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'buttons' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection((prevState) => !prevState)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
                {tableProps.actionButtons && <TableCell align="left" padding={'normal'}></TableCell>}
            </TableRow>
        </TableHead>
    )
}
export const shortNameSetter = (name: string) => {
    const words = name.split(' ')
    var shortName: string = ''
    for (let i = 0; i < words.length; i++) {
        const word = words[i]
        shortName += word.slice(0, 1)
    }
    return shortName
}

function Row(props: {
    shippingInfo?: boolean
    status?: boolean
    onClick?: (orderModel: OrderModel) => void
    actionButtons?: ActionButtons[]
    handleEditShippingModal?: () => void
    setModalInfo?: (orderModel: OrderModel) => void
    onLoading?: () => void
    onCompleted?: () => void
    data: OrderModel
    isQuote?: boolean
    orderStatusChangeHandler?: (e: IDropdownItem, orderId: string) => void
    badgeBackgroundColor: number
}) {
    const orderModel = props.data
    const [isOpen, setOpen] = useState<boolean>(false)
    const [orderStatus, setOrderStatus] = useState({ id: orderModel.orderDetails[0]?.status as OrderStatus, text: orderModel.orderDetails[0]?.status as string })

    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={orderModel.id}>
                <TableCell align="left" style={{ width: '15%' }}>
                    <div className="table-line">
                        <PopoverItem popoverContent={isOpen ? 'Hide Details' : 'Show Details'}>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!isOpen)}>
                                {isOpen ? <FontAwesomeIcon color={Color.grey600} icon={faAngleUp} /> : <FontAwesomeIcon color={Color.grey600} icon={faAngleDown} />}
                            </IconButton>
                        </PopoverItem>

                        {orderModel.transactionNumber === 'undefined' ? '' : orderModel.transactionNumber.toUpperCase()}
                    </div>
                </TableCell>
                <TableCell style={{ width: '25%' }} align="left">
                    <div className="table-line">
                        <div className="badge" style={{ backgroundColor: `#${props.badgeBackgroundColor.toString(16)}`, color: 'white' }}>
                            {shortNameSetter(orderModel.userName)}
                        </div>
                        {orderModel.userName}
                    </div>
                </TableCell>
                <TableCell style={{ width: '15%' }} align="left">
                    {standardDate(orderModel.creationDate)}
                </TableCell>
                <TableCell style={{ width: '15%' }} align="left">
                    {priceFormatter(orderModel.price!)}
                </TableCell>
                {!props.isQuote ? (
                    <TableCell style={{ width: '15%' }} align="left">
                        <BasicDropdown
                            menuItems={[
                                { id: OrderStatus.ON_HOLD.toString(), text: 'On Hold' },
                                { id: OrderStatus.SHIPPED.toString(), text: 'Shipped' },
                                { id: OrderStatus.PROCESSING.toString(), text: 'Processing' },
                                { id: OrderStatus.CANCELLED.toString(), text: 'Cancelled' },
                            ]}
                            onChange={async (item) => {
                                await props.orderStatusChangeHandler!(item, orderModel.orderDetails[0].id)
                                setOrderStatus({ id: item.id as OrderStatus, text: item.text })
                            }}
                            selectedItem={orderStatus}
                        />
                    </TableCell>
                ) : (
                    <TableCell style={{ width: '15%' }} align="left">
                        {orderModel.statusForCustomer}
                    </TableCell>
                )}

                {props.actionButtons && (
                    <TableCell style={{ width: '5%' }} align="left">
                        <div className="action-menu">
                            {props.actionButtons.map((button, index) => {
                                return (
                                    <PopoverItem placement={button.tooltipPlacement} disabled={button.type !== 'button' && !orderModel.poAttachment} key={index} popoverContent={button.popoverContent}>
                                        {button.type === 'button' ? (
                                            <Button className={`action-button yellow-button ${button?.className}`}>
                                                <Icons type={button.icon} />
                                            </Button>
                                        ) : (
                                            <a rel="noreferrer" target="_blank" href={orderModel.poAttachment}>
                                                <Button
                                                    onClick={() => button.onClick(orderModel)}
                                                    disabled={orderModel.statusForCustomer !== 'RFQ Sent'}
                                                    className={`action-button ${button?.className}`}
                                                >
                                                    <Icons type={button.icon} />
                                                </Button>
                                            </a>
                                        )}
                                    </PopoverItem>
                                )
                            })}
                        </div>
                    </TableCell>
                )}
            </TableRow>
            <TableRow className="expand-row">
                <TableCell colSpan={12} className={ignoreDashClassname}>
                    <Collapse style={{ width: '100%' }} in={isOpen} timeout="auto" className={'collapse-detail'} unmountOnExit>
                        <OrderListExpandItem
                            shippingInfo={props.shippingInfo}
                            setModalInfo={props.setModalInfo}
                            handleEditShippingModal={props.handleEditShippingModal}
                            order={orderModel}
                            isQuote={props.isQuote}
                        />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default function CustomerOrderListTable(props: Props) {
    const [orderBy, setOrderBy] = useState('name')
    const [direction, setDirection] = useState(true)
    const [sortedData, setSortedData] = useState<OrderModel[]>([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    useEffect(() => {
        const newData = props.data.map((order) => ({
            ...order,
            price: calculateTotalPrice(order),
        }))
        setSortedData(arraySort(newData, orderBy, direction ? 'ascending' : 'descending'))
    }, [direction, orderBy, props.data])

    if (props.data.length === 0) return <NoDataInfo />

    return (
        <>
            <TableContainer className="monittor-table-container customer-order-table" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead status={props.statusInfo} actionButtons={props.actionButtons} orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orderModel: OrderModel) => {
                            const badgeBackgroundColor = Math.floor(Math.random() * 16777215)
                            return (
                                <Row
                                    badgeBackgroundColor={badgeBackgroundColor}
                                    orderStatusChangeHandler={props.orderStatusChangeHandler}
                                    key={orderModel.id}
                                    status={props.statusInfo}
                                    onClick={props.onClick}
                                    actionButtons={props.actionButtons}
                                    onLoading={props.onLoading}
                                    onCompleted={props.onCompleted}
                                    setModalInfo={props.setModalInfo}
                                    handleEditShippingModal={props.handleEditShippingModal}
                                    data={orderModel}
                                    isQuote={props.isQuote}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
