import { FC, useEffect, useState } from 'react'
import Icons, { IconType } from '../../Icon'
import { DocumentListModel } from '../../models/New/Document'
import StorageService from '../../services/NewServices/StorageService'
import DocumentService from '../../services/UiService/Document'
import FileInput from '../FileInput'
import { FileInputType } from '../FileInput/enum'
import Input from '../Input'
import Modal, { ModalProps } from '../Modal'
import './style.scss'

interface Props extends Omit<ModalProps, 'onClickPrimary'> {
    closeModal: () => void
    note?: string
    document: DocumentListModel
    onLoading: () => void
    onCompleted: () => void
    onSuccess: () => void
    onError: (error: Error) => void
}

const DocumentUpdaterModal: FC<Props> = (props) => {
    const [document, setDocument] = useState<DocumentListModel>(props.document)
    const [isURL, setIsURL] = useState(false)
    const uploadFileService = new StorageService()

    useEffect(() => {
        setDocument(props.document)
        setIsURL(props.document.type === 'Url')
    }, [props.document])

    const isValid = () => document.name && document.url

    const onClickPrimary = async () => {
        if (isValid()) {
            try {
                props.onLoading()
                await DocumentService.updateDocument({ documentId: document.id, name: document.name, url: document.url, type: document.type })
                props.onSuccess()
                props.closeModal()
            } catch (error) {
                props.onError(error as Error)
            } finally {
                props.onCompleted()
            }
        }
    }

    const onChangeFile = async (file: File) => {
        try {
            if (file) {
                props.onLoading()
                const uploadedFile = await uploadFileService.upload(file as File)
                setDocument((prevState) => ({ ...prevState, name: uploadedFile.name, type: uploadedFile.type, url: uploadedFile.url }))
            }
        } catch (error: any) {
            console.error(error)
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }

    const isChanged = () => JSON.stringify(props.document) !== JSON.stringify(document)

    return (
        <Modal
            className="update-documet-modal"
            visible={props.visible}
            title={props.title}
            primaryButtonText={props.primaryButtonText}
            onClickPrimary={onClickPrimary}
            secondaryButtonText={props.secondaryButtonText}
            onClickSecondary={props.closeModal}
            primaryDisabled={!isValid() || !isChanged()}
            buttonOrder={props.buttonOrder}
        >
            <div className="line">
                <Input placeholder="TITLE" value={document.name} onChange={(e) => setDocument((prevState) => ({ ...prevState, name: e.target.value }))} />
            </div>
            {!isURL && (
                <div className="line">
                    <FileInput
                        name="UPLOAD NEW AND REPLACE"
                        isFileUploaded
                        handleChange={(e) => onChangeFile(e)}
                        fileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                        inputType={FileInputType.DOCUMENT_UPLOAD}
                        multiple={false}
                    />
                </div>
            )}
            {isURL && (
                <div className="line">
                    <Input placeholder="LINK" value={document.url} onChange={(e) => setDocument((prevState) => ({ ...prevState, url: e.target.value }))} />
                </div>
            )}
            {props.note && (
                <div className="note-section">
                    <div className="info-icon">
                        <Icons type={IconType.Info} />
                    </div>
                    <div className="info-text">{props.note}</div>
                </div>
            )}
        </Modal>
    )
}

export default DocumentUpdaterModal
