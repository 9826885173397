import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Tab from '../../../components/Tab'
import Icons, { IconType } from '../../../Icon'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import DetailsTab from './Tabs/DetailsTab/DetailsTab'
import './index.scss'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import Button from '../../../components/Button'
import DocumentsTab from './Tabs/DocumentsTab/DocumentsTab'
import SearchInput from '../../../components/SearchInput'
import { DocumentListModel } from '../../../models/New/Document'
import { useNavigate } from 'react-router'
import ActivityTab from './Tabs/ActivityTab'
import OrderTab from './Tabs/OrderTab'
import { ContractOutputModel } from '../../../models/Ui/Contract'
import ContractService from '../../../services/UiService/Contract'
import { Switch, Tooltip } from '@mui/material'
import ThreeDotButton from '../../../components/ThreeDotButton'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import { CompanyType } from '../../../models/Ui/CompanyType'
import PopoverItem from '../../../components/PopoverItem'
import { ContractActionTypes } from '../CustomerDetail'

const tabItems: TabItem[] = [
    {
        id: Tabs.Details,
        name: 'Details',
    },
    {
        id: Tabs.Activities,
        name: 'Activities',
    },
    {
        id: Tabs.Documents,
        name: 'Documents',
    },
]

function ContractDetailForManufacturer() {
    let { id } = useParams()
    let [searchParams] = useSearchParams()
    const activeTabIndex = parseInt(searchParams.get('action') ? searchParams.get('action')!.toString() : '0')
    const { confirm } = useContext(ConfirmationContext)

    const [loading, setLoading] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(tabItems[activeTabIndex].id)
    const [contract, setContract] = useState<ContractOutputModel>()
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentListModel[]>([])
    const [documents, setDocuments] = useState<DocumentListModel[]>([])

    const [createDocumentModalVisible, setCreateDocumentModalVisible] = useState<boolean>(false)
    const navigate = useNavigate()

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Customers',
            url: '/customers',
        },
        {
            name: contract?.customer.name,
            url: '/customer/' + contract?.customer.id + '?activeTab=' + +Tabs.Overview,
        },
        {
            name: 'Contracts',
            url: '/customer/' + contract?.customer.id + '?activeTab=' + +Tabs.Contracts,
        },
        {
            name: contract?.title,
            url: '/contracts/' + contract?.id,
        },
    ]
    const getCallback = useCallback(async () => {
        try {
            setLoading(true)
            const contractService = ContractService
            const contractResponse = await contractService.get(id!)
            setContract(contractResponse)
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }, [id])

    useEffect(() => {
        getCallback()
    }, [getCallback])

    const onDocumentHandleSearch = (value: DocumentListModel[]) => {
        setFilteredDocuments(value)
    }

    const onErrorCallback = useCallback(async (error: any) => {
        toast.error(error)
    }, [])

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onSuccessCallback = useCallback(
        async (action: ContractActionTypes, data?: ContractOutputModel) => {
            switch (action) {
                case ContractActionTypes.UPDATE_CONTRACT:
                    getCallback()
                    break
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [contract]
    )

    const deleteHandler = async (id: string) => {
        confirm({
            modalTitle: 'Delete contract',
            contentText: 'Deleting this contract will also delete all activities related to it.  This action cannot be undone.  Are you sure you want to proceed?',
            primaryButtonText: 'Delete',
            func: async (successCallback, errCallback) => {
                try {
                    setLoading(true)
                    await ContractService.delete(id)
                    successCallback()
                    navigate(-1)
                } catch (error: any) {
                    errCallback(error)
                } finally {
                    setLoading(false)
                }
            },
        })
    }
    const rightMenuActions = [
        {
            text: 'Edit contract details',
            onClick: () => setEditModalVisible(true),
        },
        {
            text: 'Delete contract',
            onClick: () => deleteHandler(id!),
        },
    ]

    const getStatusBadge = (contract: ContractOutputModel) => {
        let contractStatus = 'Offer'
        if (contract.startDate) {
            const today = new Date().getTime()

            const from = new Date(contract.startDate).getTime()
            const to = new Date(contract.endDate!).getTime()

            if ((today >= from && today <= to) || today < from) {
                contractStatus = 'Active'
            } else if (today > to) {
                contractStatus = 'Expired'
            }
        }

        return <div className={'status-badge badge-' + contractStatus.toLowerCase()}>{contractStatus}</div>
    }

    return (
        <PageWrapper className="new-manufacturer-equipment-detail-page" breadcrumb={breadcrumb}>
            {loading && <Loading />}
            {contract && (
                <ContentBox
                    title={<div className="product-name">{contract && contract.title}</div>}
                    subTitle={
                        <div className="subtitle">
                            <div className="brand-container" onClick={() => navigate(`/customer/${contract?.customer.id}`)}>
                                <span className="title">{contract.customer.name}</span>
                            </div>

                            <span style={{ marginLeft: '5px', marginRight: '5px' }} className="dot" />

                            <span className="contract-number">
                                Contract Number: <b>{contract.contractNumber}</b>
                                <span className="add-button" onClick={() => setEditModalVisible(true)}>
                                    {!contract.contractNumber && '+add'}
                                </span>
                            </span>
                        </div>
                    }
                >
                    <div className="tab-container">
                        <Tab
                            activeTab={activeTab}
                            tabs={tabItems}
                            onTabClick={(id: number) => {
                                setActiveTab(id)
                                // setContract({ ...contract, note: noteRef.current || '' })
                            }}
                        />

                        {activeTab === Tabs.Documents && (
                            <div className="right-actions-documents">
                                <SearchInput data={documents} searchKeys={['id', 'name', 'type', 'description']} handleSearch={onDocumentHandleSearch} />
                                <Button className="create-document-button" onClick={() => setCreateDocumentModalVisible(true)}>
                                    <Icons type={IconType.PlusCircle} />
                                    <span>Add Document</span>
                                </Button>
                            </div>
                        )}

                        {activeTab === Tabs.Details && (
                            <div className="right-actions">
                                <Tooltip title={!contract.isVisibleToCustomer ? 'Hidden from customer' : 'Visible to customer'}>
                                    <Switch
                                        disabled={false}
                                        onClick={(e) => e.stopPropagation()}
                                        checked={contract.isVisibleToCustomer}
                                        onChange={(e) => {
                                            setLoading(true)

                                            const contractService = ContractService

                                            const updatedContract = {
                                                ...contract,
                                                isVisibleToCustomer: e.target.checked,
                                            }

                                            delete updatedContract?.documents
                                            contractService
                                                .update(contract.id, updatedContract)
                                                .then(() => {
                                                    setContract(updatedContract)
                                                    toast.success('Updated successfully!')
                                                })
                                                .finally(() => {
                                                    setLoading(false)
                                                })
                                        }}
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Tooltip>
                                {getStatusBadge(contract)}

                                <PopoverItem popoverContent="Actions">
                                    <ThreeDotButton actions={rightMenuActions} />
                                </PopoverItem>
                            </div>
                        )}
                    </div>

                    <DetailsTab
                        contract={contract}
                        visible={activeTab === Tabs.Details}
                        modalVisible={editModalVisible}
                        onModalOpen={() => setEditModalVisible(true)}
                        onModalClose={() => setEditModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                    <DocumentsTab
                        contract={contract}
                        visible={activeTab === Tabs.Documents}
                        modalVisible={createDocumentModalVisible}
                        onModalOpen={() => setCreateDocumentModalVisible(true)}
                        onModalClose={() => setCreateDocumentModalVisible(false)}
                        onSearch={onDocumentHandleSearch}
                        setDocuments={(documents: DocumentListModel[]) => setDocuments(documents)}
                        filteredDocuments={filteredDocuments}
                    />

                    {activeTab === Tabs.Activities && (
                        <ActivityTab
                            // contract={contract}
                            // activities={filteredActivities}
                            // setData={(data: ActivityModel[]) => {
                            //     setActivities(data)
                            //     setFilteredActivities(data)
                            // }}
                            // onModalOpen={() => setCreateActivityModalVisible(true)}
                            // onModalClose={() => setCreateActivityModalVisible(false)}
                            // modalVisible={createActivityModalVisible}
                            companyType={CompanyType.Manufacturer}
                            contractId={id}
                            customerId={contract.customer.id}
                        />
                    )}
                    <OrderTab visible={activeTab === Tabs.Orders} />
                </ContentBox>
            )}
        </PageWrapper>
    )
}

export default ContractDetailForManufacturer
