import { faList, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ReactNode, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'
import { toast } from 'react-toastify'
import EasyIcon from '../../../components/EasyIcon'
import FileInput from '../../../components/FileInput'
import Input from '../../../components/Input'
import Loading from '../../../components/Loading'
import Modal from '../../../components/Modal'
import { IconType } from '../../../Icon'
import { EquipmentModel, EquipmentPartsListModel } from '../../../models/New/Equipment'
import { dataUrltoFile } from '../../../utils/helpers'
import './EquipmentDetail.scss'

interface Props {
    visible: boolean
    drawingFileName?: string
    csvFileName?: string
    equipment: EquipmentModel
    isAssembly: boolean

    submit: (
        id: string,
        updatedCustomEquipment: EquipmentModel,
        image: File,
        partsList: EquipmentPartsListModel[],
        csvFiles: { customName: string; file: File; isRemoved: boolean }[],
        isChange: { file: boolean; pdfPreview: boolean; csv: boolean; fileNames: boolean },
        pdfPreview?: File
    ) => void
    onUpdatePartsList: (id?: string) => void
    closeModal: () => void
}

function EditEquipmentPartStoreModal(props: Props) {
    const [loading, setLoading] = useState<boolean>(false)
    const [drawing, setDrawing] = useState<File | null>(null)
    const [pdfPreview, setPdfPreview] = useState<File | null>(null)
    const [csvFiles, setCsvFiles] = useState<
        {
            customName: string
            file: File
            isRemoved: boolean
        }[]
    >([])
    const [isChange, setIsChange] = useState({
        file: false,
        pdfPreview: false,
        csv: false,
        fileNames: false,
    })
    const partList = useRef<EquipmentPartsListModel[]>(props.equipment && props.equipment.partLists ? props.equipment.partLists : [])
    const titles = useRef<string[]>(partList.current.length > 0 ? partList.current.map((p) => p.name) : [])

    async function deletePartsList(index: number, id?: string) {
        try {
            setLoading(true)
            props.equipment!.partLists.splice(index, 1)
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            props.onUpdatePartsList(id)
            setLoading(false)
        }
    }

    const isValidForm = () => {
        return (
            (drawing || (csvFiles && csvFiles.length > 0) || (props.csvFileName && props.csvFileName.length > 0) || (props.drawingFileName && props.drawingFileName.length > 0)) &&
            (isChange.csv || isChange.file || isChange.fileNames)
        )
    }

    const clearForm = () => {
        setDrawing(null)
        setCsvFiles([])
        setIsChange({ file: false, pdfPreview: false, csv: false, fileNames: false })
        titles.current = []
        partList.current = []
    }

    const clickPrimary = () => {
        for (let index = 0; index < partList.current.length; index++) {
            const part = partList.current[index]
            if (titles.current[index]) {
                part.name = titles.current[index]
            } /* else not needed */
        }
        const partsList = titles.current.map((t, index) => {
            return {
                name: t,
                parts: partList.current[index] ? partList.current[index].parts : [],
            }
        })

        props.submit(
            props.equipment?.id!,
            props.equipment,
            drawing!,
            partsList,
            csvFiles?.map((csvFile, index) => {
                return {
                    ...csvFile,
                    customName: titles.current[index],
                }
            })!,
            isChange,
            pdfPreview ? pdfPreview : undefined
        )
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    const onChangeFile = async (file: File) => {
        try {
            if (file) {
                if (file.type === 'application/pdf') {
                    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

                    const pdf = await pdfjs.getDocument({
                        url: URL.createObjectURL(file),
                    }).promise

                    const page = await pdf.getPage(1) // The first page only will be taken into account!

                    const viewport = page.getViewport({ scale: 1.5 })
                    const { width, height } = viewport
                    const canvas = document.createElement('canvas')
                    canvas.width = width
                    canvas.height = height
                    canvas.className = 'page'
                    await page.render({
                        canvasContext: canvas.getContext('2d')!,
                        viewport,
                    }).promise
                    const convertedFile = dataUrltoFile(canvas.toDataURL('image/png'), file.name + '.jpg')
                    setPdfPreview(convertedFile)
                    setDrawing(file)
                    setIsChange({ ...isChange, file: true, pdfPreview: true })
                } else {
                    setDrawing(file)
                    setIsChange({ ...isChange, file: true })
                }
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        }
    }

    const onChangeCsv = (fileList: FileList) => {
        let arrayFileList: any = [fileList]
        let files = []
        for (let index = 0; index < arrayFileList.length; index++) {
            files.push({ customName: '', file: arrayFileList[index] })
        }
        setCsvFiles(
            files.map((data, index) => {
                titles.current[partList.current.length + index] = data.file.name
                return {
                    customName: data.customName,
                    file: data.file,
                    isRemoved: false,
                }
            })
        )

        if (files.length === 0) {
            titles.current = titles.current.slice(0, partList.current.length)
        } /* else not needed */

        setIsChange({ ...isChange, csv: true })
    }

    function buildPreviousListTitles(list: EquipmentPartsListModel[]) {
        if (list && list.length > 0) {
            let inputs: ReactNode[] = []
            for (let index = 0; index < list.length; index++) {
                const data = list[index]
                inputs.push(
                    <Input
                        className="fix-margin"
                        key={index}
                        onChange={(e) => {
                            titles.current[index] = e.target.value && e.target.value.length !== 0 ? e.target.value : data.name
                            setIsChange({ ...isChange, fileNames: true })
                        }}
                        placeholder={data.name}
                        startIcon={faList}
                        endIcon={faTrash}
                        onClickEndIcon={() => {
                            deletePartsList(index, data.id)
                        }}
                    />
                )
            }
            return inputs
        }
    }

    function buildListTitles(list: { customName: string; file: File; isRemoved: boolean }[]) {
        if (list && list.length > 0) {
            let inputs: ReactNode[] = []
            for (let index = 0; index < list.length; index++) {
                const data = list[index]
                if (data.isRemoved) break
                inputs.push(
                    <Input
                        className="fix-margin"
                        key={index}
                        onChange={(e) => {
                            titles.current[index + partList.current.length] = e.target.value && e.target.value.length !== 0 ? e.target.value : data.file.name
                            setIsChange({ ...isChange, fileNames: true })
                        }}
                        placeholder={data.file?.name}
                        startIcon={faList}
                        /*endIcon={faTrash}
          onClickEndIcon={() => {
            list[index].isRemoved = true
            removeFromInputs(index)
          }}*/
                    />
                )
            }
            return inputs
        }
    }

    return (
        <>
            {loading && <Loading />}
            <Modal
                className="edit-equipment-part-store-modal"
                visible={props.visible}
                title={props.drawingFileName ? 'Edit parts store' : 'Create parts store'}
                primaryButtonText="Submit"
                onClickPrimary={clickPrimary}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <span className="heading">Upload one drawing, preferably an exploded view, of your equipment</span>
                <FileInput
                    headerIcon={<EasyIcon className="icon" icon={IconType.Drawing} />}
                    fileInputText="UPLOAD DRAWING"
                    isFileUploaded={!!props.drawingFileName}
                    name={drawing ? drawing.name : props.drawingFileName ? props.drawingFileName : undefined}
                    handleChange={(e) => onChangeFile(e as File)}
                    fileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                />
                <span className="heading">Upload one or more lists relevant to your equipment. Name the lists as you would want your customers to see them.</span>
                <FileInput headerIcon={<EasyIcon className="icon" icon={IconType.List} />} fileInputText="UPLOAD LIST" handleChange={(e) => onChangeCsv(e as FileList)} fileTypes={['csv']} />
                <span className="template-text">
                    IMPORTANT: You can use{' '}
                    <a href="https://docs.google.com/spreadsheets/d/1hlFSrwoUEoK45CoA8L8WHol1UmKHes2gQqojKh0UEpw" target="_blank" rel="noreferrer">
                        our template here.
                    </a>
                </span>
                {props.equipment && buildPreviousListTitles(props.equipment && props.equipment.partLists ? props.equipment.partLists : [])}
                {buildListTitles(csvFiles)}
            </Modal>
        </>
    )
}

export default EditEquipmentPartStoreModal
