import React from 'react'
import { AccountManagerModel } from '../../../../../../models/Ui/Company'
import './Contact.scss'

interface Props {
    representative?: AccountManagerModel
}
function Contact(props: Props) {
    return (
        <div className="contact-container">
            <div className="contact-item">
                <div className="contact-title">Contact Us</div>
                <div className="contact-body">
                    <button>Request Urgent Service</button>
                </div>
            </div>
            {props.representative && (
                <div className="contact-item">
                    <div className="contact-title">Contact Representative</div>
                    <div className="contact-body">
                        <div className="contact-info">
                            <div className="top">
                                <div className="name">{props.representative.firstName + ' ' + props.representative.lastName}</div>
                                <div className="dot" />
                                <div className="phone">{props.representative?.phone}</div>
                            </div>
                            <div className="email">{props.representative.email}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Contact
