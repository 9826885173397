import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import Icons, { IconType } from '../../../Icon'
import Checkbox from '../../Checkbox'
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip'
import './style.scss'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export interface ChecklistItemType {
    text: string
    checked: boolean
}

interface Props {
    item: ChecklistItemType
    onChange: (item: ChecklistItemType, entered: boolean) => void
    onClickTrashIcon: () => void
    onEditMode: boolean
    onChangeEditMode: (editMode: boolean) => void
    editItemWithoutButton: boolean
}

const ChecklistItem: FC<Props> = ({ item, onChange, onClickTrashIcon, onEditMode, onChangeEditMode, editItemWithoutButton }) => {
    const [text, setText] = useState(item.text)
    const textElementRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        setText(item.text)
    }, [item.text])

    const onEdit = (entered = false) => {
        if (onEditMode && text) {
            onChange({ ...item, text }, entered)
            onChangeEditMode(false)
        } else {
            onChangeEditMode(true)
        }
    }

    const onCancel = () => {
        if (item.text) {
            onChangeEditMode(false)
            setText(item.text)
        } else {
            onClickTrashIcon()
        }
    }

    const onCheck = (val: boolean) => {
        if (text && !onEditMode) {
            onChange({ ...item, checked: val }, false)
        }
    }

    const onClickItem = () => {
        if (editItemWithoutButton) {
            onEdit()
        }
    }

    return (
        <div className="checklistItemContainer">
            <Tooltip title={item.checked ? 'Mark as incomplete' : 'Mark as complete'} placement="right">
                <span>
                    <Checkbox checked={item.checked} onChange={(val) => onCheck(val)} />
                </span>
            </Tooltip>
            {onEditMode ? (
                <div className="listItemInputContainer">
                    <input
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onEdit(true)
                            }
                        }}
                        type="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        autoFocus
                    />
                </div>
            ) : (
                <OverflowTooltip popoverContent={item.text} overflowTipRef={textElementRef}>
                    <div key={item.text} ref={textElementRef} className="listItemTextContainer" onClick={onClickItem}>
                        {item.text}
                    </div>
                </OverflowTooltip>
            )}
            <div className="listItemButtonContainer">
                {onEditMode ? (
                    <Fragment>
                        <Tooltip title="Save" placement="left">
                            <span>
                                <Icons type={IconType.Check} onClick={onEdit} />
                            </span>
                        </Tooltip>
                        <Tooltip key="cancel" title="Cancel" placement="left">
                            <span>
                                <Icons type={IconType.Close} color="gray" onClick={item.text.length > 0 ? onCancel : onClickTrashIcon} />
                            </span>
                        </Tooltip>
                    </Fragment>
                ) : (
                    <Fragment>
                        {!editItemWithoutButton && (
                            <Tooltip title="Edit" placement="left">
                                <span>
                                    <Icons type={IconType.Pen} onClick={onEdit} />
                                </span>
                            </Tooltip>
                        )}
                        <Tooltip key="delete" title="Delete" placement="right">
                            <span className="delete-todo">
                                <FontAwesomeIcon icon={faClose} onClick={onClickTrashIcon} />
                            </span>
                        </Tooltip>
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default ChecklistItem
