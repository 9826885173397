import { useState } from 'react'
import { TeamActionTypes } from '../..'
import Checkbox from '../../../../components/Checkbox'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import { CompanyModel } from '../../../../models/Ui/Company'
import { CreateUserModel } from '../../../../models/Ui/User'
import { UserRoleType } from '../../../../models/Ui/UserRoleType'
import UserService from '../../../../services/UiService/User'
import { isValidEmail } from '../../../../utils/helpers'
import './style.scss'
import { useEffect } from 'react'
import { MaxCharacter } from '../../../../models/enums'
import BasicDropdown from '../../../../components/Dropdown/BasicDropdown'

const roles = [
    {
        id: 1,
        text: UserRoleType.Admin,
    },
    {
        id: 2,
        text: UserRoleType.Manager,
    },
]

interface Props {
    company: CompanyModel
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function CreateTeamMemberModal(props: Props) {
    const { onLoading, onSuccess, onCompleted } = props

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [jobTitle, setJobTitle] = useState<string>('Admin')
    const [role, setRole] = useState<UserRoleType>(roles[0].text)
    const [isQuoteRequestReceiver, setIsQuoteRequestReceiver] = useState<boolean>(false)
    const [selectedRole, setSelectedRole] = useState({ id: '', text: '' })

    async function createTeamMemberHandler() {
        try {
            onLoading()
            const userService = new UserService()

            const data: CreateUserModel = {
                email: email,
                phone: phone,
                firstName: firstName,
                lastName: lastName,
                role: role,
                jobTitle: jobTitle,
                isQuoteRequestReceiver: isQuoteRequestReceiver,
            }
            const createUserResponse = await userService.create(data)
            onSuccess(TeamActionTypes.CREATE_TEAM_MEMBER, createUserResponse)
            props.closeModal()
            clearForm()
        } catch (error: any) {
            // onError(error) // Display error message because there is already an error message
        } finally {
            onCompleted()
        }
    }

    const isValidForm = () => {
        return firstName.length > 0 && lastName.length > 0 && email.length > 0 && isValidEmail(email)
    }

    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setJobTitle('Admin')
        setRole(roles[0].text)
        setIsQuoteRequestReceiver(false)
    }

    const clickPrimary = () => {
        createTeamMemberHandler()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }
    useEffect(() => {
        if (props.company.type !== 'customer' && roles.length < 3) {
            roles.push({
                id: 3,
                text: UserRoleType.Technician,
            })
        }
    }, [props.company.type])

    return (
        <Modal
            className="create-team-member-modal"
            visible={true}
            title="Add new team member"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="FIRST NAME" required max={MaxCharacter.name} />
                <Input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="LAST NAME" required max={MaxCharacter.name} />
            </div>

            <div className="modal-line">
                <Input value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder="JOB TITLE / POSITION" />
                <BasicDropdown
                    label="ROLE"
                    menuItems={roles}
                    onChange={(data) => {
                        setRole(data.text as UserRoleType)
                        setSelectedRole(data)
                    }}
                    selectedItem={selectedRole}
                />
            </div>
            <div className="modal-line">
                <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="COMPANY EMAIL ADDRESS" required max={MaxCharacter.companyName} />
                <Input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="PHONE" max={MaxCharacter.phone} />
            </div>
            <div className="modal-line">
                <div className="flex-row flex-start flex-gap-0-5">
                    <Checkbox checked={isQuoteRequestReceiver} onChange={() => setIsQuoteRequestReceiver(!isQuoteRequestReceiver)} />
                    Receives requests for quotes and purchase orders
                </div>
            </div>
        </Modal>
    )
}

export default CreateTeamMemberModal
