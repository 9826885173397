import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { CompanyManufacturerModel, CustomerDetailModel, CustomerEquipmentsModel, UpdateCompanyCustomer } from '../../models/New/Customer'
import { ActivityCustomerListModel, CompanyDashboardModel, CompanyModel, CustomerVerificationModel, UpdateCompanyModel } from '../../models/Ui/Company'
import { LinkToEquipmentModel } from '../../models/Ui/Customer'
import { ProductBasicDto } from './../../models/Ui/Product'

export default class CompanyService {
    async getCompany(): Promise<CompanyModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.Company, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async update(data: UpdateCompanyModel): Promise<UpdateCompanyModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.Company, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error updating company')
        }
    }

    async createCustomer(data: UpdateCompanyCustomer): Promise<CustomerDetailModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CompanyCustomer, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error creating company')
        }
    }
    async updateCustomer(data: UpdateCompanyCustomer, id: string): Promise<CustomerDetailModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateCompanyCustomer + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error updating company')
        }
    }

    async getManufacturers(): Promise<CompanyManufacturerModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetManufacturers, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get manufacturers error')
        }
    }
    async isMailExist(email: string): Promise<any> {
        try {
            return await MonitorApi.get(`${ServiceFunctionLookupTable.isMailExist}/${email}`, {})
        } catch (error: any) {
            console.error(error)
            throw new Error('Get mail error')
        }
    }

    async inviteCustomer(customerId: string): Promise<boolean> {
        try {
            const response = await MonitorApi.post(`${ServiceFunctionLookupTable.InviteCustomer}${customerId}`, {}, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async remindeCustomerInvite(customerId: string): Promise<boolean> {
        try {
            const response = await MonitorApi.post(`${ServiceFunctionLookupTable.RemindeCustomerInvite}${customerId}`, {}, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async deleteCustomer(customerId: string): Promise<void> {
        try {
            await MonitorApi.delete(`${ServiceFunctionLookupTable.CustomerForManufacturer}/${customerId}`, {})
        } catch (error: any) {
            console.error(error)
            throw new Error('Create customer error')
        }
    }

    async removeAccess(customerId: string): Promise<void> {
        try {
            await MonitorApi.delete(`company/${customerId}/remove-access`, {})
        } catch (error: any) {
            console.error(error)
            throw new Error('Create customer error')
        }
    }

    async customerVerification(params: CustomerVerificationModel): Promise<any> {
        try {
            return await MonitorApi.put(ServiceFunctionLookupTable.CustomerVerification, params, {})
        } catch (error: any) {
            console.error(error)
            throw new Error('Create customer error')
        }
    }

    async getCustomersForActivity(): Promise<ActivityCustomerListModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetCustomersForActivity, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get customers error')
        }
    }
    async getEquipmentCount(id: string): Promise<number> {
        try {
            const response = await MonitorApi.get(`/company/${id}/equipment-count`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get customers error')
        }
    }
    async getEquipmentList(id: string): Promise<CustomerEquipmentsModel[]> {
        try {
            const response = await MonitorApi.get(`/company/${id}/equipment-list`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get equipments error')
        }
    }
    async getProductList(id: string): Promise<ProductBasicDto[]> {
        try {
            const response = await MonitorApi.get(`/company/${id}/product-list`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get products error')
        }
    }
    async getCompanyDashboard(id: string): Promise<CompanyDashboardModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CompanyDashboard, { id: id }, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async linkEquipment(param: LinkToEquipmentModel): Promise<boolean> {
        try {
            await MonitorApi.post(ServiceFunctionLookupTable.LinkToEquipment, param, {})
            return true
        } catch (error: any) {
            throw new Error(error)
        }
    }
}
