import { useEffect, useState } from 'react'
import { TeamActionTypes } from '../..'
import Checkbox from '../../../../components/Checkbox'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import PopoverItem from '../../../../components/PopoverItem'
import { MaxCharacter } from '../../../../models/enums'
import { CompanyModel } from '../../../../models/Ui/Company'
import { UpdateUserModel, UserModel } from '../../../../models/Ui/User'
import { UserRoleType } from '../../../../models/Ui/UserRoleType'
import UserService from '../../../../services/UiService/User'
import { isValidEmail } from '../../../../utils/helpers'
import './style.scss'
import BasicDropdown from '../../../../components/Dropdown/BasicDropdown'
import { IDropdownItem } from './../../../../components/Dropdown/BasicDropdown/index'

const roles = [
    {
        id: 1,
        text: UserRoleType.Admin,
    },
    {
        id: 2,
        text: UserRoleType.Manager,
    },
]

interface Props {
    loginUser: UserModel | undefined
    company: CompanyModel
    user: UserModel
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function UpdateTeamMemberModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [jobTitle, setJobTitle] = useState<string>('')
    const [role, setRole] = useState<IDropdownItem>(roles[0])
    const [isQuoteRequestReceiver, setIsQuoteRequestReceiver] = useState<boolean>(false)
    const [isDisabledAccount, setIsDisabledAccount] = useState<boolean>(false)

    useEffect(() => {
        setFirstName(props.user.firstName)
        setLastName(props.user.lastName)
        setEmail(props.user.email)
        setPhone(props.user.phone)
        setJobTitle(props.user.jobTitle)
        setRole(roles.find((item) => item.text === props.user.role) ?? roles[0])
        setIsQuoteRequestReceiver(props.user.isQuoteRequestReceiver)
        setIsDisabledAccount(props.user.isDisabled)
    }, [props.user.email, props.user.firstName, props.user.lastName, props.user.isQuoteRequestReceiver, props.user.jobTitle, props.user.role, props.user.isDisabled, props.user.phone])

    useEffect(() => {
        if (props.company.type !== 'customer' && roles.length < 3) {
            roles.push({
                id: 3,
                text: UserRoleType.Technician,
            })
        }
    }, [props.company.type])

    async function updateTeamMemberHandler() {
        try {
            onLoading()
            const userService = new UserService()

            const data: UpdateUserModel = {
                id: props.user.id,
                firstName: firstName,
                email: email,
                lastName: lastName,
                phone: phone,
                role: role.text as UserRoleType,
                jobTitle: jobTitle,
                isQuoteRequestReceiver: isQuoteRequestReceiver,
                isDisabled: isDisabledAccount,
            }
            const updateUserResponse = await userService.update(data)
            onSuccess(TeamActionTypes.UPDATE_TEAM_MEMBER, updateUserResponse)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return firstName.length > 0 && lastName.length > 0 && email.length > 0 && isValidEmail(email)
    }

    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setJobTitle('')
        setRole(roles[0])
        setIsQuoteRequestReceiver(false)
    }

    const clickPrimary = () => {
        updateTeamMemberHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }
    const isDisabledButton = () => {
        return true
    }

    return (
        <Modal
            className="update-team-member-modal"
            visible={true}
            title="Edit team member"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="FIRST NAME" required max={MaxCharacter.name} />
                <Input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="LAST NAME" required max={MaxCharacter.name} />
            </div>
            <div className="modal-line">
                <Input disabled={isDisabledButton()} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="COMPANY EMAIL ADDRESS" required max={MaxCharacter.email} />
                <Input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="PHONE" max={MaxCharacter.phone} />
            </div>
            <div className="modal-line">
                <Input value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder="JOB TITLE / POSITION" />
                <PopoverItem
                    disabled={!props.user.isPrimary}
                    popoverContent={'You cannot change your role on Monittor unless you transfer the company’s account ownership.  Contact us at accounts@monittor.com to make a change request.'}
                >
                    <BasicDropdown disabled={props.user.isPrimary} label="ROLE" menuItems={roles} onChange={(data) => setRole(data)} selectedItem={role} />
                </PopoverItem>
            </div>
            <div className="modal-line">
                <div className={`flex-row flex-start flex-gap-0-5 ${props.loginUser?.role !== UserRoleType.Admin && 'disabled'}`}>
                    <Checkbox disabled={props.loginUser?.role !== UserRoleType.Admin} checked={isDisabledAccount} onChange={() => setIsDisabledAccount(!isDisabledAccount)} />
                    Disable account
                </div>
            </div>
            <div className="modal-line">
                <div className="flex-row flex-start flex-gap-0-5">
                    <Checkbox checked={isQuoteRequestReceiver} onChange={() => setIsQuoteRequestReceiver(!isQuoteRequestReceiver)} />
                    Receives requests for quotes and purchase orders
                </div>
            </div>
        </Modal>
    )
}

export default UpdateTeamMemberModal
