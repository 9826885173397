import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading'
import AuthService from '../../services/UiService/Auth';

const authService = new AuthService();

function Inpersonate() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const login = async (token: string) => {
      try {
        await authService.loginWithToken(token);
        navigate('/')
      } catch {
        toast.error('Invalid token');
      }
    }
    const token  = params.get('token');
    if (token)
      login(token);
    else 
      toast.error('Bad Request');
  }, [navigate, params])
  
  return (
    <Loading />
  )
}

export default Inpersonate;