import { faMinus, faPlus, faPlusCircle, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Icons, { IconType } from '../../Icon'
import './ZoomButton.scss'
import { useState } from 'react'
import PopoverItem from '../PopoverItem'

interface Props {
    onClickClose?: () => void
    onClickPlus: () => void
    onClickMinus: () => void
    onClickReset: () => void
    url?: any
    fileName?: string
}

function ZoomButton(props: React.PropsWithChildren<Props>) {
    const [spinner, setSpinner] = useState<boolean>(false)

    const downloadPdf = async () => {
        setSpinner(true)
        await fetch(props.url, {
            method: 'GET',
        })
            .then((response) => response.blob())
            .then((blob) => {
                var url = window.URL.createObjectURL(blob)
                var a = document.createElement('a')
                a.href = url
                a.download = props.fileName ? props.fileName : 'monittor'
                document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click()
                a.remove() //afterwards we remove the element again
            })
            .finally(() => setSpinner(false))
    }
    return (
        <div className={`zoom-button ${props.onClickClose ? 'zoom-button-with-close' : ''}`}>
            <div className="zoom-button-left">
                <PopoverItem popoverContent="Zoom out">
                    <div className="zoom-button-minus" onClick={props.onClickMinus}>
                        <FontAwesomeIcon className="zoom-button-icon" icon={faMinus} />
                    </div>
                </PopoverItem>
                <span className="zoom-label">Zoom</span>
                <PopoverItem popoverContent="Zoom in">
                    <div className="zoom-button-plus" onClick={props.onClickPlus}>
                        <FontAwesomeIcon className="zoom-button-icon" icon={faPlus} />
                    </div>
                </PopoverItem>
            </div>
            <PopoverItem popoverContent="Reset">
                <div className="zoom-button-reset" onClick={props.onClickReset}>
                    <FontAwesomeIcon className="zoom-button-icon" icon={faRotateRight} />
                </div>
            </PopoverItem>

            {props.url && (
                <button className="zoom-button-download" disabled={spinner} onClick={() => downloadPdf()}>
                    <Icons className="zoom-button-icon" type={IconType.Download} />
                </button>
            )}
            {props.onClickClose && (
                <div className="zoom-button-close" onClick={props.onClickClose}>
                    <FontAwesomeIcon className="zoom-button-icon close-icon" icon={faPlusCircle} />
                </div>
            )}
        </div>
    )
}

export default ZoomButton
