import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import SearchDropdown from '../../../components/SearchDropdown'
import { CustomerSummaryModel } from '../../../models/Ui/Customer'
import { buildShortName } from '../../../utils/helpers'
import './MasterCatalog.scss'
import './SharedWithCustomersModal.scss'

interface Props {
  visible: boolean
  customers: CustomerSummaryModel[]
  assignedCustomers: CustomerSummaryModel[]
  shareWithCustomers: (
    pushCustomerIds: string[],
    pullCustomerIds: string[]
  ) => void
  closeModal: () => void
}

function ShareWithCustomersModal(props: Props) {
  const [foundCustomers, setFoundCustomers] = useState<CustomerSummaryModel[]>(
    []
  )
  const [searchText, setSearchText] = useState('')
  const [pushCustomerIds, setPushCustomerIds] = useState<string[]>([])
  const [pullCustomerIds, setPullCustomerIds] = useState<string[]>([])
  const [temporaryCustomers, setTemporaryCustomers] = useState<
    CustomerSummaryModel[]
  >([])

  useEffect(() => {
    if (props.customers) {
      setFoundCustomers(
        props.customers.filter(
          (customer) =>
            customer.email.includes(searchText) &&
            props.assignedCustomers
              .map((customer) => customer.id)
              .includes(customer.id) === false &&
            !pushCustomerIds.includes(customer.id)
        )
      )
    } /* else not needed */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    if (props.customers && pushCustomerIds.length > 0) {
      const temporaryData: CustomerSummaryModel[] = pushCustomerIds.map(
        (customerId) => {
          return props.customers.find((customer) => customer.id === customerId)!
        }
      )

      setTemporaryCustomers([...temporaryData!])
    } else {
      setTemporaryCustomers([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushCustomerIds])

  const isValidForm = () => {
    return pushCustomerIds.length > 0 || pullCustomerIds.length > 0
  }

  const clearForm = () => {
    setSearchText('')
    setFoundCustomers([])
    setPushCustomerIds([])
    setPullCustomerIds([])
    setTemporaryCustomers([])
  }

  const clickPrimary = () => {
    props.shareWithCustomers(pushCustomerIds, pullCustomerIds)
    clearForm()
  }

  const onSecondary = () => {
    clearForm()
    props.closeModal()
  }

  function searchTextChangeHandler(searchText: string) {
    setSearchText(searchText)
  }

  function onClickCustomer(customerId: string) {
    if (pushCustomerIds.includes(customerId) === false) {
      setPushCustomerIds([...pushCustomerIds, customerId])
    } /* else not needed */
    setSearchText('')
  }

  return (
    <Modal
      className="share-with-customers-modal"
      visible={props.visible}
      title="Link Customers"
      primaryButtonText="Save Changes"
      onClickPrimary={clickPrimary}
      primaryDisabled={!isValidForm()}
      secondaryButtonText="Cancel"
      onClickSecondary={onSecondary}
    >
      <div className="modal-line">
        {foundCustomers && (
          <SearchDropdown
            onSearchTextChange={searchTextChangeHandler}
            items={foundCustomers.map((customer) => {
              return {
                id: customer.id,
                text: customer.email,
                onClick: onClickCustomer,
              }
            })}
          />
        )}
      </div>
      {props.assignedCustomers && (
        <div className="share-modal-assigned-user-container">
          {temporaryCustomers.map((customer, index) => {
            return (
              <div className="share-modal-assigned-user-item" key={index}>
                <div>
                  <h5 className={'share-modal-name temporary'}>
                    {customer.name}
                  </h5>
                  <span className={'share-modal-email'}>{customer.email}</span>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      if (pushCustomerIds.includes(customer.id) === true) {
                        setPushCustomerIds(
                          pushCustomerIds.filter(
                            (customerId) => customerId !== customer.id
                          )
                        )
                      } /* else not needed */
                    }}
                    className="share-modal-button"
                  >
                    <FontAwesomeIcon
                      className="share-modal-cross-icon"
                      icon={faPlus}
                    />
                    <span className="share-modal-button-text">
                      Remove Customer
                    </span>
                  </Button>
                </div>
              </div>
            )
          })}
          {props.assignedCustomers.map((customer, index) => {
            return (
              <div className="share-modal-assigned-user-item" key={index}>
                <div>
                  <h5
                    className={`share-modal-name ${
                      pullCustomerIds.includes(customer.id)
                        ? 'removed-item'
                        : ''
                    }`}
                  >
                    {buildShortName(customer.email)}
                  </h5>
                  <span
                    className={`share-modal-email ${
                      pullCustomerIds.includes(customer.id)
                        ? 'removed-item'
                        : ''
                    }`}
                  >
                    {customer.email}
                  </span>
                </div>
                <div>
                  {pullCustomerIds.includes(customer.id) === true ? (
                    <Button
                      onClick={() => {
                        if (pullCustomerIds.includes(customer.id) === true) {
                          setPullCustomerIds([
                            ...pullCustomerIds.filter(
                              (pullCustomerId) => pullCustomerId !== customer.id
                            ),
                          ])
                        } /* else not needed */
                      }}
                      className="share-modal-button"
                    >
                      <FontAwesomeIcon
                        className="share-modal-undo-icon"
                        icon={faUndo}
                      />
                      <span className="share-modal-button-text">Undo</span>
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        if (pullCustomerIds.includes(customer.id) === false) {
                          pullCustomerIds.push(customer.id)
                          setPullCustomerIds([...pullCustomerIds])
                        } /* else not needed */
                      }}
                      className="share-modal-button"
                    >
                      <FontAwesomeIcon
                        className="share-modal-cross-icon"
                        icon={faPlus}
                      />
                      <span className="share-modal-button-text">
                        Remove Customer
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </Modal>
  )
}

export default ShareWithCustomersModal
