import React, { PropsWithChildren, useContext } from 'react'
import Icons, { IconType } from '../../../../Icon'
import { ICartInformation, TechnicianViewType } from '../../../../store/models'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import './PurchaseTitle.scss'
import { useNavigate } from 'react-router'
import { SearchPartContext } from '../../../../store/SearchPartContext'

interface Props {
    title?: string
    disabled?: boolean
}

function PurchaseTitle(props: PropsWithChildren<Props>) {
    const checkoutContext = useContext(CheckoutContext)
    const searchPartContext = useContext(SearchPartContext)

    const navigate = useNavigate()
    const handleResetContext = () => {
        checkoutContext.handleCartInformation({
            quickCartShow: false,
            technicianViewType: TechnicianViewType.None,
            purchaseForCustomerId: '',
            purchaseForCustomerName: '',
        } as ICartInformation)
        checkoutContext.setQuickCartInfo({
            id: '',
            name: '',
        })
        searchPartContext.reset()
        checkoutContext.reset()
        checkoutContext.quickCartReset()
    }
    return (
        <div className={`page-container ${!props.disabled && 'border'}`}>
            {!props.disabled && (
                <div className="header">
                    <div
                        className="close-button"
                        onClick={async () => {
                            await checkoutContext.handleCartInformation({
                                quickCartShow: false,
                                technicianViewType: TechnicianViewType.None,
                                purchaseForCustomerId: '',
                                purchaseForCustomerName: '',
                            } as ICartInformation)

                            handleResetContext()
                            navigate('/')
                        }}
                    >
                        <Icons type={IconType.Close} />
                    </div>
                    {props.title && <span className="title">Exit cart without saving</span>}
                </div>
            )}
            <div className="content">{props.children}</div>
        </div>
    )
}
export default PurchaseTitle
