module.exports = {
    "apiKey": "AIzaSyABto88yUIFxPp9o0vAqKLwzSV5QZHRr9k",
    "authDomain": "monittor-dev.firebaseapp.com",
    "projectId": "monittor-dev",
    "storageBucket": "monittor-dev.appspot.com",
    "messagingSenderId": "7374437549",
    "appId": "1:7374437549:web:922b9643312b778902da24",
    "measurementId": "G-J2BYTSCLVC",
    "hosting": {
        "public": "public",
        "ignore": [
            "firebase.json",
            "**/.*",
            "**/node_modules/**"
        ],
        "rewrites": [{
            "source": "**",
            "destination": "/index.html"
        }]
    },
    "firestore": {
        "rules": "firestore.rules",
        "indexes": "firestore.indexes.json"
    }
}
