import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { Color } from '../../../../models/enums'
import { arrayIterator, arraySort, getRandomColorFromPalette, uniqueObjectArray } from '../../../../utils/helpers'
import Icons, { IconType } from '../../../../Icon'
import './style.scss'
import Icon from '../../../../Icon'
import { FC, ReactNode, useEffect } from 'react'
import { Tooltip, Switch, Collapse, IconButton } from '@mui/material'
import { ActivityEquipmentModel, ActivityModel, ActivityProductModel } from '../../../../models/Ui/Activity'
import { FileModel } from '../../../../services/NewServices/StorageService'
import PopoverItem from '../../../../components/PopoverItem'
import dayjs from 'dayjs'
import { colorPallette } from '../../../../utils/constants'
import { ignoreDashClassname } from '../../../../hooks/useTableHook'
import NoDataInfo from '../../../../components/NoDataInfo'
import { ContractBasicDto } from '../../../../models/Ui/Contract'
import { ActivityAssigneListModel } from '../../../../models/Ui/User'

export interface ActivityTableDataModel {
    recurring: boolean
    isWorkOrder: boolean
    title: string
    customer: string
    assignee: string
    status: string
    dueDate: ReactNode | string
    opportunity: string
    id: string
    groupId: string
    action?: ReactNode
    isVisibleToCustomer?: boolean
    description?: string
    attachments: string
    products?: ActivityProductModel[]
    equipments?: ActivityEquipmentModel[]
    type: string
    productId?: string
    productName?: string
    productPartNumber?: string
    productQuantity?: number
    equipmentId?: string
    equipmentName?: string
    customerCompanyName?: string
    assigneeColor?: string
    assigneeShortName?: string
    assigneeFirstName?: string
    contractId?: string
    contract?: ContractBasicDto
}

export enum HeadCellType {
    Empty,
    Title,
    Customer,
    Assignee,
    Status,
    DueDate,
    Action,
    ProductWithPartNumber,
    Quantity,
    TaskWithCustomer,
    Equipment,
    Product,
    PartNumber,
    Task,
}

export interface AssigneeWithColorModel {
    assigneeId: string
    color: string
    assigneeName: string
}

export interface HeadCell {
    id: keyof ActivityTableDataModel | ''
    label: string
    type: HeadCellType
    width?: string
}

interface ActivityListTableProps {
    onHandleClick?: (id: string, groupId: string, isPartial?: boolean) => any
    sortKey?: keyof ActivityTableDataModel
    data: ActivityTableDataModel[]
    onChangeVisibility?: (activityId: string, visible: boolean) => void
    isManufacturer?: boolean
    highlightedRowId?: string
    columns: HeadCell[]
    isExpendableRow?: boolean
    selectedProductId?: string
    selectedEquipmentId?: string
    onClickProductAndEquipment?: (productId: string, equipmentId: string) => void
}

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    const { disable, active, direction, children, onclick } = props

    return !disable ? (
        <div className="sorting-menu">{children}</div>
    ) : (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            {disable && <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />}
        </div>
    )
}

interface TableHeadType {
    orderBy: string
    setOrderBy: React.Dispatch<React.SetStateAction<string>>
    direction: boolean
    setDirection: React.Dispatch<React.SetStateAction<boolean>>
    columns: HeadCell[]
}

export const activityTableColumns: HeadCell[] = [
    {
        id: '',
        label: '',
        type: HeadCellType.Empty,
    },
    {
        id: 'title',
        label: 'Title',
        type: HeadCellType.Title,
    },
    {
        id: 'customer',
        label: 'Customer',
        type: HeadCellType.Customer,
    },
    {
        id: 'assignee',
        label: 'Assignee',
        type: HeadCellType.Assignee,
    },
    {
        id: 'status',
        label: 'Status',
        type: HeadCellType.Status,
    },
    {
        id: 'dueDate',
        label: 'Due Date',
        type: HeadCellType.DueDate,
    },
    {
        id: 'action',
        label: '',
        type: HeadCellType.Action,
    },
    {
        id: 'productName',
        label: 'Product',
        type: HeadCellType.ProductWithPartNumber,
    },
    {
        id: 'productName',
        label: 'Product',
        type: HeadCellType.Product,
    },
    {
        id: 'productPartNumber',
        label: 'Part Number',
        type: HeadCellType.PartNumber,
    },
    {
        id: 'productQuantity',
        label: 'Quantity',
        type: HeadCellType.Quantity,
    },
    {
        id: 'title',
        label: 'Activity',
        type: HeadCellType.TaskWithCustomer,
    },
    {
        id: 'title',
        label: 'Activity',
        type: HeadCellType.Task,
    },
    {
        id: 'equipmentName',
        label: 'Equipment',
        type: HeadCellType.Equipment,
    },
]

const iteratedColorPallette = arrayIterator(colorPallette, 100)

export const useUniqueAssigneesWithColorsSetter = (activities: ActivityModel[], setUniqueAssigneeIdsWithColors: React.Dispatch<React.SetStateAction<AssigneeWithColorModel[]>>) => {
    useEffect(() => {
        if (activities.length > 0 && iteratedColorPallette.length > 0) {
            const uniqueAssignees = uniqueObjectArray(activities.map((child) => ({ assigneeId: child?.assignee?.id, assigneeName: `${child?.assignee?.firstName} ${child?.assignee?.lastName}` })))
            setUniqueAssigneeIdsWithColors(uniqueAssignees.filter((child) => child.assigneeId != null).map((child, index) => ({ ...child, color: iteratedColorPallette[index] })))
        }
    }, [activities, setUniqueAssigneeIdsWithColors])
}

export const assigneeColorSetter = (uniqueAssigneesWithColors: AssigneeWithColorModel[], activity: ActivityModel) =>
    uniqueAssigneesWithColors?.find((assignee) => assignee?.assigneeId === activity?.assignee?.id)?.color ?? getRandomColorFromPalette()

export const assigneeShortNameSetter = (assignee: ActivityAssigneListModel) =>
    assignee && (assignee?.firstName?.substring(0, 1)?.toUpperCase() ?? '') + (assignee?.lastName?.substring(0, 1)?.toUpperCase() ?? '')

export const assigneeFirstNameSetter = (activity: ActivityModel) => activity.assignee && activity?.assignee?.firstName?.slice(0, 5) + '...'

export const filterAssignees = (item: ActivityModel, showAllAsignees: boolean, selectedAssigneeIds: string[]): boolean => {
    let output: boolean
    if (showAllAsignees) {
        output = true
    } else if (!selectedAssigneeIds.length) {
        output = !item.assignee
    } else {
        output = selectedAssigneeIds.some((child) => child === item?.assignee?.id)
    }
    return output
}

export const setAssigneeInputPlaceholder = (selectedAssigneeIds: string[], showAllAsignees: boolean, uniqueAssigneesWithColors: AssigneeWithColorModel[]): string => {
    let output = `${selectedAssigneeIds.length} Assignee(s) selected`
    if (showAllAsignees) {
        output = 'All'
    } else if (selectedAssigneeIds.length === 1) {
        output = `${uniqueAssigneesWithColors?.find((child) => child.assigneeId === selectedAssigneeIds[0])?.assigneeName ?? ''}`
    } else if (!selectedAssigneeIds.length) {
        output = 'Unassigned'
    }
    return output
}

const EnhancedTableHead: FC<TableHeadType> = ({ orderBy, setOrderBy, direction, setDirection, columns }) => {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding={'normal'}>
                        <SortingMenu
                            disable={headCell.type !== HeadCellType.Action && headCell.type !== HeadCellType.Empty}
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection((prevState) => !prevState)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const ActivityListTable: FC<ActivityListTableProps> = ({
    onClickProductAndEquipment,
    selectedProductId = '',
    selectedEquipmentId = '',
    isExpendableRow = true,
    highlightedRowId = '',
    isManufacturer,
    data,
    onHandleClick,
    sortKey,
    onChangeVisibility = () => null,
    columns,
}) => {
    const [orderBy, setOrderBy] = React.useState(sortKey || 'name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const [openedIndex, setOpenedIndex] = React.useState<null | number>(null)
    const [slicedData, setSlicedData] = React.useState<ActivityTableDataModel[]>([])

    React.useEffect(() => {
        setPage(0)
    }, [data.length])

    React.useEffect(() => {
        setSlicedData(arraySort(data, orderBy, direction ? 'ascending' : 'descending').slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }, [data, direction, orderBy, page, rowsPerPage])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const tableTypeIconRenderer = (type: string) => {
        switch (type) {
            case 'Ticket':
                return (
                    <PopoverItem popoverContent="Ticket">
                        <div>
                            <Icon className="recurring-icon" type={IconType.Ticket} />
                        </div>
                    </PopoverItem>
                )
            case 'Service':
                return (
                    <PopoverItem popoverContent="Service">
                        <div>
                            <Icon className="recurring-icon" type={IconType.Maintenance} />
                        </div>
                    </PopoverItem>
                )

            case 'Product Replenishment':
                return (
                    <PopoverItem popoverContent="Product Replenishment">
                        <div>
                            <Icon className="recurring-icon" type={IconType.Replenishment} />
                        </div>
                    </PopoverItem>
                )
        }
    }
    const isExpandableCondition = (row: ActivityTableDataModel) => {
        return (
            (row.contract || (row.products !== undefined && row.products.length > 0) || (row.equipments !== undefined && row.equipments.length > 0) || JSON.parse(row.attachments)?.length > 0) &&
            isExpendableRow
        )
    }

    const highlightRow = (activityId: string, productId: string, equipmentId: string): string => {
        if (highlightedRowId === activityId) {
            if ((productId && productId === selectedProductId) || (equipmentId && equipmentId === selectedEquipmentId) || (!productId && !equipmentId)) {
                return 'highlightedRow'
            }
        }

        return ''
    }

    const columnRenderer = (columnType: HeadCellType, row: ActivityTableDataModel, rowIndex: number): ReactNode => {
        switch (columnType) {
            case HeadCellType.Empty:
                return (
                    <TableCell style={{ width: '2%' }} align="left">
                        {!isExpandableCondition(row) ? (
                            <Tooltip title="No linked records available">
                                <span>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => (openedIndex === rowIndex ? setOpenedIndex(null) : setOpenedIndex(rowIndex))}
                                        disabled={!isExpandableCondition(row)}
                                    >
                                        <Icons type={IconType.ChevronDown} color={Color.grey100} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        ) : (
                            <PopoverItem popoverContent={openedIndex === rowIndex ? 'Hide Details' : 'Show Details'}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (openedIndex === rowIndex) {
                                            setOpenedIndex(null)
                                        } else {
                                            setOpenedIndex(rowIndex)
                                        }
                                    }}
                                >
                                    {openedIndex === rowIndex ? <Icons type={IconType.ChevronUp} color={Color.grey600} /> : <Icons type={IconType.ChevronDown} color={Color.grey600} />}
                                </IconButton>
                            </PopoverItem>
                        )}
                    </TableCell>
                )

            case HeadCellType.Title:
                return (
                    <TableCell style={{ width: '26%' }} className="font-bold" align="left">
                        <Tooltip title={row.title}>
                            <div className="one-line">{row.title}</div>
                        </Tooltip>
                    </TableCell>
                )

            case HeadCellType.Customer:
                return (
                    <TableCell style={{ width: '20%' }} align="left">
                        {row.customer}
                    </TableCell>
                )

            case HeadCellType.Assignee:
                return (
                    <TableCell style={{ width: '10%' }} align="left">
                        {row.assigneeShortName && row.assigneeFirstName ? (
                            <div className="assignee-container">
                                <Tooltip title={row.assignee} placement="right-end">
                                    <div style={{ backgroundColor: row?.assigneeColor ?? 'white' }} className="badge">
                                        {row.assigneeShortName}
                                    </div>
                                </Tooltip>
                                <span style={{ color: 'black' }} className="firstName">
                                    {row.assigneeFirstName}
                                </span>
                            </div>
                        ) : row.assignee ? (
                            <span>{row.assignee}</span>
                        ) : (
                            <></>
                        )}
                    </TableCell>
                )

            case HeadCellType.Status:
                return (
                    <TableCell style={{ width: '10%' }} align="left">
                        <div className={`activity-status ${row.status}`}>
                            {row.status}
                            {row.status === 'Closed' && (
                                <Tooltip title="Closed tasks are automatically locked and stored as historical records.">
                                    <span>
                                        <Icons type={IconType.Lock} />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    </TableCell>
                )

            case HeadCellType.DueDate:
                return (
                    <TableCell style={{ width: '12%' }} align="left">
                        <div
                            className={`date-info ${dayjs(row.dueDate?.toString()) < dayjs() && row.status !== 'Closed' && 'red'}`}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                columnGap: '0.5rem',
                            }}
                        >
                            <span>{row.dueDate}</span>
                            <div style={{ width: '1rem', color: 'var(--grey-600)' }}>
                                {row.recurring && (
                                    <Tooltip key="rec" title="Recurring">
                                        <div>
                                            <Icon className="due-date-recurring-icon" type={IconType.Reset} />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                    </TableCell>
                )

            case HeadCellType.Action:
                return (
                    <TableCell style={{ width: '2%' }} className="font-bold" align="left">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                columnGap: '1rem',
                            }}
                        >
                            {isManufacturer && (
                                <Tooltip title="Share with customer">
                                    <Switch
                                        disabled={!row.customer}
                                        onClick={(e) => e.stopPropagation()}
                                        checked={row.isVisibleToCustomer}
                                        onChange={() => onChangeVisibility(row.id, !row.isVisibleToCustomer)}
                                        className="activity-table-switch"
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Tooltip>
                            )}
                            <div style={{ width: '1rem' }}>{tableTypeIconRenderer(row.type)}</div>
                            {isManufacturer && (
                                <Tooltip title="View Details">
                                    <div
                                        className="chevronRightContainer"
                                        onClick={(e) => {
                                            if (onHandleClick) {
                                                e.stopPropagation()
                                                onHandleClick(row.id, row.groupId)
                                            }
                                        }}
                                    >
                                        <Icons type={IconType.ChevronRight} />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </TableCell>
                )

            case HeadCellType.ProductWithPartNumber:
                return (
                    <TableCell align="left">
                        <div>
                            <div>
                                <b>{row?.productName ?? ''}</b>
                            </div>
                            <div>
                                <span>{row?.productPartNumber ?? ''}</span>
                            </div>
                        </div>
                    </TableCell>
                )

            case HeadCellType.Product:
                return (
                    <TableCell align="left" style={{ width: '26%' }}>
                        <div>
                            <b className="one-line">{row?.productName ?? ''}</b>
                        </div>
                    </TableCell>
                )

            case HeadCellType.PartNumber:
                return (
                    <TableCell align="left">
                        <div>
                            <b>{row?.productPartNumber ?? ''}</b>
                        </div>
                    </TableCell>
                )

            case HeadCellType.Quantity:
                return (
                    <TableCell align="left">
                        <div>
                            <b>{row?.productQuantity ?? 1}</b>
                        </div>
                    </TableCell>
                )

            case HeadCellType.TaskWithCustomer:
                return (
                    <TableCell align="left">
                        <div>
                            <Tooltip title={row.title}>
                                <div className="one-line">
                                    <b>{row.title}</b>
                                </div>
                            </Tooltip>
                            <div>
                                <span>{row?.customerCompanyName ?? ''}</span>
                            </div>
                        </div>
                    </TableCell>
                )

            case HeadCellType.Task:
                return (
                    <TableCell align="left">
                        <Tooltip title={row.title}>
                            <div className="one-line">
                                <b>{row.title}</b>
                            </div>
                        </Tooltip>
                    </TableCell>
                )

            case HeadCellType.Equipment:
                return (
                    <TableCell align="left" style={{ width: '26%' }}>
                        <div>
                            <b className="one-line">{row?.equipmentName ?? 1}</b>
                        </div>
                    </TableCell>
                )

            default:
                return <TableCell align="left" />
        }
    }

    if (data.length === 0) return <NoDataInfo />

    return (
        <>
            <TableContainer className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} columns={columns} />
                    <TableBody>
                        {slicedData.map((row: ActivityTableDataModel, index) => {
                            return (
                                <>
                                    {openedIndex === index && !!index && <TableRow style={{ height: '5px' }} />}
                                    <TableRow
                                        onClick={() => {
                                            if (onHandleClick) {
                                                onHandleClick(row.id, row.groupId, true)
                                                if (onClickProductAndEquipment) {
                                                    onClickProductAndEquipment(row?.productId ?? '', row?.equipmentId ?? '')
                                                }
                                            }
                                        }}
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        style={{ cursor: 'pointer' }}
                                        key={row.id}
                                        className={highlightRow(row.id, row?.productId ?? '', row?.equipmentId ?? '')}
                                    >
                                        {columns?.map((column) => columnRenderer(column.type, row, index))}
                                    </TableRow>
                                    {isExpandableCondition(row) && (
                                        <TableRow className="expand-row">
                                            <TableCell colSpan={12} className={ignoreDashClassname}>
                                                <Collapse style={{ width: '100%' }} in={openedIndex === index} timeout="auto" className={'collapse-detail'} unmountOnExit>
                                                    <div className="expand-item-container">
                                                        {row.equipments !== undefined && row.equipments.length > 0 && (
                                                            <div className="cart-container">
                                                                <div className="cart-container-title">EQUIPMENT</div>
                                                                <div className="cart-container-body">
                                                                    {row.equipments.map((item) => (
                                                                        <div className="cart-item equipment">
                                                                            <span className="one-line">{item?.name}</span>
                                                                            <span className="serialNo">{item?.serialNo}</span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {row.products !== undefined && row.products.length > 0 && (
                                                            <div className="cart-container">
                                                                <div className="cart-container-title">PRODUCTS</div>
                                                                <div className="cart-container-body">
                                                                    {row.products.map((item) => {
                                                                        return (
                                                                            <div className="cart-item product">
                                                                                <b>{item.quantity}</b>

                                                                                <span className="one-line">{item?.product?.name}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {row.contract && (
                                                            <div className="cart-container">
                                                                <div className="cart-container-title">CONTRACT</div>
                                                                <div className="cart-container-body">
                                                                    <div className="cart-item equipment">
                                                                        <span className="one-line">{row.contract.title}</span>
                                                                        <span className="serialNo">{row.contract.contractNumber}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {JSON.parse(row.attachments)?.length > 0 && (
                                                            <div className="cart-container">
                                                                <div className="cart-container-title">DOCUMENTS</div>
                                                                <div className="cart-container-body">
                                                                    {(JSON.parse(row.attachments) as FileModel[]).map((item) => {
                                                                        return (
                                                                            <div className="cart-item">
                                                                                <a rel="noreferrer" target="_blank" href={item.url}>
                                                                                    <Tooltip title={item?.name ?? ''} placement="right-end">
                                                                                        <span className="one-line">{item?.name}</span>
                                                                                    </Tooltip>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {openedIndex === index && <TableRow style={{ height: '5px' }} />}
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

export default ActivityListTable
