import React from 'react'
import { CustomerDetailModel } from '../../../../../../models/New/Customer'
import './Card.scss'

interface IProps {
    customer: CustomerDetailModel
    handleUpdateAddress: () => void
}

function ContactInfo(props: IProps) {
    const { customer, handleUpdateAddress } = props
    return (
        <div className="overview-card">
            <div className="item">
                <div className="item-header">
                    <span className="title">Main Address</span>
                </div>
                {customer.streetAddress1 && <span>{customer.streetAddress1}</span>}
                {customer.streetAddress2 && <span>{customer.streetAddress2}</span>}
                <div className="address-line">
                    {customer.city && <span>{customer.city}</span>}
                    {customer.state && <span>{customer.state}</span>}
                    {customer.zipCode && <span>{customer.zipCode}</span>}
                </div>

                {customer.country && <span>{customer.country}</span>}
                {!customer.streetAddress1 && !customer.streetAddress2 && !customer.city && !customer.state && !customer.zipCode && !customer.country && (
                    <div onClick={handleUpdateAddress} className="action-button">
                        +update
                    </div>
                )}
            </div>
            <div className="item">
                <div className="item-header">
                    <span className="title">Company Contact</span>
                </div>
                {customer.phone && <span>{customer.phone}</span>}
                {customer.email && <span>{customer.email}</span>}
                {customer.website && <span>{customer.website}</span>}
            </div>
        </div>
    )
}

export default ContactInfo
