import React, { MouseEventHandler, ReactNode } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Button, { ButtonType } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Modal.scss'

export interface ModalProps {
    visible: boolean
    className?: string
    title: ReactNode
    subtitle?: ReactNode
    titleColor?: string
    headerButtonText?: string
    headerButtonDisabled?: boolean
    headerCloseButton?: boolean
    primaryButtonText?: string
    primaryDisabled?: boolean
    secondaryDisabled?: boolean
    secondaryButtonText?: string
    tertiaryButtonText?: string
    tertiaryDisabled?: boolean
    buttonOrder?: 'primarySecondary' | 'secondaryPrimary' | 'primarySecondaryTertiary'
    staticBackdrop?: boolean
    style?: React.CSSProperties
    switch?: ReactNode
    onClickHeaderButton?: MouseEventHandler<HTMLButtonElement>
    onClickPrimary?: MouseEventHandler<HTMLButtonElement>
    onClickSecondary?: MouseEventHandler<HTMLButtonElement>
    onClickTertiary?: MouseEventHandler<HTMLButtonElement>
    onClickClose?: MouseEventHandler<HTMLDivElement>
    isProductModal?: boolean
}

function Modal(props: React.PropsWithChildren<ModalProps>) {
    if (!props.visible) return <></>

    function buildPrimarySecondaryOrder() {
        return (
            <>
                {props.primaryButtonText && (
                    <Button type={ButtonType.Primary} disabled={props.primaryDisabled} onClick={props.onClickPrimary}>
                        {props.primaryButtonText}
                    </Button>
                )}
                {props.secondaryButtonText && (
                    <Button type={ButtonType.Secondary} onClick={props.onClickSecondary}>
                        {props.secondaryButtonText}
                    </Button>
                )}
            </>
        )
    }

    function buildSecondaryPrimaryOrder() {
        return (
            <>
                {props.secondaryButtonText && (
                    <Button type={ButtonType.Secondary} disabled={props.isProductModal && props.secondaryDisabled} onClick={props.onClickSecondary}>
                        {props.secondaryButtonText}
                    </Button>
                )}
                {props.primaryButtonText && (
                    <Button type={ButtonType.Primary} disabled={props.primaryDisabled} onClick={props.onClickPrimary}>
                        {props.primaryButtonText}
                    </Button>
                )}
            </>
        )
    }

    function buildSecondaryPrimaryTertiaryOrder() {
        return (
            <>
                {props.secondaryButtonText && (
                    <Button type={ButtonType.Secondary} disabled={props.secondaryDisabled} onClick={props.onClickSecondary}>
                        {props.secondaryButtonText}
                    </Button>
                )}
                {props.primaryButtonText && (
                    <Button type={ButtonType.Primary} disabled={props.primaryDisabled} onClick={props.onClickPrimary}>
                        {props.primaryButtonText}
                    </Button>
                )}

                {props.tertiaryButtonText && (
                    <Button type={ButtonType.Tertiary} disabled={props.tertiaryDisabled} onClick={props.onClickTertiary}>
                        {props.tertiaryButtonText}
                    </Button>
                )}
            </>
        )
    }

    return (
        <>
            <div style={props.style} className={props.className ? props.className + ' modal' : 'modal'}>
                <div style={{ color: props.titleColor }} className="modal-header">
                    <div className="modal-title">
                        <h4>{props.title}</h4>
                        {props.subtitle ? <span className="modal-subtitle">{props.subtitle}</span> : <></>}
                        {props.switch ? <span style={{ position: 'absolute', right: 0 }}>{props.switch}</span> : <></>}
                    </div>
                    {props.headerButtonText && (
                        <div className="modal-header-button">
                            <Button disabled={props.headerButtonDisabled} onClick={props.onClickHeaderButton}>
                                {props.headerButtonText}
                            </Button>
                        </div>
                    )}
                    {props.headerCloseButton && props.onClickClose && (
                        <div className="modal-header-close-button" onClick={props.onClickClose}>
                            <FontAwesomeIcon icon={faTimes} size="lg" />
                        </div>
                    )}
                </div>
                <div className="modal-body">{props.children}</div>
                <div className={`modal-footer ${props.tertiaryButtonText && 'row'}`}>
                    {props.isProductModal ? (
                        <div className="create-product">
                            <div className="first-row">{buildSecondaryPrimaryOrder()}</div>
                            <span className="cancel-button" onClick={props.onClickTertiary}>
                                {props.tertiaryButtonText}
                            </span>
                        </div>
                    ) : (
                        <>
                            {props.buttonOrder
                                ? props.buttonOrder === 'primarySecondary'
                                    ? buildPrimarySecondaryOrder()
                                    : buildSecondaryPrimaryOrder()
                                : props.buttonOrder === 'secondaryPrimary'
                                ? buildPrimarySecondaryOrder()
                                : buildSecondaryPrimaryTertiaryOrder()}
                        </>
                    )}
                </div>
            </div>
            <div className="modal-overlay" onClick={props.staticBackdrop ? undefined : props.onClickClose}></div>
        </>
    )
}

export default Modal
