import { useState } from 'react'
import { TeamActionTypes } from '../..'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import { UpdatePasswordModel } from '../../../../models/Ui/Auth'
import AuthService from '../../../../services/UiService/Auth'
import { isValidPassword } from '../../../../utils/helpers'
import './style.scss'

interface Props {
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function UpdatePasswordModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props

    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [newConfirmPassword, setNewConfirmPassword] = useState<string>('')

    async function changePasswordHandler() {
        try {
            onLoading()
            const authService = new AuthService()
            const data: UpdatePasswordModel = {
                currentPassword: currentPassword,
                newPassword: newPassword,
            }
            const updatePasswordResponse = await authService.updatePassword(data)
            onSuccess(TeamActionTypes.UPDATE_PASSWORD, updatePasswordResponse)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return currentPassword.length > 5 && isValidPassword(newPassword) && newPassword === newConfirmPassword
    }

    const clearForm = () => {
        setCurrentPassword('')
        setNewPassword('')
        setNewConfirmPassword('')
    }

    const clickPrimary = () => {
        changePasswordHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <Modal
            className="update-password-modal"
            visible={true}
            title="Change password"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <Input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} placeholder="CURRENT PASSWORD" />
            </div>
            <div className="modal-line">
                <Input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="NEW PASSWORD" />
            </div>
            <div className="modal-line">
                <Input type="password" value={newConfirmPassword} onChange={(e) => setNewConfirmPassword(e.target.value)} placeholder="CONFIRM NEW PASSWORD" />
            </div>
            <div className="modal-line">
                <ul className="information-rules">
                    <li>At least 8 characters</li>
                    <li>A mixture of both uppercase and lowercase letters.</li>
                    <li>A mixture of letters and numbers.</li>
                    <li>Inclusion of at least one special character (!,@,#,?)</li>
                </ul>
            </div>
            After saving your new password, you will automatically be logged out and asked to log in again.
        </Modal>
    )
}

export default UpdatePasswordModal
