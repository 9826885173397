import ActivityListTable, {
    ActivityTableDataModel,
    assigneeColorSetter,
    assigneeFirstNameSetter,
    assigneeShortNameSetter,
    AssigneeWithColorModel,
    filterAssignees,
    HeadCellType,
    setAssigneeInputPlaceholder,
} from '../../../ActivityList/ActivityListTable'
import { ActivityModel, ActivityTypes } from '../../../../../models/Ui/Activity'
import dayjs from 'dayjs'
import { TabItem, Tabs } from '../../../../../models/Ui/Tab'
import Tab from '../../../../../components/Tab'
import SearchInput from '../../../../../components/SearchInput'
import { Switch, Tooltip } from '@mui/material'
import Button from '../../../../../components/Button'
import Icons, { IconType } from '../../../../../Icon'
import { useCallback, useEffect, useState } from 'react'
import { DueDateFilter, FilterKeys, FilterType, SortByFilter, SortByType, StatusFilter, StatusFilterType } from '../../../ActivityList'
import PartialUpdateBox from '../../../ActivityList/PartialUpdateBox'
import { priceFormatter, standardDate } from '../../../../../utils/helpers'
import ActivityService from '../../../../../services/UiService/Activity'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CheckBoxDropdownInput from '../../../../../components/CheckBoxDropdownInput'
import Loading from '../../../../../components/Loading'
import BasicDropdown from '../../../../../components/Dropdown/BasicDropdown'
import './ActivityTab.scss'
import PopoverItem from '../../../../../components/PopoverItem'
import UpdateActivityPopupSidebar from '../../../ActivityList/Modals/UpdateActivityPopupSidebar'
import AddActivityPopupSidebar from '../../../ActivityList/Modals/AddActivityPopupSidebar'

export interface HeadCell {
    id: keyof ActivityTableDataModel | ''
    label: string
    type: HeadCellType
    width?: string
}

export interface FilterState {
    dueDate: DueDateFilter
    status: FilterType
    sortBy: SortByFilter
}

interface Props {
    visible: boolean
    refreshActivites: () => void
    showFilter: boolean
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>
    showActivityProductFilter: boolean
}

const activityTableColumns: HeadCell[] = [
    {
        id: '',
        label: '',
        type: HeadCellType.Empty,
    },
    {
        id: 'title',
        label: 'Title',
        type: HeadCellType.Title,
    },
    {
        id: 'customer',
        label: 'Customer',
        type: HeadCellType.Customer,
    },
    {
        id: 'assignee',
        label: 'Assignee',
        type: HeadCellType.Assignee,
    },
    {
        id: 'status',
        label: 'Status',
        type: HeadCellType.Status,
    },
    {
        id: 'dueDate',
        label: 'Due Date',
        type: HeadCellType.DueDate,
    },
    {
        id: 'action',
        label: '',
        type: HeadCellType.Action,
    },
    {
        id: 'productName',
        label: 'Product',
        type: HeadCellType.ProductWithPartNumber,
    },
    {
        id: 'productName',
        label: 'Product',
        type: HeadCellType.Product,
    },
    {
        id: 'productPartNumber',
        label: 'Part Number',
        type: HeadCellType.PartNumber,
    },
    {
        id: 'productQuantity',
        label: 'Quantity',
        type: HeadCellType.Quantity,
    },
    {
        id: 'title',
        label: 'Activity',
        type: HeadCellType.TaskWithCustomer,
    },
    {
        id: 'title',
        label: 'Activity',
        type: HeadCellType.Task,
    },
    {
        id: 'equipmentName',
        label: 'Equipment',
        type: HeadCellType.Equipment,
    },
]

const dueDateFilterTypes: DueDateFilter[] = [
    { id: ' ', text: 'All Time' },
    {
        id: dayjs(),
        text: 'Today',
        key: 'day',
    },
    {
        id: dayjs().subtract(1, 'day'),
        text: 'Before Today',
        key: 'day',
    },
    {
        id: dayjs().subtract(1, 'week'),
        text: 'Last Week',
        key: 'week',
    },
    {
        id: dayjs().add(1, 'week'),
        text: 'Next Week',
        key: 'week',
    },
    {
        id: dayjs(),
        text: 'This Week',
        key: 'week',
    },
    {
        id: dayjs().subtract(1, 'month'),
        text: 'Last Month',
        key: 'month',
    },
    {
        id: dayjs().add(1, 'month'),
        text: 'Next Month',
        key: 'month',
    },
    {
        id: dayjs(),
        text: 'This Month',
        key: 'month',
    },
    {
        id: dayjs(),
        text: 'This Year',
        key: 'year',
    },
]
const statusFilterTypes: StatusFilter[] = [
    { id: ' ', text: 'All' },
    { id: 'Open', text: 'Open', type: StatusFilterType.Open },
    { id: 'In Progress', text: 'In Progress', type: StatusFilterType.InProgress },
    { id: 'On Hold', text: 'On Hold', type: StatusFilterType.OnHold },
    { id: 'Closed', text: 'Closed', type: StatusFilterType.Closed },
]
const sortByFilterOptions: SortByFilter[] = [
    { id: 'None', text: 'None', type: SortByType.None },
    { id: 'Product', text: 'Product', type: SortByType.Product },
    { id: 'Equipment', text: 'Equipment', type: SortByType.Equipment },
]
const initialFilter: FilterState = {
    dueDate: { ...dueDateFilterTypes[0] } as DueDateFilter,
    status: { ...statusFilterTypes[0] } as FilterType,
    sortBy: sortByFilterOptions[0],
}

const ActivityTab = (props: Props) => {
    let { productId } = useParams()
    const visibleCondition = { display: props.visible ? 'block' : 'none' }
    const setActivityTableColumns = (): HeadCell[] => {
        const sortByNoneColumns: HeadCellType[] = [HeadCellType.Empty, HeadCellType.Title, HeadCellType.Assignee, HeadCellType.Status, HeadCellType.DueDate, HeadCellType.Action]
        const sortByProductColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Product,
            HeadCellType.PartNumber,
            HeadCellType.Quantity,
            HeadCellType.Task,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]
        const sortByEquipmentColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Equipment,
            HeadCellType.Task,
            HeadCellType.Assignee,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]

        let output: HeadCell[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = sortByNoneColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Product) {
            output = sortByProductColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            output = sortByEquipmentColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        }

        if (partialUpdateModalVisible) {
            return output.filter((item) => ![HeadCellType.Status, HeadCellType.DueDate].includes(item.type))
        }

        return output
    }
    const activityTabItems: TabItem[] = [
        {
            id: Tabs.All,
            name: 'All',
        },
        {
            id: Tabs.Service,
            name: 'Service',
            customIcon: <Icons type={IconType.Maintenance} />,
        },
        {
            id: Tabs.ProductReplenishment,
            name: 'Products',
            customIcon: <Icons type={IconType.Product} />,
        },
        {
            id: Tabs.Activities,
            name: 'Tickets',
            customIcon: <Icons type={IconType.Ticket} />,
        },
    ]

    const [loading, setLoading] = useState<boolean>(false)
    const [filterState, setFilterState] = useState<FilterState>(initialFilter)
    const [partialUpdateModalVisible, setPartialUpdateModalVisible] = useState(false)
    const [activeActivityTab, setActiveActivityTab] = useState<number>(activityTabItems[0].id)
    const [filteredActivitiesData, setFilteredActivitiesData] = useState<ActivityModel[]>([])
    const [viewHistoricalOnes, setViewHistoricalOnes] = useState(false)

    const [createActivityModalVisible, setCreateActivityModalVisible] = useState(false)
    const [selectedActivtyIds, setselectedActivtyIds] = useState<{ id: string; groupId: string }>({ id: '', groupId: '' })
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false)
    // const [uniqueAssigneesWithColors, setUniqueAssigneesWithColors] = useState<AssigneeWithColorModel[]>([])
    const [showAllAsignees, setShowAllAsignees] = useState(true)
    const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([])
    const [uniqueAssigneesWithColors] = useState<AssigneeWithColorModel[]>([])
    const [activities, setActivities] = useState<ActivityModel[]>([])
    const [selectedProductId, setSelectedProductId] = useState<string>('')
    const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>('')

    const onLoadingCallback = useCallback(() => setLoading(true), [])
    const onCompleteCallback = useCallback(() => setLoading(false), [])
    const onErrorCallback = useCallback((error: any) => toast.error(error), [])

    const handleSearchedActivitiesData = (data: any[]) => {
        setFilteredActivitiesData(data)
    }

    const toggleFilterMenu = () => {
        setFilterState(initialFilter)
        props.setShowFilter((prevState) => !prevState)
    }

    const onChangeVisibility = async (activityId: string, visibility: boolean) => {
        try {
            onLoadingCallback()
            const res = await ActivityService.setVisibility(activityId, visibility)
            await getActivities()
            toast.success(res.message)
        } catch (error: any) {
            onErrorCallback(error.message)
        } finally {
            onCompleteCallback()
        }
    }
    const activityTableDataMapper = (args: ActivityModel[]): ActivityTableDataModel[] => {
        let output: ActivityTableDataModel[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = args.map((child) => {
                return {
                    isWorkOrder: child.isWorkOrder,
                    recurring: child.isRecurring,
                    title: child.title,
                    customer: child.customer?.name,
                    assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                    status: child.status,
                    dueDate: standardDate(child.dueDate),
                    opportunity: priceFormatter(child.revenueOpportunity || '-'),
                    id: child.id,
                    groupId: child.groupId,
                    isVisibleToCustomer: child.isVisibleToCustomer,
                    description: child.description,
                    type: child.type,
                    attachments: child.attachments,
                    equipments: child.equipments,
                    products: child.products,
                    assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                    assigneeShortName: assigneeShortNameSetter(child.assignee),
                    assigneeFirstName: assigneeFirstNameSetter(child),
                }
            })
        } else if (filterState.sortBy.type === SortByType.Product) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.products.map((product) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        productId: product?.productId ?? '',
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        productName: product?.product?.name ?? '',
                        productPartNumber: product?.product?.partNumber ?? '',
                        productQuantity: product?.quantity ?? 0,
                        customerCompanyName: child.customer.name,
                        equipments: child.equipments,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.equipments.map((equipment) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        customerCompanyName: child.customer.name,
                        equipmentName: equipment?.name ?? '',
                        equipmentId: equipment?.id ?? '',
                        products: child.products,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        }

        return output
    }

    const getActivities = async () => {
        try {
            onLoadingCallback()
            const response = await ActivityService.getAllByProductId(productId!)
            setActivities(response)
        } catch (error: any) {
            onErrorCallback(error)
        } finally {
            onCompleteCallback()
        }
    }

    const onSuccessCallback = async () => {
        await getActivities()
        props.refreshActivites()
    }

    const setFilter = (newState: any, key: FilterKeys) =>
        setFilterState((prevState) => ({
            ...prevState,
            [key]: newState,
        }))

    useEffect(() => {
        if (props.visible) getActivities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible])

    useEffect(() => {
        if (props.showActivityProductFilter) {
            setFilterState((prevState) => ({
                ...prevState,
                sortBy: sortByFilterOptions[1],
            }))
        }
    }, [props.showActivityProductFilter])

    const onClickViewMoreAtPartialUpdateModal = () => {
        setPartialUpdateModalVisible(false)
        setUpdateModalVisible(true)
    }

    return (
        <div className="deneme" style={visibleCondition}>
            {loading && <Loading />}
            <div className="tab-container">
                <Tab
                    activeTab={activeActivityTab}
                    tabs={activityTabItems}
                    onTabClick={(id) => {
                        setActiveActivityTab(id)
                    }}
                ></Tab>
            </div>
            <div className="activity-right-buttons">
                {activities.length > 0 && (
                    <>
                        <SearchInput
                            data={activities}
                            handleSearch={handleSearchedActivitiesData}
                            searchKeys={['title', 'customer.name', 'assignedBy.firstName', 'assignedBy.lastName', 'startDate', 'frequencyUnit', 'type']}
                        />
                        <Tooltip title="View Historical Logs">
                            <Switch className="historicalLogSwitch" value={viewHistoricalOnes} onChange={() => setViewHistoricalOnes((prevState) => !prevState)} />
                        </Tooltip>
                        <Tooltip title="Apply Filters">
                            <span>
                                <Button className="filter-button" onClick={toggleFilterMenu}>
                                    <Icons type={IconType.FilterSettings} />
                                </Button>
                            </span>
                        </Tooltip>
                    </>
                )}
                <PopoverItem popoverContent="Add new">
                    <Button className="add-task" onClick={() => setCreateActivityModalVisible(true)}>
                        <Icons type={IconType.BoldPlus} />
                    </Button>
                </PopoverItem>
            </div>
            {activities.length > 0 && props.showFilter && (
                <div className="filter-menu">
                    <BasicDropdown
                        label="Sort By"
                        leftIcon={IconType.Checklist}
                        menuItems={sortByFilterOptions}
                        onChange={(e) => setFilter(e, FilterKeys.SortBy)}
                        selectedItem={{ text: filterState.sortBy.text, id: filterState.sortBy.id }}
                    />
                    <BasicDropdown
                        label="Due Date"
                        leftIcon={IconType.Calendar}
                        menuItems={dueDateFilterTypes}
                        onChange={(e) => setFilter(e, FilterKeys.DueDate)}
                        selectedItem={{ text: filterState.dueDate.text, id: filterState.dueDate.id }}
                    />
                    <CheckBoxDropdownInput
                        disabled={!uniqueAssigneesWithColors.length}
                        selectedItems={selectedAssigneeIds}
                        placeholder="Assignee"
                        listItems={(uniqueAssigneesWithColors || []).map((p) => {
                            return {
                                id: p.assigneeId,
                                text: p.assigneeName,
                            }
                        })}
                        dropIcon={true}
                        onDataChange={(data, allChecked) => {
                            setSelectedAssigneeIds(data)
                            setShowAllAsignees(allChecked as boolean)
                        }}
                        showAllCheckbox
                        showCustomInputLabelWhenEmpty
                        customInputLabel={setAssigneeInputPlaceholder(selectedAssigneeIds, showAllAsignees, uniqueAssigneesWithColors)}
                    />
                    <BasicDropdown
                        label="Status"
                        leftIcon={IconType.DottedCircle}
                        menuItems={statusFilterTypes}
                        onChange={(e) => setFilter(e, FilterKeys.Status)}
                        selectedItem={{ text: filterState.status.text, id: filterState.status.id }}
                    />
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                <div style={{ width: partialUpdateModalVisible ? '68%' : '100%' }}>
                    <ActivityListTable
                        columns={setActivityTableColumns()}
                        sortKey="dueDate"
                        highlightedRowId={partialUpdateModalVisible ? selectedActivtyIds.id : ''}
                        selectedProductId={partialUpdateModalVisible ? selectedProductId : ''}
                        selectedEquipmentId={partialUpdateModalVisible ? selectedEquipmentId : ''}
                        onChangeVisibility={onChangeVisibility}
                        onHandleClick={(id, groupId, isPartial) => {
                            if (!isPartial) {
                                setUpdateModalVisible(true)
                            } else {
                                setPartialUpdateModalVisible(true)
                            }
                            setselectedActivtyIds({ id: id, groupId: groupId })
                        }}
                        onClickProductAndEquipment={(productId, equipmentId) => {
                            setSelectedProductId(productId)
                            setSelectedEquipmentId(equipmentId)
                        }}
                        isManufacturer
                        data={activityTableDataMapper(
                            filteredActivitiesData
                                .filter((item: ActivityModel) => {
                                    if (filterState.dueDate.id === ' ') {
                                        return item
                                    } else if (filterState.dueDate.text === 'Before Today') {
                                        return filterState.dueDate.id >= dayjs(item.dueDate)
                                    }
                                    return filterState.dueDate.id.isSame(dayjs(item.dueDate), filterState.dueDate.key)
                                })
                                .filter((item: ActivityModel) => filterAssignees(item, showAllAsignees, selectedAssigneeIds))
                                .filter((item: ActivityModel) => (filterState.status.id === ' ' ? item : item.status === filterState.status.id))
                                .filter((item: ActivityModel) => !viewHistoricalOnes || (viewHistoricalOnes && item.status === 'Closed'))
                                .filter((item) => {
                                    if (activeActivityTab === Tabs.Service) {
                                        return item.type === ActivityTypes.Service
                                    } else if (activeActivityTab === Tabs.ProductReplenishment) {
                                        return item.type === ActivityTypes.ProductReplensh
                                    } else if (activeActivityTab === Tabs.Activities) {
                                        return item.type === ActivityTypes.Ticket
                                    } else {
                                        return true
                                    }
                                })
                        )}
                    />
                </div>
                {selectedActivtyIds && partialUpdateModalVisible && (
                    <div className="partial-update-box" style={{ width: '30%' }}>
                        <PartialUpdateBox
                            id={selectedActivtyIds.id}
                            visible={!!selectedActivtyIds && partialUpdateModalVisible}
                            closeModal={() => {
                                setPartialUpdateModalVisible(false)
                            }}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompleteCallback}
                            onSuccess={onSuccessCallback}
                            onError={onErrorCallback}
                            onViewMore={onClickViewMoreAtPartialUpdateModal}
                        />
                    </div>
                )}
                {selectedActivtyIds && updateModalVisible && (
                    <UpdateActivityPopupSidebar
                        id={selectedActivtyIds.id}
                        groupId={selectedActivtyIds.groupId}
                        onChangeId={(id) => setselectedActivtyIds({ ...selectedActivtyIds, id: id })}
                        visible={updateModalVisible}
                        onClose={async () => {
                            setUpdateModalVisible(false)
                            await getActivities()
                        }}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompleteCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}
                {createActivityModalVisible && (
                    <AddActivityPopupSidebar
                        onLoading={onLoadingCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                        onCompleted={onCompleteCallback}
                        visible={createActivityModalVisible}
                        onClose={() => setCreateActivityModalVisible(false)}
                    />
                )}
            </div>
        </div>
    )
}

export default ActivityTab
