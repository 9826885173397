import { useState } from 'react'
import { TeamActionTypes } from '../..'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import { MaxCharacter } from '../../../../models/enums'
import { AddAddressModel } from '../../../../models/Ui/Address'
import AddressService from '../../../../services/UiService/Address'
import './style.scss'

interface Props {
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function CreateAddressModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props

    const [label, setLabel] = useState<string>('')
    const [streetAddress1, setStreetAddress1] = useState<string>('')
    const [streetAddress2, setStreetAddress2] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [zip, setZip] = useState<string>('')
    const [country, setCountry] = useState<string>('')

    async function createAddressHandler() {
        try {
            onLoading()
            const addressService = new AddressService()
            const data: AddAddressModel = {
                label: label,
                street1: streetAddress1,
                street2: streetAddress2,
                city: city,
                state: state,
                postcode: zip,
                country: country,
            }
            const addAddressResponse = await addressService.addAddress(data)
            onSuccess(TeamActionTypes.CREATE_ADDRESS, addAddressResponse)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return label.length > 0 && streetAddress1.length > 0 && city.length > 0 && state.length > 0 && zip.length > 0 && country.length > 0
    }

    const clearForm = () => {
        setLabel('')
        setStreetAddress1('')
        setStreetAddress2('')
        setCity('')
        setState('')
        setZip('')
        setCountry('')
    }

    const clickPrimary = () => {
        createAddressHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <Modal
            className="create-address-modal"
            visible={true}
            title="Add new address"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <Input value={label} onChange={(e) => setLabel(e.target.value)} placeholder="LABEL" required />
            </div>
            <div className="modal-line">
                <Input value={streetAddress1} onChange={(e) => setStreetAddress1(e.target.value)} placeholder="STREET ADDRESS 1" required max={MaxCharacter.address} />
            </div>
            <div className="modal-line">
                <Input value={streetAddress2} onChange={(e) => setStreetAddress2(e.target.value)} placeholder="STREET ADDRESS 2" max={MaxCharacter.address} />
            </div>
            <div className="modal-line">
                <Input max={MaxCharacter.subAddress} value={city} onChange={(e) => setCity(e.target.value)} placeholder="CITY" required />
                <Input max={MaxCharacter.subAddress} value={state} onChange={(e) => setState(e.target.value)} placeholder="STATE" required />
            </div>
            <div className="modal-line">
                <Input max={MaxCharacter.zipCode} value={zip} onChange={(e) => setZip(e.target.value)} placeholder="POSTAL CODE" required />
                <Input max={MaxCharacter.subAddress} value={country} onChange={(e) => setCountry(e.target.value)} placeholder="COUNTRY" required />
            </div>
        </Modal>
    )
}

export default CreateAddressModal
