import { Switch, Tooltip } from '@mui/material'
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react'
import Button from '../../../../../components/Button'
import Checklist from '../../../../../components/Checklist'
import { ChecklistItemType } from '../../../../../components/Checklist/ChecklistItem'
import LabelWithLine from '../../../../../components/LabelWithLine'
import Loading from '../../../../../components/Loading'
import PopupSidebar, { PopupSidebarProps } from '../../../../../components/PopupSidebar'
import Icons, { IconType } from '../../../../../Icon'
import {
    ActivityDetailModel,
    ActivityDurationUnitType,
    ActivityEquipmentModel,
    ActivityFrequencyType,
    ActivityFrequencyUnitType,
    ActivityGroupModel,
    ActivityModel,
    ActivityProductModel,
    ActivityStatusType,
    ActivityTypes,
    UpdateActivityGroupModel,
} from '../../../../../models/Ui/Activity'
import { ActivityCustomerListModel } from '../../../../../models/Ui/Company'
import { ActivityAssigneListModel } from '../../../../../models/Ui/User'
import CompanyService from '../../../../../services/UiService/Company'
import UserService from '../../../../../services/UiService/User'
import './style.scss'
import ContractService from '../../../../../services/UiService/Contract'
import { ContractOutputModel } from '../../../../../models/Ui/Contract'
import { DropdownWithQuantityValueModel } from '../../../../../components/DropdownWithQuantity'
import { ProductBasicDto } from '../../../../../models/Ui/Product'
import { CustomerEquipmentsModel } from '../../../../../models/New/Customer'
import { FileModel } from '../../../../../services/NewServices/StorageService'
import ActivityService from '../../../../../services/UiService/Activity'
import ActivityTitleInput from '../AddActivityPopupSidebar/Title'
import ActivityAssignee from '../AddActivityPopupSidebar/Assignee'
import ActivityStartDueDate from '../AddActivityPopupSidebar/DueDate'
import ActivityFrequency, { FrequencySideOptionPositions } from '../AddActivityPopupSidebar/Frequency'
import ActivityDescriptionAndNote from '../AddActivityPopupSidebar/DescriptionAndNote'
import ActivityProducts from '../AddActivityPopupSidebar/Products'
import ActivityEquipment from '../AddActivityPopupSidebar/Equipment'
import ActivityAttachments from '../AddActivityPopupSidebar/Attachments'
import ActivityStatus from '../AddActivityPopupSidebar/Status'
import ActivityCustomer from '../AddActivityPopupSidebar/Customer'
import ActivityContract from '../AddActivityPopupSidebar/Contract'
import ActivityType from '../AddActivityPopupSidebar/Type'
import { standardDate } from '../../../../../utils/helpers'
import dayjs from 'dayjs'
import { ConfirmationContext } from '../../../../../store/ConfirmationContext'
import ActivityTaskListTable from '../../ActivityTaskListTable'
import { toast } from 'react-toastify'
import ConfirmationModal from './Components/ConfirmationModal'
import { ActivityActionTypes } from '../..'
import { CommonActionTypes } from '../../../../../models/Ui/Common'
interface Props extends Omit<PopupSidebarProps, 'children' | 'classname'> {
    id: string
    groupId: string
    onChangeId: (id: string) => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ActivityActionTypes, data?: ActivityDetailModel) => void
    onError: (error: Error) => void
}

const UpdateActivityPopupSidebar: FC<Props> = ({ visible, onClose, onLoading, onCompleted, onSuccess, onError, onChangeId, id, groupId }) => {
    const { confirm } = useContext(ConfirmationContext)
    const [taskId, setTaskId] = useState<string>(id)
    const [activity, setActivity] = useState<ActivityModel>()
    const [activitiesGroup, setActivitiesGroup] = useState<ActivityGroupModel[]>([])
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [todos, setTodos] = useState<ChecklistItemType[]>([])
    const [status, setStatus] = useState<ActivityStatusType | null>(ActivityStatusType.Open)
    const [type, setType] = useState<ActivityTypes | null>(ActivityTypes.Ticket)
    const [isBillable, setIsBillable] = useState<boolean>(false)
    const [assigneeId, setAssigneeId] = useState<string>('')
    const [assigneeList, setAssigneeList] = useState<ActivityAssigneListModel[]>([])
    const [notes, setNotes] = useState<string>('')
    const [customerId, setCustomerId] = useState<string>('')
    const [customerList, setCustomerList] = useState<ActivityCustomerListModel[]>([])
    const [shareWithCustomer, setShareWithCustomer] = useState(false)
    const [contractList, setContractList] = useState<ContractOutputModel[]>([])
    const [selectedContractId, setSelectedContractId] = useState('')
    const [productsWithQuantity, setProductsWithQuantity] = useState<DropdownWithQuantityValueModel[]>([])
    const [productList, setProductList] = useState<ProductBasicDto[]>([])
    const [equipmentIds, setEquipmentIds] = useState<string[]>([])
    const [equipmentList, setEquipmentList] = useState<CustomerEquipmentsModel[]>([])
    const [attachments, setAttachments] = useState<FileModel[]>([])
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [singleActivityDueDate, setSingleActivityDueDate] = useState<Date | null>(null)
    const [activityGroupDueDate, setActivityGroupDueDate] = useState<Date | null>(null)
    const [frequencyType, setFrequencyType] = useState<ActivityFrequencyType | null>(null)
    const [repeatCount, setRepeatCount] = useState<number>(1)
    const [repeatUnit, setRepeatUnit] = useState<ActivityFrequencyUnitType>(ActivityFrequencyUnitType.Days)
    const [durationUnit, setDurationUnit] = useState<ActivityDurationUnitType>(ActivityDurationUnitType.Days)
    const [duration, setDuration] = useState<number>(1)
    const [loading, setLoading] = useState({
        assignees: false,
        customers: false,
        contracts: false,
        products: false,
        equipments: false,
        uploadingFile: false,
        activityDetail: false,
        activitiesGroup: false,
        changingCustomer: false,
    })
    const [lastModifiedDate, setLastModifiedDate] = useState<string>('')
    const [selectedContract, setSelectContract] = useState({ id: '', text: '' })
    const [isFrequencyDropdownActive, setIsFrequencyDropdownActive] = useState<boolean>(false)
    const [focusedDueDate, setFocusedDueDate] = useState<boolean>(false)
    const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)

    const isFormValid = () => title && status && type && frequencyType && startDate && singleActivityDueDate

    const isSaveable = () => {
        if (activity && isFormValid()) {
            return (
                (singleActivityDueDate && new Date(singleActivityDueDate).toDateString()) !== new Date(activity.dueDate).toDateString() ||
                title !== activity.title ||
                status !== activity.status ||
                description !== activity.description ||
                assigneeId !== (activity?.assigneeId ?? '') ||
                type !== activity.type ||
                isBillable !== (activity.isBillable || false) ||
                activity.attachments !== JSON.stringify(attachments) ||
                notes !== activity.notes ||
                JSON.stringify(productsWithQuantity) !== JSON.stringify(activity.products.map((item) => ({ id: item.productId, quantity: item.quantity }))) ||
                JSON.stringify(equipmentIds) !== JSON.stringify(activity.equipments.map((item) => item.id)) ||
                JSON.stringify(todos) !== activity.todos ||
                (activity.isRecurring && frequencyType === ActivityFrequencyType.OneTime)
            )
        } else {
            return false
        }
    }

    const isFrequencySaveable = () =>
        activity &&
        !!activitiesGroup.length &&
        ((activityGroupDueDate && new Date(activityGroupDueDate).toDateString()) !== new Date(activitiesGroup[activitiesGroup.length - 1].dueDate).toDateString() ||
            repeatCount !== activity.frequency ||
            repeatUnit !== activity.frequencyUnit ||
            duration !== activity.duration ||
            durationUnit !== activity.durationUnit ||
            frequencyType !== activity.frequencyType)

    const onClickDelete = () => {
        confirm({
            modalTitle: 'Delete activity?',
            contentText: 'This action cannot be undone. Are you sure you want to proceed?',
            primaryButtonText: 'No',
            secondaryButtonText: 'Yes, delete forever',
            doesPrimaryButtonSubmit: false,
            buttonOrder: 'secondaryPrimary',
            func: async (successCallback, errorCallback) => {
                try {
                    onLoading()
                    await ActivityService.delete(taskId)
                    successCallback('Activity deleted successfully.')
                    onSuccess(CommonActionTypes.DELETE)
                } catch (error) {
                    errorCallback(error as Error)
                    onError(error as Error)
                } finally {
                    onCompleted()
                    onClose()
                }
            },
        })
    }

    const onClickDeleteEntireSeries = () => {
        confirm({
            modalTitle: 'Delete activity?',
            contentNode: (
                <div>
                    <div>You are attempting to delete {activitiesGroup.length} activities.</div>
                    <br />
                    <div>This action cannot be undone. Are you sure you want to proceed?</div>
                </div>
            ),
            primaryButtonText: 'No',
            secondaryButtonText: 'Yes, delete forever',
            doesPrimaryButtonSubmit: false,
            buttonOrder: 'secondaryPrimary',
            func: async (successCallback, errorCallback) => {
                try {
                    onLoading()
                    await ActivityService.deleteByGrupId(groupId)
                    successCallback('Activity deleted successfully.')
                    onSuccess(CommonActionTypes.DELETE)
                } catch (error) {
                    errorCallback(error as Error)
                    onError(error as Error)
                } finally {
                    onCompleted()
                    onClose()
                }
            },
        })
    }

    const onClickSave = async () => {
        if (isSaveable()) {
            try {
                onLoading()
                const equipments: ActivityEquipmentModel[] = []
                const products: ActivityProductModel[] = productsWithQuantity.map((child) => ({ productId: child.id as string, quantity: child.quantity }))
                equipmentIds.forEach((item) => equipments.push({ id: item }))

                const payload = {
                    id: taskId,
                    manufacturerId: activity?.manufacturerId,
                    isProductsPurchasableToCustomer: shareWithCustomer,
                    customerId: customerId || undefined,
                    title,
                    assigneeId: assigneeId || undefined,
                    status: status!,
                    dueDate: dayjs(singleActivityDueDate).format('MM/DD/YYYY'),
                    startDate: dayjs(startDate).format('MM/DD/YYYY'),
                    description: description,
                    type: type!,
                    duration,
                    durationUnit,
                    frequencyType: frequencyType!,
                    frequencyUnit: repeatUnit,
                    frequency: repeatCount,
                    isBillable,
                    isRecurring: frequencyType !== ActivityFrequencyType.OneTime,
                    todos: JSON.stringify(todos.filter((child) => child.text !== '')),
                    attachments: JSON.stringify(attachments),
                    notes,
                    equipments,
                    products,
                    contractId: selectedContractId || null,
                }

                if (activity?.isRecurring && frequencyType === ActivityFrequencyType.OneTime) {
                    await ActivityService.updateGroup(taskId, groupId, { ...payload, isChangeFrequency: true } as UpdateActivityGroupModel)
                    await getActivitiesGroup(true)
                } else {
                    await ActivityService.update(payload)
                    await getActivitiesGroup(true)
                }
            } catch (error) {
                onError(error as Error)
            } finally {
                if (confirmationModalVisible) {
                    setConfirmationModalVisible(false)
                    onClose()
                }
                onCompleted()
            }
        }
    }

    const onChangeFrequencyType = (value: ActivityFrequencyType) => {
        setFrequencyType(value)
        if (value === ActivityFrequencyType.OneTime) {
            setRepeatUnit(ActivityFrequencyUnitType.Days)
            setRepeatCount(1)
            setDuration(1)
            setDurationUnit(ActivityDurationUnitType.Days)
            setActivityGroupDueDate(startDate)
        } else if (value !== ActivityFrequencyType.Custom) {
            setDurationUnit(
                value === ActivityFrequencyType.Daily
                    ? ActivityDurationUnitType.Days
                    : value === ActivityFrequencyType.Weekly
                    ? ActivityDurationUnitType.Weeks
                    : value === ActivityFrequencyType.Monthly
                    ? ActivityDurationUnitType.Months
                    : value === ActivityFrequencyType.Annually
                    ? ActivityDurationUnitType.Years
                    : durationUnit
            )
            setRepeatUnit(
                value === ActivityFrequencyType.Daily
                    ? ActivityFrequencyUnitType.Days
                    : value === ActivityFrequencyType.Weekly
                    ? ActivityFrequencyUnitType.Weeks
                    : value === ActivityFrequencyType.Monthly
                    ? ActivityFrequencyUnitType.Months
                    : value === ActivityFrequencyType.Annually
                    ? ActivityFrequencyUnitType.Years
                    : repeatUnit
            )
            setRepeatCount(1)
            setActivityGroupDueDate(
                new Date(
                    new Date(startDate!).setDate(
                        new Date(startDate!).getDate() +
                            duration * (value === ActivityFrequencyType.Daily ? 1 : value === ActivityFrequencyType.Weekly ? 7 : value === ActivityFrequencyType.Monthly ? 30 : 365)
                    )
                )
            )
        } else {
            setDuration(1)
            setDurationUnit(ActivityDurationUnitType.Days)
            setRepeatUnit(ActivityFrequencyUnitType.Days)
            setRepeatCount(1)
            setActivityGroupDueDate(new Date(new Date(startDate!).setDate(new Date(startDate!).getDate() + 1)))
        }
    }

    const onChangeDuration = (value: number) => {
        setDuration(value)
        if (frequencyType !== ActivityFrequencyType.Custom) {
            setActivityGroupDueDate(
                new Date(
                    new Date(startDate!).setDate(
                        new Date(startDate!).getDate() +
                            value *
                                (frequencyType === ActivityFrequencyType.Daily ? 1 : frequencyType === ActivityFrequencyType.Weekly ? 7 : frequencyType === ActivityFrequencyType.Monthly ? 30 : 365)
                    )
                )
            )
        } else {
            setActivityGroupDueDate(
                new Date(
                    new Date(startDate!).setDate(
                        new Date(startDate!).getDate() +
                            value *
                                repeatCount *
                                (repeatUnit === ActivityFrequencyUnitType.Days ? 1 : repeatUnit === ActivityFrequencyUnitType.Weeks ? 7 : repeatUnit === ActivityFrequencyUnitType.Months ? 30 : 365)
                    )
                )
            )
        }
    }

    const onChangeRepeatCount = (value: number) => {
        setRepeatCount(value)
        setActivityGroupDueDate(
            new Date(
                new Date(startDate!).setDate(
                    new Date(startDate!).getDate() +
                        value *
                            duration *
                            (repeatUnit === ActivityFrequencyUnitType.Days ? 1 : repeatUnit === ActivityFrequencyUnitType.Weeks ? 7 : repeatUnit === ActivityFrequencyUnitType.Months ? 30 : 365)
                )
            )
        )
    }

    const onChangeRepeatUnit = (value: ActivityFrequencyUnitType) => {
        setRepeatUnit(value)
        setActivityGroupDueDate(
            new Date(
                new Date(startDate!).setDate(
                    new Date(startDate!).getDate() +
                        repeatCount * duration * (value === ActivityFrequencyUnitType.Days ? 1 : value === ActivityFrequencyUnitType.Weeks ? 7 : value === ActivityFrequencyUnitType.Months ? 30 : 365)
                )
            )
        )
        switch (value) {
            case ActivityFrequencyUnitType.Days:
                setDurationUnit(ActivityDurationUnitType.Days)
                break
            case ActivityFrequencyUnitType.Weeks:
                setDurationUnit(ActivityDurationUnitType.Weeks)
                break
            case ActivityFrequencyUnitType.Months:
                setDurationUnit(ActivityDurationUnitType.Months)
                break
            case ActivityFrequencyUnitType.Years:
                setDurationUnit(ActivityDurationUnitType.Years)
                break
            default:
        }
    }

    const onChangeDueDate = (value: Date) => {
        setActivityGroupDueDate(value)
        const differenceInDay = Math.ceil((new Date(value).getTime() - new Date(startDate!).getTime()) / (24 * 60 * 60 * 1000))
        if (frequencyType !== ActivityFrequencyType.Custom) {
            const output =
                frequencyType === ActivityFrequencyType.Daily
                    ? Math.floor(differenceInDay)
                    : frequencyType === ActivityFrequencyType.Weekly
                    ? Math.floor(differenceInDay / 7)
                    : frequencyType === ActivityFrequencyType.Monthly
                    ? Math.floor(differenceInDay / 30)
                    : Math.floor(differenceInDay / 365)
            setDuration(output + 1)
        } else {
            const output =
                repeatUnit === ActivityFrequencyUnitType.Days
                    ? Math.floor(differenceInDay / repeatCount)
                    : repeatUnit === ActivityFrequencyUnitType.Weeks
                    ? Math.floor(differenceInDay / (7 * repeatCount))
                    : repeatUnit === ActivityFrequencyUnitType.Months
                    ? Math.floor(differenceInDay / (30 * repeatCount))
                    : Math.floor(differenceInDay / (365 * repeatCount))
            setDuration(output + 1)
        }
    }

    const getActivityDetail = useCallback(
        async (tId?: string) => {
            try {
                setLoading((prevState) => ({ ...prevState, activityDetail: true }))
                const response = await ActivityService.get(tId || taskId)
                setActivity({
                    ...response,
                    todos: response.todos || '[]',
                    attachments: response.attachments || '[]',
                    products: response.products || [],
                    equipments: response.equipments || [],
                    duration: response.frequencyType !== ActivityFrequencyType.Custom ? response.duration : response.duration / response.frequency,
                })
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, activityDetail: false }))
            }
        },
        [taskId]
    )

    const getActivitiesGroup = useCallback(
        async (setTask = false) => {
            try {
                setLoading((prevState) => ({ ...prevState, activitiesGroup: true }))
                const response = await ActivityService.getActivitiesGroup(id!, groupId!)
                const sortedActivityGroup = response.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())
                setActivitiesGroup(sortedActivityGroup)
                setActivityGroupDueDate(new Date(sortedActivityGroup[sortedActivityGroup.length - 1].dueDate))
                if (setTask) {
                    setTaskId(id)
                    getActivityDetail(id)
                }
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, activitiesGroup: false }))
            }
        },
        [getActivityDetail, groupId, id]
    )

    const setFormFields = useCallback(() => {
        if (activity) {
            setTitle(activity.title)
            setCustomerId(activity.customerId)
            setSelectedContractId(activity.contractId)
            setSelectContract({ id: activity.contract?.id, text: activity.contract?.title })
            setStatus(activity.status)
            setDescription(activity.description)
            setAssigneeId(activity.assignee?.id ?? '')
            setRepeatCount(activity.frequency)
            setRepeatUnit(activity.frequencyUnit)
            setDuration(activity.duration)
            setDurationUnit(activity.durationUnit)
            setFrequencyType(activity.frequencyType)
            setSingleActivityDueDate(new Date(activity.dueDate))
            setStartDate(new Date(activity.startDate))
            setEquipmentIds(activity.equipments?.map((item) => item.id))
            setProductsWithQuantity(activity.products.map((item) => ({ id: item.productId, quantity: item.quantity })))
            setIsBillable(activity.isBillable)
            setType(activity.type)
            setAttachments(activity.attachments ? JSON.parse(activity.attachments) : [])
            setTodos(activity.todos ? JSON.parse(activity.todos) : [])
            setNotes(activity.notes)
            setShareWithCustomer(activity.isProductsPurchasableToCustomer)
            setLastModifiedDate(activity.updatedAt)
        }
    }, [activity])

    const getContractList = useCallback(async () => {
        if (customerId) {
            try {
                setLoading((prevState) => ({ ...prevState, contracts: true }))
                const response = await ContractService.getAllByCustomerId(customerId)
                setContractList(response)
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, contracts: false }))
            }
        }
    }, [customerId])

    const onUploadingFile = (showSpinner: boolean) => {
        setLoading((prevState) => ({ ...prevState, uploadingFile: showSpinner }))
    }

    const getEquipmentList = useCallback(async () => {
        if (customerId) {
            try {
                setLoading((prevState) => ({ ...prevState, equipments: true }))
                const companyService = new CompanyService()
                const response = await companyService.getEquipmentList(customerId)
                setEquipmentList(response)
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, equipments: false }))
            }
        }
    }, [customerId])

    const getProductList = useCallback(async () => {
        if (customerId) {
            try {
                setLoading((prevState) => ({ ...prevState, products: true }))
                const companyService = new CompanyService()
                const response = await companyService.getProductList(customerId)
                setProductList(response)
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, products: false }))
            }
        }
    }, [customerId])

    const getAssigneeList = useCallback(async () => {
        try {
            setLoading((prevState) => ({ ...prevState, assignees: true }))
            const userService = new UserService()
            const response = await userService.getUsersForActivity()
            setAssigneeList(response)
        } catch (error: any) {
            console.error(error.message)
        } finally {
            setLoading((prevState) => ({ ...prevState, assignees: false }))
        }
    }, [])

    const getCustomerList = useCallback(async () => {
        try {
            setLoading((prevState) => ({ ...prevState, customers: true }))
            const companyService = new CompanyService()
            const response = await companyService.getCustomersForActivity()
            setCustomerList(response)
        } catch (error: any) {
            console.error(error.message)
        } finally {
            setLoading((prevState) => ({ ...prevState, customers: false }))
        }
    }, [])

    useEffect(() => {
        setFormFields()
    }, [setFormFields])

    useEffect(() => {
        getContractList()
        getProductList()
        getEquipmentList()
    }, [getContractList, getProductList, getEquipmentList])

    useEffect(() => {
        getAssigneeList()
        getCustomerList()
        getActivityDetail()
        getActivitiesGroup()
    }, [getAssigneeList, getCustomerList, getActivityDetail, getActivitiesGroup])

    const showSpinner = () => Object.values(loading).some((child) => child)

    const setSwitchTitle = () => (shareWithCustomer ? 'This activity is shared with your customer, enabling them to see all activities in this series.' : 'Hidden from customer')

    const onChangeCustomer = async (val: string) => {
        if (!customerId) {
            try {
                setLoading((prevState) => ({ ...prevState, changingCustomer: true }))
                await ActivityService.changeCustomer(taskId, val)
                setCustomerId(val)
            } catch (error) {
                toast.error(error as Error)
            } finally {
                setLoading((prevState) => ({ ...prevState, changingCustomer: false }))
            }
        } else {
            confirm({
                func: async (successCallback, errorCallback) => {
                    try {
                        setLoading((prevState) => ({ ...prevState, changingCustomer: true }))
                        successCallback('', false)
                        await ActivityService.changeCustomer(taskId, val || null)
                        toast.success('Customer is changed successfully.')
                        setCustomerId(val)
                        setProductsWithQuantity([])
                        setEquipmentIds([])
                        setSelectedContractId('')
                    } catch (error) {
                        errorCallback(error as Error)
                    } finally {
                        setLoading((prevState) => ({ ...prevState, changingCustomer: false }))
                    }
                },
                modalTitle: 'Unlink customer?',
                primaryButtonText: 'Yes',
                secondaryButtonText: 'No',
                buttonOrder: 'secondaryPrimary',
                contentNode: (
                    <div>
                        <div>This activity is part of a recurring series. Unlinking this customer will remove all linked records and uploaded documents from the series.</div>
                        <br />
                        <div>This action cannot be undone. Are you sure you want to proceed?</div>
                    </div>
                ),
            })
        }
    }

    const onChangeShareWithCustomer = async (share: boolean) => {
        try {
            setLoading((prevState) => ({ ...prevState, changingCustomer: true }))
            await ActivityService.setVisibility(taskId, share)
            setShareWithCustomer(share)
            toast.success('Action successfully')
        } catch (error) {
            toast.error(error as Error)
        } finally {
            setLoading((prevState) => ({ ...prevState, changingCustomer: false }))
        }
    }

    const onSubmitFrequency = async () => {
        if (isFrequencySaveable()) {
            const submitFunc = async (successCallback?: (successMessage: string, showSuccessMessage: boolean) => void) => {
                try {
                    onLoading()
                    const equipments: ActivityEquipmentModel[] = []
                    const products: ActivityProductModel[] = productsWithQuantity.map((child) => ({ productId: child.id as string, quantity: child.quantity }))
                    equipmentIds.forEach((item) => equipments.push({ id: item }))

                    const payload = {
                        id: taskId,
                        manufacturerId: activity?.manufacturerId,
                        isProductsPurchasableToCustomer: shareWithCustomer,
                        customerId: customerId || undefined,
                        title,
                        assigneeId: assigneeId || undefined,
                        status: status!,
                        dueDate: dayjs(activityGroupDueDate).format('MM/DD/YYYY'),
                        startDate: dayjs(startDate).format('MM/DD/YYYY'),
                        description: description || undefined,
                        type: type!,
                        duration: frequencyType !== ActivityFrequencyType.Custom ? duration : duration * repeatCount,
                        durationUnit,
                        frequencyType: frequencyType!,
                        frequencyUnit: repeatUnit,
                        frequency: repeatCount,
                        isBillable,
                        isRecurring: frequencyType !== ActivityFrequencyType.OneTime,
                        todos: JSON.stringify(todos.filter((child) => child.text !== '')),
                        attachments: JSON.stringify(attachments),
                        notes,
                        equipments,
                        products,
                        contractId: selectedContractId,
                        isChangeFrequency: true,
                    }

                    if (successCallback) successCallback('', false)
                    await ActivityService.updateGroup(taskId, groupId, payload as UpdateActivityGroupModel)
                    getActivitiesGroup(true)
                } catch (error) {
                    onError(error as Error)
                } finally {
                    onCompleted()
                }
            }
            if (activity?.isRecurring) {
                confirm({
                    func: async (successCallback) => {
                        await submitFunc(successCallback)
                    },
                    modalTitle: 'Update frequency?',
                    primaryButtonText: 'Yes',
                    secondaryButtonText: 'No',
                    contentNode: (
                        <div>
                            <div>This activity is part of a recurring series. Changing the frequency will overwrite any custom dates that were previously created for this entire series.</div>
                            <br />
                            <div>This action cannot be undone. Are you sure you want to proceed?</div>
                        </div>
                    ),
                    buttonOrder: 'secondaryPrimary',
                })
            } else {
                await submitFunc()
            }
        }
    }

    const onCancelFrequency = useCallback(() => {
        if (activity) {
            setRepeatCount(activity.frequency)
            setRepeatUnit(activity.frequencyUnit)
            setDuration(activity.duration)
            setDurationUnit(activity.durationUnit)
            setFrequencyType(activity.frequencyType)
        }
        if (activitiesGroup.length) {
            setActivityGroupDueDate(new Date(activitiesGroup[activitiesGroup.length - 1].dueDate))
        }
    }, [activitiesGroup, activity])

    const onChangeContract = async (val: string) => {
        const payload = {
            manufacturerId: activity?.manufacturerId!,
            customerId: customerId,
            contractId: val,
            isProductsPurchasableToCustomer: activity?.isProductsPurchasableToCustomer!,
        }
        if (val) {
            try {
                setLoading((prevState) => ({ ...prevState, contracts: true }))
                await ActivityService.updateGroup(taskId, groupId, payload as UpdateActivityGroupModel)
                setSelectedContractId(val)
                setSelectContract({ id: val, text: val ? contractList.find((child) => child.id === val)?.title ?? '' : '' })
            } catch (error) {
                toast.error(error as Error)
            } finally {
                setLoading((prevState) => ({ ...prevState, contracts: false }))
            }
        } else {
            confirm({
                func: async (successCallback, errorCallback) => {
                    try {
                        setLoading((prevState) => ({ ...prevState, contracts: true }))
                        await ActivityService.updateGroup(taskId, groupId, payload as UpdateActivityGroupModel)
                        setSelectedContractId(val)
                        setSelectContract({ id: val, text: val ? contractList.find((child) => child.id === val)?.title ?? '' : '' })
                        successCallback('', false)
                    } catch (error) {
                        errorCallback(error as Error)
                    } finally {
                        setLoading((prevState) => ({ ...prevState, contracts: false }))
                    }
                },
                modalTitle: 'Unlink contract?',
                contentNode: (
                    <div>
                        <div>This activity is part of a recurring series. Unlinking this contract will remove it from all activities in this series.</div>
                        <br />
                        <div>This action cannot be undone. Are you sure you want to proceed?</div>
                    </div>
                ),
                primaryButtonText: 'No',
                secondaryButtonText: 'Yes',
                doesPrimaryButtonSubmit: false,
                buttonOrder: 'secondaryPrimary',
            })
        }
    }

    const onChangeActivity = (activityId: string) => {
        if (activitiesGroup.length > 1 && taskId !== activityId) {
            confirm({
                func: (successCallback) => {
                    successCallback('', false)
                    setTaskId(activityId)
                    onChangeId(activityId)
                },
                modalTitle: 'Close and view another activity?',
                contentText: 'You are trying to view a different activity. Are you sure you want to proceed?',
                primaryButtonText: 'Yes',
                secondaryButtonText: 'No',
                buttonOrder: 'secondaryPrimary',
            })
        }
    }

    const onChangeChecklist = (list: ChecklistItemType[]) => {
        setTodos(list)
    }

    const onChangeAttachments = (val: FileModel[]) => {
        if (val.length < attachments.length) {
            confirm({
                func: (successCallback) => {
                    successCallback('', false)
                    setAttachments(val)
                },
            })
        } else {
            setAttachments(val)
        }
    }

    const onChangeProducts = (val: DropdownWithQuantityValueModel[]) => {
        setProductsWithQuantity(val)
    }

    const onChangeEquipments = (val: string[]) => {
        setEquipmentIds(val)
    }

    const onClickClose = () => {
        if (isSaveable()) {
            setConfirmationModalVisible(true)
        } else {
            onClose()
        }
    }

    return (
        <>
            <PopupSidebar visible={visible} onClose={onClickClose} classname="update-activity-popup-sidebar">
                {showSpinner() && <Loading />}
                <div className="update-activity-popup-sidebar-content">
                    <div className="top-section">
                        <div className="title-section">
                            <ActivityTitleInput value={title} onChange={(val) => setTitle(val)} />
                            <div className="last-modified">{`Last modified: ${lastModifiedDate && standardDate(lastModifiedDate)}`}</div>
                        </div>
                        <div className="vertical-seperator" />
                        <div className="right-section">
                            <ActivityAssignee assignees={assigneeList} selectedAssigneeId={assigneeId} onChangeAssignee={(val) => setAssigneeId(val)} />
                            <div className="vertical-seperator" />
                            <Tooltip title={singleActivityDueDate && !focusedDueDate ? 'Due Date' : ''}>
                                <span>
                                    <ActivityStartDueDate value={singleActivityDueDate} onChange={(val) => setSingleActivityDueDate(val!)} onFocused={(active) => setFocusedDueDate(active)} />
                                </span>
                            </Tooltip>
                            <div className="vertical-seperator" />
                            <ActivityFrequency
                                duration={duration}
                                setDuration={onChangeDuration}
                                freqType={frequencyType}
                                onChangeFreqType={onChangeFrequencyType}
                                sideOptionPosition={FrequencySideOptionPositions.Left}
                                repeatCount={repeatCount}
                                setRepeatCount={onChangeRepeatCount}
                                repeatUnit={repeatUnit}
                                setRepeatUnit={onChangeRepeatUnit}
                                dueDate={activityGroupDueDate}
                                setDueDate={onChangeDueDate}
                                startDate={startDate}
                                onClickSave={onSubmitFrequency}
                                onClickCancel={onCancelFrequency}
                                isSaveable={isFrequencySaveable()}
                                onChangeActivity={(active) => setIsFrequencyDropdownActive(active)}
                            />
                            <div className="vertical-seperator" />
                            <Button className="delete-button" onClick={onClickDelete} disabled={isFrequencyDropdownActive}>
                                <Icons type={IconType.Trash} />
                                Delete
                            </Button>
                            <Button className="activity-save-button" disabled={!isSaveable() || isFrequencyDropdownActive} onClick={onClickSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <div className="body-section">
                        <div className="left-side">
                            <LabelWithLine labelText="Description" />
                            <ActivityDescriptionAndNote value={description} onChange={(value) => setDescription(value)} placeholder={'What is this activity about?'} />
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Checklist" />
                            <Checklist list={todos} onChange={onChangeChecklist} hideAddButton divideCompletedOnes editItemWithoutButton showAddInputAlways />
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Products" />
                            {customerId ? (
                                <Fragment>
                                    {productList.length ? (
                                        <ActivityProducts productList={productList} value={productsWithQuantity} onChange={onChangeProducts} />
                                    ) : (
                                        <div className="product-note">
                                            There are no products linked to {customerList.find((child) => child.id === customerId)?.name || 'customer'}. To link a product to this activity, link one to
                                            your customer first.
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <div className="product-note">To add products, link a customer first.</div>
                            )}
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Equipment" />
                            {customerId ? (
                                <Fragment>
                                    {equipmentList.length ? (
                                        <ActivityEquipment equipmentList={equipmentList} value={equipmentIds} onChange={onChangeEquipments} />
                                    ) : (
                                        <div className="equipment-note">
                                            There are no equipment records linked to {customerList.find((child) => child.id === customerId)?.name || 'customer'}. To link an equipment to this activity,
                                            link one to your customer first.
                                        </div>
                                    )}
                                </Fragment>
                            ) : (
                                <div className="equipment-note">To add equipment records, link a customer first.</div>
                            )}
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Attachments" />
                            <ActivityAttachments showDownloadButton onUploadingDowloadingFile={onUploadingFile} attachments={attachments} onChangeAttachments={onChangeAttachments} />
                            {activity?.isRecurring && !!activitiesGroup.length && (
                                <Fragment>
                                    <div className="activity-section-space" />
                                    <LabelWithLine labelText="Recurring activities" />
                                    <div className="delete-entire-series-section">
                                        <div className="delete-series-text" onClick={onClickDeleteEntireSeries}>
                                            Delete entire series
                                        </div>
                                    </div>
                                    <ActivityTaskListTable
                                        onClick={(id) => {
                                            onChangeActivity(id)
                                        }}
                                        selectedId={taskId}
                                        sortKey="dueDate"
                                        data={activitiesGroup.map((p) => {
                                            return {
                                                title: p.title,
                                                status: p.status,
                                                dueDate: standardDate(p.dueDate),
                                                name: p.assignee && p.assignee.firstName + ' ' + p.assignee.lastName,
                                                id: p.id,
                                                buttons: [],
                                            }
                                        })}
                                    />
                                </Fragment>
                            )}
                            <div className="activity-section-space" />
                        </div>
                        <div className="right-side">
                            <ActivityStatus value={status} onChange={(val) => setStatus(val)} />
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Customer" />
                            <div className={`customer-with-switch ${customerId && 'customer-selected'}`}>
                                <ActivityCustomer customerList={customerList} selectedCustomerId={customerId} onChangeCustomer={(val) => onChangeCustomer(val)} />
                                {customerId && (
                                    <Tooltip title={setSwitchTitle()}>
                                        <Switch checked={shareWithCustomer} onChange={(e) => onChangeShareWithCustomer(e.target.checked)} />
                                    </Tooltip>
                                )}
                            </div>
                            {customerId && <div className="share-customer-note">NOTE: This activity is shared with your customer, enabling them to see all activities in this series.</div>}
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Contract" />
                            {customerId ? (
                                <ActivityContract
                                    selectedContract={selectedContract}
                                    contractList={contractList}
                                    selectedContractId={selectedContractId}
                                    onChangeContractId={(val) => onChangeContract(val)}
                                />
                            ) : (
                                <div className="contract-note">To add a contract, link a customer first.</div>
                            )}
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Type" />
                            <ActivityType type={type} onChangeType={(value) => setType(value)} isBillable={isBillable} onChangeBillable={(value) => setIsBillable(value)} />
                            <div className="activity-section-space" />
                            <LabelWithLine labelText="Notes" />
                            <ActivityDescriptionAndNote value={notes} onChange={(value) => setNotes(value)} placeholder={'Add a note'} />
                            <div className="activity-section-space" />
                        </div>
                    </div>
                </div>
            </PopupSidebar>
            <ConfirmationModal onClickClose={onClose} onClickSave={onClickSave} onClickCancel={() => setConfirmationModalVisible(false)} visible={confirmationModalVisible} />
        </>
    )
}

export default UpdateActivityPopupSidebar
