import { useState } from 'react'
import CurrencyInput from '../../../../../../components/CurrencyInput'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import { EquipmentActionTypes } from '../../../../../../models/Ui/Common'
import { CreatePartModel } from '../../../../../../models/Ui/Part'
import PartListService from '../../../../../../services/UiService/PartList'
import TextArea from './../../../../../../components/TextArea/index'

interface Props {
    partListId: string
    partListName: string
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: any) => void
    onError: (error: any) => void
}

function CreatePartModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props
    const [number, setNumber] = useState<string>('')
    const [partName, setPartName] = useState<string>('')
    const [category, setCategory] = useState<string>('')
    const [brand, setBrand] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [quantity, setQuantity] = useState<string>('')
    const [pkg, setPkg] = useState<string>('')
    const [partNumber, setPartNumber] = useState<string>('')
    const [price, setPrice] = useState<number>()
    const [kitNo, setKitNo] = useState<string>('')

    async function createPartHandler() {
        const data: CreatePartModel = {
            number: number,
            name: partName,
            category: category,
            brand: brand,
            description: description,
            quantity: quantity,
            pkg: pkg,
            partNumber: partNumber,
            price: price,
            kitNo: kitNo,
            partListId: props.partListId,
            listName: props.partListName || '',
        }
        try {
            onLoading()
            await PartListService.create(props.partListId, data)
            onSuccess(EquipmentActionTypes.UPDATE)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return partNumber.length > 3 && partName.length > 3
    }

    const clearForm = () => {}

    const clickPrimary = () => {
        createPartHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <>
            <Modal
                className="edit-equipment-modal"
                visible={props.visible}
                title="Create Part"
                primaryButtonText="Save Changes"
                onClickPrimary={clickPrimary}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <div className="modal-line">
                    <Input value={number} onChange={(e) => setNumber(e.target.value)} placeholder="No." />
                </div>
                <div className="modal-line">
                    <Input value={partName} onChange={(e) => setPartName(e.target.value)} placeholder="Part name" required />
                </div>
                <div className="modal-line">
                    <Input value={category} onChange={(e) => setCategory(e.target.value)} placeholder="Product category" />
                    <Input value={brand} onChange={(e) => setBrand(e.target.value)} placeholder="Brand" />
                </div>
                <div className="modal-line">
                    <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
                </div>
                <div className="modal-line">
                    <Input value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="Qty. and unit" />
                    <Input value={pkg} onChange={(e) => setPkg(e.target.value)} placeholder="Packaging" />
                </div>
                <div className="modal-line">
                    <Input value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="MFG Part Number" required />
                </div>
                <div className="modal-line">
                    <CurrencyInput
                        placeholder="Price"
                        onChange={(price) => {
                            setPrice(price)
                        }}
                        value={price}
                        min={0}
                    />
                    <Input value={kitNo} onChange={(e) => setKitNo(e.target.value)} placeholder="Kit No." />
                </div>
            </Modal>
        </>
    )
}

export default CreatePartModal
