import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { PaginationInputModel, PaginationResponseModel } from '../../models/BaseModel'
import { DocumentListModel, CreateDocumentModel, UpdateDocumentModel, DeleteDocumentModel, PushPullEquipmentDocumentModel, DocumentModel, DocumentWithEquipmentsModel } from '../../models/New/Document'

class NewDocumentService {
    async get(id: string): Promise<DocumentModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetDocument + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Document not found!')
        }
    }
    async getDocuments(params: PaginationInputModel): Promise<PaginationResponseModel<DocumentListModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetDocuments, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }
    async getDocumentWithEquipments(params: PaginationInputModel): Promise<PaginationResponseModel<DocumentWithEquipmentsModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetDocuments, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async createDocument(data: CreateDocumentModel): Promise<DocumentModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateDocument, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Failed to create document!')
        }
    }

    async updateDocument(documentId: string, data: UpdateDocumentModel): Promise<DocumentModel> {
        try {
            const response = await MonitorApi.patch(`${ServiceFunctionLookupTable.UpdateDocument}/${documentId}`, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Failed to update document!')
        }
    }

    async deleteDocument(data: DeleteDocumentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(`${ServiceFunctionLookupTable.DeleteDocument}/${data.documentId}`, { data })
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async updateEquipmentsDocument(documentId: string, data: PushPullEquipmentDocumentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.post(`document/${documentId}/linkEquipments`, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async linkEquipments(documentId: string, equipmentIds: string[]): Promise<boolean> {
        try {
            const response = await MonitorApi.post(`/document/${documentId}/linkEquipments`, { equipmentIds }, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new NewDocumentService()
