import { FC, useEffect, useRef, useState } from 'react'
import { getElementFontSizeAsPixel } from '../../utils/helpers'

interface Props extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
    numOfRow?: number
}

let elementLineHeight = 0

const AutoResizableTextarea: FC<Props> = ({ numOfRow = 5, ...props }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (textareaRef.current) {
            elementLineHeight = (getElementFontSizeAsPixel(textareaRef.current) as number) * 1.5
        }
    }, [])

    useEffect(() => {
        if (textareaRef.current && props.value && !initialized) {
            if (textareaRef.current.scrollHeight <= elementLineHeight * numOfRow) {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
            } else {
                textareaRef.current.style.height = `${elementLineHeight * numOfRow}px`
            }
            setInitialized(true)
        }
    }, [props.value, initialized, numOfRow])

    const setHeight = () => {
        if (textareaRef.current && textareaRef.current.scrollHeight <= elementLineHeight * numOfRow) {
            textareaRef.current.style.height = `${elementLineHeight * 1.5}px`
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
        }
    }

    return (
        <textarea
            ref={textareaRef}
            {...props}
            onChange={(e) => {
                if (props.onChange) {
                    props.onChange(e)
                    setHeight()
                }
            }}
        />
    )
}

export default AutoResizableTextarea
