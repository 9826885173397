import { DocumentData, DocumentReference } from "firebase/firestore";

export default interface ManufacturerDbModel {
    name: string;
    email: string;
    // customers: DocumentReference<DocumentData>[]
    additional_emails?: string[];
    hubspot_api_url?: string;
    customer_list: CustomerForManufacturerDbModel[];
}

export interface ManufacturerUpdateModel {
    name?: string;
    email?: string;
    customers?: DocumentReference<DocumentData>[]
    additional_emails?: string[];
    hubspot_api_url?: string;
    customer_list?: CustomerForManufacturerDbModel[];
}

export interface CustomerForManufacturerDbModel {
    ref: DocumentReference<DocumentData>;
    status: CustomerStatus;
    notes?: string;
}

export enum CustomerStatus {
    NotInvited = 'not_invited',
    Pending = 'pending',
    Accepted = 'accepted',
}
