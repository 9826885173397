import React, { ChangeEventHandler, ReactElement, useEffect, useRef } from 'react'
import { Size } from '../../models/enums'
import './Textarea.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Icons, { IconType } from '../../Icon'
import { isIconDefinition } from '../../utils/helpers'

interface Props {
    autoSize?: boolean
    value?: string | number
    className?: string
    wrapperClass?: string
    size?: Size
    min?: number
    max?: number
    placeholder?: string
    type?: string
    required?: boolean
    disabled?: boolean
    outlinePlaceholder?: boolean
    startIcon?: IconDefinition | IconType
    startIconColor?: string
    endIcon?: IconDefinition | IconType
    endIconColor?: string
    customIcon?: ReactElement
    lineCount?: number
    onChange?: ChangeEventHandler<HTMLInputElement>
    onBlur?: ChangeEventHandler<HTMLInputElement>
    onFocus?: ChangeEventHandler<HTMLInputElement>
    onClickEndIcon?: () => void
}

function TextArea({ outlinePlaceholder = true, ...props }: React.PropsWithChildren<Props>) {
    const ref = useRef<HTMLTextAreaElement>(null)
    let defaultClassName: string = `${props.lineCount ? 'textarea textarea-' : `input input- ${props.autoSize && 'autoSize '}`}` + (props.size ? props.size : Size.lg)
    let hasIconClass: string = props.startIcon ? ' start-input-icon' : ''
    hasIconClass += props.endIcon ? ' end-input-icon' : ''

    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = '0px'
            ref.current.style.height = `${ref.current.scrollHeight}px`
        }
    }, [props.value])

    return (
        <div className={(props.wrapperClass ? props.wrapperClass + hasIconClass + ' input-wrapper' : 'input-wrapper' + hasIconClass) + (props.className ? ' ' + props.className : '')}>
            {props.lineCount ? (
                <textarea
                    ref={ref}
                    rows={props.lineCount}
                    disabled={props.disabled}
                    required={props.required}
                    value={props.value}
                    className={props.wrapperClass ? props.wrapperClass + ' ' + defaultClassName : defaultClassName}
                    // @ts-ignore
                    onChange={props.onChange}
                    placeholder={!outlinePlaceholder ? props.placeholder : ' '}
                />
            ) : (
                <textarea
                    ref={ref}
                    disabled={props.disabled}
                    required={props.required}
                    value={props.value}
                    className={props.wrapperClass ? props.wrapperClass + ' ' + defaultClassName : defaultClassName}
                    // @ts-ignore
                    onChange={props.onChange}
                    // @ts-ignore
                    onBlur={props.onBlur}
                    // @ts-ignore
                    onFocus={props.onFocus}
                    placeholder={!outlinePlaceholder ? props.placeholder : ' '}
                />
            )}

            {props.startIcon && (
                <span className="start-icon">
                    {isIconDefinition(props.startIcon) ? (
                        <FontAwesomeIcon icon={props.startIcon as IconDefinition} color={props.startIconColor} />
                    ) : (
                        <Icons type={props.startIcon as IconType} color={props.startIconColor} />
                    )}
                </span>
            )}
            {!props.startIcon && props.customIcon && <span className="start-icon">{props.customIcon}</span>}
            {outlinePlaceholder && (
                <div className="label-wrapper">
                    <span className="label">{props.placeholder}</span>
                </div>
            )}
            {props.endIcon && (
                <div className={`end-icon ${props.onClickEndIcon ? 'end-icon-button' : ''}`} onClick={props.onClickEndIcon}>
                    {isIconDefinition(props.endIcon) ? (
                        <FontAwesomeIcon icon={props.endIcon as IconDefinition} color={props.endIconColor} />
                    ) : (
                        <Icons className="icon" type={props.endIcon as IconType} color={props.endIconColor} />
                    )}
                </div>
            )}
        </div>
    )
}

export default TextArea
