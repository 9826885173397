import { Button } from '@mui/material'
import { FC } from 'react'
import { toast } from 'react-toastify'
import Icons, { IconType } from '../../../../../../../Icon'
import StorageService, { FileModel } from '../../../../../../../services/NewServices/StorageService'
import './style.scss'

interface Props {
    text: string
    onUploadFiles: (val: FileModel[], fileList?: FileList) => void
    onUploadingFile: (showSpinner: boolean) => void
    accept: string
}

const DrawingModalUploadSection: FC<Props> = ({ text, onUploadingFile, onUploadFiles, accept }) => {
    const onUpload = async (file: FileList) => {
        try {
            if (file) {
                onUploadingFile(true)
                const uploadFileService = new StorageService()
                const fileInfos = await uploadFileService.uploadMultiple(file as unknown as FileList)
                onUploadFiles([...fileInfos], file)
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            onUploadingFile(false)
        }
    }

    return (
        <div className="drawing-modal-upload-section">
            <span>{text}</span>
            <Button variant="contained" component="label" className="uploader-button">
                <Icons type={IconType.BoldPlus} color="#767676" />
                <input type="file" accept={accept} hidden multiple onChange={(e) => onUpload(e.target.files as FileList)} />
            </Button>
        </div>
    )
}

export default DrawingModalUploadSection
