import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from 'react-router'
import { ManufacturersCustomerModel } from '../../../../models/Ui/Manufacturer'
import CircleIconButton from '../../../../components/CircleIconButton'
import Icons, { IconType } from '../../../../Icon'
import { CustomerStatus } from '../../../../models/Db/Manufacturer'
import { arraySort, isAbbreviation } from '../../../../utils/helpers'
import './style.scss'
import PopoverItem from '../../../../components/PopoverItem'
import { CustomerContactModel } from '../../../../models/New/Customer'
import { Fragment, useContext, useRef } from 'react'
import { ConfirmationContext } from '../../../../store/ConfirmationContext'
import NoDataInfo from '../../../../components/NoDataInfo'

interface Data {
    name: string
    city?: string
    state?: string
    country?: string
    zipCode?: string
    equipmentCount: number
    status: string
    id: string
    contacts: CustomerContactModel[]
    searchFoundItem: any
    customer: ManufacturersCustomerModel
}

interface HeadCell {
    id: keyof Data | any
    label: string
}

interface Props {
    sortKey?: string
    sortDirection?: boolean
    data: Data[]
    navigate: string
    sendReminder?: (customer: ManufacturersCustomerModel) => Promise<void>
    inviteCustomer?: (customer: ManufacturersCustomerModel) => Promise<void>
    unlinkCustomer?: (customer: ManufacturersCustomerModel) => Promise<void>
}

const columns: HeadCell[] = [
    {
        id: 'name',
        label: 'Company Name',
    },
    {
        id: 'city',
        label: 'City',
    },
    {
        id: 'state',
        label: 'State',
    },
    {
        id: 'country',
        label: 'Country',
    },
    {
        id: 'zipCode',
        label: 'Zip Code',
    },
    {
        id: 'equipmentCount',
        label: 'Installs',
    },
    {
        id: 'button',
        label: '',
    },
]
interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    disable: boolean
    onclick: () => void
}

function SortingMenu(props: SortingMenuType) {
    return !props.disable ? (
        <div className="sorting-menu">{props.children}</div>
    ) : (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'button' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default function EnhancedTable(props: Props) {
    const { confirm } = useContext(ConfirmationContext)
    const [orderBy, setOrderBy] = React.useState(props.sortKey || 'name')
    const [direction, setDirection] = React.useState(!props.sortDirection)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const navigate = useNavigate()
    const customerRef = useRef<ManufacturersCustomerModel>()

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const getButtons = (customer: ManufacturersCustomerModel) => {
        const buttons: {
            icon: IconType
            tooltip?: string
            onClick: () => void
        }[] = []

        if (customer.status === CustomerStatus.NotInvited && props.inviteCustomer) {
            buttons.push({
                icon: IconType.AddCustomer,
                tooltip: 'Invite Customer',
                onClick: () => {
                    customerRef.current = customer
                    confirm({
                        modalTitle: 'Invite Customer',
                        contentNode: (
                            <Fragment>
                                <span>You are inviting a customer to collaborate with you on Monittor.</span>
                                <hr />
                                <span>
                                    Clicking on “Yes” will allow us to <b>send them an email</b> on your behalf. Are you sure you want to proceed?
                                </span>
                            </Fragment>
                        ),

                        primaryButtonText: 'Yes',
                        secondaryButtonText: 'No',
                        endLine: true,
                        func: async (successCallback, errCallback) => {
                            if (customerRef.current) {
                                try {
                                    await props.inviteCustomer!(customerRef.current)
                                    successCallback()
                                    customerRef.current = undefined
                                } catch (error) {
                                    errCallback(error as Error)
                                }
                            }
                        },
                    })
                },
            })
        }
        if (customer.status === CustomerStatus.Pending && props.sendReminder) {
            buttons.push({
                icon: IconType.Bell,
                tooltip: 'Send reminder',
                onClick: () => {
                    customerRef.current = customer
                    confirm({
                        modalTitle: 'Send reminder',
                        primaryButtonText: 'Continue',
                        func: async (successCallback, errCallback) => {
                            if (customerRef.current) {
                                try {
                                    await props.sendReminder!(customerRef.current)
                                    successCallback()
                                    customerRef.current = undefined
                                } catch (error) {
                                    errCallback(error as Error)
                                }
                            }
                        },
                    })
                },
            })
        }
        if (customer.status !== CustomerStatus.NotInvited && props.unlinkCustomer) {
            buttons.push({
                icon: IconType.RemoveCustomer,
                tooltip: 'Remove access',
                onClick: () => {
                    customerRef.current = customer
                    confirm({
                        modalTitle: 'Remove access',
                        primaryButtonText: 'Continue',
                        func: async (successCallback, errCallback) => {
                            if (customerRef.current) {
                                try {
                                    await props.unlinkCustomer!(customerRef.current)
                                    successCallback()
                                    customerRef.current = undefined
                                } catch (error) {
                                    errCallback(error as Error)
                                }
                            }
                        },
                    })
                },
            })
        }

        return buttons
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />

    const renderStatusInfo = (status: string) => {
        return (
            <PopoverItem key={status} popoverContent={status === 'not_invited' ? 'Not yet invited' : status === 'pending' ? 'Invite sent' : 'Connected'}>
                <div className={`status status-${status}`}></div>
            </PopoverItem>
        )
    }

    const foundItemControl = (data: any, keys: string) => {
        if (data[keys]) {
            return data[keys]
        }
    }
    return (
        <>
            <TableContainer className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={(direction: boolean) => setDirection(direction)} />
                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell className="font-bold" style={{ width: '30%' }} onClick={() => navigate(props.navigate + row.id)} align="left">
                                            <div className="flex">
                                                {renderStatusInfo(row.status)}
                                                <div className="row">
                                                    <span className="ellipsis-text">{row.name}</span>
                                                    {row.searchFoundItem && <strong className="contact-info">Contact: {foundItemControl(row.searchFoundItem, 'fullName')}</strong>}
                                                </div>
                                            </div>
                                            {isAbbreviation(row.name.length) && <div className="table-hover">{row.name}</div>}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} onClick={() => navigate(props.navigate + row.id)} align="left">
                                            {row.city}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} onClick={() => navigate(props.navigate + row.id)} align="left">
                                            {row.state}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} onClick={() => navigate(props.navigate + row.id)} align="left">
                                            {row.country}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} onClick={() => navigate(props.navigate + row.id)} align="left">
                                            {row.zipCode}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} onClick={() => navigate(props.navigate + row.id)} align="left">
                                            {row.equipmentCount}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} align="right">
                                            {getButtons(row.customer).map((button, index) => {
                                                return <CircleIconButton key={index} tooltip={button.tooltip} icon={button.icon} onClick={button.onClick} />
                                            })}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
