import { useEffect, useState } from 'react'
import ContentBox from '../../components/ContentBox'
import { HealthCheckModel } from '../../models/Ui/HealthCheck'
import HealthCheckService from '../../services/UiService/HealthCheck'
import PageWrapper from '../PageWrapper'

const initialHealthCheck: HealthCheckModel = {
    short: '',
    startDate: '',
    message: '',
}

const HealthCheck = () => {
    const [healthCheck, setHealthCheck] = useState<HealthCheckModel>(initialHealthCheck)
    useEffect(() => {
        const getHealthCheck = async () => {
            const healthCheckResponse = await HealthCheckService.get()
            setHealthCheck((prevState) => healthCheckResponse)
        }
        getHealthCheck()
    }, [])
    return (
        <>
            <PageWrapper>
                <ContentBox>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <h2>Healt Check</h2>
                        <span>
                            <b>Active Time For:</b> {healthCheck.short}
                        </span>
                        <span>
                            <b>Start Date:</b> {healthCheck.startDate}
                        </span>
                    </div>
                </ContentBox>
            </PageWrapper>
        </>
    )
}

export default HealthCheck
