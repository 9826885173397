import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper';
import PdfViewer from '../../../components/PdfViewer'
import ZoomButton from '../../../components/ZoomButton'
import { FileType } from '../../../models/Ui/Document'
import './DocumentDetail.scss'
import NewDocumentService from '../../../services/UiService/NewDocument'
import { DocumentModel } from '../../../models/New/Document'

function DocumentDetail() {
  const [equipmentDocument, setEquipmentDocument] = useState<DocumentModel>()
  const [fileType, setFileType] = useState<FileType>(FileType.Image)
  const [loading, setLoading] = useState<boolean>(true)
  const imageContainer = useRef<HTMLDivElement>(null)
  const panzoom = useRef<PanzoomObject | null>(null)
  const navigate = useNavigate()

  const breadcrumb: BreadcrumbModel[] = equipmentDocument ? [
    {
      name: 'Documents',
      url: '/documents',
    },
    {
      name: equipmentDocument.name,
    }
  ] : []

  const { id } = useParams()

  useEffect(() => {
    if (id) {
      getDocument(id)
    } /* else not needed */
  }, [id])

  useEffect(() => {
    if (panzoom.current === null && imageContainer.current !== null) {
      panzoom.current = Panzoom(imageContainer.current, { startScale: "1" })
    } /* else not needed */
  });

  async function getDocument(documentId: string) {
    setLoading(true);
    try {
      const document = await NewDocumentService.get(documentId)
      setEquipmentDocument(document)
      setFileType(
        new URL(document.url)
          .pathname
          .toLowerCase()
          .endsWith('.pdf')
          ? FileType.Pdf
          : FileType.Image
      )
    } catch (error: any) {
      console.error(error);
      toast.error(error)
    } finally {
      setLoading(false);
    }
  }

  function onClickPlusHandler() {
    panzoom.current?.zoomIn()
  }

  function onClickMinusHandler() {
    panzoom.current?.zoomOut()
  }

  function onClickResetHandler() {
    panzoom.current?.reset()
  }

  function onClickCloseDocumentHandler() {
    navigate('/documents')
  }

  return (
    <PageWrapper breadcrumb={breadcrumb}>
      {loading && <Loading />}
      {
        !loading && equipmentDocument &&
        <ContentBox
          className='document-detail-page'
          title={equipmentDocument.name}
        >
          {(fileType === FileType.Pdf ? (
            <div className="yellow-wrapper">
              <PdfViewer file={equipmentDocument.url} fileName={equipmentDocument.name} layout={"scroll"} onClose={onClickCloseDocumentHandler} />
            </div>
          ) : (
            <div className="yellow-wrapper">
              <ZoomButton onClickPlus={onClickPlusHandler} onClickMinus={onClickMinusHandler} onClickReset={onClickResetHandler} />

              <div style={{ display: "flex" }} ref={imageContainer}>
                <img
                  className="product-image"
                  src={equipmentDocument.url}
                  alt={equipmentDocument.name}
                />
              </div>
            </div>
          ))}
        </ContentBox>
      }
    </PageWrapper>
  )
}

export default DocumentDetail
