import Compressor from 'compressorjs'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { getRandomKey } from '../../utils/helpers'

export interface FileModel {
    name: string
    type: string
    url: string
}
export default class StorageService {
    storage = getStorage()

    compress(file: File): Promise<File> {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                success: (e) => resolve(e as File),
                error: reject,
            })
        })
    }

    async uploadMultiple(files: FileList): Promise<FileModel[]> {
        let list = []

        for (const prop of Array.from(files)) {
            const up = await this.upload(prop)
            list.push(up)
        }
        return list as FileModel[]
    }

    async upload(file: File) {
        const metadata = { contentType: file.type ? file.type : 'image/jpeg' }

        if (file.type.includes('image')) {
            const compressImage = await this.compress(file)
            const storageRef = ref(this.storage, 'images/' + getRandomKey() + '_' + file.name)
            const uploadTask = await uploadBytesResumable(storageRef, compressImage, metadata)
            const url = await getDownloadURL(uploadTask.ref)
            return {
                url,
                type: file.type,
                name: file.name,
            }
        } else {
            const storageRef = ref(this.storage, 'files/' + getRandomKey() + '_' + file.name)
            const uploadTask = await uploadBytesResumable(storageRef, file, metadata)
            const url = await getDownloadURL(uploadTask.ref)
            return {
                url,
                type: file.type,
                name: file.name,
            }
        }
    }
}
