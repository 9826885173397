import { useEffect } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { Color } from '../../models/enums'
import './Loading.scss'

function Loading() {
    useEffect(() => {
        const noDataComponents = document.querySelectorAll('.no-data-info, .no-data')
        for (let index = 0; index < noDataComponents.length; index++) {
            const element = noDataComponents[index]
            ;(element as HTMLDivElement).style.visibility = 'hidden'
        }

        return () => {
            const noDataComponents = document.querySelectorAll('.no-data-info, .no-data')
            for (let index = 0; index < noDataComponents.length; index++) {
                const element = noDataComponents[index]
                ;(element as HTMLDivElement).style.visibility = 'visible'
            }
        }
    }, [])

    return (
        <div className="loading-overlay">
            <TailSpin color={Color.yellow200} height={100} width={100} />
        </div>
    )
}

export default Loading
