import { faBriefcase, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Button from '../../components/Button'
import ContentBox from '../../components/ContentBox'
import Input from '../../components/Input'
import Loading from '../../components/Loading'
import PageWrapper from '../PageWrapper'
import Tab from '../../components/Tab'
import { Color } from '../../models/enums'
import { TabItem, Tabs } from '../../models/Ui/Tab'
import './Profile.scss'
import TokenService from '../../services/NewServices/TokenService'
import { CompanyType } from '../../models/Ui/CompanyType'

const tabItems: TabItem[] = [
    {
        id: Tabs.Details,
        name: 'Details',
        customIcon: <FontAwesomeIcon icon={faBriefcase} />,
    },
]

function Profile() {
    const auth = TokenService.getAuth()
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(auth.company.name)
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const type = auth.company.type
            if (type === CompanyType.Customer) {
                // const user = await customerService.customerDetailForCustomer(auth.company.firebaseId)
                // setCcMail(user.additional_emails || [])
            } else if (type === CompanyType.Manufacturer) {
            }
            setLoading(false)
        }
        getData()
    }, [auth.company.firebaseId, auth.company.type])

    const updateDetails = async () => {
        setLoading(true)
        try {
            if (auth.company.type === CompanyType.Customer) {
            } else if (auth.company.type === CompanyType.Manufacturer) {
            }
            toast.success('Action successful!')
        } catch (e: any) {
            toast.error(e)
        } finally {
            setLoading(false)
        }
    }

    // const onChangeCCMail = async (mails: string[]) => {
    //     if (mails.length > ccMails.length && !isValidEmail(mails[mails.length - 1])) {
    //         toast.error('Invalid email address');
    //     } else {
    //         setCcMail(mails);
    //     }
    // }

    return (
        <PageWrapper className="setting-page">
            <ContentBox
                title={
                    <div className="setting-title">
                        <FontAwesomeIcon color={Color.yellow300} icon={faCog} />
                        <span>Settings</span>
                    </div>
                }
            >
                {loading && <Loading />}
                <Tab
                    activeTab={activeTab}
                    tabs={tabItems}
                    onTabClick={(id) => {
                        setActiveTab(id)
                    }}
                ></Tab>
                {activeTab === Tabs.Details && (
                    <div className="setting-detail-wrapper">
                        <span>
                            <Input
                                placeholder="Name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                            />
                        </span>
                        <span>
                            <Input disabled placeholder="Code" value={auth.company.firebaseId} />
                        </span>
                        <span>
                            <Input disabled placeholder="Email" value={auth.company.email} />
                        </span>
                        {/* <span>CC to order confirmation emails:<Tags grid='horizontal' items={ccMails} onChange={onChangeCCMail} canBeAdded canBeDeleted /></span> */}
                        {(name == null || name === '') && <span className="setting-alert">Please write name</span>}
                        <Button onClick={updateDetails} disabled={name == null || name.length < 3}>
                            Save
                        </Button>
                    </div>
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default Profile
