import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { CreateImageModel, UpdateImageModel } from '../../models/New/Image'

export default class ImageService {
    async createImage(data: CreateImageModel[]): Promise<boolean> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateImage, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async update(id: string, data: UpdateImageModel): Promise<boolean> {
        try {
            const response = await MonitorApi.patch(ServiceFunctionLookupTable.UpdateImage + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async delete(id: string): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteImage + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
}
