import { FC } from 'react'
import './style.scss'

interface Props {
    customerName: string
    className?: string
    disabled?: boolean
}

const CustomerLine: FC<Props> = ({ customerName, disabled, className }) => {
    return (
        <div className={`activity-customer-line-container ${className ?? ''}`}>
            <span className={`value ${disabled ? 'disabled' : ''}`}>{customerName}</span>
        </div>
    )
}

export default CustomerLine
