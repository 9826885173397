import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Icons, { IconType } from '../../Icon'
import './DropdownButton.scss'

interface Props {
    className?: string
    label?: string
    selected: string
    actions: {
        text: string
        onClick: () => void
    }[]
    disabled?: boolean
}

function DropdownButton(props: React.PropsWithChildren<Props>) {
    const [visible, setVisible] = useState<boolean>(false)
    const toggleDropdown = () => setVisible(!visible)
    return (
        <div className={`dropdownbutton ${props.className}`}>
            <div className="dropdownbutton-container">
                {props.label && <span className="dropdownbutton-label">{props.label}</span>}
                <button onClick={toggleDropdown} className={`dropdowninner-button ${!props.disabled && visible ? 'focus' : ''}`}>
                    <span className={props.disabled ? 'dropdownbutton-text-disable' : 'dropdownbutton-text'}>{props.selected}</span>
                    {!props.disabled && (
                        <div className="dropdowninner-button-icon-container">
                            <Icons type={IconType.ChevronDown} />
                        </div>
                    )}
                </button>
            </div>
            {visible && !props.disabled && (
                <div className="dropdownbutton-transparent-container">
                    <div onClick={toggleDropdown} className="dropdownbutton-content">
                        {props.actions.map((action, index) => {
                            return (
                                <NavLink key={index} className={props.selected === action.text ? 'dropdownbutton-content--selected' : ''} to="#" onClick={action.onClick}>
                                    {action.text}
                                </NavLink>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DropdownButton
