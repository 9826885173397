import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'
import './Tags.scss'
import { Color } from '../../models/enums'
import Icon, { IconType } from '../../Icon'
import { TagModel } from '../../models/New/Tag'

interface Props {
    items?: TagModel[]
    canBeAdded?: boolean
    canBeDeleted?: boolean
    onClick?: (tagName: string) => void
    removeItem?: (id: string, name: string) => void
    createItem?: (items: string) => void
    grid: 'vertical' | 'horizontal'
}

function NewTags(props: Props) {
    const tagInput = useRef<HTMLInputElement>(null)
    const [visibleInput, setVisibleInput] = useState(false)
    const [newItem, setNewItem] = useState('')

    useEffect(() => {
        tagInput?.current?.focus()
    }, [visibleInput])
    const handleKeyDown = async (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (props.createItem) {
                await props.createItem!(newItem)
                setVisibleInput(false)
                setNewItem('')
            }
        }
    }

    return (
        <div style={{ flexWrap: props.grid === 'vertical' ? 'wrap' : 'initial', flexDirection: props.grid === 'vertical' ? 'row' : 'column' }} className="tag-wrapper">
            {props.canBeAdded &&
                (visibleInput ? (
                    <div className="tag-input-wrapper">
                        <input onKeyDown={handleKeyDown} ref={tagInput} className="tag-input" value={newItem} onChange={(e) => setNewItem(e.target.value)} />
                        <div
                            onClick={
                                props.createItem
                                    ? async () => {
                                          await props.createItem!(newItem)
                                          setVisibleInput(false)
                                          setNewItem('')
                                      }
                                    : () => {}
                            }
                            className="tag-input-button"
                        >
                            <FontAwesomeIcon icon={faCheck} size={'1x'} color={Color.blue900} />
                        </div>
                        <div
                            onClick={() => {
                                setVisibleInput(false)
                                setNewItem('')
                            }}
                            className="tag-input-button"
                        >
                            <FontAwesomeIcon icon={faTimes} size={'1x'} color={Color.blue900} />
                        </div>
                    </div>
                ) : (
                    <div onClick={() => setVisibleInput(true)} className="tag-input-button">
                        <Icon type={IconType.AddTag} />
                    </div>
                ))}
            {(props.items || []).map((p: any, i) => (
                <div key={i} className="tag-item">
                    <div
                        className="tag-name"
                        style={{ cursor: props.onClick ? 'pointer' : 'default' }}
                        onClick={
                            props.onClick
                                ? (event) => {
                                      props.onClick!(event.currentTarget.innerText)
                                  }
                                : () => {}
                        }
                    >
                        {p?.name}
                    </div>
                    {props.canBeDeleted && (
                        <div
                            onClick={
                                props.removeItem
                                    ? () => {
                                          props.removeItem!(p.id, p.name)
                                      }
                                    : () => {}
                            }
                            className="tag-close"
                        >
                            <Icon type={IconType.TagClose} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default NewTags
