import { AxiosRequestConfig } from 'axios'
import Storage from '../../utils/Storage'

export interface TokenRequestInterceptorOptions {
    document: any
    tokenKey?: string
}

export default class TokenRequestInterceptor {
    private storage: Storage

    constructor(options: TokenRequestInterceptorOptions) {
        this.storage = new Storage(options.document)
    }
    async onFulfilled(conf: AxiosRequestConfig): Promise<AxiosRequestConfig> {
        const token = this.storage.getCookie('token')

        if (token) {
            // @ts-ignore
            conf.headers['authorization'] = 'Bearer ' + token
        }

        return conf
    }

    onRejected(error: any): any {
        return Promise.reject(error)
    }
}
