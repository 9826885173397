import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import Button from '../../../components/Button'
import ContentBox from '../../../components/ContentBox'
import EasyIcon from '../../../components/EasyIcon'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Icons, { IconType } from '../../../Icon'
import SearchInput from '../../../components/SearchInput'
import Tab from '../../../components/Tab'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import NoData from '../../../components/NoData'
import NoDataInfo from '../../../components/NoDataInfo'
import ContractService from '../../../services/UiService/Contract'
import { ContractOutputModel } from '../../../models/Ui/Contract'
import ActiveContractsTable from './Tables/ActiveContractTable'
import OffersTable from './Tables/OffersTable'
import PopoverItem from '../../../components/PopoverItem'
import CreateContractModal from './Modals/CreateContractModal'
import './ContractList.scss'
import { ActivityCustomerListModel } from '../../../models/Ui/Company'
import CompanyService from '../../../services/UiService/Company'
import { CommonActionTypes } from '../../../models/Ui/Common'

const tabItems: TabItem[] = [
    { id: Tabs.Offers, name: 'Offers' },
    { id: Tabs.Contracts, name: 'Contracts' },
]

function ContractList() {
    const [loading, setLoading] = useState<boolean>(true)
    const [contracts, setContracts] = useState<ContractOutputModel[]>([])
    const [filterByTabContracts, setFilterByTabContracts] = useState<ContractOutputModel[]>([])
    const [filterBySearchContractsData, setFilterBySearchContractsData] = useState<ContractOutputModel[]>([])
    const [customerList, setCustomerList] = useState<ActivityCustomerListModel[]>([])
    const [activeTab, setActiveTab] = useState<number>(Tabs.Offers)
    const [createContractModalVisible, setCreateContractModalVisible] = useState<boolean>(false)

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Contracts',
        },
    ]

    const getContractsCallback = useCallback(async () => {
        setLoading(true)
        try {
            const list = await ContractService.getAll()
            setContracts(list)
        } catch (e: any) {
            toast.error(e)
            console.error(e.message)
        } finally {
            setLoading(false)
        }
    }, [])

    const getCustomerCallback = useCallback(async () => {
        try {
            setLoading(true)
            const companyService = new CompanyService()
            const response = await companyService.getCustomersForActivity()
            setCustomerList(response)
        } catch (error: any) {
            toast.error(error)
            console.error(error.message)
        } finally {
            setLoading(false)
        }
    }, [])

    const onSuccessCallback = useCallback(
        async (action: CommonActionTypes) => {
            switch (action) {
                case CommonActionTypes.CREATE:
                    await getContractsCallback()
                    break
            }
        },
        [getContractsCallback]
    )
    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    useEffect(() => {
        const loadComponent = () => {
            getContractsCallback()
        }
        loadComponent()
    }, [getContractsCallback])

    useEffect(() => {
        switch (activeTab) {
            case Tabs.Offers:
                setFilterByTabContracts(contracts.filter((contract) => !contract.startDate))
                setFilterBySearchContractsData(contracts.filter((contract) => !contract.startDate))
                break
            case Tabs.Contracts:
                setFilterByTabContracts(contracts.filter((contract) => contract.startDate))
                setFilterBySearchContractsData(contracts.filter((contract) => contract.startDate))
                break
            default:
                break
        }
    }, [activeTab, contracts])

    const onHandleSearch = (value: any[]) => {
        setFilterBySearchContractsData(value)
    }

    const onTabClick = (id: number) => setActiveTab(id)

    const openModal = async () => {
        if (customerList.length === 0) {
            await getCustomerCallback()
        }
        setCreateContractModalVisible(true)
    }

    if (contracts.length === 0) {
        return (
            <>
                {loading && <Loading />}
                <NoData pageTitle="Contracts" title="You currently do not have any contracts set up on Monittor." secondTitle="Start by adding one for a customer now!" handleClick={openModal} />
                {createContractModalVisible && (
                    <CreateContractModal
                        customers={customerList}
                        onError={onErrorCallback}
                        onSuccess={onSuccessCallback}
                        onLoading={() => setLoading(true)}
                        onCompleted={() => setLoading(false)}
                        visible={createContractModalVisible}
                        closeModal={() => setCreateContractModalVisible(false)}
                    />
                )}
            </>
        )
    }

    return (
        <PageWrapper breadcrumb={breadcrumb}>
            <ContentBox
                className="contract-list-page"
                title="Contracts"
                headerIcon={<EasyIcon icon={IconType.Contract} />}
                headerRight={
                    <div className="contract-header-right">
                        <SearchInput data={filterByTabContracts} handleSearch={onHandleSearch} searchKeys={['title', 'customer.name']} />
                        <div className="add-contract">
                            <PopoverItem popoverContent="Add new contract">
                                <Button onClick={openModal}>
                                    <Icons type={IconType.BoldPlus} />
                                </Button>
                            </PopoverItem>
                        </div>
                    </div>
                }
            >
                <Tab activeTab={activeTab} tabs={tabItems} onTabClick={onTabClick} />
                {loading && <Loading />}
                {activeTab === Tabs.Offers &&
                    (filterBySearchContractsData.length > 0 ? (
                        <OffersTable
                            updateVisible={(contractId, visible) => {
                                const contract = contracts.find((x) => x.id === contractId)

                                if (!contract) return

                                setLoading(true)

                                const updatedContract = {
                                    ...contract,
                                    isVisibleToCustomer: visible,
                                }
                                delete updatedContract.documents
                                ContractService.update(contractId, updatedContract)
                                    .then(() => {
                                        setContracts([...contracts.filter((x) => x.id !== contractId), updatedContract])
                                        toast.success('Updated successfully!')
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            }}
                            createAction={() => {
                                setCreateContractModalVisible(true)
                            }}
                            data={filterBySearchContractsData.map((contract) => {
                                return {
                                    id: contract.id,
                                    title: contract.title,
                                    customer: contract.customer.name,
                                    isVisibleToCustomer: contract.isVisibleToCustomer,
                                    price: contract.totalPrice,
                                }
                            })}
                        />
                    ) : (
                        <NoDataInfo />
                    ))}
                {activeTab === Tabs.Contracts &&
                    (filterBySearchContractsData.length > 0 ? (
                        <ActiveContractsTable
                            updateVisible={(contractId, visible) => {
                                const contract = contracts.find((x) => x.id === contractId)

                                if (!contract) return

                                setLoading(true)

                                const updatedContract = {
                                    ...contract,
                                    isVisibleToCustomer: visible,
                                }
                                delete updatedContract.documents
                                ContractService.update(contractId, updatedContract)
                                    .then(() => {
                                        setContracts([...contracts.filter((x) => x.id !== contractId), updatedContract])
                                        toast.success('Updated successfully!')
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            }}
                            createAction={() => {
                                setCreateContractModalVisible(true)
                            }}
                            data={filterBySearchContractsData.map((contract) => {
                                return {
                                    id: contract.id,
                                    title: contract.title,
                                    customer: contract.customer.name,
                                    isVisibleToCustomer: contract.isVisibleToCustomer,
                                    price: contract.totalPrice,
                                    endDate: contract.endDate,
                                    startDate: contract.startDate,
                                }
                            })}
                        />
                    ) : (
                        <NoDataInfo />
                    ))}

                {createContractModalVisible && (
                    <CreateContractModal
                        customers={customerList}
                        onError={onErrorCallback}
                        onSuccess={onSuccessCallback}
                        onLoading={() => setLoading(true)}
                        onCompleted={() => setLoading(false)}
                        visible={createContractModalVisible}
                        closeModal={() => setCreateContractModalVisible(false)}
                    />
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default ContractList
