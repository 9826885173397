import { Tooltip } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import Icons, { IconType } from '../../../../../../Icon'
import { ActivityAssigneListModel } from '../../../../../../models/Ui/User'
import { colorPallette } from '../../../../../../utils/constants'
import { arrayIterator } from '../../../../../../utils/helpers'
import { assigneeShortNameSetter } from '../../../ActivityListTable'
import './style.scss'

interface Props {
    selectedAssigneeId: string
    onChangeAssignee: (val: string) => void
    assignees: ActivityAssigneListModel[]
    showFullnameAfterSelected?: boolean
    disabled?: boolean
}

type AssigneeWithColorModel = {
    id: string
    fullName: string
    shortName: string
    color: string
}

const iteratedColorPallette = arrayIterator(colorPallette, 100)

const ActivityAssignee: FC<Props> = ({ selectedAssigneeId, onChangeAssignee, assignees, showFullnameAfterSelected, disabled }) => {
    const dropdownContainerRef = useRef<HTMLDivElement>(null)
    const [showOptions, setShowOptions] = useState(false)
    const [assigneeList, setAssigneeList] = useState<AssigneeWithColorModel[]>([])

    useEffect(() => {
        setAssigneeList(
            assignees.map((child, index) => ({ id: child.id, fullName: `${child.firstName} ${child.lastName}`, color: iteratedColorPallette[index], shortName: assigneeShortNameSetter(child) }))
        )
    }, [assignees])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
                setShowOptions(false)
            }
        }

        document.addEventListener('mousedown', (e) => handleClickOutside(e))
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const setSelectedOneName = () =>
        showFullnameAfterSelected ? assigneeList.find((child) => child.id === selectedAssigneeId)?.fullName : assigneeList.find((child) => child.id === selectedAssigneeId)?.shortName

    const setTitle = () => (!showFullnameAfterSelected ? assigneeList.find((child) => child.id === selectedAssigneeId)?.fullName ?? '' : '')

    return (
        <div className={`activity-assignee-container ${selectedAssigneeId && !showFullnameAfterSelected && 'circle-assignee-name'}`} ref={dropdownContainerRef}>
            <Tooltip title={setTitle()}>
                <div
                    style={{ backgroundColor: assigneeList.find((child) => child.id === selectedAssigneeId)?.color ?? undefined }}
                    className={`selected-one ${!selectedAssigneeId && !showOptions && 'empty-assignee'} ${showOptions && 'focused-dropdown'} ${disabled ? 'disabled-dropdown' : 'enabled-dropdown'}`}
                    onClick={() => {
                        if (!disabled) {
                            setShowOptions(!showOptions)
                        }
                    }}
                >
                    {!selectedAssigneeId ? (
                        <Fragment>
                            <Icons type={IconType.People} />
                            Assignee
                        </Fragment>
                    ) : (
                        <div className="assignee-name">{setSelectedOneName()}</div>
                    )}
                    {selectedAssigneeId && showFullnameAfterSelected && (
                        <div className="chevron-bottom">
                            <Icons type={IconType.ChevronDown} />
                        </div>
                    )}
                </div>
            </Tooltip>
            {showOptions && (
                <div className="assignee-options">
                    {assigneeList?.map((child) => (
                        <div
                            className={`assignee-option ${selectedAssigneeId === child.id && 'active-one'}`}
                            key={child.id}
                            onClick={async () => {
                                await onChangeAssignee(child.id)
                                setShowOptions(false)
                            }}
                        >
                            <div className="assignee-circle" style={{ backgroundColor: child.color }}>
                                {child.shortName}
                            </div>
                            <div className="assignee-fullname">{child.fullName}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ActivityAssignee
