import React, { useEffect, useRef, useState } from 'react'
import Box from '../../components/Box'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { MaxCharacter, Size } from '../../models/enums'
import './Login.scss'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import AuthService from '../../services/UiService/Auth'
import { isValidEmail, isValidPassword } from '../../utils/helpers'
import ReCAPTCHA from 'react-google-recaptcha'
import ReCaptcha from './../../components/ReCAPTCHA/index'

function LoginPage() {
    const skipReCaptcha = process.env.REACT_APP_SKIP_RECAPTCHA === 'true'

    let [searchParams] = useSearchParams()
    const codeWithUrl = searchParams.get('code')
    const [password, setPassword] = useState(codeWithUrl ? codeWithUrl : '')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ show: false, type: '', text: '' })
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [reButton, setReButton] = useState(skipReCaptcha)
    const reRef = useRef<ReCAPTCHA>()
    const navigate = useNavigate()

    useEffect(() => {
        const email = searchParams.get('email')?.replace(' ', '+')
        if (email) setEmail(email)
        if (codeWithUrl && codeWithUrl !== '' && codeWithUrl.length > 10) {
            setButtonDisabled(false)
        } /* else not needed */
    }, [codeWithUrl, searchParams])

    const checkUser = async () => {
        setLoading(true)
        try {
            await new AuthService().login(email, password)
            setLoading(false)
            setMessage({ show: true, type: 'success', text: 'Success, redirecting...' })
            setTimeout(() => {
                setMessage({ show: false, type: '', text: '' })
                navigate('/')
            }, 1000)
        } catch (e: any) {
            setLoading(false)
            setMessage({ show: true, type: 'error', text: 'Oops, something went wrong!' })
            setTimeout(() => {
                setMessage({ show: false, type: '', text: '' })
            }, 2000)
        } finally {
            reRef.current?.reset()
            setReButton(false)
            setButtonDisabled(true)
        }
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        setPassword(value)
        if (reButton && isValidPassword(value) && isValidEmail(email)) {
            setButtonDisabled(false)
            return
        } else {
            setButtonDisabled(true)
        }
    }

    function handleOnChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value
        setEmail(value)
        if (reButton && isValidEmail(value) && isValidPassword(password)) {
            setButtonDisabled(false)
            return
        }
        setButtonDisabled(true)
    }

    function handleOnChangeReCaptcha(e: boolean) {
        setReButton(e)
        if (isValidPassword(password) && isValidEmail(email)) {
            setButtonDisabled(false)
            return
        } else {
            setButtonDisabled(true)
        }
    }

    return (
        <div className="login-page">
            <div className="form-wrapper">
                <Box className="login-form">
                    <span className="brand-title">Log in</span>
                    <div className="description">Log in with your company email address</div>
                    <Input type="email" value={email} onChange={(e) => handleOnChangeEmail(e)} placeholder="EMAIL ADDRESS" max={MaxCharacter.email} size={Size.lg} />
                    <Input type="password" value={password} onChange={(e) => handleOnChange(e)} placeholder="PASSWORD" size={Size.lg} />
                    {!skipReCaptcha && <ReCaptcha referance={reRef} handleClick={() => handleOnChangeReCaptcha(true)} />}
                    <div className="info-text">
                        <Link to="/forgot-password">Forgot Password</Link>
                    </div>
                    {loading && <span className="loading-span" />}
                    {message.show && <div className={`message ${message.type}`}>{message.text}</div>}
                    <Button disabled={loading || buttonDisabled} onClick={checkUser}>
                        Login
                    </Button>
                    <div className="foot-note">
                        Don’t have an account?<Link to="/sign-up">Sign up here.</Link>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default LoginPage
