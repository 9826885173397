import { Tooltip } from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-toastify'
import FileInput from '../../../components/FileInput'
import Input from '../../../components/Input'
import Modal from '../../../components/Modal'
import TextArea from '../../../components/TextArea'
import Icons, { IconType } from '../../../Icon'
import { DocumentListModel } from '../../../models/New/Document'
import { getFileType, isURL, UploadFileType } from '../../../utils/helpers'
import './EditDocumentModal.scss'

interface Props {
    document: DocumentListModel
    visible: boolean
    editDocument: (document: DocumentListModel, name: string, url?: string, file?: File, description?: string) => void
    closeModal: () => void
}

function EditDocumentModal(props: Props) {
    const { document, visible, editDocument, closeModal } = props
    const fileType = getFileType(document.url)
    const [title, setTitle] = useState<string>(document.name)
    const [description, setDescription] = useState<string>(document.description)
    const [url, setUrl] = useState<string>(fileType === UploadFileType.Url ? document.url : '')
    const [file, setFile] = useState<File | null>(null)
    const [documentType, setDocumentType] = useState<UploadFileType | null | undefined>(getFileType(document.url))

    const isValidForm = () => {
        try {
            if (documentType === UploadFileType.Url && document.name === title && document.url === url && document.description === description) {
                return false
            } else if (documentType !== UploadFileType.Url && document.name === title && !file && document.description === description) {
                return false
            } else {
                if (documentType !== UploadFileType.Url) {
                    return title.length > 0 /* && file -> no need for edit */
                } else {
                    return title.length > 0 && url.length > 0 && isURL(url)
                }
            }
        } catch (error: any) {
            return false
        }
    }

    const clearForm = () => {
        setTitle('')
        setDescription('')
        setUrl('')
        setFile(null)
        setDocumentType(null)
    }

    const clickPrimary = async () => {
        editDocument(document, title, url, file!, description)
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        closeModal()
    }

    const onChangeFile = async (file: File) => {
        try {
            if (file) {
                setFile(file)
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        }
    }

    return (
        <Modal
            className="edit-document-modal"
            visible={visible}
            title="Edit document"
            primaryButtonText="Save Changes"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
        >
            <div className="modal-line">
                <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="TITLE" required />
            </div>
            {documentType !== UploadFileType.Url && (
                <div className="modal-line">
                    <FileInput
                        fileInputText="UPLOAD DOCUMENT REVISION"
                        isFileUploaded={!document}
                        name={file ? file.name : document ? document.name : 'Drag and drop file to upload or'}
                        handleChange={(e) => onChangeFile(e as File)}
                        fileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                    />
                </div>
            )}
            {documentType === UploadFileType.Url && (
                <div className="modal-line">
                    <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder="URL" required />
                </div>
            )}
            {!file && (
                <div className="attachments-preview">
                    <div className="attachments-preview__line">
                        <Tooltip title="Open in new tab">
                            <div className="attachments-preview__line__link">
                                <a rel="noreferrer" target="_blank" href={document.url}>
                                    <Icons type={IconType.GoToLink} />
                                </a>
                            </div>
                        </Tooltip>
                        <div className="attachments-preview__line__file-name">{document.name}</div>
                    </div>
                </div>
            )}

            <div className="modal-line">
                <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" lineCount={2} />
            </div>

            <div className="modal-line">
                <span className="warning-text">
                    Uploading a new document will overwrite any existing document. This action cannot be undone. If you would like to keep this existing document, you can upload a new one in the
                    Documents menu.
                </span>
            </div>
        </Modal>
    )
}

export default EditDocumentModal
