import React, { ReactNode, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'
import { Tooltip } from '@mui/material'

interface Props {
    startDate: Date | null
    placeholder?: string
    outlinePlaceholder?: boolean
    onDateChange: (date: Date) => void
    disabled?: boolean
    maxDate?: Date
    tooltip?: string
    customInput?: ReactNode
    minDate?: Date
    popperPlacement?: 'top-end' | 'bottom-end' | 'left-start'
    onChangeVisibility?: (visible: boolean) => void
    filterDate?: (date: Date) => boolean
}

function BasicDatePicker({ popperPlacement = 'top-end', outlinePlaceholder = true, ...props }: React.PropsWithChildren<Props>) {
    const [isFocused, setIsFocused] = useState(props.startDate ? true : false)

    useEffect(() => {
        setIsFocused(props.startDate ? true : false)
    }, [props.startDate])

    const comp = (
        <div className={`datepicker-wrapper ${props.disabled && 'disabled'}`}>
            <div className="absolute">{props.customInput}</div>
            <DatePicker
                minDate={props.minDate}
                disabled={props.disabled || false}
                popperPlacement={popperPlacement}
                selected={props.startDate}
                onChange={(date: Date) => {
                    props.onDateChange(date)
                }}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => setIsFocused(e.target.value ? true : false)}
                popperProps={{ strategy: 'fixed' }}
                dateFormat={'MMM dd, yyyy'}
                maxDate={props.maxDate}
                onCalendarOpen={() => props.onChangeVisibility && props.onChangeVisibility(true)}
                onCalendarClose={() => props.onChangeVisibility && props.onChangeVisibility(false)}
                filterDate={props.filterDate}
            />
            {outlinePlaceholder && <span className={`label ${isFocused ? 'focused-label' : ''}`}>{props.placeholder}</span>}
        </div>
    )

    if (props.tooltip) {
        return <Tooltip title={props.tooltip}>{comp}</Tooltip>
    } else return comp
}

export default BasicDatePicker
