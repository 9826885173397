import { useEffect, useState } from 'react'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import { EquipmentModel, NewUpdateEquipmentModel } from '../../../../../../models/New/Equipment'
import { WarrantyModel } from '../../../../../../models/Ui/Equipment'
import EquipmentService from '../../../../../../services/UiService/Equipment'
import './style.scss'
import TextArea from './../../../../../../components/TextArea/index'
import BasicDropdown from '../../../../../../components/Dropdown/BasicDropdown'
import { EquipmentActionTypes } from '../../../../../../models/Ui/Common'

export interface ParentModel {
    id: string
    text: string
}

interface Props {
    equipment: EquipmentModel
    parent: ParentModel
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel) => void
    onError: (error: any) => void
}

const warrantyDurationUnitList = [
    {
        id: 1,
        text: 'Week(s)',
    },
    {
        id: 2,
        text: 'Month(s)',
    },
    {
        id: 3,
        text: 'Year(s)',
    },
]

function EditEquipmentModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props

    const [partNumber, setPartNumber] = useState<string>('')
    const [equipmentName, setEquipmentName] = useState<string>('')
    const [description, setDescription] = useState<string | undefined>('')
    const [warranty, setWarranty] = useState<WarrantyModel>({ duration: props.equipment?.warrantyDuration, unit: props.equipment.warrantyDurationUnit })
    const [brand, setBrand] = useState<string>('')
    const [selectedUnit, setSelectedUnit] = useState({
        id: warrantyDurationUnitList.find((unit) => unit.text === props.equipment.warrantyDurationUnit)?.id,
        text: props.equipment.warrantyDurationUnit,
    })

    useEffect(() => {
        setBrand(props?.equipment?.brand ?? '')
        setPartNumber(props.equipment.partNumber)
        setEquipmentName(props.equipment.name)
        setDescription(props.equipment.description)
        setWarranty({ duration: props.equipment?.warrantyDuration, unit: props.equipment.warrantyDurationUnit })
    }, [props.equipment])

    async function updateEquipmentHandler(equipmentId: string, data: NewUpdateEquipmentModel) {
        try {
            onLoading()
            await EquipmentService.update(equipmentId, data)
            onSuccess(EquipmentActionTypes.UPDATE_EQUIPMENT, data)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return equipmentName.length > 3 && partNumber.length !== 0 && (!!selectedUnit.id || warranty.duration > 0 ? !!selectedUnit.id && warranty.duration > 0 : true)
    }

    const clearForm = () => {
        setPartNumber('')
        setEquipmentName('')
        setDescription('')
        setWarranty({} as WarrantyModel)
        setSelectedUnit({ id: undefined, text: '' })
    }

    const clickPrimary = () => {
        updateEquipmentHandler(props.equipment.id, {
            name: equipmentName,
            partNumber: partNumber,
            // parentId: "",
            description: description,
            warrantyDuration: warranty.duration,
            warrantyDurationUnit: warranty.unit,
            brand,
        })
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <>
            <Modal
                className="edit-equipment-modal"
                visible={props.visible}
                title="Edit equipment details"
                primaryButtonText="Save Changes"
                onClickPrimary={clickPrimary}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <div className="modal-line">
                    <Input value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="PART NUMBER" required />
                </div>
                <div className="modal-line">
                    <Input value={equipmentName} onChange={(e) => setEquipmentName(e.target.value)} placeholder="EQUIPMENT NAME" required />
                </div>
                <div className="modal-line">
                    <Input value={brand} onChange={(e) => setBrand(e.target.value)} placeholder="BRAND" />
                </div>
                {/*<div className="modal-line" hidden={true}>*/}
                {/*    <DropdownWithSearch selectedItem={props.parent} disabled={true} listItems={[]} placeholder="PARENT" endIcon={faChevronDown} />*/}
                {/*</div>*/}
                <span className="modal-line-title">Warranty</span>
                <div className="modal-line">
                    <Input
                        required={!!selectedUnit.id || warranty.duration > 0}
                        value={warranty?.duration?.toString() || ''}
                        type={'number'}
                        onChange={(e) => setWarranty({ ...warranty, duration: Number.parseInt(e.target.value) })}
                        placeholder="STANDARD WARRANTY DURATION"
                    />
                    <BasicDropdown
                        isClearButton
                        required={!!selectedUnit.id || warranty.duration > 0}
                        label="UNIT"
                        menuItems={warrantyDurationUnitList}
                        selectedItem={selectedUnit}
                        onChange={(data) => {
                            setSelectedUnit(data)
                            setWarranty({ ...warranty, unit: data.text })
                        }}
                        handleClearInput={() => setSelectedUnit({ id: undefined, text: '' })}
                    />
                </div>
                <div className="modal-line">
                    <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" />
                </div>
            </Modal>
        </>
    )
}

export default EditEquipmentModal
