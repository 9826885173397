import './MappingDetails.scss'
import Button from '../../../Button'
import { priceFormatter } from '../../../../utils/helpers'
import Icon from '../../../../Icon'
import Icons, { IconType } from '../../../../Icon'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { Coord, IMappingDetails } from '../../ImageMapping.interface'
import { TechnicianViewType } from '../../../../store/models'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import DeleteMappingConfirmationModal from '../../ConfirmationModal'
import { Tooltip } from '@mui/material'

const MappingDetails: FC<IMappingDetails> = ({
    mappingContainerRef,
    isShow,
    event,
    area,
    onClose,
    part,
    mappingMode,
    reassignMapping,
    deleteMapping,
    customEquipment,
    addToCart,
    partAmount,
    setPartAmount,
    scaleRatio,
}) => {
    const mappingDetailsRef = useRef<HTMLDivElement>(null)
    const [position, setPosition] = useState<Coord>()
    const [isAddToCart, setIsAddToCart] = useState<boolean>(false)
    const [deleteMappingConfirmationVisible, setDeleteMappingConfirmationVisible] = useState<boolean>(false)
    const checkoutContext = useContext(CheckoutContext)
    const isQuickCart = checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick || checkoutContext.cartInformation.technicianViewType === TechnicianViewType.PurchaseAs

    useEffect(() => {
        if (mappingDetailsRef.current) {
            setPosition({
                x: event.evt.offsetX + 10,
                y: event.evt.offsetY + 10,
            })
        }
    }, [event.evt.offsetX, event.evt.offsetY, mappingMode])

    useEffect(() => {
        if (isAddToCart) {
            setTimeout(() => {
                setIsAddToCart(false)
                onClose()
            }, 3000)
        }
    }, [isAddToCart, onClose])

    useEffect(() => {
        if (mappingDetailsRef.current) {
            mappingDetailsRef.current.style.transform = `scale(${1 / scaleRatio!})`
            if (scaleRatio !== 1) {
                mappingDetailsRef.current.style.transform += `translate(${(1 - scaleRatio!) * 50}%, ${(1 - scaleRatio!) * 50}%)`
            }
        }
    }, [scaleRatio])

    return (
        <>
            <div className={`mapping-details ${isShow && position ? 'mapping-details--visible' : ''}`} ref={mappingDetailsRef} style={{ top: position?.y, left: position?.x }}>
                <Button className="close-details" onClick={onClose}>
                    X
                </Button>
                <div className="wrapper">
                    <div className="part">
                        <div className="part__number">{part?.partNumber}</div>
                        <div className="part__name">{part?.name}</div>
                        {part?.brand && <div className="part__brand">{part?.brand}</div>}
                    </div>

                    {customEquipment && isQuickCart && (
                        <div className="quick-cart">
                            <span className="quick-cart__price">{priceFormatter(part.price || '-')}</span>
                            <div className="quick-cart__counter">
                                <div className="count-changer" onClick={() => setPartAmount(partAmount <= 1 ? 1 : partAmount - 1)}>
                                    <Icon type={IconType.Minus} />
                                </div>
                                <input
                                    type="number"
                                    min={1}
                                    value={partAmount}
                                    onBlur={() => {
                                        if (partAmount < 0) setPartAmount(1)
                                    }}
                                />
                                <div className="count-changer" onClick={() => setPartAmount(partAmount + 1)}>
                                    <Icon type={IconType.Plus} />
                                </div>
                            </div>

                            <div className="add-to-cart-wrapper">
                                <Tooltip title="Add to cart">
                                    <Button
                                        className="add-to-cart"
                                        onClick={() => {
                                            if (addToCart) {
                                                Array(partAmount - 1)
                                                    .fill(0)
                                                    .map((_) => addToCart(part))
                                                addToCart(part)
                                                setIsAddToCart(true)
                                            }
                                        }}
                                    >
                                        <Icon type={IconType.AddToCart} />
                                        Add to cart
                                    </Button>
                                </Tooltip>
                            </div>
                            {isAddToCart && <span className="updated-cart">Cart successfully updated!</span>}
                        </div>
                    )}
                    {mappingMode && !isQuickCart && (
                        <div className="action-buttons">
                            <span onClick={() => reassignMapping(area, event)}>
                                <Icons type={IconType.Pen} />
                                Reassign
                            </span>
                            <span onClick={() => setDeleteMappingConfirmationVisible(true)}>
                                <Icons type={IconType.Trash} /> Delete
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <DeleteMappingConfirmationModal
                visible={deleteMappingConfirmationVisible}
                onYesAction={() => {
                    deleteMapping(area)
                    setDeleteMappingConfirmationVisible(false)
                    onClose()
                }}
                onNoAction={() => {
                    setDeleteMappingConfirmationVisible(false)
                }}
                partName={part.name}
            />
        </>
    )
}

export default MappingDetails
