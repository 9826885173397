import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from 'react-router'
import Icons, { IconType } from '../../../Icon'
import { arraySort, isAbbreviation } from '../../../utils/helpers'
import NoDataInfo from '../../../components/NoDataInfo'
import PopoverItem from '../../../components/PopoverItem'

interface Data {
    name: string
    partNo: string
    vendor: string
    tags: string[] | any
    id: string
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    data: Data[]
    navigate: string
    setSearch: (key: string) => void
}

const columns: HeadCell[] = [
    {
        id: 'name',
        label: 'Equipment Name',
    },
    {
        id: 'vendor',
        label: 'Vendor',
    },
    {
        id: 'partNo',
        label: 'Part Number',
    },
    {
        id: 'tags',
        label: 'Tags',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return !props.disable ? (
        <div className="sorting-menu">{props.children}</div>
    ) : (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.id !== 'tags' ? 'left' : 'center'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'tags' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const navigate = useNavigate()

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell onClick={() => navigate(props.navigate + row.id)} className="font-bold" style={{ width: '30%' }} align="left">
                                            <span className="ellipsis-text">{row.name}</span>
                                            {isAbbreviation(row.name.length) && <div className="table-hover">{row.name}</div>}
                                        </TableCell>
                                        <TableCell onClick={() => navigate(props.navigate + row.id)} style={{ width: '25%' }} align="left">
                                            {row.brand}
                                        </TableCell>
                                        <TableCell onClick={() => navigate(props.navigate + row.id)} style={{ width: '25%' }} align="left">
                                            {row.partNo}
                                        </TableCell>
                                        <TableCell
                                            className="tag-th"
                                            onClick={() => {
                                                !row?.tags?.length && navigate(props.navigate + row.id)
                                            }}
                                            style={{ display: `${row.tags?.length > 0 ? 'flex' : 'table-cell'}`, justifyContent: 'right', alignItems: 'center', columnGap: '4px' }}
                                            align="left"
                                        >
                                            {(row.tags || []).map(
                                                (tag: string, i: number) =>
                                                    i === 0 && (
                                                        <div onClick={() => props.setSearch(tag)} key={i} className="tag-item">
                                                            {tag.length > 5 ? <PopoverItem popoverContent={tag}>{tag.slice(0, 5)}...</PopoverItem> : tag}
                                                        </div>
                                                    )
                                            )}
                                            {row.tags?.length > 1 && (
                                                <>
                                                    <div className="tag-item more">
                                                        <Icons type={IconType.Plus} />
                                                        <div className="more-hover">
                                                            {row.tags.map(
                                                                (tag: string, i: number) =>
                                                                    i > 0 && (
                                                                        <span onClick={() => props.setSearch(tag)} key={i}>
                                                                            {tag}
                                                                        </span>
                                                                    )
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
