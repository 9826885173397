import { MonitorApi } from '../../api'
import { QueueDto } from '../../models/Ui/Queue'

class QueueService {
    async getAll(): Promise<QueueDto[]> {
        try {
            const response = await MonitorApi.get('/queue', {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Queue not found!')
        }
    }

    async getById(id: string): Promise<QueueDto> {
        try {
            const response = await MonitorApi.get(`/queue/${id}`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Queue not found!')
        }
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new QueueService()
