import ActivityListTable, {
    activityTableColumns,
    ActivityTableDataModel,
    assigneeColorSetter,
    assigneeFirstNameSetter,
    assigneeShortNameSetter,
    AssigneeWithColorModel,
    filterAssignees,
    HeadCell,
    HeadCellType,
    setAssigneeInputPlaceholder,
    useUniqueAssigneesWithColorsSetter,
} from '../../../ActivityList/ActivityListTable'
import { ActivityModel, ActivityTypes } from '../../../../../models/Ui/Activity'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import PartialUpdateBox from '../../../ActivityList/PartialUpdateBox'
import { priceFormatter, standardDate } from '../../../../../utils/helpers'
import ActivityService from '../../../../../services/UiService/Activity'
import { toast } from 'react-toastify'
import Loading from '../../../../../components/Loading'
import Icons, { IconType } from '../../../../../Icon'
import SearchInput from '../../../../../components/SearchInput'
import { Switch, Tooltip } from '@mui/material'
import Button from '../../../../../components/Button'
import Tab from '../../../../../components/Tab'
import CheckBoxDropdownInput from '../../../../../components/CheckBoxDropdownInput'
import { CompanyType } from '../../../../../models/Ui/CompanyType'
import dayjs from 'dayjs'
import { TabItem, Tabs } from '../../../../../models/Ui/Tab'
import { CustomerManufacturerContext } from '../../../../../store/CustomerDashboardContext'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useParams } from 'react-router'
import BasicDropdown from '../../../../../components/Dropdown/BasicDropdown'
import './index.scss'
import UpdateActivityPopupSidebar from '../../../ActivityList/Modals/UpdateActivityPopupSidebar'
import AddActivityPopupSidebar from '../../../ActivityList/Modals/AddActivityPopupSidebar'
import { ActivityActionTypes, ActivitySpecificActionTypes } from '../../../ActivityList'
import { CommonActionTypes } from '../../../../../models/Ui/Common'

interface ActivityListProps {
    companyType: CompanyType
    customerId?: string
    contractId?: string
}

export interface FilterType {
    id: string | any
    text: string
}

export enum StatusFilterType {
    All = 'all',
    Open = 'open',
    InProgress = 'in-progress',
    OnHold = 'on-hold',
    Closed = 'closed',
}

export enum SortByType {
    None = 'none',
    Product = 'product',
    Equipment = 'equipment',
}

export interface DueDateFilter extends FilterType {
    key?: dayjs.OpUnitType
}

export interface StatusFilter extends FilterType {
    type?: StatusFilterType
}

export interface SortByFilter extends FilterType {
    type: SortByType
}

export interface FilterState {
    dueDate: DueDateFilter
    status: FilterType
    sortBy: SortByFilter
}

export enum FilterKeys {
    DueDate = 'dueDate',
    Type = 'type',
    Status = 'status',
    SortBy = 'sortBy',
}

const tabItems: TabItem[] = [
    {
        id: Tabs.All,
        name: 'All',
    },
    {
        id: Tabs.Service,
        name: 'Service',
        customIcon: <Icons type={IconType.Maintenance} />,
    },
    {
        id: Tabs.ProductReplenishment,
        name: 'Products',
        customIcon: <Icons type={IconType.Product} />,
    },
    {
        id: Tabs.Activities,
        name: 'Tickets',
        customIcon: <Icons type={IconType.Ticket} />,
    },
]

const ActivityTab: FC<ActivityListProps> = ({ companyType, customerId, contractId }) => {
    const { selectedManufacturerInfo: selectedManufacturer } = useContext(CustomerManufacturerContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [createModalVisible, setCreateModalVisible] = useState<boolean>(false)
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false)
    const [partialUpdateModalVisible, setPartialUpdateModalVisible] = useState<boolean>(false)
    const [activities, setActivities] = useState<ActivityModel[]>([])
    const [filteredActivitiesData, setFilteredActivitiesData] = useState<ActivityModel[]>([])
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [selectedActivtyIds, setselectedActivtyIds] = useState<{ id: string; groupId: string }>({ id: '', groupId: '' })
    const [uniqueAssigneesWithColors, setUniqueAssigneesWithColors] = useState<AssigneeWithColorModel[]>([])
    const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([])
    const [showAllAsignees, setShowAllAsignees] = useState(true)
    const [viewHistoricalOnes, setViewHistoricalOnes] = useState(false)
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)
    const [selectedProductId, setSelectedProductId] = useState<string>('')
    const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>('')
    const { id } = useParams()

    useUniqueAssigneesWithColorsSetter(activities, setUniqueAssigneesWithColors)

    useEffect(() => {
        setSelectedAssigneeIds(uniqueAssigneesWithColors.map((child) => child.assigneeId))
    }, [uniqueAssigneesWithColors])

    dayjs.extend(localizedFormat)

    const dueDateFilterTypes: DueDateFilter[] = [
        { id: ' ', text: 'All Time' },
        {
            id: dayjs(),
            text: 'Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'day'),
            text: 'Before Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'week'),
            text: 'Last Week',
            key: 'week',
        },
        {
            id: dayjs().add(1, 'week'),
            text: 'Next Week',
            key: 'week',
        },
        {
            id: dayjs(),
            text: 'This Week',
            key: 'week',
        },
        {
            id: dayjs().subtract(1, 'month'),
            text: 'Last Month',
            key: 'month',
        },
        {
            id: dayjs().add(1, 'month'),
            text: 'Next Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Year',
            key: 'year',
        },
    ]
    const statusFilterTypes: StatusFilter[] = [
        { id: ' ', text: 'All', type: StatusFilterType.All },
        { id: 'Open', text: 'Open', type: StatusFilterType.Open },
        { id: 'In Progress', text: 'In Progress', type: StatusFilterType.InProgress },
        { id: 'On Hold', text: 'On Hold', type: StatusFilterType.OnHold },
        { id: 'Closed', text: 'Closed', type: StatusFilterType.Closed },
    ]

    const sortByFilterOptions: SortByFilter[] = [
        { id: 'None', text: 'None', type: SortByType.None },
        { id: 'Product', text: 'Product', type: SortByType.Product },
        { id: 'Equipment', text: 'Equipment', type: SortByType.Equipment },
    ]

    const initialFilter: FilterState = {
        dueDate: { ...dueDateFilterTypes[0] } as DueDateFilter,
        status: { ...statusFilterTypes[0] } as FilterType,
        sortBy: sortByFilterOptions[0],
    }
    const [filterState, setFilterState] = useState<FilterState>(initialFilter)

    const setFilter = (newState: any, key: FilterKeys) =>
        setFilterState((prevState) => ({
            ...prevState,
            [key]: newState,
        }))

    const getActivitiesCallback = useCallback(async () => {
        try {
            setLoading(true)
            const response = await ActivityService.getAllByContractId(id!!)
            setActivities(response)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }, [id])

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onSuccessCallback = useCallback(
        async (action: ActivityActionTypes, data: any) => {
            switch (action) {
                case CommonActionTypes.CREATE:
                    if (data) {
                        setActivities(activities.concat(data))
                    } /* else not needed */
                    break
                case CommonActionTypes.DELETE:
                    if (data) {
                        const newData: ActivityModel[] = activities?.filter((item) => item.id !== data)
                        setActivities(newData)
                    } /* else not needed */
                    break
                case CommonActionTypes.UPDATE:
                    // const newData: ActivityModel[] = activities?.filter((item) => item.id !== data.id)
                    // setActivities(newData)
                    // setActivities((prevState) => [...prevState, data])
                    await getActivitiesCallback()
                    break
                case ActivitySpecificActionTypes.ACTIVITY_VISIBILITY:
                    await getActivitiesCallback()
                    break
            }
        },
        [activities, getActivitiesCallback]
    )

    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getActivitiesCallback()
            setLoading(false)
        }

        loadComponent()
    }, [getActivitiesCallback, selectedManufacturer])

    useEffect(() => {}, [selectedActivtyIds.id])
    const handleSearchedData = (data: ActivityModel[]) => {
        setFilteredActivitiesData(data)
    }
    const openModal = () => {
        setCreateModalVisible(true)
    }
    const toggleFilterMenu = () => {
        setFilterState(initialFilter)
        setShowFilter(!showFilter)
    }

    const onChangeVisibility = async (activityId: string, visibility: boolean) => {
        try {
            setLoading(true)
            const res = await ActivityService.setVisibility(activityId, visibility)
            getActivitiesCallback()
            toast.success(res.message)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const setActivityTableColumns = (): HeadCell[] => {
        const sortByNoneColumns: HeadCellType[] = [HeadCellType.Empty, HeadCellType.Title, HeadCellType.Customer, HeadCellType.Assignee, HeadCellType.Status, HeadCellType.DueDate, HeadCellType.Action]
        const sortByProductColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.ProductWithPartNumber,
            HeadCellType.Quantity,
            HeadCellType.TaskWithCustomer,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]
        const sortByEquipmentColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Equipment,
            HeadCellType.TaskWithCustomer,
            HeadCellType.Assignee,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]

        let output: HeadCell[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = sortByNoneColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Product) {
            output = sortByProductColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            output = sortByEquipmentColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        }

        if (partialUpdateModalVisible) {
            return output.filter((item) => ![HeadCellType.Status, HeadCellType.DueDate].includes(item.type))
        }

        return output
    }

    const activityTableDataMapper = (args: ActivityModel[]): ActivityTableDataModel[] => {
        let output: ActivityTableDataModel[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = args.map((child) => {
                return {
                    isWorkOrder: child.isWorkOrder,
                    recurring: child.isRecurring,
                    title: child.title,
                    customer: child.customer?.name,
                    assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                    status: child.status,
                    dueDate: standardDate(child.dueDate),
                    opportunity: priceFormatter(child.revenueOpportunity || '-'),
                    id: child.id,
                    groupId: child.groupId,
                    isVisibleToCustomer: child.isVisibleToCustomer,
                    description: child.description,
                    type: child.type,
                    attachments: child.attachments,
                    equipments: child.equipments,
                    products: child.products,
                    assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                    assigneeShortName: assigneeShortNameSetter(child.assignee),
                    assigneeFirstName: assigneeFirstNameSetter(child),
                }
            })
        } else if (filterState.sortBy.type === SortByType.Product) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.products.map((product) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        productId: product?.productId ?? '',
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        productName: product?.product?.name ?? '',
                        productPartNumber: product?.product?.partNumber ?? '',
                        productQuantity: product?.quantity ?? 0,
                        customerCompanyName: child.customer.name,
                        equipments: child.equipments,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.equipments.map((equipment) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        customerCompanyName: child.customer.name,
                        equipmentName: equipment?.name ?? '',
                        equipmentId: equipment?.id ?? '',
                        products: child.products,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        }

        return output
    }

    const toggleHistoricalLog = () => {
        setViewHistoricalOnes((prevState) => !prevState)
        if (filterState.status.id !== 'Closed' && !viewHistoricalOnes) {
            setFilter(statusFilterTypes[4], FilterKeys.Status)
        } else setFilter(statusFilterTypes[0], FilterKeys.Status)
    }

    const clearFilters = () => {
        setFilterState(initialFilter)
        setShowAllAsignees(true)
        setViewHistoricalOnes(false)
        setSelectedAssigneeIds(uniqueAssigneesWithColors.map((item) => item.assigneeId))
    }

    const onClickViewMoreAtPartialUpdateModal = () => {
        setPartialUpdateModalVisible(false)
        setUpdateModalVisible(true)
    }

    return (
        <>
            {loading && <Loading />}
            {activities.length === 0 ? (
                <div className="activity-tabs-no-data-container">
                    <span className="message">You currently do not have any activities. Start by adding now!</span>
                    <Button className="no-data-button" onClick={openModal}>
                        <Icons type={IconType.BoldPlus} />
                        <span>Add new</span>
                    </Button>
                </div>
            ) : (
                <div className="master-page">
                    <div className="tab-container">
                        <Tab
                            activeTab={activeTab}
                            tabs={tabItems}
                            onTabClick={(id) => {
                                setActiveTab(id)
                            }}
                        ></Tab>

                        <SearchInput
                            data={activities}
                            handleSearch={handleSearchedData}
                            searchKeys={['title', 'customer.name', 'assignedBy.firstName', 'assignedBy.lastName', 'startDate', 'frequencyUnit', 'type']}
                        />
                        <Tooltip title={showFilter ? 'Hide Filters' : 'Show Filters'}>
                            <span>
                                <Button className={showFilter ? 'filter-button--active' : 'filter-button'} onClick={toggleFilterMenu}>
                                    <Icons type={IconType.FilterSettings} />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Add new activity">
                            <span>
                                <Button className="create-activity-button" onClick={openModal}>
                                    <Icons type={IconType.PlusCircle} />
                                </Button>
                            </span>
                        </Tooltip>
                    </div>

                    {showFilter && (
                        <div className="filter-menu">
                            <BasicDropdown
                                label="View by Linked Records"
                                menuItems={sortByFilterOptions}
                                onChange={(e) => setFilter(e, FilterKeys.SortBy)}
                                selectedItem={{ text: filterState.sortBy.text, id: filterState.sortBy.id }}
                                leftIcon={IconType.Checklist}
                            />

                            <BasicDropdown
                                label="Due Date"
                                menuItems={dueDateFilterTypes}
                                onChange={(e) => setFilter(e, FilterKeys.DueDate)}
                                selectedItem={{ text: filterState.dueDate.text, id: filterState.dueDate.id }}
                                leftIcon={IconType.Calendar}
                            />
                            <CheckBoxDropdownInput
                                disabled={!uniqueAssigneesWithColors.length}
                                selectedItems={selectedAssigneeIds}
                                placeholder="Assignee"
                                listItems={(uniqueAssigneesWithColors || []).map((p) => {
                                    return {
                                        id: p.assigneeId,
                                        text: p.assigneeName,
                                    }
                                })}
                                dropIcon={true}
                                onDataChange={(data, allChecked) => {
                                    setSelectedAssigneeIds(data)
                                    setShowAllAsignees(allChecked as boolean)
                                }}
                                showAllCheckbox
                                showCustomInputLabelWhenEmpty
                                customInputLabel={setAssigneeInputPlaceholder(selectedAssigneeIds, showAllAsignees, uniqueAssigneesWithColors)}
                                wrapperClass={uniqueAssigneesWithColors.length !== selectedAssigneeIds.length ? 'input-active' : ''}
                            />
                            <BasicDropdown
                                label="Status"
                                menuItems={statusFilterTypes}
                                onChange={(e) => setFilter(e, FilterKeys.Status)}
                                selectedItem={{ text: filterState.status.text, id: filterState.status.id }}
                                leftIcon={IconType.DottedCircle}
                            />
                            <Tooltip title="View Historical Logs">
                                <Switch
                                    icon={<Icons type={IconType.HistoricalLog} />}
                                    checkedIcon={<Icons type={IconType.HistoricalLog} />}
                                    className="historicalLogSwitch"
                                    value={viewHistoricalOnes}
                                    onChange={toggleHistoricalLog}
                                />
                            </Tooltip>
                            <span className="clear-filter" onClick={clearFilters}>
                                Clear filters
                            </span>
                        </div>
                    )}

                    <div className="activityContent">
                        <div className={`activity-table ${partialUpdateModalVisible && 'onBox'}`}>
                            <ActivityListTable
                                columns={setActivityTableColumns()}
                                highlightedRowId={partialUpdateModalVisible ? selectedActivtyIds.id : ''}
                                selectedProductId={partialUpdateModalVisible ? selectedProductId : ''}
                                selectedEquipmentId={partialUpdateModalVisible ? selectedEquipmentId : ''}
                                isManufacturer={companyType === CompanyType.Manufacturer}
                                onChangeVisibility={onChangeVisibility}
                                sortKey="dueDate"
                                onHandleClick={(id, groupId, isPartial) => {
                                    if (companyType === CompanyType.Manufacturer) {
                                        if (!isPartial) {
                                            setUpdateModalVisible(true)
                                        } else {
                                            setPartialUpdateModalVisible(true)
                                        }
                                        setselectedActivtyIds({ id: id, groupId: groupId })
                                    }
                                }}
                                onClickProductAndEquipment={(productId, equipmentId) => {
                                    setSelectedProductId(productId)
                                    setSelectedEquipmentId(equipmentId)
                                }}
                                data={activityTableDataMapper(
                                    filteredActivitiesData
                                        .filter((item: ActivityModel) => {
                                            if (filterState.dueDate.id === ' ') {
                                                return item
                                            } else if (filterState.dueDate.text === 'Before Today') {
                                                return filterState.dueDate.id >= dayjs(item.dueDate)
                                            }
                                            return filterState.dueDate.id.isSame(dayjs(item.dueDate), filterState.dueDate.key)
                                        })
                                        .filter((item: ActivityModel) => filterAssignees(item, showAllAsignees, selectedAssigneeIds))
                                        .filter((item: ActivityModel) => (filterState.status.id === ' ' ? item : item.status === filterState.status.id))
                                        .filter((item: ActivityModel) => !viewHistoricalOnes || (viewHistoricalOnes && item.status === 'Closed'))
                                        .filter((item) => {
                                            if (activeTab === Tabs.Service) {
                                                return item.type === ActivityTypes.Service
                                            } else if (activeTab === Tabs.ProductReplenishment) {
                                                return item.type === ActivityTypes.ProductReplensh
                                            } else if (activeTab === Tabs.Activities) {
                                                return item.type === ActivityTypes.Ticket
                                            } else {
                                                return true
                                            }
                                        })
                                )}
                            />
                        </div>
                        {selectedActivtyIds && partialUpdateModalVisible && (
                            <div className="partial-update-box">
                                <PartialUpdateBox
                                    id={selectedActivtyIds.id}
                                    visible={!!selectedActivtyIds && partialUpdateModalVisible}
                                    closeModal={async () => {
                                        setPartialUpdateModalVisible(false)
                                    }}
                                    onLoading={onLoadingCallback}
                                    onCompleted={onCompletedCallback}
                                    onSuccess={onSuccessCallback}
                                    onError={onErrorCallback}
                                    onViewMore={onClickViewMoreAtPartialUpdateModal}
                                />
                            </div>
                        )}
                    </div>

                    {selectedActivtyIds && updateModalVisible && (
                        <UpdateActivityPopupSidebar
                            id={selectedActivtyIds.id}
                            groupId={selectedActivtyIds.groupId}
                            onChangeId={(id) => setselectedActivtyIds({ ...selectedActivtyIds, id: id })}
                            visible={updateModalVisible}
                            onClose={async () => {
                                setUpdateModalVisible(false)
                                await getActivitiesCallback()
                            }}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onSuccess={onSuccessCallback}
                            onError={onErrorCallback}
                        />
                    )}
                </div>
            )}
            {createModalVisible && (
                <AddActivityPopupSidebar
                    contractId={contractId}
                    customerId={customerId}
                    onLoading={onLoadingCallback}
                    onSuccess={onSuccessCallback}
                    onError={onErrorCallback}
                    onCompleted={onCompletedCallback}
                    visible={createModalVisible}
                    onClose={() => setCreateModalVisible(false)}
                />
            )}
        </>
    )
}

export default ActivityTab
