import React from 'react'
import HoverCard from '../../../../components/HoverCard'
import Icons, { IconType } from '../../../../Icon'
import { CompanyModel } from '../../../../models/Ui/Company'
import './style.scss'
import { FileUploader } from 'react-drag-drop-files'
import StorageService from '../../../../services/NewServices/StorageService'

interface Props {
    company: CompanyModel
    onClickUpdateLogoUrl: (id: string, url: string) => void
    onLoading: () => void
    onCompleted: () => void
    onError: (error: any) => void
}

function CompanyInfoTab(props: React.PropsWithChildren<Props>) {
    const { onLoading, onError, onCompleted } = props

    async function onChangeFile(file: File) {
        try {
            onLoading()
            const uploadFileService = new StorageService()
            const fileInfos = await uploadFileService.upload(file)
            await props.onClickUpdateLogoUrl(props.company.id, fileInfos.url)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }

    function buildSection(title: string, content: React.ReactNode, icon: IconType) {
        return (
            <div className="section">
                <Icons className="section-icon" type={icon} />
                <div className="section-container">
                    <div className="title">{title}</div>
                    <div className="content">{content}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="content-container">
            <HoverCard>
                <div className="company-info-tab-container">
                    {buildSection(
                        'LOGO',
                        props.company.logoUrl ? (
                            <div key={'add-image'} className="image-wrapper">
                                <img className="image-item" src={props.company.logoUrl} alt={props.company.name} />
                                <Icons className="icon image-update-icon" type={IconType.Reset} />
                                <FileUploader
                                    className="file-input"
                                    handleChange={(fileList: File) => onChangeFile(fileList)}
                                    name="file"
                                    children={<div className="file-input-area"></div>}
                                    types={['png', 'jpg', 'jpeg']}
                                />
                            </div>
                        ) : (
                            <div key={'add-image'} className="image-wrapper">
                                <Icons className="icon image-add-icon" type={IconType.AddCircle} />
                                <FileUploader
                                    className="file-input"
                                    handleChange={(fileList: File) => onChangeFile(fileList)}
                                    name="file"
                                    children={<div className="file-input-area"></div>}
                                    types={['png', 'jpg', 'jpeg']}
                                />
                            </div>
                        ),
                        IconType.Image
                    )}

                    {buildSection('COMPANY NAME', props.company.name, IconType.Brand)}

                    {/* {
                        buildSection(
                            'MAIN CONTACT PERSON',
                            <div className='content-row'>
                                <div>
                                    <span className='title'>First name</span>
                                    <span className='content'>{props.company.user.firstName}</span>
                                </div>

                                <div>
                                    <span className='title'>Last name</span>
                                    <span className='content'>{props.company.user.lastName}</span>
                                </div>
                            </div>,
                            IconType.Phone,
                        )
                    }

                    {buildSection('MAIN EMAIL ADDRESS', props.company.email, IconType.Mail)} */}

                    {buildSection('WEBSITE', props.company.website, IconType.Mail)}

                    {buildSection('ABOUT', props.company.about, IconType.Info)}
                </div>
            </HoverCard>
        </div>
    )
}

export default CompanyInfoTab
