import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import AdditionalForm from '../AdditionalForm'
import './style.scss'
import Icons, { IconType } from '../../Icon'
import PopoverItem from '../PopoverItem'
import CustomerCartItem from '../CustomerCartItem'
import { CustomerCheckoutContext } from '../../store/CustomerCheckoutContext/CustomerCheckoutContext'

function CustomerCart() {
    const {
        contextEquipments,
        contextProducts,
        contextCustomParts,
        contextCustomServices,
        removePart,
        addPart,
        setPartCount,
        getTotalPrice,
        removeProduct,
        setProductCount,
        addProduct,
        clearPart,
        clearProduct,
        toggleCartView,
        addCustomPart,
        removeCustomPart,
        removeAdditionalItem,
        setAdditionalItemCount,
        removeAdditionalServiceItem,
        addCustomService,
        removeCustomService,
        setAdditionalServiceItemCount,
        getCount,
        addAdditionalItem,
        addAdditionalServiceItem,
    } = useContext(CustomerCheckoutContext)
    return (
        <div className="customer-shopping-cart-container">
            <div className="cart-header">
                <div className="cart-title">
                    <Icons type={IconType.Transactions} />
                    <h4>Cart</h4>
                </div>
                <div onClick={() => toggleCartView()} className="cart-close">
                    <PopoverItem popoverContent="Collapse cart">
                        <Icons type={IconType.Close} />
                    </PopoverItem>
                </div>
            </div>
            <div className="cart-body">
                {contextEquipments.map((e, i) => {
                    return (
                        <div key={'eq' + i.toString()} className="cart-wrapper">
                            {e.parts.map((p, i) => {
                                return (
                                    <CustomerCartItem
                                        key={'part' + i.toString()}
                                        name={p.part.name}
                                        subName={e.equipment.name}
                                        serialNumber={p.part.serialNo}
                                        count={p.count}
                                        price={p.part.price || '-'}
                                        onClickPlus={() => addPart(e.equipment, p.part)}
                                        onClickMinus={() => removePart(e.equipment, p.part)}
                                        onChangeInput={(value) => setPartCount(e.equipment, p.part, value)}
                                        onClickTrash={() => clearPart(e.equipment, p.part)}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
                {contextProducts.length > 0 && (
                    <div className="cart-wrapper">
                        {contextProducts.map((p, i) => {
                            return (
                                <CustomerCartItem
                                    key={'part' + i.toString()}
                                    name={p.product.name}
                                    partNumber={p.product.part_no}
                                    price={p.product.price || '-'}
                                    count={p.count}
                                    onClickPlus={() => addProduct(p.product)}
                                    onClickMinus={() => removeProduct(p.product)}
                                    onChangeInput={(count) => setProductCount(p.product, count)}
                                    onClickTrash={() => clearProduct(p.product)}
                                />
                            )
                        })}
                    </div>
                )}
                {contextCustomParts?.length > 0 && (
                    <div className="cart-wrapper">
                        {contextCustomParts.map((p, i) => {
                            return (
                                <CustomerCartItem
                                    key={'part' + i.toString()}
                                    name={p.customPart.name}
                                    subName={p.customPart.equipment_name}
                                    serialNumber={p.customPart.serial_no}
                                    price={p.customPart.price || '-'}
                                    count={p.count}
                                    onClickPlus={() => addCustomPart(p.customPart)}
                                    onClickMinus={() => removeCustomPart(p.customPart)}
                                    onChangeInput={(count) => setAdditionalItemCount(p.customPart, count)}
                                    onClickTrash={() => removeAdditionalItem(p.customPart)}
                                />
                            )
                        })}
                    </div>
                )}
                {contextCustomServices.length > 0 && (
                    <div className="cart-wrapper">
                        {contextCustomServices.map((p, i) => {
                            return (
                                <CustomerCartItem
                                    key={'part' + i.toString()}
                                    name={p.customService.name}
                                    price={p.customService.price || '-'}
                                    count={p.count}
                                    onClickTrash={() => removeAdditionalServiceItem(p.customService)}
                                    onClickPlus={() => addCustomService(p.customService)}
                                    onClickMinus={() => removeCustomService(p.customService)}
                                    onChangeInput={(count) => setAdditionalServiceItemCount(p.customService, count)}
                                />
                            )
                        })}
                    </div>
                )}
            </div>
            <div className="cart-footer">
                <div className="total-price-info">
                    <div className="total-price">
                        <div className="total-price-title">Total</div>
                        <div className="total-price-value"> {getTotalPrice()}</div>
                    </div>
                </div>
                <div className="under-side">
                    <AdditionalForm addAdditionalItem={addAdditionalItem} addAdditionalServiceItem={addAdditionalServiceItem} menuPosition="up" className="cart-add-more" />
                    <Link to="/checkoutPage">
                        <Button disabled={!getCount()} onClick={() => toggleCartView(false)} className="button">
                            Checkout
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CustomerCart
