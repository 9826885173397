import React from 'react'
import { AccountManagerModel } from '../../../../../models/Ui/Company'
import NotificationModel from '../../../../../models/Ui/Notification'
import Contact from './Contact'
import './Network.scss'
import Notification from './Notification'

interface Props {
    notifications: NotificationModel[]
    accountManager?: AccountManagerModel
}

function Network(props: Props) {
    return (
        <div className="network-container">
            <div className="network-content">
                <Notification notifications={props.notifications} />
                <Contact representative={props.accountManager} />
            </div>
        </div>
    )
}

export default Network
