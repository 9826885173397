import { ChangeEvent, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import Icons, { IconType } from '../../../../Icon'
import { arraySort } from '../../../../utils/helpers'
import './style.scss'
import { ignoreDashClassname } from '../../../../hooks/useTableHook'
import NoDataInfo from '../../../../components/NoDataInfo'

interface Data {
    firstName: string
    lastName?: string
    companyName: string
    jobTitle?: string
    phone?: string
    email: string
    isPrimary: boolean
}

interface HeadCell {
    id: keyof Data | any
    label: string
}

interface Props {
    sortKey?: string
    sortDirection?: boolean
    data: Data[]
}

const columns: HeadCell[] = [
    {
        id: 'firstName',
        label: 'First Name',
    },
    {
        id: 'lastName',
        label: 'Last Name',
    },
    {
        id: 'companyName',
        label: 'Company',
    },
    {
        id: 'jobTitle',
        label: 'Job Title',
    },
    {
        id: 'phone',
        label: 'Phone',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'isPrimary',
        label: ' ',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    disable: boolean
    onclick: () => void
}

function SortingMenu(props: SortingMenuType) {
    return !props.disable ? (
        <div className="sorting-menu">{props.children}</div>
    ) : (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}

interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'button' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = useState(props.sortKey || 'name')
    const [direction, setDirection] = useState(!props.sortDirection)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />

    return (
        <>
            <TableContainer className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={(direction: boolean) => setDirection(direction)} />
                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={row.id}>
                                        <TableCell width="10%" align="left">
                                            {row.firstName}
                                        </TableCell>
                                        <TableCell width="10%" align="left">
                                            {row.lastName}
                                        </TableCell>
                                        <TableCell width="10%" align="left">
                                            {row.companyName}
                                        </TableCell>
                                        <TableCell width="10%" align="left">
                                            {row.jobTitle}
                                        </TableCell>
                                        <TableCell width="10%" align="left">
                                            {row.phone}
                                        </TableCell>
                                        <TableCell width="10%" align="left">
                                            {row.email}
                                        </TableCell>
                                        <TableCell className={ignoreDashClassname} style={{ width: '4%', padding: '0' }} align="center">
                                            {row.isPrimary ? <Icons type={IconType.Star} color="#FDC00E" /> : null}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
