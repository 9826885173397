import { useEffect, useState } from 'react'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import { EquipmentModel, UpdateTemplateFromAssemblyModel } from '../../../../../../models/New/Equipment'
import { NewUpdateAssemblyModel, NewUpdateEquipmentModel } from '../../../../../../models/Ui/Equipment'
import EquipmentService from '../../../../../../services/UiService/Equipment'
import './style.scss'
import TextArea from './../../../../../../components/TextArea/index'
import { EquipmentActionTypes } from '../../../../../../models/Ui/Common'
import SearchableDropdown from '../../../../../../components/Dropdown/SearchableDropdown'
import { toast } from 'react-toastify'

export interface ParentModel {
    id: string
    text: string
}

interface Props {
    isRootEquipmentId: string
    isLinkedTemplate?: boolean
    equipment: EquipmentModel
    assemblyId: string
    parentId: string
    visible: boolean
    parentList: ParentModel[]
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel | EquipmentModel) => void
    onError: (error: any) => void
}

function EditAssemblyModal(props: Props) {
    const { parentList, assemblyId, onLoading, onError, onSuccess, onCompleted } = props
    const [currentAssembly, setCurrentAssembly] = useState<EquipmentModel | null>(null)
    const [partNumber, setPartNumber] = useState<string>('')
    const [assemblyName, setAssemblyName] = useState<string>('')
    const [description, setDescription] = useState<string | undefined>('')
    // const [showList, setShowList] = useState<boolean>(false)
    const [parentId, setParentId] = useState<string>()

    useEffect(() => {
        setCurrentAssembly(props.equipment)
        setAssemblyName(props?.equipment?.name ?? '')
        setPartNumber(props.equipment.partNumber)
        setDescription(props.equipment.description)
        setParentId(props.parentId)
    }, [props.equipment, props.parentId])

    async function updateAssemblyHandler() {
        try {
            onLoading()
            if (!props.isLinkedTemplate) {
                const data: NewUpdateAssemblyModel = {
                    name: assemblyName,
                    partNumber: partNumber,
                    parentId: parentId ?? props.parentId,
                    description: description,
                    // showList: showList,
                    showList: false,
                }
                await EquipmentService.update(assemblyId, data)
                onSuccess(EquipmentActionTypes.UPDATE_ASSEMBLY, data)
            } else if (props.parentId) {
                const payload: UpdateTemplateFromAssemblyModel = {
                    topEquipmentId: props.isRootEquipmentId,
                    oldParentId: props.parentId,
                    childId: currentAssembly?.id!,
                    newParentId: parentId!,
                }
                await EquipmentService.updateTemplateToAssembly(payload)
                toast.success('Updated successfully!')
                onSuccess(EquipmentActionTypes.UPDATE_ASSEMBLY)
            }
        } catch (error: any) {
            onError(error)
            onCompleted() // Special case
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return (
            (props.parentId !== parentId || currentAssembly?.partNumber !== partNumber || currentAssembly?.description !== description || currentAssembly?.name !== assemblyName) &&
            assemblyName.length > 3 &&
            partNumber.length !== 0
        )
    }

    const clearForm = () => {
        setPartNumber('')
        setAssemblyName('')
        setDescription('')
    }

    const clickPrimary = () => {
        updateAssemblyHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <>
            <Modal
                className="edit-assembly-modal"
                visible={props.visible}
                title="Edit assembly details"
                primaryButtonText="Save Changes"
                onClickPrimary={clickPrimary}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <div className="modal-line">
                    <Input disabled={props.isLinkedTemplate} value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="PART NUMBER" required />
                </div>
                <div className="modal-line">
                    <Input disabled={props.isLinkedTemplate} value={assemblyName} onChange={(e) => setAssemblyName(e.target.value)} placeholder="EQUIPMENT NAME" required />
                </div>
                <div className="modal-line">
                    <SearchableDropdown
                        label="PARENT"
                        data={parentList
                            .filter((parent) => parent.id !== currentAssembly?.id)
                            .map((item) => {
                                return {
                                    id: item.id,
                                    label: item.text,
                                }
                            })}
                        onChange={(event, data) => {
                            setParentId(data?.id ?? '')
                        }}
                        selectedItem={{ id: parentId as string, label: parentList.find((item) => item.id === parentId)?.text || '' }}
                    />
                    {/*<DropdownWithSearch*/}
                    {/*    selectedItem={props.parent}*/}
                    {/*    listItems={parentList}*/}
                    {/*    placeholder="PARENT"*/}
                    {/*    dropIcon={true}*/}
                    {/*    onDataChange={(data) => {*/}
                    {/*        setParentId(data.id)*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
                <div className="modal-line">
                    <TextArea disabled={props.isLinkedTemplate} value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" />
                </div>
                {/* <div className='modal-line-checkbox'>
                    <Checkbox
                        checked={showList}
                        onChange={
                            () => {
                                setShowList(!showList)
                            }
                        } />
                    <span className='modal-line-text'>Show in equipment list</span>
                </div> */}
            </Modal>
        </>
    )
}

export default EditAssemblyModal
