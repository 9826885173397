import React from 'react'
import { useNavigate } from 'react-router';
import CircleIconButton from '../../../components/CircleIconButton';
import { IconType } from '../../../Icon';
import { CustomerStatus } from '../../../models/Db/Manufacturer';
import { Color } from '../../../models/enums';
import { ManufacturersCustomerModel } from '../../../models/Ui/Manufacturer';
import { arraySort } from '../../../utils/helpers';
import './CustomerList.scss'

interface Props {
    customers: ManufacturersCustomerModel[];
    hideStatus?: boolean;
    sendReminder?: (customer: ManufacturersCustomerModel) => Promise<void>;
    inviteCustomer?: (customer: ManufacturersCustomerModel) => Promise<void>;
    removeCustomer?: (customer: ManufacturersCustomerModel) => Promise<void>;
    unlinkCustomer?: (customer: ManufacturersCustomerModel) => Promise<void>;
    //cloneEquipment?: (customer: ManufacturersCustomerModel) => void;
}

function CustomerList(props: Props) {

    const navigate = useNavigate()

    const getButtons = (customer: ManufacturersCustomerModel) => {
        const buttons: {
            icon: IconType;
            tooltip?: string;
            onClick: () => void;
        }[] = [];

        if (customer.status === CustomerStatus.NotInvited && props.inviteCustomer) {
            buttons.push(
                {
                    icon: IconType.AddCustomer,
                    tooltip: 'Invite Customer',
                    onClick: async () => await props.inviteCustomer!(customer),
                }
            )
        }
        if (customer.status === CustomerStatus.Pending && props.sendReminder) {
            buttons.push(
                {
                    icon: IconType.Bell,
                    tooltip: 'Send reminder',
                    onClick: async () => await props.sendReminder!(customer),
                }
            )
        }
        if (customer.status !== CustomerStatus.NotInvited && props.unlinkCustomer) {
            buttons.push(
                {
                    icon: IconType.RemoveCustomer,
                    tooltip: 'Remove access',
                    onClick: async () => await props.unlinkCustomer!(customer),
                }
            )
        }

        if (props.removeCustomer) {
            buttons.push({
                icon: IconType.Trash,
                tooltip: 'Remove customer',
                onClick: async () => await props.removeCustomer!(customer),
            })
        }

/*         if (props.cloneEquipment) {
            buttons.push({
                icon: IconType.Copy,
                tooltip: 'Clone Equipment',
                onClick: async () => props.cloneEquipment!(customer),
            })
        } */

        return buttons;
    }


    const getStatusText = (status: CustomerStatus) => {
        switch (status) {
            case CustomerStatus.NotInvited: return <span style={{ color: Color.grey600 }}>Not Invited</span>
            case CustomerStatus.Pending: return <span style={{ color: Color.red500 }}>Pending</span>
            case CustomerStatus.Accepted: return <span style={{ color: Color.green100 }}>Invite accepted</span>
        }
    }

    return (
        <div className={`customer-list-container`}>
            {
                arraySort(props.customers,'name').map((customer, i) => {
                    return (
                        <div key={i} className="customer-list-item">
                            <div onClick={() => navigate('/customer/' + customer.id)} className="customer-list-info">
                                <div className='customer-list-item'>
                                    <h4 className='customer-name'>{customer.name}</h4>
                                    <div className='row-container'>
                                        <span className='title'>Email address: </span>
                                        <span className='content'>{customer.email}</span>
                                    </div>
                                    {
                                        props.hideStatus == null && !props.hideStatus
                                        && <div className='row-container'>
                                            <span className='title'>Status: </span>
                                            {getStatusText(customer.status)}
                                        </div>
                                    }
                                    {
                                        customer.equipmentCount
                                        && <div className='row-container'>
                                            <span className='title'>Number of Units: </span>
                                            {customer.equipmentCount}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="customer-list-button">
                                <div className='button-group-container'>
                                    {
                                        getButtons(customer).map((button, index) => {
                                            return <CircleIconButton
                                                key={index}
                                                tooltip={button.tooltip}
                                                icon={button.icon}
                                                onClick={button.onClick}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    )
                })
            }
        </div>
    )
}

export default CustomerList
