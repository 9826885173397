/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import Button, { ButtonType } from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Color } from '../../models/enums'
import { faAngleUp, faBars, faBell, faCog, faDolly, faFlask, faIndustry, faSignOutAlt, faVial } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import Cart from '../../components/Cart/Cart'
import Icons, { IconType } from '../../Icon'
import { CompanyType } from '../../models/Ui/CompanyType'
import NotificationService from '../../services/UiService/Notification'
import { SocketContext } from '../../store/SocketContext'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'

interface Props {
    type: CompanyType
    onLogout: () => void
    className?: string
}

interface MenuItem {
    text: string
    icon: React.ReactNode
    action: () => void
}

function MobilePageWrapper(props: PropsWithChildren<Props>) {
    const { notificationsUpdated } = useContext(SocketContext)
    const checkoutContext = useContext(CheckoutContext)
    const navigate = useNavigate()

    const notificationService = useMemo(() => {
        return new NotificationService()
    }, [])

    const [showMenu, setShowMenu] = useState(false)
    const [notificationCount, setNotificationCount] = useState(0)

    const getMenuItems = (items: MenuItem[]) => {
        return items.map((item, index) => {
            return (
                <div key={'menu-item-' + index} onClick={item.action} className="menu-item">
                    <div className="menu-item-left">{item.icon}</div>
                    <div className="menu-item-right">{item.text}</div>
                </div>
            )
        })
    }

    useEffect(() => {
        const getNotificationCount = async () => {
            const countResponse = await notificationService.getUnreadCount()
            setNotificationCount(countResponse.count)
        }
        getNotificationCount()
    }, [notificationsUpdated])

    const onClickMenu = () => {
        if (checkoutContext.cartInformation.show) checkoutContext.toggleCartView()
        setShowMenu(!showMenu)
    }
    const onClickCart = () => {
        checkoutContext.toggleCartView()
        setShowMenu(false)
    }

    const getBellIcon = () => {
        if (notificationCount) {
            return (
                <div className="bell-icon-wrapper">
                    <FontAwesomeIcon color={Color.blue900} className="icon" icon={faBell} />
                    <div className="red-dot" />
                </div>
            )
        } else {
            return <FontAwesomeIcon color={Color.blue900} className="icon" icon={faBell} />
        }
    }

    if (props.type === CompanyType.Customer) {
        return (
            <div className={props.className ? props.className + ' page-wrapper mobile' : 'page-wrapper mobile'}>
                <div className="page-wrapper-space"></div>
                <div className="page-header">
                    <div className="header-left">
                        <Link to="/">
                            <img className="logo" height={150} src="/logo.png" alt="monittor" />
                        </Link>
                    </div>
                    <div className="header-right">
                        <div onClick={onClickCart} className="cart-icon">
                            <FontAwesomeIcon size="2x" color={Color.blue900} icon={faDolly} /> {checkoutContext.getCount() ? <div className="cart-count">{checkoutContext.getCount()}</div> : <></>}
                        </div>
                        <Button onClick={onClickMenu} type={ButtonType.Secondary}>
                            {notificationCount ? (
                                <div className="menu-icon-wrapper">
                                    <FontAwesomeIcon size="lg" color={Color.blue900} icon={showMenu ? faAngleUp : faBars} />
                                    <div className="red-dot"></div>
                                </div>
                            ) : (
                                <FontAwesomeIcon size="lg" color={Color.blue900} icon={showMenu ? faAngleUp : faBars} />
                            )}
                        </Button>
                    </div>
                </div>
                <div className="drop-panel animation" style={{ height: checkoutContext.cartInformation.show ? '100%' : 0 }}>
                    <Cart />
                </div>
                <div className="drop-panel animation" style={{ height: showMenu ? '100%' : 0 }}>
                    {getMenuItems([
                        { text: 'Equipment', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faIndustry} />, action: () => navigate('/') },
                        { text: 'Products', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faVial} />, action: () => navigate('/products') },
                        { text: 'Transactions', icon: <Icons type={IconType.OrderBag} color={Color.blue900} />, action: () => navigate('/orders') },
                        { text: 'Notifications', icon: getBellIcon(), action: () => navigate('/notifications') },
                        { text: 'Log Out', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faSignOutAlt} />, action: props.onLogout },
                    ])}
                </div>
                <div style={{ height: !checkoutContext.cartInformation.show && !showMenu ? '100%' : 0 }} className="mobile-content-wrapper animation">
                    {props.children}
                </div>
            </div>
        )
    } else if (props.type === CompanyType.Manufacturer) {
        return (
            <div className={props.className ? props.className + ' page-wrapper mobile' : 'page-wrapper mobile'}>
                <div className="page-wrapper-space"></div>
                <div className="page-header">
                    <div className="header-left">
                        <Link to="/">
                            <img className="logo" height={150} src="/logo.png" alt="monittor" />
                        </Link>
                    </div>
                    <div className="header-right">
                        <Button onClick={onClickMenu} type={ButtonType.Secondary}>
                            <FontAwesomeIcon size="lg" color={Color.blue900} icon={faBars} />
                        </Button>
                    </div>
                </div>
                <div className="drop-panel animation" style={{ height: checkoutContext.cartInformation.show ? '100%' : 0 }}>
                    <Cart />
                </div>
                <div className="drop-panel animation" style={{ height: showMenu ? '100%' : 0 }}>
                    {getMenuItems([
                        { text: 'Equipment', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faIndustry} />, action: () => navigate('/') },
                        { text: 'Customers', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faVial} />, action: () => navigate('/customers') },
                        { text: 'Products', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faFlask} />, action: () => navigate('/products') },
                        { text: 'Documents', icon: <Icons type={IconType.Folder} color={Color.blue900} />, action: () => navigate('/documents') },
                        { text: 'Transactions', icon: <Icons type={IconType.OrderBag} color={Color.blue900} />, action: () => navigate('/orders') },
                        { text: 'Notifications', icon: getBellIcon(), action: () => navigate('/notifications') },
                        { text: 'Setting', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faCog} />, action: () => navigate('/settings') },
                        { text: 'Log Out', icon: <FontAwesomeIcon color={Color.blue900} className="icon" icon={faSignOutAlt} />, action: props.onLogout },
                    ])}
                </div>
                <div style={{ height: !checkoutContext.cartInformation.show && !showMenu ? '100%' : 0 }} className="mobile-content-wrapper animation">
                    {props.children}
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default MobilePageWrapper
