import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import React, { useRef, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { pdfjs, Document, Page } from 'react-pdf'
import { Color } from '../../models/enums'
import ZoomButton from '../ZoomButton'
import './PdfViewer.scss'
import Button from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import PopoverItem from '../PopoverItem'
import Icons, { IconType } from '../../Icon'

interface Props {
    onClose?: () => void
    setImageLoading?: (value: boolean) => void
    file: string
    fileName?: string
    layout?: 'button' | 'scroll'
    className?: string
    type?: 'sm' | 'lg'
    selectedDrawingIndex?: number
    previousDrawingAction?: () => void
    nextDrawingAction?: () => void
    drawingLength?: number
}

function PdfViewer(props: React.PropsWithChildren<Props>) {
    const [numPages, setNumPages] = useState<number>(0)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const imageContainer = useRef<HTMLDivElement>(null)
    const panzoom = useRef<PanzoomObject | null>(null)
    const [scale, setScale] = useState<number>(props.type === 'sm' ? 0.4 : 1)
    const scaleNumber = props.type === 'sm' ? 0.4 : 1
    //const [document, setDocument] = useState<any>(null)
    //const [size, setSize] = useState<number>(0)

    if (panzoom.current === null && imageContainer.current !== null) {
        panzoom.current = Panzoom(imageContainer.current)
    } /* else not needed */

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

    const onDocumentLoadSuccess = (documentData: any) => {
        //setDocument(documentData);
        setNumPages(documentData.numPages)
        if (props.setImageLoading) props.setImageLoading(false)
    }

    const reset = () => {
        setScale(scaleNumber)
        panzoom.current?.reset()
    }

    const onPrevious = () => {
        setPageNumber(pageNumber - 1)
        reset()
    }

    const onNext = () => {
        setPageNumber(pageNumber + 1)
        reset()
    }

    function onClickPlusHandler() {
        if (scale < 2.5) {
            setScale(scale + 0.2)
            //panzoom.current?.zoomIn()
        } /* else not needed */
    }

    function onClickMinusHandler() {
        if (scale > 0.39) {
            setScale(scale - 0.2)
        } /* else not needed */
        //panzoom.current?.zoomOut()
    }

    function onClickResetHandler() {
        reset()
    }

    /*useEffect(() => {
    async function calculateSize() {
      if (document && pageNumber) {
        const page = await document.getPage(pageNumber);
        if (page.rotate === 0 || page.rotate === 180) {
          return 930
        }
        else {
          return window.outerWidth
        }
      } else {
        return 0
      }
    }

    const getSize = async () => {
      setSize(await calculateSize())
    }

    getSize();
  }, [document, pageNumber]);*/

    return props.layout !== 'scroll' ? (
        <div className="pdf-viewer-container">
            <div className="zoom-button-controller">
                <PopoverItem popoverContent="Hotspots (Interactive Part Mapping) may only be added to image files.">
                    <Button className="edit-hotspot disabled">
                        <Icons type={IconType.EditHotspot} />
                        <span>Edit Hotspots</span>
                    </Button>
                </PopoverItem>
                <ZoomButton onClickPlus={onClickPlusHandler} onClickMinus={onClickMinusHandler} onClickReset={onClickResetHandler} url={props.file} fileName={props.fileName} />
            </div>

            <div className={`pdf-viewer ${props.className ? props.className : ''}`}>
                <PopoverItem popoverContent="Previous" disabled={props.selectedDrawingIndex === 0}>
                    <Button className="btn btn__previous" onClick={props.previousDrawingAction} disabled={props.selectedDrawingIndex === 0}>
                        <FontAwesomeIcon icon={faArrowLeft} color={Color.white} />
                    </Button>
                </PopoverItem>
                <div id="image-container" ref={imageContainer}>
                    <Document
                        file={props.file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <div className="loader">
                                <div className="center-content">
                                    <TailSpin color={Color.yellow200} height={100} width={100} />
                                    <span>Loading PDF...</span>
                                </div>
                            </div>
                        }
                    >
                        <Page pageNumber={pageNumber || 1} width={window.outerWidth - 68} renderAnnotationLayer={false} renderInteractiveForms={false} renderTextLayer={false} scale={scale} />
                    </Document>
                </div>
                <PopoverItem popoverContent="Next" disabled={props.selectedDrawingIndex === props.drawingLength! - 1}>
                    <Button className="btn btn__next" onClick={props.nextDrawingAction} disabled={props.selectedDrawingIndex === props.drawingLength! - 1}>
                        <FontAwesomeIcon icon={faArrowRight} color={Color.white} />
                    </Button>
                </PopoverItem>
            </div>
            <div className="pdf-viewer-footer" style={{ display: numPages === 0 ? 'none' : '' }}>
                <button disabled={pageNumber === 1} onClick={onPrevious}>
                    &lt;
                </button>
                <p className="total-page-number">
                    Page {pageNumber} of {numPages}
                </p>
                <button disabled={pageNumber === numPages} onClick={onNext}>
                    &gt;
                </button>
            </div>
        </div>
    ) : (
        <div className="pdf-viewer-container">
            <div className="zoom-button-controller">
                <PopoverItem popoverContent="Hotspots (Interactive Part Mapping) may only be added to image files.">
                    <Button className="edit-hotspot" disabled={true}>
                        <Icons type={IconType.EditHotspot} />
                        <span>Edit Hotspots</span>
                    </Button>
                </PopoverItem>
                <ZoomButton onClickPlus={onClickPlusHandler} onClickMinus={onClickMinusHandler} onClickReset={onClickResetHandler} url={props.file} fileName={props.fileName} />
            </div>
            <div className="pdf-viewer">
                <PopoverItem popoverContent="Previous" disabled={props.selectedDrawingIndex === 0}>
                    <Button className="btn btn__previous" onClick={props.previousDrawingAction} disabled={props.selectedDrawingIndex === 0}>
                        <FontAwesomeIcon icon={faArrowLeft} color={Color.white} />
                    </Button>
                </PopoverItem>
                <div id="image-container" ref={imageContainer}>
                    <Document
                        file={props.file}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                            <div className="loader">
                                <div className="center-content">
                                    <TailSpin color={Color.yellow200} height={100} width={100} />
                                    <span>Loading PDF...</span>
                                </div>
                            </div>
                        }
                    >
                        {Array(numPages)
                            .fill(null)
                            .map((_, i) => i + 1)
                            .map((n) => {
                                return <Page key={n} pageNumber={n} width={window.outerWidth - 68} renderAnnotationLayer={false} renderInteractiveForms={false} renderTextLayer={false} scale={scale} />
                            })}
                    </Document>
                </div>
                <PopoverItem popoverContent="Next" disabled={props.selectedDrawingIndex === props.drawingLength! - 1}>
                    <Button className="btn btn__next" onClick={props.nextDrawingAction} disabled={props.selectedDrawingIndex === props.drawingLength! - 1}>
                        <FontAwesomeIcon icon={faArrowRight} color={Color.white} />
                    </Button>
                </PopoverItem>
            </div>
        </div>
    )
}

export default PdfViewer
