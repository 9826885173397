import React, { useState } from 'react'
import Box from '../../components/Box'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Size } from '../../models/enums'

import { Link, useParams } from 'react-router-dom'
import AuthService from '../../services/UiService/Auth'
import './user-verification.scss'
import { isValidPassword } from '../../utils/helpers'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const UserVerification = {
    newPassword: '',
    newPasswordAgain: '',
}

function UserVerificationPage() {
    let { id } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ show: false, type: '', text: '' })
    const [formData, setFormData] = useState(UserVerification)
    const [finish, setFinish] = useState(false)

    const changePassword = async () => {
        setLoading(true)
        const response = await new AuthService().userVerification({ password: formData.newPassword, key: id! })
        if (response.status) {
            const email = response.data?.email
            toast.success('Successfully set password')
            setTimeout(
                () =>
                    navigate({
                        pathname: '/login',
                        search: `?email=${email}`,
                    }),
                3000
            )
        } else {
            setMessage({ show: true, type: 'error', text: response.message })
            setTimeout(() => {
                setMessage({ show: false, type: '', text: '' })
            }, 2000)
        }
        setFinish(true)
        setLoading(false)
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, type: string) {
        setFormData({ ...formData, [type]: e.target.value })
    }

    return (
        <div className="sign-up-page form-container">
            <div className="form-wrapper">
                <Box className="login-form">
                    <span className="brand-title">User Verification</span>
                    {!finish && (
                        <span className="welcome-message">
                            You’ve been invited to collaborate on Monittor as a team member. After creating your password, you’ll be able to log in to your account.
                        </span>
                    )}
                    {!finish ? (
                        <>
                            <Input type="password" value={formData.newPassword} onChange={(e) => handleOnChange(e, 'newPassword')} placeholder="NEW PASSWORD" size={Size.lg} />
                            <Input type="password" value={formData.newPasswordAgain} onChange={(e) => handleOnChange(e, 'newPasswordAgain')} placeholder="CONFIRM PASSWORD" size={Size.lg} />
                            <div className={'sign-up-password-hint'}>
                                <ul>
                                    <li className={'password-hint-item'}>At least 8 characters</li>
                                    <li className={'password-hint-item'}>A mixture of both uppercase and lowercase letters.</li>
                                    <li className={'password-hint-item'}>A mixture of letters and numbers.</li>
                                    <li className={'password-hint-item'}>Inclusion of at least one special character (!,@,#,?)</li>
                                </ul>
                            </div>

                            {loading && <span className="loading-span" />}
                            <Button disabled={loading || !(isValidPassword(formData.newPassword) && formData.newPassword === formData.newPasswordAgain)} onClick={changePassword}>
                                Set Password
                            </Button>
                        </>
                    ) : (
                        <div className="message-wrapper">
                            {message.type === 'success' ? (
                                <div className="message success">{message.text || 'Success'}</div>
                            ) : (
                                message.type === 'error' && <div className="message error">{message.text || 'Error'}</div>
                            )}
                        </div>
                    )}

                    <div className="foot-note">
                        <Link to="/login">Sign in</Link>
                        <span>or</span>
                        <Link to="/sign-up">Sign up</Link>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default UserVerificationPage
