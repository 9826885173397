import { FC, useEffect, useRef, useState } from 'react'
import Icons, { IconType } from '../../../../../Icon'
import { ActivityStatusType } from '../../../../../models/Ui/Activity'
import './style.scss'

interface Props {
    status: ActivityStatusType
    onChange: (val: ActivityStatusType) => void
    disabled?: boolean
}

const ActivityStatusForPartialUpdate: FC<Props> = ({ status, onChange, disabled }) => {
    const [showOptions, setShowOptions] = useState(false)
    const dropdownContainerRef = useRef<HTMLDivElement>(null)

    const statusTypes = [
        {
            id: 1,
            text: ActivityStatusType.Open,
        },
        {
            id: 2,
            text: ActivityStatusType.InProgress,
        },
        {
            id: 3,
            text: ActivityStatusType.OnHold,
        },
        {
            id: 4,
            text: ActivityStatusType.Closed,
        },
    ]

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
                setShowOptions(false)
            }
        }

        document.addEventListener('mousedown', (e) => handleClickOutside(e))
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const setStatusClassname = (val: ActivityStatusType) => {
        if (val === ActivityStatusType.Open) {
            return 'open'
        } else if (val === ActivityStatusType.InProgress) {
            return 'in-progress'
        } else if (val === ActivityStatusType.OnHold) {
            return 'on-hold'
        } else if (val === ActivityStatusType.Closed) {
            return 'closed'
        }
    }

    return (
        <div className="activity-partial-status-container" ref={dropdownContainerRef}>
            <div
                className={`selected-one ${setStatusClassname(status)} ${showOptions && 'focused-dropdown'} ${disabled ? 'disabled-dropdown' : 'enabled-dropdown'}`}
                onClick={() => {
                    if (!disabled) {
                        setShowOptions(!showOptions)
                    }
                }}
            >
                <div className="selected-one-text">{status}</div>
                {!disabled && <Icons type={IconType.ChevronDown} />}
            </div>
            {showOptions && (
                <div className="status-options">
                    {statusTypes.map((child) => (
                        <div
                            key={child.id}
                            className={`status-option`}
                            onClick={() => {
                                onChange(child.text)
                                setShowOptions(false)
                            }}
                        >
                            <div className={`status-text ${setStatusClassname(child.text)}`}>
                                {child.text}
                                {child.text === ActivityStatusType.Closed && <Icons type={IconType.Lock} />}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ActivityStatusForPartialUpdate
