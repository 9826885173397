import { useEffect, useState } from 'react'
import { TeamActionTypes } from '../..'
import { MonitorApi } from '../../../../api'
import { ServiceFunctionLookupTable } from '../../../../api/serviceFunctionLookupTable'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import PopoverItem from '../../../../components/PopoverItem'
import { MaxCharacter } from '../../../../models/enums'
import { AuthUserModel } from '../../../../models/Ui/Auth'
import { CompanyModel, UpdateCompanyModel } from '../../../../models/Ui/Company'
import TokenService from '../../../../services/NewServices/TokenService'
import CompanyService from '../../../../services/UiService/Company'
import './style.scss'

interface Props {
    company: CompanyModel
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function UpdateCompanyDetailsModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props

    const [companyName, setCompanyName] = useState<string>('')
    const [companyWebsite, setCompanyWebsite] = useState<string>('')
    const [companyAbout, setCompanyAbout] = useState<string>('')

    useEffect(() => {
        setCompanyName(props.company.name || '')
        setCompanyWebsite(props.company.website || '')
        setCompanyAbout(props.company.about || '')
    }, [props.company.about, props.company.name, props.company.website])

    async function updateCompanyDetailsHandler() {
        try {
            onLoading()
            const companyService = new CompanyService()
            const data: UpdateCompanyModel = {
                id: props.company.id,
                name: companyName,
                website: companyWebsite,
                about: companyAbout,
                email: props.company.email,
            }
            const updateCompanyResponse = await companyService.update(data)
            const user: AuthUserModel = (await MonitorApi.get(ServiceFunctionLookupTable.User, {})) as any as AuthUserModel
            TokenService.setUser(user)
            onSuccess(TeamActionTypes.UPDATE_COMPANY_DETAILS, updateCompanyResponse)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return companyName.length > 0
    }

    const clearForm = () => {
        setCompanyName('')
        setCompanyWebsite('')
        setCompanyAbout('')
    }

    const clickPrimary = () => {
        updateCompanyDetailsHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <Modal
            className="update-company-details-modal"
            visible={true}
            title="Edit company details"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <Input value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="COMPANY NAME" required max={MaxCharacter.companyName} />
            </div>
            <div className="modal-line">
                <PopoverItem popoverContent={'If you would like to transfer this company to another email address, please contact us at accounts@monittor.com.'}>
                    <Input disabled={true} value={props.company.email} placeholder="COMPANY EMAIL ADDRESS" required max={MaxCharacter.email} />
                </PopoverItem>
            </div>
            <div className="modal-line">
                <Input value={companyWebsite} onChange={(e) => setCompanyWebsite(e.target.value)} placeholder="COMPANY WEBSITE (e.g. www.monittor.com)" />
            </div>
            <div className="modal-line">
                <Input lineCount={4} value={companyAbout} onChange={(e) => setCompanyAbout(e.target.value)} placeholder="ABOUT" />
            </div>
        </Modal>
    )
}

export default UpdateCompanyDetailsModal
