import * as React from 'react'
import { useContext } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icons, { IconType } from '../../../../Icon'
import { arraySort, isAbbreviation, priceFormatter } from '../../../../utils/helpers'
import './style.scss'
import { CustomerProductModel } from '../../../../models/New/Customer'
import { ProductModel } from '../../../../models/Ui/Product'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import { TechnicianViewType } from '../../../../store/models'
import NoDataInfo from '../../../../components/NoDataInfo'
import { ignoreDashClassname } from '../../../../hooks/useTableHook'
import PopoverItem from '../../../../components/PopoverItem'
import { useNavigate } from 'react-router'
import Button from '../../../../components/Button'

interface Data {
    name: string
    partNumber: string
    category: string[] | any
    brand: string
    description: string
    price: string
    uom: string
    pkg: string
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    data: CustomerProductModel[]
    setSearch: (key: string) => void
    onProductPreview: (id: string) => void
    selectedProductId: string
    isProductPreviewShow: boolean
}

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: React.Dispatch<React.SetStateAction<boolean>>
    isProductPreviewShow: boolean
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const checkoutContext = React.useContext(CheckoutContext)
    const columns: HeadCell[] = tableProps.isProductPreviewShow
        ? [
              {
                  id: 'name',
                  label: 'Product Name',
              },
              {
                  id: 'pkg',
                  label: 'PKG',
              },
              {
                  id: 'price',
                  label: 'Price',
              },
          ]
        : [
              {
                  id: 'name',
                  label: 'Product Name',
              },
              {
                  id: 'category',
                  label: 'Category',
              },
              {
                  id: 'brand',
                  label: 'Brand',
              },
              {
                  id: 'partNumber',
                  label: 'MFG Part Number',
              },
              {
                  id: 'uom',
                  label: 'UOM',
              },
              {
                  id: 'pkg',
                  label: 'PKG',
              },
              {
                  id: 'price',
                  label: 'Price',
              },
          ]
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection((prevState) => !prevState)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
                {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && <TableCell />}
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const checkoutContext = useContext(CheckoutContext)
    const navigate = useNavigate()
    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const onAddToCart = (product: CustomerProductModel) => {
        const newData: ProductModel = {
            leadTime: product.leadTime,
            id: product.id,
            part_no: product.partNumber,
            name: product.name,
            category: product.category,
            vendor: product.brand,
            uom: product.uom,
            pkg: product.pkg,
            price: product.price,
            adjustedPrice: product.price,
            qty: product.quantity,
            manufacturer: {
                name: product.companyId,
                id: product.companyId,
                email: '',
            },
            attachments: [],
        }
        checkoutContext.addProduct(newData)
    }
    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} isProductPreviewShow={props.isProductPreviewShow} />

                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: CustomerProductModel, index) => {
                                const isAddedCart = checkoutContext.contextProducts.find((contextProduct) => contextProduct.product.id === row.id)
                                return (
                                    <TableRow className={props.selectedProductId === row.id ? 'highlightedRow' : ''} hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell className="font-bold" style={{ width: '25%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                                            <span className="ellipsis-text">{row.name}</span>
                                            {isAbbreviation(row.name.length) && <div className="table-hover">{row.name}</div>}
                                        </TableCell>
                                        {!props.isProductPreviewShow && (
                                            <TableCell style={{ width: '15%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                                                {row.category}
                                            </TableCell>
                                        )}
                                        {!props.isProductPreviewShow && (
                                            <TableCell style={{ width: '10%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                                                {row.brand}
                                            </TableCell>
                                        )}
                                        {!props.isProductPreviewShow && (
                                            <TableCell style={{ width: '10%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                                                {row.partNumber}
                                            </TableCell>
                                        )}
                                        {!props.isProductPreviewShow && (
                                            <TableCell style={{ width: '10%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                                                {row.uom}
                                            </TableCell>
                                        )}
                                        <TableCell style={{ width: '10%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                                            {row.pkg}
                                        </TableCell>
                                        <TableCell style={{ width: '10%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                                            {priceFormatter(row.price)}
                                        </TableCell>
                                        {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && (
                                            <TableCell style={{ width: '10%' }}>
                                                {isAddedCart ? (
                                                    <span className="green-tick">
                                                        <Icons type={IconType.GreenTick} />
                                                    </span>
                                                ) : (
                                                    <Button
                                                        className="add-to-cart"
                                                        onClick={() =>
                                                            (checkoutContext.cartInformation.technicianViewType === TechnicianViewType.PurchaseAs ||
                                                                checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick) &&
                                                            onAddToCart(row)
                                                        }
                                                    >
                                                        <Icons type={IconType.GoToShopping} />
                                                    </Button>
                                                )}
                                            </TableCell>
                                        )}
                                        <TableCell className={ignoreDashClassname} style={{ width: '2%', paddingLeft: 0 }} onClick={() => navigate('/products/' + row.id)}>
                                            <div className={props.selectedProductId === row.id ? 'action-cell action-cell-hover' : 'action-cell'}>
                                                <PopoverItem popoverContent="View Details">
                                                    <Icons type={IconType.ChevronRight} />
                                                </PopoverItem>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
