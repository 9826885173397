import React, { Fragment, ReactNode, useContext } from 'react'
import TreeViewNode, { TreeviewNodeButton } from '../Component/Node'
import { IconType } from '../../../Icon'
import { useNavigate } from 'react-router'
import './style.scss'
import { LinkTemplateFromAssemblyModel, TreeViewModel } from '../../../models/New/Equipment'
import PopoverItem from '../../PopoverItem'
import CircleIconButton from '../../CircleIconButton'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import EquipmentService from '../../../services/UiService/Equipment'

export interface TreviewHeaderButton {
    icon: IconType
    popoverContent?: string
    onClick: () => void
}

interface Props {
    root: TreeViewModel
    nodes?: TreeViewModel[]
    className?: ReactNode
    headerActionButtons?: TreviewHeaderButton[]
    rootBaseURL: string
    baseURL: string
    rootBaseId: string
    baseId: string
    visible: boolean
    isAbsolute?: boolean
    setVisible: (value: boolean) => void
    setSelectedItem: (value: TreeViewModel) => void
    onLoading: () => void
    onCompleted: () => void
    onSuccessCallBack: () => void
}

function TreeView(props: React.PropsWithChildren<Props>) {
    const navigate = useNavigate()
    const { confirm } = useContext(ConfirmationContext)

    const treeviewUnlinkButton: TreeviewNodeButton = {
        icon: IconType.Unlink,
        popoverContent: 'Unlink',

        onClick: (id, parentId) => {
            confirm({
                modalClassName: 'delete-equipment-modal',
                modalTitle: 'Unlink sub-assembly?',
                primaryButtonText: 'Yes',
                secondaryButtonText: 'No',
                func: async (successCallback, errCallback) => {
                    try {
                        props.onLoading()
                        const payload: LinkTemplateFromAssemblyModel = {
                            childId: id,
                            parentId: parentId,
                        }
                        await EquipmentService.unLinkTemplateFromAssembly(props.rootBaseId, payload)
                        successCallback()
                        if (props.baseId) {
                            navigate(props.rootBaseURL + props.rootBaseId)
                        } else {
                            props.onSuccessCallBack()
                        }
                    } catch (error: any) {
                        errCallback(error)
                    } finally {
                        props.onCompleted()
                    }
                },
                contentNode: (
                    <Fragment>
                        <div className="modal-line">Are you sure you would like to proceed?</div>
                    </Fragment>
                ),
            })
        },
    }
    const treeviewDeleteButton: TreeviewNodeButton = {
        icon: IconType.Trash,
        popoverContent: 'Delete',
        onClick: (id, parentId) => {
            confirm({
                modalClassName: 'delete-equipment-modal',
                modalTitle: 'Delete equipment?',
                primaryButtonText: 'Delete',
                func: async (successCallback, errCallback) => {
                    try {
                        props.onLoading()
                        await EquipmentService.delete(id)
                        successCallback()
                        if (props.baseId) {
                            navigate(props.rootBaseURL + props.rootBaseId)
                        } else {
                            props.onSuccessCallBack()
                        }
                    } catch (error: any) {
                        errCallback(error)
                    } finally {
                        props.onCompleted()
                    }
                },
                contentNode: (
                    <Fragment>
                        <div className="modal-line">Are you sure you would like to proceed?</div>
                    </Fragment>
                ),
            })
        },
    }

    function prepareNodesRecursive(dataList: TreeViewModel[], deepLevel: number, parent: TreeViewModel, isTemplate?: boolean) {
        deepLevel++
        return dataList.map((data: TreeViewModel) => {
            if (props.baseId === data.id) {
                props.setSelectedItem(data)
            }
            return (
                <TreeViewNode
                    key={data.id}
                    id={data.id}
                    text={data.name}
                    parent={parent}
                    childNodes={data.children ? prepareNodesRecursive(data.children, deepLevel, data!, data.isTemplate || isTemplate) : undefined}
                    deepLevel={deepLevel}
                    buttons={data.isTemplate && data?.level === 0 ? [treeviewUnlinkButton] : !data.isTemplate && !isTemplate ? [treeviewDeleteButton] : []}
                    // onClick={() => props.setVisible(false)}
                    onClickItem={() => {
                        props.setVisible(false)
                        props.setSelectedItem(data)
                        navigate(props.baseURL + data.id)
                    }}
                    checked={props.baseId === data.id}
                    isTemplate={!data.templateId}
                />
            )
        })
    }

    return (
        <div className={`new-tree-view-container ${props.isAbsolute && 'absolute'}`}>
            {props.root && (
                <div className={`hierarchial-tree-view-container ${props.className ? props.className : ''} ${!props.visible && 'none'}`}>
                    <div className="hierarchial-tree-view-container-header">
                        <span className="title">Sub-assemblies</span>
                        <div className="action-container">
                            {props.headerActionButtons &&
                                props.headerActionButtons.map((button) => {
                                    return (
                                        <div className="action-button">
                                            <PopoverItem popoverContent={button.popoverContent!}>
                                                <CircleIconButton onClick={button.onClick!} icon={button.icon} />
                                            </PopoverItem>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <div className="hierarchial-tree-view-container-body">
                        <TreeViewNode
                            key={props.root.id}
                            id={props.root.id}
                            text={props.root.name}
                            parent={{ id: props.rootBaseId, text: 'root' }}
                            childNodes={props.nodes ? prepareNodesRecursive(props.nodes, 1, { id: props.rootBaseId, name: 'root' }) : undefined}
                            deepLevel={1}
                            // onClick={() => props.setVisible(false)}
                            onClickItem={() => {
                                // props.setVisible(false)
                                navigate(props.rootBaseURL + props.root.id)
                            }}
                            checked={props.root.id === props.rootBaseId && !props.baseId}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default TreeView
