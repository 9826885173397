import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, CSSProperties } from 'react'
import { Color } from '../../models/enums'
import './NoDataInfo.scss'

interface Props {
    style?: CSSProperties
}

const NoDataInfo: FC<Props> = ({ style = {} }) => {
    return (
        <div className="no-data-info" style={style}>
            <FontAwesomeIcon color={Color.grey600} icon={faInfo} />
            <span>No Data</span>
        </div>
    )
}

export default NoDataInfo
