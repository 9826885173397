import React, { useEffect, useRef, useState } from 'react'
import Box from '../../components/Box'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { MaxCharacter, Size } from '../../models/enums'
import './sign-up.scss'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import AuthService from '../../services/UiService/Auth'
import { isValidEmail, isValidPassword } from '../../utils/helpers'
import ReCaptcha from './../../components/ReCAPTCHA/index';
import ReCAPTCHA from 'react-google-recaptcha';

const SignUpForm: any = {
    type: 'manufacturer',
    email: '',
    name: '',
    about: '',
    phone: '',
    website: '',
    user: {
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: ""
    }
}

function SignUp() {
    let [searchParams] = useSearchParams()
    const codeWithUrl = searchParams.get('code')
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ show: false, type: '', text: '' })
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [reButton, setReButton] = useState(false)
    const [formData, setFormData] = useState(SignUpForm)
    const authService = new AuthService()
    const reRef = useRef<ReCAPTCHA>();
    const navigate = useNavigate()

    useEffect(() => {
        if (codeWithUrl && codeWithUrl !== '' && codeWithUrl.length > 10) {
            setButtonDisabled(false)
        } /* else not needed */
    }, [codeWithUrl])

    useEffect(() => {
        if (reButton && isValidEmail(formData.user.email) && isValidPassword(formData.user.password) && formData.user.password === formData.user.confirmPassword && formData.user.firstName.length > 0 && formData.user.lastName.length > 0 && isValidEmail(formData.email) && formData.name.length > 0) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    }, [formData, reButton])

    const checkUser = async () => {
        setLoading(true)


        try {
            await authService.signUp(formData)
            setLoading(false)
            setMessage({
                show: true,
                type: 'success',
                text: 'Congratulations! You are all set. Please check your email to verify your account.',
            })
            setTimeout(() => {
                setMessage({ show: false, type: '', text: '' })
                navigate('/login')
            }, 2000)
        } catch (e: any) {
            setLoading(false)
            setMessage({ show: true, type: 'error', text: e.data?.message?.join(',\n') })
        } finally {
            reRef.current?.reset()
            setReButton(false)
            setButtonDisabled(true)
        }
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, type: string) {
        if (type.includes('.')) {
            const [first, second] = type.split('.')
            setFormData({ ...formData, [first]: { ...formData[first], [second]: e.target.value } })
            return
        }
        setFormData({ ...formData, [type]: e.target.value })
    }

    return (
        <div className='sign-up-page sign-up-container'>
            <div className='form-wrapper'>
                <Box className='login-form'>
                    <span className='brand-title'>Sign up</span>
                    <div className='description'>
                        Your manufacturer invited you to join Monittor.
                        Sign up below to access your private store.
                    </div>
                    <div className='group-label'>
                        User information
                    </div>
                    <Input
                        type='email'
                        value={formData.user.email}
                        onChange={(e) => handleOnChange(e, 'user.email')}
                        placeholder='EMAIL ADDRESS'
                        max={MaxCharacter.email}
                        size={Size.lg}
                    />
                    <Input
                        type='text'
                        value={formData.user.firstName}
                        onChange={(e) => handleOnChange(e, 'user.firstName')}
                        placeholder='FIRST NAME'
                        max={MaxCharacter.name}
                        size={Size.lg}
                    />
                    <Input
                        type='text'
                        value={formData.user.lastName}
                        onChange={(e) => handleOnChange(e, 'user.lastName')}
                        placeholder='LAST NAME'
                        max={MaxCharacter.name}
                        size={Size.lg}
                    />
                    <Input
                        type='password'
                        value={formData.user.password}
                        onChange={(e) => handleOnChange(e, 'user.password')}
                        placeholder='PASSWORD'
                        size={Size.lg}
                    />

                    <Input
                        type='password'
                        value={formData.user.confirmPassword}
                        onChange={(e) => handleOnChange(e, 'user.confirmPassword')}
                        placeholder='CONFIRM PASSWORD'
                        size={Size.lg}
                    />


                    <div className={'sign-up-password-hint'}>
                        <ul>
                            <li className={'password-hint-item'}>At least 8 characters
                            </li>
                            <li className={'password-hint-item'}>A mixture of both uppercase and lowercase
                                letters.
                            </li>
                            <li className={'password-hint-item'}>A mixture of letters and numbers.</li>
                            <li className={'password-hint-item'}>Inclusion of at least one special character
                                (!,@,#,?)
                            </li>
                        </ul>
                    </div>

                    <div className='group-label'>Company information</div>
                    <Input
                        type='text'
                        value={formData.name}
                        onChange={(e) => handleOnChange(e, 'name')}
                        placeholder='COMPANY NAME'
                        max={MaxCharacter.companyName}
                        size={Size.lg}
                    />
                    <Input
                        type='email'
                        value={formData.email}
                        onChange={(e) => handleOnChange(e, 'email')}
                        placeholder='COMPANY EMAIL'
                        max={MaxCharacter.email}
                        size={Size.lg}
                    />
                    <Input
                        type='phone'
                        value={formData.phone}
                        onChange={(e) => handleOnChange(e, 'phone')}
                        placeholder='PHONE'
                        max={MaxCharacter.phone}
                        size={Size.lg}
                    />
                    <Input
                        type='url'
                        value={formData.website}
                        onChange={(e) => handleOnChange(e, 'website')}
                        placeholder='COMPANY WEBSITE'
                        size={Size.lg}
                    />
                    <div className='radio-group'>
                        <label htmlFor='manufacturer'>
                            <input type='radio' value='manufacturer' id='manufacturer'
                                checked={formData.type === 'manufacturer'}
                                onChange={(e) => handleOnChange(e, 'type')} name='type' />
                            <span>Manufacturer</span>
                        </label>

                        <label htmlFor='customer'>
                            <input type='radio' value='customer' id='customer' checked={formData.type === 'customer'}
                                onChange={(e) => handleOnChange(e, 'type')} name='type' />
                            <span>Customer</span>
                        </label>
                    </div>
                    <ReCaptcha referance={reRef} handleClick={() => setReButton(true)} />
                    {
                        loading && <span className='loading-span' />
                    }
                    {
                        message.show && (
                            message.type === 'success' ?
                                <div className='message success'>{message.text || 'Success...'}</div>
                                : message.type === 'error' && <div className='message error'>{message.text || 'Error'}</div>
                        )
                    }
                    <Button disabled={loading || buttonDisabled} onClick={checkUser}>Sign up</Button>
                    <div className='foot-note'>
                        Already have an account?<Link to='/login'>Login here.</Link>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default SignUp
