import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import React, { ReactNode, useEffect, useState } from 'react'
import { IconType } from '../../Icon'
import Checkbox from '../Checkbox'
import CircleIconButton from '../CircleIconButton'
import './style.scss'

export interface ListCardItemButton {
  tooltip?: string;
  icon: IconDefinition | IconType
  onClick: () => void
}

export interface ListCardItemData {
  isClickable?: boolean
  isSelectable?: boolean
  isExpandable?: boolean
  isExpandOnClick?: boolean
  checkboxDisabled?: boolean
  checkboxChecked?: boolean
  buttons?: ListCardItemButton[]
  expandContent?: ReactNode
  onClick?: () => void
  onCheckChange?: (checked: boolean) => void
}

function ListCardItem(props: React.PropsWithChildren<ListCardItemData>) {
  const [checked, setChecked] = useState<boolean>()
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    setChecked(props.checkboxChecked ? props.checkboxChecked : false)
  }, [props.checkboxChecked])

  useEffect(() => {
    if (props.isClickable && (props.onClick !== undefined && props.isExpandOnClick !== undefined)) {
      console.warn("The isExpandOnClick prop will override the onClick function!");
    } /* else not needed */
  }, [props.isClickable, props.isExpandOnClick, props.onClick])

  if (props.isClickable && (props.onClick === undefined && props.isExpandOnClick === undefined)) {
    throw new Error("onClick function or isExpandOnClick prop must be provided!");
  } /* else not needed */

  if (props.isExpandable && props.expandContent === undefined) {
    throw new Error("expandContent prop must be provided!");
  } /* else not needed */

  return (
    <div className='list-card-item'>
      <div className='content'>
        {
          props.isSelectable &&
          <div className='checkbox-container'>
            <Checkbox
              disabled={props.checkboxDisabled}
              checked={checked}
              onChange={
                () => {
                  setChecked(!checked)
                  if (props.onCheckChange) {
                    props.onCheckChange(!checked)
                  } /* else not needed */
                }
              }
            />
          </div>
        }
        <div className='content-container'
          style={{ cursor: props.isClickable ? "pointer" : "default" }}
          onClick={
            (element) => {
              // Non-clickable area checks
              const targetElement = (element.target as HTMLElement)
              const checkAttribute = targetElement && targetElement.getAttribute('aria-label')?.toLowerCase()

              if (
                !targetElement.classList.contains('non-clickable') &&
                (checkAttribute !== 'zoom image' && checkAttribute !== 'unzoom image')
              ) {
                const onClickFunctionHandler = (props.isClickable ? props.isExpandOnClick ? () => setExpanded(!expanded) : props.onClick : undefined)
                if (onClickFunctionHandler) {
                  onClickFunctionHandler()
                } /* else not needed */
              } /* else not needed */
            }
          }
        >
          {props.children}
        </div>
        {
          props.isExpandable ?
            <div className='button-group-container'>
              {
                props.buttons && props.buttons.length > 0 ?
                  [
                    ...props.buttons.map(
                      (button, index) => {
                        return <CircleIconButton
                          key={index}
                          tooltip={button.tooltip}
                          icon={button.icon}
                          onClick={button.onClick}
                        />
                      }
                    ),
                    <CircleIconButton
                      key={"expand-button"}
                      icon={expanded ? IconType.ChevronUp : IconType.ChevronDown}
                      onClick={
                        () => {
                          setExpanded(!expanded)
                        }
                      }
                    />
                  ] :
                  <CircleIconButton
                    key={"expand-button"}
                    icon={expanded ? IconType.ChevronUp : IconType.ChevronDown}
                    onClick={
                      () => {
                        setExpanded(!expanded)
                      }
                    }
                  />
              }
            </div>
            :
            props.buttons && props.buttons.length > 0 &&
            <div className='button-group-container'>
              {
                props.buttons.map(
                  (button, index) => {
                    return <CircleIconButton
                      key={index}
                      tooltip={button.tooltip}
                      icon={button.icon}
                      onClick={button.onClick}
                    />
                  }
                )
              }
            </div>
        }
      </div>
      {
        props.isExpandable && expanded && props.expandContent &&
        <div className='expand-content'>
          {props.expandContent}
        </div>
      }
    </div>
  )
}

export default ListCardItem
