import { FC } from 'react'
import AutoResizableTextarea from '../../../../../../components/AutoResizableTextarea'
import './style.scss'

interface Props {
    value: string
    onChange: (value: string) => void
    placeholder?: string
    disabled?: boolean
}

const ActivityDescriptionAndNote: FC<Props> = ({ value, onChange, placeholder, disabled }) => {
    return (
        <AutoResizableTextarea
            disabled={disabled}
            placeholder={placeholder}
            className={`activity-description-note ${value ? 'filled-input' : 'empty-input'} ${disabled ? 'disabled-textarea' : 'enabled-textarea'}`}
            value={value}
            onChange={(e) => {
                onChange(e.target.value)
            }}
        />
    )
}

export default ActivityDescriptionAndNote
