import Zoom from 'react-medium-image-zoom'
import { Color } from '../../../models/enums'
import { CompanyType } from '../../../models/Ui/CompanyType'
import CustomEquipmentModel from '../../../models/Ui/CustomEquipment'
import { EquipmentModel } from '../../../models/Ui/Equipment'
import TokenService from '../../../services/NewServices/TokenService'
import './EquipmentListItem.scss'
const logo = require('../../../img/no_image.jpg')

const EquipmentListItem = (equipment: EquipmentModel | CustomEquipmentModel) => {
    const thumbnail = { url: '' }
    const isThumbnail = false
    const zoomWrapper = document.getElementById('zoom-wrapper')
    const auth = TokenService.getAuth()
    return (
        <div className="equipment-list-item">
            {isThumbnail ? (
                <Zoom portalEl={zoomWrapper!}>
                    <img className="image zoom-image" src={thumbnail.url} alt={equipment.name} />
                </Zoom>
            ) : equipment.imageUrl ? (
                <Zoom portalEl={zoomWrapper!}>
                    <img
                        className="image zoom-image"
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.src = logo
                        }}
                        src={equipment.imageUrl}
                        alt={equipment.name}
                    />
                </Zoom>
            ) : (
                <div className="image"></div>
            )}
            <div className="equipment-detail-container">
                <h4 className="equipment-name">{equipment?.name}</h4>
                <div className="equipment-detail">
                    <span className="detail-title">Part number: </span>
                    <span className="detail-description">{equipment?.partNumber}</span>
                    <span> • </span>
                    <span className="detail-title">Vendor: </span>
                    <span className="detail-description">{equipment?.manufacturer?.name}</span>
                </div>
                {equipment.tags?.length! > 0 && (
                    <div className="equipment-detail">
                        <span className="grey-color">Tags: </span>
                        <span>{equipment.tags?.join(', ')}</span>
                    </div>
                )}
                <div className="equipment-description">{equipment.description}</div>
                {auth.company.type === CompanyType.Manufacturer && (equipment as CustomEquipmentModel)?.visible != null && (
                    <div className="equipment-description">
                        {(equipment as CustomEquipmentModel).visible ? <span style={{ color: Color.green900 }}>Visible</span> : <span style={{ color: Color.red500 }}>Hidden</span>}
                    </div>
                )}
            </div>
        </div>
    )
}

export default EquipmentListItem
