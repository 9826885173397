import { useEffect, useState } from 'react'
import DropdownInput from '../../../../../components/DropdownInput'
import FileInput from '../../../../../components/FileInput'
import { FileInputType } from '../../../../../components/FileInput/enum'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import StorageService, { FileModel } from '../../../../../services/NewServices/StorageService'
import './CreateDocument.scss'
import LinkedDocuments from './Components/LinkedDocuments/index'
import NewDocumentService from '../../../../../services/UiService/NewDocument'
import { CreateDocumentModel, DocumentBasicModel, DocumentBelongsTo, DocumentListModel } from '../../../../../models/New/Document'
import { ProductActionTypes } from '../../actiontypes.enum'
import { getFileType } from '../../../../../utils/helpers'
import { useParams } from 'react-router'
import ContractService from '../../../../../services/UiService/Contract'
import { ContractInputModel, ContractOutputModel } from '../../../../../models/Ui/Contract'

interface AddDocumentProps {
    visible: boolean
    documents: DocumentListModel[]
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ProductActionTypes) => void
    onError: (error: any) => void

    contract: ContractOutputModel
}

enum CreateDocumentType {
    Upload = 'Upload documents',
    InsertLink = 'InsertLink',
    LinkedDocument = 'Link existing documents',
}

const createDocumentTypes = [{ text: CreateDocumentType.Upload }, { text: CreateDocumentType.InsertLink }, { text: CreateDocumentType.LinkedDocument }]
const AddDocument = (props: AddDocumentProps) => {
    const { id } = useParams()
    const uploadFileService = new StorageService()
    const [createDocumentType, setCreateDocumentType] = useState<CreateDocumentType>(CreateDocumentType.Upload)
    const [files, setFiles] = useState<FileModel[]>([])
    const [insertLink, setInsertLink] = useState<string>('http://')
    const [documents, setDocuments] = useState<DocumentListModel[]>([])
    const [selectedLinkedDocuments, setSelectedLinkedDocuments] = useState<DocumentListModel[]>([])

    const createDocumentHandler = async () => {
        try {
            props.onLoading()

            let payload: ContractInputModel = {
                ...props.contract,
                documents: (props?.documents?.map((child) => ({ ...child, type: getFileType(child.url) })) as DocumentBasicModel[]) ?? [],
            }

            if (insertLink && insertLink !== 'http://') {
                const document: CreateDocumentModel = {
                    name: insertLink,
                    type: getFileType(insertLink),
                    category: '',
                    url: insertLink,
                    isExternal: false,
                    belongsTo: DocumentBelongsTo.Contract,
                }
                const newDocumentResponse = await NewDocumentService.createDocument(document)

                payload.documents!.push(newDocumentResponse)
            }

            if (selectedLinkedDocuments) {
                payload.documents = [...payload.documents!, ...(selectedLinkedDocuments as DocumentBasicModel[])]
            }

            if (files) {
                const newDocumentData: CreateDocumentModel[] = files.map((file) => {
                    return {
                        name: file.name,
                        url: file.url,
                        type: file.type,
                        category: '',
                        isExternal: false,
                        belongsTo: DocumentBelongsTo.Contract,
                    }
                })

                payload.documents = [...payload.documents!, ...(newDocumentData as DocumentBasicModel[])]
            }
            await ContractService.update(id!, payload)
            props.onSuccess(ProductActionTypes.UPDATE_DOCUMENT)
        } catch (error: any) {
            console.log(error)
        } finally {
            props.onCompleted()
            props.closeModal()
        }
    }

    const onChangeFile = async (file: File) => {
        try {
            if (file) {
                props.onLoading()
                const uploadedFile = await uploadFileService.uploadMultiple(file as unknown as FileList)
                setFiles((prevState) => [...prevState, ...uploadedFile])
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }
    const formRenderer = () => {
        switch (createDocumentType) {
            case CreateDocumentType.Upload:
                return (
                    <FileInput
                        name={
                            files.length > 0
                                ? files.map((value) => {
                                      return value.name
                                  })
                                : 'UPLOAD'
                        }
                        isFileUploaded={files.length > 0}
                        handleChange={(e) => onChangeFile(e)}
                        fileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                        inputType={FileInputType.DOCUMENT_UPLOAD}
                        multiple
                    />
                )
            case CreateDocumentType.InsertLink:
                return <Input placeholder="Insert Link" value={insertLink} onChange={(e) => setInsertLink(e.target.value)} />
            case CreateDocumentType.LinkedDocument:
                return <LinkedDocuments selectedDocuments={[...selectedLinkedDocuments]} documents={documents} onChange={(e) => setSelectedLinkedDocuments(e)} />
        }
    }

    useEffect(() => {
        async function loadComponent() {
            try {
                props.onLoading()
                const documents = await NewDocumentService.getDocuments({ page: 0, size: 1000 })
                setDocuments(documents.content)
            } catch (error) {
                props.onError(error)
            } finally {
                props.onCompleted()
            }
        }

        loadComponent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal
            className="create-documet-modal"
            visible={props.visible}
            title="Add related documents"
            primaryButtonText="Save"
            onClickPrimary={() => createDocumentHandler()}
            secondaryButtonText="Cancel"
            onClickSecondary={props.closeModal}
        >
            <div className="form-container">
                <DropdownInput
                    multiple={true}
                    selected={createDocumentType}
                    className="status-dropdown"
                    actions={createDocumentTypes.map((type) => {
                        return {
                            text: type.text,
                            onClick: () => setCreateDocumentType(type.text),
                        }
                    })}
                />
                {formRenderer()}
            </div>
        </Modal>
    )
}

export default AddDocument
