/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { firebaseApp } from './services/firebase'
import { getAuth, signInAnonymously } from 'firebase/auth'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import ForgotPassword from './pages/ForgotPassword'
import Validation from './pages/Validation'
import { Routes, Outlet, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-medium-image-zoom/dist/styles.css'
import './components/MonittorTable/MonittorTable.scss'
import ProductList from './pages/CustomerPages/ProductList'
import EquipmentListForCustomer from './pages/CustomerPages/EquipmentList'
import EquipmentDetailForCustomer from './pages/CustomerPages/EquipmentDetail'
import EquipmentListForManufacturer from './pages/ManufacturerPages/EquipmentList'
import MasterCatalog from './pages/ManufacturerPages/MasterCatalog'
import CustomerDetail from './pages/ManufacturerPages/CustomerDetail'
import CustomerListPage from './pages/ManufacturerPages/CustomerListPage'
import Profile from './pages/Profile'
import DocumentList from './pages/ManufacturerPages/DocumentList'
import DocumentDetail from './pages/ManufacturerPages/DocumentDetail'
import OrderListForManufacturers from './pages/ManufacturerPages/OrderListForManufacturers'
import OrderList from './pages/CustomerPages/OrderList'
import CustomerEquipmentDetailForManufacturer from './pages/ManufacturerPages/CustomerEquipmentDetail'
import EquipmentDetailForManufacturer from './pages/ManufacturerPages/EquipmentDetail'
import Notifications from './pages/Notifications'
import OrderPdf from './components/OrderPDF/OrderPdf'
import AuthService from './services/UiService/Auth'
import TokenService from './services/NewServices/TokenService'
import Inpersonate from './pages/Inpersonate'
import UserVerification from './pages/UserVerification'
import Settings from './pages/Settings'
import { CompanyType } from './models/Ui/CompanyType'
import CompanyVerifitationPage from './pages/CompanyVerification'
import CurrentOrderPreview from './pages/OrderCurrent/index'
import ActivityList from './pages/ManufacturerPages/ActivityList/index'
import SocketContextProvider, { SocketContext } from './store/SocketContext'
import ConfirmationContextProvider from './store/ConfirmationContext'
import CheckoutPage from './pages/NewCheckoutPage'
import CheckoutContextProvider from './store/NewCheckoutContext/CheckoutContext'
import SearchPartContextProvider from './store/SearchPartContext'
import Home from './pages/CustomerPages/Home/index'
import CustomerDashboardContextProvider from './store/CustomerDashboardContext'
import CustomerActivityList from './pages/CustomerPages/CustomerActivityList'
import HealthCheck from './pages/HealthCheck'
import QuickCartPage from './pages/ManufacturerPages/QuickCartPage'
import ProductDetailForManufacturer from './pages/ManufacturerPages/ProductDetail'
import ContractDetailForManufacturer from './pages/ManufacturerPages/ContractDetail'
import ContractList from './pages/ManufacturerPages/ContractList/index'
import CustomerCheckoutContextProvider from './store/CustomerCheckoutContext/CustomerCheckoutContext'
import CustomerCheckoutPage from './pages/CustomerPages/Checkout'

interface AuthComponentProps {
    components: { type: string; element: React.ReactElement }[]
}

function AuthComponent(props: AuthComponentProps): React.ReactElement {
    const user = TokenService.getAuth()
    const { setSocketClient } = useContext(SocketContext)

    useEffect(() => {
        setSocketClient((prevState) => ({
            ...prevState,
            userId: user?.id ?? '',
            companyId: user?.company?.id ?? '',
        }))
    }, [user?.id, user?.company?.id])

    if (user == null) return <Navigate to="/login" />
    else {
        const page = props.components.find((p) => p.type === user.company.type)?.element
        if (page == null) {
            return <Navigate to="/" />
        } else return page
    }
}

function PrivateRoute(): React.ReactElement | null {
    const isValidAuth = new AuthService().isAuthenticated()
    if (isValidAuth) {
        return <Outlet />
    } else {
        return <Navigate to="/login" />
    }
}

function App() {
    useEffect(() => {
        const auth = getAuth(firebaseApp)
        signInAnonymously(auth)
    })

    return (
        <div className="App">
            <ConfirmationContextProvider>
                <SocketContextProvider>
                    <CheckoutContextProvider>
                        <CustomerCheckoutContextProvider>
                            <SearchPartContextProvider>
                                <CustomerDashboardContextProvider>
                                    <Routes>
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/sign-up" element={<SignUp />} />
                                        <Route path="/forgot-password" element={<ForgotPassword />}>
                                            <Route path="/forgot-password/:id" element={<ForgotPassword />} />
                                        </Route>
                                        <Route path="/mail-validation/:id" element={<Validation />} />
                                        <Route path="/order/:id/invoice" element={<CurrentOrderPreview />} />
                                        <Route path="/checkoutPage" element={<PrivateRoute />}>
                                            <Route
                                                path="/checkoutPage"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <CustomerCheckoutPage />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <CheckoutPage />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/activity" element={<PrivateRoute />}>
                                            <Route
                                                path="/activity"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <CustomerActivityList companyType={CompanyType.Customer} />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/settings" element={<PrivateRoute />}>
                                            <Route
                                                path="/settings"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <Settings />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <Settings />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/customer/:id" element={<PrivateRoute />}>
                                            <Route
                                                path="/customer/:id"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <CustomerDetail />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/customers" element={<PrivateRoute />}>
                                            <Route
                                                path="/customers"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <CustomerListPage />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/documents" element={<PrivateRoute />}>
                                            <Route
                                                path="/documents"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <DocumentList />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/document/:id" element={<PrivateRoute />}>
                                            <Route
                                                path="/document/:id"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <DocumentDetail />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/orders" element={<PrivateRoute />}>
                                            <Route
                                                path="/orders"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <OrderList />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <OrderListForManufacturers />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/products" element={<PrivateRoute />}>
                                            <Route
                                                path="/products"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <ProductList />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <MasterCatalog />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="/products/:productId" element={<PrivateRoute />}>
                                            <Route
                                                path="/products/:productId"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <ProductDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="/contracts/:id" element={<PrivateRoute />}>
                                            <Route
                                                path="/contracts/:id"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <ContractDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="/quick" element={<PrivateRoute />}>
                                            <Route
                                                path="/quick"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <QuickCartPage />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/" element={<PrivateRoute />}>
                                            <Route
                                                path="/"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <Home />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <ActivityList companyType={CompanyType.Manufacturer} />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/equipment/:equipmentId" element={<PrivateRoute />}>
                                            <Route
                                                path="/equipment/:equipmentId"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <EquipmentDetailForCustomer />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <EquipmentDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/equipment/:equipmentId/assembly/:assemblyId" element={<PrivateRoute />}>
                                            <Route
                                                path="/equipment/:equipmentId/assembly/:assemblyId"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <EquipmentDetailForCustomer />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <EquipmentDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="/custom-equipment/:equipmentId" element={<PrivateRoute />}>
                                            <Route
                                                path="/custom-equipment/:equipmentId"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <EquipmentDetailForCustomer />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <CustomerEquipmentDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/custom-equipment/:equipmentId/assembly/:assemblyId" element={<PrivateRoute />}>
                                            <Route
                                                path="/custom-equipment/:equipmentId/assembly/:assemblyId"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <EquipmentDetailForCustomer />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <CustomerEquipmentDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/customer/:customerId/equipment/:equipmentId" element={<PrivateRoute />}>
                                            <Route
                                                path="/customer/:customerId/equipment/:equipmentId"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <EquipmentDetailForCustomer />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <CustomerEquipmentDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/customer/equipment/:equipmentId/assembly/:assemblyId" element={<PrivateRoute />}>
                                            <Route
                                                path="/customer/equipment/:equipmentId/assembly/:assemblyId"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <EquipmentDetailForCustomer />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <CustomerEquipmentDetailForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/profile" element={<PrivateRoute />}>
                                            <Route
                                                path="/profile"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <Profile />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <Profile />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/notifications" element={<PrivateRoute />}>
                                            <Route
                                                path="/notifications"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <Notifications />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <Notifications />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/pdf" element={<PrivateRoute />}>
                                            <Route
                                                path="/pdf/:id"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <OrderPdf />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/equipments" element={<PrivateRoute />}>
                                            <Route
                                                path="/equipments"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Customer,
                                                                element: <EquipmentListForCustomer />,
                                                            },
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <EquipmentListForManufacturer />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>

                                        <Route path="/contracts" element={<PrivateRoute />}>
                                            <Route
                                                path="/contracts"
                                                element={
                                                    <AuthComponent
                                                        components={[
                                                            {
                                                                type: CompanyType.Manufacturer,
                                                                element: <ContractList />,
                                                            },
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Route>
                                        <Route path="/user-verification/:id" element={<UserVerification />}></Route>
                                        <Route path="/company-verification/:id" element={<CompanyVerifitationPage />}></Route>
                                        <Route path="/inpersonate" element={<Inpersonate />}></Route>
                                        <Route path="/health-check" element={<HealthCheck />} />
                                    </Routes>
                                    <ToastContainer pauseOnHover />
                                </CustomerDashboardContextProvider>
                            </SearchPartContextProvider>
                        </CustomerCheckoutContextProvider>
                    </CheckoutContextProvider>
                </SocketContextProvider>
            </ConfirmationContextProvider>
        </div>
    )
}

export default App
