import { ContractBasicDto } from './Contract'
import { ActivityAssigneListModel } from './User'

export enum ActivityStatusType {
    Open = 'Open',
    InProgress = 'In Progress',
    OnHold = 'On Hold',
    Closed = 'Closed',
}

export enum ActivityTypes {
    Ticket = 'Ticket',
    Service = 'Service',
    ProductReplensh = 'Product Replenishment',
    Billable = 'Billable',
}

export interface CreateActivityModel {
    title: string
    description?: string
    revenueOpportunity: number
    startDate: string
    dueDate: string
    isRecurring: boolean
    frequency: number
    frequencyUnit: ActivityFrequencyUnitType
    duration: number
    durationUnit: ActivityDurationUnitType
    assigneeId?: string
    status: ActivityStatusType
    frequencyType: ActivityFrequencyType
    customerId?: string
    isProductsPurchasableToCustomer: boolean
    type: string
    isBillable: boolean
    products?: ActivityProductModel[]
    equipments?: ActivityEquipmentModel[]
    contractId?: string
    notes?: string
    todos?: string
    attachments?: string
}

export interface ActivityEquipmentModel {
    id: string
    name?: string
    drawingUrl?: string
    serialNo?: string
}
export interface ActivityProductModel {
    productId: string
    product?: {
        name?: string
        partNumber?: string
    }
    quantity: number
}

export enum ActivityFrequencyType {
    OneTime = 'One-time',
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Annually = 'Annually',
    Custom = 'Custom',
}

export enum ActivityFrequencyUnitType {
    Days = 'Day(s)',
    Weeks = 'Week(s)',
    Months = 'Month(s)',
    Years = 'Year(s)',
}

export enum ActivityDurationUnitType {
    Days = 'Day(s)',
    Weeks = 'Week(s)',
    Months = 'Month(s)',
    Years = 'Year(s)',
}

export interface ActivityModel {
    id: string
    manufacturerId: string
    title: string
    groupId: string
    description: string
    revenueOpportunity: number
    startDate: string
    dueDate: string
    isRecurring: boolean
    frequency: number
    frequencyType: ActivityFrequencyType
    frequencyUnit: ActivityFrequencyUnitType
    duration: number
    durationUnit: ActivityDurationUnitType
    assignedById: string
    assigneeId: string
    status: ActivityStatusType
    customerId: string
    isVisibleToCustomer: boolean
    isWorkOrder: boolean
    isProductsPurchasableToCustomer: boolean
    createdAt: string
    updatedAt: string
    assignedBy: ActivityAssigneListModel
    customer: {
        id: string
        name: string
    }
    assignee: ActivityAssigneListModel
    equipments: ActivityEquipmentModel[]
    products: ActivityProductModel[]
    type: ActivityTypes
    isBillable: boolean
    attachments: string
    notes: string
    todos: string
    contractId: string
    contract: ContractBasicDto
}
export interface ActivityGroupModel {
    id: string
    manufacturerId: string
    title: string
    groupId: string
    description: string
    revenueOpportunity: number
    startDate: string
    dueDate: string
    isRecurring: boolean
    frequency: number
    frequencyUnit: string
    duration: number
    durationUnit: string
    assignedById: string
    assigneeId: string
    status: string
    customerId: string
    isVisibleToCustomer: boolean
    isWorkOrder: boolean
    isProductsPurchasableToCustomer: boolean
    createdAt: string
    updatedAt: string
    assignedBy: {
        id: string
        firstName: string
        lastName: string
        email: string
    }
    customer: {
        id: string
        name: string
    }
    assignee: {
        id: string
        firstName: string
        lastName: string
        email: string
    }
    contractId: string
    contract: ContractBasicDto
    equipments?: ActivityEquipmentModel[]
}

export interface UpdateActivityModel {
    id: string
    manufacturerId?: string
    title?: string
    description?: string
    revenueOpportunity?: number
    startDate?: string
    dueDate?: string
    isRecurring?: boolean
    frequency?: number
    frequencyType?: ActivityFrequencyType
    frequencyUnit?: ActivityFrequencyUnitType
    duration?: number
    durationUnit?: ActivityDurationUnitType
    assigneeId?: string
    status?: ActivityStatusType
    customerId?: string
    isProductsPurchasableToCustomer: boolean
    equipments?: ActivityEquipmentModel[]
    products?: ActivityProductModel[]
    type?: ActivityTypes
    isBillable?: boolean
    attachments?: string
    notes?: string
    todos?: string
    contractId?: string | null
}
export interface UpdatePartialActivityModel {
    isVisibleToCustomer?: boolean
    isWorkOrder?: boolean
    customerId?: string
    revenueOpportunity?: number
}
export interface UpdateActivityGroupModel {
    manufacturerId: string
    title?: string
    description?: string
    revenueOpportunity?: number
    startDate?: string
    isRecurring?: boolean
    frequencyType?: ActivityFrequencyType
    frequency?: number
    frequencyUnit?: ActivityFrequencyUnitType
    duration?: number
    durationUnit?: ActivityDurationUnitType
    assigneeId?: string
    status?: ActivityStatusType
    customerId: string
    isProductsPurchasableToCustomer: boolean
    isChangeFrequency?: boolean
    equipments?: ActivityEquipmentModel[]
    products?: ActivityProductModel[]
    type?: ActivityTypes
    isBillable?: boolean
    attachments?: string
    notes?: string
    todos?: string
}
export interface ActivityDetailModel {
    id: string
    manufacturerId: string
    title: string
    groupId: string
    description: string
    revenueOpportunity: number
    startDate: string
    endDate: Date
    isRecurring: boolean
    frequency: number
    frequencyUnit: string
    duration: number
    durationUnit: string
    assignedById: string
    assigneeId: string
    status: string
    customerId: string
    isVisibleToCustomer: boolean
    isWorkOrder: boolean
    isProductsPurchasableToCustomer: boolean
    createdAt: string
    updatedAt: string
    assignedBy: {
        id: string
        firstName: string
        lastName: string
        email: string
    }
    customer: {
        id: string
        name: string
    }
    assignee: {
        id: string
        firstName: string
        lastName: string
        email: string
    }
    contractId: string
    contract: ContractBasicDto
}

export interface RecurringDetailModel {
    id: string
    activityId: string
    title: string
    dueDate: Date
    status: ActivityStatusType
    notes: string
    assigneeId: string
    assignee: {
        id: string
        firstName: string
        lastName: string
        email: string
    }
}

export interface CreateActivityTaskModel {
    activityId: string
    title: string
    dueDate: string
    status: string
    notes: string
    assigneeId: string | undefined
}
