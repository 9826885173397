import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icons, { IconType } from '../../../../../Icon'
import { arraySort, isAbbreviation } from '../../../../../utils/helpers'
import Button from '../../../../../components/Button'
import { priceFormatter } from '../../../../../utils/helpers'
import { CheckoutContext } from '../../../../../store/NewCheckoutContext/CheckoutContext'
import { TechnicianViewType } from '../../../../../store/models'
import { ignoreDashClassname } from '../../../../../hooks/useTableHook'
import PopoverItem from '../../../../../components/PopoverItem'
import { useNavigate } from 'react-router'
import { Switch, Tooltip } from '@mui/material'
import moment from 'moment'

interface Data {
    id: string
    title: string
    customer: string
    startDate: Date | null
    endDate: Date | null
    price: number
    isVisibleToCustomer: boolean
    actions?: string
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    data: Data[]
    createAction: () => void
    updateVisible: (id: string, visible: boolean) => void
}

const columns: HeadCell[] = [
    {
        id: 'title',
        label: 'Title',
    },
    {
        id: 'customer',
        label: 'Customer',
    },
    {
        id: 'startDate',
        label: 'Start Date',
    },
    {
        id: 'endDate',
        label: 'End Date',
    },
    {
        id: 'price',
        label: 'Contract Price',
    },
    {
        id: 'actions',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}

interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const checkoutContext = React.useContext(CheckoutContext)
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'actions'}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
                {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && <TableCell />}
            </TableRow>
        </TableHead>
    )
}

export default function ActiveContractsTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('title')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const navigate = useNavigate()

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    if (props.data.length === 0)
        return (
            <div className="no-data">
                <span>You currently do not have any contracts for this customer yet!</span>
                <Button onClick={props.createAction} className="add-button">
                    <Icons type={IconType.BoldPlus} /> Add New
                </Button>
            </div>
        )

    return (
        <>
            <TableContainer className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: Data, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell className="font-bold" style={{ width: '40%' }} align="left">
                                            <span className="ellipsis-text">{row.title}</span>
                                            {isAbbreviation(row.title.length) && <div className="table-hover">{row.title}</div>}
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }} align="left">
                                            {row.customer}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} align="left">
                                            {row.startDate ? moment(row.startDate).format('ll') : ''}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} align="left">
                                            {row.endDate ? moment(row.endDate).format('ll') : ''}
                                        </TableCell>
                                        <TableCell style={{ width: '12%' }} align="left">
                                            {priceFormatter(row.price || '-')}
                                        </TableCell>
                                        <TableCell className={ignoreDashClassname} style={{ width: '8%', paddingLeft: 0 }} onClick={() => navigate('/contracts/' + row.id)}>
                                            <div className={'contracts-table-actions'}>
                                                <Tooltip title={!row.isVisibleToCustomer ? 'Hidden from customer' : 'Visible to customer'}>
                                                    <Switch
                                                        disabled={false}
                                                        onClick={(e) => e.stopPropagation()}
                                                        checked={row.isVisibleToCustomer}
                                                        onChange={(e) => {
                                                            props.updateVisible(row.id, e.target.checked)
                                                        }}
                                                        // inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </Tooltip>
                                                <div className={'action-cell'}>
                                                    <PopoverItem popoverContent="View Details">
                                                        <Icons type={IconType.ChevronRight} />
                                                    </PopoverItem>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
