import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { useNavigate } from 'react-router'
import { arraySort, isAbbreviation } from '../../../../utils/helpers'
import Icons, { IconType } from '../../../../Icon'
import './style.scss'
import { ReactNode } from 'react'
import NoDataInfo from '../../../../components/NoDataInfo'
import PopoverItem from '../../../../components/PopoverItem'

export enum TableActionsType {
    Delete = 'delete',
    Visibility = 'visibility',
}

export interface EquipmentActionModel {
    actionType: TableActionsType
    element: ReactNode
}

interface Data {
    name: string
    partNo: string
    vendor: string
    // installs: number;
    tags: string[] | any
    id: string
    actions: EquipmentActionModel[]
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    sortKey?: string
    data: Data[]
    navigate: string
    setSearch: (key: string) => void
}

const columns: HeadCell[] = [
    {
        id: 'name',
        label: 'Equipment Name',
    },
    {
        id: 'vendor',
        label: 'Brand',
    },
    {
        id: 'partNo',
        label: 'MFG Part Number',
    },
    // {
    //   id: 'installs',
    //   label: 'Installs'
    // },
    {
        id: 'tags',
        label: 'Tags',
    },
    {
        id: 'actions',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    const { disable, active, direction, children, onclick } = props

    return !disable ? (
        <div className="sorting-menu">{children}</div>
    ) : (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            {disable && <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const { orderBy, setOrderBy, direction, setDirection } = tableProps

    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.id !== 'tags' ? 'left' : 'center'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'tags' && headCell.id !== 'actions'}
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState(props.sortKey || 'name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const navigate = useNavigate()

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />
                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={row.id}>
                                        <TableCell onClick={() => navigate(props.navigate + row.id)} className="font-bold" align="left">
                                            <span className="ellipsis-text">{row.name}</span>
                                            {isAbbreviation(row.name.length) && <div className="table-hover">{row.name}</div>}
                                        </TableCell>
                                        <TableCell onClick={() => navigate(props.navigate + row.id)} align="left">
                                            {row.brand}
                                        </TableCell>
                                        <TableCell onClick={() => navigate(props.navigate + row.id)} align="left">
                                            {row.partNo}
                                        </TableCell>
                                        {/* <TableCell onClick={() => navigate(props.navigate + row.id)} style={{ width: "10%" }} align="left">{row.installs}</TableCell> */}
                                        <TableCell
                                            className="tag-th"
                                            onClick={() => {
                                                !row?.tags?.length && navigate(props.navigate + row.id)
                                            }}
                                            style={{ display: `${row.tags?.length > 0 ? 'flex' : 'table-cell'}`, justifyContent: 'right', alignItems: 'center', columnGap: '4px' }}
                                            align="left"
                                        >
                                            {(row.tags || []).map(
                                                (tag: string, i: number) =>
                                                    i === 0 && (
                                                        <div onClick={() => props.setSearch(tag)} key={i} className="tag-item">
                                                            {tag.length > 5 ? <PopoverItem popoverContent={tag}>{tag.slice(0, 5)}...</PopoverItem> : tag}
                                                        </div>
                                                    )
                                            )}
                                            {row.tags?.length > 1 && (
                                                <>
                                                    <div className="tag-item more">
                                                        <Icons type={IconType.Plus} />
                                                        <div className="more-hover">
                                                            {row.tags.map(
                                                                (tag: string, i: number) =>
                                                                    i > 0 && (
                                                                        <span onClick={() => props.setSearch(tag)} key={i}>
                                                                            {tag}
                                                                        </span>
                                                                    )
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>{(row.actions as EquipmentActionModel[]).map((action) => action.element)}</TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
