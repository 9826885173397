import { useCallback, useContext, useEffect, useState } from 'react'
import SearchInput from '../../../../../components/SearchInput'
import PartsTable from '../../Tables/PartsTable'
import PartialPartsTable from '../../Tables/PartialPartsTable'
import { EquipmentModel, EquipmentPartsListModel, PartsModel } from '../../../../../models/New/Equipment'
import Button, { ButtonType } from '../../../../../components/Button'
import Icons, { IconType } from '../../../../../Icon'
import PopoverItem from '../../../../../components/PopoverItem'
import './DrawingPartsTab.scss'
import CreatePartModal from '../../Modals/Equipment/CreatePartModal'
import EditPartModal from '../../Modals/Equipment/EditPartModal'
import PushEquipmentToCustomModal from '../../Modals/Equipment/PushEquipmentToCustomModal'
import { ConfirmationContext } from '../../../../../store/ConfirmationContext'
import PartService from '../../../../../services/UiService/Part'
import BasicDropdown from '../../../../../components/Dropdown/BasicDropdown'
import DrawingDashboard from './../../../../../components/DrawingDashboard/DrawingDashboard'
import { EquipmentActionTypes } from '../../../../../models/Ui/Common'
import EditPartsListModal from '../../Modals/EditPartsList'

interface Props {
    isHortizalRow?: boolean
    pushChangesButtonInvisible?: boolean
    equipment: EquipmentModel
    visible: boolean
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: any) => void
    onError: (error: any) => void
    selectedImageIndex: number
    onClickPreviousImage: () => void
    onClickNextImage: () => void
}

interface EditModalInfoModel {
    visible: boolean
    data: PartsModel | undefined
}
interface CreateModalInfoModel {
    visible: boolean
    data: EquipmentPartsListModel | undefined
}

function DrawingPartsTab(props: React.PropsWithChildren<Props>) {
    const { confirm } = useContext(ConfirmationContext)

    const { onLoading, onError, onSuccess, onCompleted, selectedImageIndex, onClickPreviousImage, onClickNextImage } = props
    const currentItem = props.equipment
    const [activePartListIndex, setActivePartListIndex] = useState<number>(0)
    const [filteredData, setFilteredData] = useState([] as EquipmentModel[] | any[])
    const [searchedData, setSearchedData] = useState([] as EquipmentModel[] | any[])
    const [createPartModalInfo, setCreatePartModalInfo] = useState<CreateModalInfoModel>({ visible: false, data: undefined })
    const [editPartModalInfo, setEditPartModalInfo] = useState<EditModalInfoModel>({ visible: false, data: undefined })
    const [pushEquipmentToCustomModalVisible, setPushEquipmentToCustomModalVisible] = useState<boolean>(false)
    const [editPartsListNameModalVisible, setEditPartsListNameModalVisible] = useState<boolean>(false)

    const getSearchedData = useCallback(async () => {
        let searchedArray: any = []
        for (let i = 0; i < currentItem?.partLists?.length; i++) {
            const newData = currentItem.partLists[i]?.parts.map((item) => {
                return { ...item, listName: currentItem.partLists[i]?.name }
            })
            searchedArray = searchedArray.concat(newData)
        }
        setSearchedData(searchedArray)
    }, [currentItem.partLists])

    useEffect(() => {
        if (currentItem.partLists.length > 0) {
            setFilteredData(currentItem.partLists[activePartListIndex]?.parts)
            getSearchedData()
        }
    }, [activePartListIndex, currentItem, getSearchedData])

    if (currentItem.partLists.length > 0 && activePartListIndex > currentItem.partLists.length - 1) {
        setActivePartListIndex(0)
    } /* else not needed */

    const handleSearchedData = (data: EquipmentModel[]) => {
        setFilteredData(data)
    }
    const addToCart = (data: any) => {
        console.log(data)
        // checkoutContext.addPart(props.equipment, data)
    }

    const confirmDeletePart = (id: string, partNumber: string) => {
        const isParent: boolean = !!currentItem.partLists[activePartListIndex]?.parts.find((item) => item.kitNo === partNumber)
        confirm({
            modalClassName: 'delete-part-modal',
            modalTitle: 'Delete part?',
            primaryButtonText: 'Delete',
            contentText: isParent ? 'Deleting this part also deletes all the parts attached to it. This action cannot be undone.' : 'Are you sure you would like to proceed?',
            func: async (successCallback, errCallback) => {
                try {
                    onLoading()
                    const response = await PartService.delete(id)
                    onSuccess(EquipmentActionTypes.UPDATE)
                    successCallback(response.message)
                } catch (error: any) {
                    onError(error)
                    errCallback(error)
                } finally {
                    onCompleted()
                }
            },
        })
    }

    return (
        <div className={`equipment-drawing-parts-container ${props.isHortizalRow && 'hortizal-row'}`} style={{ display: props.visible ? 'block' : 'none' }}>
            <div className="drawing-container">
                <DrawingDashboard
                    selectedImageIndex={selectedImageIndex}
                    onClickPreviousImage={onClickPreviousImage}
                    onClickNextImage={onClickNextImage}
                    equipment={props.equipment}
                    onLoading={props.onLoading}
                    onSuccess={props.onSuccess}
                    onCompleted={props.onCompleted}
                />
            </div>
            {currentItem?.partLists?.length > 0 && (
                <div className="parts-table-container">
                    <div className="equipment-detail__drawing-parts">
                        <div className={`equipment-detail-select-list-dropdown ${props.isHortizalRow && 'small-width'}`}>
                            <div onClick={() => setEditPartsListNameModalVisible(true)} className="partlist-edit-button">
                                <PopoverItem popoverContent="Edit parts list name">
                                    <Icons type={IconType.Pen} />
                                </PopoverItem>
                            </div>
                            <BasicDropdown
                                label="TYPE"
                                menuItems={currentItem.partLists.map((p, index) => {
                                    return {
                                        id: index,
                                        text: p.name,
                                    }
                                })}
                                onChange={(data) => {
                                    setActivePartListIndex(data.id)
                                }}
                                selectedItem={{
                                    id: activePartListIndex,
                                    text: currentItem.partLists[activePartListIndex]?.name,
                                }}
                            />
                            <SearchInput
                                multipleData={searchedData}
                                data={currentItem.partLists[activePartListIndex]?.parts}
                                handleSearch={handleSearchedData}
                                searchKeys={['name', 'category', 'brand', 'quantity', 'pkg', 'price', 'partNumber']}
                            />
                        </div>
                        <div className="parts-actions-buttons">
                            <Button type={ButtonType.Primary} className="addPart-button" onClick={() => setCreatePartModalInfo({ visible: true, data: currentItem.partLists[activePartListIndex] })}>
                                <PopoverItem key="add" popoverContent="Add part">
                                    <Icons type={IconType.PlusCircle} />
                                </PopoverItem>
                            </Button>
                            {props.pushChangesButtonInvisible && (
                                <Button type={ButtonType.Tertiary} onClick={() => setPushEquipmentToCustomModalVisible(true)}>
                                    <PopoverItem key="push" popoverContent="Pushing changes to custom installations will update all records linked to customers.">
                                        Push changes
                                    </PopoverItem>
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="list-wrapper">
                        {currentItem?.partLists?.length > 0 &&
                            currentItem.partLists[activePartListIndex] &&
                            (props.isHortizalRow ? (
                                <PartialPartsTable
                                    data={filteredData.sort((a, b) => a.index - b.index)}
                                    deletePart={confirmDeletePart}
                                    editPart={(data) => setEditPartModalInfo({ visible: true, data: data })}
                                    addToCart={addToCart}
                                    showAddToCart={false}
                                    showEditDelete
                                />
                            ) : (
                                <PartsTable
                                    data={filteredData.sort((a, b) => a.index - b.index)}
                                    deletePart={confirmDeletePart}
                                    // editPart={(data) => setEditPartModalInfo({ visible: true, data: data })}
                                    addToCart={addToCart}
                                    showAddToCart={false}
                                    showEditDelete
                                />
                            ))}
                    </div>
                </div>
            )}
            {createPartModalInfo && createPartModalInfo.data && (
                <CreatePartModal
                    partListName={createPartModalInfo.data.name}
                    partListId={createPartModalInfo.data.id || ''}
                    visible={createPartModalInfo.visible}
                    closeModal={() => setCreatePartModalInfo({ visible: false, data: undefined })}
                    onLoading={onLoading}
                    onCompleted={onCompleted}
                    onSuccess={onSuccess}
                    onError={onError}
                />
            )}
            {editPartModalInfo && editPartModalInfo.data && (
                <EditPartModal
                    data={editPartModalInfo.data}
                    visible={editPartModalInfo.visible}
                    closeModal={() => setEditPartModalInfo({ visible: false, data: undefined })}
                    onLoading={onLoading}
                    onCompleted={onCompleted}
                    onSuccess={onSuccess}
                    onError={onError}
                />
            )}
            {pushEquipmentToCustomModalVisible && (
                <PushEquipmentToCustomModal
                    isMoleaerUser={props.equipment.manufacturerId === '140542f7-a3bc-40ca-879e-176a0a223b11'}
                    type="partList"
                    warningTitle={
                        props.equipment.manufacturerId
                            ? '(Warning: this will overwrite sub-assemblies, part-lists, parts, and documents of custom equipment and replace them to match this template. This cannot be undone).'
                            : '(Warning: this will overwrite the drawings and part lists completely and replace them to match this template.This cannot be undone).'
                    }
                    id={props.equipment.id}
                    visible={pushEquipmentToCustomModalVisible}
                    closeModal={() => setPushEquipmentToCustomModalVisible(false)}
                    onLoading={onLoading}
                    onCompleted={onCompleted}
                    onError={onError}
                />
            )}
            {editPartsListNameModalVisible && currentItem.partLists[activePartListIndex] && (
                <EditPartsListModal
                    id={currentItem.partLists[activePartListIndex].id!}
                    name={currentItem.partLists[activePartListIndex].name}
                    visible={editPartsListNameModalVisible}
                    closeModal={() => setEditPartsListNameModalVisible(false)}
                    onLoading={onLoading}
                    onCompleted={onCompleted}
                    onError={onError}
                    onSuccess={onSuccess}
                />
            )}
        </div>
    )
}

export default DrawingPartsTab
