/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import ProductsTable from './ProductsTable'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Tab from '../../../components/Tab'
import CustomerService from '../../../services/UiService/Customer'
import OrderService from '../../../services/UiService/Order'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import Icons, { IconType } from '../../../Icon'
import ThreeDotButton, { ActionModel } from '../../../components/ThreeDotButton'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import SearchInput from '../../../components/SearchInput'
import CustomerDetailCard from './CustomerDetailCard'
import EquipmentTable from './EquipmentTable'
import './CustomerDetail.scss'
import { Switch, Tooltip } from '@mui/material'
import TechnicianView from '../TechnicianView'
import { arraySort, priceFormatter, standardDate } from '../../../utils/helpers'
import { CustomerContactModel, CustomerDetailModel, CustomerEquipmentsModel, CustomerProductModel } from '../../../models/New/Customer'
import EquipmentService from '../../../services/UiService/Equipment'
import { OrderModel } from '../../../models/New/Order'
import { UserRoleType } from '../../../models/Ui/UserRoleType'
import { UserModel } from '../../../models/Ui/User'
import UserService from '../../../services/UiService/User'
import OrderListTable from '../../../components/OrderTableList'
import ActivityService from '../../../services/UiService/Activity'
import { ActivityModel, ActivityTypes } from '../../../models/Ui/Activity'
import ActivityListTable, {
    activityTableColumns,
    ActivityTableDataModel,
    assigneeColorSetter,
    assigneeFirstNameSetter,
    assigneeShortNameSetter,
    AssigneeWithColorModel,
    filterAssignees,
    HeadCell,
    HeadCellType,
    setAssigneeInputPlaceholder,
    useUniqueAssigneesWithColorsSetter,
} from '../ActivityList/ActivityListTable'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import CompanyService from '../../../services/UiService/Company'
import { CheckoutContext } from '../../../store/NewCheckoutContext/CheckoutContext'
import { ICartInformation, ISavedCartInformation, TechnicianViewType } from '../../../store/models'
import Button, { ButtonType } from '../../../components/Button'
import { DueDateFilter, FilterKeys, FilterState, FilterType, SortByFilter, SortByType, StatusFilter, StatusFilterType } from '../ActivityList'
import PartialUpdateBox from '../ActivityList/PartialUpdateBox'
import CheckBoxDropdownInput from '../../../components/CheckBoxDropdownInput'
import Overview from './Overview/index'
import CompanyModal from './Modals/CompanyUpdateModal/CompanyModal'
import UpdateAddressModal from './Modals/CompanyUpdateAddressModal/UpdateAddressModal'
import { CustomerEquipmentStatus } from '../../../models/Db/Customer'
import PopoverItem from './../../../components/PopoverItem/index'
import LinkEquipmentsModal from './Modals/LinkEquipmentModal/index'
import { EquipmentLinkToCustomer, EquipmentListModel } from '../../../models/New/Equipment'
import { LinkToEquipmentModel } from '../../../models/Ui/Customer'
import ContractsTable from './ContractsTable'
import CreateContractModal from './ContractsTable/Modals/CreateContractModal'
import { ContractOutputModel } from '../../../models/Ui/Contract'
import ContractService from '../../../services/UiService/Contract'
import ContactModal from './Contacts/Modals/ContactModal'
import BasicDropdown from '../../../components/Dropdown/BasicDropdown'
import ProductPreview from '../MasterCatalog/ProductPreview'
import UpdateActivityPopupSidebar from '../ActivityList/Modals/UpdateActivityPopupSidebar'
import AddActivityPopupSidebar from '../ActivityList/Modals/AddActivityPopupSidebar'

const tabItems: TabItem[] = [
    {
        id: Tabs.Overview,
        name: 'Overview',
    },
    {
        id: Tabs.Activities,
        name: 'Activities',
    },
    {
        id: Tabs.Equipment,
        name: 'Equipment',
    },
    {
        id: Tabs.Contracts,
        name: 'Contracts',
    },
    {
        id: Tabs.Products,
        name: 'Products',
    },
    {
        id: Tabs.Customer,
        name: 'Contacts',
    },
    // {
    //     id: Tabs.Orders,
    //     name: 'Orders',
    // },
]

const activityTabItems: TabItem[] = [
    {
        id: Tabs.All,
        name: 'All',
    },
    {
        id: Tabs.Service,
        name: 'Service',
        customIcon: <Icons type={IconType.Maintenance} />,
    },
    {
        id: Tabs.ProductReplenishment,
        name: 'Products',
        customIcon: <Icons type={IconType.Product} />,
    },
    {
        id: Tabs.Activities,
        name: 'Tickets',
        customIcon: <Icons type={IconType.Ticket} />,
    },
]

export enum ContractActionTypes {
    CREATE_CONTACT,
    UPDATE_CONTACT,
    DELETE_CONTACT,
    UPDATE_PRIMARY_CONTACT,
    UPDATE_COMPANY_CUSTOMER,
    CREATE_COMPANY_CUSTOMER,
    INVITE_CUSTOMER,
    SEND_REMINDER,
    CREATE_CONTRACT,
    UPDATE_CONTRACT,
    DELETE_PRODUCT,
}

function CustomerDetail() {
    const { confirm } = useContext(ConfirmationContext)
    const navigate = useNavigate()
    const [user, setUser] = useState<UserModel>()
    const [customer, setCustomer] = useState<CustomerDetailModel | null>(null)
    const [equipments, setEquipments] = useState<CustomerEquipmentsModel[]>([])
    const [filteredData, setFilteredData] = useState([] as CustomerEquipmentsModel[])
    const [filteredContractsData, setFilteredContractsData] = useState([] as ContractOutputModel[])
    const [products, setProducts] = useState<CustomerProductModel[]>([])
    const [filteredProducts, setFilteredProducts] = useState([] as CustomerProductModel[])
    const [contracts, setContracts] = useState<ContractOutputModel[]>([])
    const [orders, setOrders] = useState<OrderModel[]>([])
    const [filteredOrders, setFilteredOrders] = useState<OrderModel[]>([] as OrderModel[])
    const [loading, setLoading] = useState<boolean>(true)
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)
    const [searchKey, setSearchKey] = useState<string>('')
    const [activities, setActivities] = useState<ActivityModel[]>([])
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false)
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [filteredActivitiesData, setFilteredActivitiesData] = useState<ActivityModel[]>([])
    const [partialUpdateModalVisible, setPartialUpdateModalVisible] = useState(false)
    const [createActivityModalVisible, setCreateActivityModalVisible] = useState<boolean>(false)
    const [uniqueAssigneesWithColors, setUniqueAssigneesWithColors] = useState<AssigneeWithColorModel[]>([])
    const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([])
    const [showAllAsignees, setShowAllAsignees] = useState(true)
    const [viewHistoricalOnes, setViewHistoricalOnes] = useState(false)
    const [activeActivityTab, setActiveActivityTab] = useState<number>(activityTabItems[0].id)
    const [companyModalVisible, setCompanyModalVisible] = useState<boolean>(false)
    const [updateAddressModalVisible, setUpdateAddressModalVisible] = useState<boolean>(false)
    const [createContractModalVisible, setCreateContractModalVisible] = useState<boolean>(false)
    const [linkEquipmentsModalVisible, setLinkEquipmentsModalVisible] = useState<boolean>(false)
    const [equipmentList, setEquipmentList] = useState<EquipmentListModel[]>([])
    const [primaryContact, setPrimaryContact] = useState<CustomerContactModel | null>(null)
    const [contactModalVisible, setContactModalVisible] = useState<boolean>(false)
    const [selectedProductId, setSelectedProductId] = useState<string>('')
    const [selectedActivityProductId, setSelectedActivityProductId] = useState<string>('')
    const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>('')
    const [productPreviewVisible, setProductPreviewVisible] = useState<boolean>(false)
    const companyRef = useRef<CustomerDetailModel>()
    const companyService = new CompanyService()
    const customerService = new CustomerService()

    useUniqueAssigneesWithColorsSetter(activities, setUniqueAssigneesWithColors)

    useEffect(() => {
        setSelectedAssigneeIds(uniqueAssigneesWithColors.map((child) => child.assigneeId))
    }, [uniqueAssigneesWithColors])

    const handleSearchedActivitiesData = (data: ActivityModel[]) => {
        setFilteredActivitiesData(data)
    }

    dayjs.extend(localizedFormat)

    let [searchParams, setSearchParams] = useSearchParams()

    const [selectedActivtyIds, setselectedActivtyIds] = useState<{ id: string; groupId: string }>({
        id: '',
        groupId: '',
    })

    let { id } = useParams()

    const checkoutContext = useContext(CheckoutContext)

    const dueDateFilterTypes: DueDateFilter[] = [
        { id: ' ', text: 'All Time' },
        {
            id: dayjs(),
            text: 'Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'day'),
            text: 'Before Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'week'),
            text: 'Last Week',
            key: 'week',
        },
        {
            id: dayjs().add(1, 'week'),
            text: 'Next Week',
            key: 'week',
        },
        {
            id: dayjs(),
            text: 'This Week',
            key: 'week',
        },
        {
            id: dayjs().subtract(1, 'month'),
            text: 'Last Month',
            key: 'month',
        },
        {
            id: dayjs().add(1, 'month'),
            text: 'Next Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Year',
            key: 'year',
        },
    ]
    const statusFilterTypes: StatusFilter[] = [
        { id: ' ', text: 'All' },
        { id: 'Open', text: 'Open', type: StatusFilterType.Open },
        { id: 'In Progress', text: 'In Progress', type: StatusFilterType.InProgress },
        { id: 'On Hold', text: 'On Hold', type: StatusFilterType.OnHold },
        { id: 'Closed', text: 'Closed', type: StatusFilterType.Closed },
    ]

    const sortByFilterOptions: SortByFilter[] = [
        { id: 'None', text: 'None', type: SortByType.None },
        { id: 'Product', text: 'Product', type: SortByType.Product },
        { id: 'Equipment', text: 'Equipment', type: SortByType.Equipment },
    ]

    const initialFilter: FilterState = {
        dueDate: { ...dueDateFilterTypes[0] } as DueDateFilter,
        status: { ...statusFilterTypes[0] } as FilterType,
        sortBy: sortByFilterOptions[0],
    }
    const [filterState, setFilterState] = useState<FilterState>(initialFilter)

    const setFilter = (newState: any, key: FilterKeys) =>
        setFilterState((prevState) => ({
            ...prevState,
            [key]: newState,
        }))

    useEffect(() => {}, [filterState])

    useEffect(() => {}, [showFilter])

    useEffect(() => {}, [customer?.contacts])

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Customers',
            url: '/customers',
        },
        {
            name: customer?.name!,
            url: '/customer/' + customer?.id!,
        },
        {
            name:
                activeTab === Tabs.Equipment ? 'Equipment' : activeTab === Tabs.Products ? 'Products' : activeTab === Tabs.Orders ? 'Orders' : activeTab === Tabs.Activities ? 'Activities' : 'Details',
        },
    ]
    const actionsButtons: ActionModel[] = [
        {
            text: 'Edit customer details',
            onClick: () => setCompanyModalVisible(true),
            permissions: [UserRoleType.Admin, UserRoleType.Manager, UserRoleType.Technician],
        },
        {
            text: 'Delete customer',
            onClick: () =>
                confirm({
                    modalClassName: 'delete-customers-modal',
                    modalTitle: 'Delete customer',
                    primaryButtonText: 'Delete customer',
                    func: async (successCallback, errCallback) => {
                        try {
                            await companyService.deleteCustomer(customer?.id ?? '')
                            successCallback()
                            navigate('/customers', { replace: true })
                        } catch (error: any) {
                            errCallback(error)
                        }
                    },
                }),
            permissions: [UserRoleType.Admin, UserRoleType.Manager, UserRoleType.Technician],
        },
        {
            text: 'View as customer',
            onClick: () => {
                checkoutContext.handleCartInformation({
                    technicianViewType: TechnicianViewType.ViewAs,
                    purchaseForCustomerId: id,
                    purchaseForCustomerName: customer?.name,
                } as ICartInformation)

                checkoutContext.setSavedCartInfo({
                    name: `Saved cart ${standardDate(new Date())}`,
                } as ISavedCartInformation)
            },
            permissions: [UserRoleType.Admin, UserRoleType.Technician],
        },
        {
            text: 'Purchase on behalf of customer',
            onClick: () => {
                checkoutContext.handleCartInformation({
                    technicianViewType: TechnicianViewType.PurchaseAs,
                    purchaseForCustomerId: id,
                    purchaseForCustomerName: customer?.name,
                } as ICartInformation)

                checkoutContext.setSavedCartInfo({
                    name: `Saved cart ${standardDate(new Date())}`,
                } as ISavedCartInformation)
            },
            permissions: [UserRoleType.Admin, UserRoleType.Technician],
        },
    ].filter((item) => user?.role && item.permissions?.includes(user.role))

    const getCustomerCallback = useCallback(async () => {
        try {
            const customer = await customerService.customerDetailForManufacturer(id!)
            setCustomer(customer)
            setEquipments(customer.equipments)
            setFilteredData(customer.equipments)
            setProducts(customer.products)

            await getContracts(customer.id)
            if (customer) {
                const isPrimaryContact = customer?.contacts.find((contact) => contact.isPrimary)
                setPrimaryContact(isPrimaryContact ?? null)
            }
        } catch (e: any) {
            toast.error(e)
        }
    }, [id])

    const getContracts = async (customerId: string) => {
        const list = await ContractService.getAllByCustomerId(customerId)
        setContracts(list)
        setFilteredContractsData(list)
    }

    const getUserCallback = useCallback(async () => {
        try {
            const userService = new UserService()
            const userResponse = await userService.get()
            setUser(userResponse)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [])

    useEffect(() => {
        const activeOne = searchParams?.get('activeTab')
        setActiveTab(Number(activeOne) || Tabs.Overview)
    }, [searchParams])

    const getActivities = useCallback(async () => {
        try {
            const response = await ActivityService.getForCustomer(id!)
            setActivities(response)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [id])

    const onSuccessCallback = useCallback(
        async (action: ContractActionTypes, data?: any, id?: string) => {
            switch (action) {
                case ContractActionTypes.CREATE_CONTRACT:
                    await getContracts(customer!.id)
                    break

                case ContractActionTypes.UPDATE_CONTACT:
                    const contacts = customer?.contacts.filter((item: CustomerContactModel) => item.id !== data?.id)
                    data &&
                        setCustomer((prevState: any) => ({
                            ...prevState,
                            contacts: contacts?.concat([data]),
                        }))
                    await getCustomerCallback()
                    break
                case ContractActionTypes.UPDATE_PRIMARY_CONTACT:
                    const newContactsData: CustomerContactModel[] = []
                    customer?.contacts.forEach((p) => {
                        if (p.id !== data.id) {
                            if (!p.isPrimary) {
                                newContactsData.push(p)
                            } else {
                                const updateData = {
                                    firstName: p.firstName,
                                    lastName: p.lastName,
                                    email: p.email,
                                    phone: p.phone,
                                    jobTitle: p.jobTitle,
                                    isPrimary: false,
                                    companyId: p.companyId,
                                    notes: p.notes,
                                    id: p.id,
                                }
                                newContactsData.push(updateData)
                            }
                        } else {
                            const updateData = {
                                firstName: p.firstName,
                                lastName: p.lastName,
                                email: p.email,
                                phone: p.phone,
                                jobTitle: p.jobTitle,
                                isPrimary: data.isPrimary,
                                companyId: p.companyId,
                                notes: p.notes,
                                id: p.id,
                            }
                            newContactsData.push(updateData)
                        }
                    })

                    setCustomer((prevState: any) => ({
                        ...prevState,
                        contacts: newContactsData,
                    }))
                    const isPrimaryContact = newContactsData?.find((contact) => contact.isPrimary)
                    setPrimaryContact(isPrimaryContact ?? null)
                    // await getCustomerCallback()
                    break
                case ContractActionTypes.CREATE_CONTACT:
                    await getCustomerCallback()
                    break
                case ContractActionTypes.DELETE_CONTACT:
                    setLoading(true)
                    delete (data as any).id // Added to prevent wrong equipment id bug!
                    const newData = customer?.contacts.filter((item: CustomerContactModel) => item.id !== id)
                    setCustomer((prevState: any) => ({
                        ...prevState,
                        contacts: newData,
                    }))
                    /* else not needed */
                    await getCustomerCallback()
                    setPrimaryContact(null)
                    setLoading(false)
                    break
                case ContractActionTypes.UPDATE_COMPANY_CUSTOMER:
                    await getCustomerCallback()
                    break
                case ContractActionTypes.CREATE_COMPANY_CUSTOMER:
                    break
                case ContractActionTypes.INVITE_CUSTOMER:
                    setCustomer((prevState: any) => ({
                        ...prevState,
                        status: CustomerEquipmentStatus.Pending,
                    }))
                    break
                case ContractActionTypes.SEND_REMINDER:
                    setCustomer((prevState: any) => ({
                        ...prevState,
                        status: CustomerEquipmentStatus.Accepted,
                    }))
                    break
                case ContractActionTypes.DELETE_PRODUCT:
                    break
            }
        },
        [customer?.contacts, getCustomerCallback]
    )

    async function getOrders() {
        try {
            setLoading(true)
            const orders = await OrderService.getCustomerOrderForManufacturer(id!)
            setOrders(arraySort(orders, 'creationDate', 'descending'))
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getUserCallback()
            await getCustomerCallback()
            await getOrders()
            await getActivities()
            setLoading(false)
        }

        loadComponent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    async function getEquipments() {
        setLoading(true)
        try {
            const equipments = await EquipmentService.getTemplatesForManufacturer({ page: 0, size: 9999 })
            setEquipmentList(equipments.content)
        } catch (e: any) {
            toast.error(e)
        } finally {
            setLoading(false)
        }
    }

    const updateEquipmentVisible = async (equipmentId: string, visible: boolean) => {
        try {
            await EquipmentService.updateCustomEquipment(equipmentId, { isVisible: visible })
            const newEquipment = [...filteredData]
            const updatedIndex = filteredData.findIndex((p) => p.id === equipmentId)
            newEquipment[updatedIndex].isVisible = visible
            setFilteredData(newEquipment)
        } catch (error) {
            toast.error(error)
        }
    }

    const handleSearchedData = (data: CustomerEquipmentsModel[]) => {
        setFilteredData(data)
    }
    const handleContractSearchedData = (data: ContractOutputModel[]) => {
        setFilteredContractsData(data)
    }

    const handleProductSearched = (data: CustomerProductModel[]) => setFilteredProducts(data)

    const handleOrderSearched = (data: OrderModel[]) => setFilteredOrders(data)

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])
    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    const handleDeleteEquipment = (id: string) => {
        confirm({
            modalClassName: 'delete-equipment-modal',
            modalTitle: 'Delete equipment?',
            primaryButtonText: 'Delete',
            func: async (successCallback, errCallback) => {
                try {
                    setLoading(true)
                    await EquipmentService.delete(id)
                    setCustomer((prevState) => ({
                        ...prevState!,
                        equipments: [...prevState!.equipments.filter((child) => child.id !== id)],
                    }))
                    setEquipments((prevState) => [...prevState.filter((child) => child.id !== id)])
                    setFilteredData((prevState) => [...prevState.filter((child) => child.id !== id)])
                    successCallback()
                } catch (error: any) {
                    errCallback(error)
                } finally {
                    setLoading(false)
                }
            },
        })
    }

    const onChangeVisibility = async (activityId: string, visibility: boolean) => {
        try {
            setLoading(true)
            const res = await ActivityService.setVisibility(activityId, visibility)
            getActivities()
            toast.success(res.message)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const toggleFilterMenu = () => {
        setFilterState(initialFilter)
        setShowFilter(!showFilter)
    }

    const sendReminder = async () => {
        try {
            setLoading(true)
            await companyService.remindeCustomerInvite(customer?.id!!)
            onSuccessCallback(ContractActionTypes.SEND_REMINDER)
            toast.success('Reminder sent successfully!')
        } catch (error) {
            onErrorCallback(error)
        } finally {
            setLoading(false)
        }
    }

    const inviteCustomer = async () => {
        confirm({
            modalTitle: 'Invite Customer',
            contentNode: (
                <Fragment>
                    <span>You are inviting a customer to collaborate with you on Monittor.</span>
                    <hr />
                    <span>
                        Clicking on “Yes” will allow us to <b>send them an email</b> on your behalf. Are you sure you want to proceed?
                    </span>
                </Fragment>
            ),

            primaryButtonText: 'Yes',
            secondaryButtonText: 'No',
            endLine: true,
            func: async (successCallback, errCallback) => {
                try {
                    setLoading(true)
                    await companyService.inviteCustomer(customer?.id!!)
                    toast.success('Invite successfully sent!')
                    onSuccessCallback(ContractActionTypes.INVITE_CUSTOMER)
                    successCallback()
                } catch (error) {
                    onErrorCallback(error)
                } finally {
                    setLoading(false)
                }
            },
        })
    }
    const handleLinkEquipmentModal = async () => {
        if (equipmentList.length === 0) {
            await getEquipments()
        }
        setLinkEquipmentsModalVisible(true)
    }

    const linkEquipmentsHandler = async (param: EquipmentLinkToCustomer[]) => {
        try {
            setLoading(true)
            const payload: LinkToEquipmentModel = {
                customerId: customer?.id!!,
                equipments: param,
            }
            await companyService.linkEquipment(payload)
            toast.success('Successfully linked')
            await getCustomerCallback()
        } catch (error) {
            onErrorCallback(error)
        } finally {
            setLoading(false)
            setLinkEquipmentsModalVisible(false)
        }
    }

    const setActivityTableColumns = (): HeadCell[] => {
        const sortByNoneColumns: HeadCellType[] = [HeadCellType.Empty, HeadCellType.Title, HeadCellType.Assignee, HeadCellType.Status, HeadCellType.DueDate, HeadCellType.Action]
        const sortByProductColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Product,
            HeadCellType.PartNumber,
            HeadCellType.Quantity,
            HeadCellType.TaskWithCustomer,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]
        const sortByEquipmentColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Equipment,
            HeadCellType.Task,
            HeadCellType.Assignee,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]

        let output: HeadCell[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = sortByNoneColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Product) {
            output = sortByProductColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            output = sortByEquipmentColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        }

        return output
    }

    const activityTableDataMapper = (args: ActivityModel[]): ActivityTableDataModel[] => {
        let output: ActivityTableDataModel[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = args.map((child) => {
                return {
                    isWorkOrder: child.isWorkOrder,
                    recurring: child.isRecurring,
                    title: child.title,
                    customer: child.customer?.name,
                    assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                    status: child.status,
                    dueDate: standardDate(child.dueDate),
                    opportunity: priceFormatter(child.revenueOpportunity || '-'),
                    id: child.id,
                    groupId: child.groupId,
                    isVisibleToCustomer: child.isVisibleToCustomer,
                    description: child.description,
                    type: child.type,
                    attachments: child.attachments,
                    equipments: child.equipments,
                    products: child.products,
                    assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                    assigneeShortName: assigneeShortNameSetter(child.assignee),
                    assigneeFirstName: assigneeFirstNameSetter(child),
                }
            })
        } else if (filterState.sortBy.type === SortByType.Product) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.products.map((product) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        productId: product?.productId ?? '',
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        productName: product?.product?.name ?? '',
                        productPartNumber: product?.product?.partNumber ?? '',
                        productQuantity: product?.quantity ?? 0,
                        customerCompanyName: child.customer.name,
                        equipments: child.equipments,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.equipments.map((equipment) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        customerCompanyName: child.customer.name,
                        equipmentName: equipment?.name ?? '',
                        equipmentId: equipment?.id ?? '',
                        products: child.products,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        }

        return output
    }

    const companyStatusButtonRenderer = () => {
        switch (customer?.status) {
            case CustomerEquipmentStatus.NotInvited:
                return (
                    <PopoverItem popoverContent="Invite Customer">
                        <Button onClick={() => inviteCustomer()}>
                            <Icons type={IconType.AddCustomer} />
                        </Button>
                    </PopoverItem>
                )

            case CustomerEquipmentStatus.Pending:
                return (
                    <PopoverItem popoverContent="Send Invite Reminder">
                        <Button onClick={() => sendReminder()} type={ButtonType.Tertiary}>
                            <Icons type={IconType.SendReminder} />
                        </Button>
                    </PopoverItem>
                )
            case CustomerEquipmentStatus.Accepted:
                return (
                    <PopoverItem popoverContent="Invite Accepted">
                        <Button type={ButtonType.Tertiary}>
                            <Icons type={IconType.CheckCircle} />
                        </Button>
                    </PopoverItem>
                )
            default:
                break
        }
    }

    const onClickViewMoreAtPartialUpdateModal = () => {
        setPartialUpdateModalVisible(false)
        setUpdateModalVisible(true)
    }

    return (
        <PageWrapper breadcrumb={breadcrumb} headerInfo={customer?.name!}>
            {loading && <Loading />}
            <TechnicianView isQuick onLoading={() => setLoading(true)} onCompleted={() => setLoading(false)} customerId={id || ''} title={customer?.name} customerName={customer?.name!}>
                {customer && (
                    <ContentBox>
                        <div className="customer-detail-page-header">
                            <div className="customer-info-container">
                                <div className="left">
                                    <span className="company-name">{customer.name}</span>
                                    {primaryContact !== undefined && primaryContact !== null ? (
                                        <div className="manager-info">
                                            <Icons type={IconType.UserReverse} />
                                            <span className="name">{primaryContact.firstName + ' ' + primaryContact.lastName}</span>
                                            <div className="other">
                                                <span>{primaryContact.jobTitle}</span>
                                                <span>{primaryContact.email}</span>
                                                <span>{primaryContact.phone}</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="manager-info">
                                            <Icons type={IconType.UserReverse} />
                                            <span
                                                className="name underLine"
                                                onClick={() => {
                                                    setContactModalVisible(true)
                                                }}
                                            >
                                                +add primary contact
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="right">
                                    <PopoverItem popoverContent="Actions">
                                        <ThreeDotButton actions={actionsButtons} />
                                    </PopoverItem>
                                </div>
                            </div>

                            <div className="tab-container">
                                <Tab
                                    activeTab={activeTab}
                                    tabs={tabItems}
                                    onTabClick={(id) => {
                                        setActiveTab(id)
                                        setSearchParams({ activeTab: id.toString() })
                                    }}
                                ></Tab>
                                {activeTab === Tabs.Equipment && equipments.length > 0 && (
                                    <div className="search-wrapper">
                                        <div className="equipment-search-box">
                                            <SearchInput
                                                data={equipments}
                                                handleSearch={handleSearchedData}
                                                searchKeys={['partNumber', 'name', 'serialNo', 'manufacturerName', 'tagName']}
                                                setDefaultSearch={searchKey}
                                            />
                                        </div>
                                    </div>
                                )}

                                {activeTab === Tabs.Contracts && contracts.length > 0 && (
                                    <div className="search-wrapper">
                                        <div className="equipment-search-box">
                                            <SearchInput data={contracts} handleSearch={handleContractSearchedData} searchKeys={['title']} setDefaultSearch={searchKey} />
                                        </div>
                                        <div className="add-contract">
                                            <PopoverItem popoverContent="Add new contract">
                                                <Button onClick={() => setCreateContractModalVisible(true)}>
                                                    <Icons type={IconType.BoldPlus} /> Add contract
                                                </Button>
                                            </PopoverItem>
                                        </div>
                                    </div>
                                )}

                                {activeTab === Tabs.Products && products.length > 0 && (
                                    <div className="search-wrapper">
                                        <div className="equipment-search-box">
                                            <SearchInput
                                                data={products}
                                                handleSearch={handleProductSearched}
                                                searchKeys={['name', 'brand', 'category', 'description', 'partNumber']}
                                                setDefaultSearch={searchKey}
                                            />
                                        </div>
                                    </div>
                                )}

                                {activeTab === Tabs.Orders && orders.length > 0 && (
                                    <div className="search-wrapper">
                                        <div className="equipment-search-box">
                                            <SearchInput data={orders} handleSearch={handleOrderSearched} searchKeys={['customer', 'userName', 'transactionNumber']} setDefaultSearch={searchKey} />
                                        </div>
                                    </div>
                                )}

                                <div className="company-status-wrapper">{companyStatusButtonRenderer()}</div>
                            </div>
                        </div>
                        {activeTab === Tabs.Activities && activities.length > 0 && (
                            <div className="tab-container">
                                <Tab
                                    activeTab={activeActivityTab}
                                    tabs={activityTabItems}
                                    onTabClick={(id) => {
                                        setActiveActivityTab(id)
                                    }}
                                ></Tab>
                            </div>
                        )}
                        {activeTab === Tabs.Activities && (
                            <div className="activity-right-buttons">
                                {activities.length > 0 && (
                                    <>
                                        <SearchInput
                                            data={activities}
                                            handleSearch={handleSearchedActivitiesData}
                                            searchKeys={['title', 'customer.name', 'assignedBy.firstName', 'assignedBy.lastName', 'startDate', 'frequencyUnit', 'type']}
                                        />
                                        <Tooltip title="View Historical Logs">
                                            <Switch className="historicalLogSwitch" value={viewHistoricalOnes} onChange={() => setViewHistoricalOnes((prevState) => !prevState)} />
                                        </Tooltip>
                                        <Tooltip title="Apply Filters">
                                            <span>
                                                <Button className="filter-button" onClick={toggleFilterMenu}>
                                                    <Icons type={IconType.FilterSettings} />
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </>
                                )}
                                <Tooltip title="Add new activity">
                                    <span>
                                        <Button className="add-task" onClick={() => setCreateActivityModalVisible(true)}>
                                            <Icons type={IconType.BoldPlus} /> Add activity
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                        {activities.length > 0 && showFilter && activeTab === Tabs.Activities && (
                            <div className="filter-menu">
                                <BasicDropdown
                                    label="Sort By"
                                    menuItems={sortByFilterOptions}
                                    onChange={(e) => setFilter(e, FilterKeys.SortBy)}
                                    selectedItem={{ text: filterState.sortBy.text, id: filterState.sortBy.id }}
                                    leftIcon={IconType.Checklist}
                                />
                                <BasicDropdown
                                    label="Due Date"
                                    menuItems={dueDateFilterTypes}
                                    onChange={(e) => setFilter(e, FilterKeys.DueDate)}
                                    selectedItem={{ text: filterState.dueDate.text, id: filterState.dueDate.id }}
                                    leftIcon={IconType.Calendar}
                                />
                                <CheckBoxDropdownInput
                                    disabled={!uniqueAssigneesWithColors.length}
                                    selectedItems={selectedAssigneeIds}
                                    placeholder="Assignee"
                                    listItems={(uniqueAssigneesWithColors || []).map((p) => {
                                        return {
                                            id: p.assigneeId,
                                            text: p.assigneeName,
                                        }
                                    })}
                                    dropIcon={true}
                                    onDataChange={(data, allChecked) => {
                                        setSelectedAssigneeIds(data)
                                        setShowAllAsignees(allChecked as boolean)
                                    }}
                                    showAllCheckbox
                                    showCustomInputLabelWhenEmpty
                                    customInputLabel={setAssigneeInputPlaceholder(selectedAssigneeIds, showAllAsignees, uniqueAssigneesWithColors)}
                                />
                                <BasicDropdown
                                    label="Status"
                                    leftIcon={IconType.DottedCircle}
                                    menuItems={statusFilterTypes}
                                    onChange={(e) => setFilter(e, FilterKeys.Status)}
                                    selectedItem={{ text: filterState.status.text, id: filterState.status.id }}
                                />
                            </div>
                        )}
                        {activeTab === Tabs.Overview && (
                            <Overview
                                activities={activities}
                                customer={customer}
                                orders={orders}
                                contracts={contracts}
                                handleCreateActivity={() => setCreateActivityModalVisible(true)}
                                handleUpdateCompany={() => setCompanyModalVisible(true)}
                                handleUpdateAddress={() => setUpdateAddressModalVisible(true)}
                                handleLinkEquipment={() => handleLinkEquipmentModal()}
                            />
                        )}
                        {!loading && activeTab === Tabs.Equipment && equipments && (
                            <div className="equipment-box">
                                <EquipmentTable
                                    setSearch={(e) => {
                                        if (searchKey === e) {
                                            setSearchKey('')
                                        } else {
                                            setSearchKey(e)
                                        }
                                    }}
                                    data={filteredData.map((equipment) => {
                                        return {
                                            name: equipment.name,
                                            vendor: equipment.manufacturerName,
                                            partNumber: equipment.partNumber,
                                            serialNumber: equipment.serialNo ? equipment.serialNo : '',
                                            tags: equipment.tagName,
                                            buttons: (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <PopoverItem popoverContent={!equipment.isVisible ? 'Hidden from customer' : 'Visible to customer'}>
                                                        <Switch
                                                            checked={equipment.isVisible}
                                                            onChange={() => {
                                                                try {
                                                                    updateEquipmentVisible(equipment.id, !equipment.isVisible)
                                                                    toast.success('Updated successfully!')
                                                                } catch (error) {}
                                                            }}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </PopoverItem>
                                                    <PopoverItem popoverContent="Delete">
                                                        <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteEquipment(equipment.id)} color="var(--blue-900)" />
                                                    </PopoverItem>
                                                </div>
                                            ),
                                            onClick: () => navigate('/customer/' + customer.id + '/equipment/' + equipment.id),
                                        }
                                    })}
                                />
                            </div>
                        )}

                        {activeTab === Tabs.Activities && (
                            <div className="activityContent">
                                <div className={`activity-table ${partialUpdateModalVisible && 'onBox'}`}>
                                    <ActivityListTable
                                        columns={setActivityTableColumns()}
                                        isManufacturer
                                        highlightedRowId={partialUpdateModalVisible ? selectedActivtyIds.id : ''}
                                        selectedProductId={partialUpdateModalVisible ? selectedActivityProductId : ''}
                                        selectedEquipmentId={partialUpdateModalVisible ? selectedEquipmentId : ''}
                                        onChangeVisibility={onChangeVisibility}
                                        sortKey="dueDate"
                                        onHandleClick={(id, groupId, isPartial) => {
                                            if (!isPartial) {
                                                setUpdateModalVisible(true)
                                            } else {
                                                setPartialUpdateModalVisible(true)
                                            }
                                            setselectedActivtyIds({ id: id, groupId: groupId })
                                        }}
                                        onClickProductAndEquipment={(productId, equipmentId) => {
                                            setSelectedActivityProductId(productId)
                                            setSelectedEquipmentId(equipmentId)
                                        }}
                                        data={activityTableDataMapper(
                                            activities.length
                                                ? filteredActivitiesData
                                                      .filter((item: ActivityModel) => {
                                                          if (filterState.dueDate.id === ' ') {
                                                              return item
                                                          } else if (filterState.dueDate.text === 'Before Today') {
                                                              return filterState.dueDate.id >= dayjs(item.dueDate)
                                                          }
                                                          return filterState.dueDate.id.isSame(dayjs(item.dueDate), filterState.dueDate.key)
                                                      })
                                                      .filter((item: ActivityModel) => filterAssignees(item, showAllAsignees, selectedAssigneeIds))
                                                      .filter((item: ActivityModel) => (filterState.status.id === ' ' ? item : item.status === filterState.status.id))
                                                      .filter((item: ActivityModel) => !viewHistoricalOnes || (viewHistoricalOnes && item.status === 'Closed'))
                                                      .filter((item) => {
                                                          if (activeActivityTab === Tabs.Service) {
                                                              return item.type === ActivityTypes.Service
                                                          } else if (activeActivityTab === Tabs.ProductReplenishment) {
                                                              return item.type === ActivityTypes.ProductReplensh
                                                          } else if (activeActivityTab === Tabs.Activities) {
                                                              return item.type === ActivityTypes.Ticket
                                                          } else {
                                                              return true
                                                          }
                                                      })
                                                : []
                                        )}
                                    />
                                </div>
                                {selectedActivtyIds && partialUpdateModalVisible && (
                                    <div className="partial-update-box">
                                        <PartialUpdateBox
                                            id={selectedActivtyIds.id}
                                            visible={!!selectedActivtyIds && partialUpdateModalVisible}
                                            closeModal={async () => {
                                                setPartialUpdateModalVisible(false)
                                            }}
                                            onLoading={onLoadingCallback}
                                            onCompleted={onCompletedCallback}
                                            onSuccess={async (_action) => await getActivities()}
                                            onError={onErrorCallback}
                                            onViewMore={onClickViewMoreAtPartialUpdateModal}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        {activeTab === Tabs.Products && products && (
                            <div className="customer__product">
                                <div style={{ width: productPreviewVisible ? '68%' : '100%' }}>
                                    <ProductsTable
                                        setSearch={(e) => {
                                            if (searchKey === e) {
                                                setSearchKey('')
                                            } else {
                                                setSearchKey(e)
                                            }
                                        }}
                                        data={filteredProducts.map((child) => ({ ...child, price: child.price ? Number(child.price) : child.price }))}
                                        onProductPreview={(id: string) => {
                                            setSelectedProductId(id)
                                            setProductPreviewVisible(true)
                                        }}
                                        selectedProductId={selectedProductId}
                                        isProductPreviewShow={productPreviewVisible}
                                    />
                                </div>
                                {selectedProductId && productPreviewVisible && (
                                    <ProductPreview
                                        closeModal={() => {
                                            setProductPreviewVisible(false)
                                            setSelectedProductId('')
                                        }}
                                        selectedProductId={selectedProductId}
                                        onCompleted={onCompletedCallback}
                                        onLoading={onLoadingCallback}
                                        onError={onErrorCallback}
                                        onSuccess={async () => await getCustomerCallback()}
                                    />
                                )}
                            </div>
                        )}

                        {activeTab === Tabs.Contracts && products && (
                            <ContractsTable
                                updateVisible={(contractId, visible) => {
                                    const contract = contracts.find((x) => x.id === contractId)

                                    if (!contract) return

                                    setLoading(true)

                                    const updatedContract = {
                                        ...contract,
                                        isVisibleToCustomer: visible,
                                    }
                                    delete updatedContract.documents
                                    ContractService.update(contractId, updatedContract)
                                        .then(() => {
                                            setContracts([...contracts.filter((x) => x.id !== contractId), updatedContract])
                                            toast.success('Updated successfully!')
                                        })
                                        .finally(() => {
                                            setLoading(false)
                                        })
                                }}
                                createAction={() => {
                                    setCreateContractModalVisible(true)
                                }}
                                setSearch={(e) => {
                                    if (searchKey === e) {
                                        setSearchKey('')
                                    } else {
                                        setSearchKey(e)
                                    }
                                }}
                                data={filteredContractsData}
                            />
                        )}

                        {!loading && activeTab === Tabs.Orders && orders && <OrderListTable data={filteredOrders} />}
                        {!loading && activeTab === Tabs.Customer && <CustomerDetailCard data={customer} handleUpdateCompany={() => setCompanyModalVisible(true)} onSuccess={onSuccessCallback} />}

                        {selectedActivtyIds && updateModalVisible && (
                            <UpdateActivityPopupSidebar
                                id={selectedActivtyIds.id}
                                groupId={selectedActivtyIds.groupId}
                                onChangeId={(id) => setselectedActivtyIds({ ...selectedActivtyIds, id: id })}
                                visible={updateModalVisible}
                                onClose={async () => {
                                    setUpdateModalVisible(false)
                                    await getActivities()
                                }}
                                onLoading={onLoadingCallback}
                                onCompleted={onCompletedCallback}
                                onSuccess={async () => await getActivities()}
                                onError={onErrorCallback}
                            />
                        )}
                        {createActivityModalVisible && (
                            <AddActivityPopupSidebar
                                customerId={customer.id}
                                onLoading={onLoadingCallback}
                                onSuccess={async () => await getActivities()}
                                onError={onErrorCallback}
                                onCompleted={onCompletedCallback}
                                visible={createActivityModalVisible}
                                onClose={() => setCreateActivityModalVisible(false)}
                            />
                        )}
                        {companyModalVisible && (
                            <CompanyModal
                                onSuccess={onSuccessCallback}
                                onLoading={() => setLoading(true)}
                                onCompleted={() => setLoading(false)}
                                company={customer}
                                visible={companyModalVisible}
                                closeModal={() => {
                                    setCompanyModalVisible(false)
                                    companyRef.current = undefined
                                }}
                            />
                        )}
                        {updateAddressModalVisible && (
                            <UpdateAddressModal
                                onSuccess={onSuccessCallback}
                                onLoading={() => setLoading(true)}
                                onCompleted={() => setLoading(false)}
                                company={customer}
                                visible={updateAddressModalVisible}
                                closeModal={() => setUpdateAddressModalVisible(false)}
                            />
                        )}
                        {createContractModalVisible && (
                            <CreateContractModal
                                onError={onErrorCallback}
                                customer={customer}
                                onSuccess={onSuccessCallback}
                                onLoading={() => setLoading(true)}
                                onCompleted={() => setLoading(false)}
                                visible={createContractModalVisible}
                                closeModal={() => setCreateContractModalVisible(false)}
                            />
                        )}
                        {equipments && (
                            <LinkEquipmentsModal
                                equipments={equipmentList!}
                                selectedEquipments={[]}
                                visible={linkEquipmentsModalVisible}
                                closeModal={() => setLinkEquipmentsModalVisible(false)}
                                onSave={linkEquipmentsHandler}
                            />
                        )}
                        {contactModalVisible && (
                            <ContactModal
                                visible={contactModalVisible}
                                closeModal={() => setContactModalVisible(false)}
                                onLoading={onLoadingCallback}
                                onCompleted={onCompletedCallback}
                                onSuccess={onSuccessCallback}
                                isAddPrimaryContact={true}
                            />
                        )}
                    </ContentBox>
                )}
            </TechnicianView>
        </PageWrapper>
    )
}

export default CustomerDetail
