import React, { useEffect, useState } from 'react'
import BasicDropdown, { IDropdownItem } from '../../../../../../components/Dropdown/BasicDropdown'
import AdressBox from '../../../../../../components/AdressBox'
import './style.scss'
import AddressService from '../../../../../../services/UiService/Address'
import { toast } from 'react-toastify'
import { AddressModel } from '../../../../../../models/Ui/Address'
import { IAddress } from '../../../../../../store/CustomerCheckoutContext/models'
import TextArea from '../../../../../../components/TextArea'

interface Props {
    currentAddress: IAddress
    handleAddress: Function
    userId: string
    onLoading: () => void
    onCompleted: () => void
}

function ShippingAddress(props: React.PropsWithChildren<Props>) {
    const [selectedAddress, setSelectedAddress] = useState({ id: '', text: '' })
    const [companyAddresses, setCompanyAddresses] = useState<AddressModel[]>([])
    const [shippingItems, setShippingItems] = useState<IDropdownItem[]>([
        {
            text: 'Add new',
            id: 'addNew',
        },
    ])

    const getAddress = async () => {
        const addressService = new AddressService()
        try {
            props.onLoading()
            const response = await addressService.getAll()
            const concatData = shippingItems.concat(
                response.map((address) => {
                    return { text: address.label, id: address.id }
                })
            )
            setShippingItems(concatData)
            setCompanyAddresses(response)
        } catch (error) {
            toast.error(error)
        } finally {
            props.onCompleted()
        }
    }

    useEffect(() => {
        getAddress()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddress = async (data: IDropdownItem) => {
        setSelectedAddress(data)
        if (data.id !== 'addNew') {
            const currentSelectAddress = companyAddresses.find((address) => address.id === data.id)
            if (currentSelectAddress) {
                props.handleAddress({
                    site_address1: currentSelectAddress?.street1,
                    site_address2: currentSelectAddress?.street2,
                    city: currentSelectAddress?.city,
                    state: currentSelectAddress?.state,
                    postal_code: currentSelectAddress?.postcode,
                    country: currentSelectAddress?.country,
                    note: '',
                    contactInformation: '',
                })
            }
        } else {
            props.handleAddress({
                site_address1: '',
                site_address2: '',
                city: '',
                state: '',
                postal_code: '',
                country: '',
                note: '',
                contactInformation: '',
            })
        }
    }

    return (
        <div className="shipping-form-container">
            <BasicDropdown classname="address-dropdown" label="SELECT ADDRESS OR ADD NEW" menuItems={shippingItems} onChange={handleAddress} selectedItem={selectedAddress} />
            {selectedAddress.text && (
                <>
                    <AdressBox {...props} currentAddress={props.currentAddress as any} className="customer-checkout-shipping" />
                    <div className="form-container-line">
                        <TextArea
                            autoSize
                            value={props.currentAddress.contactInformation}
                            placeholder="Contact Information"
                            onChange={(e) => props.handleAddress({ contactInformation: e.target.value })}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default ShippingAddress
