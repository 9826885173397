import { PropsWithChildren, ReactNode } from 'react'
import { IRowItem } from './row.interface'
import './row.scss'

export const Row = (props: PropsWithChildren<ReactNode>) => {
    return <div className="row">{props.children}</div>
}
export const RowItem = (props: IRowItem) => {
    return (
        <div className={`row-item ${props.classname}`}>
            <span className="row-item__label">{props.label}</span>
            <span className="row-item__value">{props.value ?? '-'}</span>
        </div>
    )
}
