import React, { FC } from 'react'
import './MappingCircleSettings.scss'
import Modal from '../../../Modal'
import { mappingCircleColors, mappingCircleSizes } from '../../ImageMapping.config'
import { MappingCircleSettingsProps } from './MappingCircleSettings.interface'
import MappingCircle from '../MappingCircle'
import { EMappingCircleSize } from '../MappingCircle/MappingCircle.interface'

const MappingCircleSettings: FC<MappingCircleSettingsProps> = ({ visible, onClose, mappingCircle, setMappingCircle }) => {
    return (
        <Modal visible={visible} title="Hotspot Settings" onClickClose={onClose}>
            <div className="mapping-ui">
                <div className="row">
                    <span>Hotspot color:</span>
                    <div className="mapping-circle-list">
                        {mappingCircleColors.map((mappingCircleColor) => (
                            <MappingCircle
                                active={mappingCircleColor?.background === mappingCircle?.background}
                                background={mappingCircleColor?.background}
                                color={mappingCircleColor?.color}
                                onClick={() => setMappingCircle({ ...mappingCircle, background: mappingCircleColor.background })}
                            />
                        ))}
                    </div>
                </div>
                <div className="row">
                    <span>Size:</span>
                    <div className="mapping-circle-list">
                        {mappingCircleSizes.map((mappingCircleSize) => (
                            <MappingCircle
                                active={mappingCircleSize?.size === mappingCircle?.size}
                                background={mappingCircleSize?.background}
                                color={mappingCircleSize?.color}
                                size={mappingCircleSize?.size}
                                onClick={() => setMappingCircle({ ...mappingCircle, size: mappingCircleSize.size ? mappingCircleSize.size : EMappingCircleSize.LARGE })}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default MappingCircleSettings
