import React, { useEffect, useState } from 'react'
import CurrencyInputOnly from 'react-currency-input-field'
import './CurrencyInput.scss'

interface IProps {
    id?: string
    placeholder?: string
    required?: boolean
    value: number | undefined
    onChange: (price: number) => void
    prefix?: string
    disabledPrefix?: boolean
    className?: string
    allowNegativeValue?: boolean
    max?: string | number | undefined
    min?: string | number | undefined
}

const CurrencyInput = (props: IProps) => {
    const [currenctValue, setCurrenctValue] = useState<string | undefined>('')
    const { placeholder, value, required, onChange, prefix = '$', disabledPrefix, className, allowNegativeValue = false, max, min } = props

    useEffect(() => {
        if (Number(value) && value) {
            setCurrenctValue(value.toString())
        }
    }, [value])

    const validateValue = (value: string | undefined): void => {
        const rawValue = value === undefined ? '0' : value
        setCurrenctValue(rawValue)

        if (Number(rawValue) || Number(rawValue) === 0) {
            onChange(Number(rawValue))
        }
    }

    return (
        <div className={`currency-input-wrapper ${className}`}>
            <CurrencyInputOnly
                groupSeparator=","
                decimalSeparator="."
                allowDecimals
                allowNegativeValue={allowNegativeValue}
                value={currenctValue}
                onValueChange={validateValue}
                prefix={disabledPrefix ? undefined : prefix}
                max={max}
                min={min}
            />
            {placeholder && (
                <span className={`label ${value?.toString() ? 'has-value' : ''}`}>
                    {placeholder}
                    {required && <strong className="required"> *</strong>}
                </span>
            )}
        </div>
    )
}

export default CurrencyInput
