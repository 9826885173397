import { useContext, useState } from 'react'
import Button from '../../../../../components/Button'
import ShippingMethod from './ShippingMethod'
import './OrderRequirements.scss'
import { CustomerContactModel } from '../../../../../models/New/Customer'
import OrderService from './../../../../../services/UiService/Order'
import AuthService from '../../../../../services/UiService/Auth'
import { CreateOrderOrQuoteInput } from '../../../../../models/Ui/Order'
import { CartActionTypes } from '../..'
import { CustomerCheckoutContext } from '../../../../../store/CustomerCheckoutContext/CustomerCheckoutContext'
import { CheckoutType } from '../../../../../store/CustomerCheckoutContext/models'
import ShippingAddress from './ShippingAddress'
import Icons, { IconType } from '../../../../../Icon'
import EmailRecipients from './EmailRecipients'

interface IProps {
    contacts: CustomerContactModel[] | undefined
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (type: CartActionTypes) => void
    onError: (error: any) => void
}

function OrderRequirements(props: IProps) {
    const [isRequirementsShow, setIsRequirementsShow] = useState({
        shippingAddress: false,
        shippingMethod: false,
        emailRecipients: false,
    })
    const {
        createCheckoutPayload,
        shippingAddress,
        handleShippingAddress,
        shippingMethod,
        handleShippingMethod,
        checkoutType,
        handleCheckoutType,
        reset,
        contextCustomParts,
        contextEquipments,
        contextProducts,
        contextCustomServices,
    } = useContext(CustomerCheckoutContext)
    const auth = new AuthService().getAuthUser()

    const handlePurchaseSubmit = async () => {
        try {
            props.onLoading()
            await OrderService.createPurchaseRequest(createCheckoutPayload(auth.id, CheckoutType.Purchase) as CreateOrderOrQuoteInput)
            await reset()
            props.onSuccess(CartActionTypes.PURCHASE)
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }
    const handleSendQuote = async () => {
        const quotePayload = createCheckoutPayload(auth.id, CheckoutType.RFQ) as CreateOrderOrQuoteInput
        // if (!quotePayload.recipientEmails || quotePayload.recipientEmails.length === 0) {
        //     toast.warning('Add at least one recipient to send this quote.')
        // } else {
        try {
            props.onLoading()
            await OrderService.createRFQ(quotePayload)
            await reset()
            props.onSuccess(CartActionTypes.RFQ)
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
        }
        // }
    }
    const isValidFormPurchase = () => {
        return contextCustomParts?.length !== 0 || contextEquipments?.length !== 0 || contextProducts?.length !== 0 || contextCustomServices?.length !== 0
    }
    const isValidFormRFQ = () => {
        return contextCustomParts?.length !== 0 || contextEquipments?.length !== 0 || contextProducts?.length !== 0 || contextCustomServices?.length !== 0
    }
    return (
        <div className="customer-requirements-container">
            <div className="type-button-container">
                <Button onClick={() => handleCheckoutType(CheckoutType.RFQ)} className={`type-button  ${checkoutType === CheckoutType.RFQ && 'checked'}`}>
                    RFQ
                </Button>
                <Button onClick={() => handleCheckoutType(CheckoutType.Purchase)} className={`type-button  ${checkoutType === CheckoutType.Purchase && 'checked'}`}>
                    Purchase Request
                </Button>
            </div>

            <div className="requirements-body">
                <div className="requirement-container">
                    <div className="requirement-label" onClick={() => setIsRequirementsShow({ ...isRequirementsShow, shippingAddress: !isRequirementsShow.shippingAddress })}>
                        <span>Shipping Address</span>
                        <Icons type={!isRequirementsShow.shippingAddress ? IconType.Plus : IconType.Minus} />
                    </div>

                    {isRequirementsShow.shippingAddress && (
                        <ShippingAddress onLoading={props.onLoading} onCompleted={props.onCompleted} userId={auth.id} currentAddress={shippingAddress} handleAddress={handleShippingAddress} />
                    )}
                </div>
                <div className="requirement-container">
                    <div className="requirement-label" onClick={() => setIsRequirementsShow({ ...isRequirementsShow, shippingMethod: !isRequirementsShow.shippingMethod })}>
                        <span>Shipping Method</span>
                        <Icons type={!isRequirementsShow.shippingMethod ? IconType.Plus : IconType.Minus} />
                    </div>

                    {isRequirementsShow.shippingMethod && <ShippingMethod currentMethod={shippingMethod} handleMethod={handleShippingMethod} />}
                </div>
                <div className="requirement-container">
                    <div className="requirement-label" onClick={() => setIsRequirementsShow({ ...isRequirementsShow, emailRecipients: !isRequirementsShow.emailRecipients })}>
                        <span>Email Recipients</span>
                        <Icons type={!isRequirementsShow.emailRecipients ? IconType.Plus : IconType.Minus} />
                    </div>

                    {isRequirementsShow.emailRecipients && (
                        <EmailRecipients
                            disabledAddEmails={checkoutType === CheckoutType.RFQ}
                            onLoading={props.onLoading}
                            onCompleted={props.onCompleted}
                            companyId={auth.id}
                            contacts={props.contacts}
                        />
                    )}
                </div>
            </div>

            <div className="send-button">
                {checkoutType === CheckoutType.RFQ ? (
                    <Button disabled={!isValidFormRFQ()} onClick={() => handleSendQuote()}>
                        Send RFQ
                    </Button>
                ) : (
                    <Button disabled={!isValidFormPurchase()} onClick={() => handlePurchaseSubmit()}>
                        Send PR
                    </Button>
                )}
            </div>
        </div>
    )
}

export default OrderRequirements
