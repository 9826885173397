import { useEffect, useState } from 'react'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import Modal from '../../../../../../components/Modal'
import { CustomerSummaryModel } from '../../../../../../models/Ui/Customer'
import './style.scss'
import Checkbox from '../../../../../../components/Checkbox'
import { isEqual } from 'lodash'
import { CustomerLinkToCustomer } from '../../../../../../models/New/Equipment'
import Input from '../../../../../../components/Input'
import { toast } from 'react-toastify'
import SearchInput from '../../../../../../components/SearchInput'

interface Props {
    customers: CustomerSummaryModel[]
    selectedCustomers: string[]
    visible: boolean
    closeModal: () => void
    onSave: (param: CustomerLinkToCustomer[]) => void
}

function LinkCustomersModal(props: Props) {
    const { onSave, customers } = props
    const [tempCustomer, setTempCustomer] = useState(
        customers.map((x) => {
            return {
                id: x.id,
                name: x.name,
                email: x.email,
                additional_emails: x.additional_emails,
                checked: props.selectedCustomers.includes(x.id),
                installCount: 1,
            }
        })
    )
    const [filteredData, setFilteredData] = useState(
        customers.map((x) => {
            return {
                id: x.id,
                name: x.name,
                email: x.email,
                additional_emails: x.additional_emails,
                checked: props.selectedCustomers.includes(x.id),
                installCount: 1,
            }
        })
    )

    const onChangeCheckbox = (id: string) => {
        if (tempCustomer.find((x) => x.id === id)!.checked) {
            const indexOf = tempCustomer.findIndex((x) => x.id === id)
            tempCustomer[indexOf].checked = false
            setTempCustomer([...tempCustomer])
        } else {
            const indexOf = tempCustomer.findIndex((x) => x.id === id)
            tempCustomer[indexOf].checked = true
            setTempCustomer([...tempCustomer])
        }
    }

    const onInstallCountChange = (id: string, value: number) => {
        const indexOf = tempCustomer.findIndex((x) => x.id === id)
        tempCustomer[indexOf].installCount = value
        tempCustomer[indexOf].checked = true
        setTempCustomer([...tempCustomer])
    }

    const onSelectAll = () => {
        if (tempCustomer.filter((x) => x.checked).length === customers.length) {
            tempCustomer.forEach((x) => {
                x.checked = false
            })
            setTempCustomer([...tempCustomer])
        } else {
            tempCustomer.forEach((x) => {
                x.checked = true
            })
            setTempCustomer([...tempCustomer])
        }
    }

    const onClickSave = () => {
        const tempData = tempCustomer
            .filter((x) => x.checked)
            .map((p) => {
                return {
                    installCount: p.installCount,
                    customerId: p.id,
                } as CustomerLinkToCustomer
            })
        onSave(tempData)
        toast.success('Successfully linked')
        props.closeModal()
    }

    useEffect(() => {
        const data = customers.map((x) => {
            return {
                id: x.id,
                name: x.name,
                email: x.email,
                additional_emails: x.additional_emails,
                checked: props.selectedCustomers.includes(x.id),
                installCount: 1,
            }
        })
        setTempCustomer(data)
        setFilteredData(data)
    }, [props.selectedCustomers, customers])

    const handleSearchedData = (data: any[]) => {
        setFilteredData(data)
    }

    return (
        <Modal
            className="link-equipment-modal"
            visible={props.visible}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            onClickPrimary={() => onClickSave()}
            onClickSecondary={() => props.closeModal()}
            title={`Link customer(s) to equipment`}
            primaryDisabled={isEqual(
                tempCustomer.filter((x) => x.checked).map((x) => x.id),
                props.selectedCustomers
            )}
        >
            <SearchInput data={tempCustomer} handleSearch={handleSearchedData} searchKeys={['name']} setDefaultSearch={''} />
            <TableContainer className="pagination-table" component={Paper} style={{ maxHeight: '400px' }}>
                <Table className="link-equipment-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox onChange={() => onSelectAll()} checked={tempCustomer.filter((x) => x.checked).length === customers.length} />
                            </TableCell>
                            <TableCell> Clone Count </TableCell>
                            <TableCell> Name </TableCell>
                            <TableCell> Email </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ maxHeight: '450px' }}>
                        {filteredData.map((row, ri) => (
                            <TableRow key={ri} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>
                                    <Checkbox onChange={() => onChangeCheckbox(row.id)} checked={row.checked} />
                                </TableCell>
                                <TableCell style={{ minWidth: '80px' }}>
                                    <div className="modal-line">
                                        <Input
                                            type="number"
                                            placeholder="Count"
                                            value={row.installCount}
                                            onChange={(e) => {
                                                onInstallCountChange(row.id, e.target.valueAsNumber)
                                            }}
                                            min={1}
                                            onBlur={(e) => (!e.target.valueAsNumber || e.target.valueAsNumber === 0) && onInstallCountChange(row.id, 1)}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Modal>
    )
}

export default LinkCustomersModal
