import React, { FC } from 'react'
import { EMappingCircleColor, EMappingCircleSize, MappingCircleColor, MappingCircleSize } from './MappingCircle.interface'
import './MappingCircle.scss'

const MappingCircle: FC<MappingCircleColor & MappingCircleSize> = ({ active, background, color, size = EMappingCircleSize.LARGE, onClick }) => {
    return (
        <div className={`mapping-circle ${active ? 'active' : ''}`} onClick={onClick} style={{ background: background === EMappingCircleColor.WHITE ? '#999999' : '' }}>
            <div
                className="circle"
                style={{
                    backgroundColor: background,
                    color: background === EMappingCircleColor.WHITE ? EMappingCircleColor.NAVY : color,
                    width: `${size}rem`,
                    height: `${size}rem`,
                    fontSize: `${size}rem`,
                }}
            ></div>
        </div>
    )
}

export default MappingCircle
