import './Warranty.scss'
import { standardDate } from '../../../utils/helpers'

interface Props {
    status: string
    endDate: string
}

function Warranty(props: Props) {
    return (
        <div className="warranty-title-wrapper">
            <span className="dot">
                <h4>·</h4>
            </span>
            <div className="warranty-info-container">
                <div className={`title ${props.endDate === null ? 'no-warranty-info' : props.status === 'Expired' ? 'expired' : 'not-expired'}`}>
                    <h5>{props.endDate === null ? '' : props.status === 'Expired' ? 'Warranty expired' : 'Covered by warranty'}</h5>
                </div>
                {props.endDate !== null && (
                    <div className="date">
                        <span style={{ color: '#354A63' }}>Exp.:</span> {standardDate(props.endDate)}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Warranty
