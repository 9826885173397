import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CurrencyInput from '../../../../../../components/CurrencyInput'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import { PartsModel } from '../../../../../../models/New/Equipment'
import { EquipmentActionTypes } from '../../../../../../models/Ui/Common'
import { UpdatePartModel } from '../../../../../../models/Ui/Part'
import PartService from '../../../../../../services/UiService/Part'
import TextArea from './../../../../../../components/TextArea/index'

interface Props {
    data: PartsModel
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: any) => void
    onError: (error: any) => void
}

function EditPartModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props
    const [number, setNumber] = useState<string>('')
    const [partName, setPartName] = useState<string>('')
    const [category, setCategory] = useState<string>('')
    const [brand, setBrand] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [quantity, setQuantity] = useState<string>('')
    const [pkg, setPkg] = useState<string>('')
    const [partNumber, setPartNumber] = useState<string>('')
    const [price, setPrice] = useState<number>()
    const [kitNo, setKitNo] = useState<string>('')

    useEffect(() => {
        setNumber(props.data.number)
        setPartName(props.data.name)
        setCategory(props.data.category)
        setBrand(props.data.brand)
        setDescription(props.data.description)
        setQuantity(props.data.quantity.toString())
        setPkg(props.data.pkg)
        setPartNumber(props.data.partNumber)
        setPrice(props.data.price)
        setKitNo(props.data.kitNo)
    }, [
        props.data.brand,
        props.data.category,
        props.data.description,
        props.data.kitNo,
        props.data.name,
        props.data.number,
        props.data.partNumber,
        props.data.pkg,
        props.data.price,
        props.data.quantity,
    ])

    async function createPartHandler() {
        const data: UpdatePartModel = {
            number: number,
            name: partName,
            category: category,
            brand: brand,
            description: description,
            quantity: quantity,
            pkg: pkg,
            partNumber: partNumber,
            price: price,
            kitNo: kitNo,
        }
        try {
            onLoading()
            const response = await PartService.update(props.data.id, data)
            onSuccess(EquipmentActionTypes.UPDATE)
            toast.success(response.message)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return (
            partNumber?.length > 2 &&
            partName.length > 2 &&
            (partNumber !== props.data.partNumber ||
                number !== props.data.number ||
                partName !== props.data.name ||
                category !== props.data.category ||
                brand !== props.data.brand ||
                description !== props.data.description ||
                quantity !== props.data.quantity.toString() ||
                pkg !== props.data.pkg ||
                price !== props.data.price ||
                kitNo !== props.data.kitNo)
        )
    }

    const clearForm = () => {
        // setPartNumber('')
        // setEquipmentName('')
        // setDescription('')
        // setWarranty({})
    }

    const clickPrimary = () => {
        createPartHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <>
            <Modal
                className="edit-equipment-modal"
                visible={props.visible}
                title="Edit Part"
                primaryButtonText="Save Changes"
                onClickPrimary={clickPrimary}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <div className="modal-line">
                    <Input value={number} onChange={(e) => setNumber(e.target.value)} placeholder="No." />
                </div>
                <div className="modal-line">
                    <Input value={partName} onChange={(e) => setPartName(e.target.value)} placeholder="Part name" required />
                </div>
                <div className="modal-line">
                    <Input value={category} onChange={(e) => setCategory(e.target.value)} placeholder="Product category" />
                    <Input value={brand} onChange={(e) => setBrand(e.target.value)} placeholder="Brand" />
                </div>
                <div className="modal-line">
                    <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" />
                </div>
                <div className="modal-line">
                    <Input value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="Qty. and unit" />
                    <Input value={pkg} onChange={(e) => setPkg(e.target.value)} placeholder="Packaging" />
                </div>
                <div className="modal-line">
                    <Input value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="Part Number" required />
                </div>
                <div className="modal-line">
                    <CurrencyInput
                        placeholder="Price"
                        onChange={(price) => {
                            setPrice(price)
                        }}
                        value={price}
                        min={0}
                    />
                    <Input value={kitNo} onChange={(e) => setKitNo(e.target.value)} placeholder="Kit No." />
                </div>
            </Modal>
        </>
    )
}

export default EditPartModal
