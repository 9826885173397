/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react'
import PageWrapper from '../../PageWrapper'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { arraySort, getFileType, UploadFileType } from '../../../utils/helpers'
import PdfViewer from '../../../components/PdfViewer'
import { DrawingType, EquipmentCartModel } from '../../../models/Ui/Equipment'
import { PartModel } from '../../../models/Db/Equipment'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import HierarchialTreeView from '../../../components/HierarchialTreeView'
import Tab from '../../../components/Tab'
import Icons, { IconType } from '../../../Icon'
import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import ZoomButton from '../../../components/ZoomButton'
import HoverCard from '../../../components/HoverCard'
import EquipmentDetailsTab from './EquipmentDetailsTab'
import './EquipmentDetail.scss'
import EquipmentService from '../../../services/UiService/Equipment'
import OrderService from '../../../services/UiService/Order'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import PartsTable from './PartsTable'
import DocumentListTable from './DocumentListTable'
import SearchInput from '../../../components/SearchInput'
import { EquipmentModel, PartsModel } from '../../../models/New/Equipment'
import { DocumentModel } from '../../../models/New/Document'
import { OrderModel } from '../../../models/New/Order'
import OrderListTable from '../../../components/OrderTableList'
import Warranty from '../../ManufacturerPages/Warranty'
import NoDataInfo from '../../../components/NoDataInfo'
import BasicDropdown from '../../../components/Dropdown/BasicDropdown'
import { CustomerCheckoutContext } from '../../../store/CustomerCheckoutContext/CustomerCheckoutContext'
import Button from '../../../components/Button'
import Icon from '../../../Icon'

const tabItems: TabItem[] = [
    {
        id: Tabs.Details,
        name: 'Details',
        customIcon: <Icons type={IconType.Info} />,
    },
    {
        id: Tabs.DrawingAndParts,
        name: 'Drawing and Parts',
        customIcon: <Icons type={IconType.Parts} />,
    },
    {
        id: Tabs.Documents,
        name: 'Documents',
        customIcon: <Icons type={IconType.Folder} />,
    },
    {
        id: Tabs.Orders,
        name: 'Orders',
        customIcon: <Icons type={IconType.OrderBag} />,
    },
]

function EquipmentDetail() {
    const checkoutContext = useContext(CustomerCheckoutContext)
    const [equipment, setEquipment] = useState<EquipmentModel | null>(null)
    const [rootEquipment, setRootEquipment] = useState<EquipmentModel | null>(null)
    const [loading, setLoading] = useState(true)
    const [drawingType, setDrawingType] = useState<DrawingType>(DrawingType.Image)
    const [activePartListIndex, setActivePartListIndex] = useState(0)
    const [activeTab, setActiveTab] = useState<number>(Tabs.DrawingAndParts)
    const [documentDetailVisible, setDocumentDetailVisible] = useState<boolean>(false)
    const imageContainer = useRef<HTMLDivElement>(null)
    const imageContainerDocument = useRef<HTMLDivElement>(null)
    const panzoom = useRef<PanzoomObject | null>(null)
    const panzoomDocument = useRef<PanzoomObject | null>(null)
    const [fileType, setFileType] = useState<UploadFileType | undefined>(UploadFileType.Image)
    const [equipmentDocument, setEquipmentDocument] = useState<DocumentModel>()
    const [orders, setOrders] = useState<OrderModel[]>([])
    const [filteredData, setFilteredData] = useState<PartsModel[]>([])
    const navigate = useNavigate()
    const [searchKey, setSearchKey] = useState<string>('')
    const [activePartList, setActivePartList] = useState({ id: '', text: '' })

    let [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (panzoom.current === null && imageContainer.current !== null) {
            panzoom.current = Panzoom(imageContainer.current, { startScale: '1' })
        } /* else not needed */

        if (documentDetailVisible && panzoomDocument.current === null && imageContainerDocument.current !== null) {
            panzoomDocument.current = Panzoom(imageContainerDocument.current, { startScale: '1' })
        } /* else not needed */
    })

    let { equipmentId, assemblyId } = useParams()

    useEffect(() => {
        setLoading(true)

        async function getOrders() {
            try {
                setLoading(true)
                const orders = await OrderService.getEquipmentOrders(equipmentId!)
                setOrders(arraySort(orders, 'creationDate', 'descending'))
            } catch (error: any) {
                console.error(error)
                toast.error(error)
            } finally {
                setLoading(false)
            }
        }

        const getData = async () => {
            try {
                const equipmentResponse = await EquipmentService.get(assemblyId ? assemblyId : equipmentId!)

                if (equipment?.drawingUrl && equipment?.drawingUrl.length > 0) {
                    setDrawingType(new URL(equipment!?.drawingUrl!).pathname.toLowerCase().endsWith('.pdf') ? DrawingType.Pdf : DrawingType.Image)
                } /* else not needed */

                setEquipment(equipmentResponse)
                setRootEquipment(equipmentResponse)
                setActivePartListIndex(0)
                await getOrders()
            } catch (e: any) {
                toast.error(e)
                console.error(e)
                navigate('/')
            } finally {
                setLoading(false)
            }
        }
        if (equipmentId) {
            getData()
        } else {
            toast.error('Invalid equipment id')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    useEffect(() => {
        const activeOne = searchParams?.get('activeTab')
        setActiveTab(Number(activeOne) || Tabs.DrawingAndParts)
    }, [])

    useEffect(() => {
        if (equipment) {
            setFilteredData(equipment.partLists[activePartListIndex]?.parts || [])
        }
    }, [activePartListIndex, equipment])

    const onAddToCart = (cart: PartsModel) => {
        if (equipment) {
            const eq: EquipmentCartModel = {
                id: equipment.id,
                name: equipment.name,
                partNumber: equipment.partNumber,
                drawing: equipment.drawingUrl ? equipment.drawingUrl : '',
                pdfPreview: equipment.drawingUrl ? equipment.drawingUrl : '',
                manufacturer: {
                    name: equipment.manufacturerName,
                    id: equipment.manufacturerId,
                    email: equipment.manufacturerName,
                },
                serialNumber: equipment.serialNo,
            }
            const newCartData: PartModel = {
                description: cart.description,
                index: cart.index,
                no: cart.number,
                serialNo: equipment.serialNo,
                part_no: cart.partNumber,
                name: cart.name,
                note: cart.notes,
                leadTime: cart.leadTime,
                pkg: cart.pkg,
                uom: cart.uom,
                qty: cart.quantity,
                category: cart.category,
                vendor: cart.brand,
                price: cart.price,
                adjustedPrice: cart.price,
                id: cart.id,
                attachments: [],
            }
            checkoutContext.addPart(eq, newCartData)
        } else {
            toast.error('Equipment info not found!')
        }
    }

    const breadcrumb: BreadcrumbModel[] =
        equipmentDocument && documentDetailVisible
            ? [
                  {
                      name: 'Equipment',
                      url: '/',
                  },
                  {
                      name: rootEquipment?.name!,
                      url: '/equipment/' + rootEquipment?.id,
                  },
                  {
                      name: activeTab === Tabs.DrawingAndParts ? 'Drawing and Parts' : activeTab === Tabs.Documents ? 'Documents' : activeTab === Tabs.Orders ? 'Orders' : 'Details',
                      url: '/equipment/' + rootEquipment?.id,
                  },
                  {
                      name: equipmentDocument.name,
                  },
              ]
            : [
                  {
                      name: 'Equipment',
                      url: '/',
                  },
                  {
                      name: rootEquipment?.name!,
                      url: '/equipment/' + rootEquipment?.id,
                  },
                  {
                      name: activeTab === Tabs.DrawingAndParts ? 'Drawing and Parts' : activeTab === Tabs.Documents ? 'Documents' : activeTab === Tabs.Orders ? 'Orders' : 'Details',
                  },
              ]

    function onClickPlusHandler() {
        panzoom.current?.zoomIn()
    }

    function onClickMinusHandler() {
        panzoom.current?.zoomOut()
    }

    function onClickResetHandler() {
        panzoom.current?.reset()
    }

    function onClickPlusDocumentHandler() {
        panzoomDocument.current?.zoomIn()
    }

    function onClickMinusDocumentHandler() {
        panzoomDocument.current?.zoomOut()
    }

    function onClickResetDocumentHandler() {
        panzoomDocument.current?.reset()
    }

    function onClickCloseDocumentHandler() {
        setDocumentDetailVisible(false)
        panzoomDocument.current = null
    }
    const handleSearchedData = (data: PartsModel[]) => {
        setFilteredData(data)
    }

    const getDescription = () => {
        if (equipment) {
            return <Warranty status={equipment.warrantyStatus} endDate={equipment.warrantyEnds} />
        }
    }

    return (
        <PageWrapper className="customer-equipment-detail-page" breadcrumb={breadcrumb} headerInfo={equipment?.manufacturerName}>
            {loading && <Loading />}
            <div className="product-content equipment-content">
                {rootEquipment && equipment && (
                    <ContentBox title={rootEquipment.name !== equipment.name ? rootEquipment.name + ' > ' + equipment.name : equipment.name} description={getDescription()}>
                        <div className="tab-container">
                            <HierarchialTreeView equipment={rootEquipment} nodeButtons={[]} linkRoot={'equipment'} />
                            <Tab
                                activeTab={activeTab}
                                tabs={tabItems}
                                onTabClick={(id) => {
                                    setSearchParams({ activeTab: id.toString() })
                                    setActiveTab(id)
                                    setDocumentDetailVisible(false)
                                    panzoom.current = null
                                    panzoomDocument.current = null
                                }}
                            ></Tab>
                        </div>
                        {activeTab === Tabs.Details && equipment && (
                            <div className="content-container">
                                <HoverCard title={equipment.name}>
                                    <EquipmentDetailsTab isEquipment={rootEquipment.id === equipment.id} equipment={equipment} />
                                </HoverCard>
                            </div>
                        )}
                        {activeTab === Tabs.DrawingAndParts && (
                            <>
                                {equipment.drawingUrl &&
                                    equipment.drawingUrl !== '' &&
                                    (drawingType === DrawingType.Pdf ? (
                                        <div className="yellow-wrapper drawing-parts">
                                            <PdfViewer className="pdf-viewer-sm" file={equipment.drawingUrl} fileName={equipment.name} type={'sm'} />
                                        </div>
                                    ) : (
                                        <div className="yellow-wrapper drawing-parts">
                                            <ZoomButton onClickPlus={onClickPlusHandler} onClickMinus={onClickMinusHandler} onClickReset={onClickResetHandler} />

                                            <div style={{ display: 'flex', justifyContent: 'center' }} ref={imageContainer}>
                                                <img src={equipment.drawingUrl} alt={equipment.name} />
                                            </div>
                                        </div>
                                    ))}
                                {equipment.partLists &&
                                    !loading &&
                                    (equipment.partLists.length > 0 ? (
                                        <>
                                            <div className="filter-wrapper">
                                                <div className="equipment-detail-filter-menu">
                                                    <BasicDropdown
                                                        label="TYPE"
                                                        menuItems={equipment.partLists.map((equipmentPartList, index) => ({
                                                            id: index,
                                                            text: equipmentPartList.name,
                                                        }))}
                                                        onChange={(data) => {
                                                            setActivePartListIndex(data.id)
                                                            setActivePartList(data)
                                                        }}
                                                        selectedItem={activePartList}
                                                    />
                                                </div>
                                                <SearchInput
                                                    data={equipment.partLists[activePartListIndex].parts}
                                                    handleSearch={handleSearchedData}
                                                    searchKeys={['part_no', 'no', 'description']}
                                                    setDefaultSearch={searchKey}
                                                />
                                            </div>
                                            <div className="list-wrapper">
                                                {equipment.partLists && equipment.partLists.length > 0 && equipment.partLists[activePartListIndex] && equipment.partLists[activePartListIndex].parts ? (
                                                    <PartsTable
                                                        data={filteredData.map((p, i) => {
                                                            return {
                                                                no: i,
                                                                name: p.name,
                                                                category: p.category,
                                                                brand: p.brand,
                                                                qty: p.quantity,
                                                                pkg: p.pkg,
                                                                partNo: p.partNumber,
                                                                price: p.price,
                                                                button: (
                                                                    <Button className="add-to-cart" onClick={() => onAddToCart(p)}>
                                                                        <Icon type={IconType.AddToCart} />
                                                                    </Button>
                                                                ),
                                                            }
                                                        })}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <NoDataInfo />
                                    ))}
                            </>
                        )}
                        {activeTab === Tabs.Documents &&
                            !loading &&
                            equipment &&
                            equipment.documents &&
                            (!documentDetailVisible ? (
                                <DocumentListTable
                                    setSearch={(e) => {
                                        if (searchKey === e) {
                                            setSearchKey('')
                                        } else {
                                            setSearchKey(e)
                                        }
                                    }}
                                    data={equipment.documents.map((d) => {
                                        return {
                                            name: d.name,
                                            fileType: d.type,
                                            onClick: () => {
                                                const fileType = getFileType(d.url)
                                                if (fileType === UploadFileType.Url) {
                                                    window.open(d.url)
                                                } else {
                                                    setEquipmentDocument(d)
                                                    setFileType(getFileType(d.url))
                                                    setDocumentDetailVisible(true)
                                                }
                                            },
                                        }
                                    })}
                                />
                            ) : (
                                equipmentDocument &&
                                (fileType === UploadFileType.Pdf ? (
                                    <div className="yellow-wrapper">
                                        <PdfViewer file={equipmentDocument.url} fileName={equipmentDocument.name} onClose={onClickCloseDocumentHandler} layout={'scroll'} />
                                    </div>
                                ) : fileType === UploadFileType.Image ? (
                                    <div className="yellow-wrapper">
                                        <ZoomButton onClickPlus={onClickPlusDocumentHandler} onClickMinus={onClickMinusDocumentHandler} onClickReset={onClickResetDocumentHandler} />

                                        <div style={{ display: 'flex' }} ref={imageContainerDocument}>
                                            <img src={equipmentDocument.url} alt={equipmentDocument.name} />
                                        </div>
                                    </div>
                                ) : fileType === UploadFileType.Video ? (
                                    <>video</>
                                ) : (
                                    <>not supported!</>
                                ))
                            ))}
                        {activeTab === Tabs.Orders && !loading && orders && <OrderListTable data={orders} />}
                    </ContentBox>
                )}
            </div>
        </PageWrapper>
    )
}

export default EquipmentDetail
