import { FC, Fragment, useRef } from 'react'
import './style.scss'

interface ImageWithModalProps {
    src: string
}

const ImageWithModal: FC<ImageWithModalProps> = ({ src }) => {
    const modalRef = useRef<HTMLDivElement>(null)

    const onClickImage = () => {
        if (modalRef.current) {
            modalRef.current.style.visibility = 'visible'
        }
    }

    const onClose = () => {
        if (modalRef.current) {
            modalRef.current.style.visibility = 'hidden'
        }
    }

    return (
        <Fragment>
            <img className="modalImage" src={src} alt="modalImage" onClick={onClickImage} />
            <div className="imageWithModal" ref={modalRef}>
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <img className="modal-content img01" src={src} alt="modalImage2" />
            </div>
        </Fragment>
    )
}

export default ImageWithModal
