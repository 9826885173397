import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import React, { ReactNode } from 'react'
import { IconType } from '../../../Icon'
import CircleIconButton from '../../CircleIconButton'
import PopoverItem from '../../PopoverItem'

export interface NodeParentModel {
    id: string
    text: string
}
export interface TreeviewNodeButton {
    icon: IconType
    popoverContent?: string
    onClick: (id: string, parentId: string) => void
}

interface Props {
    id?: string
    text: string
    expandIcon?: IconDefinition
    collapseIcon?: IconDefinition
    childNodes?: ReactNode[]
    deepLevel: number
    buttons?: TreeviewNodeButton[]
    parent?: any
    checked?: boolean
    isTemplate?: boolean
    onClick?: () => void
    onClickItem?: () => void
}

function TreeViewNode(props: React.PropsWithChildren<Props>) {
    return (
        <>
            <div className={`node-container ${props.checked && 'checked'}`} style={{ paddingLeft: (props.deepLevel - 1) * 36 + 16 }}>
                <>
                    <div
                        className={`left-border ${props.childNodes && props.childNodes.length > 0 ? 'half-height-bottom-border' : ''}`}
                        style={{
                            left: (props.deepLevel - 2) * 36 + 32,
                            visibility: props.deepLevel === 1 ? 'hidden' : 'unset',
                        }}
                    />
                    <div
                        className={'bottom-border'}
                        style={{
                            left: (props.deepLevel - 2) * 36 + 32,
                            visibility: props.deepLevel === 1 ? 'hidden' : 'unset',
                        }}
                    />
                </>
                <div className="node-left">
                    <div className="node-text" onClick={props.onClickItem}>
                        {props.text}
                    </div>
                </div>
                <div className="node-right" onClick={props.onClick}>
                    <div className="node-buttons">
                        {props.buttons &&
                            props.buttons.map((button, index) => {
                                return button.popoverContent ? (
                                    <PopoverItem key={index} popoverContent={button.popoverContent}>
                                        <CircleIconButton
                                            onClick={() => {
                                                button.onClick(props.id!, props.parent?.id)
                                            }}
                                            icon={button.icon}
                                        />
                                    </PopoverItem>
                                ) : (
                                    <CircleIconButton
                                        key={index}
                                        onClick={() => {
                                            button.onClick(props.id!, props.parent?.id)
                                        }}
                                        icon={button.icon}
                                    />
                                )
                            })}
                    </div>
                </div>
            </div>
            {props.childNodes?.length! > 0 && <div className="child-nodes">{props.childNodes}</div>}
        </>
    )
}

export default TreeViewNode
