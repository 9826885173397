import Modal from '../../../../../../components/Modal'
import EquipmentService from '../../../../../../services/UiService/Equipment'
import { toast } from 'react-toastify'

interface Props {
    type: 'assembly' | 'partList'
    isMoleaerUser: boolean
    id: string
    visible: boolean
    warningTitle?: string
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onError: (error: any) => void
    onSuccess?: () => Promise<void>
}

function PushEquipmentToCustomModal(props: Props) {
    const { onLoading, onError, onCompleted, onSuccess } = props

    async function onSubmit() {
        try {
            onLoading()
            if (props.isMoleaerUser) {
                await EquipmentService.pushTemplatesEverythingToCustomEquipment(props.id)
            } else {
                switch (props.type) {
                    case 'assembly':
                        await EquipmentService.pushTemplateToCustomEquipment(props.id)

                        break
                    case 'partList':
                        await EquipmentService.pushPartListsToCustomEquipments(props.id)

                        break
                    default:
                        break
                }
            }
            toast.success('Custom equipment updated!')
            onSuccess && onSuccess()
            props.closeModal()
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }

    const onSecondary = () => {
        props.closeModal()
    }

    return (
        <Modal visible={props.visible} title="Custom Equipment Update" primaryButtonText="Proceed" onClickPrimary={() => onSubmit()} secondaryButtonText="Cancel" onClickSecondary={onSecondary}>
            <div className="modal-line">
                PUSH TEMPLATE CHANGES TO ALL CUSTOM EQUIPMENT RECORDS <br />
                {props.warningTitle && props.warningTitle}
            </div>
        </Modal>
    )
}

export default PushEquipmentToCustomModal
