import { Checkbox } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import Icons, { IconType } from '../../../../../Icon'
import { ActivityTypes } from '../../../../../models/Ui/Activity'
import './style.scss'

interface Props {
    type: ActivityTypes
    onChange: (val: ActivityTypes) => void
    disabled?: boolean
    isBillable: boolean
    onChangeBillable: (value: boolean) => void
}

const ActivityPartialUpdateType: FC<Props> = ({ type, onChange, disabled, isBillable, onChangeBillable }) => {
    const [showOptions, setShowOptions] = useState(false)
    const dropdownContainerRef = useRef<HTMLDivElement>(null)

    const typeOptions = [
        {
            id: 1,
            text: ActivityTypes.Ticket,
            classname: 'ticket',
            iconType: IconType.Ticket,
        },
        {
            id: 2,
            text: ActivityTypes.Service,
            classname: 'service',
            iconType: IconType.Maintenance,
        },
        {
            id: 3,
            text: ActivityTypes.ProductReplensh,
            classname: 'product-replenishment',
            iconType: IconType.Replenishment,
        },
    ]

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
                setShowOptions(false)
            }
        }

        document.addEventListener('mousedown', (e) => handleClickOutside(e))
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const setClassname = (typeOption: ActivityTypes): string => {
        return typeOptions.find((child) => child.text === typeOption)?.classname ?? ''
    }

    const setIcon = (typeOption: ActivityTypes) => typeOptions.find((child) => child.text === typeOption)?.iconType

    return (
        <div className="activity-partial-type-container" ref={dropdownContainerRef}>
            <div
                className={`selected-one ${showOptions && 'focused-dropdown'} ${disabled ? 'disabled-dropdown' : 'enabled-dropdown'} ${setClassname(type)}`}
                onClick={() => {
                    if (!disabled) {
                        setShowOptions(!showOptions)
                    }
                }}
            >
                <span className="type-icon">
                    <Icons type={setIcon(type)!} />
                </span>
                <div className="selected-one-text">{type}</div>
                {!disabled && <Icons type={IconType.ChevronDown} />}
            </div>
            {showOptions && (
                <div className="type-options">
                    {typeOptions.map((child) => (
                        <div
                            key={child.id}
                            className="type-option"
                            onClick={() => {
                                onChange(child.text)
                                setShowOptions(false)
                            }}
                        >
                            <div className={`type-text ${setClassname(child.text)}`}>
                                <span className="type-icon">
                                    <Icons type={setIcon(child.text)!} />
                                </span>
                                {child.text}
                            </div>
                            {child.text === ActivityTypes.Service && type === ActivityTypes.Service && (
                                <div className="billable">
                                    <Checkbox
                                        onClick={(e) => e.stopPropagation()}
                                        checked={isBillable}
                                        onChange={() => {
                                            if (type === ActivityTypes.Service) {
                                                onChangeBillable(!isBillable)
                                            }
                                        }}
                                    />
                                    Billable
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ActivityPartialUpdateType
