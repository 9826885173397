import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import './Table.scss'
import { useNavigate } from 'react-router'
import NoDataInfo from '../NoDataInfo'

interface Props {
    titles: React.ReactNode[]
    data: any[]
    align?: ('left' | 'center' | 'right' | 'justify' | 'inherit')[]
    width?: string[]
    isClickable?: boolean
}

export default function MTable(props: Props) {
    const navigate = useNavigate()

    if (props.data.length === 0) return <NoDataInfo />

    return (
        <div className="table-wrapper">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {props.titles.map((p, i) => (
                                <TableCell align={props.align ? props.align[i] : undefined} key={i}>
                                    {p}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row, ri) => (
                            <TableRow
                                style={props.isClickable ? { cursor: 'pointer' } : undefined}
                                onClick={
                                    props.isClickable
                                        ? () => {
                                              navigate(row.link)
                                          }
                                        : undefined
                                }
                                key={ri}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {Object.values(row).map((d: any, i) => {
                                    if (props.titles.length === Object.keys(row).length - 1 && i === props.titles.length) {
                                        return undefined
                                    } else {
                                        return (
                                            <TableCell align={props.align ? props.align[i] : undefined} sx={{ width: props.width ? props.width[i] : undefined }} key={i}>
                                                {d}
                                            </TableCell>
                                        )
                                    }
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
