import { CSSProperties, FC } from 'react'
import './PopoverItem.scss'
import { Tooltip, TooltipProps } from '@mui/material'

interface Props {
    popoverContent: string
    disabled?: boolean
    placement?: TooltipProps['placement']
    style?: CSSProperties
}

const PopoverItem: FC<Props> = ({ disabled, children, popoverContent, placement = 'bottom', style = {} }) => {
    return disabled ? (
        <div className="popover-item" style={style}>
            {children}
        </div>
    ) : (
        <div className="popover-item" style={style}>
            <Tooltip title={popoverContent} placement={placement}>
                <div className="popover-item-selection">{children}</div>
            </Tooltip>
        </div>
    )
}

export default PopoverItem
