const host = window.location.host

export function getApiUrl() {
    switch (host) {
        case 'dev.monittor.com':
            return 'https://api-dev.monittor.com/'
        case 'monittorappdev.rapicapp.com':
            return 'https://api-dev.monittor.com/'
        case 'demo.monittor.com':
            return 'https://api-stage.monittor.com/'
        case 'app.monittor.com':
            return 'https://api.monittor.com/'
        default:
            return 'https://api-dev.monittor.com/'
    }
}
