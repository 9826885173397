import React from 'react'
import Input from '../../../../../../components/Input'
import TextArea from '../../../../../../components/TextArea'
import './ShippingMethod.scss'
import BasicDropdown from '../../../../../../components/Dropdown/BasicDropdown'
import { IShippingMethodModel } from '../../../../../../store/CustomerCheckoutContext/models'
import BasicDatePicker from '../../../../../../components/DatePicker'

interface Props {
    currentMethod: IShippingMethodModel
    handleMethod: Function
}

const shippingType = [
    {
        text: 'Expedited',
        id: 'expedited',
    },
    {
        text: 'Standard',
        id: 'standard',
    },
    {
        text: 'Economy',
        id: 'economy',
    },
]

function ShippingMethod(props: React.PropsWithChildren<Props>) {
    const { currentMethod, handleMethod } = props
    return (
        <div className="shipping-method-form-container">
            <div className="form-line">
                <Input value={currentMethod.carrier} placeholder="PREFERRED SHIPPING CARRIER" onChange={(e) => handleMethod({ carrier: e.target.value })} />
            </div>
            <div className="form-line" style={{ marginBottom: '10px' }}>
                <BasicDropdown
                    label="TYPE"
                    menuItems={shippingType}
                    onChange={(data) => {
                        handleMethod({ type: data.text })
                    }}
                    selectedItem={shippingType.find((item) => item.text === currentMethod?.type) || undefined}
                />
            </div>
            <div className="form-line">
                <BasicDatePicker key={'startDate'} startDate={currentMethod.date ? new Date(currentMethod.date) : null} placeholder="DATE NEEDED" onDateChange={(e) => handleMethod({ date: e })} />
            </div>

            <div className="form-line">
                <TextArea autoSize value={currentMethod.note} placeholder="ADD NOTE" onChange={(e) => handleMethod({ note: e.target.value })} />
            </div>
        </div>
    )
}

export default ShippingMethod
