import Modal from '../../../components/Modal'
import './MasterCatalog.scss'

interface Props {
  visible: boolean
  onApprove: () => void
  closeModal: () => void
}

function RemoveProductApprovalModal(props: Props) {
  const clickPrimary = () => {
    props.onApprove()
    props.closeModal()
  }

  const onSecondary = () => {
    props.closeModal()
  }

  return (
    <Modal
      className="remove-product-approval-modal"
      visible={props.visible}
      title="Delete Products"
      primaryButtonText="Delete Selected"
      onClickPrimary={clickPrimary}
      primaryDisabled={false}
      secondaryButtonText="Cancel"
      onClickSecondary={onSecondary}
    >
      The selected products will be removed. Are you sure you would like to
      proceed?
    </Modal>
  )
}

export default RemoveProductApprovalModal
