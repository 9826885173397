import React from 'react'
import { TeamActionTypes } from '../..'
import ChipButton from '../../../../components/ChipButton'
import CircleIconButton from '../../../../components/CircleIconButton'
import HoverCard from '../../../../components/HoverCard'
import Icons, { IconType } from '../../../../Icon'
import { AddressModel } from '../../../../models/Ui/Address'
import { CompanyModel, UpdateCompanyModel } from '../../../../models/Ui/Company'
import { CompanyType } from '../../../../models/Ui/CompanyType'
import CompanyService from '../../../../services/UiService/Company'
import './style.scss'

interface Props {
    company: CompanyModel
    addresses: AddressModel[]
    onClickDeleteAddress: (addressId: string) => void
    onClickUpdateAddress: (addressId: string) => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function AddressesTab(props: React.PropsWithChildren<Props>) {
    const { onLoading, onError, onSuccess, onCompleted } = props

    async function updateCompanyDetailsHandler(addressId: string, addressType: 'billing' | 'shipping') {
        try {
            onLoading()
            const companyService = new CompanyService()
            const data: UpdateCompanyModel =
                addressType === 'billing'
                    ? {
                          id: props.company.id,
                          billingAddressId: addressId,
                          email: props.company.email,
                      }
                    : {
                          id: props.company.id,
                          shippingAddressId: addressId,
                          email: props.company.email,
                      }

            const updateCompanyResponse = await companyService.update(data)
            onSuccess(TeamActionTypes.UPDATE_COMPANY_DETAILS, updateCompanyResponse)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }

    function buildAddressCard(key: number, address: AddressModel, isDefaultShippingAddress: boolean, isDefaultBillingAddress: boolean) {
        return (
            <HoverCard key={key}>
                <div className="addresses-tab-container">
                    <div className="section">
                        <Icons className="section-icon" type={IconType.Addresses} />
                        <div className="section-container">
                            <div className="title">{address.label.toUpperCase()}</div>
                            <div className="content">
                                {props.company.type === CompanyType.Customer && (
                                    <div className="content-row">
                                        {isDefaultShippingAddress ? (
                                            <ChipButton className="green address-button">Default Shipping Address</ChipButton>
                                        ) : (
                                            <ChipButton className="default address-button" onClick={() => updateCompanyDetailsHandler(address.id, 'shipping')}>
                                                Set as default shipping address
                                            </ChipButton>
                                        )}
                                        {isDefaultBillingAddress ? (
                                            <ChipButton className="green address-button">Default Billing Address</ChipButton>
                                        ) : (
                                            <ChipButton className="default address-button" onClick={() => updateCompanyDetailsHandler(address.id, 'billing')}>
                                                Set as default billing address
                                            </ChipButton>
                                        )}
                                    </div>
                                )}
                                <div className="content-line">
                                    <div className="content-line-item">
                                        <span className="title">STREET ADDRESS 1</span>
                                        <span className="value">{address.street1 || '-'}</span>
                                    </div>
                                </div>
                                <div className="content-line">
                                    <div className="content-line-item">
                                        <span className="title">STREET ADDRESS 2</span>
                                        <span className="value">{address.street2 || '-'}</span>
                                    </div>
                                </div>
                                <div className="content-line">
                                    <div className="content-line-item">
                                        <span className="title">CITY</span>
                                        <span className="value">{address.city || '-'}</span>
                                    </div>
                                    <div className="content-line-item">
                                        <span className="title">STATE</span>
                                        <span className="value">{address.state || '-'}</span>
                                    </div>
                                </div>
                                <div className="content-line">
                                    <div className="content-line-item">
                                        <span className="title">ZIP CODE</span>
                                        <span className="value">{address.postcode || '-'}</span>
                                    </div>
                                    <div className="content-line-item">
                                        <span className="title">COUNTRY</span>
                                        <span className="value">{address.country || '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <CircleIconButton icon={IconType.Pen} onClick={() => props.onClickUpdateAddress(address.id)} />
                        <CircleIconButton icon={IconType.NewTrash} onClick={() => props.onClickDeleteAddress(address.id)} />
                    </div>
                </div>
            </HoverCard>
        )
    }

    return (
        <div className="addresses-content-container">
            {props.addresses.map((address, index) => buildAddressCard(index, address, props.company.shippingAddress?.id === address.id, props.company.billingAddress?.id === address.id))}
        </div>
    )
}

export default AddressesTab
