import { ContractOutputModel } from '../Ui/Contract'

export interface DocumentModel {
    creationDate: string
    isDeleted: boolean
    id: string
    name: string
    type: string
    category: string
    url: string
    description: string
    isExternal: boolean
}

export interface DocumentListModel {
    id: string
    name: string
    type: string
    category: string
    url: string
    description: string
    isExternal: boolean
    companyId: string
    creationDate: string
    equipments: DocumentWithEquipmentsContentModel[]
    belongsTo?: DocumentBelongsTo
    contracts?: ContractOutputModel[]
}

export interface CreateDocumentModel {
    name: string
    type?: string
    category: string
    url?: string
    description?: string
    isExternal: boolean
    belongsTo?: DocumentBelongsTo
}

export interface UpdateDocumentModel {
    name: string
    url: string
    description?: string
    type?: string
}
export interface DeleteDocumentModel {
    documentId: string
}

export interface DocumentWithEquipmentsModel {
    id: string
    name: string
    type: string
    category: string
    url: string
    description: string
    isExternal: boolean
    companyId: string
    creationDate: string
    equipments: DocumentWithEquipmentsContentModel[]
}
export interface DocumentWithEquipmentsContentModel {
    id: string
    equipmentId: string
    documentId: string
    creationDate: string
}
export interface PushPullEquipmentDocumentModel {
    equipmentIds: string[]
}

export interface DocumentBasicModel {
    id?: string
    name: string
    url: string

    type: string
}
export enum DocumentBelongsTo {
    Equipment = 'equipment',
    Product = 'product',
    Order = 'order',
    Activity = 'activity',
    Contract = 'contract',
}
