import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { HealthCheckModel } from '../../models/Ui/HealthCheck'
class HealthCheckService {
    async get(): Promise<HealthCheckModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.HealthCheck, {})
            return response as any
        } catch (error) {
            throw new Error('Error getting healt check data' + error)
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HealthCheckService()
