import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SearchableDropdown from '../../../../../components/Dropdown/SearchableDropdown'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import TextArea from '../../../../../components/TextArea'
import { EquipmentModel, UpdateCustomEquipmentModel, UpdateTemplateFromAssemblyModel } from '../../../../../models/New/Equipment'
import { EquipmentActionTypes } from '../../../../../models/Ui/Common'
import EquipmentService from '../../../../../services/UiService/Equipment'
import './style.scss'
interface ParentModel {
    id: string
    text: string
}

interface Props {
    isRootEquipmentId: string
    parentId: string
    assemblyId: string
    isHubspotSupport: boolean
    equipment: EquipmentModel
    visible: boolean
    parentList: ParentModel[]
    isLinkedTemplate?: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes) => void
    onError: (error: any) => void
}

function EditCustomEquipmentModalAssembly(props: Props) {
    const { isRootEquipmentId, isLinkedTemplate, parentList, assemblyId, onLoading, onError, onSuccess, onCompleted } = props
    const [currentAssembly, setCurrentAssembly] = useState<EquipmentModel | null>(null)
    const [name, setName] = useState<string>('')
    const [serialNumber, setSerialNumber] = useState<string>('')
    const [partNumber, setPartNumber] = useState<string>('')
    const [parentId, setParentId] = useState<string | undefined>(undefined)
    const [description, setDescription] = useState<string | undefined>('')

    useEffect(() => {
        setCurrentAssembly(props.equipment)
        setName(props?.equipment?.name)
        setSerialNumber(props.equipment.serialNo)
        setPartNumber(props.equipment.partNumber)
        setDescription(props.equipment.description)
        setParentId(props.parentId)
    }, [props.equipment, props.parentId])

    const isValidForm = () => {
        return (
            (props.parentId !== parentId ||
                currentAssembly?.partNumber !== partNumber ||
                currentAssembly?.description !== description ||
                currentAssembly?.name !== name ||
                currentAssembly?.serialNo !== serialNumber) &&
            name.length > 3 &&
            parentId &&
            partNumber.length !== 0
        )
    }

    const clearForm = () => {
        setName('')
        setDescription('')
        setSerialNumber('')
    }

    async function updateCustomEquipment() {
        try {
            onLoading()
            if (!isLinkedTemplate) {
                const payload: UpdateCustomEquipmentModel = {
                    name: name,
                    partNumber: partNumber,
                    serialNo: serialNumber,
                    parentId: parentId ?? props.parentId,
                    description: description,
                }
                await EquipmentService.updateCustomEquipment(assemblyId, payload)
            } else if (props.parentId) {
                const payload: UpdateTemplateFromAssemblyModel = {
                    oldParentId: props.parentId,
                    newParentId: parentId!,
                    childId: currentAssembly?.id!,
                    topEquipmentId: isRootEquipmentId,
                }
                await EquipmentService.updateTemplateToAssembly(payload)
                toast.success('Updated successfully!')
            }
            onSuccess(EquipmentActionTypes.UPDATE_ASSEMBLY)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
            clearForm()
        }
    }
    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }
    return (
        <>
            <Modal
                className="edit-custom-assembly-modal"
                visible={props?.visible}
                title="Edit equipment details"
                primaryButtonText="Save Changes"
                onClickPrimary={updateCustomEquipment}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <div className="modal-line">
                    <Input disabled={isLinkedTemplate} value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="PART NUMBER" required />
                </div>
                <div className="modal-line">
                    <Input disabled={isLinkedTemplate} value={name} onChange={(e) => setName(e.target.value)} placeholder="EQUIPMENT NAME" required />
                </div>
                <div className="modal-line">
                    <Input disabled={isLinkedTemplate} value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} placeholder="SERIAL NUMBER" />
                </div>
                <div className="modal-line">
                    <SearchableDropdown
                        label="PARENT"
                        data={parentList
                            .filter((parent) => parent.id !== currentAssembly?.id)
                            .map((item) => {
                                return {
                                    id: item.id,
                                    label: item.text,
                                }
                            })}
                        onChange={(event, data) => {
                            setParentId(data?.id ?? undefined)
                        }}
                        selectedItem={{ id: parentId as string, label: parentList.find((item) => item.id === parentId)?.text || '' }}
                    />
                </div>
                <div className="modal-line">
                    <TextArea disabled={isLinkedTemplate} value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" />
                </div>
            </Modal>
        </>
    )
}

export default EditCustomEquipmentModalAssembly
