import {
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import Checkbox from '../../../../components/Checkbox';
import Loading from '../../../../components/Loading';
import Modal from '../../../../components/Modal';
import SearchInput from '../../../../components/SearchInput';
import { DocumentListModel } from '../../../../models/New/Document';
import { EquipmentModel } from '../../../../models/New/Equipment';
import NewDocumentService from '../../../../services/UiService/NewDocument';
import './LinkDocument.scss';

interface Props {
  equipment: EquipmentModel;
  selectedIds?: string[] | undefined;
  visible: boolean;
  close: () => void;
  onSave: (documentIds: string[]) => void;
}

function LinkDocumentModal(props: Props) {
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([] as DocumentListModel[]);
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentListModel[]>([]);
  const [selectedIds, setSelectedIds] = useState(props.selectedIds ? props.selectedIds : [] as string[]);

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const documentResponse = await NewDocumentService.getDocuments({ page: 0, size: 10000 });
      setDocuments(documentResponse.content);
      setLoading(false);
    };
    get();
  }, []);

  const onChangeCheckbox = (id: string) => {
    const index = selectedIds.indexOf(id);
    if (index > -1) {
      setSelectedIds(selectedIds.filter((p) => p !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const onSelectAll = () => {
    if (selectedIds.length === documents.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(documents.map((p) => p.id));
    }
  };

  useEffect(() => {
    setSelectedIds(props.selectedIds ? props.selectedIds : []);
  }, [props.selectedIds]);

  function onSearchDocumentHandler(data: any[]) {
    setFilteredDocuments(data as DocumentListModel[]);
  }
  if (loading) { return <Loading />; }
  return (
    <Modal
      className="link-equipment-modal"
      visible={props.visible}
      title={`Link documents to: ${props.equipment.name}`}
      subtitle={`All customers linked to ${props.equipment.name} will have access to view the linked document(s).`}
      onClickClose={props.close}
      primaryButtonText="Save"
      primaryDisabled={isEqual(selectedIds, props.selectedIds)}
      onClickSecondary={props.close}
      onClickPrimary={() => props.onSave(selectedIds)}
      secondaryButtonText="Cancel"
    >
      <SearchInput data={documents} handleSearch={onSearchDocumentHandler} searchKeys={['name', 'type', 'description', 'category', 'creationDate']} />
      <TableContainer className="pagination-table" component={Paper} style={{ maxHeight: '350px' }}>
        <Table className="link-equipment-table">
          <TableHead>
            <TableRow>
              <TableCell><Checkbox onChange={onSelectAll} checked={selectedIds.length === documents.length} /></TableCell>
              <TableCell> Name </TableCell>
              <TableCell> Description </TableCell>
              <TableCell> Type </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDocuments.map((row, ri) => (
              <TableRow className='link-equipment-table-row' key={ri} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: "10%" }}>
                  <Checkbox onChange={() => onChangeCheckbox(row.id)} checked={selectedIds.includes(row.id)} />
                </TableCell>
                <TableCell style={{ width: "40%" }}>{row.name}</TableCell>
                <TableCell style={{ width: "10%" }}>{row.type}</TableCell>
                <TableCell style={{ width: "20%" }} >{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Modal>
  );
}

export default LinkDocumentModal;
