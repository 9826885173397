import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { CustomerContactModel, UpdateCustomerContactModel, UpdatePrimaryContact } from './../../models/New/Customer'

class ContactService {
    async update(data: UpdateCustomerContactModel, id: string): Promise<CustomerContactModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateContact + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }
    async create(data: UpdateCustomerContactModel): Promise<CustomerContactModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateContact + data.companyId, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }
    async delete(id: string): Promise<CustomerContactModel> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteContact + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }
    async setIsPrimary(data: UpdatePrimaryContact, id: string): Promise<CustomerContactModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.SetIsPrimary + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async getCompanyContacts(id: string): Promise<CustomerContactModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetCompanyContacts + id, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContactService()
