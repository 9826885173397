import { RefObject, useEffect } from 'react'

export const ignoreDashClassname = 'ignore-dash'
export const centeredDashClassname = 'centered-dash'

export const useTableBodyEmptyCellSetter = (pageWrapperRef: RefObject<HTMLDivElement>) => {
    useEffect(() => {
        if (pageWrapperRef.current) {
            const emptyTableBodyCellPlaceholderSetter = () => {
                const tableDashClassname = 'table-dash'

                setTimeout(() => {
                    if (pageWrapperRef.current) {
                        let allTableBodyEmptyCells = [...(pageWrapperRef.current.querySelectorAll('td.MuiTableCell-root') as unknown as any[])].filter(
                            (child) => !child.textContent.trim() && !child.children.length && !child.classList.contains(ignoreDashClassname)
                        )

                        if (allTableBodyEmptyCells) {
                            for (let index = 0; index < allTableBodyEmptyCells.length; index++) {
                                const tableCell = allTableBodyEmptyCells[index]
                                const noDataPlaceholder = document.createElement('div')
                                noDataPlaceholder.textContent = '_'
                                if (tableCell?.classList?.contains(centeredDashClassname)) {
                                    noDataPlaceholder.style.marginLeft = '48%'
                                }
                                noDataPlaceholder.classList.add(tableDashClassname)
                                noDataPlaceholder.style.fontWeight = 'bold'
                                tableCell.appendChild(noDataPlaceholder)
                            }
                        }

                        const dashes = document.getElementsByClassName(tableDashClassname)

                        for (let index = 0; index < dashes.length; index++) {
                            const dashElement = dashes[index]
                            if (dashElement.parentElement && dashElement.parentElement.children.length > 1) {
                                dashElement.remove()
                            }
                        }
                    }
                }, 300)
            }
            emptyTableBodyCellPlaceholderSetter()

            pageWrapperRef.current.onclick = emptyTableBodyCellPlaceholderSetter
        }
    })
}
