import React, { useContext, useState } from 'react'
import Icon from '../../../../Icon'
import Icons, { IconType } from '../../../../Icon'
import './Order.scss'
import { CustomPartModel, PartModel } from '../../../../models/Db/Equipment'
import { EquipmentCartModel } from '../../../../models/Ui/Equipment'
import { ProductModel } from '../../../../models/Ui/Product'
import { Color, MaxCharacter } from '../../../../models/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { formatQuantity, priceFormatter } from '../../../../utils/helpers'
import OrderListTable from './OrderListTable/OrderListTable'
import Modal from '../../../../components/Modal'
import { useNavigate, useParams } from 'react-router'
import AuthService from '../../../../services/UiService/Auth'
import FileInput from '../../../../components/FileInput'
import StorageService from '../../../../services/NewServices/StorageService'
import { toast } from 'react-toastify'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import Button, { ButtonType } from '../../../../components/Button'
import Input from '../../../../components/Input'
import DropdownWithSearch from '../../../../components/DropdownWithSearch'
import { CompanyManufacturerModel, CustomerDetailModel, CustomerEquipmentsModel } from '../../../../models/New/Customer'
import AddMoreButton from '../../../../components/AddMore'
import TextArea from '../../../../components/TextArea'
import { Tabs } from '../../../../models/Ui/Tab'
import { ConfirmationContext } from '../../../../store/ConfirmationContext'
import { AddImageModalVisibleModel } from '../../../../models/Common/AddImageModalVisibleModel'
import { CustomServiceModel } from '../../../../store/models'
import PopoverItem from '../../../../components/PopoverItem'
import BasicDropdown from '../../../../components/Dropdown/BasicDropdown'
import { Tooltip } from '@mui/material'
import RenameFilename from '../../../../components/RenameFilename'
import CurrencyInput from '../../../../components/CurrencyInput'

const additionalInput: {
    key: string
    label: string
    type: string
    width: string
    defaultValue?: number
    min?: number
    required?: boolean
}[] = [
    {
        key: 'part_no',
        label: 'PART NUMBER',
        width: '25%',
        type: 'text',
        required: true,
    },
    {
        key: 'name',
        label: 'PART NAME',
        width: '40%',
        type: 'text',
        required: true,
    },
    {
        key: 'qty',
        label: 'QTY.',
        width: '15%',
        type: 'number',
        defaultValue: 0,
        min: 1,
    },
    {
        key: 'price',
        label: 'PRICE',
        width: '15%',
        type: 'number',
        min: 0,
    },
    {
        key: 'equipment_name',
        label: 'EQUIPMENT',
        width: '25%',
        type: 'text',
    },
    {
        key: 'serial_no',
        label: 'SERIAL NUMBER',
        width: '25%',
        type: 'text',
    },
    {
        key: 'manufacturer',
        label: 'Manufacturer',
        width: '25%',
        type: 'text',
    },
]

const additionalServiceInput: {
    key: string
    label: string
    type: string
    width: string
    defaultValue?: number
    min?: number
    required?: boolean
}[] = [
    {
        key: 'name',
        label: 'TITLE',
        type: 'text',
        width: '80%',
        required: true,
    },
    {
        key: 'price',
        label: 'PRICE',
        width: '20%',
        type: 'number',
    },
    {
        key: 'note',
        label: 'DESCRIPTION',
        width: '80%',
        type: 'textarea',
    },
]

const initialCustomPart = {
    id: '',
    manufacturer: {
        id: '',
        name: '',
    },
    part_no: '',
    qty: 1,
    name: '',
    note: '',
    price: 0,
    serial_no: '',
    equipment: '',
    attachments: [],
}

interface IProps {
    manufacturers: CompanyManufacturerModel[] | undefined
    onLoading: () => void
    onCompleted: () => void
    customer?: CustomerDetailModel
    customerId?: string
    customerEquipmentCount?: number
    customerEquipmentList?: CustomerEquipmentsModel[]
}

export interface ExpandItemInfoModel {
    visible: boolean
    itemId: string
}

interface AddCustomItemInfo {
    isService?: boolean
    visible: boolean
    isUpdate?: boolean
}

export default function Order(props: IProps) {
    const {
        contextEquipments,
        contextCustomParts,
        contextProducts,
        contextCustomServices,
        purchaseWithoutCustomer,
        removePart,
        addPart,
        setPartCount,
        removeProduct,
        setProductCount,
        addProduct,
        clearPart,
        clearProduct,
        addAdditionalItem,
        addAdditionalServiceItem,
        removeAdditionalItem,
        removeAdditionalServiceItem,
        setPartPrice,
        setProductPrice,
        setPartNote,
        setPartAttachment,
        setProductNote,
        setProductAttachment,
        setCustomPartAttachment,
        setCustomServiceAttachment,
        setAdditionalItemPrice,
        setAdditionalItemCount,
        setAdditionalItem,
        setAdditionalService,
        removeCustomPart,
        addCustomPart,
        removeCustomService,
        addCustomService,
        setAdditionalServiceItemCount,
        setAdditionalServiceItemPrice,
        getTotalPrice,
    } = useContext(CheckoutContext)
    const { confirm } = useContext(ConfirmationContext)

    const navigate = useNavigate()

    const [customItemInputInfo, setCustomItemInputInfo] = React.useState<AddCustomItemInfo>({ isService: false, visible: false })
    const [additionalPart, setAdditionalPart] = React.useState<CustomPartModel>(initialCustomPart)
    const [latestActiveInput, setLatestActiveInput] = useState<string>()
    const [editNoteModalVisible, setEditNoteModalVisible] = React.useState(false)
    const [editModalInfo, setEditModalInfo] = React.useState<any>({})
    const [addImageModalInfo, setAddImageEditModalInfo] = React.useState<AddImageModalVisibleModel>({
        visible: false,
        type: '',
        name: '',
        attachments: [],
    })
    const [orderTableExpandItemInfo, setOrderTableExpandItemInfo] = React.useState<ExpandItemInfoModel>({
        visible: false,
        itemId: '',
    })
    // const [orderTableExpandItemInfo, setOrderTableExpandItemInfo] = React.useState<boolean>({
    //     visible: false,
    //     type: '',
    //     name: '',
    //     attachment: undefined,
    // })

    const auth = new AuthService().getAuthUser()
    let { id: customerId } = useParams()

    const mergeState = (state: any, newState: any) => {
        return {
            ...state,
            ...newState,
        }
    }

    const renderCounterPart = (part: PartModel, index: number, qty: number, equipment: EquipmentCartModel) => {
        return (
            <>
                <div className="counter">
                    <div onClick={() => removePart(equipment, part)} className="count-changer">
                        <Icon type={IconType.Minus} />
                    </div>
                    <input
                        onChange={(e) => setPartCount(equipment, part, e.target.valueAsNumber)}
                        onBlur={() => {
                            if (!qty) {
                                setPartCount(equipment, part, 1)
                            }
                        }}
                        type="number"
                        min={1}
                        value={qty}
                    />
                    <div onClick={() => addPart(equipment, part)} className="count-changer">
                        <Icon type={IconType.Plus} />
                    </div>
                </div>
            </>
        )
    }
    const renderCounterCustomPart = (part: CustomPartModel, qty: number) => {
        return (
            <>
                <div className="counter">
                    <div onClick={() => removeCustomPart(part)} className="count-changer">
                        <Icon type={IconType.Minus} />
                    </div>
                    <input
                        onBlur={() => {
                            if (!qty) {
                                setAdditionalItemCount(part, 1)
                            }
                        }}
                        onChange={(e) => setAdditionalItemCount(part, qty)}
                        type="number"
                        min={1}
                        value={qty}
                    />
                    <div onClick={() => addCustomPart(part)} className="count-changer">
                        <Icon type={IconType.Plus} />
                    </div>
                </div>
            </>
        )
    }
    const renderCounterCustomService = (service: CustomServiceModel, qty: number) => {
        return (
            <>
                <div className="counter">
                    <div onClick={() => removeCustomService(service)} className="count-changer">
                        <Icon type={IconType.Minus} />
                    </div>
                    <input
                        onBlur={() => {
                            if (!qty) {
                                setAdditionalServiceItemCount(service, 1)
                            }
                        }}
                        onChange={(e) => setAdditionalServiceItemCount(service, qty)}
                        type="number"
                        min={1}
                        value={qty}
                    />
                    <div onClick={() => addCustomService(service)} className="count-changer">
                        <Icon type={IconType.Plus} />
                    </div>
                </div>
            </>
        )
    }

    const renderCounterProduct = (product: ProductModel, index: number, qty: number) => {
        return (
            <>
                <div className="counter">
                    <div onClick={() => removeProduct(product)} className="count-changer">
                        <Icon type={IconType.Minus} />
                    </div>
                    <input
                        onBlur={() => {
                            if (!qty) {
                                setProductCount(product, 1)
                            }
                        }}
                        onChange={(e) => setProductCount(product, e.target.valueAsNumber)}
                        type="number"
                        min={1}
                        value={qty}
                    />
                    <div onClick={() => addProduct(product)} className="count-changer">
                        <Icon type={IconType.Plus} />
                    </div>
                </div>
            </>
        )
    }
    const renderPartPrice = (price: number, id: string, equipment: EquipmentCartModel, part: PartModel, warrantyDate?: string) => {
        return props.customerId || purchaseWithoutCustomer ? (
            <div className="price">
                <input
                    id={id}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    onChange={(e) => setPartPrice(equipment, part, e.target.valueAsNumber || 0)}
                    onFocus={() => {
                        setLatestActiveInput(id)
                    }}
                    onBlur={() => {
                        setLatestActiveInput(undefined)
                    }}
                    value={id === latestActiveInput ? price : priceFormatter(price)}
                    type={id === latestActiveInput ? 'number' : 'string'}
                />
                {warrantyDate && new Date(warrantyDate).getTime() > new Date().getTime() && (
                    <div className="price-changer">
                        <Icon type={IconType.Warranty} />
                    </div>
                )}
            </div>
        ) : (
            <div>{priceFormatter(price)}</div>
        )
    }
    const renderProductPrice = (price: number, id: string, product: ProductModel, warrantyDate?: string) => {
        return props.customerId || purchaseWithoutCustomer ? (
            <div className="price">
                <input
                    id={id}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    onChange={(e) => {
                        setProductPrice(product, e.target.valueAsNumber || 0)
                    }}
                    onFocus={() => setLatestActiveInput(id)}
                    onBlur={() => setLatestActiveInput(undefined)}
                    value={id === latestActiveInput ? price : priceFormatter(price)}
                    type={id === latestActiveInput ? 'number' : 'string'}
                />
                {warrantyDate && new Date(warrantyDate).getTime() > new Date().getTime() && (
                    <div className="price-changer">
                        <Icon type={IconType.Warranty} />
                    </div>
                )}
            </div>
        ) : (
            <div>{priceFormatter(price)}</div>
        )
    }

    const renderCustomPartPrice = (price: number | '-', id: string, customPart: CustomPartModel) => {
        return props.customerId || purchaseWithoutCustomer ? (
            <div className="price">
                <input
                    id={id}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    onChange={(e) => {
                        setAdditionalItemPrice(customPart, e.target.valueAsNumber || 0)
                    }}
                    onFocus={() => setLatestActiveInput(id)}
                    onBlur={() => setLatestActiveInput(undefined)}
                    value={id === latestActiveInput ? price : priceFormatter(price)}
                    type={id === latestActiveInput ? 'number' : 'string'}
                />
            </div>
        ) : (
            <div>{priceFormatter(price)}</div>
        )
    }
    const renderCustomServicePrice = (price: number | '-', id: string, customService: CustomServiceModel) => {
        return props.customerId || purchaseWithoutCustomer ? (
            <div className="price">
                <input
                    id={id}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                    onChange={(e) => {
                        setAdditionalServiceItemPrice(customService, e.target.valueAsNumber || 0)
                    }}
                    onFocus={() => setLatestActiveInput(id)}
                    onBlur={() => setLatestActiveInput(undefined)}
                    value={id === latestActiveInput ? price : priceFormatter(price)}
                    type={id === latestActiveInput ? 'number' : 'string'}
                />
            </div>
        ) : (
            <div>{priceFormatter(price)}</div>
        )
    }

    const renderNoteIcon = (empty: boolean, expandVisible: boolean) => {
        return (
            <PopoverItem popoverContent={expandVisible ? 'Hide notes' : empty ? 'View notes' : 'Add note'}>
                <Icon empty={empty} type={IconType.WriteNote} />
            </PopoverItem>
        )
    }

    const getSummaryData = () => {
        let currentCart: {
            expandItemId?: string
            equipmentName?: string
            serialNumber?: string
            name: string
            category?: string
            vendor?: string
            partNo: string
            note?: string
            uom?: string
            pkg?: string
            leadTime?: string
            price: JSX.Element | undefined
            action: JSX.Element
            actions: React.ElementRef<any>
            expandItem: JSX.Element
        }[] = []
        contextEquipments.forEach((e) => {
            e.parts.forEach((p, i) => {
                currentCart.push({
                    expandItemId: p.part.id,
                    name: p.part.name,
                    category: p.part.category,
                    vendor: p.part.vendor,
                    partNo: p.part.part_no,
                    note: p.part.note,
                    uom: p.part.uom,
                    pkg: p.part.pkg,
                    leadTime: p.part.leadTime,
                    equipmentName: e.equipment.name,
                    serialNumber: p.part.serialNo,
                    action: renderCounterPart(p.part, i, p.count, e.equipment),
                    price: renderPartPrice(p.part.adjustedPrice, p.part.id, e.equipment, p.part, p.part.warrantyEnds),
                    expandItem: <Input value={p.part.note} onChange={(event) => setPartNote(e.equipment, p.part, event.target.value)} placeholder="Add note" />,
                    actions: (
                        <>
                            <div className="buttons">
                                <div
                                    onClick={() => {
                                        setOrderTableExpandItemInfo({
                                            visible: orderTableExpandItemInfo.itemId === p.part.id ? !orderTableExpandItemInfo.visible : true,
                                            itemId: p.part.id,
                                        })
                                    }}
                                    className="action-button"
                                >
                                    {renderNoteIcon(!!p.part.note, orderTableExpandItemInfo.itemId === p.part.id)}
                                </div>
                                <div
                                    onClick={() => {
                                        setAddImageEditModalInfo({
                                            visible: true,
                                            type: 'part',
                                            name: p.part.name,
                                            equipment: e.equipment,
                                            part: p.part,
                                            attachments: p.part.attachments ?? [],
                                        })
                                    }}
                                    className="action-button"
                                >
                                    <PopoverItem popoverContent="Upload Images">
                                        <Icon empty={p.part.attachments && p.part.attachments.length > 0} type={IconType.AddImage} />
                                    </PopoverItem>
                                </div>
                                <div
                                    onClick={() =>
                                        confirm({
                                            modalTitle: 'Delete part',
                                            func: async (successCallback, errCallback) => {
                                                try {
                                                    props.onLoading()
                                                    await clearPart(e.equipment, p.part)
                                                    successCallback()
                                                } catch (error: any) {
                                                    errCallback(error)
                                                } finally {
                                                    props.onCompleted()
                                                }
                                            },
                                        })
                                    }
                                    className="action-button"
                                >
                                    <FontAwesomeIcon size="lg" color={Color.grey600} icon={faTrash} />
                                </div>
                            </div>
                        </>
                    ),
                })
            })
        })
        contextProducts.forEach((p, i) => {
            currentCart.push({
                expandItemId: p.product.id,
                name: p.product.name,
                category: p.product.category,
                vendor: p.product.vendor,
                partNo: p.product.part_no,
                note: p.product.note,
                uom: p.product.uom,
                pkg: p.product.pkg,
                leadTime: p.product.leadTime,
                action: renderCounterProduct(p.product, i, p.count),
                price: renderProductPrice(p.product.adjustedPrice, p.product.id, p.product),
                expandItem: <Input value={p.product.note} onChange={(e) => setProductNote(p.product, e.target.value)} placeholder="Add note" />,
                actions: (
                    <>
                        <div className="buttons">
                            <div
                                onClick={() => {
                                    setOrderTableExpandItemInfo({
                                        visible: orderTableExpandItemInfo.itemId === p.product.id ? !orderTableExpandItemInfo.visible : true,
                                        itemId: p.product.id,
                                    })
                                }}
                                className="action-button"
                            >
                                {renderNoteIcon(!!p.product.note, orderTableExpandItemInfo.itemId === p.product.id)}
                            </div>
                            <div
                                onClick={() => {
                                    setAddImageEditModalInfo({
                                        visible: true,
                                        type: 'product',
                                        name: p.product.name,
                                        product: p.product,
                                        attachments: p.product.attachments,
                                    })
                                }}
                                className="action-button"
                            >
                                <PopoverItem popoverContent="Upload Images">
                                    <Icon empty={p.product.attachments.length > 0} type={IconType.AddImage} />
                                </PopoverItem>
                            </div>
                            <div
                                onClick={() =>
                                    confirm({
                                        modalTitle: 'Delete product',
                                        func: async (successCallback, errCallback) => {
                                            try {
                                                props.onLoading()
                                                await clearProduct(p.product)
                                                successCallback()
                                            } catch (error: any) {
                                                errCallback(error)
                                            } finally {
                                                props.onCompleted()
                                            }
                                        },
                                    })
                                }
                                className="action-button"
                            >
                                <FontAwesomeIcon size="lg" color={Color.grey600} icon={faTrash} />
                            </div>
                        </div>
                    </>
                ),
            })
        })
        contextCustomParts.forEach((p, i) => {
            currentCart.push({
                expandItemId: p.customPart.name,
                equipmentName: p.customPart.equipment_name,
                serialNumber: p.customPart.serial_no,
                name: p.customPart.name,
                vendor: p.customPart.manufacturer.name,
                partNo: p.customPart.part_no,
                note: p.customPart.note,
                action: renderCounterCustomPart(p.customPart, p.count),
                price: renderCustomPartPrice(p.customPart.price, p.customPart.manufacturer.id + p.customPart.part_no + p.customPart.name, p.customPart),
                expandItem: (
                    <Input
                        value={p.customPart.note}
                        onChange={(e) => {
                            const newAdditionalItem = { ...p.customPart, note: e.target.value }
                            setAdditionalItem(p.customPart.id, newAdditionalItem)
                        }}
                        placeholder="Add note"
                    />
                ),
                actions: (
                    <>
                        <div className="buttons">
                            <div
                                className="action-button"
                                onClick={async () => {
                                    await setAdditionalPart(p.customPart)
                                    setCustomItemInputInfo({ visible: true, isUpdate: true })
                                }}
                            >
                                <PopoverItem popoverContent="Edit">
                                    <Icons type={IconType.Pen} />
                                </PopoverItem>
                            </div>
                            <div
                                onClick={() => {
                                    setOrderTableExpandItemInfo({
                                        visible: orderTableExpandItemInfo.itemId === p.customPart.name ? !orderTableExpandItemInfo.visible : true,
                                        itemId: p.customPart.name,
                                    })
                                }}
                                className="action-button"
                            >
                                {renderNoteIcon(!!p.customPart.note, orderTableExpandItemInfo.itemId === p.customPart.name)}
                            </div>
                            <div
                                onClick={() => {
                                    setAddImageEditModalInfo({
                                        visible: true,
                                        type: 'customPart',
                                        name: p.customPart.name,
                                        customPart: p.customPart,
                                        attachments: p.customPart.attachments,
                                    })
                                }}
                                className="action-button"
                            >
                                <PopoverItem popoverContent="Upload Images">
                                    <Icon empty={p.customPart.attachments.length > 0} type={IconType.AddImage} />
                                </PopoverItem>
                            </div>
                            <div
                                onClick={() =>
                                    confirm({
                                        modalTitle: 'Delete custom part',
                                        func: async (successCallback, errCallback) => {
                                            try {
                                                props.onLoading()
                                                await removeAdditionalItem(p.customPart)
                                                successCallback()
                                            } catch (error: any) {
                                                errCallback(error)
                                            } finally {
                                                props.onCompleted()
                                            }
                                        },
                                    })
                                }
                                className="remove-wrapper"
                            >
                                <FontAwesomeIcon size="lg" color={Color.grey600} icon={faTrash} />
                            </div>
                        </div>
                    </>
                ),
            })
        })
        contextCustomServices.forEach((p, i) => {
            currentCart.push({
                equipmentName: p.customService.note,
                expandItemId: p.customService.name,
                name: p.customService.name,
                partNo: '',
                note: p.customService.note,
                action: renderCounterCustomService(p.customService, p.count),
                price: renderCustomServicePrice(p.customService.price, p.customService.manufacturerId + p.customService.note + p.customService.name, p.customService),
                expandItem: (
                    <Input
                        value={p.customService.note}
                        onChange={(e) => {
                            const newAdditionalServiceItem = { ...p.customService, note: e.target.value }
                            setAdditionalService(p.customService, newAdditionalServiceItem)
                        }}
                        placeholder="Add note"
                    />
                ),
                actions: (
                    <>
                        <div className="buttons">
                            <div
                                onClick={() => {
                                    setOrderTableExpandItemInfo({
                                        visible: orderTableExpandItemInfo.itemId === p.customService.name ? !orderTableExpandItemInfo.visible : true,
                                        itemId: p.customService.name,
                                    })
                                }}
                                className="action-button"
                            >
                                {renderNoteIcon(!!p.customService.note, orderTableExpandItemInfo.itemId === p.customService.name)}
                            </div>
                            <div
                                onClick={() => {
                                    setAddImageEditModalInfo({
                                        visible: true,
                                        type: 'customService',
                                        name: p.customService.name,
                                        customService: p.customService,
                                        attachments: p.customService.attachments,
                                    })
                                }}
                                className="action-button"
                            >
                                <PopoverItem popoverContent="Upload Images">
                                    <Icon empty={p.customService.attachments.length > 0} type={IconType.AddImage} />
                                </PopoverItem>
                            </div>
                            <div
                                onClick={() =>
                                    confirm({
                                        modalTitle: 'Delete custom service',
                                        func: async (successCallback, errCallback) => {
                                            try {
                                                props.onLoading()
                                                await removeAdditionalServiceItem(p.customService)
                                                successCallback()
                                            } catch (error: any) {
                                                errCallback(error)
                                            } finally {
                                                props.onCompleted()
                                            }
                                        },
                                    })
                                }
                                className="remove-wrapper"
                            >
                                <FontAwesomeIcon size="lg" color={Color.grey600} icon={faTrash} />
                            </div>
                        </div>
                    </>
                ),
            })
        })
        return currentCart
    }

    const addNewPart = (value: any, key: string, isService?: boolean) => {
        if (!isService) {
            setAdditionalPart(mergeState(additionalPart, { [key]: value }))
        }
    }

    const isValidForm = () => {
        if (customItemInputInfo.isService) {
            return additionalPart.name.length > 3
        } else {
            return (additionalPart as CustomPartModel).part_no.length > 2 && additionalPart.name.length > 2
        }
    }
    const addAdditionalPart = async () => {
        try {
            if (customItemInputInfo.isUpdate) {
                const newItem: CustomPartModel = {
                    ...additionalPart,
                    manufacturer: { id: auth.company.id, name: additionalPart.manufacturer.name },
                }
                await setAdditionalItem(additionalPart.id, newItem as CustomPartModel)
            } else {
                if (customItemInputInfo.isService) {
                    const newItem: CustomServiceModel = {
                        name: additionalPart.name,
                        note: additionalPart.note,
                        attachments: additionalPart.attachments,
                        price: additionalPart.price,
                        manufacturerId: auth.company.id,
                        qty: 1,
                    }
                    await addAdditionalServiceItem(newItem)
                } else {
                    const newItem: CustomPartModel = {
                        ...additionalPart,
                        manufacturer: { id: auth.company.id, name: additionalPart.manufacturer.name },
                    }
                    await addAdditionalItem({ ...newItem, id: Math.floor(performance.now() * 10000000000000) + '' } as CustomPartModel)
                }
            }
            setAdditionalPart(initialCustomPart)
            setCustomItemInputInfo({ visible: false })
        } catch (error) {
        } finally {
        }
    }

    const renderAdditionalInput = () => {
        return (
            <div className="additional-input">
                {(customItemInputInfo.isService ? additionalServiceInput : additionalInput).map((item, index) => {
                    const value = Object.values(additionalPart)[Object.keys(additionalPart).indexOf(item.key)]

                    if (item.key === 'manufacturer') {
                        if (!customerId && props?.manufacturers) {
                            return (
                                <div style={{ width: item.width }} className="line">
                                    <DropdownWithSearch
                                        required={item.required}
                                        listItems={props?.manufacturers?.map((p) => {
                                            return {
                                                id: p.id,
                                                text: p.name,
                                            }
                                        })}
                                        placeholder={item.label}
                                        dropIcon={true}
                                        enableSearch={false}
                                        onDataChange={async (e) => {
                                            addNewPart({ id: e.id, name: e.text }, 'manufacturer')
                                        }}
                                        selectedItem={additionalPart.manufacturer}
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div style={{ width: item.width }} className="line">
                                    <Input
                                        key={index}
                                        type={item.type}
                                        required={item.required}
                                        placeholder={item.label}
                                        value={additionalPart.manufacturer.name}
                                        onChange={(e) =>
                                            addNewPart(
                                                {
                                                    id: auth.company.id,
                                                    name: e.target.value,
                                                },
                                                item.key
                                            )
                                        }
                                        defaultValue={item.defaultValue}
                                        min={item.min}
                                    />
                                </div>
                            )
                        }
                    } else {
                        if (item.key === 'qty') {
                            return (
                                <div style={{ width: item.width }} className="line">
                                    <Input
                                        required={item.required}
                                        placeholder={item.label}
                                        key={index}
                                        defaultValue={item.defaultValue}
                                        id={item.key}
                                        onWheel={(e) => (e.target as HTMLElement).blur()}
                                        onChange={(e) => {
                                            addNewPart(formatQuantity(e.target.value), item.key)
                                        }}
                                        value={value}
                                        type="number"
                                        min={item.min}
                                    />
                                </div>
                            )
                        } else if (item.key === 'price') {
                            return (
                                <div style={{ width: item.width }} className="line">
                                    <CurrencyInput
                                        required={item.required}
                                        placeholder={item.label}
                                        key={index}
                                        onChange={(price) => {
                                            addNewPart(price, item.key)
                                        }}
                                        value={value}
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div style={{ width: item.width }} className="line">
                                    <Input
                                        key={index}
                                        type={item.type}
                                        required={item.required}
                                        placeholder={item.label}
                                        defaultValue={item.defaultValue}
                                        value={value}
                                        onChange={(e) => addNewPart(e.target.value, item.key)}
                                    />
                                </div>
                            )
                        }
                    }
                })}

                <div className="input-button-wrapper">
                    <Button disabled={!isValidForm()} onClick={addAdditionalPart} type={ButtonType.Primary}>
                        {!customItemInputInfo.isUpdate ? 'Add' : 'Saved'}
                    </Button>
                    <Button
                        onClick={() => {
                            setAdditionalPart(initialCustomPart)
                            setCustomItemInputInfo({ visible: false })
                        }}
                        type={ButtonType.Primary}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
            </div>
        )
    }

    const showAdditionalInput = (isService?: boolean) => {
        setCustomItemInputInfo({ isService: isService, visible: true })
        setAdditionalPart(initialCustomPart)
    }

    const onChangeFile = async (file: File) => {
        try {
            if (file) {
                props.onLoading()
                const uploadFileService = new StorageService()
                const fileInfos = await uploadFileService.uploadMultiple(file as unknown as FileList)
                await setAddImageEditModalInfo({
                    ...addImageModalInfo,
                    attachments: fileInfos.map((value, index, array) => {
                        return { fileName: value.name!, fileUrl: value.url }
                    }),
                })
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            props.onCompleted()
        }
    }

    const onClickAddEquipmentParts = () => {
        if (props.customerEquipmentCount && props.customerEquipmentCount > 0) {
            if (props.customerEquipmentCount > 1) {
                navigate(`/customer/${props.customerId}?activeTab=${Tabs.Equipment}`)
            } else if (props.customerEquipmentCount === 1 && props.customerEquipmentList) {
                const equipmentId = props.customerEquipmentList[0].id
                navigate(`/customer/${props.customerId}/equipment/${equipmentId}?activeTab=${Tabs.DrawingAndParts}`)
            }
        }
    }

    const onClickAddProducts = () => {
        if (props.customerId) {
            navigate(`/customer/${props.customerId}?activeTab=${Tabs.Products}`)
        }
    }

    const addMoreButtons = () => {
        if (purchaseWithoutCustomer) {
            return [
                { title: 'Add custom service', onClick: () => showAdditionalInput(true) },
                { title: 'Add custom part', onClick: showAdditionalInput },
            ]
        } else {
            if (props.customerEquipmentCount && props.customerEquipmentCount > 0) {
                return [
                    { title: 'Add custom service', onClick: () => showAdditionalInput(true) },
                    { title: 'Add custom part', onClick: showAdditionalInput },
                    { title: 'Add equipment parts', onClick: onClickAddEquipmentParts },
                    { title: 'Add products', onClick: onClickAddProducts },
                ]
            } else {
                return [
                    { title: 'Add custom service', onClick: () => showAdditionalInput(true) },
                    { title: 'Add custom part', onClick: showAdditionalInput },
                    { title: 'Add products', onClick: onClickAddProducts },
                ]
            }
        }
    }

    const onChangeFileName = (index: number, filename: string) => {
        let existViewedAttachments = addImageModalInfo.attachments
        existViewedAttachments[index] = { ...existViewedAttachments[index], fileName: filename }
        setAddImageEditModalInfo({ ...addImageModalInfo, attachments: existViewedAttachments })
    }

    return (
        <div className="order">
            <div className="order-table">
                <OrderListTable
                    expandItemInfo={orderTableExpandItemInfo}
                    data={getSummaryData().map((p) => {
                        return {
                            name: p.name,
                            category: p.category,
                            vendor: p.vendor,
                            partNumber: p.partNo,
                            equipmentName: p.equipmentName,
                            serialNumber: p.serialNumber,
                            uom: p.uom,
                            pkg: p.pkg,
                            qty: p.action,
                            price: p.price,
                            buttons: p.actions,
                            id: p.name,
                            expandItem: p.expandItem,
                            expandItemId: p.expandItemId,
                        }
                    })}
                />
            </div>
            {customItemInputInfo.visible && renderAdditionalInput()}

            <div className="total-wrapper">
                <div className="additional-cta">
                    <AddMoreButton options={addMoreButtons()} />
                </div>
                <div className="total-price">
                    <div className="total-price-title">Total Price:</div>
                    <div className="total-price-value"> {getTotalPrice()}</div>
                </div>
            </div>
            {editNoteModalVisible && (
                <Modal
                    visible={editNoteModalVisible}
                    primaryButtonText="Save"
                    secondaryButtonText="Cancel"
                    onClickSecondary={() => {
                        setEditNoteModalVisible(false)
                    }}
                    onClickPrimary={() => {
                        if (editModalInfo.type === 'product') {
                            setProductNote(editModalInfo.product, editModalInfo.note)
                        } else if (editModalInfo.type === 'customPart') {
                            const newData: CustomPartModel = {
                                id: editModalInfo.id,
                                name: editModalInfo.name,
                                manufacturer: editModalInfo.manufacturer,
                                part_no: editModalInfo.part_no,
                                note: editModalInfo.note,
                                qty: editModalInfo.oldCustomPart.qty,
                                price: editModalInfo.oldCustomPart.price,
                                attachments: editModalInfo.oldCustomPart.attachments,
                            }
                            setAdditionalItem(editModalInfo.id, newData)
                        } else {
                            setPartNote(editModalInfo.equipment, editModalInfo.part, editModalInfo.note)
                        }
                        setEditNoteModalVisible(false)
                    }}
                    title={editModalInfo.name}
                >
                    <div className="modal-container">
                        {editModalInfo.equipment && editModalInfo.equipment.name !== '' && editModalInfo?.serialNumber !== '' && (
                            <div className="modal-item-description">
                                {editModalInfo?.equipment.name} • {editModalInfo?.serialNumber}
                            </div>
                        )}
                        {editModalInfo.type === 'customPart' ? (
                            props.manufacturers ? (
                                <>
                                    <BasicDropdown
                                        label="Manufacturer Name"
                                        menuItems={props?.manufacturers?.map((manufacturer) => {
                                            return {
                                                id: manufacturer.id,
                                                text: manufacturer.name,
                                            }
                                        })}
                                        onChange={(data) => {
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                manufacturer: { id: data.id, name: data.text },
                                            })
                                        }}
                                        selectedItem={{
                                            id: editModalInfo.manufacturer.id,
                                            text: editModalInfo.manufacturer.name,
                                        }}
                                    />
                                    <Input
                                        placeholder="Name"
                                        max={MaxCharacter.name}
                                        value={editModalInfo.name}
                                        onChange={(e) =>
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                name: e.target.value,
                                            })
                                        }
                                    />

                                    <Input
                                        placeholder="Part Number"
                                        max={MaxCharacter.max50}
                                        value={editModalInfo.part_no}
                                        onChange={(e) =>
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                part_no: e.target.value,
                                            })
                                        }
                                    />

                                    <TextArea
                                        placeholder="Notes"
                                        value={editModalInfo.note}
                                        onChange={(e) =>
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                note: e.target.value,
                                            })
                                        }
                                    ></TextArea>
                                </>
                            ) : (
                                <>
                                    <Input
                                        placeholder="Manufacturer Name"
                                        max={MaxCharacter.name}
                                        value={editModalInfo.manufacturer.name}
                                        onChange={(e) =>
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                manufacturer: { id: auth.company.id, name: e.target.value },
                                            })
                                        }
                                    />
                                    <Input
                                        placeholder="Name"
                                        max={MaxCharacter.name}
                                        value={editModalInfo.name}
                                        onChange={(e) =>
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                name: e.target.value,
                                            })
                                        }
                                    />

                                    <Input
                                        placeholder="Part Number"
                                        max={MaxCharacter.max50}
                                        value={editModalInfo.part_no}
                                        onChange={(e) =>
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                part_no: e.target.value,
                                            })
                                        }
                                    />

                                    <TextArea
                                        value={editModalInfo.note}
                                        placeholder="Notes"
                                        onChange={(e) =>
                                            setEditModalInfo({
                                                ...editModalInfo,
                                                note: e.target.value,
                                            })
                                        }
                                    ></TextArea>
                                </>
                            )
                        ) : (
                            <>
                                <TextArea
                                    value={editModalInfo.note}
                                    placeholder="Notes"
                                    onChange={(e) =>
                                        setEditModalInfo({
                                            ...editModalInfo,
                                            note: e.target.value,
                                        })
                                    }
                                ></TextArea>
                            </>
                        )}
                    </div>
                </Modal>
            )}
            {addImageModalInfo.visible && (
                <Modal
                    visible={addImageModalInfo.visible}
                    primaryButtonText="Save"
                    secondaryButtonText="Cancel"
                    onClickSecondary={() => {
                        setAddImageEditModalInfo({
                            visible: false,
                            type: '',
                            name: '',
                            attachments: [],
                        })
                    }}
                    onClickPrimary={() => {
                        try {
                            if (addImageModalInfo.type === 'product' && addImageModalInfo.product && addImageModalInfo.attachments.length > 0) {
                                setProductAttachment(addImageModalInfo.product, addImageModalInfo.attachments)
                            } else if (addImageModalInfo.type === 'part' && addImageModalInfo.equipment && addImageModalInfo.part) {
                                setPartAttachment(addImageModalInfo.equipment, addImageModalInfo.part, addImageModalInfo.attachments)
                            } else if (addImageModalInfo.type === 'customPart' && addImageModalInfo.customPart && addImageModalInfo.attachments.length > 0) {
                                setCustomPartAttachment(addImageModalInfo.customPart, addImageModalInfo.attachments)
                            } else if (addImageModalInfo.type === 'customService' && addImageModalInfo.customService && addImageModalInfo.attachments.length > 0) {
                                setCustomServiceAttachment(addImageModalInfo.customService, addImageModalInfo.attachments)
                            }
                        } catch (error) {
                        } finally {
                            setAddImageEditModalInfo({
                                visible: false,
                                type: '',
                                name: '',
                                attachments: [],
                            })
                        }
                    }}
                    title="Upload images"
                >
                    <div className="modal-container">
                        <FileInput
                            multiple
                            fileInputText="UPLOAD IMAGES"
                            handleChange={(event) => onChangeFile(event)}
                            isFileUploaded={addImageModalInfo.attachments.length > 0}
                            name={addImageModalInfo.attachments.map((value, index, array) => {
                                return value.fileName
                            })}
                        />
                        <div className="attachments-preview">
                            {addImageModalInfo.attachments.map((attachment, index: number) => (
                                <div className="attachments-preview__line">
                                    <Tooltip title="Open in new tab">
                                        <div className="attachments-preview__line__link">
                                            <a rel="noreferrer" target="_blank" href={attachment.fileUrl}>
                                                <Icons type={IconType.GoToLink} />
                                            </a>
                                        </div>
                                    </Tooltip>
                                    <div className="attachments-preview__line__file-name">
                                        <RenameFilename filename={attachment.fileName} onChange={(filename) => onChangeFileName(index, filename)} />
                                    </div>
                                    <Tooltip title="Delete">
                                        <div
                                            onClick={() => {
                                                console.log(addImageModalInfo.attachments.filter((e) => e.fileUrl !== attachment.fileUrl))
                                                setAddImageEditModalInfo({
                                                    ...addImageModalInfo,
                                                    attachments: addImageModalInfo.attachments.filter((e) => e.fileUrl !== attachment.fileUrl),
                                                })
                                            }}
                                            className="attachments-preview__line__delete"
                                        >
                                            <Icons type={IconType.Trash} />
                                        </div>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    )
}
