import { EquipmentModel, NewUpdateEquipmentModel } from '../../../../../../models/New/Equipment'
import ImageService from '../../../../../../services/UiService/Image'
import { UpdateImageModel } from '../../../../../../models/New/Image'
import StorageService from '../../../../../../services/NewServices/StorageService'
import TagService from '../../../../../../services/UiService/Tag'
import { CreateTagModel } from '../../../../../../models/New/Tag'
import EquipmentImageBox from '../../../../../../components/EquipmentImageBox'
import NewTags from './../../../../../../components/NewTags/index'
import EquipmentInfoCart from './EquipmentInfoCart'
import './NewEquipmentDetails.scss'
import React, { useContext } from 'react'
import { ConfirmationContext } from '../../../../../../store/ConfirmationContext'
import { EquipmentActionTypes } from '../../../../../../models/Ui/Common'
import { useNavigate } from 'react-router'

interface NewEquipmentDetailsProps {
    isRootEquipment: boolean
    equipment: EquipmentModel
    installationCount: number
    flexWrap?: boolean
    isGoToTemplateButton?: boolean
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel) => void
    onError: (error: any) => void
    handleLinkCustomers: () => void
    handleEdit: () => void
}

const descriptionLineRenderer = (value: string | undefined, handleEdit: () => void) => {
    return (
        <div className="line">
            <span className="title">Description</span>
            {value ? (
                <span className="value" style={{ lineBreak: 'normal' }}>
                    {value}
                </span>
            ) : (
                <span className="add-button" onClick={handleEdit}>
                    +add description
                </span>
            )}
        </div>
    )
}
const vendorAndBrandLineRenderer = (vendor: string | undefined, brand: string | undefined, handleEdit: () => void) => {
    return (
        <>
            <div className="line">
                <span className="title">Vendor</span>
                {vendor ? (
                    <span className="value">{vendor}</span>
                ) : (
                    <span className="add-button" onClick={handleEdit}>
                        +add
                    </span>
                )}
            </div>
            <div className="line">
                <span className="title">Brand</span>
                {brand ? (
                    <span className="value">{brand}</span>
                ) : (
                    <span className="add-button" onClick={handleEdit}>
                        +add
                    </span>
                )}
            </div>
        </>
    )
}
const templateLineRenderer = (templateId: string, handleNavigate: () => void) => {
    return (
        <div className="line">
            <span className="title">Template</span>
            <div className="go-to" onClick={() => handleNavigate()}>
                Go to template
            </div>
        </div>
    )
}

function NewEquipmentDetails(props: NewEquipmentDetailsProps) {
    const { onLoading, onError, onSuccess, onCompleted } = props
    const { confirm } = useContext(ConfirmationContext)
    const imageService = new ImageService()
    const tagService = new TagService()
    const navigate = useNavigate()

    async function createEquipmentTagHandler(equipmentId: string, name: string) {
        try {
            onLoading()
            const newData: CreateTagModel = {
                name: name,
                equipmentId: equipmentId,
            }

            const response = await tagService.createTag(newData)
            await onSuccess(EquipmentActionTypes.CREATE_EQUIPMENT_TAG, response as CreateTagModel)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }
    async function deleteEquipmentTagHandler(id: string, name: string) {
        try {
            onLoading()
            const newData = {
                name: name,
            }
            await tagService.delete(id)
            onSuccess(EquipmentActionTypes.DELETE_EQUIPMENT_TAG, newData)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }

    async function createEquipmentImageHandler(file: FileList) {
        try {
            onLoading()
            if (file) {
                props.onLoading()
                const uploadFileService = new StorageService()
                const fileInfos = await uploadFileService.uploadMultiple(file as unknown as FileList)
                await imageService.createImage(
                    fileInfos.map((file) => {
                        return { url: file.url, equipmentId: props.equipment.id }
                    })
                )
                onSuccess(EquipmentActionTypes.UPDATE_EQUIPMENT_IMG)
            } /* else not needed */
        } catch (error: any) {
            console.log(error)
        } finally {
            onCompleted()
        }
    }

    async function updateEquipmentImageHandler(imageId: string, isFeatured: boolean) {
        try {
            onLoading()
            const newData: UpdateImageModel = {
                isFeatured: isFeatured,
            }
            await imageService.update(imageId, newData)
            onSuccess(EquipmentActionTypes.UPDATE_EQUIPMENT_IMG)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }

    async function deleteEquipmentImageHandler(imageId: string) {
        confirm({
            modalTitle: 'Deleting Equipment Image?',
            // titleColor: '#D84315',
            primaryButtonText: 'Cancel',
            doesPrimaryButtonSubmit: false,
            secondaryButtonText: 'Delete',
            func: async (successCallback, errorCallback) => {
                try {
                    onLoading()
                    await imageService.delete(imageId)
                    onSuccess(EquipmentActionTypes.UPDATE_EQUIPMENT_IMG)
                    successCallback()
                } catch (error: any) {
                    onError(error)
                    errorCallback(error)
                } finally {
                    onCompleted()
                }
            },
            contentNode: (
                <div className="modal-line">
                    <span className="warning-text">Are you sure you would like to delete the equipment's image?</span>
                </div>
            ),
        })
    }

    const handleNavigate = () => {
        navigate('/equipment/' + props.equipment.id)
    }

    return (
        <div className="new-details-tab-container">
            {props.isRootEquipment && (
                <div className="equipment-info-cart">
                    <EquipmentInfoCart
                        flexWrap={props.flexWrap}
                        handleEdit={props.handleEdit}
                        handleLinkCustomers={props.handleLinkCustomers}
                        installationCount={props.installationCount}
                        equipment={props.equipment}
                    />
                </div>
            )}
            <div className="equipment-info">
                <div className="top">
                    <EquipmentImageBox
                        title="Equipment"
                        updateEquipmentImageHandler={updateEquipmentImageHandler}
                        deleteEquipmentImageHandler={deleteEquipmentImageHandler}
                        createEquipmentImageHandler={createEquipmentImageHandler}
                        images={props.equipment?.images.sort((image) => (image.isFeatured ? -1 : 1))}
                    />
                    <div className="top-right">
                        <div className="tags-container">
                            <span className="title">Tags</span>
                            <NewTags
                                grid="vertical"
                                createItem={async (tags: string) => await createEquipmentTagHandler(props.equipment.id, tags)}
                                removeItem={async (id: string, name: string) => await deleteEquipmentTagHandler(id, name)}
                                items={props.equipment.tags || []}
                                canBeAdded
                                canBeDeleted
                            />
                        </div>
                        <div className="line">
                            {props.flexWrap ? (
                                vendorAndBrandLineRenderer(props.equipment.manufacturerName, props.equipment.brand, props.handleEdit)
                            ) : (
                                <>
                                    {descriptionLineRenderer(props.equipment.description, props.handleEdit)}
                                    {props.isGoToTemplateButton && templateLineRenderer(props.equipment.templateId, handleNavigate)}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    {!props.flexWrap ? (
                        vendorAndBrandLineRenderer(props.equipment.manufacturerName, props.equipment.brand, props.handleEdit)
                    ) : (
                        <>
                            {descriptionLineRenderer(props.equipment.description, props.handleEdit)}
                            {props.isGoToTemplateButton && templateLineRenderer(props.equipment.templateId, handleNavigate)}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default NewEquipmentDetails
