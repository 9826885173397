import { EquipmentCustomerTableModel } from '../../../../../models/New/Equipment'
import CustomerListTable from '../../Tables/CustomerListTable'

interface Props {
    customers: EquipmentCustomerTableModel[]
}

function CustomersTab(props: Props) {
    return (
        <CustomerListTable
            cloneEquipment={() => {}}
            navigate={'/customer/'}
            data={props.customers.map((p) => {
                return {
                    name: p.name,
                    id: p.id,
                    email: p.email,
                    installs: p.installationCount ? p.installationCount : '',
                }
            })}
        />
    )
}

export default CustomersTab
