import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Icon, { IconType } from '../../Icon'
import { Color } from '../../models/enums'
import { priceFormatter } from '../../utils/helpers'
import './CartItem.scss'

interface Props {
    name: string
    description?: string
    count: number
    price: number | '-'
    serialNumber?: string
    equipmentName?: string
    onClickPlus?: React.MouseEventHandler<HTMLDivElement>
    onClickMinus?: React.MouseEventHandler<HTMLDivElement>
    onClickTrash?: React.MouseEventHandler<HTMLDivElement>
    onChangeInput?: (value: number) => void
}

function CartItem(props: Props) {
    return (
        <div className="cart-item">
            <div className="cart-left">
                <div className="name">{props.name}</div>
                <div className="description">{props.description}</div>
            </div>

            <div className="cart-right">
                <div className="price-info">
                    <span>{priceFormatter(props.price)}</span>
                </div>
                <div className="action">
                    <div className="counter">
                        <div onClick={props.onClickMinus} className="count-changer">
                            <Icon type={IconType.Minus} />
                        </div>
                        <input
                            onChange={(e) => props.onChangeInput && props.onChangeInput(e.target.valueAsNumber)}
                            onBlur={() => {
                                if (!props.count && props.onChangeInput) {
                                    props.onChangeInput(1)
                                }
                            }}
                            type="number"
                            min={1}
                            value={props.count}
                        />
                        <div onClick={props.onClickPlus} className="count-changer">
                            <Icon type={IconType.Plus} />
                        </div>
                    </div>
                    <div onClick={props.onClickTrash} className="remove-wrapper">
                        <FontAwesomeIcon size="xs" color={Color.blue900} icon={faTrash} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem
