import { useEffect, useState } from 'react'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import PartList from '../../../../../services/UiService/PartList'

interface Props {
    name: string
    id: string
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: () => void
    onError: (error: any) => void
}

function EditPartsListModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props
    const [name, setName] = useState<string>('')

    useEffect(() => {
        setName(props.name)
    }, [props.name])

    async function updatePartsList() {
        try {
            onLoading()
            await PartList.update(props.id, name)
            onSuccess()
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return name === props.name
    }

    const clearForm = () => {
        setName('')
    }

    const clickPrimary = () => {
        updatePartsList()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <>
            <Modal
                className="edit-equipment-modal"
                visible={props.visible}
                title="Edit Parts List Name"
                primaryButtonText="Save Changes"
                onClickPrimary={clickPrimary}
                primaryDisabled={isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
            >
                <div className="modal-line">
                    <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="No." />
                </div>
            </Modal>
        </>
    )
}

export default EditPartsListModal
