import { CustomerEquipmentLocationModel } from '../Db/Customer'
import { PartsListModel } from '../Db/Equipment'
import { DocumentModel } from './Document'
import { ProductModel } from './Product'

export interface AssemblyModel extends Omit<EquipmentModel, 'assemblies' | 'equipmentTree'> {
    subassemblies: AssemblyModel[]
}
export interface EquipmentModel extends EquipmentSummaryModel {
    partNumber: string
    installationAddress?: CustomerEquipmentLocationModel
    description?: string
    imageUrl?: string
    partsList: PartsListModel[]
    assemblies: AssemblyModel[]
    products: ProductModel[]
    documents: DocumentModel[]
    equipmentTree?: string
    pdfPreview?: string
    images: ImageModel[]
    note?: string
    standardWarranty?: WarrantyModel
    tags?: string[]
    installs?: number
    showList?: boolean
}

export interface WarrantyModel {
    duration: number
    unit: string
}

export interface EquipmentSummaryModel {
    id: string
    name: string
    partNumber: string
    drawing?: string
    pdfPreview?: string
    manufacturer: EquipmentManufacturerModel
    parent?: EquipmentSummaryModel
    serialNumber?: string
}

export interface EquipmentCartModel {
    savedId?: string
    savedOrderId?: string
    parent?: EquipmentSummaryModel
    id: string
    name: string
    partNumber: string
    drawing?: string
    pdfPreview?: string
    manufacturer: EquipmentManufacturerModel
    serialNumber?: string
}

export interface EquipmentManufacturerModel {
    name: string
    id: string
    email: string
}

export interface CreateAssemblyModel extends CreateEquipmentModel {
    subAssemblies: AssemblyModel[]
}

export interface CreateEquipmentModel {
    name: string
    partNumber: string
    serialNumber?: string
    description?: string
    parentId: string
    standardWarranty?: WarrantyModel
}

export interface CloneEquipmentModel {
    equipmentId: string
    name: string
    partNumber: string
    drawing?: string
    pdfPreview?: string
}

export interface NewUpdateEquipmentModel {
    name?: string
    partNumber?: string
    serialNumber?: string
    description?: string
    standardWarranty?: WarrantyModel
    tags?: string[]
    parentId?: string | null
    note?: string
    images?: ImageModel[]
    documents?: DocumentModel[]
    drawing?: string
    pdfPreview?: string
    partsList?: PartsListModel[]
    equipmentTree?: string
    showList?: boolean
}

export interface UpdateEquipmentModel {
    id: string
    name?: string
    partNumber?: string
    serialNumber?: string
    description?: string
    standardWarranty?: WarrantyModel
    tags?: string[]
    parentId?: string
    showList?: boolean
}

export interface PushEquipmentImagesModel {
    id: string
    images: ImageModel[]
}

export interface PullEquipmentImagesModel {
    id: string
    imageId: string
}

export interface ImageModel {
    id: string
    url: string
    isThumbnail: boolean
}

export interface UpdateAssemblyModel extends UpdateEquipmentModel {
    subAssemblies: AssemblyModel[]
}

export interface NewUpdateAssemblyModel extends Omit<NewUpdateEquipmentModel, 'assemblies' | 'equipmentTree' | 'serialNumber' | 'standartWarranty'> {
    subAssemblies?: AssemblyModel[]
}

export interface BillModel {
    [key: string]: string
    contact_name: string
    phone_number: string
    site_address1: string
    site_address2: string
    country: string
    note: string
    city: string
    state: string
    postal_code: string
}
export interface ShippingModel {
    [key: string]: string
    shipVIA: string
    type: string
    note: string
    shippingCost: string
}
export interface QuoteShippingModel {
    shipping_cost: number | undefined
    note: string
}

export enum DrawingType {
    Image = 'image',
    Pdf = 'pdf',
}
export enum CheckoutType {
    Quote = 'quote',
    Purchase = 'purchase',
    DraftCart = 'draftCart',
    UpdateCart = 'updateCart',
}
