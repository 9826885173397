import ViewAsCustomer from '../../../../../components/ViewAsCustomer'
import { NewUpdateEquipmentModel } from '../../../../../models/New/Equipment'
import NewNotepad from './../../../../../components/NewNotepad/index'
import { useParams } from 'react-router'
import { ContractInputModel, ContractOutputModel } from '../../../../../models/Ui/Contract'
import ContractEditModal from '../../Modals/ContractEditModal'
import ContractService from '../../../../../services/UiService/Contract'
import NewContractDetails from './ContractDetailsTab'
import { ContractActionTypes } from '../../../CustomerDetail'

interface Props {
    contract: ContractOutputModel
    visible: boolean
    modalVisible: boolean
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ContractActionTypes) => void
    onError: (error: any) => void
    onModalOpen: () => void
    onModalClose: () => void
}

function DetailsTab(props: React.PropsWithChildren<Props>) {
    const { contract, onSuccess, onError, onCompleted, onLoading } = props
    const { id } = useParams()

    async function saveNoteHandler(data: NewUpdateEquipmentModel) {
        try {
            await ContractService.update(id!, {
                notes: data.notes,
            } as ContractInputModel)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }

    return (
        <>
            <div className="content-container" style={{ display: props.visible ? 'flex' : 'none' }}>
                <NewContractDetails handleEdit={props.onModalOpen} contract={contract} onLoading={onLoading} onCompleted={onCompleted} onSuccess={onSuccess} onError={onError} />
                <div className="notepad-container">
                    <ViewAsCustomer>
                        <NewNotepad id={contract.id as string} notes={contract.notes as string} saveNote={(data: NewUpdateEquipmentModel) => saveNoteHandler(data)} />
                    </ViewAsCustomer>
                </div>
            </div>
            {
                <ContractEditModal
                    contract={contract}
                    visible={props.modalVisible}
                    closeModal={props.onModalClose}
                    onLoading={onLoading}
                    onCompleted={onCompleted}
                    onError={onError}
                    onSuccess={onSuccess}
                />
            }
        </>
    )
}

export default DetailsTab
