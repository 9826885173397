import { useEffect, useState } from 'react'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import { CustomerDetailModel, UpdateCompanyCustomer } from '../../../../../models/New/Customer'
import { ContractActionTypes } from '../..'
import CompanyService from '../../../../../services/UiService/Company'
import { MaxCharacter } from '../../../../../models/enums'
import { toast } from 'react-toastify'

interface Props {
    company: CustomerDetailModel
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ContractActionTypes, data?: any) => void
}

function UpdateAddressModal(props: Props) {
    const { onLoading, onCompleted } = props
    const [streetAddress1, setStreetAddress1] = useState<string>('')
    const [streetAddress2, setStreetAddress2] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [zipCode, setZipCode] = useState<string>('')
    const [country, setCountry] = useState<string>('')

    const clearForm = () => {
        setStreetAddress1('')
        setStreetAddress2('')
        setCity('')
        setState('')
        setZipCode('')
        setCountry('')
    }

    async function updateCompany() {
        const companyService = new CompanyService()
        try {
            onLoading()
            const newData: UpdateCompanyCustomer = {
                email: props.company.email,
                streetAddress1: streetAddress1,
                streetAddress2: streetAddress2,
                city: city,
                state: state,
                zipCode: zipCode,
                country: country,
            } as UpdateCompanyCustomer
            const response = await companyService.updateCustomer(newData, props.company.id)
            props.onSuccess(ContractActionTypes.UPDATE_COMPANY_CUSTOMER, response)
        } catch (error: any) {
            toast.error(error)
        } finally {
            props.closeModal()
            clearForm()
            onCompleted()
        }
    }
    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }
    useEffect(() => {
        setStreetAddress1(props.company?.streetAddress1)
        setStreetAddress2(props.company?.streetAddress2)
        setCity(props.company?.city)
        setState(props.company.state)
        setZipCode(props.company?.zipCode)
        setCountry(props.company.country)
    }, [props.company?.city, props.company.country, props.company.state, props.company?.streetAddress1, props.company?.streetAddress2, props.company?.zipCode])

    const isValidForm = () => {
        return (
            props.company.streetAddress1 !== streetAddress1 ||
            props.company.streetAddress2 !== streetAddress2 ||
            props.company.city !== city ||
            props.company.state !== state ||
            props.company.zipCode !== zipCode
        )
    }
    return (
        <>
            <Modal
                visible={props?.visible}
                title="Add new address"
                className="contact-modal"
                primaryButtonText="Save"
                onClickPrimary={updateCompany}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
                buttonOrder="secondaryPrimary"
                primaryDisabled={!isValidForm()}
            >
                <div className="modal-line">
                    <Input value={streetAddress1} onChange={(e) => setStreetAddress1(e.target.value)} placeholder="STREET ADDRESS 1" max={MaxCharacter.address} />
                </div>
                <div className="modal-line">
                    <Input value={streetAddress2} onChange={(e) => setStreetAddress2(e.target.value)} placeholder="STREET ADDRESS 2" max={MaxCharacter.address} />
                </div>
                <div className="modal-line">
                    <Input max={MaxCharacter.subAddress} value={city} onChange={(e) => setCity(e.target.value)} placeholder="CITY" />
                    <Input max={MaxCharacter.subAddress} value={state} onChange={(e) => setState(e.target.value)} placeholder="STATE" />
                </div>
                <div className="modal-line">
                    <Input max={MaxCharacter.zipCode} value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="ZIP CODE" />
                    <Input max={MaxCharacter.subAddress} value={country} onChange={(e) => setCountry(e.target.value)} placeholder="COUNTRY" />
                </div>
            </Modal>
        </>
    )
}

export default UpdateAddressModal
