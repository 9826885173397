import { useEffect, useState } from 'react'
import OrderService from '../../../../../services/UiService/Order'
import { useParams } from 'react-router'
import { OrderModel } from '../../../../../models/New/Order'
import SearchInput from '../../../../../components/SearchInput'
import { arraySort } from '../../../../../utils/helpers'
import OrderListTable from '../../../../../components/OrderTableList'
import { toast } from 'react-toastify'
import Loading from '../../../../../components/Loading'

interface Props {
    visible: boolean
}

const OrderTab = (props: Props) => {
    const { productId } = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [orders, setOrders] = useState<OrderModel[]>([])
    const [orderFilteredData, setOrderFilteredData] = useState<OrderModel[]>([])

    const getOrders = async () => {
        try {
            setLoading(true)
            const response = await OrderService.getOrderByProductId(productId!)
            setOrders(arraySort(response, 'creationDate', 'descending'))
        } catch (error: any) {
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    const handleOrderSearchedData = (data: OrderModel[]) => {
        setOrderFilteredData(data)
    }

    useEffect(() => {
        if (props.visible) getOrders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible])

    return (
        <div
            style={{
                display: props.visible ? 'flex' : 'none',
                flexDirection: 'column',
                gap: '1rem',
            }}
        >
            {loading && <Loading />}
            <SearchInput data={orders} handleSearch={handleOrderSearchedData} searchKeys={['customer.name', 'creationDate']} />
            <OrderListTable data={orderFilteredData} />
        </div>
    )
}

export default OrderTab
