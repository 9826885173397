import React from 'react'
import { CustomerDetailModel } from '../../../../../../models/New/Customer'
import './Card.scss'

interface IProps {
    customer: CustomerDetailModel
    handleUpdateCompany: () => void
}

function AssignedManager(props: IProps) {
    const { customer, handleUpdateCompany } = props
    return (
        <div className="overview-card">
            <div className="item">
                <div className="item-header">
                    <span className="title">Assigned Account Manager</span>
                </div>
                {customer.accountManager ? (
                    <>
                        <span>{customer.accountManager.firstName + ' ' + customer.accountManager.lastName}</span>
                        <span>Regional Manager</span>
                        <span>{customer.accountManager.phone}</span>
                        <span>{customer.accountManager.email}</span>
                    </>
                ) : (
                    <div onClick={handleUpdateCompany} className="action-button">
                        +update
                    </div>
                )}
            </div>
        </div>
    )
}

export default AssignedManager
