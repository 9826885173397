import React from 'react'
import { EquipmentModel } from '../../models/New/Equipment'
import './EquipmentTitle.scss'

interface IProps {
    equipment: EquipmentModel
    className?: string
}

function EquipmentTitle(props: React.PropsWithChildren<IProps>) {
    const { equipment, className } = props
    return (
        <div className={`page-title-menu ${className}`}>
            {equipment?.id === equipment.tree?.id ? (
                <span className="equipment-name">{equipment.name}</span>
            ) : (
                <div className="page-title-menu">
                    <span className="root-equipment-name">{equipment.tree.name}</span>
                    <span className="chevron-right">{'>'}</span>
                    <span className="equipment-name">{equipment.name}</span>
                </div>
            )}
        </div>
    )
}

export default EquipmentTitle
