import './SearchableDropdown.scss'
import { Autocomplete, TextField } from '@mui/material'
import { IDropdownOption } from '../dropdown.interface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FC, useEffect, useState } from 'react'
import PopoverItem from '../../PopoverItem'

interface Props {
    label?: string
    data: IDropdownOption[]
    onChange: (event: any, values: any) => void
    selectedItem?: IDropdownOption
    clearIconText?: string
    disabled?: boolean
    required?: boolean
    className?: string
}

const SearchableDropdown: FC<Props> = ({ className, label, data, onChange, selectedItem, clearIconText = 'Clear', disabled, required }) => {
    const selectedItemIsEmpty = (selectedItem?.id === '' && selectedItem.label === '') || !selectedItem
    const [searchText, setSearchText] = useState<string>('')

    useEffect(() => {
        if (!selectedItem) {
            setSearchText('')
        }
    }, [selectedItem])

    return (
        <div className={`searchable-dropdown-container ${className}`}>
            <Autocomplete
                sx={{ width: '12rem' }}
                disabled={disabled}
                renderInput={(params) => <TextField {...params} required={required} label={label} />}
                options={data}
                onChange={onChange}
                value={!selectedItemIsEmpty ? selectedItem : null}
                inputValue={searchText}
                onInputChange={(_e, value) => setSearchText(value)}
                getOptionLabel={(option) => option.label}
                disableClearable={selectedItemIsEmpty && !searchText}
                clearIcon={
                    !selectedItemIsEmpty || searchText ? (
                        <PopoverItem popoverContent={clearIconText} style={{ padding: '1px 5px' }}>
                            <div className="clear-button">
                                <FontAwesomeIcon className="fa-close" icon={faClose} size="sm" />
                            </div>
                        </PopoverItem>
                    ) : null
                }
                clearText=""
                renderOption={(props, item) => (
                    <li {...props} key={item.id}>
                        {' '}
                        {item.label}{' '}
                    </li>
                )}
            />
        </div>
    )
}

export default SearchableDropdown
