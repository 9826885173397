import { FC } from 'react'
import Input from '../../../../../../components/Input'
import './style.scss'

interface Props {
    value: string
    onChange: (value: string) => void
    disabled?: boolean
    placeholder?: string
}

const ActivityTitleInput: FC<Props> = ({ value, onChange, disabled, placeholder = 'What do you need to do?' }) => {
    return (
        <Input
            disabled={disabled}
            outlinePlaceholder={!value}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            required
            className={`activity-title-input ${value ? 'filled-input' : 'empty-input'}`}
        />
    )
}

export default ActivityTitleInput
