import Konva from 'konva'
import { Circle, Layer, Stage } from 'react-konva'
import { createRef, FC, useEffect, useRef, useState } from 'react'
import { Area, Coord, ICanvasImage, IMappingDetailsData } from '../../ImageMapping.interface'
import MappingDetails from '../../components/MappingDetails'
import { EMappingCircleColor } from '../../components/MappingCircle/MappingCircle.interface'
import { KonvaEventObject } from 'konva/lib/Node'
import AddMapping from '../../components/AddMapping'
import { PartsModel } from '../../../../models/New/Equipment'

const CanvasImage: FC<ICanvasImage> = (props: ICanvasImage) => {
    const [reassign, setReassign] = useState<boolean>(false)
    const [partAmount, setPartAmount] = useState<number>(1)
    const [circleRefs, setCircleRefs] = useState([] as any[])
    const [mappingDetails, setMappingDetails] = useState<IMappingDetailsData | null>(null)
    const [isHoverCursor, setIsHoverCursor] = useState<boolean>(false)
    const [mappingCircle, setMappingCircle] = useState(props.mappingCircle)
    const [stage, setStage] = useState({ width: 0, height: 0 })
    const selectedAreaRef = useRef<Coord>({} as Coord)
    const reAssignRef = useRef<Area>()
    const addMappingRef = useRef<HTMLDivElement>(null)
    const mappingContainerRef = useRef<HTMLDivElement>(null)
    const imageRef = useRef<HTMLImageElement>(null)

    const closeAddMapping = () => {
        if (addMappingRef.current) {
            addMappingRef.current.style.display = 'none'
        }
    }
    const dragEndAction = (event: Konva.KonvaEventObject<DragEvent>, selectedArea: Area) => {
        if (addMappingRef.current) {
            closeAddMapping()

            const currentMapping = props.areas.find((area) => area.partNumber === selectedArea.partNumber && area.name === selectedArea.name)
            const newState = props.areas.filter((area) => area.partNumber !== selectedArea.partNumber || area.name !== selectedArea.name)
            if (currentMapping) {
                currentMapping.coords = {
                    x: event.evt.offsetX,
                    y: event.evt.offsetY,
                }
                props.setAreas([...newState, currentMapping])
            }
        }
    }

    const clickAction = (event: Konva.KonvaEventObject<MouseEvent>) => {
        if (event.target.attrs['data-stage'] && props.mappingMode) {
            setMappingDetails({} as IMappingDetailsData)
            if (addMappingRef.current) {
                addMappingRef.current.style.display = 'flex'
                addMappingRef.current.style.left = `${event.evt.offsetX + 10}px`
                addMappingRef.current.style.top = `${event.evt.offsetY + 10}px`

                selectedAreaRef.current = {
                    x: event.evt.offsetX,
                    y: event.evt.offsetY,
                }
            }
        }
    }

    const addMappingData = (part: PartsModel) => {
        if (selectedAreaRef.current) {
            const data = {
                partNumber: part.partNumber,
                name: part.name,
                coords: !reassign ? { ...selectedAreaRef.current } : { ...reAssignRef.current?.coords! },
            }
            if (reAssignRef.current) {
                const newState = props.areas.filter((area) => area.partNumber !== reAssignRef.current?.partNumber || area.name !== reAssignRef.current?.name)
                props.setAreas([...newState, data])
            } else props.setAreas((prevState) => [...prevState, data])

            selectedAreaRef.current = {} as Coord
            if (addMappingRef.current) closeAddMapping()
            setReassign(false)
        }
    }

    const mappingDetailsCloseAction = () => {
        setMappingDetails({} as IMappingDetailsData)
    }

    const deleteMapping = (area: Area) => {
        const newState = props.areas.filter((_area) => _area.partNumber !== area.partNumber || _area.name !== area.name)
        props.setAreas(newState)
        mappingDetailsCloseAction()
    }

    const reassignMapping = (area: Area, event: KonvaEventObject<PointerEvent>) => {
        mappingDetailsCloseAction()
        const activeArea = props.areas.find((_areas) => _areas.partNumber === area.partNumber && _areas.name === area.name)
        if (activeArea) {
            reAssignRef.current = activeArea
        }
        setReassign(true)
        if (addMappingRef.current) {
            addMappingRef.current.style.display = 'flex'
            addMappingRef.current.style.left = `${event.evt.offsetX + 10}px`
            addMappingRef.current.style.top = `${event.evt.offsetY + 10}px`
        }
    }
    useEffect(() => {
        if (props.areas) {
            setCircleRefs((circleRefs) =>
                Array(props.areas.length)
                    .fill(undefined)
                    .map((_, index) => circleRefs[index] || createRef())
            )
        }
    }, [props.areas])

    useEffect(() => {
        closeAddMapping()
        mappingDetailsCloseAction()
    }, [props.image.src])

    useEffect(() => setMappingCircle(props.mappingCircle), [props.mappingCircle])
    return (
        <div className="mapping-container" style={{ position: 'relative' }} ref={mappingContainerRef}>
            <img
                ref={imageRef}
                src={props.image.src}
                alt={props.image.alt}
                onLoad={() => {
                    props.setImageLoading(false)
                    imageRef.current &&
                        setStage({
                            width: imageRef.current?.width,
                            height: imageRef.current?.height,
                        })
                }}
            />
            {stage.width > 0 && stage.height > 0 && (
                <Stage
                    data-stage={true}
                    className={isHoverCursor ? `mapping-cursor ${props.mappingMode && 'add-mapping-cursor'}` : ` ${props.mappingMode && 'add-mapping-cursor'}`}
                    width={stage.width}
                    height={stage.height}
                    onClick={clickAction}
                >
                    <Layer>
                        {props.areas.map((area, index: number) => (
                            <>
                                <Circle
                                    ref={circleRefs[index]}
                                    key={`${area.partNumber}-${area.name}`}
                                    draggable
                                    shadowColor={mappingCircle.background}
                                    x={area.coords.x}
                                    y={area.coords.y}
                                    radius={mappingCircle.size * 10}
                                    fill={mappingCircle.background}
                                    stroke={mappingCircle.background === EMappingCircleColor.WHITE ? EMappingCircleColor.BLACK : mappingCircle.background}
                                    strokeWidth={2}
                                    onDragEnd={(event) => props.mappingMode && dragEndAction(event, area)}
                                    onDragStart={(event) => !props.mappingMode && event.target.stopDrag()}
                                    onPointerClick={(event) => {
                                        //
                                        const part = props.partLists.find((partList) => partList.partNumber === area.partNumber && partList.name === area.name)
                                        if (part) {
                                            setMappingDetails({
                                                event,
                                                area,
                                                part: part,
                                                selectedIndex: index,
                                            })
                                        }
                                        if (addMappingRef.current) closeAddMapping()
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.attrs.shadowBlur = '10'
                                        setIsHoverCursor(true)
                                    }}
                                    onMouseOut={(e) => {
                                        e.target.attrs.shadowBlur = '0'
                                        setIsHoverCursor(false)
                                    }}
                                />
                            </>
                        ))}
                    </Layer>
                </Stage>
            )}

            {mappingDetails && mappingDetails.area && mappingDetails.part && (
                <MappingDetails
                    mappingMode={props.mappingMode}
                    area={mappingDetails?.area}
                    event={mappingDetails?.event!}
                    mappingContainerRef={mappingContainerRef}
                    onClose={mappingDetailsCloseAction}
                    isShow={mappingDetails.selectedIndex! > -1 && !!mappingDetails.event}
                    part={mappingDetails.part}
                    deleteMapping={deleteMapping}
                    reassignMapping={reassignMapping}
                    customEquipment={props.customEquipment}
                    addToCart={props.addToCart}
                    partAmount={partAmount}
                    setPartAmount={setPartAmount}
                    scaleRatio={props.scaleRatio}
                />
            )}
            <AddMapping closeAddMapping={closeAddMapping} addMappingData={addMappingData} addMappingRef={addMappingRef} areas={props.areas} partLists={props.partLists} />
        </div>
    )
}

export default CanvasImage
