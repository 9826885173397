import Icons, { IconType } from '../../../Icon'
import Zoom from 'react-medium-image-zoom'
import './EquipmentDetailsTab.scss'
// import Tags from '../../../components/Tags'
import { EquipmentModel } from '../../../models/New/Equipment'
import Tags from '../../../components/Tags'
import { standardDate } from '../../../utils/helpers'

interface EquipmentDetailsTabProps {
    isEquipment: boolean
    equipment: EquipmentModel
}

function EquipmentDetailsTab(props: EquipmentDetailsTabProps) {
    const zoomWrapper = document.getElementById('zoom-wrapper')

    const warrantyInfo = () => {
        const warrantyDate = props.equipment.warrantyStarts ? standardDate(props.equipment.warrantyStarts) : '-'
        const warrantyEndDate = props.equipment.warrantyEnds ? standardDate(props.equipment.warrantyEnds) : '-'
        // return `Starts: ${warrantyDate.toLocaleDateString('en-US')} - Duration: ${props.equipment.standardWarrantyDuration} ${props.equipment.standardWarrantyDurationUnit}`
        return (
            <>
                <div className="content-line">
                    <div className="content-line-item">
                        <span className="title">STATUS</span>
                        <span className={`value ${props.equipment.warrantyStatus?.toLowerCase()}`}>{props.equipment.warrantyStatus || '-'}</span>
                    </div>
                </div>
                {props.equipment.warrantyStarts && (
                    <>
                        <div className="content-line">
                            <div className="content-line-item">
                                <span className="title">DURATION</span>
                                <span className="value">{props.equipment.warrantyDuration || '-'}</span>
                            </div>
                            <div className="content-line-item">
                                <span className="title">UNIT</span>
                                <span className="value">{props.equipment.warrantyDurationUnit || '-'}</span>
                            </div>
                        </div>
                        <div className="content-line">
                            <div className="content-line-item">
                                <span className="title">START DATE</span>
                                <span className="value">{warrantyDate}</span>
                            </div>
                            <div className="content-line-item">
                                <span className="title">END DATE</span>
                                <span className="value">{warrantyEndDate}</span>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }

    const description = () => {
        const pipeline = props.equipment.hsPipeline ? (props.equipment.hsPipeline === '2016054' ? 'Sales units: 2016054' : 'Lease units: 2021415') : ''
        const coreTechSerial = props.equipment.hsCoreTechSerialNumber ? 'Core tech serial number: ' + props.equipment.hsCoreTechSerialNumber : ''
        const description = props.equipment.description ? props.equipment.description : '-'
        return (
            <>
                {description}
                <p>{pipeline}</p>
                <p>{coreTechSerial}</p>
            </>
        )
    }

    return (
        <div className="customer-equipment-details-tab-container">
            <div className="section section-part-number">
                <Icons className="section-icon" type={IconType.PartNumber} />
                <div className="section-container">
                    <div className="title">PART NUMBER</div>
                    <div className="content">{props.equipment.partNumber}</div>
                </div>
            </div>

            <div className="section section-vendor">
                <Icons className="section-icon" type={IconType.Vendor} />
                <div className="section-container">
                    <div className="title">VENDOR (Record Owner)</div>
                    <div className="content">{props.equipment.manufacturerName}</div>
                </div>
            </div>

            {props.isEquipment && (
                <>
                    <div className="section section-warranty">
                        <Icons className="section-icon" type={IconType.Calendar} />
                        <div className="section-container">
                            <div className="title">WARRANTY INFO</div>
                            <div className="content">{warrantyInfo()}</div>
                        </div>
                    </div>

                    <div className="section section-serial-number">
                        <Icons className="section-icon" type={IconType.SerialNumber} />
                        <div className="section-container">
                            <div className="title">SERIAL NUMBER</div>
                            <div className="content">{props.equipment.serialNo || '-'}</div>
                        </div>
                    </div>

                    {props.equipment.installationAddress && (
                        <div className="section section-installation-address">
                            <Icons className="section-icon" type={IconType.Address} />
                            <div className="section-container">
                                <div className="title">INSTALLATION ADDRESS</div>
                                <div className="content">
                                    <div className="content-line">
                                        <div className="content-line-item">
                                            <span className="title">STREET ADDRESS 1</span>
                                            <span className="value">{props.equipment.installationAddress.street1 || '-'}</span>
                                        </div>
                                    </div>
                                    <div className="content-line">
                                        <div className="content-line-item">
                                            <span className="title">STREET ADDRESS 2</span>
                                            <span className="value">{props.equipment.installationAddress.street2 || '-'}</span>
                                        </div>
                                    </div>
                                    <div className="content-line">
                                        <div className="content-line-item">
                                            <span className="title">CITY</span>
                                            <span className="value">{props.equipment.installationAddress.city || '-'}</span>
                                        </div>
                                        <div className="content-line-item">
                                            <span className="title">STATE</span>
                                            <span className="value">{props.equipment.installationAddress.state || '-'}</span>
                                        </div>
                                    </div>
                                    <div className="content-line">
                                        <div className="content-line-item">
                                            <span className="title">ZIP CODE</span>
                                            <span className="value">{props.equipment.installationAddress.postcode || '-'}</span>
                                        </div>
                                        <div className="content-line-item">
                                            <span className="title">COUNTRY</span>
                                            <span className="value">{props.equipment.installationAddress.country || '-'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <div className="section section-description">
                <Icons className="section-icon" type={IconType.Description} />
                <div className="section-container">
                    <div className="title">DESCRIPTION</div>
                    <div className="content">{description()}</div>
                </div>
            </div>

            <div className="section section-tag">
                <Icons className="section-icon" type={IconType.Tag} />
                <div className="section-container">
                    <div className="title">TAGS</div>
                    <div className="content">
                        <Tags grid="vertical" items={props.equipment.tags || []} />
                    </div>
                </div>
            </div>

            {props.isEquipment && props.equipment.images && props.equipment.images.length > 0 && (
                <div className="section section-images">
                    <Icons className="section-icon" type={IconType.Image} />
                    <div className="section-container">
                        <div className="title">EQUIPMENT IMAGES</div>
                        <div className="content">
                            <div className="image-scroll">
                                <div className="image-container">
                                    {props.equipment.images.map((image, index) => {
                                        return (
                                            image && (
                                                <div key={index} className="image-wrapper">
                                                    <Zoom portalEl={zoomWrapper!}>
                                                        <img className="image-item zoom-image" src={image.url} alt={props.equipment.name} />
                                                    </Zoom>
                                                </div>
                                            )
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EquipmentDetailsTab
