import { RefObject, useContext, useEffect, useRef, useState } from 'react'
import TextArea from '../../../../../components/TextArea'
import { CustomerContactModel, UpdateCustomerContactModel } from '../../../../../models/New/Customer'
import Icons, { IconType } from '../../../../../Icon'
import Checkbox from '../../../../../components/Checkbox'
import SearchInput from '../../../../../components/SearchInput'
import PopoverItem from '../../../../../components/PopoverItem'
import { isValidEmail } from '../../../../../utils/helpers'
import './EmailRecipients.scss'
import Input from '../../../../../components/Input'
import { CheckoutContext } from '../../../../../store/NewCheckoutContext/CheckoutContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ContactService from '../../../../../services/UiService/Contact'
import { toast } from 'react-toastify'

interface IProps {
    contacts: CustomerContactModel[] | undefined
    companyId: string
    onLoading: () => void
    onCompleted: () => void
}
interface ContactModel extends CustomerContactModel {
    isTemporary?: boolean
}
function EmailRecipients(props: IProps) {
    const { recipientsEmails, handleRecipientsEmails } = useContext(CheckoutContext)
    const [selectedEmails, setSelectedEmails] = useState<string[]>(recipientsEmails.emails || [])
    const [contacts, setContacts] = useState<ContactModel[] | undefined>(undefined)
    const [filteredContacts, setFilteredContacts] = useState<ContactModel[]>([])
    const [contactDropdownVisible, setContactDropdownVisible] = useState<boolean>(false)
    const [searchEmail, setSearchEmail] = useState<string>('')
    const popupBoxRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setContacts(props.contacts)
        if (props.contacts) {
            setFilteredContacts(props.contacts)
        }
    }, [props.contacts])

    useEffect(() => {
        document.addEventListener('mousedown', (e: MouseEvent) => {
            if (popupBoxRef.current && !popupBoxRef.current.contains(e.target as Node)) {
                setContactDropdownVisible(false)
            }
        })
    }, [])
    useEffect(() => {
        setSelectedEmails(recipientsEmails.emails || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactDropdownVisible])

    const handleSubmit = async () => {
        await handleRecipientsEmails({ ...recipientsEmails, emails: selectedEmails })
        setContactDropdownVisible(false)
    }

    const onHandleSearch = (value: CustomerContactModel[]) => {
        setFilteredContacts(value)
    }
    const hanldeKeyDown = async (e: React.KeyboardEvent<HTMLImageElement>) => {
        if (e.key === 'Enter' && isValidEmail(searchEmail)) {
            const newContactData = {
                firstName: searchEmail.split('@')[0],
                lastName: searchEmail.split('@')[0],
                email: searchEmail,
                isTemporary: true,
                companyId: props.companyId,
            } as ContactModel
            await setContacts(contacts?.concat(newContactData))
            await setFilteredContacts(filteredContacts?.concat(newContactData))
            setSelectedEmails([...selectedEmails, newContactData.email])
        } /* else not needed */
    }
    async function createContact(contact: UpdateCustomerContactModel) {
        try {
            props.onLoading()
            const response = await ContactService.create(contact)
            const realContact = { ...response, isTemporary: false }
            const newContacts = contacts?.filter((item) => item.email !== contact.email).concat(realContact)
            await setContacts(newContacts)
            await setFilteredContacts(newContacts || [])
            toast.success('Successfully added!')
        } catch (error: any) {
            toast.error(error)
        } finally {
            props.onCompleted()
        }
    }

    return (
        <div className="form">
            <div className="form-line">
                <div ref={popupBoxRef} className="dropdown-container">
                    <div style={{ display: 'flex' }} className="dropdown-input">
                        <div className="dropdown-selected">
                            {contactDropdownVisible ? (
                                <SearchInput
                                    onFocus={() => setContactDropdownVisible(true)}
                                    onKeyDown={hanldeKeyDown}
                                    handleSearchTextValue={(value) => setSearchEmail(value)}
                                    placeHolder="Search or add email recipients"
                                    data={contacts}
                                    handleSearch={onHandleSearch}
                                    searchKeys={['firstName', 'lastName', 'email']}
                                />
                            ) : (
                                <>
                                    <Input
                                        onFocus={() => setContactDropdownVisible(true)}
                                        placeholder="Search or add email recipients"
                                        value={
                                            !recipientsEmails?.emails || recipientsEmails?.emails.length === 0
                                                ? ''
                                                : recipientsEmails?.emails?.length === 1
                                                ? recipientsEmails?.emails[0]
                                                : `${recipientsEmails.emails?.length} Contacts Selected`
                                        }
                                    />
                                    <Icons className="down-icon" type={IconType.ChevronDown} />
                                </>
                            )}
                        </div>
                        {contactDropdownVisible && !(filteredContacts?.length === 0 && isValidEmail(searchEmail)) && (
                            <div onClick={handleSubmit} className="action-button">
                                <PopoverItem popoverContent="Done">
                                    <Icons type={IconType.Check} />
                                </PopoverItem>
                            </div>
                        )}
                    </div>

                    {contactDropdownVisible && (
                        <div className="dropdown-list-container">
                            <div className="action-container">
                                {filteredContacts.length === 0 && (
                                    <div className="search-line">
                                        <span>Not found!</span>
                                    </div>
                                )}
                            </div>
                            <div className="contact-list">
                                {filteredContacts?.map((item) => {
                                    return (
                                        <div className="contact">
                                            <Checkbox
                                                checked={!!selectedEmails.find((contact: string) => contact === item.email)}
                                                onChange={() => {
                                                    if (!!selectedEmails.find((contact: string) => contact === item.email)) {
                                                        setSelectedEmails(selectedEmails.filter((email) => email !== item.email))
                                                    } else {
                                                        setSelectedEmails([...selectedEmails, item.email])
                                                    }
                                                }}
                                            />
                                            <div className="contact-info">
                                                <span className="name">
                                                    {item.firstName} {item.lastName}
                                                </span>
                                                <span className="email">{item.email}</span>
                                            </div>
                                            {item.isTemporary && (
                                                <div onClick={() => createContact(item)} className="add-contact-button">
                                                    <PopoverItem popoverContent="Add as a new contact">
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </PopoverItem>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="form-line">
                <TextArea
                    placeholder="Message to recipients"
                    value={recipientsEmails.note}
                    onChange={(event) => {
                        handleRecipientsEmails({ ...recipientsEmails, note: event.target.value })
                    }}
                />
            </div>
        </div>
    )
}
export default EmailRecipients
