import { Tooltip } from '@mui/material'
import { FC } from 'react'
import CheckBoxDropdownInput from '../../../../../../components/CheckBoxDropdownInput'
import Icons, { IconType } from '../../../../../../Icon'
import { CustomerEquipmentsModel } from '../../../../../../models/New/Customer'
import './style.scss'

interface Props {
    equipmentList: CustomerEquipmentsModel[]
    value: string[]
    onChange: (val: string[]) => void
    showAddButton?: boolean
    disabled?: boolean
}

const ActivityEquipment: FC<Props> = ({ equipmentList, value, onChange, showAddButton = true, disabled = false }) => {
    const setEquipmentInfo = (equipmentId: string | number) => equipmentList.find((child) => child.id === equipmentId)

    const onClickDelete = (equipmentId: string | number) => onChange(value.filter((child) => child !== equipmentId))

    return (
        <div className="activity-equipment-container">
            <CheckBoxDropdownInput
                disabled={equipmentList.length === 0 || disabled}
                selectedItems={value}
                placeholder="SELECT EQUIPMENT RECORDS TO LINK"
                listItems={equipmentList.map((p) => {
                    return {
                        id: p.id,
                        text: p.name,
                        serialNo: p.serialNo,
                    }
                })}
                dropIcon={true}
                onDataChange={(data) => {
                    onChange(data)
                }}
            />
            <div className="equipment-list-container">
                <div className="equipment-list">
                    {value.map((child) => (
                        <div className={`equipment-list-item ${(value.length === 1 || !showAddButton) && 'full-width'}`} key={child}>
                            <div className="list-item-content">
                                <Tooltip title={setEquipmentInfo(child)?.name ?? ''}>
                                    <b>{setEquipmentInfo(child)?.name}</b>
                                </Tooltip>
                                <div className="model-part-number">{setEquipmentInfo(child)?.partNumber}</div>
                            </div>
                            {!disabled && (
                                <div className="close-section">
                                    <Tooltip title="Unlink" placement="left">
                                        <span onClick={() => onClickDelete(child)}>
                                            <Icons type={IconType.Close} />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ActivityEquipment
