import { FC } from 'react'
import { SearchPartsTableColumnModel } from '../../searchParts.types'
import './searchPartsTableHead.scss'

interface SearchPartsTableHeadProps {
    columns: SearchPartsTableColumnModel[]
}

const SearchPartsTableHead: FC<SearchPartsTableHeadProps> = ({ columns }) => {
    return (
        <div className="searchPartsTableHeadContainer">
            {columns?.map((column) => (
                <div className="tableHeadCell" style={{ width: column.width, position: 'sticky' }}>
                    <div className="columnTitle">{column.columnName}</div>
                </div>
            ))}
        </div>
    )
}

export default SearchPartsTableHead
