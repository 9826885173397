import { useEffect, useState } from 'react'
import ProductService from '../../../../../services/UiService/Product'
import { useParams } from 'react-router'
import { CompanyModel } from '../../../../../models/Ui/Company'
import CustomerListTable from '../../../EquipmentDetail/Tables/CustomerListTable'
import { toast } from 'react-toastify'
import Loading from '../../../../../components/Loading'

interface Props {
    visible: boolean
    filteredCustomers: CompanyModel[]
    onSearch: (value: CompanyModel[]) => void
    setCustomers: (value: CompanyModel[]) => void
}

function CustomersTab(props: Props) {
    const { productId } = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const visibleCondition = { display: props.visible ? 'block' : 'none' }

    const getCustomer = async () => {
        try {
            setLoading(true)
            const response = await ProductService.getProductCustomers(productId!)
            props.setCustomers(response)
        } catch (error: any) {
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.visible) getCustomer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible])

    return (
        <div style={visibleCondition}>
            {loading && <Loading />}
            <CustomerListTable
                cloneEquipment={() => {}}
                navigate={'/customer/'}
                data={props.filteredCustomers.map((p) => {
                    return {
                        name: p.name,
                        id: p.id,
                        email: p.email,
                    }
                })}
            />
        </div>
    )
}

export default CustomersTab
