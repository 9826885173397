import Modal from '../../Modal'
import './style.scss'

interface NoDataProp {
    visible: boolean
    onClickClose: () => void
    onClickCancel: () => void
    onClickSave: () => void
}
function SaveConfirmationModal(props: NoDataProp) {
    return (
        <Modal
            className="save-confirmation-modal"
            visible={props.visible}
            title="Save changes?"
            primaryButtonText="Save and close"
            secondaryButtonText="Exit without saving"
            tertiaryButtonText="Cancel"
            onClickSecondary={props.onClickClose}
            onClickTertiary={props.onClickCancel}
            onClickPrimary={props.onClickSave}
        >
            <span>Any unsaved changes will be lost. Do you want to save changes before leaving?</span>
        </Modal>
    )
}

export default SaveConfirmationModal
