import { useState } from "react"
import Checkbox from "../../../../components/Checkbox"
import ChipButton from "../../../../components/ChipButton"
import HoverCard from "../../../../components/HoverCard"
import PopoverItem from "../../../../components/PopoverItem"
import { CompanyModel } from "../../../../models/Ui/Company"
import { CompanyType } from "../../../../models/Ui/CompanyType"
import './style.scss'

interface Props {
    company: CompanyModel
}

function PaymentTab(props: React.PropsWithChildren<Props>) {
    const [billingMethodChecked, setBillingMethodChecked] = useState<boolean>(true)

    return (
        <div className='payment-content-container'>
            <HoverCard title='Setup Customer Payment Methods' justifyTitleContent='center'>
                <div className='payment-tab-container'>
                    <div className='section'>
                        <div className='section-container'>
                            Enable the payment methods that you would like to allow for your customers. You can customize this later per customer.
                            <div className='content-row'>
                                <Checkbox disabled onChange={() => { }} />
                                <PopoverItem popoverContent={'Coming soon!'}>
                                    <span className='gray-out-text'>Credit card</span>
                                </PopoverItem>
                            </div>
                            <div className='content-row'>
                                <Checkbox disabled onChange={() => { }} />
                                <PopoverItem popoverContent={'Coming soon!'}>
                                    <span className='gray-out-text'>ACH debit</span>
                                </PopoverItem>
                            </div>
                            <div className='content-row'>
                                <Checkbox disabled onChange={() => { }} />
                                <PopoverItem popoverContent={'Coming soon!'}>
                                    <span className='gray-out-text'>Wire transfer</span>
                                </PopoverItem>
                            </div>

                            <div className='content-row'>
                                <Checkbox
                                    disabled={true}
                                    checked={billingMethodChecked}
                                    onChange={
                                        () => setBillingMethodChecked(!billingMethodChecked)
                                    }
                                />
                                Billing through purchase orders
                            </div>
                        </div>
                    </div>
                </div>
            </HoverCard>
            {
                props.company.type === CompanyType.Manufacturer &&
                <HoverCard title='Receiving Payments' justifyTitleContent='center'>
                    <div className='payment-tab-container'>
                        <div className='section'>
                            <div className='section-container'>
                                This bank account is where you will receive payments sent by your customers.
                                <div className='center'>
                                    <ChipButton>Coming soon!</ChipButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </HoverCard>
            }

            {
                props.company.type === CompanyType.Customer &&
                <HoverCard title='Payment Methods' justifyTitleContent='center'>
                    <div className='payment-tab-container'>
                        <div className='section'>
                            <div className='section-container'>
                                <div className='center'>
                                    <ChipButton>Coming soon!</ChipButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </HoverCard>
            }
        </div>
    )
}

export default PaymentTab