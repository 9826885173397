import React, { useEffect, useState } from 'react'
import Modal from '../../../../components/Modal'
import { CreateProductModel, ProductImageModel } from '../../../../models/New/Product'
import Input from '../../../../components/Input'
import './AddProductModal.scss'
import FileInput from '../../../../components/FileInput'
import { FileInputType } from '../../../../components/FileInput/enum'
import StorageService, { FileModel } from '../../../../services/NewServices/StorageService'
import { toast } from 'react-toastify'
import Icons, { IconType } from '../../../../Icon'
import { DocumentListModel } from '../../../../models/New/Document'
import ProductService from '../../../../services/UiService/Product'
import { ImageList, ImageListItem } from '@mui/material'
import { Color } from '../../../../models/enums'
import LinkedDocuments from '../../ProductDetail/Modals/CreateDocument/Components/LinkedDocuments'
import DocumentService from '../../../../services/UiService/Document'
import TextArea from './../../../../components/TextArea/index'
import CurrencyInput from '../../../../components/CurrencyInput'

interface Props {
    visible: boolean
    createProduct?: (product: CreateProductModel) => void
    closeModal: () => void
    onError: (error: any) => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: () => void
}

const AddProductModal = (props: Props) => {
    const uploadFileServices = new StorageService()
    const [productName, setProductName] = useState<string>('')
    const [brandName, setBrandName] = useState<string>('')
    const [partNumber, setPartNumber] = useState<string>('')
    const [productCategory, setProductCategory] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [uom, setUom] = useState<string>('')
    const [pkg, setPkg] = useState<string>('')
    const [unitPrice, setUnitPrice] = useState<number>()
    const [images, setImages] = useState<FileModel[]>()
    const [productImages, setProductImages] = useState<ProductImageModel[] | undefined>([])
    const [documents, setDocuments] = useState<DocumentListModel[]>()
    const [selectedLinkedDocuments, setSelectedLinkedDocuments] = useState<DocumentListModel[]>([])
    const onUploadChange = async (uploadFiles: FileList) => {
        try {
            const uploadedFiles = await uploadFileServices.uploadMultiple(uploadFiles)
            const tempProductImages: ProductImageModel[] = uploadedFiles.map((file) => ({ imageUrl: file.url, name: file.name, isFeatured: false }))
            setImages((prevState) => [...(images ?? []), ...uploadedFiles])
            setProductImages(productImages?.concat(tempProductImages))
        } catch (error: any) {
            toast.error(error)
        }
    }
    const clearForm = () => {
        setProductName('')
        setBrandName('')
        setPartNumber('')
        setProductCategory('')
        setDescription('')
        setUom('')
        setPkg('')
        setUnitPrice(0)
        setImages([])
        setDocuments([])
    }
    const createProductHandler = async (addAnother: boolean) => {
        const productImages: ProductImageModel[] = images
            ? images?.map((image) => ({
                  imageUrl: image.url,
                  name: image.name,
                  isFeatured: false,
              }))
            : ([] as ProductImageModel[])

        try {
            props.onLoading()
            const payload: CreateProductModel = {
                name: productName,
                brand: brandName,
                partNumber: partNumber,
                category: productCategory,
                description: description,
                uom: uom,
                pkg: pkg,
                price: unitPrice,
                images: productImages,
                documentIds: selectedLinkedDocuments.map((document) => document.id),
            }
            await ProductService.createProduct(payload)
            props.onSuccess()
        } catch (error: any) {
            props.onError(error)
        } finally {
            clearForm()
            props.onCompleted()
            if (!addAnother) props.closeModal()
        }
    }
    const toggleIsFeatured = (imageUrl: string, isFeatured: boolean) => {
        const data = productImages?.map((productImage: ProductImageModel) => {
            if (isFeatured) {
                if (productImage.imageUrl === imageUrl) return { ...productImage, isFeatured }
                else return { ...productImage, isFeatured: !isFeatured }
            }
            return { ...productImage, isFeatured: false }
        })
        setProductImages(data)
    }

    const getDocuments = async () => {
        const documents = await DocumentService.getDocuments({ page: 0, size: 10000 })
        setDocuments(documents.content)
    }

    useEffect(() => {
        getDocuments()
    }, [])

    return (
        <div>
            <Modal
                className="create-product-modal"
                visible={props.visible}
                title="Add new product"
                primaryButtonText="Save and close"
                primaryDisabled={!productName || !partNumber}
                onClickPrimary={() => createProductHandler(false)}
                secondaryButtonText="Save and add another"
                onClickSecondary={() => createProductHandler(true)}
                secondaryDisabled={!productName || !partNumber}
                tertiaryButtonText="Close"
                onClickTertiary={props.closeModal}
                isProductModal={true}
            >
                <div className="modal-section">
                    <div className="modal-section__line">
                        <Input value={productName} onChange={(e) => setProductName(e.target.value)} placeholder="PRODUCT NAME" required />
                    </div>
                    <div className="modal-section__line">
                        <Input value={brandName} onChange={(e) => setBrandName(e.target.value)} placeholder="BRAND" />
                        <Input value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="MFG PART NUMBER" required />
                    </div>
                    <div className="modal-section__line">
                        <Input value={productCategory} onChange={(e) => setProductCategory(e.target.value)} placeholder="PRODUCT CATEGORY" />
                    </div>
                    <div className="modal-section__line">
                        <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" />
                    </div>
                    <div className="modal-section__line">
                        <Input value={uom} onChange={(e) => setUom(e.target.value)} placeholder="UOM" />
                        <Input value={pkg} onChange={(e) => setPkg(e.target.value)} placeholder="PKG" />
                        <CurrencyInput
                            placeholder="UNIT PRICE"
                            value={unitPrice}
                            onChange={(price) => {
                                setUnitPrice(price)
                            }}
                        />
                    </div>
                </div>
                <div className="modal-section">
                    <h5 className="modal-section__image-title">Product Images</h5>
                    <ImageList cols={6}>
                        {productImages?.map((productImage, index: number) => (
                            <ImageListItem className="product-image" key={index}>
                                <span className="delete">
                                    <Icons type={IconType.Trash} color={Color.grey600} />
                                </span>
                                <img src={productImage.imageUrl} alt={productImage.name} />
                                <span className="star">
                                    {!productImage.isFeatured ? (
                                        <Icons type={IconType.StarBorder} color={Color.grey600} onClick={() => toggleIsFeatured(productImage.imageUrl, true)} />
                                    ) : (
                                        <Icons type={IconType.Star} color={Color.yellow200} onClick={() => toggleIsFeatured(productImage.imageUrl, false)} />
                                    )}
                                </span>
                            </ImageListItem>
                        ))}
                        <FileInput
                            fileInputText="PRODUCTS"
                            files={images}
                            handleChange={(e) => onUploadChange(e)}
                            fileTypes={['jpg', 'png', 'jpeg']}
                            multiple={true}
                            inputType={FileInputType.NEW_PRODUCT_UPLOAD}
                        />
                    </ImageList>
                </div>
                <div className="modal-section modal-section__document">
                    <div className="modal-section__header">
                        <h5 className="modal-section__image-title">Linked Documents</h5>
                        <Icons type={IconType.BoldPlus} />
                    </div>
                    <LinkedDocuments documents={documents} selectedDocuments={selectedLinkedDocuments} onChange={(e) => setSelectedLinkedDocuments(e)} />
                </div>
            </Modal>
        </div>
    )
}

export default AddProductModal
