import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { arraySort } from '../../../../utils/helpers'
import Icons, { IconType } from '../../../../Icon'
import './style.scss'
import Icon from '../../../../Icon'
import Button from '../../../../components/Button'
import NoDataInfo from '../../../../components/NoDataInfo'
import dayjs from 'dayjs'
import { Tooltip } from '@mui/material'

interface Data {
    title: string
    name: string
    status: string
    dueDate: string
    id: string
    buttons: any
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    sortKey?: string
    data: Data[]
    selectedId: string
    onClick: (id: string) => void
    onClickBackToAllActivities?: () => void
}

const columns: HeadCell[] = [
    {
        id: 'title',
        label: 'Title',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'dueDate',
        label: 'Due Date',
    },
    {
        id: 'buttons',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    const { disable, active, direction, children, onclick } = props

    return !disable ? (
        <div className="sorting-menu">{children}</div>
    ) : (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            {disable && <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const { orderBy, setOrderBy, direction, setDirection } = tableProps

    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'buttons'}
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function ActivityListTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState(props.sortKey || 'name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />
                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index) => {
                                return (
                                    <TableRow onClick={() => props.onClick(row.id)} className={props.selectedId === row.id ? 'active-task' : ''} hover tabIndex={-1} key={row.id}>
                                        <TableCell style={{ width: '45%' }} className="font-bold" align="left">
                                            <div className="name-info-container">
                                                <Tooltip title={row.title}>
                                                    <span className="table-title">{row.title}</span>
                                                </Tooltip>
                                                <div className="sub-name">{row.name}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: '20%' }} align="left">
                                            <div className={`activity-status ${row.status}`}>
                                                {row.status}
                                                {row.status === 'Closed' && (
                                                    <Tooltip title="Closed tasks are automatically locked and stored as historical records.">
                                                        <span>
                                                            <Icons type={IconType.Lock} />
                                                        </span>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: '20%' }} align="left">
                                            <div
                                                className={`date-info ${dayjs(row.dueDate?.toString()) < dayjs() && row.status !== 'Closed' && 'red'}`}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-around',
                                                    columnGap: '0.5rem',
                                                }}
                                            >
                                                <span className="date-info-container">{row.dueDate}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="action-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                {row.buttons}{' '}
                                                {props.selectedId === row.id && (
                                                    <Button className="chevron-right">
                                                        <Icon type={IconType.ChevronRight} />
                                                    </Button>
                                                )}{' '}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="bottomOfTable">
                {props.onClickBackToAllActivities && (
                    <div className="backToAll" onClick={props.onClickBackToAllActivities}>
                        <Icon type={IconType.ArrowLeft} />
                        Back to all activities
                    </div>
                )}
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={props.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    )
}
