import { MonitorApi } from '../../api'
import { PaginationInputModel, PaginationResponseModel } from '../../models/BaseModel'
import NotificationModel, { NotificationCountResponseModel } from '../../models/Ui/Notification'

export default class NotificationService {
    async gets(params: PaginationInputModel): Promise<PaginationResponseModel<NotificationModel>> {
        const response = await MonitorApi.get('/notification', { params })
        return response as any
    }

    async delete(id: string): Promise<PaginationResponseModel<boolean>> {
        const response = await MonitorApi.delete('/notification/' + id, { params: { id } })
        return response as any
    }

    async markAsRead(id: string, isRead: boolean): Promise<PaginationResponseModel<boolean>> {
        const response = await MonitorApi.post(`/notification/${id}/set-is-read/`, { isRead }, {})
        return response as any
    }

    async markAsAllRead(): Promise<PaginationResponseModel<boolean>> {
        const response = await MonitorApi.post('/notification/set-all-as-read', {}, {})
        return response as any
    }

    async getUnreadCount(): Promise<NotificationCountResponseModel> {
        const response = await MonitorApi.get('/notification/unread-count', {})
        return response as any
    }
}
