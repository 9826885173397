import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icons, { IconType } from '../../../../Icon'
import { arraySort, isAbbreviation } from '../../../../utils/helpers'
import CircleIconButton from '../../../../components/CircleIconButton'
import { UserModel } from '../../../../models/Ui/User'
import './style.scss'
import Icon from '../../../../Icon'
import PopoverItem from '../../../../components/PopoverItem'
import NoDataInfo from '../../../../components/NoDataInfo'

interface Data {
    id: string
    name: string
    jobTitle: string
    role: string
    isQuoteRequestReceiver: boolean
    status: string
    isPending: boolean
}

interface HeadCell {
    id: keyof Data | any
    label: string
}

interface Props {
    data: Data[]
    handleResendVerification: (userId: string) => void
    onClickEditTeamMember: (teamMemberId: string) => void
    onClickDeleteTeamMember: (teamMemberId: string) => void
}

const columns: HeadCell[] = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'jobTitle',
        label: 'Job Container / Position',
    },
    {
        id: 'role',
        label: 'Role',
    },
    {
        id: 'status',
        label: 'Status',
    },
    {
        id: 'isQuoteRequestReceiver',
        label: 'Receives Quotes',
    },
    {
        id: 'buttons',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    const { active, direction, children, onclick, disable } = props

    return !disable ? (
        <div className="sorting-menu">{children}</div>
    ) : (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            {disable && <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const { orderBy, setOrderBy, direction, setDirection } = tableProps

    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'} width={headCell.id === 'button' ? '100px' : 'auto'}>
                        <SortingMenu
                            disable={headCell.id !== 'buttons' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const getButtons = (user: UserModel) => {
        const buttons: {
            icon: IconType
            tooltip?: string
            type: string
            onClick: () => void
        }[] = []

        buttons.push({
            icon: IconType.Pen,
            tooltip: 'Edit team member',
            type: 'edit',
            onClick: () => {
                props.onClickEditTeamMember(user.id)
            },
        })

        buttons.push({
            icon: IconType.NewTrash,
            tooltip: 'Delete team member',
            type: 'delete',
            onClick: () => {
                props.onClickDeleteTeamMember(user.id)
            },
        })

        return buttons
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container team-member-table" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell className="font-bold" align="left">
                                            <span className="ellipsis-text">{row.name}</span>
                                            {isAbbreviation(row.name.length) && <div className="table-hover">{row.name}</div>}
                                        </TableCell>
                                        <TableCell align="left">{row.jobTitle}</TableCell>
                                        <TableCell align="left">{row.role}</TableCell>
                                        <TableCell style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {' '}
                                            {row.status}
                                            {row.isPending && (
                                                <div style={{ marginRight: '1rem' }}>
                                                    <PopoverItem key={index} popoverContent={'Resend Mail'}>
                                                        <Icon onClick={() => props.handleResendVerification(row.id)} color="#fffff" type={IconType.Reset} />
                                                    </PopoverItem>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell align="left">{row.isQuoteRequestReceiver ? 'Yes' : 'No'}</TableCell>
                                        <TableCell align="right">
                                            <div style={{ marginRight: '10px' }}>
                                                {getButtons(row).map((button, index) => {
                                                    return (
                                                        <CircleIconButton
                                                            disabled={button.type === 'delete' ? row.disabled : false}
                                                            key={index}
                                                            tooltip={button.tooltip}
                                                            icon={button.icon}
                                                            onClick={button.onClick}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
