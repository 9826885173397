import React from 'react'
import Button, { ButtonType } from '../../Button'
import Icons, { IconType } from '../../../Icon'
import { Color } from '../../../models/enums'
import './style.scss'
import PopoverItem from '../../PopoverItem'

interface Props {
    isCreate: boolean
    visible: boolean
    onClickButton: () => void
}

function TreeViewButton(props: React.PropsWithChildren<Props>) {
    const { isCreate, visible, onClickButton } = props
    return (
        <div className="new-tree-view-button">
            <PopoverItem popoverContent={isCreate ? 'Add sub-assembly' : !visible ? 'View sub-assemblies' : 'Hide sub-assemblies'}>
                <Button className={`${visible ? 'checked' : ''}`} type={visible ? ButtonType.Secondary : ButtonType.Tertiary} onClick={() => onClickButton()}>
                    <Icons type={IconType.Treeview} color={Color.blue900} />
                    <Icons type={isCreate ? IconType.BoldPlus : visible ? IconType.ChevronUp : IconType.ChevronDown} color={Color.blue900} />
                </Button>
            </PopoverItem>
        </div>
    )
}

export default TreeViewButton
