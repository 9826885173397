export enum Size {
    sm = 'sm',
    md = 'md',
    lg = 'lg',
}

export enum Color {
    white = '#fff',
    yellow200 = '#E5AB00',
    yellow300 = '#FDC00E',
    yellow500 = '#FFEFC3',
    yellow800 = '#FEDF87',
    yellow900 = '#FDD835',
    green900 = '#689F38',
    green100 = '#459B68',
    light500 = '#C4C4C4',
    grey100 = '#E7E7E7',
    grey400 = '#9E9E9E',
    grey600 = '#767676',
    blue50 = '#A2ADBB',
    blue100 = '#F5F6F7',
    blue800 = '#68737F',
    blue900 = '#354A63',
    red500 = '#D84315',
    gray = '#E5E5E5',
}
export enum MaxCharacter {
    name = 50,
    email = 100,
    phone = 25,
    companyName = 250,
    address = 100,
    subAddress = 50,
    zipCode = 10,
    max50 = 50,
}
