import { FC, Fragment, useContext } from 'react'
import { toast } from 'react-toastify'
import Modal from '../../components/Modal'
import { ConfirmationContext } from '../../store/ConfirmationContext'
import './confirmationModal.scss'
import { Divider } from '@mui/material'

const ConfirmationModal: FC = () => {
    const { confirmation } = useContext(ConfirmationContext)

    const onSubmit = () => {
        confirmation.func(
            (successMessage?: string, showSuccessMessage = true) => {
                if (showSuccessMessage) {
                    toast.success(successMessage || confirmation.successText)
                }
                confirmation.onClose()
            },
            (error) => {
                toast.error((error as Error).message)
            }
        )
    }

    const onClickPrimary = () => {
        if (confirmation.doesPrimaryButtonSubmit) {
            onSubmit()
        } else {
            if (confirmation.secondaryFunc) {
                confirmation.secondaryFunc(() => {
                    confirmation.onClose()
                })
            } else {
                confirmation.onClose()
            }
        }
    }

    const onClickSecondary = () => {
        if (!confirmation.doesPrimaryButtonSubmit) {
            onSubmit()
        } else {
            if (confirmation.secondaryFunc) {
                confirmation.secondaryFunc(() => {
                    confirmation.onClose()
                })
            } else {
                confirmation.onClose()
            }
        }
    }

    return (
        <Modal
            className={`${confirmation.modalClassName} confirmationModal`}
            visible={confirmation.show}
            title={confirmation.modalTitle}
            titleColor={confirmation.titleColor}
            primaryButtonText={confirmation.primaryButtonText}
            onClickPrimary={onClickPrimary}
            secondaryButtonText={confirmation.secondaryButtonText}
            onClickSecondary={onClickSecondary}
            buttonOrder={confirmation.buttonOrder}
        >
            {confirmation.contentNode ? <Fragment>{confirmation.contentNode}</Fragment> : confirmation.contentText ? <div className="modal-line">{confirmation.contentText}</div> : <Fragment />}
            {confirmation.endLine && <Divider className="confirmation-modal-divider" />}
        </Modal>
    )
}

export default ConfirmationModal
