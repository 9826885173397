import { FC, useContext } from 'react'
import Button from '../../../../../components/Button'
import Input from '../../../../../components/Input'
import Icons, { IconType } from '../../../../../Icon'
import { Size } from '../../../../../models/enums'
import './searchEngine.scss'
import { SearchPartContext } from '../../../../../store/SearchPartContext'

const SearchEngine: FC = () => {
    const ctx = useContext(SearchPartContext)

    const showNoResult = () => ctx.isEmptyResult

    const search = () => {
        ctx.handleSearch()
    }

    return (
        <div className="searchEngineContainer">
            {showNoResult() && ctx.isSearchMode && (
                <div className="noResult">
                    <div>{`We didn't find anything for "${ctx.lastSearchedText}".`}</div>
                    <div>Try taking away or adding spaces or</div>
                    <div>{`try searching for ${'partial name or part number'}`}</div>
                </div>
            )}
            <div className="seachEngineContent">
                <Input
                    startIcon={ctx.isSearchMode ? IconType.Search : undefined}
                    startIconColor="#767676"
                    value={ctx.isSearchMode ? ctx.searchedText : ctx.filterText}
                    onChange={(e) => {
                        if (ctx.isSearchMode) ctx.handleSearchText(e.target.value)
                        else ctx.handleFilterText(e.target.value)
                    }}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter' && ctx.searchedText && ctx.searchedText.length > 2) {
                            if (ctx.isSearchMode) search()
                            else ctx.handleFilter(ctx.filterText)
                        }
                    }}
                    placeholder={ctx.isSearchMode ? 'SEARCH' : 'FILTER SEARCH RESULTS'}
                    size={Size.lg}
                    onClickEndIcon={() => {
                        if (ctx.isSearchMode) ctx.handleSearchText('')
                        else ctx.handleFilterText('')
                    }}
                    endIcon={ctx.filterText.length > 0 ? IconType.Close : undefined}
                />
                {ctx.isSearchMode && (
                    <Button disabled={ctx.searchedText.length < 3} className={ctx.isSearchMode ? 'searchButton' : 'goBackButton'} onClick={search}>
                        {'Search'}
                    </Button>
                )}
            </div>
            {ctx.isSearchMode && (
                <div className="searchEngineNote">
                    <Icons type={IconType.Question} />3 characters minimum
                </div>
            )}
        </div>
    )
}

export default SearchEngine
