export enum IconType {
    Cart,
    Trash,
    Plus,
    Minus,
    Attach,
    CreditCard,
    Btp,
    AddTag,
    Rfq,
    Debit,
    Equipment,
    Activities,
    ChevronUp,
    ChevronDown,
    ChevronRight,
    Parts,
    Customers,
    Product,
    Document,
    SubAssembly,
    Magnifier,
    Reset,
    Show,
    Hide,
    Download,
    Duplicate,
    PlusCircle,
    Pen,
    Link,
    Unlink,
    NewTrash,
    Folder,
    OrderBag,
    MessageDollar,
    Info,
    Close,
    AddCircle,
    YellowAddCircle,
    Image,
    PartNumber,
    Vendor,
    Description,
    Tag,
    TagClose,
    StarBorder,
    Star,
    SerialNumber,
    Address,
    Drawing,
    List,
    UploadCloud,
    Upload,
    AddCustomer,
    RemoveCustomer,
    Share,
    UnShare,
    Bell,
    Treeview,
    Copy,
    Company,
    Phone,
    Mail,
    UpArrow,
    CardView,
    Menus,
    Cog,
    Handbag,
    ManageAccount,
    Team,
    Payments,
    Addresses,
    Brand,
    Calendar,
    Customize,
    Warranty,
    AddNote,
    Chat,
    BoldPlus,
    UserReverse,
    GoToPage,
    GoToShopping,
    GoToLink,
    Check,
    Question,
    ReOrder,
    Support,
    Left,
    UserFriends,
    Flask,
    UnReadNotification,
    ReadNotification,
    AddDescription,
    AddImage,
    WriteNote,
    Config,
    Search,
    Type,
    Maintenance,
    Ticket,
    Replenishment,
    ThreeShape,
    DottedCircle,
    FilterSettings,
    ManufacturerLogo,
    Checklist,
    AddToCart,
    SendReminder,
    CheckCircle,
    HistoricalLog,
    PlusInCircle,
    ArrowLeft,
    Lock,
    Transactions,
    ImageViewArrow,
    Contract,
    AddSubEquipment,
    Recycle,
    People,
    DownloadCircelar,
    HortizalRow,
    TreeViewSub,
    EditHotspot,
    GreenTick,
    RigthOk,
}

interface Props {
    type: IconType
    color?: string
    className?: string
    onClick?: () => void
    empty?: boolean
}

function Icons(props: Props) {
    const color = props.color ? props.color : '#68737F'
    switch (props.type) {
        case IconType.DownloadCircelar:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.33398 10.2077C3.59987 11.1999 4.18575 12.0768 5.00075 12.7022C5.81576 13.3275 6.81435 13.6665 7.84164 13.6665C8.86893 13.6665 9.86752 13.3275 10.6825 12.7022C11.4975 12.0768 12.0834 11.1999 12.3493 10.2077M4.50831 6.33317L7.84164 8.99984L11.175 6.33317M7.84164 2.6665V8.6665"
                        stroke="black"
                    />
                </svg>
            )

        case IconType.People:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.00016 1.3335C4.32016 1.3335 1.3335 4.32016 1.3335 8.00016C1.3335 11.6802 4.32016 14.6668 8.00016 14.6668C11.6802 14.6668 14.6668 11.6802 14.6668 8.00016C14.6668 4.32016 11.6802 1.3335 8.00016 1.3335ZM8.00016 4.00016C9.28683 4.00016 10.3335 5.04683 10.3335 6.3335C10.3335 7.62016 9.28683 8.66683 8.00016 8.66683C6.7135 8.66683 5.66683 7.62016 5.66683 6.3335C5.66683 5.04683 6.7135 4.00016 8.00016 4.00016ZM8.00016 13.3335C6.64683 13.3335 5.04683 12.7868 3.90683 11.4135C5.0335 10.5335 6.4535 10.0002 8.00016 10.0002C9.54683 10.0002 10.9668 10.5335 12.0935 11.4135C10.9535 12.7868 9.3535 13.3335 8.00016 13.3335Z"
                        fill="#A2ADBB"
                    />
                </svg>
            )

        case IconType.Recycle:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3.73366 7.99984C3.73366 6.72854 4.27766 5.58819 5.13099 4.79158L5.94699 5.62638C6.11233 5.79553 6.40033 5.67549 6.40033 5.42996V3.08925C6.40033 2.93647 6.28299 2.81644 6.13366 2.81644H3.84566C3.60566 2.81644 3.48833 3.11107 3.65899 3.28021L4.37366 4.0168C3.32833 5.00983 2.66699 6.42299 2.66699 7.99984C2.66699 10.5915 4.43766 12.7631 6.80566 13.3196C7.14166 13.396 7.46699 13.1396 7.46699 12.7849C7.46699 12.5285 7.29099 12.3102 7.04566 12.2502C5.15233 11.8083 3.73366 10.0732 3.73366 7.99984ZM13.3337 7.99984C13.3337 5.40814 11.563 3.23656 9.19499 2.68003C8.85899 2.60364 8.53366 2.86009 8.53366 3.21474C8.53366 3.47118 8.70966 3.68943 8.95499 3.74945C10.8483 4.1914 12.267 5.92648 12.267 7.99984C12.267 9.27113 11.723 10.4115 10.8697 11.2081L10.0537 10.3733C9.88833 10.2041 9.60033 10.3242 9.60033 10.5697V12.9104C9.60033 13.0632 9.71766 13.1832 9.86699 13.1832H12.155C12.395 13.1832 12.5123 12.8886 12.3417 12.7195L11.627 11.9829C12.6723 10.9898 13.3337 9.57668 13.3337 7.99984Z"
                        fill="#354A63"
                    />
                </svg>
            )

        case IconType.ImageViewArrow:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14 5.72667V2.66667C14 2.3 13.7 2 13.3333 2H10.2733C9.68 2 9.38 2.72 9.8 3.14L10.86 4.2L4.19333 10.8667L3.13333 9.80667C2.72 9.38667 2 9.68 2 10.2733V13.3333C2 13.7 2.3 14 2.66667 14H5.72667C6.32 14 6.62 13.28 6.2 12.86L5.14 11.8L11.8067 5.13333L12.8667 6.19333C13.28 6.61333 14 6.32 14 5.72667Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.Transactions:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.8333 7.4987C11.2917 7.4987 11.6667 7.1237 11.6667 6.66536V4.9987H13.3333C13.7917 4.9987 14.1667 4.6237 14.1667 4.16536C14.1667 3.70703 13.7917 3.33203 13.3333 3.33203H11.6667V1.66536C11.6667 1.20703 11.2917 0.832031 10.8333 0.832031C10.375 0.832031 10 1.20703 10 1.66536V3.33203H8.33333C7.875 3.33203 7.5 3.70703 7.5 4.16536C7.5 4.6237 7.875 4.9987 8.33333 4.9987H10V6.66536C10 7.1237 10.375 7.4987 10.8333 7.4987Z"
                        fill="#C4C4C4"
                    />
                    <path
                        d="M6.75435 15.0013C5.82158 15.0013 5.06688 15.7513 5.06688 16.668C5.06688 17.5846 5.82158 18.3346 6.75435 18.3346C7.68712 18.3346 8.4503 17.5846 8.4503 16.668C8.4503 15.7513 7.68712 15.0013 6.75435 15.0013ZM15.2341 15.0013C14.3013 15.0013 13.5466 15.7513 13.5466 16.668C13.5466 17.5846 14.3013 18.3346 15.2341 18.3346C16.1669 18.3346 16.93 17.5846 16.93 16.668C16.93 15.7513 16.1669 15.0013 15.2341 15.0013ZM7.68712 10.8346H14.0045C14.6405 10.8346 15.2002 10.493 15.4885 9.9763L18.2359 4.85964C18.4479 4.45964 18.3038 3.95964 17.8967 3.74297C17.4812 3.51797 16.964 3.6763 16.7435 4.08464L14.0045 9.16797H8.05175L4.43938 1.66797H2.51448C2.04809 1.66797 1.6665 2.04297 1.6665 2.5013C1.6665 2.95964 2.04809 3.33464 2.51448 3.33464H3.36245L6.41516 9.65964L5.27039 11.693C4.65137 12.8096 5.46543 14.168 6.75435 14.168H16.0821C16.5484 14.168 16.93 13.793 16.93 13.3346C16.93 12.8763 16.5484 12.5013 16.0821 12.5013H6.75435L7.68712 10.8346Z"
                        fill="#C4C4C4"
                    />
                </svg>
            )

        case IconType.Lock:
            return (
                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.33333 4.66667H8.66667V3.33333C8.66667 1.49333 7.17333 0 5.33333 0C3.49333 0 2 1.49333 2 3.33333V4.66667H1.33333C0.6 4.66667 0 5.26667 0 6V12.6667C0 13.4 0.6 14 1.33333 14H9.33333C10.0667 14 10.6667 13.4 10.6667 12.6667V6C10.6667 5.26667 10.0667 4.66667 9.33333 4.66667ZM5.33333 10.6667C4.6 10.6667 4 10.0667 4 9.33333C4 8.6 4.6 8 5.33333 8C6.06667 8 6.66667 8.6 6.66667 9.33333C6.66667 10.0667 6.06667 10.6667 5.33333 10.6667ZM3.33333 4.66667V3.33333C3.33333 2.22667 4.22667 1.33333 5.33333 1.33333C6.44 1.33333 7.33333 2.22667 7.33333 3.33333V4.66667H3.33333Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.ArrowLeft:
            return (
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.18821 6.14929V7.28699C4.18821 7.92381 3.50208 8.23865 3.10185 7.78786L0.185825 4.50357C-0.0619417 4.22451 -0.0619416 3.77372 0.185825 3.49467L3.10185 0.210375C3.50208 -0.24041 4.18821 0.0815788 4.18821 0.718402L4.18821 1.8561L7.3647 1.8561C7.71412 1.8561 8 2.17809 8 2.57163V5.43376C8 5.8273 7.71412 6.14929 7.3647 6.14929H4.18821Z"
                        fill="#68737F"
                    />
                </svg>
            )

        case IconType.Checklist:
            return (
                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.3329 3.66668C16.3329 3.20835 15.9579 2.83335 15.4996 2.83335H9.66628C9.20794 2.83335 8.83294 3.20835 8.83294 3.66668C8.83294 4.12502 9.20794 4.50002 9.66628 4.50002H15.4996C15.9579 4.50002 16.3329 4.12502 16.3329 3.66668ZM8.83294 10.3333C8.83294 10.7917 9.20794 11.1667 9.66628 11.1667H15.4996C15.9579 11.1667 16.3329 10.7917 16.3329 10.3333C16.3329 9.87502 15.9579 9.50002 15.4996 9.50002H9.66628C9.20794 9.50002 8.83294 9.87502 8.83294 10.3333ZM6.72461 0.85835C7.04961 1.18335 7.04961 1.70835 6.72461 2.03335L3.19961 5.57502C2.87461 5.90002 2.34961 5.90002 2.01628 5.57502L0.249609 3.80002C-0.0753906 3.47502 -0.0753906 2.95002 0.249609 2.62502C0.574609 2.30002 1.09961 2.30002 1.42461 2.62502L2.60794 3.80835L5.55794 0.85835C5.87461 0.541683 6.40794 0.541683 6.72461 0.85835ZM6.73294 7.53335C7.05794 7.85835 7.05794 8.38335 6.73294 8.70835L3.20794 12.25C2.88294 12.575 2.35794 12.575 2.02461 12.25L0.249609 10.4667C-0.0753906 10.1417 -0.0753906 9.61668 0.249609 9.29168C0.574609 8.96668 1.09961 8.96668 1.42461 9.29168L2.60794 10.475L5.55794 7.52502C5.87461 7.20835 6.40794 7.20835 6.73294 7.53335Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.PlusInCircle:
            return (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" width="24" height="24" rx="12" fill="#FEDF87" />
                    <path
                        d="M11.166 7.99984C11.166 7.26346 11.763 6.6665 12.4993 6.6665C13.2357 6.6665 13.8327 7.26346 13.8327 7.99984V15.9998C13.8327 16.7362 13.2357 17.3332 12.4993 17.3332C11.763 17.3332 11.166 16.7362 11.166 15.9998V7.99984Z"
                        fill="#354A63"
                    />
                    <path d="M11.166 10.6665V13.3332H8.49935C7.76297 13.3332 7.16602 12.7362 7.16602 11.9998C7.16602 11.2635 7.76297 10.6665 8.49935 10.6665L11.166 10.6665Z" fill="#354A63" />
                    <path d="M16.4993 10.6665C17.2357 10.6665 17.8327 11.2635 17.8327 11.9998C17.8327 12.7362 17.2357 13.3332 16.4993 13.3332H13.8327V10.6665L16.4993 10.6665Z" fill="#354A63" />
                </svg>
            )

        case IconType.Search:
            return (
                <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
                        fill="#767676"
                    />
                    <path
                        d="M12.5 7.91667C12.5 10.448 10.448 12.5 7.91667 12.5C5.38536 12.5 3.33333 10.448 3.33333 7.91667C3.33333 5.38536 5.38536 3.33333 7.91667 3.33333C10.448 3.33333 12.5 5.38536 12.5 7.91667Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.Config:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2.5" y="4.16797" width="8.33333" height="1.66667" rx="0.833333" fill="#767676" />
                    <rect x="9.1665" y="9.16797" width="8.33333" height="1.66667" rx="0.833333" fill="#767676" />
                    <rect x="9.1665" y="14.168" width="8.33333" height="1.66667" rx="0.833333" fill="#767676" />
                    <rect x="12.5" y="4.16797" width="5" height="1.66667" rx="0.833333" fill="#767676" />
                    <rect x="2.5" y="9.16797" width="5" height="1.66667" rx="0.833333" fill="#767676" />
                    <rect x="2.5" y="14.168" width="5" height="1.66667" rx="0.833333" fill="#767676" />
                    <rect x="11.6665" y="3.33203" width="3.33333" height="3.33333" rx="1.66667" fill="#767676" />
                    <rect x="5" y="8.33203" width="3.33333" height="3.33333" rx="1.66667" fill="#767676" />
                    <rect x="8.3335" y="13.332" width="3.33333" height="3.33333" rx="1.66667" fill="#767676" />
                </svg>
            )

        case IconType.ReadNotification:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22 7.98V17C22 18.1 21.1 19 20 19H6L2 23V5C2 3.9 2.9 3 4 3H14.1C14.04 3.32 14 3.66 14 4C14 6.76 16.24 9 19 9C20.13 9 21.16 8.61 22 7.98ZM16 4C16 5.66 17.34 7 19 7C20.66 7 22 5.66 22 4C22 2.34 20.66 1 19 1C17.34 1 16 2.34 16 4Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.UnReadNotification:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.05 19.29C17.66 19.68 17.03 19.68 16.64 19.29L14.52 17.17C14.13 16.78 14.13 16.15 14.52 15.76C14.91 15.37 15.54 15.37 15.93 15.76L17.34 17.17L20.88 13.63C21.27 13.24 21.9 13.24 22.29 13.63C22.68 14.02 22.68 14.65 22.29 15.04L18.05 19.29ZM12 17C12 13.13 15.13 10 19 10C20.08 10 21.09 10.25 22 10.68V4C22 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V22L6 18H12C12 17.83 12.01 17.67 12.03 17.5C12.01 17.33 12 17.17 12 17Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.Flask:
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M19.7992 18.4L13.9992 10.67V6.5L15.3492 4.81C15.6092 4.48 15.3792 4 14.9592 4H9.0392C8.6192 4 8.3892 4.48 8.6492 4.81L9.9992 6.5V10.67L4.1992 18.4C3.7092 19.06 4.1792 20 4.9992 20H18.9992C19.8192 20 20.2892 19.06 19.7992 18.4Z" />
                </svg>
            )

        case IconType.UserFriends:
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z" />
                </svg>
            )

        case IconType.Left:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.18821 10.1493V11.287C8.18821 11.9238 7.50208 12.2386 7.10185 11.7879L4.18582 8.50357C3.93806 8.22451 3.93806 7.77372 4.18582 7.49467L7.10185 4.21037C7.50208 3.75959 8.18821 4.08158 8.18821 4.7184L8.18821 5.8561L11.3647 5.8561C11.7141 5.8561 12 6.17809 12 6.57163V9.43376C12 9.8273 11.7141 10.1493 11.3647 10.1493H8.18821Z"
                        fill="#354A63"
                    />
                </svg>
            )

        case IconType.ReOrder:
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.2197 2.50017C11.9436 2.02187 12.1074 1.41028 12.5857 1.13414C13.064 0.857999 13.6756 1.02187 13.9518 1.50017L18.1483 8.76874C18.4244 9.24703 18.2605 9.85862 17.7823 10.1348C17.304 10.4109 16.6924 10.247 16.4162 9.76874L12.2197 2.50017Z"
                        fill="#0E1F33"
                    />
                    <path
                        d="M9.41601 1.50017C9.69215 1.02187 10.3037 0.857999 10.782 1.13414C11.2603 1.41028 11.4242 2.02187 11.1481 2.50017L6.95154 9.76874C6.6754 10.247 6.06381 10.4109 5.58552 10.1348C5.10723 9.85862 4.94335 9.24703 5.21949 8.76874L9.41601 1.50017Z"
                        fill="#0E1F33"
                    />
                    <path
                        d="M1.03035 8.87668C0.872563 8.24553 1.34992 7.63414 2.00049 7.63414H21.4389C22.0895 7.63414 22.5669 8.24553 22.4091 8.87668L21.275 13H15C14 13 13 14 13 15V19.6341H4.50049C4.04163 19.6341 3.64164 19.3218 3.53035 18.8767L1.03035 8.87668Z"
                        fill="#0E1F33"
                    />
                    <path
                        d="M15.36 20.648C15.176 20.248 15.464 19.8 15.904 19.8C16.136 19.8 16.352 19.936 16.448 20.152C16.888 21.12 17.864 21.8 19 21.8C20.544 21.8 21.8 20.544 21.8 19C21.8 17.456 20.544 16.2 19 16.2C18.24 16.2 17.544 16.504 17.04 17H17.6C17.928 17 18.2 17.272 18.2 17.6C18.2 17.928 17.928 18.2 17.6 18.2H15.8C15.36 18.2 15 17.84 15 17.4V15.6C15 15.272 15.272 15 15.6 15C15.928 15 16.2 15.272 16.2 15.6V16.144C16.92 15.44 17.912 15 19 15C21.208 15 23 16.792 23 19C23 21.208 21.208 23 19 23C17.376 23 15.984 22.032 15.36 20.648Z"
                        fill="#0E1F33"
                    />
                </svg>
            )

        case IconType.Support:
            return (
                <svg
                    width="WS18"
                    height="16"
                    viewBox="0 0 24 22"
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22.4999 11.2567C22.4999 4.85167 17.5299 0.5 11.9999 0.5C6.52825 0.5 1.49992 4.75833 1.49992 11.3267C0.799919 11.7233 0.333252 12.47 0.333252 13.3333V15.6667C0.333252 16.95 1.38325 18 2.66659 18C3.30825 18 3.83325 17.475 3.83325 16.8333V11.2217C3.83325 6.75333 7.27492 2.845 11.7433 2.71667C16.3633 2.57667 20.1666 6.28667 20.1666 10.8833V19.1667H11.9999C11.3583 19.1667 10.8333 19.6917 10.8333 20.3333C10.8333 20.975 11.3583 21.5 11.9999 21.5H20.1666C21.4499 21.5 22.4999 20.45 22.4999 19.1667V17.7433C23.1883 17.3817 23.6666 16.67 23.6666 15.83V13.1467C23.6666 12.33 23.1883 11.6183 22.4999 11.2567Z"
                        fill="currentColor"
                    />
                    <path
                        d="M8.49992 13.3333C9.14425 13.3333 9.66658 12.811 9.66658 12.1667C9.66658 11.5223 9.14425 11 8.49992 11C7.85559 11 7.33325 11.5223 7.33325 12.1667C7.33325 12.811 7.85559 13.3333 8.49992 13.3333Z"
                        fill="currentColor"
                    />
                    <path
                        d="M15.4999 13.3333C16.1442 13.3333 16.6666 12.811 16.6666 12.1667C16.6666 11.5223 16.1442 11 15.4999 11C14.8556 11 14.3333 11.5223 14.3333 12.1667C14.3333 12.811 14.8556 13.3333 15.4999 13.3333Z"
                        fill="currentColor"
                    />
                    <path
                        d="M18.9999 9.86833C18.4399 6.54333 15.5466 4 12.0583 4C8.52325 4 4.71992 6.92833 5.02325 11.525C7.90492 10.3467 10.0749 7.78 10.6933 4.65333C12.2216 7.72167 15.3599 9.83333 18.9999 9.86833Z"
                        fill="currentColor"
                    />
                </svg>
            )

        case IconType.Question:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'none'}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6.99967 0.333496C3.31967 0.333496 0.333008 3.32016 0.333008 7.00016C0.333008 10.6802 3.31967 13.6668 6.99967 13.6668C10.6797 13.6668 13.6663 10.6802 13.6663 7.00016C13.6663 3.32016 10.6797 0.333496 6.99967 0.333496ZM7.66634 11.6668H6.33301V10.3335H7.66634V11.6668ZM9.04634 6.50016L8.44634 7.1135C8.11301 7.4535 7.87301 7.76016 7.75301 8.24016C7.69967 8.4535 7.66634 8.6935 7.66634 9.00016H6.33301V8.66683C6.33301 8.36016 6.38634 8.06683 6.47967 7.7935C6.61301 7.40683 6.83301 7.06016 7.11301 6.78016L7.93967 5.94016C8.24634 5.64683 8.39301 5.20683 8.30634 4.74016C8.21967 4.26016 7.84634 3.8535 7.37967 3.72016C6.63967 3.5135 5.95301 3.9335 5.73301 4.56683C5.65301 4.8135 5.44634 5.00016 5.18634 5.00016H4.98634C4.59967 5.00016 4.33301 4.62683 4.43967 4.2535C4.72634 3.2735 5.55967 2.52683 6.59301 2.36683C7.60634 2.20683 8.57301 2.7335 9.17301 3.56683C9.95967 4.6535 9.72634 5.82016 9.04634 6.50016Z"
                        fill="#A2ADBB"
                    />
                </svg>
            )

        case IconType.Check:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'none'}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.70711 11.6568C5.09763 11.2663 5.7308 11.2663 6.12132 11.6568L11.0711 16.6066C11.4616 16.9971 11.4616 17.6303 11.0711 18.0208L9.65685 19.435C9.26633 19.8255 8.63316 19.8255 8.24264 19.435L3.29289 14.4853C2.90237 14.0947 2.90237 13.4616 3.29289 13.0711L4.70711 11.6568Z"
                        fill="#767676"
                    />
                    <path
                        d="M20.9708 6.70711C21.3613 7.09763 21.3613 7.7308 20.9708 8.12132L9.65685 19.435C9.26633 19.8255 8.63316 19.8255 8.24264 19.435L6.82864 18.0208C6.43812 17.6303 6.43812 16.9971 6.82864 16.6066L18.1424 5.29289C18.5329 4.90237 19.166 4.90237 19.5566 5.29289L20.9708 6.70711Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.GoToLink:
            return (
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.5 10.5H3.5C3.17917 10.5 2.91667 10.2375 2.91667 9.91667V2.91667C2.91667 2.59583 3.17917 2.33333 3.5 2.33333H6.41667C6.7375 2.33333 7 2.07083 7 1.75C7 1.42917 6.7375 1.16667 6.41667 1.16667H2.91667C2.26917 1.16667 1.75 1.69167 1.75 2.33333V10.5C1.75 11.1417 2.275 11.6667 2.91667 11.6667H11.0833C11.725 11.6667 12.25 11.1417 12.25 10.5V7C12.25 6.67917 11.9875 6.41667 11.6667 6.41667C11.3458 6.41667 11.0833 6.67917 11.0833 7V9.91667C11.0833 10.2375 10.8208 10.5 10.5 10.5ZM8.16667 1.75C8.16667 2.07083 8.42917 2.33333 8.75 2.33333H10.2608L4.935 7.65917C4.7075 7.88667 4.7075 8.25417 4.935 8.48167C5.1625 8.70917 5.53 8.70917 5.7575 8.48167L11.0833 3.15583V4.66667C11.0833 4.9875 11.3458 5.25 11.6667 5.25C11.9875 5.25 12.25 4.9875 12.25 4.66667V1.75C12.25 1.42917 11.9875 1.16667 11.6667 1.16667H8.75C8.42917 1.16667 8.16667 1.42917 8.16667 1.75Z"
                        fill="#676767"
                    />
                </svg>
            )

        case IconType.Cart:
            return (
                <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.41765 18.8732C6.15765 18.8732 5.1382 19.9041 5.1382 21.1641C5.1382 22.4241 6.15765 23.455 7.41765 23.455C8.67765 23.455 9.70856 22.4241 9.70856 21.1641C9.70856 19.9041 8.67765 18.8732 7.41765 18.8732ZM0.544922 0.545898V2.83681H2.83583L6.95947 11.5308L5.4131 14.3372C5.22983 14.6579 5.12674 15.0359 5.12674 15.4368C5.12674 16.6968 6.15765 17.7277 7.41765 17.7277H21.1631V15.4368H7.89874C7.73838 15.4368 7.61238 15.3108 7.61238 15.1504L7.64674 15.013L8.67765 13.1459H17.2113C18.0704 13.1459 18.8264 12.6763 19.2158 11.9661L23.3166 4.53208C23.4082 4.37172 23.454 4.17699 23.454 3.98226C23.454 3.35226 22.9386 2.83681 22.3086 2.83681H5.36729L4.29056 0.545898H0.544922ZM18.8722 18.8732C17.6122 18.8732 16.5927 19.9041 16.5927 21.1641C16.5927 22.4241 17.6122 23.455 18.8722 23.455C20.1322 23.455 21.1631 22.4241 21.1631 21.1641C21.1631 19.9041 20.1322 18.8732 18.8722 18.8732Z"
                        fill="currentColor"
                    />
                </svg>
            )
        case IconType.Trash:
            return (
                <svg onClick={props.onClick ? props.onClick : undefined} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#767676" />
                </svg>
            )

        case IconType.Plus:
            return (
                <svg width="13" height="12" viewBox="0 0 13 12" stroke={color} xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 0V12" strokeWidth="1.5" />
                    <path d="M12.5 6L0.5 6" strokeWidth="1.5" />
                </svg>
            )
        case IconType.Minus:
            return (
                <svg width="13" height="16" viewBox="0 0 13 16" stroke={color} xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 8H0.5" strokeWidth="1.5" />
                </svg>
            )
        case IconType.Attach:
            return (
                <svg width="16" height="8" viewBox="0 0 16 8" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.9993 6.66634H4.33268C2.85935 6.66634 1.66602 5.47301 1.66602 3.99967C1.66602 2.52634 2.85935 1.33301 4.33268 1.33301H12.666C13.586 1.33301 14.3327 2.07967 14.3327 2.99967C14.3327 3.91967 13.586 4.66634 12.666 4.66634H5.66602C5.29935 4.66634 4.99935 4.36634 4.99935 3.99967C4.99935 3.63301 5.29935 3.33301 5.66602 3.33301H11.9993V2.33301H5.66602C4.74602 2.33301 3.99935 3.07967 3.99935 3.99967C3.99935 4.91967 4.74602 5.66634 5.66602 5.66634H12.666C14.1393 5.66634 15.3327 4.47301 15.3327 2.99967C15.3327 1.52634 14.1393 0.333008 12.666 0.333008H4.33268C2.30602 0.333008 0.666016 1.97301 0.666016 3.99967C0.666016 6.02634 2.30602 7.66634 4.33268 7.66634H11.9993V6.66634Z"
                        fill="black"
                        fillOpacity="0.54"
                    />
                </svg>
            )
        case IconType.CreditCard:
            return (
                <svg width="20" height="16" viewBox="0 0 20 16" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z" fill="#354A63" />
                </svg>
            )
        case IconType.Btp:
            return (
                <svg width="19" height="20" viewBox="0 0 19 20" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.5 2H12.32C11.9 0.84 10.8 0 9.5 0C8.2 0 7.1 0.84 6.68 2H2.5C1.4 2 0.5 2.9 0.5 4V18C0.5 19.1 1.4 20 2.5 20H16.5C17.6 20 18.5 19.1 18.5 18V4C18.5 2.9 17.6 2 16.5 2ZM9.5 1.75C9.91 1.75 10.25 2.09 10.25 2.5C10.25 2.91 9.91 3.25 9.5 3.25C9.09 3.25 8.75 2.91 8.75 2.5C8.75 2.09 9.09 1.75 9.5 1.75ZM6.6 16H4.5V13.86L10.46 7.9L12.58 10.02L6.6 16ZM14.35 8.27L13.29 9.33L11.17 7.21L12.23 6.15C12.43 5.95 12.74 5.95 12.94 6.15L14.35 7.56C14.55 7.76 14.55 8.07 14.35 8.27Z"
                        fill="#354A63"
                    />
                </svg>
            )
        case IconType.Debit:
            return (
                <svg width="22" height="16" viewBox="0 0 22 16" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18 10V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V10C0 11.1 0.9 12 2 12H16C17.1 12 18 11.1 18 10ZM9 9C7.34 9 6 7.66 6 6C6 4.34 7.34 3 9 3C10.66 3 12 4.34 12 6C12 7.66 10.66 9 9 9ZM22 3V14C22 15.1 21.1 16 20 16H3V14H20V3H22Z"
                        fill="#354A63"
                    />
                </svg>
            )
        case IconType.Document:
            return (
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 8H8C5.8 8 4.02 9.8 4.02 12L4 36C4 38.2 5.8 40 8 40H40C42.2 40 44 38.2 44 36V16C44 13.8 42.2 12 40 12H24L20 8Z" fill="#FDC00E" />
                </svg>
            )

        case IconType.Rfq:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19 4H17V13H4V15C4 15.55 4.45 16 5 16H16L20 20V5C20 4.45 19.55 4 19 4ZM15 10V1C15 0.45 14.55 0 14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10Z"
                        fill="#354A63"
                    />
                </svg>
            )
        case IconType.Equipment:
            return (
                <svg fill={color} className={props.className} width="16" height="16" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.05 9.54459C15.05 10.7217 15.4483 11.8646 16.1806 12.7889L15.7242 13.1651C14.6903 12.586 13.9081 11.6468 13.5283 10.5282C13.1485 9.40953 13.1978 8.19061 13.6667 7.106C13.6981 7.01775 13.7562 6.94135 13.833 6.88735C13.9098 6.83335 14.0016 6.8044 14.0957 6.80451H14.1191L14.7116 5.78132H16.0439V4.58737L8.95872 7.88374V17.8782C8.95872 18.441 8.73411 18.9806 8.33431 19.3786C7.93451 19.7765 7.39227 20 6.82687 20C6.26147 20 5.71922 19.7765 5.31942 19.3786C4.91962 18.9806 4.69502 18.441 4.69502 17.8782V9.86609L3.00482 10.6532C2.49272 10.8802 1.91148 10.8977 1.38652 10.7019C0.861566 10.5062 0.434975 10.1129 0.1988 9.60685C-0.0373745 9.10085 -0.0641988 8.52271 0.124115 7.99719C0.312429 7.47167 0.700784 7.04091 1.20538 6.79784L15.4025 0.194427C15.9134 -0.0394471 16.4964 -0.0632831 17.0248 0.128096C17.5533 0.319475 17.9846 0.710615 18.225 1.21658C18.4654 1.72254 18.4956 2.30247 18.3089 2.83043C18.1223 3.3584 17.7339 3.79176 17.2281 4.03641V5.78198H18.5631L19.1562 6.80585H19.1803C19.2743 6.80597 19.3658 6.83502 19.4425 6.889C19.5192 6.94298 19.5772 7.01924 19.6086 7.10733C20.0776 8.19194 20.1269 9.41087 19.747 10.5295C19.3672 11.6481 18.585 12.5874 17.5511 13.1665L17.0947 12.7896C17.8276 11.8658 18.2262 10.723 18.226 9.54593C18.2259 9.31773 18.2107 9.08978 18.1804 8.86358L18.0022 8.73217H15.2705L15.0942 8.85891C15.0646 9.08628 15.0498 9.31532 15.05 9.54459Z"
                        fill="currentColor"
                    />
                </svg>
            )
        case IconType.Activities:
            return (
                <svg width="22" height="22" viewBox="0 0 25 25" className={props.className} fill={color} xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22 8.54V6C22 4.9 21.1 4 20 4H4C2.9 4 2.01 4.89 2.01 6V8.54C2.01 9.23 2.34 9.91 2.95 10.23C3.58 10.58 4 11.24 4 12C4 12.76 3.57 13.43 2.94 13.76C2.34 14.09 2 14.77 2 15.46V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V15.46C22 14.77 21.66 14.09 21.06 13.76C20.43 13.42 20 12.76 20 12C20 11.24 20.43 10.58 21.06 10.24C21.66 9.91 22 9.23 22 8.54ZM13 17.5H11V15.5H13V17.5ZM13 13H11V11H13V13ZM13 8.5H11V6.5H13V8.5Z"
                        fill="currentColor"
                    />
                </svg>
            )

        case IconType.ChevronUp:
            return (
                <svg className={props.className} width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 8L8 0L16 8H0Z" fill={color ? color : 'currentColor'} />
                </svg>
            )
        case IconType.ChevronDown:
            return (
                <svg className={props.className} width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0L8 8L16 0H0Z" fill={color ? color : 'currentColor'} />
                </svg>
            )
        case IconType.ChevronRight:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    width="8"
                    height="16"
                    viewBox="0 0 8 16"
                    fill={props.color ? props.color : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 16L8 8L0 0V16Z" fill={props.color ? props.color : 'currentColor'} />
                </svg>
            )
        case IconType.Parts:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 12.0004V20.0004H18V12.0004H10ZM0 20.0004H8V12.0004H0V20.0004ZM0 2.00043V10.0004H8V2.00043H0ZM13.66 0.69043L8 6.34043L13.66 12.0004L19.32 6.34043L13.66 0.69043Z"
                        fill="currentColor"
                    />
                </svg>
            )
        case IconType.Customers:
            return (
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V14H14V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C15.19 8.89 16 10.02 16 11.5V14H22V11.5C22 9.17 17.33 8 15 8Z"
                        fill="currentColor"
                    />
                </svg>
            )
        case IconType.Product:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8002 14.4L10.0002 6.67V2.5L11.3502 0.81C11.6102 0.48 11.3802 0 10.9602 0H5.04018C4.62018 0 4.39017 0.48 4.65018 0.81L6.00018 2.5V6.67L0.200175 14.4C-0.289825 15.06 0.180175 16 1.00018 16H15.0002C15.8202 16 16.2902 15.06 15.8002 14.4Z"
                        fill="currentColor"
                    />
                </svg>
            )
        case IconType.SubAssembly:
            return (
                <svg width="24" height="24" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.8877 4.40307L9.48771 5.52307L7.93438 4.06307V3.12974L9.48771 1.66974L11.8877 2.78974C12.141 2.90974 12.4344 2.7964 12.5544 2.54974C12.6744 2.2964 12.561 2.00307 12.3144 1.88307L9.70104 0.66307C9.44771 0.54307 9.14771 0.596403 8.94771 0.796403L7.78771 1.86307C7.66771 1.70307 7.48104 1.5964 7.26771 1.5964C6.90104 1.5964 6.60104 1.8964 6.60104 2.26307V2.92974H4.48104C4.16104 2.02974 3.25438 1.4164 2.20104 1.64307C1.42771 1.80974 0.76771 2.4764 0.62771 3.26307C0.481044 4.14307 0.934377 4.90974 1.61438 5.3164L3.32104 10.9297H1.26771V12.9297H9.93438V10.9297H7.52104L4.20771 4.7764C4.32104 4.6164 4.41438 4.44974 4.48104 4.26307H6.60104V4.92974C6.60104 5.2964 6.90104 5.5964 7.26771 5.5964C7.48104 5.5964 7.66771 5.48974 7.78771 5.32974L8.94771 6.3964C9.14771 6.5964 9.44771 6.64973 9.70104 6.52973L12.3144 5.30974C12.5677 5.18974 12.6744 4.8964 12.5544 4.64307C12.4344 4.3964 12.141 4.28307 11.8877 4.40307ZM2.60104 4.26307C2.23438 4.26307 1.93438 3.96307 1.93438 3.5964C1.93438 3.22974 2.23438 2.92974 2.60104 2.92974C2.96771 2.92974 3.26771 3.22974 3.26771 3.5964C3.26771 3.96307 2.96771 4.26307 2.60104 4.26307Z"
                        fill="currentColor"
                    />
                </svg>
            )

        case IconType.Magnifier:
            return (
                <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
                        fill="currentColor"
                    />
                    <circle cx="9.5" cy="9.5" r="5.5" fill="currentColor" />
                </svg>
            )
        case IconType.Reset:
            return (
                <svg
                    onClick={props.onClick ? props.onClick : undefined}
                    className={props.className}
                    width="16"
                    height="20"
                    viewBox="0 0 16 20"
                    fill={props.color ? props.color : 'none'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8 4V0L3 5L8 10V6C11.31 6 14 8.69 14 12C14 15.31 11.31 18 8 18C4.69 18 2 15.31 2 12H0C0 16.42 3.58 20 8 20C12.42 20 16 16.42 16 12C16 7.58 12.42 4 8 4Z"
                        fill="currentColor"
                    />
                </svg>
            )

        case IconType.Show:
            return (
                <svg className={props.className} width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                        fill="currentColor"
                    />
                </svg>
            )
        case IconType.Hide:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 7C14.76 7 17 9.24 17 12C17 12.65 16.87 13.26 16.64 13.83L19.56 16.75C21.07 15.49 22.26 13.86 22.99 12C21.26 7.61 16.99 4.5 11.99 4.5C10.59 4.5 9.25 4.75 8.01 5.2L10.17 7.36C10.74 7.13 11.35 7 12 7ZM2 4.27L4.28 6.55L4.74 7.01C3.08 8.3 1.78 10.02 1 12C2.73 16.39 7 19.5 12 19.5C13.55 19.5 15.03 19.2 16.38 18.66L16.8 19.08L19.73 22L21 20.73L3.27 3L2 4.27ZM7.53 9.8L9.08 11.35C9.03 11.56 9 11.78 9 12C9 13.66 10.34 15 12 15C12.22 15 12.44 14.97 12.65 14.92L14.2 16.47C13.53 16.8 12.79 17 12 17C9.24 17 7 14.76 7 12C7 11.21 7.2 10.47 7.53 9.8V9.8ZM11.84 9.02L14.99 12.17L15.01 12.01C15.01 10.35 13.67 9.01 12.01 9.01L11.84 9.02Z"
                        fill="#767676"
                    />
                </svg>
            )
        case IconType.Duplicate:
            return (
                <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.0039 3H9.00391C7.89934 3 7.00391 3.89543 7.00391 5V15C7.00391 16.1046 7.89934 17 9.00391 17H19.0039C20.1085 17 21.0039 16.1046 21.0039 15V5C21.0039 3.89543 20.1085 3 19.0039 3Z"
                        fill="currentColor"
                        stroke="#767676"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17.002 17V19C17.002 19.5304 16.7912 20.0391 16.4162 20.4142C16.0411 20.7893 15.5324 21 15.002 21H5.00195C4.47152 21 3.96281 20.7893 3.58774 20.4142C3.21267 20.0391 3.00195 19.5304 3.00195 19V9C3.00195 8.46957 3.21267 7.96086 3.58774 7.58579C3.96281 7.21071 4.47152 7 5.00195 7H7.00195"
                        stroke="#767676"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )

        case IconType.PlusCircle:
            return (
                <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14 11H11V14C11 14.5 10.5 15 10 15C9.5 15 9 14.5 9 14V11H6C5.5 11 5 10.5 5 10C5 9.5 5.5 9 6 9H9V6C9 5.5 9.5 5 10 5C10.5 5 11 5.5 11 6V9H14C14.5 9 15 9.5 15 10C15 10.5 14.5 11 14 11Z"
                        fill="currentColor"
                    />
                </svg>
            )

        case IconType.Pen:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill={props.color ? color : '#767676'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M12.0909 2.90909L16.0909 6.90909L4.45455 18.5455L0.454547 14.5455L12.0909 2.90909Z" fill="#767676" />
                    <path
                        d="M14.2727 0.727273C14.6744 0.325611 15.3582 0.358172 15.8 0.8L18.2 3.2C18.6418 3.64183 18.6744 4.32561 18.2727 4.72727L16.8182 6.18182L12.8182 2.18182L14.2727 0.727273Z"
                        fill="#767676"
                    />
                    <path d="M1 18L0.454547 14.5455L4.45455 18.5455L1 18Z" fill="#767676" />
                </svg>
            )

        case IconType.Link:
            return (
                <svg className={props.className} width="22" height="10" viewBox="0 0 22 10" fill="#767676" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22 5C22.0012 6.2892 21.4915 7.52891 20.5776 8.45986C19.6636 9.3908 18.4163 9.94087 17.0963 9.99501H13.0014C12.7299 10.0215 12.4587 9.94167 12.2475 9.77305C12.0363 9.60444 11.9024 9.36086 11.8753 9.09591C11.8481 8.83096 11.93 8.56633 12.1027 8.36025C12.2755 8.15417 12.5251 8.0235 12.7966 7.99701H16.8711C17.6856 7.99701 18.4668 7.68125 19.0428 7.11921C19.6187 6.55716 19.9423 5.79486 19.9423 5C19.9423 4.20514 19.6187 3.44284 19.0428 2.88079C18.4668 2.31875 17.6856 2.00299 16.8711 2.00299H12.8069C12.5354 1.9765 12.2858 1.84583 12.113 1.63975C11.9402 1.43367 11.8584 1.16904 11.8855 0.904088C11.9127 0.639136 12.0466 0.395561 12.2578 0.226947C12.4689 0.0583325 12.7401 -0.0215095 13.0116 0.0049857H17.1066C18.4247 0.0616371 19.6695 0.612802 20.5813 1.54348C21.493 2.47416 22.0013 3.71245 22 5Z"
                        fill="#767676"
                    />
                    <path
                        d="M10.2373 8.99601C10.2373 9.26096 10.1295 9.51506 9.93748 9.70241C9.74549 9.88976 9.4851 9.99501 9.21359 9.99501H5.11866C3.76111 9.99501 2.45916 9.46876 1.49922 8.53201C0.539286 7.59526 0 6.32476 0 5C0 3.67524 0.539286 2.40474 1.49922 1.46799C2.45916 0.531244 3.76111 0.00498548 5.11866 0.00498548H9.21359C9.34802 -0.00813361 9.4838 0.00471589 9.61314 0.0428C9.74249 0.0808842 9.86289 0.143457 9.96746 0.226947C10.072 0.310436 10.1587 0.413206 10.2226 0.529391C10.2865 0.645575 10.3262 0.772897 10.3397 0.904088C10.3531 1.03528 10.34 1.16777 10.3009 1.29399C10.2619 1.42022 10.1978 1.53771 10.1122 1.63975C10.0267 1.74179 9.92137 1.82639 9.80231 1.88872C9.68324 1.95104 9.55277 1.98987 9.41833 2.00299H5.33364C4.51911 2.00299 3.73794 2.31875 3.16198 2.88079C2.58602 3.44284 2.26245 4.20514 2.26245 5C2.26245 5.79486 2.58602 6.55716 3.16198 7.11921C3.73794 7.68125 4.51911 7.99701 5.33364 7.99701H9.4081C9.6447 8.04165 9.85758 8.16631 10.0093 8.34905C10.1609 8.53179 10.2417 8.76089 10.2373 8.99601Z"
                        fill="#767676"
                    />
                    <path
                        d="M8.25128 4.001H14.3937C14.6652 4.001 14.9256 4.10625 15.1176 4.2936C15.3095 4.48095 15.4174 4.73505 15.4174 5C15.4174 5.26495 15.3095 5.51905 15.1176 5.7064C14.9256 5.89375 14.6652 5.999 14.3937 5.999H8.25128C7.97977 5.999 7.71938 5.89375 7.52739 5.7064C7.3354 5.51905 7.22755 5.26495 7.22755 5C7.22755 4.73505 7.3354 4.48095 7.52739 4.2936C7.71938 4.10625 7.97977 4.001 8.25128 4.001Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.Unlink:
            return (
                <svg className={props.className} width="22" height="21" viewBox="0 0 22 21" fill="#767676" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M22 11C22.0012 12.2892 21.4915 13.5289 20.5776 14.4599C19.6636 15.3908 18.4163 15.9409 17.0963 15.995H13.0014C12.7299 16.0215 12.4587 15.9417 12.2475 15.7731C12.0363 15.6044 11.9024 15.3609 11.8753 15.0959C11.8481 14.831 11.93 14.5663 12.1027 14.3602C12.2755 14.1542 12.5251 14.0235 12.7966 13.997H16.8711C17.6856 13.997 18.4668 13.6813 19.0428 13.1192C19.6187 12.5572 19.9423 11.7949 19.9423 11C19.9423 10.2051 19.6187 9.44284 19.0428 8.88079C18.4668 8.31875 17.6856 8.00299 16.8711 8.00299H12.8069C12.5354 7.9765 12.2858 7.84583 12.113 7.63975C11.9402 7.43367 11.8584 7.16904 11.8855 6.90409C11.9127 6.63914 12.0466 6.39556 12.2578 6.22695C12.4689 6.05833 12.7401 5.97849 13.0116 6.00499H17.1066C18.4247 6.06164 19.6695 6.6128 20.5813 7.54348C21.493 8.47416 22.0013 9.71245 22 11Z"
                        fill="#767676"
                    />
                    <path
                        d="M10.2373 14.996C10.2373 15.261 10.1295 15.5151 9.93748 15.7024C9.74549 15.8898 9.4851 15.995 9.21359 15.995H5.11866C3.76111 15.995 2.45916 15.4688 1.49922 14.532C0.539286 13.5953 0 12.3248 0 11C0 9.67524 0.539286 8.40474 1.49922 7.46799C2.45916 6.53124 3.76111 6.00499 5.11866 6.00499H9.21359C9.34803 5.99187 9.4838 6.00472 9.61314 6.0428C9.74249 6.08088 9.86289 6.14346 9.96746 6.22695C10.072 6.31044 10.1587 6.41321 10.2226 6.52939C10.2865 6.64558 10.3262 6.7729 10.3397 6.90409C10.3531 7.03528 10.34 7.16777 10.3009 7.29399C10.2619 7.42022 10.1978 7.53771 10.1122 7.63975C10.0267 7.74179 9.92137 7.82639 9.80231 7.88872C9.68324 7.95104 9.55277 7.98987 9.41833 8.00299H5.33364C4.51911 8.00299 3.73794 8.31875 3.16198 8.88079C2.58602 9.44284 2.26245 10.2051 2.26245 11C2.26245 11.7949 2.58602 12.5572 3.16198 13.1192C3.73794 13.6813 4.51911 13.997 5.33364 13.997H9.4081C9.6447 14.0417 9.85758 14.1663 10.0093 14.3491C10.1609 14.5318 10.2417 14.7609 10.2373 14.996Z"
                        fill="#767676"
                    />
                    <path
                        d="M8.25128 10.001H14.3937C14.6652 10.001 14.9256 10.1062 15.1176 10.2936C15.3095 10.4809 15.4174 10.735 15.4174 11C15.4174 11.265 15.3095 11.5191 15.1176 11.7064C14.9256 11.8938 14.6652 11.999 14.3937 11.999H8.25128C7.97977 11.999 7.71938 11.8938 7.52739 11.7064C7.3354 11.5191 7.22755 11.265 7.22755 11C7.22755 10.735 7.3354 10.4809 7.52739 10.2936C7.71938 10.1062 7.97977 10.001 8.25128 10.001Z"
                        fill="#767676"
                    />
                    <path
                        d="M0.707106 1.70711C0.316582 1.31658 0.316583 0.683417 0.707107 0.292893C1.09763 -0.0976311 1.7308 -0.097631 2.12132 0.292893L20.5061 18.6777C20.8966 19.0682 20.8966 19.7014 20.5061 20.0919C20.1156 20.4824 19.4824 20.4824 19.0919 20.0919L0.707106 1.70711Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.NewTrash:
            return (
                <svg className={props.className} onClick={props.onClick ? props.onClick : undefined} width="14" height="18" viewBox="0 0 14 18" fill="#767676" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#767676" />
                </svg>
            )

        case IconType.Folder:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8 0Z" />
                </svg>
            )

        case IconType.OrderBag:
            return (
                <svg className={props.className} width="22" height="19" viewBox="0 0 22 19" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2197 1.50017C10.9436 1.02187 11.1074 0.410284 11.5857 0.134142C12.064 -0.142001 12.6756 0.0218746 12.9518 0.500167L17.1483 7.76874C17.4244 8.24703 17.2605 8.85862 16.7823 9.13476C16.304 9.41091 15.6924 9.24703 15.4162 8.76874L11.2197 1.50017Z" />
                    <path d="M8.41601 0.500166C8.69215 0.0218737 9.30374 -0.142001 9.78203 0.134142C10.2603 0.410284 10.4242 1.02187 10.1481 1.50017L5.95154 8.76874C5.6754 9.24703 5.06381 9.41091 4.58552 9.13476C4.10723 8.85862 3.94335 8.24703 4.21949 7.76874L8.41601 0.500166Z" />
                    <path d="M0.0303501 7.87668C-0.127437 7.24553 0.349922 6.63414 1.00049 6.63414H20.4389C21.0895 6.63414 21.5669 7.24553 21.4091 7.87668L18.9091 17.8767C18.7978 18.3218 18.3978 18.6341 17.9389 18.6341H3.50049C3.04163 18.6341 2.64164 18.3218 2.53035 17.8767L0.0303501 7.87668Z" />
                </svg>
            )
        case IconType.MessageDollar:
            return (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.333 1.33203L11.333 1.33206L10.6663 1.33206C9.99968 1.33199 9.13301 1.33206 8.77968 1.33206H2.66634C1.99968 1.33206 1.33301 1.9987 1.33301 2.6654L1.33301 14.6654C2.33301 13.6654 2.99967 12.9987 3.99968 11.9987H13.333C13.9997 11.9987 14.6663 11.332 14.6663 10.6654V2.6654C14.6663 1.9987 13.9997 1.33203 13.333 1.33203ZM9.33301 5.99873C9.69968 5.99873 9.99968 6.29873 9.99968 6.6654V8.6654C9.99968 9.03206 9.69968 9.33206 9.33301 9.33206H8.66634C8.66634 9.69873 8.36634 9.99873 7.99968 9.99873C7.63301 9.99873 7.33301 9.69873 7.33301 9.33206H6.66634C6.29968 9.33206 5.99968 9.03206 5.99968 8.6654C5.99968 8.29873 6.29968 7.99873 6.66634 7.99873H8.66634V7.33206H6.66634C6.29968 7.33206 5.99968 7.03206 5.99968 6.6654V4.6654C5.99968 4.29873 6.29968 3.99873 6.66634 3.99873H7.33301C7.33301 3.63206 7.63301 3.33206 7.99968 3.33206C8.36634 3.33206 8.66634 3.63206 8.66634 3.99873H9.33301C9.69968 3.99873 9.99968 4.29873 9.99968 4.6654C9.99968 5.03206 9.69968 5.33206 9.33301 5.33206H7.33301V5.99873H9.33301Z"
                        fill="currentColor"
                    />
                </svg>
            )

        case IconType.Download:
            return (
                <svg className={props.className} fill={props.color ? color : 'currentColor'} width="31" height="30" viewBox="0 0 31 30" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.75 25H24.25V22.5H6.75V25ZM24.25 11.25H19.25V3.75H11.75V11.25H6.75L15.5 20L24.25 11.25Z" fill="#354A63" />
                </svg>
            )

        case IconType.Company:
            return (
                <svg className={props.className} fill={props.color ? color : 'currentColor'} width="20" height="19" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 4H14V2C14 0.89 13.11 0 12 0H8C6.89 0 6 0.89 6 2V4H2C0.89 4 0.00999999 4.89 0.00999999 6L0 17C0 18.11 0.89 19 2 19H18C19.11 19 20 18.11 20 17V6C20 4.89 19.11 4 18 4ZM12 4H8V2H12V4Z" />
                </svg>
            )

        case IconType.Info:
            return (
                <svg className={props.className} width="24" height="24" viewBox="0 0 24 24" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.4875 2 2 6.4875 2 12C2 17.5125 6.4875 22 12 22C17.5125 22 22 17.5125 22 12C22 6.4875 17.5125 2 12 2ZM13.25 18.25H10.75V10.75H13.25V18.25ZM13.25 8.25H10.75V5.75H13.25V8.25Z" />
                </svg>
            )

        case IconType.Close:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill={props.color ? color : 'currentColor'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M1.2216 4.05028C0.83108 3.65975 0.83108 3.02659 1.2216 2.63606L2.63582 1.22185C3.02634 0.831324 3.65951 0.831324 4.05003 1.22185L16.778 13.9498C17.1685 14.3403 17.1685 14.9735 16.778 15.364L15.3637 16.7782C14.9732 17.1687 14.34 17.1687 13.9495 16.7782L1.2216 4.05028Z" />
                    <path d="M13.9495 1.22185C14.3401 0.831324 14.9732 0.831324 15.3637 1.22185L16.778 2.63606C17.1685 3.02659 17.1685 3.65975 16.778 4.05028L11.8282 9.00002L8.99978 6.1716L13.9495 1.22185Z" />
                    <path d="M4.05003 16.7782C3.65951 17.1687 3.02634 17.1687 2.63582 16.7782L1.2216 15.364C0.83108 14.9735 0.83108 14.3403 1.2216 13.9498L6.17135 9.00002L8.99978 11.8285L4.05003 16.7782Z" />
                </svg>
            )

        case IconType.AddCircle:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill={props.color ? color : 'currentColor'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14 11H11V14C11 14.5 10.5 15 10 15C9.5 15 9 14.5 9 14V11H6C5.5 11 5 10.5 5 10C5 9.5 5.5 9 6 9H9V6C9 5.5 9.5 5 10 5C10.5 5 11 5.5 11 6V9H14C14.5 9 15 9.5 15 10C15 10.5 14.5 11 14 11Z" />
                </svg>
            )
        case IconType.YellowAddCircle:
            return (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="40" height="40" rx="20" fill="#FEDF87" />
                    <path
                        d="M17.3335 11.9987C17.3335 10.5259 18.5274 9.33203 20.0002 9.33203C21.4729 9.33203 22.6668 10.5259 22.6668 11.9987V27.9987C22.6668 29.4715 21.4729 30.6654 20.0002 30.6654C18.5274 30.6654 17.3335 29.4715 17.3335 27.9987V11.9987Z"
                        fill="#354A63"
                    />
                    <path d="M17.3335 17.332V22.6654H12.0002C10.5274 22.6654 9.3335 21.4715 9.3335 19.9987C9.3335 18.5259 10.5274 17.332 12.0002 17.332L17.3335 17.332Z" fill="#354A63" />
                    <path d="M28.0002 17.332C29.4729 17.332 30.6668 18.5259 30.6668 19.9987C30.6668 21.4715 29.4729 22.6654 28.0002 22.6654H22.6668V17.332L28.0002 17.332Z" fill="#354A63" />
                </svg>
            )

        case IconType.Image:
            return (
                <svg className={props.className} width="18" height="18" viewBox="0 0 18 18" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16ZM5.5 10.5L8 13.51L11.5 9L16 15H2L5.5 10.5Z" />
                </svg>
            )

        case IconType.PartNumber:
            return (
                <svg className={props.className} width="16" height="16" viewBox="0 0 16 16" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 2C9 0.895431 9.89543 0 11 0C12.1046 0 13 0.895431 13 2V14C13 15.1046 12.1046 16 11 16C9.89543 16 9 15.1046 9 14V2Z" />
                    <path d="M3 2C3 0.895431 3.89543 0 5 0C6.10457 0 7 0.895431 7 2V14C7 15.1046 6.10457 16 5 16C3.89543 16 3 15.1046 3 14V2Z" />
                    <path d="M2 7C0.895431 7 -1.31719e-08 6.10457 0 5C1.31719e-08 3.89543 0.895431 3 2 3H3V7H2Z" />
                    <path d="M2 13C0.895431 13 -1.31719e-08 12.1046 0 11C1.31719e-08 9.89543 0.895431 9 2 9H3V13H2Z" />
                    <path d="M14 9C15.1046 9 16 9.89543 16 11C16 12.1046 15.1046 13 14 13H13V9H14Z" />
                    <path d="M14 3C15.1046 3 16 3.89543 16 5C16 6.10457 15.1046 7 14 7H13V3L14 3Z" />
                    <path d="M9 3V7H7V3H9Z" />
                    <path d="M9 9V13H7V9H9Z" />
                </svg>
            )
        case IconType.Vendor:
            return (
                <svg className={props.className} width="22" height="18" viewBox="0 0 22 18" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 12C5.33 12 0 13.34 0 16V18H16V16C16 13.34 10.67 12 8 12Z" />
                    <path d="M21.1 3.84C21.11 3.73 21.12 3.62 21.12 3.5C21.12 3.38 21.11 3.27 21.09 3.16L21.83 2.58C21.9 2.53 21.91 2.43 21.87 2.36L21.17 1.15C21.13 1.07 21.03 1.05 20.96 1.07L20.1 1.42C19.92 1.28 19.72 1.17 19.51 1.08L19.38 0.15C19.36 0.0600001 19.29 0 19.2 0H17.8C17.71 0 17.64 0.0600001 17.63 0.15L17.5 1.08C17.29 1.17 17.09 1.29 16.91 1.42L16.04 1.07C15.96 1.04 15.87 1.07 15.83 1.15L15.13 2.36C15.09 2.44 15.1 2.53 15.17 2.58L15.91 3.16C15.89 3.27 15.88 3.39 15.88 3.5C15.88 3.61 15.89 3.73 15.91 3.84L15.17 4.42C15.1 4.47 15.09 4.57 15.13 4.64L15.83 5.85C15.87 5.93 15.97 5.95 16.04 5.93L16.91 5.58C17.09 5.72 17.29 5.83 17.5 5.92L17.63 6.85C17.64 6.94 17.71 7 17.8 7H19.2C19.29 7 19.36 6.94 19.37 6.85L19.5 5.92C19.71 5.83 19.91 5.71 20.09 5.58L20.96 5.93C21.04 5.96 21.13 5.93 21.17 5.85L21.87 4.64C21.91 4.56 21.9 4.47 21.83 4.42L21.1 3.84ZM18.5 4.75C17.81 4.75 17.25 4.19 17.25 3.5C17.25 2.81 17.81 2.25 18.5 2.25C19.19 2.25 19.75 2.81 19.75 3.5C19.75 4.19 19.19 4.75 18.5 4.75Z" />
                    <path d="M18.92 8.68L18.42 7.81C18.39 7.75 18.32 7.73 18.27 7.75L17.65 8C17.52 7.9 17.38 7.82 17.23 7.76L17.14 7.1C17.12 7.04 17.06 7 17 7H16C15.94 7 15.89 7.04 15.88 7.11L15.79 7.77C15.64 7.83 15.5 7.92 15.37 8.01L14.75 7.76C14.69 7.74 14.63 7.76 14.6 7.82L14.1 8.69C14.07 8.75 14.08 8.81 14.13 8.85L14.66 9.26C14.65 9.34 14.64 9.42 14.64 9.5C14.64 9.58 14.65 9.67 14.66 9.74L14.13 10.15C14.08 10.19 14.07 10.26 14.1 10.31L14.6 11.18C14.63 11.24 14.7 11.26 14.75 11.24L15.37 10.99C15.5 11.09 15.64 11.17 15.79 11.23L15.88 11.89C15.89 11.96 15.94 12 16 12H17C17.06 12 17.12 11.96 17.12 11.89L17.21 11.23C17.36 11.17 17.5 11.08 17.63 10.99L18.25 11.24C18.31 11.26 18.37 11.24 18.4 11.18L18.9 10.31C18.93 10.25 18.92 10.19 18.87 10.15L18.35 9.74C18.36 9.66 18.37 9.58 18.37 9.5C18.37 9.42 18.36 9.33 18.35 9.26L18.88 8.85C18.93 8.81 18.94 8.74 18.92 8.68ZM16.5 10.33C16.04 10.33 15.67 9.95 15.67 9.5C15.67 9.04 16.05 8.67 16.5 8.67C16.95 8.67 17.33 9.05 17.33 9.5C17.33 9.96 16.96 10.33 16.5 10.33Z" />
                    <path d="M3.74 6H12.27C12.54 6 12.76 5.78 12.76 5.51V5.49C12.76 5.22 12.54 5 12.27 5H12C12 3.52 11.19 2.25 10 1.55V2.5C10 2.78 9.78 3 9.5 3C9.22 3 9 2.78 9 2.5V1.14C8.68 1.06 8.35 1 8 1C7.65 1 7.32 1.06 7 1.14V2.5C7 2.78 6.78 3 6.5 3C6.22 3 6 2.78 6 2.5V1.55C4.81 2.25 4 3.52 4 5H3.74C3.47 5 3.25 5.22 3.25 5.49V5.52C3.25 5.78 3.47 6 3.74 6Z" />
                    <path d="M8.00002 10C9.86001 10 11.41 8.72 11.86 7H4.14001C4.59001 8.72 6.14002 10 8.00002 10Z" />
                </svg>
            )

        case IconType.Description:
            return (
                <svg className={props.className} viewBox="0 0 18 20" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 1.75C9.41 1.75 9.75 2.09 9.75 2.5C9.75 2.91 9.41 3.25 9 3.25C8.59 3.25 8.25 2.91 8.25 2.5C8.25 2.09 8.59 1.75 9 1.75ZM6.1 16H4V13.86L9.96 7.9L12.08 10.02L6.1 16ZM13.85 8.27L12.79 9.33L10.67 7.21L11.73 6.15C11.93 5.95 12.24 5.95 12.44 6.15L13.85 7.56C14.05 7.76 14.05 8.07 13.85 8.27Z" />
                </svg>
            )

        case IconType.Tag:
            return (
                <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.41 9.58L10.41 0.58C10.05 0.22 9.55 0 9 0H2C0.9 0 0 0.9 0 2V9C0 9.55 0.22 10.05 0.59 10.42L9.59 19.42C9.95 19.78 10.45 20 11 20C11.55 20 12.05 19.78 12.41 19.41L19.41 12.41C19.78 12.05 20 11.55 20 11C20 10.45 19.77 9.94 19.41 9.58ZM3.5 5C2.67 5 2 4.33 2 3.5C2 2.67 2.67 2 3.5 2C4.33 2 5 2.67 5 3.5C5 4.33 4.33 5 3.5 5Z" />
                </svg>
            )

        case IconType.StarBorder:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill={props.color ? color : 'currentColor'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M20 7.24L12.81 6.62L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27L16.18 19L14.55 11.97L20 7.24ZM10 13.4L6.24 15.67L7.24 11.39L3.92 8.51L8.3 8.13L10 4.1L11.71 8.14L16.09 8.52L12.77 11.4L13.77 15.68L10 13.4Z" />
                </svg>
            )

        case IconType.Star:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill={props.color ? color : 'currentColor'}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M10.2584 14.8419L10 14.686L9.74163 14.8419L4.57613 17.9596L5.94693 12.0836L6.01549 11.7897L5.78739 11.5921L1.22308 7.63803L7.23227 7.12821L7.5327 7.10272L7.65036 6.82511L10 1.2813L12.3496 6.82511L12.4673 7.10272L12.7677 7.12821L18.7769 7.63803L14.2126 11.5921L13.9845 11.7897L14.0531 12.0836L15.4239 17.9596L10.2584 14.8419Z" />
                </svg>
            )

        case IconType.SerialNumber:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 1C0 0.447715 0.447715 0 1 0H4C4.55228 0 5 0.447715 5 1V15C5 15.5523 4.55228 16 4 16H1C0.447715 16 0 15.5523 0 15V1Z" />
                    <path d="M6 1C6 0.447715 6.44772 0 7 0C7.55228 0 8 0.447715 8 1V15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15V1Z" />
                    <path d="M9 0.5C9 0.223858 9.22386 0 9.5 0C9.77614 0 10 0.223858 10 0.5V15.5C10 15.7761 9.77614 16 9.5 16C9.22386 16 9 15.7761 9 15.5V0.5Z" />
                    <path d="M11 1C11 0.447715 11.4477 0 12 0H16C16.5523 0 17 0.447715 17 1V15C17 15.5523 16.5523 16 16 16H12C11.4477 16 11 15.5523 11 15V1Z" />
                    <path d="M18 1C18 0.447715 18.4477 0 19 0C19.5523 0 20 0.447715 20 1V15C20 15.5523 19.5523 16 19 16C18.4477 16 18 15.5523 18 15V1Z" />
                </svg>
            )

        case IconType.Address:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z" />
                </svg>
            )
        case IconType.AddCustomer:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C12.79 4 11 5.79 11 8C11 10.21 12.79 12 15 12ZM6 10V7H4V10H1V12H4V15H6V12H9V10H6ZM15 14C12.33 14 7 15.34 7 18V20H23V18C23 15.34 17.67 14 15 14Z" />
                </svg>
            )
        case IconType.RemoveCustomer:
            return (
                <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4ZM15 6V8H21V6H15ZM0 14V16H16V14C16 11.34 10.67 10 8 10C5.33 10 0 11.34 0 14Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.Drawing:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M11.5333 7.59164L12.8417 6.28331L9.71667 3.15831L8.40833 4.46664L4.95833 1.02498C4.30833 0.374976 3.25 0.374976 2.6 1.02498L1.01667 2.60831C0.366667 3.25831 0.366667 4.31664 1.01667 4.96664L4.45833 8.40831L0.5 12.375V15.5H3.625L7.59167 11.5333L11.0333 14.975C11.825 15.7666 12.8917 15.475 13.3917 14.975L14.975 13.3916C15.625 12.7416 15.625 11.6833 14.975 11.0333L11.5333 7.59164ZM5.65 7.22498L2.2 3.78331L3.775 2.19998L4.83333 3.25831L3.85 4.24998L5.025 5.42498L6.01667 4.43331L7.225 5.64164L5.65 7.22498ZM12.2167 13.8L8.775 10.3583L10.3583 8.77498L11.5667 9.98331L10.575 10.975L11.75 12.15L12.7417 11.1583L13.8 12.2166L12.2167 13.8Z" />
                    <path d="M15.2583 3.8666C15.5833 3.5416 15.5833 3.0166 15.2583 2.6916L13.3083 0.741597C12.9167 0.34993 12.375 0.49993 12.1333 0.741597L10.6083 2.2666L13.7333 5.3916L15.2583 3.8666Z" />
                </svg>
            )

        case IconType.List:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.666748 13.6667H17.3334V10.3334H0.666748V13.6667ZM2.33341 11.1667H4.00008V12.8334H2.33341V11.1667ZM0.666748 0.333374V3.66671H17.3334V0.333374H0.666748ZM4.00008 2.83337H2.33341V1.16671H4.00008V2.83337ZM0.666748 8.66671H17.3334V5.33337H0.666748V8.66671ZM2.33341 6.16671H4.00008V7.83337H2.33341V6.16671Z" />
                </svg>
            )

        case IconType.UploadCloud:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_175_15552)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M65.5007 45.2094C66.0812 49.6481 64.1752 54.0422 60.5915 56.527C57.0077 59.0119 52.3747 59.1517 48.6584 56.8872C47.342 59.468 44.9325 61.2499 42.1589 61.6939C39.3853 62.1379 36.5732 61.1917 34.5733 59.1417C33.4518 61.4817 31.2499 63.0538 28.7488 63.3003C26.2477 63.5468 23.8015 62.4327 22.2781 60.3533C20.252 62.8337 17.0286 63.878 14.0025 63.0344C10.9765 62.1909 8.69129 59.6109 8.1358 56.4109C5.65359 55.7064 3.58595 53.9157 2.46711 51.5012C1.34827 49.0868 1.28798 46.2856 2.30181 43.8213C-0.142444 40.5115 -0.714217 36.1012 0.799869 32.2363C2.31395 28.3714 5.68642 25.6326 9.65873 25.0418C9.68673 21.4145 11.5988 18.086 14.6579 16.3395C17.7171 14.5929 21.4456 14.7009 24.4063 16.6219C25.6675 12.2775 29.2171 9.08099 33.5217 8.41329C37.8263 7.74559 42.1141 9.72645 44.5327 13.5001C47.4974 11.64 51.0548 11.1042 54.4024 12.0135C57.75 12.9227 60.6059 15.2005 62.3257 18.3329C65.3553 17.9641 68.2844 19.5971 69.6595 22.4216C71.0345 25.2461 70.5627 28.6608 68.4782 30.9709C71.1807 32.6258 72.5596 35.9096 71.896 39.11C71.2324 42.3104 68.6766 44.7021 65.5614 45.038L65.5007 45.2094Z"
                            fill="#9AA4B1"
                        />
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.4766 41.9811L36.0003 24.0378L59.5241 41.9811H53.8848V47.9623H18.1159V41.9811H12.4766Z" fill="#F5F6F7" />
                    </g>
                    <defs>
                        <clipPath id="clip0_175_15552">
                            <rect width="72" height="72" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )

        case IconType.Upload:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.4 10.5925H8.99V15.5925C8.99 16.1425 9.44 16.5925 9.99 16.5925H13.99C14.54 16.5925 14.99 16.1425 14.99 15.5925V10.5925H16.58C17.47 10.5925 17.92 9.5125 17.29 8.8825L12.7 4.2925C12.31 3.9025 11.68 3.9025 11.29 4.2925L6.7 8.8825C6.07 9.5125 6.51 10.5925 7.4 10.5925ZM5 19.5925C5 20.1425 5.45 20.5925 6 20.5925H18C18.55 20.5925 19 20.1425 19 19.5925C19 19.0425 18.55 18.5925 18 18.5925H6C5.45 18.5925 5 19.0425 5 19.5925Z"
                        fill="#354A63"
                    />
                </svg>
            )

        case IconType.Share:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23 12C23.0012 13.2892 22.4915 14.5289 21.5776 15.4599C20.6636 16.3908 19.4163 16.9409 18.0963 16.995H14.0014C13.7299 17.0215 13.4587 16.9417 13.2475 16.7731C13.0363 16.6044 12.9024 16.3609 12.8753 16.0959C12.8481 15.831 12.93 15.5663 13.1027 15.3602C13.2755 15.1542 13.5251 15.0235 13.7966 14.997H17.8711C18.6856 14.997 19.4668 14.6813 20.0428 14.1192C20.6187 13.5572 20.9423 12.7949 20.9423 12C20.9423 11.2051 20.6187 10.4428 20.0428 9.88079C19.4668 9.31875 18.6856 9.00299 17.8711 9.00299H13.8069C13.5354 8.9765 13.2858 8.84583 13.113 8.63975C12.9402 8.43367 12.8584 8.16904 12.8855 7.90409C12.9127 7.63914 13.0466 7.39556 13.2578 7.22695C13.4689 7.05833 13.7401 6.97849 14.0116 7.00499H18.1066C19.4247 7.06164 20.6695 7.6128 21.5813 8.54348C22.493 9.47416 23.0013 10.7125 23 12Z"
                        fill="#767676"
                    />
                    <path
                        d="M11.2373 15.996C11.2373 16.261 11.1295 16.5151 10.9375 16.7024C10.7455 16.8898 10.4851 16.995 10.2136 16.995H6.11866C4.76111 16.995 3.45916 16.4688 2.49922 15.532C1.53929 14.5953 1 13.3248 1 12C1 10.6752 1.53929 9.40474 2.49922 8.46799C3.45916 7.53124 4.76111 7.00499 6.11866 7.00499H10.2136C10.348 6.99187 10.4838 7.00472 10.6131 7.0428C10.7425 7.08088 10.8629 7.14346 10.9675 7.22695C11.072 7.31044 11.1587 7.41321 11.2226 7.52939C11.2865 7.64557 11.3262 7.7729 11.3397 7.90409C11.3531 8.03528 11.34 8.16777 11.3009 8.29399C11.2619 8.42022 11.1978 8.53771 11.1122 8.63975C11.0267 8.74179 10.9214 8.82639 10.8023 8.88872C10.6832 8.95104 10.5528 8.98987 10.4183 9.00299H6.33364C5.51911 9.00299 4.73794 9.31875 4.16198 9.88079C3.58602 10.4428 3.26245 11.2051 3.26245 12C3.26245 12.7949 3.58602 13.5572 4.16198 14.1192C4.73794 14.6813 5.51911 14.997 6.33364 14.997H10.4081C10.6447 15.0417 10.8576 15.1663 11.0093 15.3491C11.1609 15.5318 11.2417 15.7609 11.2373 15.996Z"
                        fill="#767676"
                    />
                    <path
                        d="M9.25128 11.001H15.3937C15.6652 11.001 15.9256 11.1062 16.1176 11.2936C16.3095 11.4809 16.4174 11.735 16.4174 12C16.4174 12.265 16.3095 12.5191 16.1176 12.7064C15.9256 12.8938 15.6652 12.999 15.3937 12.999H9.25128C8.97977 12.999 8.71938 12.8938 8.52739 12.7064C8.3354 12.5191 8.22755 12.265 8.22755 12C8.22755 11.735 8.3354 11.4809 8.52739 11.2936C8.71938 11.1062 8.97977 11.001 9.25128 11.001Z"
                        fill="#767676"
                    />
                </svg>
            )
        case IconType.UnShare:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23 12C23.0012 13.2892 22.4915 14.5289 21.5776 15.4599C20.6636 16.3908 19.4163 16.9409 18.0963 16.995H14.0014C13.7299 17.0215 13.4587 16.9417 13.2475 16.7731C13.0363 16.6044 12.9024 16.3609 12.8753 16.0959C12.8481 15.831 12.93 15.5663 13.1027 15.3602C13.2755 15.1542 13.5251 15.0235 13.7966 14.997H17.8711C18.6856 14.997 19.4668 14.6813 20.0428 14.1192C20.6187 13.5572 20.9423 12.7949 20.9423 12C20.9423 11.2051 20.6187 10.4428 20.0428 9.88079C19.4668 9.31875 18.6856 9.00299 17.8711 9.00299H13.8069C13.5354 8.9765 13.2858 8.84583 13.113 8.63975C12.9402 8.43367 12.8584 8.16904 12.8855 7.90409C12.9127 7.63914 13.0466 7.39556 13.2578 7.22695C13.4689 7.05833 13.7401 6.97849 14.0116 7.00499H18.1066C19.4247 7.06164 20.6695 7.6128 21.5813 8.54348C22.493 9.47416 23.0013 10.7125 23 12Z"
                        fill="#767676"
                    />
                    <path
                        d="M11.2373 15.996C11.2373 16.261 11.1295 16.5151 10.9375 16.7024C10.7455 16.8898 10.4851 16.995 10.2136 16.995H6.11866C4.76111 16.995 3.45916 16.4688 2.49922 15.532C1.53929 14.5953 1 13.3248 1 12C1 10.6752 1.53929 9.40474 2.49922 8.46799C3.45916 7.53124 4.76111 7.00499 6.11866 7.00499H10.2136C10.348 6.99187 10.4838 7.00472 10.6131 7.0428C10.7425 7.08088 10.8629 7.14346 10.9675 7.22695C11.072 7.31044 11.1587 7.41321 11.2226 7.52939C11.2865 7.64557 11.3262 7.7729 11.3397 7.90409C11.3531 8.03528 11.34 8.16777 11.3009 8.29399C11.2619 8.42022 11.1978 8.53771 11.1122 8.63975C11.0267 8.74179 10.9214 8.82639 10.8023 8.88872C10.6832 8.95104 10.5528 8.98987 10.4183 9.00299H6.33364C5.51911 9.00299 4.73794 9.31875 4.16198 9.88079C3.58602 10.4428 3.26245 11.2051 3.26245 12C3.26245 12.7949 3.58602 13.5572 4.16198 14.1192C4.73794 14.6813 5.51911 14.997 6.33364 14.997H10.4081C10.6447 15.0417 10.8576 15.1663 11.0093 15.3491C11.1609 15.5318 11.2417 15.7609 11.2373 15.996Z"
                        fill="#767676"
                    />
                    <path
                        d="M9.25128 11.001H15.3937C15.6652 11.001 15.9256 11.1062 16.1176 11.2936C16.3095 11.4809 16.4174 11.735 16.4174 12C16.4174 12.265 16.3095 12.5191 16.1176 12.7064C15.9256 12.8938 15.6652 12.999 15.3937 12.999H9.25128C8.97977 12.999 8.71938 12.8938 8.52739 12.7064C8.3354 12.5191 8.22755 12.265 8.22755 12C8.22755 11.735 8.3354 11.4809 8.52739 11.2936C8.71938 11.1062 8.97977 11.001 9.25128 11.001Z"
                        fill="#767676"
                    />
                    <rect x="1" y="2" width="2" height="28" rx="1" transform="rotate(-45 1 2)" fill="#767676" />
                </svg>
            )
        case IconType.Bell:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.58027 4.08L6.15027 2.65C3.75027 4.48 2.17027 7.3 2.03027 10.5H4.03027C4.18027 7.85 5.54027 5.53 7.58027 4.08ZM19.9703 10.5H21.9703C21.8203 7.3 20.2403 4.48 17.8503 2.65L16.4303 4.08C18.4503 5.53 19.8203 7.85 19.9703 10.5ZM18.0003 11C18.0003 7.93 16.3603 5.36 13.5003 4.68V4C13.5003 3.17 12.8303 2.5 12.0003 2.5C11.1703 2.5 10.5003 3.17 10.5003 4V4.68C7.63027 5.36 6.00027 7.92 6.00027 11V16L4.00027 18V19H20.0003V18L18.0003 16V11ZM12.0003 22C12.1403 22 12.2703 21.99 12.4003 21.96C13.0503 21.82 13.5803 21.38 13.8403 20.78C13.9403 20.54 13.9903 20.28 13.9903 20H9.99027C10.0003 21.1 10.8903 22 12.0003 22Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.Treeview:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="17"
                    height="22"
                    viewBox="0 0 17 22"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0 1C0 0.447715 0.447715 0 1 0H6C6.55228 0 7 0.447715 7 1V5C7 5.55228 6.55228 6 6 6H1C0.447715 6 0 5.55228 0 5V1Z" />
                    <path d="M0 9C0 8.44772 0.447715 8 1 8H6C6.55228 8 7 8.44772 7 9V13C7 13.5523 6.55228 14 6 14H1C0.447715 14 0 13.5523 0 13V9Z" />
                    <path d="M0 17C0 16.4477 0.447715 16 1 16H6C6.55228 16 7 16.4477 7 17V21C7 21.5523 6.55228 22 6 22H1C0.447715 22 0 21.5523 0 21V17Z" />
                    <path d="M10 9C10 8.44772 10.4477 8 11 8H16C16.5523 8 17 8.44772 17 9V13C17 13.5523 16.5523 14 16 14H11C10.4477 14 10 13.5523 10 13V9Z" />
                    <path d="M2 6H5V8H2V6Z" />
                    <path d="M2 14H5V16H2V14Z" />
                    <path d="M7 10H10V12H7V10Z" />
                </svg>
            )

        case IconType.Copy:
            return (
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.0039 3H9.00391C7.89934 3 7.00391 3.89543 7.00391 5V15C7.00391 16.1046 7.89934 17 9.00391 17H19.0039C20.1085 17 21.0039 16.1046 21.0039 15V5C21.0039 3.89543 20.1085 3 19.0039 3Z"
                        fill="#767676"
                        stroke="#767676"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17.002 17V19C17.002 19.5304 16.7912 20.0391 16.4162 20.4142C16.0411 20.7893 15.5324 21 15.002 21H5.00195C4.47152 21 3.96281 20.7893 3.58774 20.4142C3.21267 20.0391 3.00195 19.5304 3.00195 19V9C3.00195 8.46957 3.21267 7.96086 3.58774 7.58579C3.96281 7.21071 4.47152 7 5.00195 7H7.00195"
                        stroke="#767676"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )

        case IconType.Phone:
            return (
                <svg className={props.className} width="18" height="18" fill={props.color ? color : 'currentColor'} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z" />
                </svg>
            )

        case IconType.Mail:
            return (
                <svg className={props.className} width="20" height="16" viewBox="0 0 20 16" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z" />
                </svg>
            )

        case IconType.AddTag:
            return (
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.6665 2.00001C4.6665 1.26363 5.26346 0.666672 5.99984 0.666672C6.73622 0.666672 7.33317 1.26363 7.33317 2.00001V10C7.33317 10.7364 6.73622 11.3333 5.99984 11.3333C5.26346 11.3333 4.6665 10.7364 4.6665 10V2.00001Z"
                        fill="#767676"
                    />
                    <path d="M4.6665 4.66667V7.33334H1.99984C1.26346 7.33334 0.666504 6.73638 0.666504 6C0.666504 5.26363 1.26346 4.66667 1.99984 4.66667L4.6665 4.66667Z" fill="#767676" />
                    <path d="M9.99984 4.66667C10.7362 4.66667 11.3332 5.26363 11.3332 6C11.3332 6.73638 10.7362 7.33334 9.99984 7.33334H7.33317V4.66667L9.99984 4.66667Z" fill="#767676" />
                </svg>
            )

        case IconType.TagClose:
            return (
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.814403 2.70017C0.554053 2.43982 0.554053 2.01771 0.814403 1.75736L1.75721 0.814555C2.01756 0.554206 2.43967 0.554206 2.70002 0.814555L11.1853 9.29984C11.4457 9.56019 11.4457 9.9823 11.1853 10.2426L10.2425 11.1855C9.98214 11.4458 9.56003 11.4458 9.29968 11.1855L0.814403 2.70017Z"
                        fill="#767676"
                    />
                    <path
                        d="M9.29968 0.814555C9.56003 0.554206 9.98214 0.554206 10.2425 0.814555L11.1853 1.75736C11.4457 2.01771 11.4457 2.43982 11.1853 2.70017L7.88547 6L5.99985 4.11439L9.29968 0.814555Z"
                        fill="#767676"
                    />
                    <path
                        d="M2.70002 11.1855C2.43967 11.4458 2.01756 11.4458 1.75721 11.1855L0.814403 10.2426C0.554053 9.9823 0.554053 9.56019 0.814403 9.29984L4.11423 6L5.99985 7.88562L2.70002 11.1855Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.UpArrow:
            return (
                <svg className={props.className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="down" d="M6.57153 13.6L12.5715 20L18.5715 13.6H6.57153Z" fill="#68737F" />
                    <path className="up" d="M6.57153 10.4L12.5715 4L18.5715 10.4H6.57153Z" fill="#68737F" />
                </svg>
            )

        case IconType.CardView:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.77778 0H15.2222C15.6539 0 16 0.35 16 0.777778V6.22222C16 6.65 15.6539 7 15.2222 7H9.77778C9.34611 7 9 6.65 9 6.22222V0.777778C9 0.35 9.34611 0 9.77778 0Z"
                        fill="#354A63"
                    />
                    <path
                        d="M9.77778 9H15.2222C15.6539 9 16 9.35 16 9.77778V15.2222C16 15.65 15.6539 16 15.2222 16H9.77778C9.34611 16 9 15.65 9 15.2222V9.77778C9 9.35 9.34611 9 9.77778 9Z"
                        fill="#354A63"
                    />
                    <path
                        d="M0.777778 0H6.22222C6.65389 0 7 0.35 7 0.777778V6.22222C7 6.65 6.65389 7 6.22222 7H0.777778C0.346111 7 0 6.65 0 6.22222V0.777778C0 0.35 0.346111 0 0.777778 0Z"
                        fill="#354A63"
                    />
                    <path
                        d="M0.777778 9H6.22222C6.65389 9 7 9.35 7 9.77778V15.2222C7 15.65 6.65389 16 6.22222 16H0.777778C0.346111 16 0 15.65 0 15.2222V9.77778C0 9.35 0.346111 9 0.777778 9Z"
                        fill="#354A63"
                    />
                </svg>
            )

        case IconType.Menus:
            return (
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1V3C20 3.55228 19.5523 4 19 4H1C0.447716 4 0 3.55228 0 3V1Z" fill="#354A63" />
                    <path d="M0 7C0 6.44772 0.447715 6 1 6H19C19.5523 6 20 6.44772 20 7V9C20 9.55228 19.5523 10 19 10H1C0.447716 10 0 9.55228 0 9V7Z" fill="#354A63" />
                    <path d="M0 13C0 12.4477 0.447715 12 1 12H19C19.5523 12 20 12.4477 20 13V15C20 15.5523 19.5523 16 19 16H1C0.447716 16 0 15.5523 0 15V13Z" fill="#354A63" />
                </svg>
            )

        case IconType.Cog:
            return (
                <svg className={props.className} width="26" height="26" viewBox="0 0 26 26" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.52 14.2545C22.5733 13.8545 22.6 13.4412 22.6 13.0012C22.6 12.5745 22.5733 12.1478 22.5067 11.7478L25.2133 9.64117C25.4533 9.4545 25.52 9.0945 25.3733 8.82784L22.8133 4.40117C22.6533 4.10784 22.32 4.0145 22.0267 4.10784L18.84 5.38784C18.1733 4.88117 17.4667 4.4545 16.68 4.1345L16.2 0.747838C16.1467 0.427838 15.88 0.201172 15.56 0.201172H10.44C10.12 0.201172 9.86665 0.427838 9.81332 0.747838L9.33332 4.1345C8.54665 4.4545 7.82665 4.8945 7.17332 5.38784L3.98665 4.10784C3.69332 4.00117 3.35998 4.10784 3.19998 4.40117L0.653317 8.82784C0.493317 9.10784 0.54665 9.4545 0.813317 9.64117L3.51998 11.7478C3.45332 12.1478 3.39998 12.5878 3.39998 13.0012C3.39998 13.4145 3.42665 13.8545 3.49332 14.2545L0.78665 16.3612C0.54665 16.5478 0.479984 16.9078 0.62665 17.1745L3.18665 21.6012C3.34665 21.8945 3.67998 21.9878 3.97332 21.8945L7.15998 20.6145C7.82665 21.1212 8.53332 21.5478 9.31998 21.8678L9.79998 25.2545C9.86665 25.5745 10.12 25.8012 10.44 25.8012H15.56C15.88 25.8012 16.1467 25.5745 16.1867 25.2545L16.6667 21.8678C17.4533 21.5478 18.1733 21.1212 18.8267 20.6145L22.0133 21.8945C22.3067 22.0012 22.64 21.8945 22.8 21.6012L25.36 17.1745C25.52 16.8812 25.4533 16.5478 25.2 16.3612L22.52 14.2545ZM13 17.8012C10.36 17.8012 8.19998 15.6412 8.19998 13.0012C8.19998 10.3612 10.36 8.20117 13 8.20117C15.64 8.20117 17.8 10.3612 17.8 13.0012C17.8 15.6412 15.64 17.8012 13 17.8012Z" />
                </svg>
            )

        case IconType.Handbag:
            return (
                <svg className={props.className} width="24" height="23" viewBox="0 0 24 23" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.3333 5.00065H16.6666V2.66732C16.6666 1.37232 15.6283 0.333984 14.3333 0.333984H9.66659C8.37159 0.333984 7.33325 1.37232 7.33325 2.66732V5.00065H2.66659C1.37159 5.00065 0.344919 6.03898 0.344919 7.33398L0.333252 20.1673C0.333252 21.4623 1.37159 22.5007 2.66659 22.5007H21.3333C22.6283 22.5007 23.6666 21.4623 23.6666 20.1673V7.33398C23.6666 6.03898 22.6283 5.00065 21.3333 5.00065ZM14.3333 5.00065H9.66659V2.66732H14.3333V5.00065Z" />
                </svg>
            )

        case IconType.ManageAccount:
            return (
                <svg className={props.className} width="24" height="21" viewBox="0 0 24 21" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.66659 9.99935C12.2439 9.99935 14.3333 7.91001 14.3333 5.33268C14.3333 2.75535 12.2439 0.666016 9.66659 0.666016C7.08926 0.666016 4.99992 2.75535 4.99992 5.33268C4.99992 7.91001 7.08926 9.99935 9.66659 9.99935Z" />
                    <path d="M10.4483 11.1893C10.1916 11.1777 9.93492 11.166 9.66659 11.166C6.84325 11.166 4.20659 11.9477 1.95492 13.2893C0.928252 13.896 0.333252 15.0393 0.333252 16.241V19.3327H11.1366C10.2149 18.0143 9.66659 16.4043 9.66659 14.666C9.66659 13.4177 9.95825 12.251 10.4483 11.1893Z" />
                    <path d="M22.2083 14.666C22.2083 14.4093 22.1733 14.176 22.1383 13.931L23.4683 12.7527L22.3016 10.7343L20.61 11.306C20.2366 10.991 19.8166 10.746 19.35 10.571L19 8.83268H16.6666L16.3166 10.571C15.85 10.746 15.43 10.991 15.0566 11.306L13.365 10.7343L12.1983 12.7527L13.5283 13.931C13.4933 14.176 13.4583 14.4093 13.4583 14.666C13.4583 14.9227 13.4933 15.156 13.5283 15.401L12.1983 16.5793L13.365 18.5977L15.0566 18.026C15.43 18.341 15.85 18.586 16.3166 18.761L16.6666 20.4993H19L19.35 18.761C19.8166 18.586 20.2366 18.341 20.61 18.026L22.3016 18.5977L23.4683 16.5793L22.1383 15.401C22.1733 15.156 22.2083 14.9227 22.2083 14.666ZM17.8333 16.9993C16.55 16.9993 15.5 15.9493 15.5 14.666C15.5 13.3827 16.55 12.3327 17.8333 12.3327C19.1166 12.3327 20.1666 13.3827 20.1666 14.666C20.1666 15.9493 19.1166 16.9993 17.8333 16.9993Z" />
                </svg>
            )

        case IconType.Team:
            return (
                <svg className={props.className} width="28" height="14" viewBox="0 0 28 14" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 7.875C15.9017 7.875 17.5817 8.33 18.9467 8.925C20.2067 9.485 21 10.745 21 12.11V14H7V12.1217C7 10.745 7.79333 9.485 9.05333 8.93667C10.4183 8.33 12.0983 7.875 14 7.875ZM4.66667 8.16667C5.95 8.16667 7 7.11667 7 5.83333C7 4.55 5.95 3.5 4.66667 3.5C3.38333 3.5 2.33333 4.55 2.33333 5.83333C2.33333 7.11667 3.38333 8.16667 4.66667 8.16667ZM5.985 9.45C5.55333 9.38 5.12167 9.33333 4.66667 9.33333C3.51167 9.33333 2.415 9.57833 1.42333 10.01C0.56 10.3833 0 11.2233 0 12.1683V14H5.25V12.1217C5.25 11.1533 5.51833 10.2433 5.985 9.45ZM23.3333 8.16667C24.6167 8.16667 25.6667 7.11667 25.6667 5.83333C25.6667 4.55 24.6167 3.5 23.3333 3.5C22.05 3.5 21 4.55 21 5.83333C21 7.11667 22.05 8.16667 23.3333 8.16667ZM28 12.1683C28 11.2233 27.44 10.3833 26.5767 10.01C25.585 9.57833 24.4883 9.33333 23.3333 9.33333C22.8783 9.33333 22.4467 9.38 22.015 9.45C22.4817 10.2433 22.75 11.1533 22.75 12.1217V14H28V12.1683ZM14 0C15.9367 0 17.5 1.56333 17.5 3.5C17.5 5.43667 15.9367 7 14 7C12.0633 7 10.5 5.43667 10.5 3.5C10.5 1.56333 12.0633 0 14 0Z" />
                </svg>
            )

        case IconType.Payments:
            return (
                <svg className={props.className} width="24" height="20" viewBox="0 0 24 20" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.9999 0.666016H4.99992C2.42159 0.666016 0.333252 2.75435 0.333252 5.33268V14.666C0.333252 17.2443 2.42159 19.3327 4.99992 19.3327H18.9999C21.5783 19.3327 23.6666 17.2443 23.6666 14.666V5.33268C23.6666 2.75435 21.5783 0.666016 18.9999 0.666016ZM16.8299 12.0643C16.5499 12.2977 16.1649 12.391 15.8033 12.2977L2.84159 9.12435C3.19159 8.27268 4.01992 7.66601 4.99992 7.66601H18.9999C19.7816 7.66601 20.4699 8.06268 20.9016 8.64601L16.8299 12.0643ZM4.99992 2.99935H18.9999C20.2833 2.99935 21.3333 4.04935 21.3333 5.33268V5.97435C20.6449 5.57768 19.8516 5.33268 18.9999 5.33268H4.99992C4.14825 5.33268 3.35492 5.57768 2.66659 5.97435V5.33268C2.66659 4.04935 3.71659 2.99935 4.99992 2.99935Z" />
                </svg>
            )

        case IconType.Addresses:
            return (
                <svg className={props.className} width="18" height="24" viewBox="0 0 18 24" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.99992 0.333984C4.48492 0.333984 0.833252 3.98565 0.833252 8.50065C0.833252 14.6257 8.99992 23.6673 8.99992 23.6673C8.99992 23.6673 17.1666 14.6257 17.1666 8.50065C17.1666 3.98565 13.5149 0.333984 8.99992 0.333984ZM8.99992 11.4173C7.38992 11.4173 6.08325 10.1107 6.08325 8.50065C6.08325 6.89065 7.38992 5.58398 8.99992 5.58398C10.6099 5.58398 11.9166 6.89065 11.9166 8.50065C11.9166 10.1107 10.6099 11.4173 8.99992 11.4173Z" />
                </svg>
            )

        case IconType.Brand:
            return (
                <svg className={props.className} width="20" height="20" viewBox="0 0 20 20" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.41 9.58L10.41 0.58C10.05 0.22 9.55 0 9 0H2C0.9 0 0 0.9 0 2V9C0 9.55 0.22 10.05 0.59 10.42L9.59 19.42C9.95 19.78 10.45 20 11 20C11.55 20 12.05 19.78 12.41 19.41L19.41 12.41C19.78 12.05 20 11.55 20 11C20 10.45 19.77 9.94 19.41 9.58ZM3.5 5C2.67 5 2 4.33 2 3.5C2 2.67 2.67 2 3.5 2C4.33 2 5 2.67 5 3.5C5 4.33 4.33 5 3.5 5Z" />
                </svg>
            )

        case IconType.Calendar:
            return (
                <svg className={props.className} width="20" height="22" viewBox="0 0 20 22" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2H17V0H15V2H5V0H3V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM18 20H2V7H18V20Z" />
                </svg>
            )

        case IconType.Customize:
            return (
                <svg className={props.className} width="14" height="14" viewBox="0 0 14 14" fill="#459b68" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.2973 1.52108C6.03501 0.262796 4.14472 0.00612989 2.62495 0.744826L4.9046 3.01726C5.14952 3.2614 5.14952 3.65579 4.9046 3.89994L3.90608 4.8953C3.66115 5.1457 3.26551 5.1457 3.02059 4.8953L0.740934 2.62287C0.0061695 4.14408 0.263651 6.01586 1.52594 7.27415C2.69403 8.43854 4.4022 8.74528 5.85289 8.20065L10.8518 13.1837C11.4986 13.8285 12.5411 13.8285 13.1817 13.1837C13.8285 12.5389 13.8285 11.4997 13.1817 10.8612L8.2079 5.89692C8.78567 4.43205 8.48423 2.70425 7.2973 1.52108Z"
                        fill="#459b68"
                    />
                </svg>
            )

        case IconType.Warranty:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.53332 1.50606L3.53332 3.00606C3.01332 3.20606 2.66666 3.69939 2.66666 4.25939V7.39939C2.66666 10.7661 4.93999 13.9061 7.99999 14.6727C11.06 13.9061 13.3333 10.7661 13.3333 7.39939V4.25939C13.3333 3.70606 12.9867 3.20606 12.4667 3.01273L8.46666 1.51273C8.16666 1.39273 7.83332 1.39273 7.53332 1.50606ZM6.81999 9.88606L5.40666 8.47273C5.14666 8.21273 5.14666 7.79273 5.40666 7.53273C5.66666 7.27273 6.08666 7.27273 6.34666 7.53273L7.28666 8.47273L9.64666 6.11273C9.90666 5.85273 10.3267 5.85273 10.5867 6.11273C10.8467 6.37273 10.8467 6.79273 10.5867 7.05273L7.75999 9.87939C7.50666 10.1461 7.07999 10.1461 6.81999 9.88606Z"
                        fill="#459B68"
                    />
                </svg>
            )

        case IconType.AddNote:
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V12L12 18H2ZM8 11C8.28333 11 8.521 10.904 8.713 10.712C8.90433 10.5207 9 10.2833 9 10C9 9.71667 8.90433 9.479 8.713 9.287C8.521 9.09567 8.28333 9 8 9H5C4.71667 9 4.479 9.09567 4.287 9.287C4.09567 9.479 4 9.71667 4 10C4 10.2833 4.09567 10.5207 4.287 10.712C4.479 10.904 4.71667 11 5 11H8ZM13 7C13.2833 7 13.5207 6.904 13.712 6.712C13.904 6.52067 14 6.28333 14 6C14 5.71667 13.904 5.479 13.712 5.287C13.5207 5.09567 13.2833 5 13 5H5C4.71667 5 4.479 5.09567 4.287 5.287C4.09567 5.479 4 5.71667 4 6C4 6.28333 4.09567 6.52067 4.287 6.712C4.479 6.904 4.71667 7 5 7H13ZM11 16L16 11H12C11.7167 11 11.4793 11.0957 11.288 11.287C11.096 11.479 11 11.7167 11 12V16Z"
                        fill="#354A63"
                    />
                </svg>
            )
        case IconType.WriteNote:
            return props.empty ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13 10C13 10.55 12.55 11 12 11H3C2.45 11 2 10.55 2 10C2 9.45 2.45 9 3 9H12C12.55 9 13 9.45 13 10ZM2 6C2 6.55 2.45 7 3 7H12C12.55 7 13 6.55 13 6C13 5.45 12.55 5 12 5H3C2.45 5 2 5.45 2 6ZM9 14C9 13.45 8.55 13 8 13H3C2.45 13 2 13.45 2 14C2 14.55 2.45 15 3 15H8C8.55 15 9 14.55 9 14ZM17.01 11.87L17.72 11.16C18.11 10.77 18.74 10.77 19.13 11.16L19.84 11.87C20.23 12.26 20.23 12.89 19.84 13.28L19.13 13.99L17.01 11.87ZM16.3 12.58L11.14 17.74C11.05 17.83 11 17.95 11 18.09V19.5C11 19.78 11.22 20 11.5 20H12.91C13.04 20 13.17 19.95 13.26 19.85L18.42 14.69L16.3 12.58Z"
                        fill="#767676"
                    />
                    <rect x="16" y="2" width="6" height="6" rx="3" fill="#D84315" />
                </svg>
            ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14 11C14 11.55 13.55 12 13 12H4C3.45 12 3 11.55 3 11C3 10.45 3.45 10 4 10H13C13.55 10 14 10.45 14 11ZM3 7C3 7.55 3.45 8 4 8H13C13.55 8 14 7.55 14 7C14 6.45 13.55 6 13 6H4C3.45 6 3 6.45 3 7ZM10 15C10 14.45 9.55 14 9 14H4C3.45 14 3 14.45 3 15C3 15.55 3.45 16 4 16H9C9.55 16 10 15.55 10 15ZM18.01 12.87L18.72 12.16C19.11 11.77 19.74 11.77 20.13 12.16L20.84 12.87C21.23 13.26 21.23 13.89 20.84 14.28L20.13 14.99L18.01 12.87ZM17.3 13.58L12.14 18.74C12.05 18.83 12 18.95 12 19.09V20.5C12 20.78 12.22 21 12.5 21H13.91C14.04 21 14.17 20.95 14.26 20.85L19.42 15.69L17.3 13.58Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.AddDescription:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13 10C13 10.55 12.55 11 12 11H3C2.45 11 2 10.55 2 10C2 9.45 2.45 9 3 9H12C12.55 9 13 9.45 13 10ZM2 6C2 6.55 2.45 7 3 7H12C12.55 7 13 6.55 13 6C13 5.45 12.55 5 12 5H3C2.45 5 2 5.45 2 6ZM9 14C9 13.45 8.55 13 8 13H3C2.45 13 2 13.45 2 14C2 14.55 2.45 15 3 15H8C8.55 15 9 14.55 9 14ZM17.01 11.87L17.72 11.16C18.11 10.77 18.74 10.77 19.13 11.16L19.84 11.87C20.23 12.26 20.23 12.89 19.84 13.28L19.13 13.99L17.01 11.87ZM16.3 12.58L11.14 17.74C11.05 17.83 11 17.95 11 18.09V19.5C11 19.78 11.22 20 11.5 20H12.91C13.04 20 13.17 19.95 13.26 19.85L18.42 14.69L16.3 12.58Z"
                        fill="#767676"
                    />
                    {props.empty && <rect x="16" y="2" width="6" height="6" rx="3" fill="#D84315" />}
                </svg>
            )

        case IconType.AddImage:
            return props.empty ? (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15 8.99997C13.5 7.49995 13.7338 4.5 14.4 4H6C4.9 4 4 4.9 4 6V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V9.76793C19 10.2679 16.5 10.5 15 8.99997ZM16.96 18H7C6.59 18 6.35 17.53 6.6 17.2L8.58 14.57C8.79 14.29 9.2 14.31 9.4 14.59L11 17L13.61 13.52C13.81 13.26 14.2 13.25 14.4 13.51L17.35 17.19C17.61 17.52 17.38 18 16.96 18Z"
                        fill="#767676"
                    />
                    <rect x="15" y="3" width="6" height="6" rx="3" fill="#D84315" />
                </svg>
            ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 9.01V8H14.99C14.46 8 13.96 7.79 13.58 7.42C13.21 7.04 13 6.54 13 5.98C13 5.62 13.1 5.29 13.27 5H5C3.9 5 3 5.9 3 7V19C3 20.1 3.9 21 5 21H17C18.1 21 19 20.1 19 19V10.72C18.7 10.89 18.36 11 17.98 11C16.89 10.99 16 10.1 16 9.01ZM15.96 19H6C5.59 19 5.35 18.53 5.6 18.2L7.58 15.57C7.79 15.29 8.2 15.31 8.4 15.59L10 18L12.61 14.52C12.81 14.26 13.2 14.25 13.4 14.51L16.35 18.19C16.61 18.52 16.38 19 15.96 19Z"
                        fill="#767676"
                    />
                    <path
                        d="M21.02 5H19V2.98C19 2.44 18.56 2 18.02 2H17.99C17.44 2 17 2.44 17 2.98V5H14.99C14.45 5 14.01 5.44 14 5.98V6.01C14 6.56 14.44 7 14.99 7H17V9.01C17 9.55 17.44 10 17.99 9.99H18.02C18.56 9.99 19 9.55 19 9.01V7H21.02C21.56 7 22 6.56 22 6.02V5.98C22 5.44 21.56 5 21.02 5Z"
                        fill="#459B68"
                    />
                </svg>
            )

        case IconType.Chat:
            return (
                <svg version="1.1" width="18" height="18" fill={props.color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
                    <path
                        d="M442.213,0H176.464c-32.597,0-59.136,26.517-59.136,59.115V64h218.219c56.128,0,101.781,45.675,101.781,101.781v132.885
                        h4.885c32.597,0,59.115-26.517,59.115-59.115V59.115C501.328,26.517,474.811,0,442.213,0z"
                    />
                    <path
                        d="M335.557,106.667H69.787c-32.597,0-59.115,26.517-59.115,59.115v180.437c0,32.597,26.517,59.115,59.115,59.115h4.885
                        v85.333c0,8.619,5.184,16.405,13.163,19.712c2.645,1.088,5.419,1.621,8.171,1.621c5.547,0,11.008-2.176,15.083-6.251
                        l100.416-100.416h124.053c32.597,0,59.115-26.517,59.115-59.115V165.781C394.672,133.184,368.155,106.667,335.557,106.667z
                         M117.339,277.333c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333
                        S129.115,277.333,117.339,277.333z M202.672,277.333c-11.776,0-21.333-9.557-21.333-21.333s9.557-21.333,21.333-21.333
                        s21.333,9.557,21.333,21.333S214.448,277.333,202.672,277.333z M288.005,277.333c-11.776,0-21.333-9.557-21.333-21.333
                        s9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333S299.781,277.333,288.005,277.333z"
                    />
                </svg>
            )

        case IconType.BoldPlus:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill={props.color || 'none'} xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 2C6 0.895431 6.89543 0 8 0C9.10457 0 10 0.895431 10 2V14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14V2Z" fill={props.color || '#354A63'} />
                    <path d="M6 6V10H2C0.89543 10 -3.86258e-08 9.10457 0 8C3.86258e-08 6.89543 0.895431 6 2 6L6 6Z" fill={props.color || '#354A63'} />
                    <path d="M14 6C15.1046 6 16 6.89543 16 8C16 9.10457 15.1046 10 14 10H10V6L14 6Z" fill={props.color || '#354A63'} />
                </svg>
            )

        case IconType.UserReverse:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 18C7.97 18 5.57 17.18 3.86 15.12C5.55 13.8 7.68 13 10 13C12.32 13 14.45 13.8 16.14 15.12C14.43 17.18 12.03 18 10 18Z"
                        fill="#354A63"
                    />
                </svg>
            )

        case IconType.GoToPage:
            return (
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 11.0026H2C1.63333 11.0026 1.33333 10.7026 1.33333 10.3359V2.33594C1.33333 1.96927 1.63333 1.66927 2 1.66927H5.33333C5.7 1.66927 6 1.36927 6 1.0026C6 0.635938 5.7 0.335938 5.33333 0.335938H1.33333C0.593333 0.335938 0 0.935938 0 1.66927V11.0026C0 11.7359 0.6 12.3359 1.33333 12.3359H10.6667C11.4 12.3359 12 11.7359 12 11.0026V7.0026C12 6.63594 11.7 6.33594 11.3333 6.33594C10.9667 6.33594 10.6667 6.63594 10.6667 7.0026V10.3359C10.6667 10.7026 10.3667 11.0026 10 11.0026ZM7.33333 1.0026C7.33333 1.36927 7.63333 1.66927 8 1.66927H9.72667L3.64 7.75594C3.38 8.01594 3.38 8.43594 3.64 8.69594C3.9 8.95594 4.32 8.95594 4.58 8.69594L10.6667 2.60927V4.33594C10.6667 4.7026 10.9667 5.0026 11.3333 5.0026C11.7 5.0026 12 4.7026 12 4.33594V1.0026C12 0.635938 11.7 0.335938 11.3333 0.335938H8C7.63333 0.335938 7.33333 0.635938 7.33333 1.0026Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.GoToShopping:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7 18C5.9 18 5.01 18.9 5.01 20C5.01 21.1 5.9 22 7 22C8.1 22 9 21.1 9 20C9 18.9 8.1 18 7 18ZM17 18C15.9 18 15.01 18.9 15.01 20C15.01 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18ZM19 16C19 15.45 18.55 15 18 15H7L8.1 13H15.55C16.3 13 16.96 12.59 17.3 11.97L20.54 5.83C20.79 5.35 20.62 4.75 20.14 4.49C19.65 4.22 19.04 4.41 18.78 4.9L15.55 11H8.53L4.54 2.57C4.38 2.22 4.02 2 3.64 2H2C1.45 2 1 2.45 1 3C1 3.55 1.45 4 2 4H3L6.6 11.59L5.25 14.03C4.52 15.37 5.48 17 7 17H18C18.55 17 19 16.55 19 16ZM11.29 2.71C11.68 2.32 12.31 2.32 12.7 2.71L15.29 5.3C15.68 5.69 15.68 6.32 15.29 6.71L12.7 9.3C12.31 9.69 11.68 9.69 11.29 9.3C10.9 8.91 10.9 8.28 11.29 7.89L12.17 7H9C8.45 7 8 6.55 8 6C8 5.45 8.45 5 9 5H12.17L11.29 4.12C10.9 3.73 10.9 3.1 11.29 2.71Z"
                        fill="#767676"
                    />
                </svg>
            )

        case IconType.Type:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.1501 3.39992L7.43012 9.47992C7.02012 10.1399 7.50012 10.9999 8.28012 10.9999H15.7101C16.4901 10.9999 16.9701 10.1399 16.5601 9.47992L12.8501 3.39992C12.4601 2.75992 11.5401 2.75992 11.1501 3.39992Z"
                        fill="#354A63"
                    />
                    <path d="M17.5 22C19.9853 22 22 19.9853 22 17.5C22 15.0147 19.9853 13 17.5 13C15.0147 13 13 15.0147 13 17.5C13 19.9853 15.0147 22 17.5 22Z" fill="#354A63" />
                    <path d="M4 21.5H10C10.55 21.5 11 21.05 11 20.5V14.5C11 13.95 10.55 13.5 10 13.5H4C3.45 13.5 3 13.95 3 14.5V20.5C3 21.05 3.45 21.5 4 21.5Z" fill="#354A63" />
                </svg>
            )

        case IconType.Maintenance:
            return (
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.5376 1.78065C8.64413 -0.106783 5.80869 -0.491782 3.52903 0.616263L6.94852 4.02491C7.3159 4.39113 7.3159 4.98271 6.94852 5.34893L5.45073 6.84197C5.08334 7.21758 4.48988 7.21758 4.1225 6.84197L0.703015 3.43333C-0.399132 5.71515 -0.0129094 8.52282 1.88052 10.4103C3.63265 12.1568 6.19491 12.6169 8.37095 11.8L15.8693 19.2746C16.8396 20.2418 18.4033 20.2418 19.3642 19.2746C20.3344 18.3074 20.3344 16.7486 19.3642 15.7908L11.9035 8.3444C12.7701 6.1471 12.318 3.5554 10.5376 1.78065Z"
                        fill="#9B8345"
                    />
                </svg>
            )

        case IconType.Ticket:
            return (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.26312 9.84762V6.28571C8.26312 4.97143 9.34584 3.90476 10.6799 3.90476C12.014 3.90476 13.0967 4.97143 13.0967 6.28571V9.84762C14.2664 9.07619 15.0301 7.77143 15.0301 6.28571C15.0301 3.91429 13.087 2 10.6799 2C8.27279 2 6.32969 3.91429 6.32969 6.28571C6.32969 7.77143 7.0934 9.07619 8.26312 9.84762ZM13.58 12.2C13.3094 12.0667 13.0193 12 12.7197 12H12.13V6.28571C12.13 5.49524 11.4823 4.85714 10.6799 4.85714C9.87753 4.85714 9.22983 5.49524 9.22983 6.28571V16.5143L5.90434 15.8286C5.54666 15.7524 5.16964 15.8667 4.90863 16.1238C4.49294 16.5429 4.49294 17.2095 4.90863 17.6286L8.78514 21.4476C9.15249 21.8 9.64552 22 10.1579 22H16.0548C17.0215 22 17.8336 21.3048 17.9689 20.3619L18.5779 16.1048C18.6939 15.2952 18.2686 14.4952 17.5242 14.1333L13.58 12.2Z"
                        fill="#429494"
                    />
                </svg>
            )
        case IconType.Replenishment:
            return (
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            d="M12.2913 13.2667L8.42468 8.11341V5.33341L9.32468 4.20675C9.49801 3.98675 9.34468 3.66675 9.06468 3.66675H5.11801C4.83801 3.66675 4.68468 3.98675 4.85801 4.20675L5.75801 5.33341V8.11341L1.89134 13.2667C1.56468 13.7067 1.87801 14.3334 2.42468 14.3334H11.758C12.3047 14.3334 12.618 13.7067 12.2913 13.2667Z"
                            fill="#767676"
                        />
                        <path
                            d="M13.4969 19.354C13.2899 18.904 13.6139 18.4 14.1089 18.4C14.3699 18.4 14.6129 18.553 14.7209 18.796C15.2159 19.885 16.3139 20.65 17.5919 20.65C19.3289 20.65 20.7419 19.237 20.7419 17.5C20.7419 15.763 19.3289 14.35 17.5919 14.35C16.7369 14.35 15.9539 14.692 15.3869 15.25H16.0169C16.3859 15.25 16.6919 15.556 16.6919 15.925C16.6919 16.294 16.3859 16.6 16.0169 16.6H13.9919C13.4969 16.6 13.0919 16.195 13.0919 15.7V13.675C13.0919 13.306 13.3979 13 13.7669 13C14.1359 13 14.4419 13.306 14.4419 13.675V14.287C15.2519 13.495 16.3679 13 17.5919 13C20.0759 13 22.0919 15.016 22.0919 17.5C22.0919 19.984 20.0759 22 17.5919 22C15.7649 22 14.1989 20.911 13.4969 19.354Z"
                            fill="#4F0DA5"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_2629_3524">
                            <rect width="24" height="24" fill="white" transform="translate(0.0918579)" />
                        </clipPath>
                    </defs>
                </svg>
            )
        case IconType.ThreeShape:
            return (
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.29177 0.83335L4.19177 5.90002C3.8501 6.45002 4.2501 7.16668 4.9001 7.16668H11.0918C11.7418 7.16668 12.1418 6.45002 11.8001 5.90002L8.70843 0.83335C8.38343 0.300016 7.61677 0.300016 7.29177 0.83335Z"
                        fill="#767676"
                    />
                    <path
                        d="M12.5833 16.3333C14.6543 16.3333 16.3333 14.6543 16.3333 12.5833C16.3333 10.5122 14.6543 8.83325 12.5833 8.83325C10.5122 8.83325 8.83325 10.5122 8.83325 12.5833C8.83325 14.6543 10.5122 16.3333 12.5833 16.3333Z"
                        fill="#767676"
                    />
                    <path
                        d="M1.33333 15.9167H6.33333C6.79167 15.9167 7.16667 15.5417 7.16667 15.0833V10.0833C7.16667 9.625 6.79167 9.25 6.33333 9.25H1.33333C0.875 9.25 0.5 9.625 0.5 10.0833V15.0833C0.5 15.5417 0.875 15.9167 1.33333 15.9167Z"
                        fill="#767676"
                    />
                </svg>
            )
        case IconType.DottedCircle:
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.7253 3.43328C12.5503 3.99995 14.0003 5.44995 14.567 7.27495C14.7253 7.79995 15.2003 8.16661 15.742 8.16661H15.7753C16.6087 8.16661 17.2087 7.36661 16.967 6.57495C16.1587 3.92495 14.067 1.83328 11.417 1.02495C10.6337 0.783279 9.83366 1.38328 9.83366 2.21661V2.24995C9.83366 2.79995 10.2003 3.27495 10.7253 3.43328ZM14.567 10.7249C14.0003 12.5583 12.5503 13.9999 10.7253 14.5666C10.2003 14.7249 9.83366 15.1999 9.83366 15.7416V15.7749C9.83366 16.6083 10.6337 17.2083 11.4253 16.9666C14.0753 16.1583 16.167 14.0666 16.9753 11.4166C17.217 10.6249 16.617 9.82495 15.792 9.82495H15.7503C15.2003 9.83328 14.7253 10.1999 14.567 10.7249ZM8.16699 15.7583C8.16699 15.1999 7.79199 14.7249 7.25866 14.5583C4.89199 13.8166 3.16699 11.6083 3.16699 8.99995C3.16699 6.39161 4.89199 4.18328 7.25866 3.44161C7.79199 3.27495 8.16699 2.79995 8.16699 2.24161V2.23328C8.16699 1.39995 7.35866 0.783279 6.55866 1.03328C3.15033 2.07495 0.666992 5.24995 0.666992 8.99995C0.666992 12.7499 3.15033 15.9249 6.55866 16.9666C7.35866 17.2166 8.16699 16.5999 8.16699 15.7583Z"
                        fill="#767676"
                    />
                </svg>
            )
        case IconType.FilterSettings:
            return (
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="1" width="10" height="2" rx="1" fill="#354A63" />
                    <rect x="8" y="7" width="10" height="2" rx="1" fill="#354A63" />
                    <rect x="8" y="13" width="10" height="2" rx="1" fill="#354A63" />
                    <rect x="12" y="1" width="6" height="2" rx="1" fill="#354A63" />
                    <rect y="7" width="6" height="2" rx="1" fill="#354A63" />
                    <rect y="13" width="6" height="2" rx="1" fill="#354A63" />
                    <rect x="11" width="4" height="4" rx="2" fill="#354A63" />
                    <rect x="3" y="6" width="4" height="4" rx="2" fill="#354A63" />
                    <rect x="7" y="12" width="4" height="4" rx="2" fill="#354A63" />
                </svg>
            )
        case IconType.ManufacturerLogo:
            return (
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" fill="url(#pattern0)" />
                    <defs>
                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use transform="scale(0.005)" />
                        </pattern>
                        <image id="image0_3132_1616" width="200" height="200" />
                    </defs>
                </svg>
            )

        case IconType.AddToCart:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 9C12.55 9 13 8.55 13 8V6H15C15.55 6 16 5.55 16 5C16 4.45 15.55 4 15 4H13V2C13 1.45 12.55 1 12 1C11.45 1 11 1.45 11 2V4H9C8.45 4 8 4.45 8 5C8 5.55 8.45 6 9 6H11V8C11 8.55 11.45 9 12 9Z"
                        fill="#FDC00E"
                    />
                    <path
                        d="M7 18C5.9 18 5.01 18.9 5.01 20C5.01 21.1 5.9 22 7 22C8.1 22 9 21.1 9 20C9 18.9 8.1 18 7 18ZM17 18C15.9 18 15.01 18.9 15.01 20C15.01 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18ZM8.1 13H15.55C16.3 13 16.96 12.59 17.3 11.97L20.54 5.83C20.79 5.35 20.62 4.75 20.14 4.49C19.65 4.22 19.04 4.41 18.78 4.9L15.55 11H8.53L4.27 2H2C1.45 2 1 2.45 1 3C1 3.55 1.45 4 2 4H3L6.6 11.59L5.25 14.03C4.52 15.37 5.48 17 7 17H18C18.55 17 19 16.55 19 16C19 15.45 18.55 15 18 15H7L8.1 13Z"
                        fill="#FDC00E"
                    />
                </svg>
            )
        case IconType.SendReminder:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z" fill="#E5AB00" />
                    <path
                        d="M10.67 13.02C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.47 18.87 10 17.49 10 16C10 14.93 10.25 13.93 10.67 13.02Z"
                        fill="#E5AB00"
                    />
                    <path
                        d="M14.7834 12.8103L14.0662 12.0769C12.8626 13.0154 12.0702 14.4615 12 16.1026H13.003C13.0782 14.7436 13.7603 13.5538 14.7834 12.8103ZM20.997 16.1026H22C21.9248 14.4615 21.1324 13.0154 19.9338 12.0769L19.2217 12.8103C20.2347 13.5538 20.9218 14.7436 20.997 16.1026ZM20.009 16.359C20.009 14.7846 19.1866 13.4667 17.7523 13.1179V12.7692C17.7523 12.3436 17.4162 12 17 12C16.5838 12 16.2477 12.3436 16.2477 12.7692V13.1179C14.8084 13.4667 13.991 14.7795 13.991 16.359V18.9231L12.988 19.9487V20.4615H21.012V19.9487L20.009 18.9231V16.359ZM17 22C17.0702 22 17.1354 21.9949 17.2006 21.9795C17.5266 21.9077 17.7924 21.6821 17.9228 21.3744C17.9729 21.2513 17.998 21.1179 17.998 20.9744H15.992C15.997 21.5385 16.4433 22 17 22Z"
                        fill="#E5AB00"
                    />
                </svg>
            )
        case IconType.CheckCircle:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                        fill="#459B68"
                    />
                </svg>
            )

        case IconType.HistoricalLog:
            return (
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.39346 2.42978C4.12497 2.42978 3.9053 2.64688 3.9053 2.91222V4.842C3.9053 4.97226 3.95899 5.09287 4.04686 5.18454L5.05736 6.18319L5.56505 5.31962L4.88162 4.6442V2.91222C4.88162 2.64688 4.66195 2.42978 4.39346 2.42978ZM0.615084 5.32444C0.297779 5.32444 0.0585794 5.61873 0.136685 5.92267C0.624847 7.79938 2.34318 9.184 4.39346 9.184C4.40322 9.184 4.41787 9.184 4.42763 9.184L3.83695 8.17569C2.49451 7.95859 1.41567 6.96475 1.08372 5.67663C1.03002 5.46435 0.834757 5.32444 0.615084 5.32444ZM0.488162 3.87711C0.219673 3.87711 0 3.66001 0 3.39467V1.46489C0 1.19954 0.219673 0.982444 0.488162 0.982444C0.756651 0.982444 0.976324 1.19954 0.976324 1.46489V2.12101C1.78179 1.13683 3.01196 0.5 4.39346 0.5C6.81962 0.5 8.78692 2.44425 8.78692 4.842H7.81059C7.81059 2.97976 6.27776 1.46489 4.39346 1.46489C3.23651 1.46489 2.21137 2.039 1.59141 2.91222H2.44081C2.7093 2.91222 2.92897 3.12932 2.92897 3.39467C2.92897 3.66001 2.7093 3.87711 2.44081 3.87711H0.488162Z"
                        fill="#C4C4C4"
                    />
                    <path
                        d="M9.16402 5.948L6.74762 8.3361L5.71272 7.31332C5.52233 7.12517 5.21479 7.12517 5.02441 7.31332C4.83402 7.50147 4.83402 7.80541 5.02441 7.99357L6.40591 9.35889C6.59629 9.54704 6.90383 9.54704 7.09422 9.35889L9.85721 6.62825C10.0476 6.4401 10.0476 6.13616 9.85721 5.948C9.66195 5.75985 9.35441 5.75985 9.16402 5.948Z"
                        fill="#C4C4C4"
                    />
                </svg>
            )
        case IconType.Contract:
            return (
                <svg
                    className={props.className}
                    onClick={props.onClick ? props.onClick : undefined}
                    fill={props.color ? color : 'currentColor'}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.4797 10.4117C16.0897 10.8017 15.4397 10.8017 15.0497 10.4117L10.5797 5.95167L3.52969 12.9917L2.86969 12.3617C1.69969 11.1917 1.69969 9.29167 2.86969 8.12167L7.10969 3.88167C8.27969 2.71167 10.1797 2.71167 11.3497 3.88167L16.4797 9.00167C16.8697 9.39167 16.8697 10.0217 16.4797 10.4117ZM17.1797 8.29167C17.9597 9.07167 17.9597 10.3417 17.1797 11.1217C15.9097 12.3917 14.5697 11.3417 14.3497 11.1217L10.5897 7.36167L5.01969 12.9317C4.62969 13.3217 4.62969 13.9517 5.01969 14.3417C5.40969 14.7317 6.03969 14.7317 6.43969 14.3417L11.0597 9.72167L11.7697 10.4317L7.14969 15.0517C6.75969 15.4417 6.75969 16.0717 7.14969 16.4617C7.53969 16.8517 8.16969 16.8517 8.56969 16.4617L13.1897 11.8417L13.8997 12.5517L9.27969 17.1717C8.88969 17.5617 8.88969 18.1917 9.27969 18.5817C9.66969 18.9717 10.2997 18.9717 10.6897 18.5817L15.3097 13.9617L16.0197 14.6717L11.3997 19.2917C11.0097 19.6817 11.0097 20.3117 11.3997 20.7017C11.7897 21.0917 12.4197 21.0917 12.8097 20.7017L21.1297 12.3617C22.2997 11.1917 22.2997 9.29167 21.1297 8.12167L16.8897 3.88167C15.7397 2.73167 13.8797 2.71167 12.7097 3.82167L17.1797 8.29167Z"
                        fill="currentColor"
                    />
                </svg>
            )

        case IconType.AddSubEquipment:
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.667 9.66667C10.667 9.29848 10.9655 9 11.3337 9C11.7018 9 12.0003 9.29848 12.0003 9.66667V12.3333C12.0003 12.7015 11.7018 13 11.3337 13C10.9655 13 10.667 12.7015 10.667 12.3333V9.66667Z"
                        fill="#767676"
                    />
                    <path d="M12.667 10.3333C13.0352 10.3333 13.3337 10.6318 13.3337 11C13.3337 11.3682 13.0352 11.6667 12.667 11.6667H12.0003V10.3333H12.667Z" fill="#767676" />
                    <path d="M10.667 10.3333V11.6667H10.0003C9.63214 11.6667 9.33366 11.3682 9.33366 11C9.33366 10.6318 9.63214 10.3333 10.0003 10.3333H10.667Z" fill="#767676" />
                    <path
                        d="M2.66699 3.66667C2.66699 3.29848 2.96547 3 3.33366 3H6.00033C6.36852 3 6.66699 3.29848 6.66699 3.66667V6.33333C6.66699 6.70152 6.36852 7 6.00033 7H3.33366C2.96547 7 2.66699 6.70152 2.66699 6.33333V3.66667Z"
                        fill="#767676"
                    />
                    <path
                        d="M2.66699 9.66667C2.66699 9.29848 2.96547 9 3.33366 9H6.00033C6.36852 9 6.66699 9.29848 6.66699 9.66667V12.3333C6.66699 12.7015 6.36852 13 6.00033 13H3.33366C2.96547 13 2.66699 12.7015 2.66699 12.3333V9.66667Z"
                        fill="#767676"
                    />
                    <path d="M4.00033 7H5.33366V9H4.00033V7Z" fill="#767676" />
                    <path d="M6.66699 10.3333H8.66699V11.6667H6.66699V10.3333Z" fill="#767676" />
                </svg>
            )
        case IconType.HortizalRow:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 5C2 4.44772 2.44772 4 3 4H10C10.5523 4 11 4.44772 11 5V19C11 19.5523 10.5523 20 10 20H3C2.44772 20 2 19.5523 2 19V5Z" fill="#354A63" />
                    <path d="M13 5C13 4.44772 13.4477 4 14 4H21C21.5523 4 22 4.44772 22 5V19C22 19.5523 21.5523 20 21 20H14C13.4477 20 13 19.5523 13 19V5Z" fill="#354A63" />
                </svg>
            )

        case IconType.TreeViewSub:
            return (
                <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.666016 1.33464C0.666016 0.966446 0.964492 0.667969 1.33268 0.667969H5.33268C5.70087 0.667969 5.99935 0.966446 5.99935 1.33464V3.33464C5.99935 3.70283 5.70087 4.0013 5.33268 4.0013H1.33268C0.964492 4.0013 0.666016 3.70283 0.666016 3.33464V1.33464Z"
                        fill="#767676"
                    />
                    <path
                        d="M3.99935 6.0013C3.99935 5.63311 4.29783 5.33464 4.66602 5.33464H8.66602C9.0342 5.33464 9.33268 5.63311 9.33268 6.0013V8.0013C9.33268 8.36949 9.0342 8.66797 8.66602 8.66797H4.66602C4.29783 8.66797 3.99935 8.36949 3.99935 8.0013V6.0013Z"
                        fill="#767676"
                    />
                    <path
                        d="M3.99935 10.668C3.99935 10.2998 4.29783 10.0013 4.66602 10.0013H8.66602C9.0342 10.0013 9.33268 10.2998 9.33268 10.668V12.668C9.33268 13.0362 9.0342 13.3346 8.66602 13.3346H4.66602C4.29783 13.3346 3.99935 13.0362 3.99935 12.668V10.668Z"
                        fill="#767676"
                    />
                    <path d="M1.99935 4.0013H2.66602V12.0013C2.29783 12.0013 1.99935 11.7028 1.99935 11.3346V4.0013Z" fill="#767676" />
                    <path d="M2.66602 11.3346H3.99935V12.0013H2.66602V11.3346Z" fill="#767676" />
                    <path d="M2.66602 6.66797H3.99935V7.33463H2.66602V6.66797Z" fill="#767676" />
                </svg>
            )
        case IconType.EditHotspot:
            return (
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_7886_198374)">
                        <g filter="url(#filter1_b_7886_198374)">
                            <rect x="24" y="24" width="2" height="2" rx="1" fill="#354A63" />
                            <rect x="16" y="16" width="18" height="18" rx="9" stroke="url(#paint0_linear_7886_198374)" strokeWidth="2" />
                            <rect x="12.5" y="12.5" width="25" height="25" rx="12.5" stroke="#D84315" />
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_d_7886_198374" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset />
                            <feGaussianBlur stdDeviation="6" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.207843 0 0 0 0 0.290196 0 0 0 0 0.388235 0 0 0 0.5 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7886_198374" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7886_198374" result="shape" />
                        </filter>
                        <filter id="filter1_b_7886_198374" x="8" y="8" width="34" height="34" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7886_198374" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7886_198374" result="shape" />
                        </filter>
                        <linearGradient id="paint0_linear_7886_198374" x1="16" y1="25" x2="33.8179" y2="26.8015" gradientUnits="userSpaceOnUse">
                            <stop offset="0.181635" stopColor="#334A62" />
                            <stop offset="1" stopColor="#354A63" stopOpacity="0.25" />
                        </linearGradient>
                    </defs>
                </svg>
            )
        case IconType.GreenTick:
            return (
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z"
                        fill="#459B68"
                    />
                </svg>
            )
        case IconType.RigthOk:
            return (
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.4 17.3995L18.85 9.91953C19.66 9.56953 19.66 8.42953 18.85 8.07953L1.4 0.599529C0.74 0.309529 0.00999999 0.799529 0.00999999 1.50953L0 6.11953C0 6.61953 0.37 7.04953 0.87 7.10953L15 8.99953L0.87 10.8795C0.37 10.9495 0 11.3795 0 11.8795L0.00999999 16.4895C0.00999999 17.1995 0.74 17.6895 1.4 17.3995Z"
                        fill="#767676"
                    />
                </svg>
            )
        default:
            return <></>
    }
}

export default Icons
