import { EquipmentModel } from '../New/Equipment'
import { ActivityModel } from './Activity'
import { CompanyType } from './CompanyType'
import NotificationModel from './Notification'
import { UserRoleType } from './UserRoleType'
import { ProductListModel } from './../New/Product'

export interface AccountManagerModel {
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
}
export interface CompanyDashboardModel {
    user: {
        creationDate: string
        isDeleted: boolean
        id: string
        email: string
        firstName: string
        lastName: string
        phone: string
        role: string
        jobTitle: string
        isQuoteRequestReceiver: boolean
        isPending: boolean
        isDisabled: boolean
        isPrimary: boolean
        status: string
        company: {
            id: string
            type: string
            email: string
            name: string
            about: string
            phone: string
            firebaseId: string
            hsCompanyId: string
            hsApiKey: string
            hsLastSyncTime: string
        }
    }
    accountManager?: AccountManagerModel
    activities: ActivityModel[]
    notifications: NotificationModel[]
    equipments: EquipmentModel[]
    products: ProductListModel[]
}
export interface CompanyModel {
    id: string
    name: string
    about?: string
    email: string
    phone?: string
    website?: string
    type: CompanyType
    firebaseId: string
    user: CompanyUserModel
    shippingAddress?: AddressModel
    billingAddress?: AddressModel
    hsCompanyId?: string
    hsApiKey?: string
    hsLastSyncTime?: string
    addresses: AddressModel[]
    logoUrl: string
}

export interface AddressModel {
    id: string
    label: string
    firstName: string
    lastName: string
    phoneNumber: string
    street1: string
    street2: string
    city: string
    state: string
    postcode: string
    country: string
}

export interface UpdateCompanyModel {
    id: string
    name?: string
    website?: string
    about?: string
    phone?: string
    email: string
    shippingAddressId?: string
    billingAddressId?: string
    logoUrl?: string
    accountManagerId?: string
}

export interface CompanyUserModel {
    id: string
    firstName: string
    lastName: string
    email: string
    role: UserRoleType
    isPrimary: boolean
}

export interface CreateCustomerModel {
    id: string
    firstName: string
    lastName: string
    email: string
    role: UserRoleType
    isPrimary: boolean
}

export enum InvitationStatus {
    NotInvited = 'not_invited',
    Pending = 'pending',
    Accept = 'accepted',
    Reject = 'rejected',
}

export interface CustomerVerificationModel {
    validationKey: string
    status: InvitationStatus
    email?: string
    firstName?: string
    lastName?: string
    password?: string
}

export interface ActivityCustomerListModel {
    id: string
    name: string
    logoUrl?: string
}
