import { useEffect, useState } from 'react'
import { TeamActionTypes } from '../..'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import PopoverItem from '../../../../components/PopoverItem'
import { MaxCharacter } from '../../../../models/enums'
import { UpdateUserModel, UserModel } from '../../../../models/Ui/User'
import UserService from '../../../../services/UiService/User'
import { isValidEmail } from '../../../../utils/helpers'
import './style.scss'

interface Props {
    user: UserModel
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function UpdateAccountDetailsModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted } = props

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [jobTitle, setJobTitle] = useState<string>('')

    useEffect(() => {
        setFirstName(props.user.firstName)
        setLastName(props.user.lastName)
        setEmail(props.user.email)
        setJobTitle(props.user.jobTitle)
    }, [props.user.email, props.user.firstName, props.user.lastName, props.user.jobTitle])

    async function updateAccountDetailsHandler() {
        try {
            onLoading()
            const userService = new UserService()
            const data: UpdateUserModel = {
                firstName,
                lastName,
                jobTitle,
                isQuoteRequestReceiver: props.user.isQuoteRequestReceiver,
                role: props.user.role,
                phone: props.user.phone,
            }
            const updateAccountDetailsResponse = await userService.update(data)
            onSuccess(TeamActionTypes.UPDATE_ACCOUNT_DETAILS, updateAccountDetailsResponse)
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return firstName.length > 0 && lastName.length > 0 && email.length > 0 && isValidEmail(email)
    }

    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setJobTitle('')
    }

    const clickPrimary = () => {
        updateAccountDetailsHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <Modal
            className="update-account-details-modal"
            visible={true}
            title="Edit account details"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="FIRST NAME" required max={MaxCharacter.name} />
                <Input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="LAST NAME" required max={MaxCharacter.name} />
            </div>
            <div className="modal-line">
                <Input disabled={true} value={email} onChange={(e) => setEmail(e.target.value)} placeholder="COMPANY EMAIL ADDRESS" required max={MaxCharacter.companyName} />
            </div>
            <div className="modal-line">
                <Input value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder="JOB TITLE / POSITION" />
            </div>
            <div className="modal-line">
                <PopoverItem
                    popoverContent={'You cannot change your role on Monittor unless you transfer the company’s account ownership.  Contact us at accounts@monittor.com to make a change request.'}
                >
                    <Input disabled={true} value={props.user.role} placeholder="ROLE" />
                </PopoverItem>
            </div>
        </Modal>
    )
}

export default UpdateAccountDetailsModal
