import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ChangeEventHandler, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { Size } from '../../models/enums'
import Checkbox from '../Checkbox'
import './DropdownWithSearch.scss'
import Icons, { IconType } from '../../Icon'
import PopoverItem from '../PopoverItem'

export enum DropdownFilterType {
    DueDate,
    Type,
    Status,
    None,
}

interface IDropdownItem {
    id: any
    text?: string
    icon?: IconType
}

interface Props {
    checkBox?: boolean
    selectedItem?: any
    listItems: IDropdownItem[]
    className?: string
    wrapperClass?: string
    size?: Size
    placeholder?: string
    type?: string
    labelName?: string
    required?: boolean
    disabled?: boolean
    outlinePlaceholder?: boolean
    startIcon?: IconType
    dropIcon?: boolean
    startIconColor?: string
    endIcon?: IconDefinition
    endIconColor?: string
    inputLabel?: string
    customIcon?: ReactElement | IconType
    isContentAbsolute?: boolean
    onDataChange?: (selectedItem: any) => void
    onBlur?: ChangeEventHandler<HTMLInputElement>
    onFocus?: ChangeEventHandler<HTMLInputElement>
    enableSearch?: boolean
    handleFilter?: (data: any[]) => void
    isFocusIcon?: boolean
    focusIcon?: IconType
    focusIconColor?: string
    width?: string
    filterType?: DropdownFilterType
    styleName?: string
    isClearSelected?: boolean
    isClearLabel?: string
    onClickClearSelected?: () => void
    notes?: string
    outline?: string
}

function DropdownWithSearch({ notes = '', enableSearch = true, outlinePlaceholder = true, filterType = DropdownFilterType.None, ...props }: React.PropsWithChildren<Props>) {
    if (props.listItems == null) {
        throw new Error('List items must be provided!')
    }
    const [selectedItem, setSelectedItem] = useState<any>(props.selectedItem ? props.selectedItem : props.listItems[0])
    const [showContent, setShowContent] = useState<boolean>(false)
    const [selectedIds, setSelectedIds] = useState([] as string[])
    const wrapperRef = useRef<any>(null)
    const [searchInput, setSearchInput] = useState<string>('' as string)

    let defaultClassName: string = 'input input-' + (props.size ? props.size : Size.lg)
    let hasIconClass: string = props.startIcon || (props.focusIcon && showContent) ? ' start-input-icon' : ''
    hasIconClass += props.endIcon ? ' end-input-icon' : ''

    const onChangeCheckbox = async (id: string) => {
        const index = selectedIds.indexOf(id)
        if (index > -1) {
            await setSelectedIds(selectedIds.filter((p) => p !== id))
        } else {
            await setSelectedIds([...selectedIds, id])
        }
    }

    const onChangeContentCallback = useCallback(async () => {
        if (props.checkBox) {
            props.onDataChange!(selectedIds)
        }
    }, [props.checkBox, props.onDataChange, selectedIds])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowContent(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    useEffect(() => {
        onChangeContentCallback()
    }, [onChangeContentCallback, showContent])

    useEffect(() => {
        setSelectedItem(props.selectedItem)
        if (props.selectedItem && props.selectedItem.text !== 'undefined') setSearchInput(props.selectedItem.text)
    }, [props.selectedItem])

    const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        // wrapperRef.current.addEventListener("keydown", (event: React.KeyboardEvent) => {
        //     if(event.code === "Backspace" && selectedItem)
        //     {
        //         clearInput()
        //     }
        // })
        setSearchInput(event.target.value)
    }

    const clearInput = () => {
        props.onClickClearSelected && props.onClickClearSelected()
        setSearchInput('')
    }

    return (
        <div className={`block-dropdown-input ${props.className}`} ref={wrapperRef} style={{ width: props.width }}>
            <div className="block-dropdown-input-container">
                <div className={props.wrapperClass ? props.wrapperClass + hasIconClass + ' input-wrapper' : 'input-wrapper' + hasIconClass}>
                    {props.isFocusIcon && showContent && (
                        <span className="start-icon focus-icon">
                            <Icons type={props.focusIcon as IconType} color={props.focusIconColor} />
                        </span>
                    )}
                    <input
                        disabled={props.disabled}
                        required={props.required}
                        type={props.type ? props.type : 'text'}
                        // value={props.checkBox ? selectedIds.length + ' ' + (props.inputLabel || '') : selectedItem ? selectedItem[props.labelName || 'text'] ?? selectedItem['name'] : searchInput}
                        // value={selectedItem ? selectedItem[props.labelName || 'text'] || selectedItem['name'] : searchInput}
                        value={searchInput}
                        onChange={enableSearch ? onSearchInput : undefined}
                        //itemID={selectedItem.id}
                        className={props.wrapperClass ? props.wrapperClass + ' ' + defaultClassName : defaultClassName}
                        onBlur={props.onBlur}
                        onFocus={(event: React.ChangeEvent<HTMLInputElement>) => {
                            props.onFocus && props.onFocus(event)
                            setShowContent(true)
                        }}
                        placeholder={!outlinePlaceholder ? props.placeholder : ' '}
                        style={showContent ? { outline: props.outline || `1px solid var(--yellow-100)` } : {}}
                    />
                    {props.dropIcon && !selectedItem?.id && !searchInput && (
                        <div className="down-icon">
                            <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0L8 8L16 0H0Z" fill="#767676" />
                            </svg>
                        </div>
                    )}

                    {(searchInput || selectedItem?.id) && (
                        <div className="clear-icon" onClick={clearInput}>
                            <PopoverItem popoverContent={props.isClearLabel || ''}>
                                <Icons type={IconType.Close} />
                            </PopoverItem>
                        </div>
                    )}

                    {props.startIcon && (
                        <span className="start-icon">
                            <Icons type={props.startIcon as IconType} color={props.startIconColor} />
                        </span>
                    )}
                    {!props.startIcon && props.customIcon && <span className="start-icon">{props.customIcon}</span>}
                    {outlinePlaceholder && <span className="label">{props.placeholder}</span>}
                    {props.endIcon && (
                        <span className="end-icon">
                            <FontAwesomeIcon icon={props.endIcon} color={props.endIconColor} />
                        </span>
                    )}
                    <div className="dropdown-input-transparent-container">
                        <div
                            className="dropdown-input-content"
                            style={{
                                display: showContent ? 'block' : 'none',
                                position: props.isContentAbsolute === undefined || props.isContentAbsolute ? 'absolute' : undefined,
                            }}
                        >
                            {notes && <div className="notes">{notes}</div>}
                            {props.listItems
                                .filter((item: IDropdownItem) => item.text?.toLowerCase()?.includes(searchInput?.toLowerCase()))
                                .map((item: IDropdownItem, index: number) => {
                                    if (item.id === -1) return undefined
                                    return (
                                        <div
                                            key={index}
                                            itemID={item.id}
                                            onClick={() => {
                                                if (props.checkBox) {
                                                    onChangeCheckbox(item.id)
                                                } else {
                                                    setSelectedItem(item)
                                                    setSearchInput(item.text ?? '')
                                                    if (props.onDataChange) {
                                                        props.onDataChange!(item)
                                                        setShowContent(false)
                                                    }
                                                }
                                            }}
                                            className="item-content"
                                        >
                                            {props.checkBox && <Checkbox onChange={() => onChangeCheckbox(item.id)} checked={selectedIds.includes(item.id)} />}

                                            <span className={props.styleName}>
                                                {item.icon && <Icons type={item.icon} />}
                                                {item.text}
                                            </span>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DropdownWithSearch
