import React, { useState } from 'react'
import Input from '../../../../../components/Input'
import TextArea from '../../../../../components/TextArea'
import { ShippingModel } from '../../../../../models/Ui/Equipment'
import './ShippingMethod.scss'
import BasicDropdown from '../../../../../components/Dropdown/BasicDropdown'
import CurrencyInput from '../../../../../components/CurrencyInput'

interface Props {
    className?: string
    currentMethod: ShippingModel
    handleMethod: Function
    isQuote?: boolean
}

const shippingType = [
    {
        text: 'Expedited',
        id: 'expedited',
    },
    {
        text: 'Standard',
        id: 'standard',
    },
    {
        text: 'Economy',
        id: 'economy',
    },
]

function ShippingMethod(props: React.PropsWithChildren<Props>) {
    const { currentMethod, handleMethod } = props
    const [selectedType, setSelectedType] = useState({ id: '', text: '' })
    return (
        <div className={props.className ? props.className + ' form' : 'form'}>
            <div className="form-container-line">
                <Input value={currentMethod.shipVIA} placeholder="Shipping Method" onChange={(e) => handleMethod({ shipVIA: e.target.value })} />
                {!props.isQuote && <CurrencyInput value={Number(currentMethod.shippingCost)} placeholder="Shipping Cost" onChange={(price) => handleMethod({ shippingCost: price })} />}
            </div>
            <div className="form-line" style={{ marginBottom: '10px' }}>
                <BasicDropdown
                    label="Type"
                    menuItems={shippingType}
                    onChange={(data) => {
                        handleMethod({ type: data.text })
                        setSelectedType(data)
                    }}
                    selectedItem={selectedType}
                />
            </div>
            <div className="form-line">
                <TextArea autoSize value={currentMethod.note} placeholder="Add note" onChange={(e) => handleMethod({ note: e.target.value })} />
            </div>
        </div>
    )
}

export default ShippingMethod
