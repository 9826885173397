import { useContext, useState } from 'react'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Button from '../../../components/Button'
import './EquipmentList.scss'
import { IconType } from '../../../Icon'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import { Size } from '../../../models/enums'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import Tab from '../../../components/Tab'
import SearchParts from './SearchParts'
import Input from '../../../components/Input'
import { SearchPartContext } from '../../../store/SearchPartContext'
import TechnicianView from '../TechnicianView'
import { CheckoutContext } from '../../../store/NewCheckoutContext/CheckoutContext'
import { ICartInformation, TechnicianViewType } from '../../../store/models'
import EasyIcon from '../../../components/EasyIcon'
import { useSearchParams } from 'react-router-dom'

const tabItems: TabItem[] = [
    {
        id: Tabs.SearchParts,
        name: 'Search Parts',
    },
]

function QuickCartPage() {
    const searchPartContext = useContext(SearchPartContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [searchParams, setSearchParams] = useSearchParams()

    const checkoutContext = useContext(CheckoutContext)

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick ? 'Quick Cart' : 'Search',
        },
    ]

    const onTabClick = (id: number) => {
        setActiveTab(id)
    }

    return (
        <PageWrapper breadcrumb={breadcrumb}>
            {loading && <Loading />}
            <TechnicianView isQuick disabled={activeTab !== Tabs.SearchParts} onLoading={() => setLoading(true)} onCompleted={() => setLoading(false)}>
                <ContentBox
                    headerIcon={checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick ? <EasyIcon icon={IconType.AddToCart} /> : <EasyIcon icon={IconType.Search} />}
                    title={checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick ? 'Quick Cart' : 'Search'}
                    className="quick-cart-content-box"
                    headerRight={
                        <div className="action-wrapper">
                            {activeTab === Tabs.SearchParts && !searchPartContext.isSearchMode && (
                                <Input
                                    startIcon={IconType.Search}
                                    startIconColor="#767676"
                                    value={searchPartContext.searchedText}
                                    onChange={(e) => {
                                        searchPartContext.handleSearchText(e.target.value)
                                    }}
                                    onKeyDown={(e: any) => {
                                        if (e.key === 'Enter' && searchPartContext.searchedText.length >= 3) {
                                            searchPartContext.handleSearch()
                                        }
                                    }}
                                    placeholder={'SEARCH'}
                                    size={Size.lg}
                                    onClickEndIcon={() => {
                                        searchPartContext.reset()
                                    }}
                                    endIcon={IconType.Close}
                                />
                            )}
                        </div>
                    }
                >
                    <div className="tab-container">
                        <Tab activeTab={activeTab} tabs={tabItems} onTabClick={onTabClick} />

                        {activeTab === Tabs.SearchParts && checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.Quick && (
                            <Button
                                className="quick-button"
                                onClick={async () => {
                                    await checkoutContext.handleCartInformation({
                                        technicianViewType: TechnicianViewType.Quick,
                                        quickCartShow: true,
                                        purchaseForCustomerId: checkoutContext.quickCartInformation.id,
                                        purchaseForCustomerName: checkoutContext.quickCartInformation.name,
                                    } as ICartInformation)
                                }}
                            >
                                Quick Cart
                            </Button>
                        )}
                    </div>
                    <SearchParts />
                </ContentBox>
            </TechnicianView>
        </PageWrapper>
    )
}

export default QuickCartPage
