export enum SearchPartsTableColumns {
    PartName = 'part_name',
    PartNumber = 'part_number',
    Customer = 'customer',
    Price = 'price',
    Action = 'action',
    EquipmentName = 'equipment_name',
}

export type SearchPartsTableColumnModel = {
    columnName: string
    width: string
    columnType: SearchPartsTableColumns
    align?: string
}

export interface EquipmentSubPartsModel {
    equipmentId: string
    id?: string
    refNo?: string | number
    partName: string
    partNumber: string | number
    customersCompanyName: string
    serialNumber: string | number
    price: number
    drawing?: string
}

export interface SearchPartsViewModel {
    equipmentGroupName: string
    parts: EquipmentSubPartsModel[]
}
