import { useRef, useState } from 'react'
import Loading from '../../../components/Loading'
import './CustomerDetailCard.scss'
import { CustomerContactModel, CustomerDetailModel } from '../../../models/New/Customer'
import ContactModal from './Contacts/Modals/ContactModal'
import { ContractActionTypes } from '.'
import ContactsMenu from './Contacts/index'

interface Props {
    data: CustomerDetailModel
    handleUpdateCompany: () => void
    onSuccess: (action: ContractActionTypes, data?: CustomerContactModel) => void
    isAddPrimaryContact?: boolean
}

function CustomerDetailCard(props: Props) {
    const [loading, setLoading] = useState<boolean>(false)
    const [contactModalVisible, setContactModalVisible] = useState<boolean>(false)

    const contactRef = useRef<CustomerContactModel>()
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return (
        <>
            {loading && <Loading />}
            <div className="contacts-detail-card">
                <ContactsMenu onLoading={() => setLoading(true)} onCompleted={() => setLoading(false)} onSuccess={props.onSuccess} contacts={props.data.contacts} />
            </div>
            {contactModalVisible && (
                <ContactModal
                    onSuccess={props.onSuccess}
                    onLoading={() => setLoading(true)}
                    onCompleted={() => setLoading(false)}
                    contact={contactRef.current ? contactRef.current : undefined}
                    visible={contactModalVisible}
                    closeModal={() => {
                        setContactModalVisible(false)
                        contactRef.current = undefined
                    }}
                />
            )}
        </>
    )
}

export default CustomerDetailCard
