import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { arraySort } from '../../../../utils/helpers'
import Icons, { IconType } from '../../../../Icon'
import './style.scss'
import NoDataInfo from '../../../../components/NoDataInfo'

interface Data {
    name: string
    fileType?: string
    onClick: () => void
}

interface HeadCell {
    id: keyof Data | any
    label: string
}

interface Props {
    data: Data[]
    setSearch: (key: string) => void
}

const columns: HeadCell[] = [
    {
        id: 'name',
        label: 'Title',
    },
    {
        id: 'fileType',
        label: 'File Type',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    const { active, direction, children, onclick, disable } = props

    return !disable ? (
        <div className="sorting-menu">{children}</div>
    ) : (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            {disable && <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const { orderBy, setOrderBy, direction, setDirection } = tableProps

    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'} width={headCell.id === 'buttons' ? '100px' : 'auto'}>
                        <SortingMenu
                            disable={headCell.id !== 'buttons' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index} onClick={row.onClick}>
                                        <TableCell className="font-bold" style={{ width: '30%' }} align="left">
                                            <span className="ellipsis-text">{row.name}</span>
                                        </TableCell>
                                        <TableCell style={{ width: '30%' }} align="left">
                                            {row.fileType}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
