import { MonitorApi } from '../../api'
import { ContractBasicDto, ContractInputModel, ContractOutputModel } from '../../models/Ui/Contract'
import { DocumentListModel } from '../../models/New/Document'
import { ProductListModel } from '../../models/New/Product'

class ContractService {
    private baseUrl = '/contract'

    getAll = async (): Promise<ContractOutputModel[]> => {
        try {
            const response = await MonitorApi.get(`${this.baseUrl}`, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    getAllByCustomerId = async (customerId: string): Promise<ContractOutputModel[]> => {
        try {
            const response = await MonitorApi.get(this.baseUrl + '/customer/' + customerId, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    get = async (id: string): Promise<ContractOutputModel> => {
        try {
            const response = await MonitorApi.get(`${this.baseUrl}/${id}`, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    getCustomersContracts = async (customerId: string): Promise<ContractBasicDto[]> => {
        try {
            const response = await MonitorApi.get(`${this.baseUrl}/customer/${customerId}/list`, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    create = async (args: ContractInputModel): Promise<boolean> => {
        try {
            const response = await MonitorApi.post(`${this.baseUrl}`, args, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    update = async (id: string, args: ContractInputModel): Promise<boolean> => {
        try {
            const response = await MonitorApi.put(`${this.baseUrl}/${id}`, args, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    delete = async (id: string): Promise<boolean> => {
        try {
            const response = await MonitorApi.delete(`${this.baseUrl}/${id}`, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async unlinkDocument(contractId: string, documentId: string): Promise<ProductListModel> {
        try {
            const response = await MonitorApi.post(`${this.baseUrl}/${contractId}/document/${documentId}/unlink`, {}, {})
            return response as any
        } catch (error: any) {
            throw new Error('a problem of unlinking document for contract')
        }
    }
    async getDocuments(id: string): Promise<DocumentListModel[]> {
        try {
            const response = await MonitorApi.get(`${this.baseUrl}/${id}/documents`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Documents not found!')
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContractService()
