import { Switch, Tooltip } from '@mui/material'
import { FC, Fragment, useCallback, useEffect, useState } from 'react'
import Button from '../../../../../components/Button'
import Checklist from '../../../../../components/Checklist'
import { ChecklistItemType } from '../../../../../components/Checklist/ChecklistItem'
import LabelWithLine from '../../../../../components/LabelWithLine'
import Loading from '../../../../../components/Loading'
import PopupSidebar, { PopupSidebarProps } from '../../../../../components/PopupSidebar'
import Icons, { IconType } from '../../../../../Icon'
import {
    ActivityDurationUnitType,
    ActivityEquipmentModel,
    ActivityFrequencyType,
    ActivityFrequencyUnitType,
    ActivityModel,
    ActivityProductModel,
    ActivityStatusType,
    ActivityTypes,
    CreateActivityModel,
} from '../../../../../models/Ui/Activity'
import { ActivityCustomerListModel } from '../../../../../models/Ui/Company'
import { ActivityAssigneListModel } from '../../../../../models/Ui/User'
import CompanyService from '../../../../../services/UiService/Company'
import UserService from '../../../../../services/UiService/User'
import ActivityAssignee from './Assignee'
import ActivityContract from './Contract'
import ActivityCustomer from './Customer'
import ActivityDescriptionAndNote from './DescriptionAndNote'
import ActivityStartDueDate from './DueDate'
import ActivityFrequency, { FrequencySideOptionPositions } from './Frequency'
import ActivityStatus from './Status'
import './style.scss'
import ActivityTitleInput from './Title'
import ActivityType from './Type'
import ContractService from '../../../../../services/UiService/Contract'
import { ContractOutputModel } from '../../../../../models/Ui/Contract'
import ActivityProducts from './Products'
import { DropdownWithQuantityValueModel } from '../../../../../components/DropdownWithQuantity'
import { ProductBasicDto } from '../../../../../models/Ui/Product'
import { CustomerEquipmentsModel } from '../../../../../models/New/Customer'
import ActivityEquipment from './Equipment'
import ActivityAttachments from './Attachments'
import { FileModel } from '../../../../../services/NewServices/StorageService'
import ActivityService from '../../../../../services/UiService/Activity'
import { toast } from 'react-toastify'
import { ActivityActionTypes } from '../..'
import { CommonActionTypes } from '../../../../../models/Ui/Common'

interface Props extends Omit<PopupSidebarProps, 'children' | 'classname'> {
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ActivityActionTypes, data: ActivityModel) => void
    onError: (error: Error) => void
    customerId?: string
    contractId?: string
    equipmentId?: string
}

const AddActivityPopupSidebar: FC<Props> = ({ visible, onClose, onLoading, onCompleted, onSuccess, onError, ...props }) => {
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [todos, setTodos] = useState<ChecklistItemType[]>([])
    const [status, setStatus] = useState<ActivityStatusType | null>(ActivityStatusType.Open)
    const [type, setType] = useState<ActivityTypes | null>(ActivityTypes.Ticket)
    const [isBillable, setIsBillable] = useState<boolean>(false)
    const [assigneeId, setAssigneeId] = useState<string>('')
    const [assigneeList, setAssigneeList] = useState<ActivityAssigneListModel[]>([])
    const [notes, setNotes] = useState<string>('')
    const [customerId, setCustomerId] = useState<string>(props.customerId || '')
    const [customerList, setCustomerList] = useState<ActivityCustomerListModel[]>([])
    const [shareWithCustomer, setShareWithCustomer] = useState(false)
    const [contractList, setContractList] = useState<ContractOutputModel[]>([])
    const [selectedContractId, setSelectedContractId] = useState(props.contractId || '')
    const [productsWithQuantity, setProductsWithQuantity] = useState<DropdownWithQuantityValueModel[]>([])
    const [productList, setProductList] = useState<ProductBasicDto[]>([])
    const [equipmentIds, setEquipmentIds] = useState<string[]>([])
    const [equipmentList, setEquipmentList] = useState<CustomerEquipmentsModel[]>([])
    const [attachments, setAttachments] = useState<FileModel[]>([])
    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setDate(new Date().getDate() + 7)))
    const [dueDate, setDueDate] = useState<Date | null>(new Date(new Date().setDate(new Date().getDate() + 7)))
    const [frequencyType, setFrequencyType] = useState<ActivityFrequencyType | null>(ActivityFrequencyType.OneTime)
    const [repeatCount, setRepeatCount] = useState<number>(1)
    const [repeatUnit, setRepeatUnit] = useState<ActivityFrequencyUnitType>(ActivityFrequencyUnitType.Days)
    const [durationUnit, setDurationUnit] = useState<ActivityDurationUnitType>(ActivityDurationUnitType.Days)
    const [duration, setDuration] = useState<number>(1)
    const [loading, setLoading] = useState({
        assignees: false,
        customers: false,
        contracts: false,
        products: false,
        equipments: false,
        uploadingFile: false,
    })
    const [focusedDueDate, setFocusedDueDate] = useState<boolean>(false)

    const setInitialValues = () => {
        setTitle('')
        setDescription('')
        setTodos([])
        setStatus(ActivityStatusType.Open)
        setType(ActivityTypes.Ticket)
        setIsBillable(false)
        setAssigneeId('')
        setAssigneeList([])
        setNotes('')
        setCustomerId(props.customerId || '')
        setCustomerList([])
        setShareWithCustomer(false)
        setContractList([])
        setSelectedContractId(props.contractId || '')
        setProductsWithQuantity([])
        setProductList([])
        setEquipmentIds([])
        setEquipmentList([])
        setAttachments([])
        setStartDate(null)
        setDueDate(null)
        setFrequencyType(null)
        setRepeatCount(1)
        setRepeatUnit(ActivityFrequencyUnitType.Days)
        setDurationUnit(ActivityDurationUnitType.Days)
        setDuration(1)
    }

    useEffect(() => {
        if (props?.equipmentId) {
            setEquipmentIds([props.equipmentId])
        }
    }, [props?.equipmentId])

    useEffect(() => {
        if (props?.contractId) {
            setSelectedContractId(props.contractId)
        }
    }, [props?.contractId])

    const isFormValid = () => title && status && type && frequencyType && startDate && dueDate

    const onSubmit = async () => {
        if (isFormValid()) {
            onLoading()
            try {
                const equipments: ActivityEquipmentModel[] = []
                const products: ActivityProductModel[] = productsWithQuantity.map((child) => ({
                    productId: child.id as string,
                    quantity: child.quantity,
                }))
                equipmentIds.forEach((item) => equipments.push({ id: item }))
                const payload: CreateActivityModel = {
                    title: title,
                    description: description,
                    startDate: startDate!.toUTCString(),
                    dueDate: dueDate!.toUTCString(),
                    isRecurring: !!frequencyType && frequencyType !== ActivityFrequencyType.OneTime,
                    frequency: repeatCount,
                    frequencyUnit: repeatUnit,
                    duration: frequencyType !== ActivityFrequencyType.Custom ? duration : duration * repeatCount,
                    durationUnit,
                    customerId: customerId || undefined,
                    contractId: selectedContractId || undefined,
                    isProductsPurchasableToCustomer: shareWithCustomer,
                    status: status!,
                    revenueOpportunity: 0,
                    frequencyType: frequencyType!,
                    assigneeId: assigneeId || undefined,
                    type: type!,
                    isBillable: isBillable,
                    products: products,
                    equipments: equipments,
                    todos: JSON.stringify(todos.filter((child) => child.text !== '')),
                    notes: notes,
                    attachments: attachments ? JSON.stringify(attachments) : undefined,
                }
                const response = await ActivityService.create(payload)
                onSuccess(CommonActionTypes.CREATE, response)
                toast.success('Activity created successfully.')
            } catch (error) {
                onError(error as Error)
            } finally {
                onCompleted()
                setInitialValues()
            }
        }
    }

    const onChangeFrequencyType = (value: ActivityFrequencyType) => {
        setFrequencyType(value)
        if (value === ActivityFrequencyType.OneTime) {
            setRepeatUnit(ActivityFrequencyUnitType.Days)
            setRepeatCount(1)
            setDuration(1)
            setDurationUnit(ActivityDurationUnitType.Days)
            setDueDate(startDate)
        } else if (value !== ActivityFrequencyType.Custom) {
            setDurationUnit(
                value === ActivityFrequencyType.Daily
                    ? ActivityDurationUnitType.Days
                    : value === ActivityFrequencyType.Weekly
                    ? ActivityDurationUnitType.Weeks
                    : value === ActivityFrequencyType.Monthly
                    ? ActivityDurationUnitType.Months
                    : value === ActivityFrequencyType.Annually
                    ? ActivityDurationUnitType.Years
                    : durationUnit
            )
            setRepeatUnit(
                value === ActivityFrequencyType.Daily
                    ? ActivityFrequencyUnitType.Days
                    : value === ActivityFrequencyType.Weekly
                    ? ActivityFrequencyUnitType.Weeks
                    : value === ActivityFrequencyType.Monthly
                    ? ActivityFrequencyUnitType.Months
                    : value === ActivityFrequencyType.Annually
                    ? ActivityFrequencyUnitType.Years
                    : repeatUnit
            )
            setRepeatCount(1)
            setDueDate(
                new Date(
                    new Date(startDate!).setDate(
                        new Date(startDate!).getDate() +
                            duration * (value === ActivityFrequencyType.Daily ? 1 : value === ActivityFrequencyType.Weekly ? 7 : value === ActivityFrequencyType.Monthly ? 30 : 365)
                    )
                )
            )
        } else {
            setDuration(1)
            setDurationUnit(ActivityDurationUnitType.Days)
            setRepeatUnit(ActivityFrequencyUnitType.Days)
            setRepeatCount(1)
            setDueDate(new Date(new Date(startDate!).setDate(new Date(startDate!).getDate() + 1)))
        }
    }

    const onChangeDuration = (value: number) => {
        setDuration(value)
        if (frequencyType !== ActivityFrequencyType.Custom) {
            setDueDate(
                new Date(
                    new Date(startDate!).setDate(
                        new Date(startDate!).getDate() +
                            value *
                                (frequencyType === ActivityFrequencyType.Daily ? 1 : frequencyType === ActivityFrequencyType.Weekly ? 7 : frequencyType === ActivityFrequencyType.Monthly ? 30 : 365)
                    )
                )
            )
        } else {
            setDueDate(
                new Date(
                    new Date(startDate!).setDate(
                        new Date(startDate!).getDate() +
                            value *
                                repeatCount *
                                (repeatUnit === ActivityFrequencyUnitType.Days ? 1 : repeatUnit === ActivityFrequencyUnitType.Weeks ? 7 : repeatUnit === ActivityFrequencyUnitType.Months ? 30 : 365)
                    )
                )
            )
        }
    }

    const onChangeRepeatCount = (value: number) => {
        setRepeatCount(value)
        setDueDate(
            new Date(
                new Date(startDate!).setDate(
                    new Date(startDate!).getDate() +
                        value *
                            duration *
                            (repeatUnit === ActivityFrequencyUnitType.Days ? 1 : repeatUnit === ActivityFrequencyUnitType.Weeks ? 7 : repeatUnit === ActivityFrequencyUnitType.Months ? 30 : 365)
                )
            )
        )
    }

    const onChangeRepeatUnit = (value: ActivityFrequencyUnitType) => {
        setRepeatUnit(value)
        setDueDate(
            new Date(
                new Date(startDate!).setDate(
                    new Date(startDate!).getDate() +
                        repeatCount * duration * (value === ActivityFrequencyUnitType.Days ? 1 : value === ActivityFrequencyUnitType.Weeks ? 7 : value === ActivityFrequencyUnitType.Months ? 30 : 365)
                )
            )
        )
        switch (value) {
            case ActivityFrequencyUnitType.Days:
                setDurationUnit(ActivityDurationUnitType.Days)
                break
            case ActivityFrequencyUnitType.Weeks:
                setDurationUnit(ActivityDurationUnitType.Weeks)
                break
            case ActivityFrequencyUnitType.Months:
                setDurationUnit(ActivityDurationUnitType.Months)
                break
            case ActivityFrequencyUnitType.Years:
                setDurationUnit(ActivityDurationUnitType.Years)
                break
            default:
        }
    }

    const onChangeDueDate = (value: Date) => {
        setDueDate(value)
        const differenceInDay = Math.ceil((new Date(value).getTime() - new Date(startDate!).getTime()) / (24 * 60 * 60 * 1000))
        if (frequencyType === ActivityFrequencyType.OneTime) {
            setStartDate(value)
        } else if (frequencyType !== ActivityFrequencyType.Custom) {
            const output =
                frequencyType === ActivityFrequencyType.Daily
                    ? Math.floor(differenceInDay)
                    : frequencyType === ActivityFrequencyType.Weekly
                    ? Math.floor(differenceInDay / 7)
                    : frequencyType === ActivityFrequencyType.Monthly
                    ? Math.floor(differenceInDay / 30)
                    : Math.floor(differenceInDay / 365)
            setDuration(output + 1)
        } else {
            const output =
                repeatUnit === ActivityFrequencyUnitType.Days
                    ? Math.floor(differenceInDay / repeatCount)
                    : repeatUnit === ActivityFrequencyUnitType.Weeks
                    ? Math.floor(differenceInDay / (7 * repeatCount))
                    : repeatUnit === ActivityFrequencyUnitType.Months
                    ? Math.floor(differenceInDay / (30 * repeatCount))
                    : Math.floor(differenceInDay / (365 * repeatCount))
            setDuration(output + 1)
        }
    }

    const onChangeStartDate = (value: Date) => {
        setStartDate(value)
        if (frequencyType === ActivityFrequencyType.OneTime) {
            setDueDate(value)
        } else if (frequencyType !== ActivityFrequencyType.Custom) {
            setDueDate(
                new Date(
                    new Date(value).setDate(
                        new Date(value).getDate() +
                            duration *
                                (frequencyType === ActivityFrequencyType.Daily ? 1 : frequencyType === ActivityFrequencyType.Weekly ? 7 : frequencyType === ActivityFrequencyType.Monthly ? 30 : 365)
                    )
                )
            )
        } else {
            setDueDate(
                new Date(
                    new Date(value).setDate(
                        new Date(value).getDate() +
                            duration *
                                repeatCount *
                                (repeatUnit === ActivityFrequencyUnitType.Days ? 1 : repeatUnit === ActivityFrequencyUnitType.Weeks ? 7 : repeatUnit === ActivityFrequencyUnitType.Months ? 30 : 365)
                    )
                )
            )
        }
    }

    const getContractList = useCallback(async () => {
        if (customerId) {
            try {
                setLoading((prevState) => ({ ...prevState, contracts: true }))
                const response = await ContractService.getAllByCustomerId(customerId)
                setContractList(response)
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, contracts: false }))
            }
        }
    }, [customerId])

    const onUploadingFile = (showSpinner: boolean) => {
        setLoading((prevState) => ({ ...prevState, uploadingFile: showSpinner }))
    }

    const getEquipmentList = useCallback(async () => {
        if (customerId) {
            try {
                setLoading((prevState) => ({ ...prevState, equipments: true }))
                const companyService = new CompanyService()
                const response = await companyService.getEquipmentList(customerId)
                setEquipmentList(response)
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, equipments: false }))
            }
        }
    }, [customerId])

    const getProductList = useCallback(async () => {
        if (customerId) {
            try {
                setLoading((prevState) => ({ ...prevState, products: true }))
                const companyService = new CompanyService()
                const response = await companyService.getProductList(customerId)
                setProductList(response)
            } catch (error: any) {
                console.error(error.message)
            } finally {
                setLoading((prevState) => ({ ...prevState, products: false }))
            }
        }
    }, [customerId])

    const getAssigneeList = useCallback(async () => {
        try {
            setLoading((prevState) => ({ ...prevState, assignees: true }))
            const userService = new UserService()
            const response = await userService.getUsersForActivity()
            setAssigneeList(response)
        } catch (error: any) {
            console.error(error.message)
        } finally {
            setLoading((prevState) => ({ ...prevState, assignees: false }))
        }
    }, [])

    const getCustomerList = useCallback(async () => {
        try {
            setLoading((prevState) => ({ ...prevState, customers: true }))
            const companyService = new CompanyService()
            const response = await companyService.getCustomersForActivity()
            setCustomerList(response)
        } catch (error: any) {
            console.error(error.message)
        } finally {
            setLoading((prevState) => ({ ...prevState, customers: false }))
        }
    }, [])

    useEffect(() => {
        getAssigneeList()
        getCustomerList()
        getContractList()
        getProductList()
        getEquipmentList()
    }, [getAssigneeList, getCustomerList, getContractList, getProductList, getEquipmentList])

    const showSpinner = () => Object.values(loading).some((child) => child)

    const setSwitchTitle = () => (shareWithCustomer ? 'This activity is shared with your customer, enabling them to see all activities in this series.' : 'Hidden from customer')

    return (
        <PopupSidebar visible={visible} onClose={onClose} classname="add-activity-popup-sidebar">
            {showSpinner() && <Loading />}
            <div className="add-activity-popup-sidebar-content">
                <div className="top-section">
                    <div className="title-section">
                        <ActivityTitleInput value={title} onChange={(val) => setTitle(val)} />
                    </div>
                    <div className="vertical-seperator" />
                    <div className="right-section">
                        <ActivityAssignee assignees={assigneeList} selectedAssigneeId={assigneeId} onChangeAssignee={(val) => setAssigneeId(val)} />
                        <div className="vertical-seperator" />
                        {!frequencyType || frequencyType === ActivityFrequencyType.OneTime ? (
                            <Tooltip title={dueDate && !focusedDueDate ? 'Due Date' : ''}>
                                <span>
                                    <ActivityStartDueDate value={dueDate} onChange={(val) => setDueDate(val)} onFocused={(active) => setFocusedDueDate(active)} />
                                </span>
                            </Tooltip>
                        ) : (
                            <Tooltip title={startDate && !focusedDueDate ? 'Start Date' : ''}>
                                <span>
                                    <ActivityStartDueDate placeholder="Start Date" value={startDate} onChange={(val) => onChangeStartDate(val!)} onFocused={(active) => setFocusedDueDate(active)} />
                                </span>
                            </Tooltip>
                        )}
                        <div className="vertical-seperator" />
                        <ActivityFrequency
                            duration={duration}
                            setDuration={onChangeDuration}
                            freqType={frequencyType}
                            onChangeFreqType={onChangeFrequencyType}
                            sideOptionPosition={FrequencySideOptionPositions.Left}
                            repeatCount={repeatCount}
                            setRepeatCount={onChangeRepeatCount}
                            repeatUnit={repeatUnit}
                            setRepeatUnit={onChangeRepeatUnit}
                            dueDate={dueDate}
                            setDueDate={onChangeDueDate}
                            startDate={startDate}
                        />
                        <div className="vertical-seperator" />
                        <Button className="cancel-button" onClick={onClose}>
                            <Icons type={IconType.Close} />
                            Cancel
                        </Button>
                        <Button className="activity-save-button" disabled={!isFormValid()} onClick={onSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
                <div className="body-section">
                    <div className="left-side">
                        <LabelWithLine labelText="Description" />
                        <ActivityDescriptionAndNote value={description} onChange={(value) => setDescription(value)} placeholder={'What is this activity about?'} />
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Checklist" />
                        <Checklist list={todos} onChange={(list) => setTodos(list)} hideAddButton divideCompletedOnes editItemWithoutButton showAddInputAlways />
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Products" />
                        {customerId ? (
                            <Fragment>
                                {productList.length ? (
                                    <ActivityProducts showAddButton={false} productList={productList} value={productsWithQuantity} onChange={(val) => setProductsWithQuantity(val)} />
                                ) : (
                                    <div className="product-note">
                                        There are no products linked to {customerList.find((child) => child.id === customerId)?.name || 'customer'}. To link a product to this activity, link one to
                                        your customer first.
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <div className="product-note">To add products, link a customer first.</div>
                        )}
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Equipment" />
                        {customerId ? (
                            <Fragment>
                                {equipmentList.length ? (
                                    <ActivityEquipment
                                        showAddButton={false}
                                        equipmentList={equipmentList}
                                        value={equipmentIds}
                                        onChange={(val) => setEquipmentIds(val)}
                                        disabled={!!props.equipmentId}
                                    />
                                ) : (
                                    <div className="equipment-note">
                                        There are no equipment records linked to {customerList.find((child) => child.id === customerId)?.name || 'customer'}. To link an equipment to this activity,
                                        link one to your customer first.
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <div className="equipment-note">To add equipment records, link a customer first.</div>
                        )}
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Attachments" />
                        <ActivityAttachments isEditable onUploadingDowloadingFile={onUploadingFile} attachments={attachments} onChangeAttachments={(val) => setAttachments(val)} />
                        <div className="activity-section-space" />
                    </div>
                    <div className="right-side">
                        <ActivityStatus isAddNewActivityModal value={status} onChange={(val) => setStatus(val)} />
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Customer" />
                        <div className={`customer-with-switch ${customerId && 'customer-selected'}`}>
                            <ActivityCustomer
                                customerList={customerList}
                                selectedCustomerId={customerId}
                                onChangeCustomer={(val) => {
                                    setSelectedContractId('')
                                    setEquipmentIds([])
                                    setProductsWithQuantity([])
                                    setCustomerId(val)
                                }}
                                disabled={props.customerId ? true : customerList.length <= 1}
                            />
                            {customerId && (
                                <Tooltip title={setSwitchTitle()}>
                                    <Switch checked={shareWithCustomer} onChange={(e) => setShareWithCustomer(e.target.checked)} />
                                </Tooltip>
                            )}
                        </div>
                        {customerId && <div className="share-customer-note">NOTE: This activity is shared with your customer, enabling them to see all activities in this series.</div>}
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Contract" />
                        {customerId ? (
                            <ActivityContract
                                contractList={contractList}
                                selectedContractId={selectedContractId}
                                onChangeContractId={(val) => setSelectedContractId(val)}
                                disabled={!!props.contractId}
                            />
                        ) : (
                            <div className="contract-note">To add a contract, link a customer first.</div>
                        )}
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Type" />
                        <ActivityType type={type} onChangeType={(value) => setType(value)} isBillable={isBillable} onChangeBillable={(value) => setIsBillable(value)} />
                        <div className="activity-section-space" />
                        <LabelWithLine labelText="Notes" />
                        <ActivityDescriptionAndNote value={notes} onChange={(value) => setNotes(value)} placeholder={'Add a note'} />
                        <div className="activity-section-space" />
                    </div>
                </div>
            </div>
        </PopupSidebar>
    )
}

export default AddActivityPopupSidebar
