import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
})

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult
const ErrorBoundary = plugin.createErrorBoundary(React) as any

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
)
