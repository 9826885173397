import { OrderModel } from '../../models/New/Order'
import './Order.scss'
import { useEffect, useState } from 'react'
import OrderService from '../../services/UiService/Order'
import Loading from '../../components/Loading'
import { toast } from 'react-toastify'
import { useParams } from 'react-router'
import OrderListExpandItem from '../../components/OrderCardList/OrderListExpandItem'

function CurrentOrderPreview() {
    const [order, setOrder] = useState<OrderModel | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const { id } = useParams()

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            try {
                if (id) {
                    const response = await OrderService.getOrderCurrent(id)
                    setOrder(response)
                }
            } catch (error: any) {
                console.error(error.message)
                toast.error(error.message)
            }
            setLoading(false)
        }

        loadComponent()
    }, [id])

    return (
        <>
            {loading && <Loading />}
            {order && (
                <div className="current-order-preview">
                    <OrderListExpandItem order={order} forEmail={true} />
                    <div className="powered-by">
                        <span className="powered">POWERED BY</span>
                        <img className="logo" height={25} src={'/mini-logo.png'} alt="monittor" />
                    </div>
                </div>
            )}
        </>
    )
}

export default CurrentOrderPreview
