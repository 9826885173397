import { CustomPartModel } from '../../models/Db/Equipment'
import { ProductModel } from '../../models/Ui/Product'
import { CartEquipmentModel } from '../../models/Ui/Cart'
import { IAttachmentInfo } from '../../models/Common/AddImageModalVisibleModel'

export interface ICustomServiceContextModel {
    count: number
    customService: CustomServiceModel
}
export interface ICustomPartContextModel {
    count: number
    customPart: CustomPartModel
}

export interface IProductContextModel {
    count: number
    product: ProductModel
}

export interface IShippingMethodModel {
    [key: string]: string

    date: string
    type: string
    note: string
    carrier: string
}

export interface ICustomerShippingMethodModel {
    [key: string]: string

    carrier: string
    type: string
    note: string
    date: string
}

export interface IAddress {
    [key: string]: string

    site_address1: string
    site_address2: string
    country: string
    note: string
    city: string
    state: string
    postal_code: string
    contactInformation: string
}

export interface CustomServiceModel {
    name: string
    price: number | '-'
    note: string
    attachments: IAttachmentInfo[]
    manufacturerId: string
    qty: number
    savedId?: string
    savedOrderId?: string
}

export interface IOrderRequirements {
    isSameAddress: boolean
    billingAddress: IAddress
    shippingAddress: IAddress
    shippingMethod: IShippingMethodModel
    emailRecipients: IEmailRecipients
}

export interface IOrder {
    equipments: CartEquipmentModel[]
    products: IProductContextModel[]
    customParts: ICustomPartContextModel[]
    customServices: ICustomServiceContextModel[]
}

export interface IEmailRecipients {
    note: string
    emails: string[]
}

export interface ISavedCartInformation {
    id: string
    name: string
}

export interface ICartInformation {
    cartShow: boolean
    prevCartShow: boolean
    orderId: string
}

export enum CheckoutType {
    RFQ = 'rfq',
    Purchase = 'purchase',
}

export interface IQuickCartCustomer {
    id: string
    name: string
}
