import { Button, Tooltip } from '@mui/material'
import { FC } from 'react'
import { toast } from 'react-toastify'
import Icons, { IconType } from '../../../../../../Icon'
import StorageService, { FileModel } from '../../../../../../services/NewServices/StorageService'
import './style.scss'
import { downloadFile } from '../../../../../../utils/helpers'

interface Props {
    onChangeAttachments: (val: FileModel[]) => void
    attachments: FileModel[]
    onUploadingDowloadingFile: (showSpinner: boolean) => void
    showDownloadButton?: boolean
    isEditable?: boolean
}

const ActivityAttachments: FC<Props> = ({ isEditable, onChangeAttachments, attachments, onUploadingDowloadingFile, showDownloadButton = false }) => {
    const onUpload = async (file: File) => {
        try {
            if (file) {
                onUploadingDowloadingFile(true)
                const uploadFileService = new StorageService()
                const fileInfos = await uploadFileService.uploadMultiple(file as unknown as FileList)
                onChangeAttachments([...attachments, ...fileInfos])
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            onUploadingDowloadingFile(false)
        }
    }
    const onUpdate = async (i: number, file: File) => {
        try {
            if (file) {
                onUploadingDowloadingFile(true)
                const uploadFileService = new StorageService()
                const fileInfos = await uploadFileService.uploadMultiple(file as unknown as FileList)
                onChangeAttachments([...attachments.filter((_child, index) => index !== i), ...fileInfos])
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            onUploadingDowloadingFile(false)
        }
    }

    const onDelete = (i: number) => onChangeAttachments(attachments.filter((_child, index) => index !== i))

    return (
        <>
            <div className="activity-attachments-container">
                {attachments?.map((child, index) => (
                    <div key={index} className="attachment-list-item">
                        <div className="content-section">
                            <div className="file-name">{child.name}</div>
                            <div className="file-description">{child.type}</div>
                        </div>
                        <div className="icon-section">
                            {isEditable && (
                                <Tooltip title="Edit">
                                    <Button disableElevation disableRipple component="label" className="edit-button-wrapper">
                                        <span className="edit-button">
                                            <Icons type={IconType.Pen} />
                                            <input type="file" hidden onChange={(e) => onUpdate(index, e.target.files as unknown as File)} />
                                        </span>
                                    </Button>
                                </Tooltip>
                            )}
                            {showDownloadButton && (
                                <Tooltip title="Download">
                                    <span className="download-button" onClick={() => downloadFile(child, onUploadingDowloadingFile)}>
                                        <Icons type={IconType.DownloadCircelar} />
                                    </span>
                                </Tooltip>
                            )}
                            <Tooltip title="Unlink">
                                <span onClick={() => onDelete(index)} className="delete-button">
                                    <Icons type={IconType.Close} />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </div>
            <Tooltip title="Add new">
                <Button variant="contained" component="label" className="uploaderButton">
                    <Icons type={IconType.BoldPlus} />
                    <input type="file" hidden multiple onChange={(e) => onUpload(e.target.files as unknown as File)} />
                </Button>
            </Tooltip>
        </>
    )
}

export default ActivityAttachments
