import { AuthModel, AuthUserModel } from '../../models/Ui/Auth'
import Storage from '../../utils/Storage'

const storage = new Storage(document)
const oneDayInMs = 24 * 60 * 60 * 1000

class TokenService {
    getAuth(): AuthUserModel {
        try {
            return JSON.parse(storage.getCookie('auth')) as AuthUserModel
        } catch (error: any) {
            throw new Error('Not authorized!')
        }
    }

    isAuth(): boolean {
        const auth = storage.getCookie('auth')
        const token = storage.getCookie('token')

        return auth != null && token != null && auth !== '' && token !== ''
    }

    setAuth(authPayload: AuthModel, expireDuration: number = 90 * oneDayInMs) {
        const exp = new Date(new Date().getTime() + expireDuration).toUTCString()
        document.cookie = `auth=${JSON.stringify(authPayload.user) || ''}; expires=${exp}; path=/`
        document.cookie = `token=${authPayload.token || ''}; expires=${exp}; path=/`
    }

    setToken(token: string, expireDuration: number = 90 * oneDayInMs) {
        const exp = new Date(new Date().getTime() + expireDuration).toUTCString()
        document.cookie = `token=${token || ''}; expires=${exp}; path=/`
    }

    setUser(user: AuthUserModel, expireDuration: number = 90 * oneDayInMs) {
        const exp = new Date(new Date().getTime() + expireDuration).toUTCString()
        document.cookie = `auth=${JSON.stringify(user) || ''}; expires=${exp}; path=/`
    }

    clearAuth(): void {
        storage.setCookie('auth', '', 0)
        storage.setCookie('token', '', 0)

        Object.keys(window.localStorage)
            .filter((key) => typeof window.localStorage[key] === 'string')
            .forEach((key) => window.localStorage.removeItem(key))
    }
}

const tokenService = new TokenService()
export default tokenService
