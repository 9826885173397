import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import React, { ReactNode, useState } from 'react'
import Icons, { IconType } from '../../Icon'
import CircleIconButton from '../CircleIconButton'
import PopoverItem from '../PopoverItem'

export interface NodeButton {
    icon: IconDefinition | IconType
    popoverContent?: string
    onClick: (id: string, text: string, parent: { id: string; text: string }) => void
}

interface Props {
    id?: string
    text: string
    expandIcon?: IconDefinition
    collapseIcon?: IconDefinition
    childNodes?: ReactNode[]
    deepLevel: number
    buttons?: NodeButton[]
    buttonsOnlyForRoot?: boolean
    parent?: any
    onClick?: () => void
    onClickItem?: () => void
}

function TreeViewNode(props: React.PropsWithChildren<Props>) {
    const [expanded, setExpanded] = useState<boolean>(true)

    return (
        <>
            <div className="node-container" style={{ paddingLeft: (props.deepLevel - 1) * 36 + 20 }}>
                <>
                    <div
                        className={`left-border ${props.childNodes && props.childNodes.length > 0 ? 'half-height-bottom-border' : ''}`}
                        style={{
                            left: (props.deepLevel - 2) * 36 + 25.5,
                        }}
                    />
                    <div
                        className={'bottom-border'}
                        style={{
                            left: (props.deepLevel - 2) * 36 + 25.5,
                            visibility: props.deepLevel === 1 ? 'hidden' : 'unset',
                        }}
                    />
                </>
                <div className="node-left">
                    {props.childNodes && props.childNodes.length > 0 ? (
                        <div className="node-icon" onClick={() => setExpanded(!expanded)}>
                            {expanded ? props.collapseIcon ? props.collapseIcon : <Icons type={IconType.ChevronDown} /> : props.expandIcon ? props.expandIcon : <Icons type={IconType.ChevronRight} />}
                        </div>
                    ) : null}

                    <div className="node-text" onClick={props.onClickItem}>
                        {props.text}
                    </div>
                </div>
                <div className="node-right" onClick={props.onClick}>
                    <div className="node-buttons">
                        {props.buttonsOnlyForRoot
                            ? props.deepLevel === 1 &&
                              props.buttons &&
                              props.buttons.map((button, index) => {
                                  return button.popoverContent ? (
                                      <PopoverItem key={index} popoverContent={button.popoverContent}>
                                          <CircleIconButton
                                              onClick={() => {
                                                  button.onClick(props.id!, props.text, props.parent)
                                              }}
                                              icon={button.icon}
                                          />
                                      </PopoverItem>
                                  ) : (
                                      <CircleIconButton
                                          key={index}
                                          onClick={() => {
                                              button.onClick(props.id!, props.text, props.parent)
                                          }}
                                          icon={button.icon}
                                      />
                                  )
                              })
                            : props.buttons &&
                              props.buttons.map((button, index) => {
                                  return button.popoverContent ? (
                                      <PopoverItem key={index} popoverContent={button.popoverContent}>
                                          <CircleIconButton
                                              onClick={() => {
                                                  button.onClick(props.id!, props.text, props.parent)
                                              }}
                                              icon={button.icon}
                                          />
                                      </PopoverItem>
                                  ) : (
                                      <CircleIconButton
                                          key={index}
                                          onClick={() => {
                                              button.onClick(props.id!, props.text, props.parent)
                                          }}
                                          icon={button.icon}
                                      />
                                  )
                              })}
                    </div>
                </div>
            </div>
            {props.childNodes?.length! > 0 && <div className={`child-nodes ${expanded ? 'child-nodes-visible' : ''}`}>{props.childNodes}</div>}
        </>
    )
}

export default TreeViewNode
