import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ContractActionTypes } from '../..'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import TextArea from '../../../../../components/TextArea'
import ContactService from '../../../../../services/UiService/Contact'
import { isValidEmail } from '../../../../../utils/helpers'
import { CustomerContactModel, UpdateCustomerContactModel } from '../../../../../models/New/Customer'
import './ContactModal.scss'
import { MaxCharacter } from '../../../../../models/enums'

interface Props {
    contact?: CustomerContactModel
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ContractActionTypes, data: CustomerContactModel) => void
    isAddPrimaryContact?: boolean
}

function ContactModal(props: Props) {
    const { onLoading, onCompleted } = props
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [jobTitle, setJobTitle] = useState<string>('')
    const [notes, setNotes] = useState<string>('')
    let { id } = useParams()

    useEffect(() => {
        if (props.contact) {
            setFirstName(props.contact?.firstName)
            setLastName(props.contact?.lastName)
            setPhone(props.contact?.phone)
            setEmail(props.contact?.email)
            setJobTitle(props.contact.jobTitle)
            setNotes(props.contact.notes)
        }
    }, [props.contact])

    const isValidForm = () => {
        if (props.contact) {
            return (
                (props.contact.firstName !== firstName ||
                    props.contact.lastName !== lastName ||
                    props.contact.email !== email ||
                    props.contact.phone !== phone ||
                    props.contact.jobTitle !== jobTitle ||
                    props.contact.notes !== notes) &&
                firstName.length > 0 &&
                lastName.length > 0 &&
                email.length > 0 &&
                isValidEmail(email)
            )
        } else {
            return firstName.length > 0 && lastName.length > 0 && email.length > 0 && isValidEmail(email)
        }
    }

    const clearForm = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setJobTitle('')
        setNotes('')
    }

    async function updateContact() {
        try {
            onLoading()
            if (props.contact) {
                const newData: UpdateCustomerContactModel = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    jobTitle: jobTitle,
                    companyId: props.contact?.companyId,
                    notes: notes,
                    isPrimary: props.contact.isPrimary,
                }
                const response = await ContactService.update(newData, props.contact.id)
                props.onSuccess(ContractActionTypes.UPDATE_CONTACT, response)
            } else {
                const newData: UpdateCustomerContactModel = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    jobTitle: jobTitle,
                    companyId: id || '',
                    notes: notes,
                    isPrimary: !!props.isAddPrimaryContact,
                }
                const response = await ContactService.create(newData)
                props.onSuccess(ContractActionTypes.CREATE_CONTACT, response)
            }
        } catch (error: any) {
        } finally {
            props.closeModal()
            clearForm()
            onCompleted()
        }
    }
    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }
    return (
        <>
            <Modal
                visible={props?.visible}
                title={props.contact ? 'Edit contact details' : 'Create Contact'}
                className="contact-modal"
                primaryButtonText={props.contact ? 'Save Changes' : 'Create'}
                onClickPrimary={updateContact}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
                buttonOrder="secondaryPrimary"
            >
                <div className="modal-line">
                    <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="FIRST NAME" required max={MaxCharacter.name} />
                    <Input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="LAST NAME" required max={MaxCharacter.name} />
                </div>
                <div className="modal-line">
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="EMAIL" required max={MaxCharacter.email} />
                    <Input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="PHONE" max={MaxCharacter.phone} />
                </div>
                <div className="modal-line">
                    <Input value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} placeholder="JOB TITLE" />
                </div>
                <div className="modal-line">
                    <TextArea value={notes} onChange={(e) => setNotes(e.target.value)} placeholder="NOTES" />
                </div>
            </Modal>
        </>
    )
}

export default ContactModal
