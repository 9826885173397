import { FC } from 'react'
import { IImageMapping } from './ImageMapping.interface'
import './Imagemapping.scss'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Color } from '../../models/enums'
import PopoverItem from '../PopoverItem'
import Button from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CanvasImage from './components/Canvas'

const ImageMapping: FC<IImageMapping> = (props: IImageMapping) => {
    return (
        <>
            <div className="mapping-control">
                <PopoverItem popoverContent="Previous" disabled={props.selectedImageIndex === 0}>
                    <Button className="btn btn__previous" onClick={props.previousImageClick} disabled={props.selectedImageIndex === 0}>
                        <FontAwesomeIcon icon={faArrowLeft} color={Color.white} />
                    </Button>
                </PopoverItem>

                {!props.mappingMode ? (
                    <div ref={props.panzoomRef}>
                        <CanvasImage
                            areas={props.areas}
                            image={props.image}
                            mappingCircle={props.mappingCircle}
                            mappingMode={props.mappingMode}
                            partLists={props.partLists}
                            setAreas={props.setAreas}
                            setImageLoading={props.setImageLoading}
                            addToCart={props.addToCart}
                            scaleRatio={props.scaleRatio}
                            customEquipment={props.customEquipment}
                        />
                    </div>
                ) : (
                    <CanvasImage
                        areas={props.areas}
                        image={props.image}
                        mappingCircle={props.mappingCircle}
                        mappingMode={props.mappingMode}
                        partLists={props.partLists}
                        setAreas={props.setAreas}
                        setImageLoading={props.setImageLoading}
                        addToCart={props.addToCart}
                        scaleRatio={props.scaleRatio}
                        customEquipment={props.customEquipment}
                    />
                )}

                <PopoverItem popoverContent="Next" disabled={props.selectedImageIndex === props.equipment.drawings.length - 1}>
                    <Button className="btn btn__next" onClick={props.nextImageClick} disabled={props.selectedImageIndex === props.equipment.drawings.length - 1}>
                        <FontAwesomeIcon icon={faArrowRight} color={Color.white} />
                    </Button>
                </PopoverItem>
            </div>
            <div className="pagination">
                <div className="wrapper">
                    {Array(props.drawings?.length)
                        .fill(undefined)
                        .map((_, index) => (
                            <div className={`child ${props.selectedImageIndex === index ? 'active' : ''}`} key={index}></div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default ImageMapping
