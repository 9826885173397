/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, FC, createContext, useState, useEffect, useMemo } from 'react'
import { url } from '../api'
import { io } from 'socket.io-client'
import NotificationModel from '../models/Ui/Notification'
import { uniqueObjectArray } from '../utils/helpers'
import NotificationService from '../services/UiService/Notification'

interface SocketContextProviderProps {
    children: ReactNode
}

export type SocketClientModel = {
    userId: string
    companyId: string
}

const socket = io(url)

const useContext = () => {
    const [socketClient, setSocketClient] = useState<SocketClientModel>({} as SocketClientModel)
    const [notifications, setNotifications] = useState<NotificationModel[]>([])
    const [notificationsUpdated, setNotificationsUpdated] = useState(false)

    useEffect(() => {
        if (socketClient.userId) {
            socket.connect()
            socket.emit('join', { companyId: socketClient.companyId, userId: socketClient.userId })
        } else {
            socket.disconnect()
        }
    }, [socketClient])

    useEffect(() => {
        socket.on('notification', (payload: NotificationModel) => {
            setNotifications((prevState) => uniqueObjectArray([payload, ...prevState]))
            setNotificationsUpdated((prevState) => !prevState)
        })
    }, [])

    const notificationService = useMemo(() => {
        return new NotificationService()
    }, [])

    async function getNotifications() {
        try {
            const n = await notificationService.gets({ size: 10, page: 0 })
            setNotifications(n.content)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (socketClient.userId) {
            getNotifications()
        }
    }, [socketClient.userId])

    const resetSocket = () => {
        setSocketClient({} as SocketClientModel)
        setNotifications([])
    }

    return {
        socketClient,
        setSocketClient,
        notifications,
        setNotifications,
        resetSocket,
        notificationsUpdated,
        setNotificationsUpdated,
    }
}

export const SocketContext = createContext({} as ReturnType<typeof useContext>)

const SocketContextProvider: FC<SocketContextProviderProps> = ({ children }) => {
    return <SocketContext.Provider value={useContext()}>{children}</SocketContext.Provider>
}

export default SocketContextProvider
