/* eslint-disable react-hooks/exhaustive-deps */
import { faBell, faExternalLinkSquareAlt, faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import ContentBox from '../../components/ContentBox'
import Loading from '../../components/Loading'
import { Color } from '../../models/enums'
import NotificationModel from '../../models/Ui/Notification'
import NotificationService from '../../services/UiService/Notification'
import { SocketContext } from '../../store/SocketContext'
import { standardDate } from '../../utils/helpers'
import PageWrapper from '../PageWrapper'
import './Notifications.scss'

function Notifications() {
    const { notificationsUpdated } = useContext(SocketContext)
    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState([] as NotificationModel[])

    const notificationService = useMemo(() => {
        return new NotificationService()
    }, [])

    const navigate = useNavigate()
    useEffect(() => {
        async function getData() {
            setLoading(true)
            const n = await notificationService.gets({ size: 1000, page: 0 })
            await notificationService.markAsAllRead()
            setNotifications(n.content)
            setLoading(false)
        }
        getData()
    }, [notificationsUpdated])

    const getItemClassName = (notification: NotificationModel) => {
        let className = 'notification-item'

        if (!notification.isRead) {
            className += ' new'
        }

        if (notification.path) {
            className += ' pointer'
        }

        return className
    }

    const onClickGoToButton = (url: string) => {
        navigate(url)
    }

    return (
        <PageWrapper className="notification-page">
            <ContentBox
                title={
                    <div className="notification-page-title">
                        <FontAwesomeIcon color={Color.yellow300} icon={faBell} />
                        <span>Notifications</span>
                    </div>
                }
            >
                {loading && <Loading />}
                {notifications.length > 0 ? (
                    <div className="notification">
                        {notifications.map((n, i) => {
                            return (
                                <div key={i} className={getItemClassName(n)}>
                                    <div className="notification-title">
                                        <span>{n.title}</span>
                                        {n.path && (
                                            <Tooltip title="Go to">
                                                <div className="notification-button" onClick={() => onClickGoToButton(n.path)}>
                                                    <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </div>
                                    {n.description && <div className="notification-text">{n.description}</div>}
                                    <div className="notification-date">{`${standardDate(n.creationDate)} ${dayjs(n.creationDate).format('hh:mm A')}`}</div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className="no-notification-info">
                        <FontAwesomeIcon icon={faInfo} /> <span> {loading ? 'Loading...' : 'No notifications yet'}</span>
                    </div>
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default Notifications
