import React, { useContext, useEffect, useState } from 'react'
import './ProductEditModal.scss'
import StorageService from '../../../../../services/NewServices/StorageService'
import { ProductImageModel, ProductListModel, UpdateProductModel } from '../../../../../models/New/Product'
import { DocumentListModel } from '../../../../../models/New/Document'
import ProductService from '../../../../../services/UiService/Product'
import Icons, { IconType } from '../../../../../Icon'
import { Color } from '../../../../../models/enums'
import { ImageList, ImageListItem } from '@mui/material'
import FileInput from '../../../../../components/FileInput'
import Input from '../../../../../components/Input'
import { FileInputType } from '../../../../../components/FileInput/enum'
import Modal from '../../../../../components/Modal'
import { ProductActionTypes } from '../../actiontypes.enum'
import TextArea from './../../../../../components/TextArea/index'
import LinkedDocuments from '../CreateDocument/Components/LinkedDocuments'
import DocumentService from '../../../../../services/UiService/Document'
import { ConfirmationContext } from '../../../../../store/ConfirmationContext'
import CurrencyInput from '../../../../../components/CurrencyInput'

interface IProductEditModal {
    product: ProductListModel
    closeModal: () => void
    selectedProductId: string
    visible: boolean
    onLoading: () => void
    onCompleted: () => void
    onError: (error: any) => void
    onSuccess: (action?: ProductActionTypes, data?: any) => void
}

const ProductEditModal = (props: IProductEditModal) => {
    const uploadFileServices = new StorageService()
    const { confirm } = useContext(ConfirmationContext)

    const [productName, setProductName] = useState<string>(props.product.name)
    const [brandName, setBrandName] = useState<string>(props.product.brand)
    const [partNumber, setPartNumber] = useState<string>(props.product.partNumber)
    const [productCategory, setProductCategory] = useState<string>(props.product.category)
    const [description, setDescription] = useState<string>(props.product.description)
    const [uom, setUom] = useState<string>(props.product.uom)
    const [pkg, setPkg] = useState<string>(props.product.pkg)
    const [unitPrice, setUnitPrice] = useState<number>(props.product.price)
    const [productImages, setProductImages] = useState<ProductImageModel[]>(props.product.images ?? [])
    const [selectedLinkedDocuments, setSelectedLinkedDocuments] = useState<DocumentListModel[]>(props.product.documents)
    const [toggleLinkedDocumentsVisible, setToggleLinkedDocumentsVisible] = useState<boolean>(false)
    //const [uploadedDocumentListVisible, setUploadedDocumentListVisible] = useState<boolean>(false)
    const [documents, setDocuments] = useState<DocumentListModel[]>([])

    const onUploadChange = async (uploadFiles: FileList) => {
        try {
            const uploadedFiles = await uploadFileServices.uploadMultiple(uploadFiles)
            const newProductImages: ProductImageModel[] = uploadedFiles.map((file) => ({
                imageUrl: file.url,
                name: file.name,
                isFeatured: false,
            }))
            setProductImages([...productImages, ...newProductImages])
        } catch (error: any) {
            props.onError(error)
        }
    }

    const updateProductHandler = async () => {
        try {
            props.onLoading()
            const payload: UpdateProductModel = {
                name: productName,
                brand: brandName,
                partNumber: partNumber,
                category: productCategory,
                description: description,
                uom: uom,
                pkg: pkg,
                price: unitPrice,
                images: productImages,
                documents: [...selectedLinkedDocuments],
            }
            await ProductService.update(payload, props.selectedProductId)
            props.onSuccess()
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
            props.closeModal()
        }
    }

    const getDocuments = async () => {
        try {
            const documents = await DocumentService.getDocuments({ page: 0, size: 10000 })
            setDocuments(documents.content)
        } catch (error: any) {
            props.onError(error)
        }
    }
    const setFeatureImage = async (imageUrl: string, isFeatured: boolean) => {
        try {
            props.onLoading()
            const newImages = productImages.map((image: ProductImageModel) => {
                if (isFeatured) {
                    if (image.imageUrl === imageUrl) return { ...image, isFeatured }
                    else return { ...image, isFeatured: !isFeatured }
                }
                return { ...image, isFeatured: false }
            })
            await ProductService.update({ images: newImages }, props.product.id)
            props.onSuccess()
        } catch (error: any) {
            console.log(error)
        } finally {
            props.onCompleted()
        }
    }
    useEffect(() => {
        setProductName(props.product.name)
        setBrandName(props.product.brand)
        setPartNumber(props.product.partNumber)
        setProductCategory(props.product.category)
        setDescription(props.product.description)
        setUom(props.product.uom)
        setPkg(props.product.pkg)
        setUnitPrice(props.product.price)
        setSelectedLinkedDocuments(props.product.documents)
        setProductImages(props.product.images ?? [])
    }, [props.product])

    useEffect(() => {
        if (toggleLinkedDocumentsVisible) getDocuments()
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [toggleLinkedDocumentsVisible])

    const deleteImageHandler = (imageUrl: string) => {
        confirm({
            modalTitle: 'Deleting Product Image?',
            // titleColor: '#D84315',
            primaryButtonText: 'Cancel',
            doesPrimaryButtonSubmit: false,
            secondaryButtonText: 'Delete',
            func: async (successCallback, errorCallback) => {
                try {
                    props.onLoading()
                    const images = productImages.filter((productImage: ProductImageModel) => productImage.imageUrl !== imageUrl)
                    setProductImages(images)
                    props.onCompleted()
                    successCallback()
                } catch (error: any) {
                    props.onError(error)
                    errorCallback(error)
                }
            },
            contentNode: (
                <div className="modal-line">
                    <span className="warning-text">Are you sure you would like to delete the product's image?</span>
                </div>
            ),
        })
    }
    return (
        <Modal
            visible={props.visible}
            title="Edit Product Details"
            className="edit-product-modal"
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            onClickPrimary={updateProductHandler}
            onClickSecondary={props.closeModal}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <Input value={productName} placeholder="Product Name" onChange={(e) => setProductName(e.target.value)} />
            </div>
            <div className="modal-line">
                <Input type="string" value={brandName} placeholder="BRAND" onChange={(e) => setBrandName(e.target.value)} />
                <Input type="string" value={partNumber} placeholder="Part Number" onChange={(e) => setPartNumber(e.target.value)} />
            </div>
            <div className="modal-line">
                <Input type="string" value={productCategory} placeholder="Category" onChange={(e) => setProductCategory(e.target.value)} />
            </div>
            <div className="modal-line">
                <TextArea type="string" value={description} placeholder="Description" onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="modal-line">
                <Input type="string" value={uom} placeholder="UOM" onChange={(e) => setUom(e.target.value)} />
                <Input type="string" value={pkg} placeholder="PKG" onChange={(e) => setPkg(e.target.value)} />
                <CurrencyInput
                    placeholder="UNIT PRICE"
                    value={unitPrice}
                    onChange={(price) => {
                        setUnitPrice(price)
                    }}
                />
            </div>
            <div className="upload-image">
                <span className="body__section__title">Product Images</span>
                <div className="modal-line">
                    <ImageList cols={6}>
                        {productImages?.map((image, index: number) => (
                            <ImageListItem className="product-image" key={index}>
                                <span className="delete" onClick={() => deleteImageHandler(image.imageUrl)}>
                                    <Icons type={IconType.Trash} color={Color.grey600} />
                                </span>
                                <img src={image.imageUrl} alt={image.name} />
                                {image.isFeatured ? (
                                    <span className="star star--is-featured">
                                        <Icons type={IconType.Star} onClick={() => setFeatureImage(image.imageUrl, false)} />
                                    </span>
                                ) : (
                                    <span className="star">
                                        <Icons type={IconType.StarBorder} color={Color.grey600} onClick={() => setFeatureImage(image.imageUrl, true)} />
                                    </span>
                                )}
                            </ImageListItem>
                        ))}
                        <FileInput fileInputText="PRODUCTS" handleChange={(e) => onUploadChange(e)} fileTypes={['jpg', 'png', 'jpeg']} multiple={true} inputType={FileInputType.NEW_PRODUCT_UPLOAD} />
                    </ImageList>
                </div>
            </div>
            <div className="documents">
                <span className="documents__title">
                    Link Documents
                    <span onClick={() => setToggleLinkedDocumentsVisible(!toggleLinkedDocumentsVisible)}>
                        <Icons type={IconType.BoldPlus} />
                    </span>
                </span>
                {toggleLinkedDocumentsVisible && <LinkedDocuments documents={documents} selectedDocuments={selectedLinkedDocuments} onChange={(e) => setSelectedLinkedDocuments(e)} />}
                {/*{selectedLinkedDocuments.length > 0 && (*/}
                {/*    <>*/}
                {/*        <span>{selectedLinkedDocuments.length} Documents linked</span>*/}
                {/*        <div className="documents__uploaded-documents">*/}
                {/*            {uploadedDocumentListVisible ? (*/}
                {/*                <span onClick={() => setUploadedDocumentListVisible(false)}>*/}
                {/*                    Hide Details <Icons type={IconType.ChevronUp} />*/}
                {/*                </span>*/}
                {/*            ) : (*/}
                {/*                <span onClick={() => setUploadedDocumentListVisible(true)}>*/}
                {/*                    Show Details <Icons type={IconType.ChevronDown} />*/}
                {/*                </span>*/}
                {/*            )}*/}
                {/*            {uploadedDocumentListVisible && (*/}
                {/*                <div className="documents__list">*/}
                {/*                    {selectedLinkedDocuments.map((document: DocumentListModel, index: number) => (*/}
                {/*                        <span className="documents__list__item" key={index}>*/}
                {/*                            {document.name}*/}
                {/*                            /!*<Icons type={IconType.Trash} onClick={() => removeDocuments(document.url)} />*!/*/}
                {/*                            <a href={document.url} target="_blank" rel="noreferrer">*/}
                {/*                                show*/}
                {/*                            </a>*/}
                {/*                        </span>*/}
                {/*                    ))}*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*)}*/}
            </div>
        </Modal>
    )
}

export default ProductEditModal
