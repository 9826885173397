export interface IDropdownOption {
    id: string | number
    label: string
}

export interface IDropdownMenuItem {
    label: string
    type: OrderStatus
}
export enum OrderStatus {
    ON_HOLD = 'On Hold',
    SHIPPED = 'Shipped',
    PROCESSING = 'Processing',
    CANCELLED = 'Cancelled',
}
