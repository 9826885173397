import React, { useEffect, useState } from 'react'
import Box from '../../components/Box'
import './validation.scss'
import {Link, useNavigate, useParams} from 'react-router-dom'
import AuthService from '../../services/UiService/Auth'
import Button from "../../components/Button";

function Validation() {
    let {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ show: false, type: '' , text: ''})

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)

        const checkValidationKey = async () => {
            if (id) {
                const verifyMail = await new AuthService().verifyMail(id);
                if (verifyMail.status){
                    setLoading(false);
                    setMessage({ show: true, type: 'success' , text: 'Congratulations! You are all set.'})
                    return
                }
                setLoading(false);
                setMessage({ show: true, type: 'error' , text: verifyMail.message})
            } else {
                setLoading(false);
                setMessage({ show: true, type: 'error' , text: 'Invalid validation key'})
            }
            
        }
        checkValidationKey()
    }, [id,navigate])


    return (
        <div className='validation-card'>
            <div className="card-wrapper">
                <Box>
                    {
                        message.show && (
                          message.type === 'success' ? <div className="message success">{message.text || 'Success'}</div>
                            : message.type === 'error' && <div className="message error">{message.text || 'Error'}</div>
                        )
                    }
                    {
                        loading && <span className='loading-span' />
                    }

                    {
                          <Link className='card-button' to={'/login'}>
                              <Button>Back to Main Page</Button>
                          </Link>
                    }
                </Box>
            </div>
        </div>
    )
}

export default Validation
