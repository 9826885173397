import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { ActivityFrequencyType, ActivityFrequencyUnitType, ActivityModel, ActivityStatusType, ActivityTypes, UpdateActivityModel } from '../../../../models/Ui/Activity'
import ActivityService from '../../../../services/UiService/Activity'
import UserService from '../../../../services/UiService/User'
import { ActivityAssigneListModel as ActivityAssigneeListModel } from '../../../../models/Ui/User'
import Icons, { IconType } from '../../../../Icon'
import Button, { ButtonType } from '../../../../components/Button'
import './UpdateBox.scss'
import ActivityTitleInput from '../Modals/AddActivityPopupSidebar/Title'
import ActivityAssignee from '../Modals/AddActivityPopupSidebar/Assignee'
import ActivityStatusForPartialUpdate from './Status'
import { Tooltip } from '@mui/material'
import ActivityStartDueDate from '../Modals/AddActivityPopupSidebar/DueDate'
import LabelWithLine from '../../../../components/LabelWithLine'
import ActivityDescriptionAndNote from '../Modals/AddActivityPopupSidebar/DescriptionAndNote'
import Checklist from '../../../../components/Checklist'
import { ChecklistItemType } from '../../../../components/Checklist/ChecklistItem'
import ActivityFrequency, { FrequencySideOptionPositions } from '../Modals/AddActivityPopupSidebar/Frequency'
import ActivityPartialUpdateType from './Type'
import { ActivityActionTypes } from '..'
import { CommonActionTypes } from '../../../../models/Ui/Common'
import CustomerLine from './Components/CustomerLine'

interface Props {
    id: string
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ActivityActionTypes, data: any) => void
    onError: (error: any) => void
    onViewMore: () => void
}

function PartialUpdateBox(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted, closeModal, onViewMore } = props
    const [activity, setActivity] = useState<ActivityModel>()
    const [title, setTitle] = useState<string>('')
    const [assigneeList, setAssigneeList] = useState<ActivityAssigneeListModel[]>([])
    const [assigneeId, setAssigneeId] = useState<string>('')
    const [status, setStatus] = useState<ActivityStatusType>(ActivityStatusType.Open)
    const [description, setDescription] = useState<string>('')
    const [customerId, setCustomerId] = useState<string>('')
    const [customerName, setCustomerName] = useState<string>('')
    const [type, setType] = useState<ActivityTypes>(ActivityTypes.Ticket)
    const [isBillable, setIsBillable] = useState<boolean>(false)
    const [onEditMode, setOnEditMode] = useState<boolean>(false)
    const [todos, setTodos] = useState<ChecklistItemType[]>([])
    const [dueDate, setDueDate] = useState<Date | null>(null)
    const [frequencyType, setFrequencyType] = useState<ActivityFrequencyType>(ActivityFrequencyType.OneTime)
    const [repeatCount, setRepeatCount] = useState<number>(1)
    const [repeatUnit, setRepeatUnit] = useState<ActivityFrequencyUnitType>(ActivityFrequencyUnitType.Days)
    const [duration, setDuration] = useState<number>(1)
    const [focusedDueDate, setFocusedDueDate] = useState<boolean>(false)

    useEffect(() => {
        setOnEditMode(false)
    }, [props.id])

    const getActivitiesDetailCallback = useCallback(async () => {
        try {
            onLoading()
            const response = await ActivityService.get(props.id)
            setDescription('')
            setActivity({ ...response, todos: response.todos || '[]' })
            return response
        } catch (error: any) {
            console.error(error.message)
        } finally {
            onCompleted()
        }
    }, [onCompleted, onLoading, props.id])

    const getUsersCallback = useCallback(async () => {
        try {
            const userService = new UserService()
            const response = await userService.getUsersForActivity()
            setAssigneeList(response)
        } catch (error: any) {
            console.error(error.message)
        } finally {
        }
    }, [])

    async function updateActivityHandler(close?: boolean) {
        try {
            onLoading()
            if (activity) {
                let payload: UpdateActivityModel = {
                    id: props.id,
                    manufacturerId: activity?.manufacturerId,
                    title: title,
                    description: description || '',
                    startDate: activity?.startDate,
                    dueDate: dayjs(dueDate).format('MM/DD/YYYY'),
                    status: status,
                    customerId: customerId || undefined,
                    isProductsPurchasableToCustomer: false,
                    assigneeId: assigneeId || undefined,
                    type: type,
                    isBillable: isBillable,
                    todos: JSON.stringify(todos.filter((child) => child.text !== '')),
                }
                await ActivityService.update(payload)
                await onSuccess(CommonActionTypes.UPDATE, payload)
                await getActivitiesDetailCallback()
                setOnEditMode(false)
            }
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
            if (close) {
                closeModal()
                handleClearForm()
            }
        }
    }
    const handleDefaultValueCallback = useCallback(() => {
        if (activity) {
            setTitle(activity.title)
            setCustomerId(activity.customerId)
            setCustomerName(activity?.customer?.name ?? '')
            setStatus(activity.status)
            setDescription(activity.description)
            setAssigneeId(activity?.assignee?.id ?? '')
            setDuration(activity.duration)
            setDueDate(new Date(activity.dueDate))
            setIsBillable(activity.isBillable)
            setType(activity.type)
            setTodos(activity.todos ? JSON.parse(activity.todos) : [])
            setFrequencyType(activity.frequencyType)
        }
    }, [activity])

    const handleClearForm = useCallback(() => {
        setTitle('--')
        setCustomerId('')
        setCustomerName('')
        setStatus(ActivityStatusType.Open)
        setAssigneeId('')
        setDueDate(new Date())
        setDescription('')
        setDuration(0)
        setIsBillable(false)
        setType(ActivityTypes.Ticket)
    }, [])

    const isValidForm = () => {
        if (activity) {
            return (
                title.length > 3 &&
                ((dueDate && new Date(dueDate).toDateString()) !== new Date(activity.dueDate).toDateString() ||
                    title !== activity.title ||
                    customerId !== activity.customerId ||
                    status !== activity.status ||
                    description !== activity.description ||
                    assigneeId !== (activity?.assigneeId ?? '') ||
                    duration !== activity.duration ||
                    type !== activity.type ||
                    isBillable !== activity.isBillable ||
                    activity.todos !== JSON.stringify(todos))
            )
        } else {
            return false
        }
    }

    const clickPrimary = (close?: boolean) => {
        updateActivityHandler(close)
    }

    const onSecondary = () => {
        closeModal()
    }

    useEffect(() => {
        getActivitiesDetailCallback()
    }, [getActivitiesDetailCallback])

    useEffect(() => {
        const loadComponentCallback = async () => {
            onLoading()
            await getUsersCallback()
            onCompleted()
            // await getProductCallback()
        }
        loadComponentCallback()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        handleDefaultValueCallback()
    }, [handleDefaultValueCallback])

    return (
        <div className="activity-modal partial-update">
            <div className="cart">
                <div className="cart-header">
                    <ActivityTitleInput value={title} onChange={(val) => setTitle(val)} disabled={!onEditMode} placeholder="Title" />
                </div>
                <div className="icon-section">
                    <div className="icon-section-left-side">
                        <ActivityAssignee
                            disabled={!onEditMode}
                            showFullnameAfterSelected={onEditMode}
                            selectedAssigneeId={assigneeId ?? ''}
                            assignees={assigneeList}
                            onChangeAssignee={(val) => setAssigneeId(val)}
                        />
                        <Tooltip title={!onEditMode ? 'Status' : ''}>
                            <span>
                                <ActivityStatusForPartialUpdate status={status} onChange={(val) => setStatus(val)} disabled={!onEditMode} />
                            </span>
                        </Tooltip>
                    </div>
                    <div className="icon-section-icons">
                        <Tooltip title="Edit">
                            <span onClick={() => setOnEditMode(!onEditMode)}>
                                <Icons type={IconType.Pen} />
                            </span>
                        </Tooltip>
                        <Tooltip title="View more">
                            <span onClick={onViewMore}>
                                <Icons type={IconType.GoToPage} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Close preview">
                            <span onClick={closeModal}>
                                <Icons type={IconType.Close} />
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <div className="divider" />
                <div className="cart-body">
                    <div className="date-section">
                        <Tooltip title={`${!focusedDueDate ? 'Due Date' : ''}`}>
                            <span>
                                <ActivityStartDueDate
                                    value={dueDate}
                                    onChange={(date) => {
                                        setDueDate(date)
                                    }}
                                    popperPlacement="left-start"
                                    disabled={!onEditMode}
                                    onFocused={(active) => setFocusedDueDate(active)}
                                />
                            </span>
                        </Tooltip>
                        <div className="vertical-seperator" />
                        <Tooltip title="To edit the frequency, go to the detail page of this activity">
                            <span>
                                <ActivityFrequency
                                    duration={duration}
                                    setDuration={setDuration}
                                    freqType={frequencyType}
                                    onChangeFreqType={(value) => setFrequencyType(value)}
                                    sideOptionPosition={FrequencySideOptionPositions.Left}
                                    repeatCount={repeatCount}
                                    setRepeatCount={setRepeatCount}
                                    repeatUnit={repeatUnit}
                                    setRepeatUnit={setRepeatUnit}
                                    dueDate={dueDate}
                                    setDueDate={setDueDate}
                                    startDate={null}
                                    disabled
                                />
                            </span>
                        </Tooltip>
                    </div>
                    <div className="divider" />
                    <LabelWithLine labelText="Customer" />
                    <div className="modal-line">
                        <Tooltip title="To change the linked customer, go to the detail page of this activity">
                            <span>
                                <CustomerLine disabled={onEditMode} customerName={customerName} />
                            </span>
                        </Tooltip>
                    </div>
                    <LabelWithLine labelText="Details" />
                    <div className="modal-line">
                        <Tooltip title={!onEditMode ? 'Type' : ''}>
                            <span>
                                <ActivityPartialUpdateType
                                    isBillable={isBillable}
                                    onChangeBillable={(val) => setIsBillable(val)}
                                    disabled={!onEditMode}
                                    type={type}
                                    onChange={(val) => {
                                        setType(val)
                                        if (val !== ActivityTypes.Service) {
                                            setIsBillable(false)
                                        }
                                    }}
                                />
                            </span>
                        </Tooltip>
                    </div>
                    <div className="modal-line">
                        <ActivityDescriptionAndNote disabled={!onEditMode} value={description} onChange={(val) => setDescription(val)} placeholder="Description" />
                    </div>
                    <LabelWithLine labelText="Checklist" classname="checklist-line" />
                    <div className="modal-line">
                        <Checklist
                            divideCompletedOnes
                            disabled={!onEditMode}
                            list={todos}
                            onChange={(list) => {
                                setTodos(list)
                                if (activity && todos !== JSON.parse(activity.todos)) {
                                    setOnEditMode(true)
                                }
                            }}
                            hideAddButton
                            editItemWithoutButton
                            showAddInputAlways
                        />
                    </div>
                </div>

                {onEditMode && (
                    <div className="cart-footer">
                        <div className="modal-line">
                            <Button className="action-button" type={ButtonType.Tertiary} onClick={onSecondary}>
                                Cancel
                            </Button>
                            <Button
                                className="action-button"
                                type={ButtonType.Primary}
                                onClick={() => {
                                    clickPrimary()
                                }}
                                disabled={!isValidForm()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PartialUpdateBox
