import React, { ChangeEventHandler, useEffect, useState } from 'react'
import Input from '../Input'
import { Size } from '../../models/enums'
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons'

// usage:
// const handleSearchedData = (data: CustomerEquipmentModel[]) => {
//     setFilteredData(data);
// }
// <SearchInput data={allData} handleSearch={handleSearchedData} searchKeys={['name','manufacturer.name']}/>

interface Props {
    multipleData?: any
    data: any
    searchKeys: string[]
    className?: string
    placeHolder?: string
    handleSearch: (data: any[]) => void
    foundItem?: (data: any) => void
    setDefaultSearch?: string | null
    storageKey?: string
    handleSearchTextValue?: (value: string) => void
    onKeyDown?: React.KeyboardEvent<HTMLImageElement> | any
    onFocus?: ChangeEventHandler<HTMLInputElement>
    resetReferance?: boolean
}

function SearchInput(props: Props) {
    const searchStorage = localStorage.getItem(props.storageKey || '')
    const [search, setSearch] = useState(searchStorage || '')

    useEffect(() => {
        if (props.handleSearchTextValue) {
            props.handleSearchTextValue(search)
        }
    }, [props, search])

    useEffect(() => {
        if (props.setDefaultSearch === '') {
            if (props.storageKey) localStorage.setItem(props.storageKey, search)
        }

        const searchValue = props.setDefaultSearch === null ? '' : search
        if (props.data) {
            handleOnChange(props.setDefaultSearch || searchValue || '')
        } /* else not needed */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, props.setDefaultSearch])

    useEffect(() => {
        if (props.storageKey) {
            localStorage.setItem(props.storageKey, search)
        }
    }, [props.storageKey, search, props.setDefaultSearch])

    const handleOnChange = (e: string) => {
        const searchValue = e
        const currentData: any[] = []
        const foundItemData: any[] = []
        setSearch(searchValue)
        if (searchValue.length > 0) {
            ;(props.multipleData ? (search ? props.multipleData : props.data) : props.data).forEach((item: any) => {
                props.searchKeys.forEach((key: string) => {
                    if (key?.includes('.')) {
                        const keys = key.split('.')
                        if (item[keys[0]] instanceof Array) {
                            item[keys[0]].forEach((element: any) => {
                                if (searchValue && element[keys[1]].toLowerCase().includes(searchValue.toLowerCase())) {
                                    if (currentData.some((addedItem: any) => addedItem.id === item.id)) {
                                        return
                                    }
                                    foundItemData.push(element)
                                    currentData.push(item)
                                }
                                props.foundItem && props.foundItem(foundItemData)
                                return
                            })
                        } else {
                            const value = item[keys[0]] ? item[keys[0]][keys[1]] : undefined
                            if (value === undefined) {
                                return
                            }
                            if (value.toLowerCase()?.includes(searchValue.toLowerCase())) {
                                if (currentData.some((addedItem: any) => addedItem.id === item.id)) {
                                    return
                                }
                                currentData.push(item)
                            }
                        }
                    } else {
                        const value = item[key]
                        if (value === undefined) {
                            return
                        }

                        if (item[key] instanceof Array) {
                            const newValue = item[key].join(', ')
                            if (newValue.toLowerCase().includes(searchValue.toLowerCase())) {
                                if (currentData.some((addedItem: any) => addedItem.id === item.id)) {
                                    return
                                }
                                currentData.push(item)
                            }
                            return
                        }
                        if (value) {
                            if (value.toLowerCase().includes(searchValue.toLowerCase())) {
                                if (currentData.some((addedItem: any) => addedItem.id === item.id)) {
                                    return
                                }
                                currentData.push(item)
                            }
                        }
                    }
                })
            })
            props.handleSearch(currentData)
        } else {
            props.handleSearch(props.data)
        }
    }

    useEffect(() => {
        setSearch('')
    }, [props.resetReferance])

    return (
        <Input
            onKeyDown={props.onKeyDown}
            className={props.className ? props.className : undefined}
            startIcon={faSearch}
            startIconColor="#767676"
            value={search}
            onChange={(e) => handleOnChange(e.target.value)}
            placeholder={props.placeHolder ? props.placeHolder : 'SEARCH'}
            size={Size.lg}
            endIcon={search ? faClose : undefined}
            endIconColor="#767676"
            onClickEndIcon={() => handleOnChange('')}
            onFocus={props.onFocus}
        />
    )
}

export default SearchInput
