import React from 'react'
import './Table.scss';
import {useMobileHook} from "../../hooks/useMobileHook";
import MTable from './MTable';
import MList, {MListProps} from './MList';

interface Props {
    titles: React.ReactNode[]
    data: any[]
    align?: ("left" | "center" | "right" | "justify" | "inherit")[]
    width?: string[]
    listObject?: MListProps[]
    persistMobile?: boolean
    isClickable?: boolean
}


export default function BasicTable(props: Props) {

    const { isMobile } = useMobileHook()

    if(props.isClickable && !props.data.every((d)=>d.link))
    {
        throw new Error("Clickable table data must have the link property!");
    } /* else not needed */

    return (
        <div className="table-wrapper">
            {((isMobile || props.persistMobile) && props.listObject)
            ? <MList mobileOnDesktop={props.persistMobile} items={props.listObject} />
            : <MTable
                titles={props.titles}
                data={props.data}
                align={props.align}
                width={props.width}
                isClickable={props.isClickable}
            />
            }
        </div>
    );
}
