import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { arraySort } from '../../../../utils/helpers'
import Icons, { IconType } from '../../../../Icon'
import './style.scss'
import { priceFormatter } from '../../../../utils/helpers'
import PopoverItem from '../../../../components/PopoverItem'
import { ignoreDashClassname } from '../../../../hooks/useTableHook'
import { useNavigate } from 'react-router'
import NoDataInfo from '../../../../components/NoDataInfo'

interface Data {
    name: string
    partNumber: string
    manufacturerName?: string
    category: string[] | any
    brand: string
    uom: string
    pkg: string
    price: number
    id: string
    select: any
}

export interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    sortKey?: string
    data: Data[]
    allSelect: React.ReactElement<HTMLInputElement>
    setSearch: (key: string) => void
    onProductPreview: (id: string) => void
    selectedProductId?: string
    navigate: string
    columns: HeadCell[]
}

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
}

function SortingMenu(props: SortingMenuType) {
    const { active, direction, children, onclick } = props

    return (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />
        </div>
    )
}

interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
    selectBox: React.ReactElement<HTMLInputElement>
    columns: HeadCell[]
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const { selectBox, orderBy, setOrderBy, direction, setDirection, columns } = tableProps

    return (
        <TableHead className="m-table-head">
            <TableRow>
                <TableCell padding="checkbox">{selectBox}</TableCell>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
                <TableCell />
            </TableRow>
        </TableHead>
    )
}

export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState(props.sortKey || 'name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const navigate = useNavigate()

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const tableCellRenderer = (column: HeadCell, row: Data) => {
        switch (column.id) {
            case 'name':
                return (
                    <TableCell className="product-name" style={{ width: '13%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                        {row.name && <span className="ellipsis-text">{row.name}</span>}
                        <div className="manu">
                            <span>{row.manufacturerName}</span>
                            {row.manufacturerName && row.partNumber ? <span className="seperator-dot">.</span> : <span></span>}
                            <span>{row.partNumber}</span>
                        </div>
                    </TableCell>
                )

            case 'category':
                return (
                    <TableCell style={{ width: '10%' }} onClick={() => props.onProductPreview(row.id)} align="left">
                        {row.category}
                    </TableCell>
                )

            case 'brand':
                return (
                    <TableCell style={{ width: '8%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                        {row.brand}
                    </TableCell>
                )

            case 'partNumber':
                return (
                    <TableCell style={{ width: '8%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                        {row.partNumber}
                    </TableCell>
                )

            case 'uom':
                return (
                    <TableCell style={{ width: '8%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                        {row.uom}
                    </TableCell>
                )

            case 'pkg':
                return (
                    <TableCell style={{ width: '10%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                        {row.pkg}
                    </TableCell>
                )

            case 'price':
                return (
                    <TableCell style={{ width: '10%' }} align="left" onClick={() => props.onProductPreview(row.id)}>
                        {priceFormatter(row.price || '-')}
                    </TableCell>
                )

            default:
                return <></>
        }
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer id="product-table" className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead columns={props.columns} orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} selectBox={props.allSelect} />
                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className={props.selectedProductId === row.id ? 'highlightedRow' : ''}
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        style={{ cursor: 'pointer' }}
                                        key={row.id}
                                        data-key={row.id}
                                    >
                                        <TableCell className="checkbox-cell" padding="checkbox">
                                            {row.select}
                                        </TableCell>
                                        {props.columns?.map((column) => tableCellRenderer(column, row))}
                                        <TableCell className={ignoreDashClassname} style={{ width: '2%', paddingLeft: 0 }} onClick={() => navigate(props.navigate + row.id)}>
                                            <div className={props.selectedProductId === row.id ? 'action-cell action-cell-hover' : 'action-cell'}>
                                                <PopoverItem popoverContent="View Details">
                                                    <Icons type={IconType.ChevronRight} />
                                                </PopoverItem>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
