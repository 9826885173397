import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { BaseOutputDto } from '../../models/Ui/Common'
import {
    ActivityDetailModel,
    ActivityGroupModel,
    ActivityModel,
    CreateActivityModel,
    CreateActivityTaskModel,
    UpdateActivityGroupModel,
    UpdateActivityModel,
    UpdatePartialActivityModel,
} from '../../models/Ui/Activity'

class ActivityService {
    async create(data: CreateActivityModel): Promise<ActivityModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateActivity, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error creating task')
        }
    }

    async createTask(data: CreateActivityTaskModel): Promise<ActivityDetailModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateActivityTask + data.activityId + '/task', data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error creating task')
        }
    }

    async getActivitiesForManifacturer(): Promise<ActivityModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetActivities, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get activities error')
        }
    }

    async getVisibleActivitiesForCutomer(): Promise<ActivityModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetVisibleActivitiesForCustomer, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get activities error')
        }
    }

    async getActivitiesGroup(id: string, groupId: string): Promise<ActivityGroupModel[]> {
        try {
            const response = await MonitorApi.get('/activity/' + id + '/group/' + groupId, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get activities error')
        }
    }

    async get(id: string): Promise<ActivityModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetActivityDetail + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get activity error')
        }
    }

    async getForCustomer(id: string): Promise<ActivityModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetActivitiesForCustomer + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get activities error')
        }
    }

    async setVisibility(activityId: string, visibility: boolean): Promise<BaseOutputDto> {
        try {
            const response = await MonitorApi.put(`/activity/${activityId}/visible-to-customer/${visibility}`, {}, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update activity error')
        }
    }

    async update(data: UpdateActivityModel): Promise<ActivityDetailModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateActivity + data.id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update activity error')
        }
    }

    async changeCustomer(activityId: string, customerId: string | null): Promise<ActivityDetailModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateActivity + activityId + '/change-customer', { id: customerId }, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update activity error')
        }
    }

    async changeContract(activityId: string, contractId: string | null): Promise<ActivityDetailModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateActivity + activityId + '/change-contract', { id: contractId }, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update activity error')
        }
    }

    async updatePartial(id: string, data: UpdatePartialActivityModel): Promise<ActivityDetailModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateActivity + id + '/partial', data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update activity error')
        }
    }
    async updateGroup(id: string, groupId: string, data: UpdateActivityGroupModel): Promise<ActivityDetailModel> {
        try {
            const response = await MonitorApi.put('activity/' + id + '/group/' + groupId, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update activity error')
        }
    }
    async delete(id: string) {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteActivity + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Delete activity error')
        }
    }
    async deleteByGrupId(groupId: string) {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteActivity + groupId + '/group', {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Delete activity error')
        }
    }

    async deleteTask(id: string) {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteActivityTask + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Delete activity error')
        }
    }

    async getAllByEquipmentId(id: string): Promise<ActivityModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetActivityByEquipmentId + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get activity by equipment id')
        }
    }

    async getAllByContractId(id: string): Promise<ActivityModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetActivityByContractId + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get activity by contract id')
        }
    }

    async getAllByProductId(id: string) {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetActivityByProductId + id, {})
            return response as any
        } catch (error: any) {
            console.log(error)
            throw new Error('Error when get activity by product id')
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ActivityService()
