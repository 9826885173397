import React, { ReactNode, useEffect, useState } from 'react'
import './style.scss'
import TreeViewNode, { NodeButton } from './Node'
import Button from '../Button'
import Icons, { IconType } from '../../Icon'
import { Color } from '../../models/enums'
import { useRef } from 'react'
import { useNavigate } from 'react-router'
import { EquipmentModel, TreeViewModel } from '../../models/New/Equipment'

interface Props {
    equipment: EquipmentModel
    className?: ReactNode
    nodeButtons?: NodeButton[]
    linkRoot?: string
    customerId?: string
    buttonsOnlyForRoot?: boolean
}

function HierarchialTreeView(props: React.PropsWithChildren<Props>) {
    const navigate = useNavigate()
    const treeviewWrapperRef = useRef<any>(null)
    const [treeViewVisible, setTreeViewVisible] = useState<boolean>(false)
    const tree: TreeViewModel = props.equipment.tree!

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (treeviewWrapperRef.current && !treeviewWrapperRef.current.contains(event.target)) {
                setTreeViewVisible(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('click', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    function prepareNodesRecursive(dataList: TreeViewModel[], deepLevel: number = 0, parent: TreeViewModel) {
        deepLevel++
        return dataList.map((data: TreeViewModel, index: number) => {
            return (
                <TreeViewNode
                    key={index}
                    id={data.id}
                    text={data.name}
                    parent={parent}
                    childNodes={data.children ? prepareNodesRecursive(data.children, deepLevel, data!) : undefined}
                    deepLevel={deepLevel}
                    buttons={props.nodeButtons}
                    buttonsOnlyForRoot={props.buttonsOnlyForRoot}
                    onClick={() => setTreeViewVisible(false)}
                    onClickItem={() => {
                        setTreeViewVisible(false)

                        if (props.customerId) {
                            if (data.parentId === undefined) {
                                navigate('/' + props.linkRoot + '/' + props.customerId + '/equipment/' + data.id)
                            } else {
                                navigate('/' + props.linkRoot + '/' + props.customerId + '/equipment/' + props.equipment.id + '/assembly/' + data.id)
                            }
                        } else {
                            if (data.parentId === undefined) {
                                navigate('/' + props.linkRoot + '/' + data.id)
                            } else {
                                navigate('/' + props.linkRoot + '/' + props.equipment.id + '/assembly/' + data.id)
                            }
                        }
                    }}
                />
            )
        })
    }

    return (
        <div className="new-tree-view-container" ref={treeviewWrapperRef}>
            <Button className="tree-view-button" onClick={() => setTreeViewVisible(!treeViewVisible)}>
                <Icons type={IconType.Treeview} color={Color.blue900} />
            </Button>
            {treeViewVisible && tree.id !== undefined && (
                <div className={`hierarchial-tree-view ${props.className ? props.className : ''}`}>
                    {prepareNodesRecursive([{ id: props.equipment.id, name: props.equipment.name, children: tree.children }], 0, { id: '-1', name: 'root' })}
                </div>
            )}
        </div>
    )
}

export default HierarchialTreeView
