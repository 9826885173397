import { DocumentData, DocumentReference } from "firebase/firestore";
import ProductDbModel from "./Product";

export default interface CustomerDbModel {
    name: string;
    email: string;
    products: CustomerProductDbModel[];
    additional_emails?: string[];
    custom_equipments: DocumentReference<DocumentData>[];
}

export interface CustomerProductDbModel {
    ref: DocumentReference<ProductDbModel>
    visible: boolean;
}

export interface CustomerEquipmentLocationModel {
    street1: string;
    street2: string
    city: string;
    state: string;
    postcode: string;
    country: string;
}

export enum CustomerEquipmentStatus {
    NotInvited = 'not_invited',
    Pending = 'pending',
    Accepted = 'accepted',
}