import { ReactNode } from 'react'

export enum Tabs {
    'Details' = 1,
    'DrawingAndParts',
    'Documents',
    'Customers',
    'Orders',
    'All',
    'Service',
    'ProductReplenishment',
    'Equipment',
    'Products',
    'Customer',
    'Company',
    'Account',
    'Team',
    'Payments',
    'Addresses',
    'HubspotIntegration',
    'Activities',
    'SavedCarts',
    'Installations',
    'Quotes',
    'EquipmentTemplates',
    'CustomInstallations',
    'SearchParts',
    'ReadNotifications',
    'UnreadNotifications',
    'Overview',
    'Contracts',
    'Checklist',
    'Records',
    'Notes',
    'Tasks',
    'Offers',
    'Templates',
    'PurchaseRequests',
}

export interface TabItem {
    id: number
    name: string
    customIcon?: ReactNode
    permissions?: string[]
    hidden?: boolean
}
