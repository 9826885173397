import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumb.scss";

export interface BreadcrumbModel {
  url?: string;
  name?: string;
}

interface Props {
  items: BreadcrumbModel[];
}



export const Breadcrumb = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      className="breadcrumb"
    >
      {props.items.map((item, index) =>
        <React.Fragment key={index}>
          {
            item.url
              ? <Link
                key={'item-link' + index}
                color="inherit"
                to={item.url}
              >
                <div className="breadcrumb-item">
                  {item.name}
                </div>
              </Link>
              : <div key={'item-active' + index} className="breadcrumb-item active">
                {item.name}
              </div>
          }
          {
            props.items.length !== index + 1
              && <div key={'item-separator' + index} className="breadcrumb-separator"><FontAwesomeIcon size="sm" icon={faCaretRight} /></div>
          }
        </React.Fragment>
      )}
    </div>
  );
};
