import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { HubspotProviderLogModel, HubspotProviderModel } from '../../models/Ui/Hubspot'
import { objectDeepCopy } from '../../utils/helpers'

const hubspotPayloadDateFilter = {
    value: 1641027600000,
    propertyName: 'hs_lastmodifieddate',
    operator: 'GTE',
}

const hubspotPayload = {
    filterGroups: [
        {
            filters: [
                {
                    propertyName: 'unit_serial_number',
                    operator: 'HAS_PROPERTY',
                },
            ],
        },
    ],
    properties: [
        'company',
        'unit_serial_number',
        'model',
        'unit_family',
        'core_tech_serial_number',
        'date_turned_on_for_the_first_time',
        'street_address_1',
        'street_address_2',
        'zip_code',
        'city',
        'state_province',
        'country',
        'hs_pipeline',
        'name',
    ],
    limit: 100,
    after: 0,
}

class HubspotService {
    getDateFilter(unitMs: number) {
        hubspotPayloadDateFilter.value = unitMs
        return hubspotPayloadDateFilter
    }

    async getUnits(lastSyncTime?: string): Promise<any> {
        try {
            const payload = objectDeepCopy(hubspotPayload)
            // if (lastSyncTime) {
            //     payload.filterGroups[0].filters.push(this.getDateFilter(dayjs(lastSyncTime).valueOf()))
            // } /* else not needed */
            const response = await MonitorApi.post(ServiceFunctionLookupTable.GetHubspotUnitsWithDetails, payload, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error in HubspotService.getUnits')
        }
    }
    async getByProviderType(params: string = 'hubspot'): Promise<HubspotProviderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetByProviderType, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error in HubspotService.getByProviderType')
        }
    }
    async getProviderLog(id: string): Promise<HubspotProviderLogModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetByProviderLog + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error in HubspotService.getByProviderType')
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HubspotService()
