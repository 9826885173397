import React from 'react'
import './HoverCard.scss'

interface HoverCardProps {
  className?: string
  title?: string
  rightMenu?: React.ReactNode;
  justifyTitleContent?: 'center' | 'flex-end' | 'flex-start'
  justifyHeaderContent?: 'center' | 'flex-end' | 'flex-start' | 'space-arround'
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

function HoverCard(props: React.PropsWithChildren<HoverCardProps>) {
  return (
    <div className={`hover-card ${props.className ? props.className : ''}`} onClick={props.onClick}>
      <div className="header" style={{ justifyContent: props.justifyHeaderContent || 'space-between' }}>
        {
          props.title &&
          <div className='title' style={{ justifyContent: props.justifyTitleContent || 'flex-start' }}>
            <h4>
              {props.title}
            </h4>
          </div>
        }
        {
          props.rightMenu &&
          <div className='right-button' style={{ float: "right" }}>
            {props.rightMenu}
          </div>
        }
      </div>

      <div className='content'>
        {props.children}
      </div>
    </div>
  )
}

export default HoverCard
