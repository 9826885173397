import Modal from '../../../../../components/Modal'
import Input from '../../../../../components/Input'
import ContractService from '../../../../../services/UiService/Contract'
import { useEffect, useState } from 'react'
import { ContractInputModel } from '../../../../../models/Ui/Contract'
import BasicDatePicker from '../../../../../components/DatePicker'
import TextArea from '../../../../../components/TextArea'
import dayjs, { ManipulateType } from 'dayjs'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import BasicDropdown from '../../../../../components/Dropdown/BasicDropdown'
import SearchableDropdown from './../../../../../components/Dropdown/SearchableDropdown/index'
import { ActivityCustomerListModel } from '../../../../../models/Ui/Company'
import './index.scss'
import CurrencyInput from '../../../../../components/CurrencyInput'
import { CommonActionTypes } from '../../../../../models/Ui/Common'

interface Props {
    customers: ActivityCustomerListModel[]
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: CommonActionTypes, data?: any) => void
    onError: (error: any) => void
}

function CreateContractModal(props: Props) {
    const [contract, setContract] = useState<ContractInputModel>({
        duration: undefined,
        durationUnit: '',
        customerId: undefined,
        startDate: null,
        endDate: null,
        firstBillingDate: null,
    })
    const [selectedContractDuration, setSelectedContractDuration] = useState({ id: '', text: '' })
    const [selectedBillingFrequency, setSelectedBillingFrequency] = useState({ id: '', text: '' })

    const billingPeriods = [
        {
            id: 124,
            text: '',
        },
        {
            id: 1,
            text: 'Weekly',
        },
        {
            id: 2,
            text: 'Biweekly',
        },
        {
            id: 3,
            text: 'Monthly',
        },
        {
            id: 4,
            text: 'Quarterly',
        },
        {
            id: 5,
            text: 'Annually',
        },

        {
            id: 6,
            text: 'Custom',
        },
    ]

    const durationUnits = [
        {
            id: 0,
            text: '',
        },
        {
            id: 1,
            text: 'Day',
        },
        {
            id: 2,
            text: 'Hour',
        },
        {
            id: 3,
            text: 'Week',
        },
        {
            id: 4,
            text: 'Month',
        },
        {
            id: 5,
            text: 'Year',
        },
    ].map((value) => {
        return {
            ...value,
            text: contract.duration && contract.duration > 1 ? (value.id === 0 ? value.text : value.text + 's') : value.text,
        }
    })

    useEffect(() => {
        updateDates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contract.firstBillingDate, contract.startDate, contract.duration, contract.durationUnit])

    const updateDates = () => {
        let endDate = contract.endDate

        if (contract.startDate && contract.duration && contract.durationUnit) {
            let tempDate = dayjs(contract.startDate)
            let date = dayjs(contract.startDate)

            let unit: ManipulateType | undefined

            if (contract.durationUnit.includes('Day')) unit = 'd'
            if (contract.durationUnit.includes('Week')) unit = 'w'
            if (contract.durationUnit.includes('Hour')) unit = 'h'
            if (contract.durationUnit.includes('Month')) unit = 'M'
            if (contract.durationUnit.includes('Year')) unit = 'y'

            if (unit) {
                date = tempDate.add(contract.duration, unit)
            }

            endDate = new Date(Date.UTC(date.get('year'), date.get('month'), date.get('date')))
        } else {
            endDate = null
        }
        let firstBillingDate = contract.firstBillingDate

        if (!contract.startDate && contract.firstBillingDate) {
            firstBillingDate = null
            toast.error('Start date must be selected for billing date')
        } else if (contract.firstBillingDate && dayjs(contract.firstBillingDate).diff(contract.startDate) < 0) {
            firstBillingDate = contract.startDate
            toast.error('First billing date cannot be before start date')
        }

        setContract((prevState) => {
            return {
                ...prevState,
                firstBillingDate: firstBillingDate,
                endDate: endDate,
            }
        })
    }

    async function create() {
        try {
            props.onLoading()
            await ContractService.create(contract)

            props.onSuccess(CommonActionTypes.CREATE)
            toast.success('Successfully added!')
            clearForm()
            props.closeModal()
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }

    function cancel() {
        clearForm()
        props.closeModal()
    }

    const isValidForm = () => {
        const validDuration = contract.durationUnit || (contract.duration && contract?.duration > 0) ? contract.durationUnit && contract.duration && contract.duration > 0 : true
        return contract.title !== null && contract.title !== undefined && validDuration
    }

    const clearForm = () => {
        setContract({
            duration: undefined,
            durationUnit: 'Day',
            customerId: undefined,
            startDate: null,
            endDate: null,
            firstBillingDate: null,
        })
    }

    return (
        <Modal
            visible={props?.visible}
            title="Add custom contract"
            className="contract-list-modal"
            primaryButtonText="Save"
            onClickPrimary={create}
            secondaryButtonText="Cancel"
            onClickSecondary={cancel}
            buttonOrder="secondaryPrimary"
            primaryDisabled={!isValidForm()}
        >
            <div className="dropdown-line">
                <SearchableDropdown
                    selectedItem={{ id: contract.customerId || '', label: props.customers.find((customer) => customer.id === contract.customerId)?.name || '' }}
                    className="customer-dropdown"
                    label="CUSTOMER"
                    data={(props.customers || []).map((customer) => ({ id: customer.id, label: customer.name }))}
                    onChange={(event, data) => {
                        setContract({ ...contract, customerId: data?.id })
                    }}
                    required={true}
                />
            </div>

            <span className="modal-line-title">Details</span>
            <div className="modal-line">
                <Input
                    value={contract.title}
                    onChange={(e) =>
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                title: e.target.value,
                            }
                        })
                    }
                    placeholder="TITLE"
                    required
                />
            </div>
            <div className="modal-line">
                <Input
                    value={contract.contractNumber}
                    onChange={(e) =>
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                contractNumber: e.target.value,
                            }
                        })
                    }
                    placeholder="CONTRACT NO."
                />
            </div>
            <div className="modal-line">
                <Input
                    value={contract.type}
                    onChange={(e) =>
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                type: e.target.value,
                            }
                        })
                    }
                    placeholder="TYPE"
                />
            </div>

            <div className="modal-line">
                <Input
                    required={!!(contract.durationUnit || (contract.duration && contract?.duration > 0))}
                    value={contract.duration}
                    type="number"
                    placeholder="DURATION"
                    onChange={(event) => {
                        setContract((prevState) => {
                            const val = parseInt(event.target.value)
                            return {
                                ...prevState,
                                duration: val,
                            }
                        })
                    }}
                    min={1}
                />
                <BasicDropdown
                    required={!!(contract.durationUnit || (contract.duration && contract?.duration > 0))}
                    label="CONTRACT DURATION"
                    menuItems={durationUnits}
                    onChange={(data) => {
                        setSelectedContractDuration(data)
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                durationUnit: data.text,
                            }
                        })
                    }}
                    selectedItem={selectedContractDuration}
                />
            </div>
            <div className="modal-line">
                <BasicDatePicker
                    placeholder="START DATE"
                    key={'startDate'}
                    startDate={contract.startDate}
                    onDateChange={(date) => {
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                startDate: date,
                            }
                        })
                    }}
                    customInput={<FontAwesomeIcon style={{ color: '#767676', paddingRight: '10px' }} icon={faCalendarAlt} />}
                />
                <BasicDatePicker
                    tooltip={'The end date is calculated based on the start date and contract duration'}
                    key={'endDate'}
                    placeholder="END DATE"
                    startDate={contract.endDate}
                    disabled={true}
                    customInput={<FontAwesomeIcon style={{ color: '#C4C4C4', paddingRight: '10px' }} icon={faCircleQuestion} />}
                    onDateChange={() => {}}
                />
            </div>

            <div className="modal-line">
                <TextArea
                    autoSize
                    value={contract.description}
                    onChange={(e) =>
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                description: e.target.value,
                            }
                        })
                    }
                    placeholder="DESCRIPTION"
                />
            </div>

            <span className="modal-line-title">Billing Details</span>
            <div className="modal-line">
                <CurrencyInput
                    placeholder="TOTAL CONTRACT PRICE"
                    value={contract.totalPrice}
                    onChange={(price) => {
                        setContract((prevState) => ({ ...prevState, totalPrice: price }))
                    }}
                />
            </div>

            <div className={'modal-line'}>
                <BasicDropdown
                    label="BILLING FREQUENCY"
                    menuItems={billingPeriods}
                    onChange={(data) => {
                        setSelectedBillingFrequency(data)
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                billingFrequency: data.text,
                            }
                        })
                    }}
                    selectedItem={selectedBillingFrequency}
                />
                <BasicDatePicker
                    minDate={contract.startDate ?? undefined}
                    customInput={<FontAwesomeIcon style={{ color: '#767676', paddingRight: '10px' }} icon={faCalendarAlt} />}
                    placeholder="FIRST BILLING DATE"
                    key={'firstBillingDate'}
                    startDate={contract.firstBillingDate}
                    onDateChange={(date) => {
                        setContract((prevState) => {
                            return {
                                ...prevState,
                                firstBillingDate: date,
                            }
                        })
                    }}
                    disabled={!contract.startDate}
                />
            </div>
        </Modal>
    )
}

export default CreateContractModal
