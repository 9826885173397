import React from 'react'
import { toast } from 'react-toastify'
import Icon, { IconType } from '../../Icon'
import './CopyButton.scss'

interface Props {
    className?: string
    disabled?: boolean
    value: string
}

function CopyButton(props: React.PropsWithChildren<Props>) {
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(props.value)
            toast.success('Copied!')
        } catch (error) {
            toast.error('Failed to copy!')
        }
    }

    return (
        <button disabled={props.disabled} onClick={() => copyToClipboard()} type="button" className={`copy-button ${props.className}`}>
            <Icon type={IconType.Copy} />
        </button>
    )
}

export default CopyButton
