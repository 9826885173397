import ViewAsCustomer from '../../../../../components/ViewAsCustomer'
import { NewUpdateEquipmentModel } from '../../../../../models/New/Equipment'
import NewNotepad from './../../../../../components/NewNotepad/index'
import NewProductDetailsTab from './NewProductDetailsTab'
import { ProductListModel } from '../../../../../models/New/Product'
import ProductService from '../../../../../services/UiService/Product'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import Loading from '../../../../../components/Loading'

interface Props {
    product: ProductListModel
    visible: boolean
    modalVisible: boolean
    onModalOpen: () => void
    onModalClose: () => void
    onSuccess: () => void
    setProduct: (product: ProductListModel) => void
    onAddDemand: () => void
    onClickManageCustomer: () => void
    activityLengthChanged: boolean
}

function DetailsTab(props: React.PropsWithChildren<Props>) {
    const { productId } = useParams()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {}, [props.modalVisible])

    async function saveNoteHandler(data: NewUpdateEquipmentModel) {
        try {
            await ProductService.update(data, productId!)
        } catch (error: any) {
            onErrorCallback(error)
        } finally {
            onCompleteCallback()
        }
    }

    const onErrorCallback = useCallback(async (error: any) => {
        toast.error(error)
    }, [])

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompleteCallback = useCallback(() => {
        setLoading(false)
    }, [])

    // const onSuccessCallback = useCallback(
    //     async (action: ProductActionTypes, data?: ProductListModel) => {
    //         props.onSuccess()
    //         switch (action) {
    //             case ProductActionTypes.UPDATE_PRODUCT:
    //                 props.setProduct({ ...props.product, ...data })
    //                 break
    //             case ProductActionTypes.DELETE_IMAGE:
    //                 props.setProduct({ ...props.product, images: data?.images })
    //                 break
    //             case ProductActionTypes.ADD_IMAGE:
    //                 props.setProduct({ ...props.product, images: data?.images })
    //                 break
    //             case ProductActionTypes.UPDATE_DOCUMENT:
    //                 props.setProduct({ ...props.product, documents: data?.documents ?? props.product.documents })
    //                 break
    //         }
    //     },
    //     [props.product]
    // )

    return (
        <>
            {loading && <Loading />}
            <div className="content-container" style={{ display: props.visible ? 'flex' : 'none' }}>
                <NewProductDetailsTab
                    onClickManageCustomer={props.onClickManageCustomer}
                    handleEdit={props.onModalOpen}
                    product={props.product}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompleteCallback}
                    onSuccess={props.onSuccess}
                    onError={onErrorCallback}
                    onAddDemand={props.onAddDemand}
                    activityLengthChanged={props.activityLengthChanged}
                />
                <div className="notepad-container">
                    <ViewAsCustomer>
                        <NewNotepad id={props.product.id as string} notes={props.product.notes as string} saveNote={(data: NewUpdateEquipmentModel) => saveNoteHandler(data)} />
                    </ViewAsCustomer>
                </div>
            </div>
            {/*{props.product && (*/}
            {/*    <ProductEditModal*/}
            {/*        product={props.product}*/}
            {/*        visible={props.modalVisible}*/}
            {/*        closeModal={props.onModalClose}*/}
            {/*        selectedProductId={productId!}*/}
            {/*        onLoading={onLoadingCallback}*/}
            {/*        onCompleted={onCompleteCallback}*/}
            {/*        onError={onErrorCallback}*/}
            {/*        onSuccess={onSuccessCallback}*/}
            {/*    />*/}
            {/*)}*/}
        </>
    )
}

export default DetailsTab
