import React, { useContext } from 'react'
import AdressBox from '../../../../components/AdressBox'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import EmailRecipients from './EmailRecipients'
import ShippingMethod from './ShippingMethod'
import './OrderRequirements.scss'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import { CustomerContactModel } from '../../../../models/New/Customer'
import { CheckoutType } from '../../../../models/Ui/Equipment'
import OrderService from './../../../../services/UiService/Order'
import AuthService from '../../../../services/UiService/Auth'
import { CreateOrderOrQuoteInput } from '../../../../models/Ui/Order'
import { CartActionTypes } from '../..'
import { toast } from 'react-toastify'

interface IProps {
    contacts: CustomerContactModel[] | undefined
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (type: CartActionTypes) => void
    onError: (error: any) => void
}

function OrderRequirements(props: IProps) {
    const {
        createCheckoutPayload,
        isSameAddress,
        handleIsSameAddress,
        billingAddress,
        handleBillingAddress,
        shippingAddress,
        handleShippingAddress,
        shippingMethod,
        handleShippingMethod,
        checkoutType,
        handleCheckoutType,
        reset,
        cartInformation,
        contextCustomParts,
        contextEquipments,
        contextProducts,
        contextCustomServices,
        recipientsEmails,
        savedCartInformation,
    } = useContext(CheckoutContext)
    const auth = new AuthService().getAuthUser()

    const handlePurchaseSubmit = async () => {
        try {
            props.onLoading()
            const payload = createCheckoutPayload(auth.id, CheckoutType.Purchase) as CreateOrderOrQuoteInput
            if (savedCartInformation.id) {
                await OrderService.updateSavedToPurchaseRequest(payload, savedCartInformation.id)
            } else {
                await OrderService.createPurchaseRequest(payload)
            }
            await reset()
            props.onSuccess(CartActionTypes.PURCHASE)
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }
    const handleSendQuote = async () => {
        const quotePayload = createCheckoutPayload(auth.id, CheckoutType.Quote) as CreateOrderOrQuoteInput
        if (!quotePayload.recipientEmails || quotePayload.recipientEmails.length === 0) {
            toast.warning('Add at least one recipient to send this quote.')
        } else {
            try {
                props.onLoading()
                if (savedCartInformation.id) {
                    await OrderService.updateSavedToQuote(quotePayload, savedCartInformation.id)
                } else {
                    if (cartInformation.isQuote) {
                        await OrderService.updateRFQToQuote(quotePayload, cartInformation.quoteId!)
                    } else {
                        await OrderService.createQuote(quotePayload)
                    }
                }
                await reset()
                props.onSuccess(CartActionTypes.SEND_QUOTE)
            } catch (error: any) {
                props.onError(error)
            } finally {
                props.onCompleted()
            }
        }
    }
    const isValidFormPurchase = () => {
        return contextCustomParts?.length !== 0 || contextEquipments?.length !== 0 || contextProducts?.length !== 0 || contextCustomServices?.length !== 0
    }
    const isValidFormQuote = () => {
        return (contextCustomParts?.length !== 0 || contextEquipments?.length !== 0 || contextProducts?.length !== 0 || contextCustomServices?.length !== 0) && recipientsEmails?.emails?.length > 0
    }
    return (
        <div className="requirements-container">
            <div className="type-button-container">
                <Button onClick={() => handleCheckoutType(CheckoutType.Quote)} className={`type-button  ${checkoutType === CheckoutType.Quote && 'checked'}`}>
                    Quote
                </Button>
                <Button disabled={cartInformation.isQuote} onClick={() => handleCheckoutType(CheckoutType.Purchase)} className={`type-button  ${checkoutType === CheckoutType.Purchase && 'checked'}`}>
                    Purchase Request
                </Button>
            </div>
            {checkoutType === CheckoutType.Purchase ? (
                <div className="requirement-container">
                    <div className="requirement-box">
                        <span className="requirement-box-title">Shipping Address</span>
                        <AdressBox contactInfo className="address" currentAddress={shippingAddress} handleAddress={handleShippingAddress} />
                        <label className="label-wrapper">
                            <Checkbox checked={isSameAddress} onChange={handleIsSameAddress} />
                            <span>Billing same as shipping address</span>
                        </label>
                    </div>
                    {!isSameAddress && (
                        <div className="requirement-box">
                            <span className="requirement-box-title">Billing Address</span>
                            <AdressBox className="address" currentAddress={billingAddress} handleAddress={handleBillingAddress} />
                        </div>
                    )}
                    <div className="requirement-box">
                        <span className="requirement-box-title">Shipping Method</span>
                        <ShippingMethod currentMethod={shippingMethod} handleMethod={handleShippingMethod} isQuote={true} />
                    </div>
                </div>
            ) : (
                <div className="requirement-container">
                    <div className="requirement-box">
                        <span className="requirement-box-title">Shipping Address</span>
                        <AdressBox contactInfo className="address" currentAddress={shippingAddress} handleAddress={handleShippingAddress} />
                    </div>
                    <div className="requirement-box">
                        <span className="requirement-box-title">Shipping Method</span>
                        <ShippingMethod currentMethod={shippingMethod} handleMethod={handleShippingMethod} isQuote={false} />
                    </div>
                    <div className="requirement-box">
                        <span className="requirement-box-title">
                            Email Recipients<b>*</b>
                        </span>
                        <EmailRecipients onLoading={props.onLoading} onCompleted={props.onCompleted} companyId={cartInformation.purchaseForCustomerId} contacts={props.contacts} />
                    </div>
                </div>
            )}
            <div className="send-button">
                {checkoutType === CheckoutType.Quote ? (
                    <Button disabled={!isValidFormQuote()} onClick={() => handleSendQuote()}>
                        Send Quote
                    </Button>
                ) : (
                    <Button disabled={!isValidFormPurchase()} onClick={() => handlePurchaseSubmit()}>
                        Submit
                    </Button>
                )}
            </div>
        </div>
    )
}

export default OrderRequirements
