import { NavLink } from 'react-router-dom'
import { ProductListModel } from '../../../../../models/New/Product'
import ProductCart from './ProductCart'
import './Products.scss'

interface Props {
    data: ProductListModel[]
}

function Products(props: Props) {
    return (
        <>
            {props.data.length > 0 ? (
                <div className="products-container">
                    <div className="products-content">
                        <div className="products-header">
                            <div className="title">Recommended Products</div>
                            <NavLink to="/products">
                                <div className="go-to-all">View all products</div>
                            </NavLink>
                        </div>
                        <div className="products-body">
                            {props.data.length > 0 &&
                                props.data.map((item) => {
                                    return <ProductCart productInfo={item} />
                                })}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}

export default Products
