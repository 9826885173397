import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icons, { IconType } from '../../../../Icon'
import { arraySort, isAbbreviation } from '../../../../utils/helpers'
import './style.scss'
import NoDataInfo from '../../../../components/NoDataInfo'
import { ReactNode } from 'react'

interface Data {
    name: string
    partNumber: string
    category: string[] | any
    brand: string
    description: string
    uom: string
    pkg: string
    price: string
    id: string
    button: ReactNode
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    data: Data[]
    setSearch: (key: string) => void
}

const columns: HeadCell[] = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'description',
        label: 'Description',
    },
    {
        id: 'category',
        label: 'Category',
    },
    {
        id: 'brand',
        label: 'Brand',
    },
    {
        id: 'uom',
        label: 'UOM',
    },
    {
        id: 'pkg',
        label: 'Pkg',
    },
    {
        id: 'partNumber',
        label: 'Part Number',
    },
    {
        id: 'price',
        label: 'Price',
    },
    {
        id: 'button',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'button' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container customer-product-table">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell className="font-bold" style={{ width: '15%' }} align="left" key={index}>
                                            <span className="ellipsis">{row.name}</span>
                                            {isAbbreviation(row.name.length) && <div className="table-hover">{row.name}</div>}
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }} align="left">
                                            {row.description && <span className="ellipsis">{row.description}</span>}
                                            {isAbbreviation(row.description.length) && <div className="table-hover">{row.description}</div>}
                                        </TableCell>
                                        <TableCell style={{ width: '8%' }} align="left">
                                            <div onClick={() => props.setSearch(row.category)}>{row.category}</div>
                                        </TableCell>
                                        <TableCell style={{ width: '8%' }} align="left">
                                            {row.brand}
                                        </TableCell>
                                        <TableCell style={{ width: '5%' }} align="left">
                                            {row.uom}
                                        </TableCell>
                                        <TableCell style={{ width: '5%' }} align="left">
                                            {row.pkg}
                                        </TableCell>
                                        <TableCell style={{ width: '10%' }} align="left">
                                            {row.partNumber}
                                        </TableCell>
                                        <TableCell style={{ width: '9%' }} align="left">
                                            <span className="ellipsis"> {row.price}</span>
                                        </TableCell>
                                        <TableCell style={{ width: '9%' }} className="custom-button" align="center">
                                            {row.button}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
