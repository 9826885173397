import React from 'react'
import './Card.scss'

interface IProps {
    equipmentsCount: number
    contractsCount: number
    handleLinkEquipment: () => void
}

function InstallationCount(props: IProps) {
    const { equipmentsCount, contractsCount, handleLinkEquipment } = props

    return (
        <div className="overview-card">
            <div className="item">
                <div className="item-header center">
                    <span className="title">No. of Installations</span>
                </div>
                <div className="line center">
                    <span className="count">{equipmentsCount}</span>
                </div>
                <div onClick={handleLinkEquipment} className="action-button center">
                    +add
                </div>
            </div>
            <div className="divider" />
            <div className="item">
                <div className="item-header center">
                    <span className="title">No. of Contracts</span>
                </div>
                <div className="line center">
                    <span className="count">{contractsCount}</span>
                </div>
                {/*<div className="action-button center">+add</div>*/}
            </div>
        </div>
    )
}

export default InstallationCount
