import { useEffect, useState } from 'react'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import './style.scss'
import { isEqual } from 'lodash'
import { EquipmentLinkToCustomer } from '../../../../../models/New/Equipment'
import SearchInput from '../../../../../components/SearchInput'
import Checkbox from '../../../../../components/Checkbox'
import Modal from '../../../../../components/Modal'
import { EquipmentListModel } from './../../../../../models/New/Equipment'
import Input from '../../../../../components/Input'
import NoDataInfo from '../../../../../components/NoDataInfo'

interface Props {
    equipments: EquipmentListModel[]
    selectedEquipments: string[]
    visible: boolean
    closeModal: () => void
    onSave: (param: EquipmentLinkToCustomer[]) => void
}

interface Equipment extends EquipmentListModel {
    checked: boolean
    installCount: number
}

function LinkEquipmentsModal(props: Props) {
    const { onSave, equipments } = props
    const [tempEquipments, setTempEequipments] = useState<Equipment[]>(
        equipments.map((equipment) => {
            return {
                ...equipment,
                checked: props.selectedEquipments.includes(equipment.id),
                installCount: 1,
            }
        })
    )
    const [filteredData, setFilteredData] = useState<Equipment[]>(
        equipments.map((equipment) => {
            return {
                ...equipment,
                checked: props.selectedEquipments.includes(equipment.id),
                installCount: 1,
            }
        })
    )

    const onChangeCheckbox = (id: string) => {
        if (tempEquipments.find((x) => x.id === id)!.checked) {
            const indexOf = tempEquipments.findIndex((x) => x.id === id)
            tempEquipments[indexOf].checked = false
            setTempEequipments([...tempEquipments])
        } else {
            const indexOf = tempEquipments.findIndex((x) => x.id === id)
            tempEquipments[indexOf].checked = true
            setTempEequipments([...tempEquipments])
        }
    }

    const onSelectAll = () => {
        if (tempEquipments.filter((x) => x.checked).length === equipments.length) {
            tempEquipments.forEach((x) => {
                x.checked = false
            })
            setTempEequipments([...tempEquipments])
        } else {
            tempEquipments.forEach((x) => {
                x.checked = true
            })
            setTempEequipments([...tempEquipments])
        }
    }

    const onInstallCountChange = (id: string, value: number) => {
        const indexOf = tempEquipments.findIndex((x) => x.id === id)
        tempEquipments[indexOf].installCount = value
        tempEquipments[indexOf].checked = true
        setTempEequipments([...tempEquipments])
    }

    const onClickSave = () => {
        const tempData = tempEquipments
            .filter((x) => x.checked)
            .map((p) => {
                return {
                    equipmentId: p.id,
                    installCount: p.installCount,
                } as EquipmentLinkToCustomer
            })
        onSave(tempData)
    }

    useEffect(() => {
        const data = equipments.map((equipment) => {
            return {
                ...equipment,
                checked: props.selectedEquipments.includes(equipment.id),
                installCount: 1,
            }
        })
        setTempEequipments(data)
        setFilteredData(data)
    }, [props.selectedEquipments, equipments])

    const handleSearchedData = (data: any[]) => {
        setFilteredData(data)
    }

    return (
        <Modal
            className="link-equipment-modal"
            visible={props.visible}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            onClickPrimary={() => onClickSave()}
            onClickSecondary={() => props.closeModal()}
            title="Link equipment(s) to customer"
            primaryDisabled={isEqual(
                tempEquipments.filter((x) => x.checked).map((x) => x.id),
                props.selectedEquipments
            )}
        >
            <SearchInput data={tempEquipments} handleSearch={handleSearchedData} searchKeys={['name', 'partNumber']} setDefaultSearch={''} />
            {filteredData.length > 0 ? (
                <TableContainer className="pagination-table" component={Paper} style={{ maxHeight: '400px' }}>
                    <Table className="link-equipment-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox onChange={() => onSelectAll()} checked={tempEquipments.filter((x) => x.checked).length === equipments.length} />
                                </TableCell>
                                <TableCell> Count </TableCell>
                                <TableCell> Equipment Name </TableCell>
                                <TableCell> Part Number </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody style={{ maxHeight: '450px' }}>
                            {filteredData.map((row, ri) => (
                                <TableRow key={ri} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>
                                        <Checkbox onChange={() => onChangeCheckbox(row.id)} checked={row.checked} />
                                    </TableCell>
                                    <TableCell style={{ minWidth: '80px' }}>
                                        <div className="modal-line">
                                            <Input
                                                type="number"
                                                placeholder="Count"
                                                value={row.installCount}
                                                onChange={(e) => {
                                                    onInstallCountChange(row.id, e.target.valueAsNumber)
                                                }}
                                                min={1}
                                                onBlur={(e) => (!e.target.valueAsNumber || e.target.valueAsNumber === 0) && onInstallCountChange(row.id, 1)}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ minWidth: '80px' }}>{row.name}</TableCell>
                                    <TableCell>{row.partNumber}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoDataInfo style={{ marginTop: '25px' }} />
            )}
        </Modal>
    )
}

export default LinkEquipmentsModal
