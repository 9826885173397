import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Icons, { IconType } from '../../../../../Icon'
import { arraySort } from '../../../../../utils/helpers'
import { Collapse } from '@mui/material'
import { ExpandItemInfoModel } from '../Order'
import { ignoreDashClassname } from '../../../../../hooks/useTableHook'
import NoDataInfo from '../../../../../components/NoDataInfo'

interface Data {
    name: string
    category?: string
    vendor?: string
    partNumber: string
    uom?: string
    pkg?: string
    equipmentName?: string
    serialNumber?: string
    qty: JSX.Element
    price: JSX.Element | undefined
    buttons: React.ElementRef<any>
    id: string
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    expandItemInfo: ExpandItemInfoModel
    data: Data[]
}

const columns: HeadCell[] = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'partNumber',
        label: 'Part Number',
    },
    {
        id: 'qty',
        label: 'Qty.',
    },
    {
        id: 'price',
        label: 'Unit Price',
    },
    {
        id: 'buttons',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return !props.disable ? (
        <div className="sorting-menu">{props.children}</div>
    ) : (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding={'normal'}>
                        <SortingMenu
                            disable={false}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function OrderListTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending').map((row: any, index) => {
                            return (
                                <>
                                    <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                                        <TableCell className="font-bold" style={{ width: '40%' }} align="left">
                                            {row.name}
                                            {row.equipmentName && (
                                                <div className="table-cell-description">
                                                    {row.equipmentName} • {row.serialNumber}
                                                </div>
                                            )}
                                            {row.category && (
                                                <div className="table-cell-description">
                                                    {row.category} • {row.uom} •{row.pkg}
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell className="font-bold" style={{ width: '15%' }} align="left">
                                            {row.partNumber}
                                            {row.vendor && <div className="table-cell-description">{row.vendor}</div>}
                                        </TableCell>
                                        <TableCell className="font-bold" style={{ width: '15%' }} align="left">
                                            {row.qty}
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }} align="left">
                                            {row.price}
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }} align="left">
                                            {row.buttons}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="expand-row">
                                        <TableCell colSpan={12} className={ignoreDashClassname}>
                                            <Collapse
                                                style={{ width: '100%' }}
                                                in={props.expandItemInfo.visible && props.expandItemInfo.itemId === row.expandItemId}
                                                timeout="auto"
                                                className={'collapse-detail'}
                                                unmountOnExit
                                            >
                                                <div style={{ padding: '1rem' }} className="expand-item">
                                                    {row.expandItem}
                                                </div>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
