import { EMappingCircleColor, EMappingCircleSize, MappingCircleColor, MappingCircleSize } from './components/MappingCircle/MappingCircle.interface'

export const MAPPING_RADIUS = EMappingCircleSize.MEDIUM

export const mappingCircleColors: MappingCircleColor[] = [
    { background: EMappingCircleColor.RED, color: EMappingCircleColor.WHITE, stroke: EMappingCircleColor.RED },
    { background: EMappingCircleColor.GREEN, color: EMappingCircleColor.WHITE, stroke: EMappingCircleColor.GREEN },
    { background: EMappingCircleColor.BLUE, color: EMappingCircleColor.WHITE, stroke: EMappingCircleColor.BLUE },
    { background: EMappingCircleColor.WHITE, color: EMappingCircleColor.WHITE, stroke: EMappingCircleColor.WHITE },
    { background: EMappingCircleColor.BLACK, color: EMappingCircleColor.WHITE, stroke: EMappingCircleColor.BLACK },
]

export const mappingCircleSizes: MappingCircleSize[] = [
    { background: EMappingCircleColor.RED, size: EMappingCircleSize.SMALL, color: EMappingCircleColor.WHITE },
    { background: EMappingCircleColor.RED, size: EMappingCircleSize.MEDIUM, color: EMappingCircleColor.WHITE },
    { background: EMappingCircleColor.RED, size: EMappingCircleSize.LARGE, color: EMappingCircleColor.WHITE },
]
