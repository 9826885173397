import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import EasyIcon from '../../../components/EasyIcon'
import Icons, { IconType } from '../../../Icon'
import ActivityListTable, {
    activityTableColumns,
    ActivityTableDataModel,
    assigneeColorSetter,
    assigneeFirstNameSetter,
    assigneeShortNameSetter,
    AssigneeWithColorModel,
    filterAssignees,
    HeadCell,
    HeadCellType,
    setAssigneeInputPlaceholder,
    useUniqueAssigneesWithColorsSetter,
} from './ActivityListTable'
import './ActivityList.scss'
import { ActivityModel, ActivityTypes } from '../../../models/Ui/Activity'
import { toast } from 'react-toastify'
import ActivityService from '../../../services/UiService/Activity'
import { priceFormatter, standardDate } from '../../../utils/helpers'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { CompanyType } from '../../../models/Ui/CompanyType'
import SearchInput from '../../../components/SearchInput'
import Button from '../../../components/Button'
import { CustomerManufacturerContext } from '../../../store/CustomerDashboardContext'
import PartialUpdateBox from './PartialUpdateBox'
import NoData from '../../../components/NoData'
import CheckBoxDropdownInput from '../../../components/CheckBoxDropdownInput'
import { Switch, Tooltip } from '@mui/material'
import Tab from '../../../components/Tab'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import BasicDropdown from '../../../components/Dropdown/BasicDropdown'
import AddActivityPopupSidebar from './Modals/AddActivityPopupSidebar'
import UpdateActivityPopupSidebar from './Modals/UpdateActivityPopupSidebar'
import { CommonActionTypes } from '../../../models/Ui/Common'

export enum ActivitySpecificActionTypes {
    ACTIVITY_VISIBILITY,
}

export type ActivityActionTypes = CommonActionTypes | ActivitySpecificActionTypes

interface ActivityListProps {
    companyType: CompanyType
}

export interface FilterType {
    id: string | any
    text: string
}

export enum StatusFilterType {
    All = 'all',
    Open = 'open',
    InProgress = 'in-progress',
    OnHold = 'on-hold',
    Closed = 'closed',
}

export enum SortByType {
    None = 'none',
    Product = 'product',
    Equipment = 'equipment',
}

export interface DueDateFilter extends FilterType {
    key?: dayjs.OpUnitType
}

export interface StatusFilter extends FilterType {
    type?: StatusFilterType
}

export interface SortByFilter extends FilterType {
    type: SortByType
}

export interface FilterState {
    dueDate: DueDateFilter
    status: FilterType
    sortBy: SortByFilter
}

export enum FilterKeys {
    DueDate = 'dueDate',
    Type = 'type',
    Status = 'status',
    SortBy = 'sortBy',
}

const tabItems: TabItem[] = [
    {
        id: Tabs.All,
        name: 'All',
    },
    {
        id: Tabs.Service,
        name: 'Service',
        customIcon: <Icons type={IconType.Maintenance} />,
    },
    {
        id: Tabs.ProductReplenishment,
        name: 'Products',
        customIcon: <Icons type={IconType.Product} />,
    },
    {
        id: Tabs.Activities,
        name: 'Tickets',
        customIcon: <Icons type={IconType.Ticket} />,
    },
]

const ActivityList: FC<ActivityListProps> = ({ companyType }) => {
    const { selectedManufacturerInfo: selectedManufacturer } = useContext(CustomerManufacturerContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [createModalVisible, setCreateModalVisible] = useState<boolean>(false)
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false)
    const [partialUpdateModalVisible, setPartialUpdateModalVisible] = useState<boolean>(false)
    const [activities, setActivities] = useState<ActivityModel[]>([])
    const [filteredActivitiesData, setFilteredActivitiesData] = useState<ActivityModel[]>([])
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [selectedActivtyIds, setselectedActivtyIds] = useState<{ id: string; groupId: string }>({ id: '', groupId: '' })
    const [uniqueAssigneesWithColors, setUniqueAssigneesWithColors] = useState<AssigneeWithColorModel[]>([])
    const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([])
    const [showAllAsignees, setShowAllAsignees] = useState(true)
    const [viewHistoricalOnes, setViewHistoricalOnes] = useState(false)
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)
    const [activityTableData, setActivityTableData] = useState<ActivityTableDataModel[]>([])
    const [selectedProductId, setSelectedProductId] = useState<string>('')
    const [selectedEquipmentId, setSelectedEquipmentId] = useState<string>('')

    useUniqueAssigneesWithColorsSetter(activities, setUniqueAssigneesWithColors)

    useEffect(() => {
        setSelectedAssigneeIds(uniqueAssigneesWithColors.map((child) => child.assigneeId))
    }, [uniqueAssigneesWithColors])

    dayjs.extend(localizedFormat)

    const dueDateFilterTypes: DueDateFilter[] = [
        { id: ' ', text: 'All Time' },
        {
            id: dayjs(),
            text: 'Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'day'),
            text: 'Before Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'week'),
            text: 'Last Week',
            key: 'week',
        },
        {
            id: dayjs().add(1, 'week'),
            text: 'Next Week',
            key: 'week',
        },
        {
            id: dayjs(),
            text: 'This Week',
            key: 'week',
        },
        {
            id: dayjs().subtract(1, 'month'),
            text: 'Last Month',
            key: 'month',
        },
        {
            id: dayjs().add(1, 'month'),
            text: 'Next Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Year',
            key: 'year',
        },
    ]
    const statusFilterTypes: StatusFilter[] = [
        { id: ' ', text: 'All', type: StatusFilterType.All },
        { id: 'Open', text: 'Open', type: StatusFilterType.Open },
        { id: 'In Progress', text: 'In Progress', type: StatusFilterType.InProgress },
        { id: 'On Hold', text: 'On Hold', type: StatusFilterType.OnHold },
        { id: 'Closed', text: 'Closed', type: StatusFilterType.Closed },
    ]

    const sortByFilterOptions: SortByFilter[] = [
        { id: ' ', text: 'None', type: SortByType.None },
        { id: 'Product', text: 'Product', type: SortByType.Product },
        { id: 'Equipment', text: 'Equipment', type: SortByType.Equipment },
    ]

    const initialFilter: FilterState = {
        dueDate: { ...dueDateFilterTypes[0] } as DueDateFilter,
        status: { ...statusFilterTypes[0] } as FilterType,
        sortBy: sortByFilterOptions[0],
    }
    const [filterState, setFilterState] = useState<FilterState>(initialFilter)

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Activities',
        },
    ]

    const setFilter = (newState: any, key: FilterKeys) =>
        setFilterState((prevState) => ({
            ...prevState,
            [key]: newState,
        }))

    const getActivitiesCallback = useCallback(async () => {
        try {
            if (companyType === CompanyType.Manufacturer) {
                setLoading(true)
                const response = await ActivityService.getActivitiesForManifacturer()
                setActivities(response)
            } else {
                setLoading(true)
                const response = await ActivityService.getVisibleActivitiesForCutomer()
                setActivities(response)
            }
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }, [companyType])

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onSuccessCallback = useCallback(
        async (action: ActivityActionTypes, data: any) => {
            switch (action) {
                case CommonActionTypes.CREATE:
                    if (data) {
                        setActivities(activities.concat(data))
                    } /* else not needed */
                    break
                case CommonActionTypes.DELETE:
                    if (data) {
                        const newData: ActivityModel[] = activities?.filter((item) => item.id !== data)
                        setActivities(newData)
                    } /* else not needed */
                    break
                case CommonActionTypes.UPDATE:
                    await getActivitiesCallback()
                    break
                case ActivitySpecificActionTypes.ACTIVITY_VISIBILITY:
                    await getActivitiesCallback()
                    break
            }
        },
        [activities, getActivitiesCallback]
    )

    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getActivitiesCallback()
            setLoading(false)
        }

        loadComponent()
    }, [getActivitiesCallback, selectedManufacturer])

    const handleSearchedData = (data: ActivityModel[]) => {
        setFilteredActivitiesData(data)
    }
    const openModal = () => {
        setCreateModalVisible(true)
    }
    const toggleFilterMenu = () => {
        setFilterState(initialFilter)
        setShowFilter(!showFilter)
    }

    const onChangeVisibility = async (activityId: string, visibility: boolean) => {
        try {
            setLoading(true)
            const res = await ActivityService.setVisibility(activityId, visibility)
            getActivitiesCallback()
            toast.success(res.message)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const setActivityTableColumns = (): HeadCell[] => {
        const sortByNoneColumns: HeadCellType[] = [HeadCellType.Empty, HeadCellType.Title, HeadCellType.Customer, HeadCellType.Assignee, HeadCellType.Status, HeadCellType.DueDate, HeadCellType.Action]
        const sortByProductColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.ProductWithPartNumber,
            HeadCellType.Quantity,
            HeadCellType.TaskWithCustomer,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]
        const sortByEquipmentColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Equipment,
            HeadCellType.TaskWithCustomer,
            HeadCellType.Assignee,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]

        let output: HeadCell[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = sortByNoneColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Product) {
            output = sortByProductColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            output = sortByEquipmentColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        }

        if (partialUpdateModalVisible) {
            return output.filter((item) => ![HeadCellType.Customer, HeadCellType.Assignee].includes(item.type))
        }

        return output
    }

    useEffect(() => {
        const activityTableDataMapper = (args: ActivityModel[]): ActivityTableDataModel[] => {
            let output: ActivityTableDataModel[] = []

            if (filterState.sortBy.type === SortByType.None) {
                output = args.map((child) => {
                    return {
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        equipments: child.equipments,
                        products: child.products,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                        assigneeFirstName: assigneeFirstNameSetter(child),
                        contractId: child.contractId,
                        contract: child.contract,
                    }
                })
            } else if (filterState.sortBy.type === SortByType.Product) {
                args.forEach((child) => {
                    output = [
                        ...output,
                        ...child.products.map((product) => ({
                            isWorkOrder: child.isWorkOrder,
                            recurring: child.isRecurring,
                            title: child.title,
                            customer: child.customer?.name,
                            assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                            status: child.status,
                            dueDate: standardDate(child.dueDate),
                            opportunity: priceFormatter(child.revenueOpportunity || '-'),
                            id: child.id,
                            groupId: child.groupId,
                            isVisibleToCustomer: child.isVisibleToCustomer,
                            description: child.description,
                            type: child.type,
                            attachments: child.attachments,
                            productId: product?.productId ?? '',
                            productName: product?.product?.name ?? '',
                            productPartNumber: product?.product?.partNumber ?? '',
                            productQuantity: product?.quantity ?? 0,
                            customerCompanyName: child.customer.name,
                            equipments: child.equipments,
                            assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                            assigneeShortName: assigneeShortNameSetter(child.assignee),
                            contractId: child.contractId,
                            contract: child.contract,
                        })),
                    ]
                })
            } else if (filterState.sortBy.type === SortByType.Equipment) {
                args.forEach((child) => {
                    output = [
                        ...output,
                        ...child.equipments.map((equipment) => ({
                            isWorkOrder: child.isWorkOrder,
                            recurring: child.isRecurring,
                            title: child.title,
                            customer: child.customer?.name,
                            assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                            status: child.status,
                            dueDate: standardDate(child.dueDate),
                            opportunity: priceFormatter(child.revenueOpportunity || '-'),
                            id: child.id,
                            groupId: child.groupId,
                            isVisibleToCustomer: child.isVisibleToCustomer,
                            description: child.description,
                            type: child.type,
                            attachments: child.attachments,
                            customerCompanyName: child.customer.name,
                            equipmentId: equipment?.id ?? '',
                            equipmentName: equipment?.name ?? '',
                            products: child.products,
                            assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                            assigneeShortName: assigneeShortNameSetter(child.assignee),
                            contractId: child.contractId,
                            contract: child.contract,
                        })),
                    ]
                })
            }

            return output
        }
        setActivityTableData(
            activityTableDataMapper(
                filteredActivitiesData
                    .filter((item: ActivityModel) => {
                        if (filterState.dueDate.id === ' ') {
                            return item
                        } else if (filterState.dueDate.text === 'Before Today') {
                            return filterState.dueDate.id >= dayjs(item.dueDate)
                        }
                        return filterState.dueDate.id.isSame(dayjs(item.dueDate), filterState.dueDate.key)
                    })
                    .filter((item: ActivityModel) => filterAssignees(item, showAllAsignees, selectedAssigneeIds))
                    .filter((item: ActivityModel) => (filterState.status.id === ' ' ? item : item.status === filterState.status.id))
                    .filter((item: ActivityModel) => !viewHistoricalOnes || (viewHistoricalOnes && item.status === 'Closed'))
                    .filter((item) => {
                        if (activeTab === Tabs.Service) {
                            return item.type === ActivityTypes.Service
                        } else if (activeTab === Tabs.ProductReplenishment) {
                            return item.type === ActivityTypes.ProductReplensh
                        } else if (activeTab === Tabs.Activities) {
                            return item.type === ActivityTypes.Ticket
                        } else {
                            return true
                        }
                    })
            )
        )
    }, [
        activeTab,
        filterState.dueDate.id,
        filterState.dueDate.key,
        filterState.dueDate.text,
        filterState.sortBy.type,
        filterState.status.id,
        filteredActivitiesData,
        selectedAssigneeIds,
        showAllAsignees,
        uniqueAssigneesWithColors,
        viewHistoricalOnes,
    ])

    const toggleHistoricalLog = (event: ChangeEvent<HTMLInputElement>) => {
        setViewHistoricalOnes(event.target.checked)
        if (filterState.status.id !== 'Closed' && !viewHistoricalOnes) {
            setFilter(statusFilterTypes[4], FilterKeys.Status)
        } else setFilter(statusFilterTypes[0], FilterKeys.Status)
    }

    const clearFilters = () => {
        setFilterState(initialFilter)
        setShowAllAsignees(true)
        setViewHistoricalOnes(false)
        setSelectedAssigneeIds(uniqueAssigneesWithColors.map((item) => item.assigneeId))
    }

    const onClickViewMoreAtPartialUpdateModal = () => {
        setPartialUpdateModalVisible(false)
        setUpdateModalVisible(true)
    }

    return (
        <>
            {loading && <Loading />}
            {activities.length === 0 ? (
                <>
                    <NoData
                        handleClick={() => setCreateModalVisible(true)}
                        breadcrumb={breadcrumb}
                        pageTitle="Activities"
                        title="You currently do not have any activities.  Start by adding one now!"
                    />
                </>
            ) : (
                <PageWrapper className="master-page" breadcrumb={breadcrumb}>
                    <ContentBox
                        title="Activities"
                        headerIcon={<EasyIcon icon={IconType.Activities} />}
                        headerRight={
                            <div className="activity-right-buttons">
                                <SearchInput
                                    data={activities}
                                    handleSearch={handleSearchedData}
                                    searchKeys={['title', 'customer.name', 'assignedBy.firstName', 'assignedBy.lastName', 'startDate', 'frequencyUnit', 'type']}
                                />
                                <Tooltip title={showFilter ? 'Hide Filters' : 'Show Filters'}>
                                    <span>
                                        <Button className={showFilter ? 'filter-button--active' : 'filter-button'} onClick={toggleFilterMenu}>
                                            <Icons type={IconType.FilterSettings} />
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Add new activity">
                                    <span>
                                        <Button className="create-activity-button" onClick={openModal}>
                                            <Icons type={IconType.PlusCircle} />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>
                        }
                    >
                        {activities.length > 0 && (
                            <Tab
                                activeTab={activeTab}
                                tabs={tabItems}
                                onTabClick={(id) => {
                                    setActiveTab(id)
                                }}
                            ></Tab>
                        )}
                        {showFilter && (
                            <div className="filter-menu">
                                <BasicDropdown
                                    label="View by Linked Records"
                                    menuItems={sortByFilterOptions}
                                    leftIcon={IconType.Checklist}
                                    onChange={(e) => setFilter(e, FilterKeys.SortBy)}
                                    selectedItem={{ id: filterState.sortBy.id, text: filterState.sortBy.text }}
                                    classname={filterState.sortBy.id !== ' ' ? 'active-dropdown' : ''}
                                />
                                <BasicDropdown
                                    label="Due Date"
                                    menuItems={dueDateFilterTypes}
                                    leftIcon={IconType.Calendar}
                                    onChange={(e) => setFilter(e, FilterKeys.DueDate)}
                                    selectedItem={{ id: filterState.dueDate.id, text: filterState.dueDate.text }}
                                    classname={filterState.dueDate.id !== ' ' ? 'active-dropdown' : ''}
                                />
                                <CheckBoxDropdownInput
                                    disabled={!uniqueAssigneesWithColors.length}
                                    selectedItems={selectedAssigneeIds}
                                    placeholder="Assignee"
                                    listItems={(uniqueAssigneesWithColors || []).map((p) => {
                                        return {
                                            id: p.assigneeId,
                                            text: p.assigneeName,
                                        }
                                    })}
                                    dropIcon={true}
                                    onDataChange={(data, allChecked) => {
                                        setSelectedAssigneeIds(data)
                                        setShowAllAsignees(allChecked as boolean)
                                    }}
                                    showAllCheckbox
                                    showCustomInputLabelWhenEmpty
                                    customInputLabel={setAssigneeInputPlaceholder(selectedAssigneeIds, showAllAsignees, uniqueAssigneesWithColors)}
                                    wrapperClass={uniqueAssigneesWithColors.length !== selectedAssigneeIds.length ? 'input-active' : ''}
                                />
                                <BasicDropdown
                                    label="Status"
                                    menuItems={statusFilterTypes}
                                    leftIcon={IconType.DottedCircle}
                                    onChange={(e) => setFilter(e, FilterKeys.Status)}
                                    selectedItem={{ id: filterState.status.id, text: filterState.status.text }}
                                    classname={filterState.status.id !== ' ' ? 'active-dropdown' : ''}
                                />
                                <Tooltip title={viewHistoricalOnes ? 'Hide historical log' : 'Show historical log'}>
                                    <Switch
                                        icon={<Icons type={IconType.HistoricalLog} />}
                                        checkedIcon={<Icons type={IconType.HistoricalLog} />}
                                        className="historicalLogSwitch"
                                        onChange={toggleHistoricalLog}
                                        checked={viewHistoricalOnes}
                                    />
                                </Tooltip>
                                <span className="clear-filter" onClick={clearFilters}>
                                    Clear filters
                                </span>
                            </div>
                        )}

                        <div className="activityContent">
                            <div className={`activity-table ${partialUpdateModalVisible && 'onBox'}`}>
                                <ActivityListTable
                                    columns={setActivityTableColumns()}
                                    highlightedRowId={partialUpdateModalVisible ? selectedActivtyIds.id : ''}
                                    selectedProductId={partialUpdateModalVisible ? selectedProductId : ''}
                                    selectedEquipmentId={partialUpdateModalVisible ? selectedEquipmentId : ''}
                                    isManufacturer={companyType === CompanyType.Manufacturer}
                                    onChangeVisibility={onChangeVisibility}
                                    sortKey="dueDate"
                                    onHandleClick={(id, groupId, isPartial) => {
                                        if (companyType === CompanyType.Manufacturer) {
                                            if (!isPartial) {
                                                setUpdateModalVisible(true)
                                                setPartialUpdateModalVisible(false)
                                            } else {
                                                setPartialUpdateModalVisible(true)
                                            }
                                            setselectedActivtyIds({ id: id, groupId: groupId })
                                        }
                                    }}
                                    onClickProductAndEquipment={(productId, equipmentId) => {
                                        setSelectedProductId(productId)
                                        setSelectedEquipmentId(equipmentId)
                                    }}
                                    data={activityTableData}
                                />
                            </div>
                            {selectedActivtyIds && partialUpdateModalVisible && (
                                <div className="partial-update-box">
                                    <PartialUpdateBox
                                        id={selectedActivtyIds.id}
                                        visible={!!selectedActivtyIds && partialUpdateModalVisible}
                                        closeModal={() => {
                                            setPartialUpdateModalVisible(false)
                                        }}
                                        onLoading={onLoadingCallback}
                                        onCompleted={onCompletedCallback}
                                        onSuccess={onSuccessCallback}
                                        onError={onErrorCallback}
                                        onViewMore={onClickViewMoreAtPartialUpdateModal}
                                    />
                                </div>
                            )}
                        </div>
                        {selectedActivtyIds && updateModalVisible && (
                            <UpdateActivityPopupSidebar
                                id={selectedActivtyIds.id}
                                groupId={selectedActivtyIds.groupId}
                                onChangeId={(id) => setselectedActivtyIds({ ...selectedActivtyIds, id: id })}
                                visible={updateModalVisible}
                                onClose={async () => {
                                    setUpdateModalVisible(false)
                                    await getActivitiesCallback()
                                }}
                                onLoading={onLoadingCallback}
                                onCompleted={onCompletedCallback}
                                onSuccess={onSuccessCallback}
                                onError={onErrorCallback}
                            />
                        )}
                    </ContentBox>
                </PageWrapper>
            )}
            {createModalVisible && (
                <AddActivityPopupSidebar
                    onLoading={onLoadingCallback}
                    onSuccess={onSuccessCallback}
                    onError={onErrorCallback}
                    onCompleted={onCompletedCallback}
                    visible={createModalVisible}
                    onClose={() => setCreateModalVisible(false)}
                />
            )}
        </>
    )
}

export default ActivityList
