import React, { useCallback, useEffect, useRef, useState } from 'react'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Table from '../../../components/Table'
import { toast } from 'react-toastify'
import Checkbox from '../../../components/Checkbox'
import ShareWithCustomersModal from './ShareWithCustomersModal'
import { CustomerSummaryModel } from '../../../models/Ui/Customer'
import { csvToJson } from '../../../utils/helpers'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import RemoveProductApprovalModal from './RemoveProductApprovalModal'
import ThreeDotButton from '../../../components/ThreeDotButton'
import SearchInput from '../../../components/SearchInput'
import { MListProps } from '../../../components/Table/MList'
import EasyIcon from '../../../components/EasyIcon'
import Icons, { IconType } from '../../../Icon'
import MasterCatalogTable, { HeadCell } from './MasterCatalogTable'
import Button from '../../../components/Button'
import ProductService from '../../../services/UiService/Product'
import { NewUpdateProductModel, ProductListModel, ProductModel, UpdateCustomersProductsModel } from '../../../models/New/Product'
import CustomerService from '../../../services/UiService/Customer'
import UploadImageModal from './UploadImageModal'
import NoData from '../../../components/NoData'
import { IDropdownData } from '../../../components/NoData/interface'
import AddProductModal from './AddProductModal'
import BulkUploadModal from './BulkUploadModal'
import ProductPreview from './ProductPreview'
import PopoverItem from '../../../components/PopoverItem'
import NoDataInfo from '../../../components/NoDataInfo'
import { ProductActionTypes } from '../ProductDetail/actiontypes.enum'

interface ProductModelWithCheckbox extends ProductModel {
    checked: boolean
}

interface UploadProductImgModel {
    url?: string
    id: string
    visible: boolean
}

function MasterCatalog() {
    const [loading, setLoading] = useState<boolean>(true)
    const [shareWithCustomersModalVisible, setShareWithCustomersModalVisible] = useState<boolean>(false)
    const [customers, setCustomers] = useState<CustomerSummaryModel[] | null>(null)
    const [products, setProducts] = useState<ProductModelWithCheckbox[]>([])
    const [filteredProductData, setFilteredProductData] = useState<ProductModelWithCheckbox[]>([])
    const productIds = useRef<string[]>([])
    const [uploadProductModalVisible, setUploadProductModalVisible] = useState<boolean>(false)
    const [uploadProductImgModalInfo, setUploadProductImgModalInfo] = useState<UploadProductImgModel | undefined>(undefined)
    const [removeProductApprovalModalVisible, setRemoveProductApprovalModalVisible] = useState<boolean>(false)
    const [selectedAll, setSelectedAll] = useState<boolean>(false)
    const [selectedView, setSelectedView] = useState<boolean>(false)
    const [searchKey, setSearchKey] = useState<string>('')
    const [addProductModalVisible, setAddProductModalVisible] = useState<boolean>(false)
    const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState<boolean>(false)
    const [selectedProductId, setSelectedProductId] = useState<string>('')
    const [productPreviewVisible, setProductPreviewVisible] = useState<boolean>(false)
    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Products',
        },
    ]
    const productTableHeaders: React.ReactNode[] = [
        <Checkbox checked={selectedAll} disabled={false} onChange={() => onProductChecked('all')} />,
        'Part Number',
        'Product Name',
        'Product Category',
        'Brand',
        'Description',
        'UOM',
        'Pkg',
        'Price',
    ]

    const dropdownData: IDropdownData[] = [
        { title: 'Add new product', clickAction: () => setAddProductModalVisible(true) },
        { title: 'Bulk upload', clickAction: () => setBulkUploadModalVisible(true) },
    ]

    /*const [productCustomers, setProductCustomers] = useState<
    CustomerSummaryModel[] | null
  >(null)*/

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getProducts()
            setLoading(false)
        }

        loadComponent()
    }, [])

    async function getCustomers() {
        try {
            const page: number = 0
            const size: number = 9999
            setLoading(true)
            const manufacturerService = new CustomerService()
            const customers = await manufacturerService.getCustomersForManufacturer({ page, size })
            setCustomers(customers.content)
        } catch (e: any) {
            console.error(e)
            toast.error(e)
        } finally {
            setLoading(false)
        }
    }

    async function getProducts() {
        try {
            const page: number = 0
            const size: number = 9999
            const products = await ProductService.getProductList({ page, size })
            setProducts([])
            setFilteredProductData([])
            const productsWithCheckbox = products.content.map((product) => {
                return {
                    ...product,
                    checked: false,
                } as any
            })
            setProducts(productsWithCheckbox)
            setFilteredProductData(productsWithCheckbox)
        } catch (e: any) {
            console.error(e)
            toast.error(e)
        }
    }

    async function shareWithCustomersHandler(pushCustomerIds: string[]) {
        try {
            setLoading(true)
            const customersProducts: UpdateCustomersProductsModel = {
                customerIds: pushCustomerIds,
                productIds: productIds.current,
            }
            await ProductService.linkCustomersToProducts(customersProducts)
            toast.success('Successfully linked!')
            productIds.current = []
            await getProducts()
        } catch (error: any) {
            toast.error(error)
        } finally {
            setShareWithCustomersModalVisible(false)
            setLoading(false)
        }
    }

    async function removeProducts() {
        try {
            setLoading(true)
            await ProductService.delete(productIds.current)
            const newData = products.filter((o1) => !productIds.current.some((o2) => o1.id === o2))
            setProducts(newData)
            productIds.current = []
        } catch (error: any) {
            toast.error(error)
        } finally {
            setRemoveProductApprovalModalVisible(false)
            setLoading(false)
        }
    }

    function onProductChecked(productId: string) {
        if (productId === 'all') {
            let checked: boolean = !(products.filter((product) => product.checked).length === products.length)

            products.forEach((product) => {
                product.checked = checked
            })
        } else {
            products.forEach((product) => {
                if (product.id === productId) {
                    product.checked = !product.checked
                } /* else not needed */
            })
        }

        setSelectedAll(products.filter((product) => product.checked).length === products.length)

        setProducts(() => [...products])
        setFilteredProductData(() => [...products])
        productIds.current = products.filter((product) => product.checked).map((product) => product.id)
    }

    const updateProductListHandler = async (products: File, isCsvUploaded: boolean) => {
        try {
            setLoading(true)

            if (!isCsvUploaded) {
                throw new Error('CSV not uploaded!')
            } /* else not needed */

            const productList = await new Promise<ProductListModel>((resolve, reject) => {
                csvToJson(products, (json) => {
                    if (json[0]['Product name']) {
                        const products: ProductListModel = json
                            .map((product: any) => {
                                if (product['Product name'] !== '') {
                                    return {
                                        partNumber: product['Part number'],
                                        name: product['Product name'],
                                        category: product['Product category'],
                                        brand: product['Brand'],
                                        description: product['Description'],
                                        uom: product['Unit of measurement'],
                                        pkg: product['Packaging'],
                                        price: product['Price'] ? product['Price'].replace('$', '').replaceAll(',', '') : null,
                                    } as ProductListModel
                                } else {
                                    return null
                                }
                            })
                            .filter((product: ProductListModel) => product !== null)

                        resolve(products)
                    } else {
                        reject()
                        toast('CSV file not valid!', {
                            type: toast.TYPE.ERROR,
                        })
                        throw new Error('CSV file not valid!')
                    }
                })
            }).catch((error: any) => {
                throw error
            })
            await ProductService.createProducts(productList)
            await getProducts()
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setUploadProductModalVisible(false)
            setLoading(false)
        }
    }
    const updateProductDrawingUrl = async (url: string) => {
        try {
            setLoading(true)
            if (uploadProductImgModalInfo) {
                await ProductService.update({ drawingUrl: url }, uploadProductImgModalInfo?.id)
                await getProducts()
            }
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setUploadProductImgModalInfo(undefined)
            setLoading(false)
        }
    }

    const handleSearchedData = (data: ProductModelWithCheckbox[]) => {
        setFilteredProductData(data)
    }

    const categoryImageRender = (categoryName: string) => {
        return (
            <div className="category-image" style={{ display: 'none' }}>
                <img src={`/assets/images/${categoryName ? (categoryName === 'Chemicals' ? 'chem' : categoryName) : 'default'}.png`} alt="category" />
            </div>
        )
    }

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    const onSuccessCallback = useCallback(
        async (action?: ProductActionTypes, data?: NewUpdateProductModel) => {
            switch (action) {
                case ProductActionTypes.UPDATE_PRODUCT:
                    const filteredProducts = products.filter((product) => product.id !== data?.id)
                    const product = products.filter((product) => product.id === data?.id)
                    setProducts([...filteredProducts, { ...product, ...data } as unknown as ProductModelWithCheckbox])
                    break
                case ProductActionTypes.DELETE_PRODUCT:
                    await getProducts()
                    break
                default:
                    break
            }
        },
        [products]
    )

    const getListObj = (): MListProps[] | undefined =>
        filteredProductData.map((product: ProductModelWithCheckbox) => {
            return {
                type: 'horizontal',
                children: [
                    {
                        element: (
                            <div className="card-first">
                                <Checkbox checked={product.checked} disabled={false} onChange={() => onProductChecked(product.id)} />
                                {categoryImageRender(product.category)}
                            </div>
                        ),
                    },
                    {
                        type: 'vertical',
                        children: [
                            {
                                type: 'horizontal',
                                customStyle: {
                                    justifyContent: 'space-between',
                                },
                                children: [
                                    {
                                        element: (
                                            <div className="element-wrapper">
                                                <div className="card-wrapper">
                                                    <div className="column-card">
                                                        <div className="title">{product.name}</div>
                                                        <div className="desc-wrapper">
                                                            <div className="desc-column">
                                                                <div>
                                                                    <div className="desc-alg">
                                                                        <span className="label">Product Category: </span>
                                                                        <span className="desc"> {product.category}</span>
                                                                    </div>
                                                                    <div className="desc-alg">&bull;</div>
                                                                    <div className="desc-alg">
                                                                        <span className="label">Part number:</span>
                                                                        <span className="desc">{product.partNumber}</span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="desc-alg">
                                                                        <span className="label">Brand:</span>
                                                                        <span className="desc"> {product.brand}</span>
                                                                    </div>
                                                                    <div className="desc-alg">&bull;</div>
                                                                    {/* <div className='desc-alg'>
                                <span className='label'>Manufacturer:</span>
                                <span className='desc'>{product.manufacturer.}</span>
                              </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="long-text">{product.description}</div>
                                            </div>
                                        ),
                                    },
                                ],
                            },
                            {},
                        ],
                    },
                ],
            }
        })

    const columns: HeadCell[] = [
        {
            id: 'name',
            label: 'Product Name',
        },
        {
            id: 'category',
            label: 'Category',
        },
        {
            id: 'brand',
            label: 'Brand',
        },
        {
            id: 'partNumber',
            label: 'MFG Part Number',
        },
        {
            id: 'uom',
            label: 'UOM',
        },
        {
            id: 'pkg',
            label: 'Pkg',
        },
        {
            id: 'price',
            label: 'Price',
        },
    ]

    const columnRendered = () => {
        const sidebarOpenedColumnIds = ['name', 'pkg', 'price']

        if (selectedProductId && productPreviewVisible) {
            return columns?.filter((child) => sidebarOpenedColumnIds.some((id) => child.id === id)) ?? []
        }

        return columns
    }

    return (
        <div>
            {products.length === 0 ? (
                <>
                    {loading && <Loading />}
                    <NoData
                        breadcrumb={breadcrumb}
                        pageTitle="Products"
                        title="You currently do not have any products on Monittor.  Start by adding one now!"
                        hasIsDropdown={true}
                        dropdownData={dropdownData}
                    />
                    <BulkUploadModal visible={bulkUploadModalVisible} submit={updateProductListHandler} closeModal={() => setBulkUploadModalVisible(false)} />
                </>
            ) : (
                <PageWrapper className="master-page" breadcrumb={breadcrumb}>
                    {loading && <Loading />}

                    <ContentBox
                        title="Products"
                        headerIcon={<EasyIcon icon={IconType.Product} />}
                        headerRight={
                            <div className="product-search-area">
                                <SearchInput
                                    setDefaultSearch={searchKey}
                                    data={products}
                                    handleSearch={handleSearchedData}
                                    searchKeys={['name', 'partNumber', 'brand', 'category', 'description', 'uom', 'pkg']}
                                />
                                <PopoverItem popoverContent="Add new">
                                    <Button className="create-product-button" onClick={() => setAddProductModalVisible(true)}>
                                        <Icons type={IconType.BoldPlus} />
                                    </Button>
                                </PopoverItem>
                                <PopoverItem popoverContent={selectedView ? 'List View' : 'Card View'}>
                                    <Button className="toggle-button" onClick={() => setSelectedView(!selectedView)}>
                                        {!selectedView ? <Icons type={IconType.Menus} /> : <Icons type={IconType.CardView} />}
                                    </Button>
                                </PopoverItem>
                                <PopoverItem popoverContent="Actions">
                                    <ThreeDotButton
                                        actions={[
                                            {
                                                text: 'Bulk Upload',
                                                onClick: () => setUploadProductModalVisible(true),
                                            },
                                            {
                                                text: 'Export List',
                                                onClick: () => {},
                                            },
                                            {
                                                text: 'Link Customers',
                                                disabled: productIds.current.length === 0 && true,
                                                onClick: async () => {
                                                    if (productIds.current.length > 0) {
                                                        if (!customers) {
                                                            await getCustomers()
                                                        }
                                                        setShareWithCustomersModalVisible(true)
                                                    } else {
                                                        toast.error('Please, select at least one product from the list!')
                                                    }
                                                },
                                            },
                                            {
                                                text: 'Delete Products',
                                                disabled: productIds.current.length === 0 && true,
                                                onClick: () => {
                                                    if (productIds.current.length > 0) {
                                                        setRemoveProductApprovalModalVisible(true)
                                                    } else {
                                                        toast.error('Please, select at least one product from the list!')
                                                    }
                                                },
                                            },
                                        ]}
                                    />
                                </PopoverItem>
                            </div>
                        }
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1.31rem' }}>
                            <div className="product-table-container" style={{ width: productPreviewVisible ? '68%' : '100%' }}>
                                {filteredProductData.length > 0 ? (
                                    selectedView ? (
                                        <Table
                                            titles={
                                                filteredProductData.length !== filteredProductData.filter((p) => p.price === 0).length
                                                    ? productTableHeaders
                                                    : productTableHeaders.pop()
                                                    ? productTableHeaders
                                                    : []
                                            }
                                            data={filteredProductData.map((product) => {
                                                return {
                                                    select: <Checkbox checked={product.checked} disabled={false} onChange={() => onProductChecked(product.id)} />,
                                                    partNumber: product.partNumber,
                                                    name: product.name,
                                                    category: product.category,
                                                    brand: product.brand,
                                                    description: product.description,
                                                    uom: product.uom,
                                                    pkg: product.pkg,
                                                    price: product.price ? '$' + parseFloat(product.price.toString()).toFixed(2) : '',
                                                }
                                            })}
                                            persistMobile={true}
                                            listObject={getListObj()}
                                        />
                                    ) : (
                                        <MasterCatalogTable
                                            columns={columnRendered()}
                                            setSearch={(e) => {
                                                if (searchKey === e) {
                                                    setSearchKey('')
                                                } else {
                                                    setSearchKey(e)
                                                }
                                            }}
                                            selectedProductId={selectedProductId}
                                            allSelect={
                                                <Checkbox
                                                    checked={products.filter((product) => product.checked).length === products.length}
                                                    disabled={false}
                                                    onChange={() => onProductChecked('all')}
                                                />
                                            }
                                            data={filteredProductData.map((p) => {
                                                return {
                                                    select: <Checkbox checked={p.checked} disabled={false} onChange={() => onProductChecked(p.id)} />,
                                                    partNumber: p.partNumber,
                                                    name: p.name,
                                                    category: p.category,
                                                    brand: p.brand,
                                                    uom: p.uom,
                                                    pkg: p.pkg,
                                                    price: p.price,
                                                    id: p.id,
                                                    manufacturerName: p.manufacturer?.name,
                                                }
                                            })}
                                            onProductPreview={(id: string) => {
                                                setSelectedProductId(id)
                                                setProductPreviewVisible(true)
                                            }}
                                            navigate="/products/"
                                        />
                                    )
                                ) : (
                                    <NoDataInfo />
                                )}
                            </div>
                            {selectedProductId && productPreviewVisible && (
                                <ProductPreview
                                    closeModal={() => {
                                        setProductPreviewVisible(false)
                                        setSelectedProductId('')
                                    }}
                                    selectedProductId={selectedProductId}
                                    onCompleted={onCompletedCallback}
                                    onLoading={onLoadingCallback}
                                    onError={onErrorCallback}
                                    onSuccess={onSuccessCallback}
                                />
                            )}
                        </div>
                    </ContentBox>
                    <ShareWithCustomersModal
                        customers={customers!}
                        assignedCustomers={[]}
                        visible={shareWithCustomersModalVisible}
                        shareWithCustomers={shareWithCustomersHandler}
                        closeModal={() => setShareWithCustomersModalVisible(false)}
                    />
                    <UploadImageModal
                        onLoading={() => setLoading(true)}
                        onCompleted={() => setLoading(false)}
                        visible={uploadProductImgModalInfo?.visible || false}
                        closeModal={() => setUploadProductImgModalInfo(undefined)}
                        submit={updateProductDrawingUrl}
                        selectedFile={uploadProductImgModalInfo?.url}
                    />

                    {/*<UploadProductModal visible={uploadProductModalVisible} closeModal={() => setUploadProductModalVisible(false)} submit={updateProductListHandler} />*/}

                    <RemoveProductApprovalModal visible={removeProductApprovalModalVisible} closeModal={() => setRemoveProductApprovalModalVisible(false)} onApprove={removeProducts} />
                    <BulkUploadModal visible={uploadProductModalVisible} submit={updateProductListHandler} closeModal={() => setUploadProductModalVisible(false)} />
                </PageWrapper>
            )}
            {addProductModalVisible && (
                <AddProductModal
                    visible={addProductModalVisible}
                    closeModal={() => setAddProductModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onError={onErrorCallback}
                    onSuccess={async () => await getProducts()}
                />
            )}
        </div>
    )
}
export default MasterCatalog
