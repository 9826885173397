import { ReactNode, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

import './Tab.scss'

//usage example:
// <Tab activeTab={'all'} onTabClick={handleClick} tabs={
//   [
//     {name: 'Test', id: 'all', icon: faSquareFull},
//     {name: 'Test2', id: 'popular', customIcon: <span>CustomIcon</span>},
//     {name: 'Test3', id: 'new', icon: faSquareFull},
//     {name: 'Test4', id: 'top', icon: faSquareFull},
//     {name: 'Test5', id: 'best', icon: faSquareFull},
//   ]
// }/>

type TabProps = {
    activeTab: number
    tabs: Array<{ name: string; id: number; icon?: IconDefinition; customIcon?: ReactNode }>
    onTabClick: (id: number) => void
}

export default function Tab(props: TabProps) {
    const { activeTab, tabs, onTabClick } = props
    const [activeTabId, setActiveTabId] = useState(activeTab)

    const handleTabClick = (tab: { name: string; id: number }) => {
        setActiveTabId(tab.id)
        onTabClick(tab.id)
    }

    useEffect(() => {
        setActiveTabId(activeTab)
    }, [activeTab])

    return (
        <div className="tabs">
            {tabs.map((tab) => (
                <div key={tab.id} className={`tab ${tab.id === activeTabId ? 'active' : ''}`} onClick={() => handleTabClick(tab)}>
                    {tab.icon && (
                        <span className="tab-icon">
                            <FontAwesomeIcon icon={tab.icon} />
                        </span>
                    )}
                    {tab.name}
                    {!tab.icon && tab.customIcon && <span className="tab-icon">{tab.customIcon}</span>}
                </div>
            ))}
        </div>
    )
}
