import React from 'react'
import { EquipmentModel } from '../../../../../../../models/New/Equipment'
import './EquipmentInfoCart.scss'

interface Props {
    installationCount: number
    equipment: EquipmentModel
    flexWrap?: boolean
    handleLinkCustomers: () => void
    handleEdit: () => void
}

function EquipmentInfoCart(props: Props) {
    return (
        <div className="equipment-info-cart-container">
            <div className="warranty-box">
                <span className="title">Standard Warranty Duration</span>
                <div className="content">
                    {props.equipment.warrantyDuration && <span className="amount">{props.equipment.warrantyDuration}</span>}
                    {props.equipment.warrantyDurationUnit && <span className="unit">{props.equipment.warrantyDurationUnit}</span>}
                </div>
                <span className="add-button" onClick={props.handleEdit}>
                    +update
                </span>
            </div>

            <div className="divider" />

            <div className="customer-amount-box">
                <span className="title">No. of Customers</span>
                <div className="content">
                    <span className="amount">{props.equipment?.customers?.length}</span>
                </div>
                <span className="add-button" onClick={props.handleLinkCustomers}>
                    +manage
                </span>
            </div>

            <div className="divider" />

            <div className="installations-amount-box">
                <span className="title">No. of Installations</span>
                <div className="content">
                    <span className="amount">{props.installationCount}</span>
                </div>
                <span className="add-button" onClick={props.handleLinkCustomers}>
                    +manage
                </span>
            </div>
        </div>
    )
}

export default EquipmentInfoCart
