import Icons, { IconType } from '../../Icon';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { isIconDefinition } from '../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    className?: string
    icon?: IconDefinition | IconType
    iconColor?: string
}

function EasyIcon(props: Props) {
    return (
        <>
            {
                props.icon &&
                (
                    isIconDefinition(props.icon) ?
                        <FontAwesomeIcon className={props.className} icon={props.icon as IconDefinition} color={props.iconColor} />
                        :
                        <Icons className={props.className} type={props.icon as IconType} color={props.iconColor} />
                )
            }
        </>
    )
}

export default EasyIcon
