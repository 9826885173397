import { useContext } from 'react'
import { Link } from 'react-router-dom'
import Button, { ButtonType } from '../../components/Button'
import CartItem from '../../components/CartItem'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'
import './QuickCart.scss'
import Icons, { IconType } from '../../Icon'
import Input from '../Input'
import PopoverItem from '../PopoverItem'
import AdditionalForm from '../AdditionalForm'

function QuickCart() {
    const {
        contextEquipments,
        contextCustomParts,
        contextProducts,
        contextCustomServices,
        cartInformation,
        removePart,
        addPart,
        setPartCount,
        removeProduct,
        setProductCount,
        addProduct,
        clearPart,
        clearProduct,
        removeAdditionalItem,
        removeAdditionalServiceItem,
        setAdditionalItemCount,
        toggleQuickCartView,
        removeCustomPart,
        addCustomPart,
        addCustomService,
        removeCustomService,
        setAdditionalServiceItemCount,
        getTotalPrice,
        purchaseWithoutCustomer,
        customerNameOfPurchaseWithoutCustomer,
        handleCustomerNameOfPurchaseWithoutCustomer,
        addAdditionalItem,
        addAdditionalServiceItem,
    } = useContext(CheckoutContext)

    return (
        <div className="quick-cart-container">
            <div className="cart-header">
                <div className="cart-title">
                    <Icons type={IconType.Transactions} />
                    <h4>Quick Cart</h4>
                </div>
                <div onClick={() => toggleQuickCartView()} className="cart-close">
                    <PopoverItem popoverContent="Collapse cart">
                        <Icons type={IconType.Close} />
                    </PopoverItem>
                </div>
            </div>
            <div className="customer-info">
                {purchaseWithoutCustomer ? (
                    <>
                        <span className="without-custmer-section">
                            <span className="without-custmer-section-note">Manually enter your customer's name or inventory</span>
                            <Input type="text" placeholder="CUSTOMER" value={customerNameOfPurchaseWithoutCustomer} onChange={(e) => handleCustomerNameOfPurchaseWithoutCustomer(e.target.value)} />
                        </span>
                    </>
                ) : (
                    <>
                        {cartInformation.purchaseForCustomerId && cartInformation.purchaseForCustomerName ? (
                            <span className="selected-customer-name">{cartInformation.purchaseForCustomerName}</span>
                        ) : (
                            <span className="select-customer-note">It looks like you have not added anything to your cart. Search for equipment parts to start purchasing!</span>
                        )}
                    </>
                )}
            </div>
            <div className="cart-body">
                {contextEquipments.length > 0 && (
                    <div className="cart-wrapper">
                        <div className="title">PARTS</div>
                        {contextEquipments.map((e, i) => {
                            return (
                                <div key={'eq' + i.toString()} className="cart-wrapper">
                                    {e.parts.map((p, i) => {
                                        return (
                                            <CartItem
                                                key={'part' + i.toString()}
                                                name={p.part.name}
                                                count={p.count}
                                                price={p.part.price || '-'}
                                                serialNumber={p.part.serialNo}
                                                equipmentName={e.equipment.name}
                                                description={p.part?.part_no}
                                                onClickPlus={() => addPart(e.equipment, p.part)}
                                                onClickMinus={() => removePart(e.equipment, p.part)}
                                                onChangeInput={(value) => setPartCount(e.equipment, p.part, value)}
                                                onClickTrash={() => clearPart(e.equipment, p.part)}
                                            />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )}
                {contextProducts.length > 0 && (
                    <div className="cart-wrapper">
                        <Link to="/products">
                            <div className="title">PRODUCTS</div>
                        </Link>
                        {contextProducts.map((p, i) => {
                            return (
                                <CartItem
                                    key={'part' + i.toString()}
                                    name={p.product.name}
                                    price={p.product.price || '-'}
                                    count={p.count}
                                    onClickPlus={() => addProduct(p.product)}
                                    onClickMinus={() => removeProduct(p.product)}
                                    onChangeInput={(count) => setProductCount(p.product, count)}
                                    description={p.product.note}
                                    onClickTrash={() => clearProduct(p.product)}
                                />
                            )
                        })}
                    </div>
                )}
                {contextCustomParts?.length > 0 && (
                    <div className="cart-wrapper">
                        <div className="title">CUSTOM PARTS</div>
                        {contextCustomParts.map((p, i) => {
                            return (
                                <CartItem
                                    key={'part' + i.toString()}
                                    name={p.customPart.name}
                                    price={p.customPart.price || '-'}
                                    count={p.count}
                                    onClickPlus={() => addCustomPart(p.customPart)}
                                    onClickMinus={() => removeCustomPart(p.customPart)}
                                    onChangeInput={(count) => setAdditionalItemCount(p.customPart, count)}
                                    description={p.customPart.note}
                                    onClickTrash={() => removeAdditionalItem(p.customPart)}
                                />
                            )
                        })}
                    </div>
                )}
                {contextCustomServices.length > 0 && (
                    <div className="cart-wrapper">
                        <div className="title">CUSTOM SERVICES</div>
                        {contextCustomServices.map((p, i) => {
                            return (
                                <CartItem
                                    key={'part' + i.toString()}
                                    name={p.customService.name}
                                    price={p.customService.price || '-'}
                                    count={p.count}
                                    onClickPlus={() => addCustomService(p.customService)}
                                    onClickMinus={() => removeCustomService(p.customService)}
                                    onChangeInput={(count) => setAdditionalServiceItemCount(p.customService, count)}
                                    description={p.customService.note}
                                    onClickTrash={() => removeAdditionalServiceItem(p.customService)}
                                />
                            )
                        })}
                    </div>
                )}
            </div>
            {(purchaseWithoutCustomer || cartInformation.purchaseForCustomerId) && (
                <div className="cart-footer">
                    <AdditionalForm addAdditionalItem={addAdditionalItem} addAdditionalServiceItem={addAdditionalServiceItem} className="cart-add-more" />
                    <div className="total-price">
                        <div className="total-price-title">Total Price: </div>
                        <div className="total-price-value"> {getTotalPrice()}</div>
                    </div>
                </div>
            )}
            {(purchaseWithoutCustomer || cartInformation.purchaseForCustomerId) && (
                <div className="cart-footer">
                    <Link to="/checkoutPage">
                        <Button disabled={purchaseWithoutCustomer && !customerNameOfPurchaseWithoutCustomer} onClick={() => toggleQuickCartView(false)} className="button" type={ButtonType.Primary}>
                            Continue
                        </Button>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default QuickCart
