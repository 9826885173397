import './style.scss'
import Button from '../../../Button'
import { FC, useState } from 'react'
import { IAddMapping } from '../../ImageMapping.interface'
import SearchInput from '../../../SearchInput'
import { PartsModel } from '../../../../models/New/Equipment'

const AddMapping: FC<IAddMapping> = ({ addMappingRef, closeAddMapping, partLists, areas, addMappingData }) => {
    const [filteredPartLists, setFilteredPartLists] = useState<PartsModel[]>([])

    const addMappingSearchHandle = (data: PartsModel[]) => {
        setFilteredPartLists(data)
    }

    return (
        <div className="add-mapping" ref={addMappingRef}>
            <div className="header">
                <SearchInput className="add-mapping-search" data={partLists} searchKeys={['name', 'partNumber']} handleSearch={addMappingSearchHandle} />
                <Button className="close-add-mapping" onClick={closeAddMapping}>
                    X
                </Button>
            </div>
            <div className="body">
                <div className="divider" />
                <div className="part-list">
                    {filteredPartLists
                        .filter((partList) => !areas.some((area) => area.partNumber === partList.partNumber && area.name === partList.name))
                        .map((filteredPartList, index: number) => (
                            <div className="part" onClick={() => addMappingData(filteredPartList)} key={index}>
                                <span>{filteredPartList.name}</span>
                                <span className="part__part-number">{filteredPartList.partNumber}</span>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default AddMapping
