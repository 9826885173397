import React from 'react'
import { NavLink } from 'react-router-dom'
import { ActivityModel } from '../../../../../models/Ui/Activity'
import ActivityCart from './ActivityCart'
import './Tasks.scss'

interface Props {
    data: ActivityModel[]
}

function Activities(props: Props) {
    return (
        <>
            {props.data.length > 0 ? (
                <div className="tasks-container">
                    <div className="tasks-content">
                        <div className="tasks-header">
                            <div className="title">What’s next?</div>
                            <NavLink to="/activity">
                                <div className="go-to-all">View all activities</div>
                            </NavLink>
                        </div>
                        <div className="tasks-body">
                            {props.data.map((item) => {
                                return (
                                    <ActivityCart
                                        title={item.title}
                                        assignee={item?.assignee?.firstName}
                                        status={item.status}
                                        type={item?.type}
                                        dueDate={new Date(item.dueDate)}
                                        isRecurring={item.isRecurring}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}

export default Activities
