import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ProductActionTypes } from '../../actiontypes.enum'
import { DocumentBelongsTo, DocumentListModel } from '../../../../../models/New/Document'
import DocumentListTable from '../../Tables/DocumentListTable'
import { IconType } from '../../../../../Icon'
import { ConfirmationContext } from '../../../../../store/ConfirmationContext'
import { useParams } from 'react-router'
import CreateDocumentModal from '../../Modals/CreateDocument'
import { toast } from 'react-toastify'
import Loading from '../../../../../components/Loading'
import ContractService from '../../../../../services/UiService/Contract'
import { ContractOutputModel } from '../../../../../models/Ui/Contract'
import DocumentService from '../../../../../services/UiService/Document'
import DocumentUpdaterModal from '../../../../../components/DocumentUpdaterModal'

interface Props {
    visible: boolean
    modalVisible: boolean
    onModalOpen: () => void
    onModalClose: () => void
    onSearch: (value: DocumentListModel[]) => void
    filteredDocuments: DocumentListModel[]
    contract: ContractOutputModel
    setDocuments: (documents: DocumentListModel[]) => void
}

function DocumentsTab(props: React.PropsWithChildren<Props>) {
    const { id } = useParams()
    const { confirm } = useContext(ConfirmationContext)
    const panzoom = useRef<PanzoomObject | null>(null)
    const imageContainer = useRef<HTMLDivElement>(null)
    const [searchKey, setSearchKey] = useState<string>('')
    const [documents] = useState<DocumentListModel[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [editModalVisible, setEditModalVisible] = useState({
        visible: false,
        document: {} as DocumentListModel,
        note: '',
    })

    const getDocuments = async () => {
        try {
            onLoadingCallback()
            const documentsResponse = await ContractService.getDocuments(id!)
            props.setDocuments(documentsResponse)
        } catch (error: any) {
            onErrorCallback(error)
        } finally {
            onCompleteCallback()
        }
    }

    useEffect(() => {
        if (imageContainer.current !== null) {
            panzoom.current = Panzoom(imageContainer.current, { startScale: '1' })
        } /* else not needed */
    }, [documents])

    useEffect(() => {
        if (props.visible) getDocuments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible])

    const onLoadingCallback = useCallback(() => setLoading(true), [])
    const onCompleteCallback = useCallback(() => setLoading(false), [])
    const onErrorCallback = useCallback((error: any) => toast.error(error), [])

    const onSuccessCallback = useCallback(
        async (action: ProductActionTypes) => {
            switch (action) {
                case ProductActionTypes.UPDATE_DOCUMENT:
                    await getDocuments()
                    // props.onSearch(data)
                    break
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [documents]
    )

    const onClickEdit = (document: DocumentListModel) => {
        let note = ''

        if (document.belongsTo !== DocumentBelongsTo.Contract) {
            note = `The document is linked to ${document.contracts?.length ?? ''} other records. Updating it will affect these records as well. This cannot be undone.`
        }

        setEditModalVisible({
            visible: true,
            document,
            note,
        })
    }

    const onClickDelete = (document: DocumentListModel) => {
        let contentNode = <Fragment />
        if (props.contract.isVisibleToCustomer) {
            contentNode = (
                <div className="modal-line">
                    <span className="warning-text">
                        If you delete this document, your customer will no longer be able to see it.
                        <br />
                        <br />
                        This action cannot be undone. Are you sure you want to proceed?
                    </span>
                </div>
            )
        } else {
            contentNode = (
                <div className="modal-line">
                    <span className="warning-text">This action cannot be undone. Are you sure you want to proceed?</span>
                </div>
            )
        }

        confirm({
            modalClassName: 'delete-document-modal',
            modalTitle: 'Delete document?',
            primaryButtonText: 'No',
            secondaryButtonText: 'Yes',
            buttonOrder: 'secondaryPrimary',
            doesPrimaryButtonSubmit: false,
            func: async (successCallback, errorCallback) => {
                try {
                    onLoadingCallback()
                    await DocumentService.deleteDocument({ documentId: document.id })
                    panzoom.current = null
                    onSuccessCallback(ProductActionTypes.UPDATE_DOCUMENT)
                    successCallback()
                } catch (error: any) {
                    onErrorCallback(error)
                    errorCallback(error)
                } finally {
                    onCompleteCallback()
                }
            },
            contentNode,
        })
    }

    const onClickChevronRight = (document: DocumentListModel) => {
        window.open(document.url, '_blank')
    }

    const onClickUnlink = (document: DocumentListModel) => {
        let contentNode = <Fragment />
        if (props.contract.isVisibleToCustomer) {
            contentNode = (
                <div className="modal-line">
                    <span className="warning-text">
                        If you unlink this document, your customer will no longer be able to see it.
                        <br />
                        <br />
                        This action cannot be undone. Are you sure you want to proceed?
                    </span>
                </div>
            )
        } else {
            contentNode = (
                <div className="modal-line">
                    <span className="warning-text">This action cannot be undone. Are you sure you want to proceed?</span>
                </div>
            )
        }

        confirm({
            modalClassName: 'unlink-document-modal',
            modalTitle: 'Unlink document?',
            primaryButtonText: 'No',
            secondaryButtonText: 'Yes',
            buttonOrder: 'secondaryPrimary',
            doesPrimaryButtonSubmit: false,
            func: async (successCallback, errorCallback) => {
                try {
                    onLoadingCallback()
                    await ContractService.unlinkDocument(id!, document.id)
                    panzoom.current = null
                    onSuccessCallback(ProductActionTypes.UPDATE_DOCUMENT)
                    successCallback()
                } catch (error: any) {
                    onErrorCallback(error)
                    errorCallback(error)
                } finally {
                    onCompleteCallback()
                }
            },
            contentNode,
        })
    }

    const buttonsRenderer = (document: DocumentListModel) => {
        let output: {
            icon: IconType
            onClick: () => Promise<void>
            tooltip?: string
        }[] = [
            {
                icon: IconType.Pen,
                onClick: async () => onClickEdit(document),
                tooltip: 'Edit',
            },
        ]

        if (document.belongsTo !== DocumentBelongsTo.Contract) {
            output = [
                ...output,
                {
                    icon: IconType.Unlink,
                    onClick: async () => onClickUnlink(document),
                    tooltip: 'Unlink Document',
                },
            ]
        } else {
            output = [
                ...output,
                {
                    icon: IconType.Trash,
                    onClick: async () => onClickDelete(document),
                    tooltip: 'Delete',
                },
            ]
        }

        output = [
            ...output,
            {
                icon: IconType.ChevronRight,
                onClick: async () => onClickChevronRight(document),
                tooltip: 'View Details',
            },
        ]

        return output
    }

    return (
        <>
            {loading && <Loading />}
            <div style={{ display: props.visible ? 'block' : 'none' }}>
                <DocumentListTable
                    setSearch={(e) => {
                        if (searchKey === e) {
                            setSearchKey('')
                        } else {
                            setSearchKey(e)
                        }
                    }}
                    data={props.filteredDocuments.map((document) => {
                        return {
                            name: document.name,
                            fileType: document.type,
                            onClick: () => {
                                window.open(document.url)
                            },
                            buttons: buttonsRenderer(document),
                        }
                    })}
                />
            </div>
            {props.modalVisible && (
                <CreateDocumentModal
                    contract={props.contract}
                    visible={props.modalVisible}
                    documents={props.filteredDocuments}
                    closeModal={props.onModalClose}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompleteCallback}
                    onSuccess={onSuccessCallback}
                    onError={onErrorCallback}
                />
            )}
            {editModalVisible && (
                <DocumentUpdaterModal
                    visible={editModalVisible.visible}
                    primaryButtonText="Save"
                    closeModal={() =>
                        setEditModalVisible({
                            visible: false,
                            document: {} as DocumentListModel,
                            note: '',
                        })
                    }
                    title="Edit Document Details"
                    secondaryButtonText="Cancel"
                    note={editModalVisible.note}
                    document={editModalVisible.document}
                    buttonOrder="secondaryPrimary"
                    onLoading={onLoadingCallback}
                    onCompleted={onCompleteCallback}
                    onSuccess={() => onSuccessCallback(ProductActionTypes.UPDATE_DOCUMENT)}
                    onError={onErrorCallback}
                />
            )}
        </>
    )
}

export default DocumentsTab
