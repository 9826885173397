import { ReactNode, useState } from 'react'
import { Color } from '../../models/enums'
import ContentBox from '../ContentBox'
import Button from '../Button'
import Icons, { IconType } from '../../Icon'
import PageWrapper from '../../pages/PageWrapper'
import './NoData.scss'
import { IDropdownData } from './interface'
import { BreadcrumbModel } from './../Breadcrumb/index'
interface NoDataProp {
    pageTitle: string
    title: string
    secondTitle?: string
    url?: string
    hasIsDropdown?: boolean
    dropdownData?: IDropdownData[]
    breadcrumb?: BreadcrumbModel[]
    tabsMenu?: ReactNode
    customizedButton?: ReactNode
    disabledWrapper?: boolean
    handleClick?: () => void
}
function NoData(props: NoDataProp) {
    const [dropdownIsOpen, setDropdownStatus] = useState<boolean>(false)
    const toggleDropdown = () => setDropdownStatus(!dropdownIsOpen)
    return !props.disabledWrapper ? (
        <PageWrapper breadcrumb={props.breadcrumb}>
            <ContentBox title={props.pageTitle}>
                {props.tabsMenu && <div className="tab-container">{props.tabsMenu}</div>}
                <div className="no-data">
                    <span className="no-data__title">{props.title}</span>
                    {props.customizedButton ?? (
                        <Button className="no-data__button" onClick={props.hasIsDropdown ? toggleDropdown : props.handleClick}>
                            <Icons type={IconType.BoldPlus} color={Color.blue900} />
                            <span>Add new</span>
                            {props.hasIsDropdown && <Icons type={IconType.ChevronDown} color={Color.blue900} />}
                        </Button>
                    )}
                    {dropdownIsOpen && props.dropdownData && (
                        <div className="no-data__dropdown">
                            {props.dropdownData.map((item: IDropdownData, index: number) => (
                                <div className="no-data__dropdown__wrapper">
                                    <span className="no-data__dropdown__wrapper__item" key={index} onClick={item.clickAction}>
                                        {item.title}
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                    <span>{props.secondTitle}</span>
                </div>
            </ContentBox>
        </PageWrapper>
    ) : (
        <div className="no-data">
            <span className="no-data__title">{props.title}</span>
            {props.customizedButton ?? (
                <Button className="no-data__button" onClick={props.hasIsDropdown ? toggleDropdown : props.handleClick}>
                    <Icons type={IconType.BoldPlus} color={Color.blue900} />
                    <span>Add new</span>
                    {props.hasIsDropdown && <Icons type={IconType.ChevronDown} color={Color.blue900} />}
                </Button>
            )}
            {dropdownIsOpen && props.dropdownData && (
                <div className="no-data__dropdown">
                    {props.dropdownData.map((item: IDropdownData, index: number) => (
                        <div className="no-data__dropdown__wrapper">
                            <span className="no-data__dropdown__wrapper__item" key={index} onClick={item.clickAction}>
                                {item.title}
                            </span>
                        </div>
                    ))}
                </div>
            )}
            <span>{props.secondTitle}</span>
        </div>
    )
}

export default NoData
