import React from 'react'
import './Footer.scss'
import { CompanyManufacturerModel } from './../../../../../models/New/Customer'
import { Avatar } from '@mui/material'
import { STATIC_LOGO } from '../../const'

interface Props {
    manufacturerInfo?: CompanyManufacturerModel
}

function Footer(props: Props) {
    return (
        <div className="footer-container">
            <div className="top">
                <div className="manufacturer-info">
                    <Avatar src={props.manufacturerInfo?.logoUrl ?? STATIC_LOGO} alt="Dashboard" />
                    <span className="name">{props?.manufacturerInfo?.name}</span>
                </div>
                <div className="about-container">
                    <div className="about-item">
                        <span className="title">Get to know us</span>
                        <span className="go-to">Vendor Details</span>
                        <span className="go-to">Contact Us</span>
                    </div>
                    <div className="about-item">
                        <span className="title">Monittor</span>
                        <span className="go-to">What is Monittor?</span>
                        <span className="go-to">Sell on Monittor</span>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <span className="powered-by">POWERED BY MONITTOR</span>
            </div>
        </div>
    )
}

export default Footer
