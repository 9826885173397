import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import { EquipmentModel } from '../../../models/Ui/Equipment'
import { useNavigate } from 'react-router-dom'
import Button from '../../../components/Button'
import './EquipmentList.scss'
import SearchInput from '../../../components/SearchInput'
import CreateEquipmentModal from './CreateEquipmentModal'
import Icons, { IconType } from '../../../Icon'
import { arraySort } from '../../../utils/helpers'
import dayjs from 'dayjs'
import CustomEquipmentModel from '../../../models/Ui/CustomEquipment'
import EquipmentListTable, { TableActionsType } from './EquipmentListTable'
import CustomEquipmentListTable from './CustomEquipmentListTable'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import EasyIcon from '../../../components/EasyIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import TokenService from '../../../services/NewServices/TokenService'
import EquipmentService from '../../../services/UiService/Equipment'
import { CreateEquipmentModel, CustomEquipmentListModel, EquipmentListModel } from '../../../models/New/Equipment'
import ListCard from '../../../components/ListCard'
import EquipmentListItem from './EquipmentListItem'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import Tab from '../../../components/Tab'
import NoData from '../../../components/NoData'
import NoDataInfo from '../../../components/NoDataInfo'
import PopoverItem from '../../../components/PopoverItem'
import Switch from '@mui/material/Switch'

const tabItems: TabItem[] = [
    {
        id: Tabs.EquipmentTemplates,
        name: 'Equipment Templates',
    },
    {
        id: Tabs.CustomInstallations,
        name: 'Custom Installations',
    },
]

function EquipmentListForManufacturer() {
    const { confirm } = useContext(ConfirmationContext)
    const [equipments, setEquipments] = useState<EquipmentListModel[]>([])
    const [customInstallations, setCustomInstallations] = useState<CustomEquipmentListModel[]>([])
    const [filteredData, setFilteredData] = useState([] as EquipmentListModel[] | CustomEquipmentListModel[] | any[])
    const [loading, setLoading] = useState<boolean>(false)
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [selectedView, setSelectedView] = useState<boolean>(false)
    const navigate = useNavigate()
    const [searchKey, setSearchKey] = useState<string | null>('')
    const [searchInputResetReferance, setSearchInputResetReferance] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(Number(localStorage?.getItem('equipmentActiveTab') ?? tabItems[0].id))

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Equipment',
        },
    ]
    useEffect(() => {
        async function get() {
            if (activeTab === Tabs.EquipmentTemplates) {
                await getEquipments()
            } else if (activeTab === Tabs.CustomInstallations) {
                await getCustomEquipments()
            }
        }

        get()
    }, [activeTab])

    async function getEquipments() {
        setLoading(true)
        try {
            const equipments = await EquipmentService.getTemplatesForManufacturer({ page: 0, size: 9999 })
            setEquipments(equipments.content)
            setFilteredData(equipments.content)
        } catch (e: any) {
            toast.error(e)
        } finally {
            setLoading(false)
        }
    }

    async function getCustomEquipments() {
        setLoading(true)
        try {
            const response = await EquipmentService.getCustomsForManufacturer({ page: 0, size: 9999 })
            setCustomInstallations(response.content)
            setFilteredData(response.content)
        } catch (e: any) {
            toast.error(e)
        } finally {
            setLoading(false)
        }
    }

    async function createEquipmentHandler(data: CreateEquipmentModel) {
        try {
            setLoading(true)
            const newEquipment = await EquipmentService.createEquipment(data)
            setEquipments([...equipments, newEquipment])
            navigate('/equipment/' + newEquipment.id)
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setModalVisible(false)
            setLoading(false)
        }
    }

    const openModal = () => {
        setModalVisible(true)
        const auth = TokenService.getAuth()
        // @ts-ignore
        window.dataLayer.push({
            event: 'new equipment modal open',
            user: `${auth.company.id} ${auth.company.type} ${auth.company.name} ${auth.company.email} `,
            date: dayjs().format('MM/DD/YYYY HH:mm:ss'),
        })
    }

    const handleSearchedData = (data: CustomEquipmentModel[] | EquipmentModel[]) => {
        setFilteredData(data)
    }

    const handleDeleteEquipment = (id: string) => {
        confirm({
            modalClassName: 'delete-equipment-modal',
            modalTitle: 'Delete equipment?',
            primaryButtonText: 'Delete',
            func: async (successCallback, errCallback) => {
                try {
                    setLoading(true)
                    await EquipmentService.delete(id)
                    setFilteredData((prevState) => [...prevState.filter((child) => child.id !== id)])
                    successCallback()
                } catch (error: any) {
                    errCallback(error)
                } finally {
                    setLoading(false)
                }
            },
        })
    }
    const updateEquipmentVisible = async (equipmentId: string, visible: boolean) => {
        try {
            setLoading(true)
            await EquipmentService.updateCustomEquipment(equipmentId, { isVisible: visible })
            const newEquipment = [...customInstallations]
            const updatedIndex = customInstallations.findIndex((p) => p.id === equipmentId)
            newEquipment[updatedIndex].isVisible = visible
            setEquipments(newEquipment)
            toast.success('Updated successfully!')
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    const setTableActions = (item: any) => {
        if (activeTab === Tabs.CustomInstallations) {
            return [
                {
                    actionType: TableActionsType.Visibility,
                    element: (
                        <PopoverItem popoverContent={!item.isVisible ? 'Hidden from customer' : 'Visible to customer'}>
                            <Switch
                                checked={item.isVisible}
                                onChange={() => {
                                    updateEquipmentVisible(item.id, !item.isVisible)
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </PopoverItem>
                    ),
                },
                {
                    actionType: TableActionsType.Delete,
                    element: (
                        <PopoverItem popoverContent="Delete">
                            <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteEquipment(item.id)} color="var(--blue-900)" />
                        </PopoverItem>
                    ),
                },
            ]
        } else {
            return [
                {
                    actionType: TableActionsType.Delete,
                    element: (
                        <PopoverItem popoverContent="Delete">
                            <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteEquipment(item.id)} color="var(--blue-900)" />
                        </PopoverItem>
                    ),
                },
            ]
        }
    }

    const onTabClick = (id: number) => {
        setActiveTab(id)
        setSearchInputResetReferance(!searchInputResetReferance)
        localStorage.setItem('equipmentActiveTab', id.toString())
    }

    return (
        <>
            {equipments.length === 0 && activeTab === Tabs.EquipmentTemplates ? (
                <>
                    {loading && <Loading />}
                    <NoData
                        handleClick={() => setModalVisible(true)}
                        breadcrumb={breadcrumb}
                        pageTitle="Equipment"
                        title="You currently do not have any equipment templates on Monittor.  Start by adding one now!"
                    />
                </>
            ) : (
                <PageWrapper breadcrumb={breadcrumb}>
                    {loading && <Loading />}
                    <ContentBox
                        title="Equipment"
                        headerIcon={<EasyIcon icon={IconType.Equipment} />}
                        className="equipment-list-content-box"
                        headerRight={
                            <div className="action-wrapper">
                                <SearchInput
                                    resetReferance={searchInputResetReferance}
                                    data={activeTab === Tabs.EquipmentTemplates ? equipments : activeTab === Tabs.CustomInstallations ? customInstallations : []}
                                    handleSearch={handleSearchedData}
                                    setDefaultSearch={searchKey}
                                    searchKeys={
                                        activeTab === Tabs.EquipmentTemplates
                                            ? ['partNumber', 'name', 'tagName', 'manufacturerName']
                                            : [
                                                  'partNumber',
                                                  'name',
                                                  'serialNo',
                                                  'tagName',
                                                  'customerName',
                                                  'manufacturerName',
                                                  'installationAddress.city',
                                                  'installationAddress.country',
                                                  'installationAddress.state',
                                              ]
                                    }
                                />
                                <PopoverItem popoverContent={selectedView ? 'List View' : 'Card View'}>
                                    <Button disabled={activeTab !== Tabs.EquipmentTemplates} className="toggle-button" onClick={() => setSelectedView(!selectedView)}>
                                        {!selectedView ? <Icons type={IconType.Menus} /> : <Icons type={IconType.CardView} />}
                                    </Button>
                                </PopoverItem>
                                <PopoverItem popoverContent="Add new">
                                    <Button className="create-equipment-button" onClick={openModal}>
                                        <Icons type={IconType.PlusCircle} />
                                    </Button>
                                </PopoverItem>
                            </div>
                        }
                    >
                        <div className="tab-container">
                            <Tab activeTab={activeTab} tabs={tabItems} onTabClick={onTabClick} />
                        </div>
                        {activeTab === Tabs.EquipmentTemplates ? (
                            !loading && filteredData && filteredData.length > 0 ? (
                                selectedView ? (
                                    <ListCard
                                        data={arraySort(filteredData as EquipmentListModel[], 'name').map((equipment, index) => {
                                            return {
                                                isClickable: true,
                                                onClick: () => navigate('/equipment/' + equipment.id),
                                                children: equipment && <EquipmentListItem key={index} {...equipment} />,
                                            }
                                        })}
                                    />
                                ) : (
                                    <EquipmentListTable
                                        navigate={'/equipment/'}
                                        setSearch={(e) => {
                                            if (searchKey === e) {
                                                setSearchKey('')
                                            } else {
                                                setSearchKey(e)
                                            }
                                        }}
                                        data={filteredData.map((p) => {
                                            return {
                                                name: p.name,
                                                vendor: p.manufacturerName,
                                                // installs: p.installs,
                                                partNo: p.partNumber,
                                                tags: p.tagName,
                                                id: p.id,
                                                actions: setTableActions(p),
                                            }
                                        })}
                                    />
                                )
                            ) : (
                                <NoDataInfo />
                            )
                        ) : activeTab === Tabs.CustomInstallations ? (
                            <CustomEquipmentListTable
                                setSearch={(e) => {
                                    if (searchKey === e) {
                                        setSearchKey('')
                                    } else {
                                        setSearchKey(e)
                                    }
                                }}
                                data={filteredData.map((p) => {
                                    return {
                                        name: p.name,
                                        partNo: p.partNumber,
                                        vendor: p.manufacturerName,
                                        address: p.installationAddress,
                                        serialNumber: p.serialNo || '',
                                        customerName: p.customerName,
                                        // warrantly: p?.standardWarranty?.duration +" "+ p.standardWarranty?.unit,
                                        tags: p.tagName,
                                        id: p.id,
                                        customerId: p.customerId,
                                        actions: setTableActions(p),
                                    }
                                })}
                            />
                        ) : (
                            <></>
                        )}
                    </ContentBox>
                </PageWrapper>
            )}
            <CreateEquipmentModal visible={modalVisible} closeModal={() => setModalVisible(false)} createEquipment={async (equipment) => createEquipmentHandler(equipment)} />
        </>
    )
}

export default EquipmentListForManufacturer
