import { MouseEventHandler } from 'react'

export enum EMappingCircleSize {
    SMALL = 0.3,
    MEDIUM = 0.6,
    LARGE = 1,
}

export enum EMappingCircleColor {
    RED = '#D84315',
    GREEN = '#3BBB6F',
    BLUE = '#5BA5FF',
    WHITE = '#FFFFFF',
    BLACK = '#212121',
    NAVY = '#354A63',
}

export interface BaseMappingCircle {
    active?: boolean
    background: EMappingCircleColor
    color: EMappingCircleColor
    onClick?: MouseEventHandler
}

export interface MappingCircleColor extends BaseMappingCircle {
    stroke?: EMappingCircleColor
}

export interface MappingCircleSize extends BaseMappingCircle {
    size?: EMappingCircleSize
}
