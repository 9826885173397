import React, { CSSProperties, FC, MouseEventHandler } from 'react'
import { Size } from '../../models/enums'
import './Button.scss'

export enum ButtonType {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Quaternary = 'quaternary',
}

interface Props {
    className?: string
    size?: Size
    type?: ButtonType
    disabled?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    title?: string
    style?: CSSProperties
}

const Button: FC<Props> = ({ children, className, disabled, onClick, size, title = '', type, style = {} }) => {
    const buttonType = type && type !== ButtonType.Primary ? ' ' + type : ''

    let defaultClassName: string = 'button button-' + (size ? size : Size.md) + buttonType
    return (
        <button style={style} title={title} disabled={disabled} onClick={onClick} type="button" className={className ? className + ' ' + defaultClassName : defaultClassName}>
            {children}
        </button>
    )
}

export default Button
