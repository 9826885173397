import Zoom from 'react-medium-image-zoom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import './ProductImageBox.scss'
import { FileUploader } from 'react-drag-drop-files'
import { ProductImageModel } from '../../../../models/New/Product'
import Icons, { IconType } from '../../../../Icon'
import { useEffect, useRef } from 'react'

interface Props {
    title: string
    images: ProductImageModel[]
    updateProductImageHandler: (imageId: string, isFeatured: boolean) => void
    deleteProductImageHandler: (imageId: string) => void
    createProductImageHandler: (file: FileList) => void
}

function ProductImageBox(props: Props) {
    const zoomWrapper = document.getElementById('zoom-wrapper')
    let carouselRef = useRef<Carousel>(null)

    const deleteImageAction = async (imageId: string) => {
        await props.deleteProductImageHandler(imageId)
    }

    useEffect(() => {
        carouselRef?.current?.selectItem({ selectedItem: 0, swiping: true })
    }, [props.images])
    return (
        <div className="product-image-box">
            <div className="header">
                <span className="title">{props.title}</span>
                <div key={'add-image'} className="add-photo">
                    <FileUploader
                        multiple={true}
                        className="file-input"
                        handleChange={(fileList: FileList) => props.createProductImageHandler(fileList)}
                        name="file"
                        children={<span className="add-photo">+add photo</span>}
                        types={['png', 'jpg', 'jpeg']}
                    />
                </div>
            </div>
            <div className="content">
                {props.images.length > 0 ? (
                    <Carousel showArrows={false} showStatus={false} ref={carouselRef}>
                        {props.images.map((image, index) => {
                            return (
                                <div key={index} className="image-wrapper">
                                    <Zoom portalEl={zoomWrapper!}>
                                        <img className="image-item zoom-image" src={image.imageUrl} alt={props.title} />
                                    </Zoom>
                                    <Icons className="icon image-remove-icon" type={IconType.Close} onClick={() => deleteImageAction(image.imageUrl)} />
                                    {image.isFeatured ? (
                                        <Icons className="icon image-star-icon" type={IconType.Star} onClick={() => props.updateProductImageHandler(image.imageUrl, false)} />
                                    ) : (
                                        <Icons className="icon image-star-border-icon" type={IconType.StarBorder} onClick={() => props.updateProductImageHandler(image.imageUrl, true)} />
                                    )}
                                </div>
                            )
                        })}
                    </Carousel>
                ) : (
                    <FileUploader
                        multiple={true}
                        className="file-input"
                        handleChange={(fileList: FileList) => props.createProductImageHandler(fileList)}
                        name="file"
                        children={
                            <div className="no-image">
                                <Icons type={IconType.YellowAddCircle} />
                            </div>
                        }
                        types={['png', 'jpg', 'jpeg']}
                    />
                )}
            </div>
        </div>
    )
}

export default ProductImageBox
