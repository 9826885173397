module.exports = {
    "apiKey": "AIzaSyAB4sryXFTeG47U8eNiZzzf2MXigr67lvc",
    "authDomain": "monittor-app.firebaseapp.com",
    "projectId": "monittor-app",
    "storageBucket": "monittor-app.appspot.com",
    "messagingSenderId": "373379258575",
    "appId": "1:373379258575:web:3519079ddd91ba94b0bfba",
    "measurementId": "G-TM28SRHTMN",
    "hosting": {
      "public": "public",
      "ignore": [
        "firebase.json",
        "**/.*",
        "**/node_modules/**"
      ],
      "rewrites": [{
        "source": "**",
        "destination": "/index.html"
      }]
    },
    "firestore": {
      "rules": "firestore.rules",
      "indexes": "firestore.indexes.json"
    }
  }
