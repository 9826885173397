import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { OrderModel, UpdateOrderDetailModel, UpdateOrderModel, UpdateOrderPartialModel } from '../../models/New/Order'
import { CreateOrderDto, CreateOrderOrQuoteInput } from '../../models/Ui/Order'

class OrderService {
    async get(id: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetOrder + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Order not found!')
        }
    }
    async getOrderCurrent(id: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.get(`order/${id}/invoice`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Order not found!')
        }
    }
    async delete(id: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.GetOrder + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Order not found!')
        }
    }
    async create(data: CreateOrderOrQuoteInput): Promise<OrderModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateOrder, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async createPurchaseRequest(data: CreateOrderOrQuoteInput): Promise<OrderModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreatePurchaseRequest, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async createRFQ(data: CreateOrderOrQuoteInput): Promise<OrderModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateRFQ, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async createQuote(data: CreateOrderOrQuoteInput): Promise<OrderModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateQuote, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async updateRFQToQuote(data: CreateOrderOrQuoteInput, orderId: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.put(`/order/rfq/${orderId}/to-quote`, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async sendFollowUp(message: string, orderId: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.post(`/order/rfq/${orderId}/follow-up`, { message: message }, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async updateSavedToQuote(data: CreateOrderOrQuoteInput, orderId: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.put(`/order/saved/${orderId}/to-quote`, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async updateSavedToPurchaseRequest(data: CreateOrderOrQuoteInput, orderId: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.put(`/order/saved/${orderId}/to-purchase-request`, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async save(data: CreateOrderDto): Promise<OrderModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.SaveOrder, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async updateSave(id: string, data: CreateOrderDto): Promise<OrderModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateSavedOrder + id, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }
    async getCustomerOrderForManufacturer(id: string): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetCustomerOrdersForManufacturer + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async getCustomerForCustomerOrders(): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetCustomerOrdersForCustomer, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async getPurchaseRequests(): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetPurchaseRequests, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async getManufacturerOrders(): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetManufacturerOrders, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async getSavedCarts(): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetSavedCarts, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async getVendorQuotes(): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetVendorQuotes, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async getCustomerQuotes(): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetCustomerQuotes, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async getReOrder(id: string): Promise<OrderModel> {
        try {
            const response = await MonitorApi.get(`/order/${id}/re-order`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }

    async getEquipmentOrders(id: string): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetEquipmentOrders + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }
    async update(id: string, data: UpdateOrderModel): Promise<OrderModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateOrder + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update order error')
        }
    }
    async updatePartial(id: string, data: UpdateOrderPartialModel): Promise<OrderModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateOrder + id + '/partial', data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update order error')
        }
    }
    async updateOrderDetail(id: string, data: UpdateOrderDetailModel): Promise<OrderModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateOrderDetail + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update order error')
        }
    }

    async getOrderByProductId(productId: string): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(`order/product/${productId}`, {})
            return response as any
        } catch (error: any) {
            throw new Error('Get order by product id error')
        }
    }

    async getNumberOfOrders(): Promise<number> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetNumberOfOrders, {})
            return response as any
        } catch (error: any) {
            throw new Error('Orders not found!')
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OrderService()
