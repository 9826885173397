import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import React, { useContext, useMemo } from 'react'
import { useNavigate } from 'react-router'
import Icon, { IconType } from '../../../../../../Icon'
import NotificationModel from '../../../../../../models/Ui/Notification'
import NotificationService from '../../../../../../services/UiService/Notification'
import { SocketContext } from '../../../../../../store/SocketContext'
import './Notification.scss'

interface Props {
    notifications: NotificationModel[]
}

function Notification(props: Props) {
    const { setNotifications, setNotificationsUpdated } = useContext(SocketContext)
    const navigate = useNavigate()

    const notificationService = useMemo(() => {
        return new NotificationService()
    }, [])

    const getItemClassName = (notification: NotificationModel) => {
        let className = 'notification-item'

        if (!notification.isRead) {
            className += ' new'
        }

        if (notification.path) {
            className += ' pointer'
        }

        return className
    }

    const onClickGoToButton = async (notification: NotificationModel) => {
        if (notification.path) {
            markAsReadNotification(notification, true)
            navigate(notification.path!)
        }
    }

    const markAsReadNotification = async (notification: NotificationModel, isRead: boolean) => {
        try {
            await notificationService.markAsRead(notification.id, isRead)
            const newNotifications = [...props.notifications]
            const index = props.notifications.findIndex((p) => p.id === notification.id)
            newNotifications[index].isRead = isRead
            setNotifications(newNotifications)
            setNotificationsUpdated((prevState) => !prevState)
        } catch (error) {
            console.log(error)
        }
    }
    const newestCount = props.notifications.filter((p) => !p.isRead).length

    return (
        <div className="notification-container">
            <div className="header">
                <div className="cart-info">
                    <span className="title">Notifications</span>
                    <span className="notification-count">{newestCount}</span>
                </div>
                <span className="go-to-all">View all notifications</span>
            </div>
            <div className="body">
                {props.notifications.length > 0 ? (
                    props.notifications.map((n, i) => {
                        return (
                            <div key={i} className={getItemClassName(n)}>
                                <div className="notificationItemMiddleSide">
                                    <div className={`notification-title ${n.isRead && 'readTitle'}`}>
                                        <span style={{ cursor: n.path ? 'pointer' : 'default' }} onClick={() => onClickGoToButton(n)}>
                                            {n.title}
                                        </span>
                                    </div>
                                    {n.description && <div className="notification-description">{n.description}</div>}
                                </div>
                                <div className="notificationItemRightSide">
                                    <div className="notification-button-wrapper">
                                        {!n.isRead && (
                                            <Tooltip title="Mark as read">
                                                <div className="notification-button" onClick={() => markAsReadNotification(n, true)}>
                                                    <Icon type={IconType.UnReadNotification} />
                                                </div>
                                            </Tooltip>
                                        )}
                                        {n.isRead && (
                                            <Tooltip title="Mark as unread">
                                                <div className="notification-button" onClick={() => markAsReadNotification(n, false)}>
                                                    <Icon type={IconType.ReadNotification} />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className="notification-info-message">
                        <FontAwesomeIcon icon={faInfo} /> <span>No props.notification yet</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Notification
