import React, { ChangeEventHandler, ReactElement, KeyboardEvent, ReactNode } from 'react'
import { Size } from '../../models/enums'
import './Input.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Icons, { IconType } from '../../Icon'
import { isIconDefinition } from '../../utils/helpers'
import PopoverItem from '../PopoverItem'

interface Props {
    defaultValue?: string | number
    value?: string | number
    className?: string
    wrapperClass?: string
    size?: Size
    min?: number
    max?: number
    placeholder?: string
    type?: string
    required?: boolean
    disabled?: boolean
    outlinePlaceholder?: boolean
    startIcon?: IconDefinition | IconType
    startIconColor?: string
    endIcon?: IconDefinition | IconType
    endIconTooltipText?: string
    endItem?: ReactNode
    endIconColor?: string
    customIcon?: ReactElement
    lineCount?: number
    id?: string
    onWheel?: ChangeEventHandler<HTMLInputElement>
    onChange?: ChangeEventHandler<HTMLInputElement>
    onBlur?: ChangeEventHandler<HTMLInputElement>
    onFocus?: ChangeEventHandler<HTMLInputElement>
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onClickEndIcon?: () => void
}

function Input({ endIconTooltipText = 'Clear selected', outlinePlaceholder = true, ...props }: React.PropsWithChildren<Props>) {
    let defaultClassName: string = `${props.lineCount ? 'textarea textarea-' : 'input input-'}` + (props.size ? props.size : Size.lg) + ` ${props.disabled && 'disabled-input'}`
    let hasIconClass: string = props.startIcon ? ' start-input-icon' : ''
    hasIconClass += props.endIcon ? ' end-input-icon' : ''

    return (
        <div className={(props.wrapperClass ? props.wrapperClass + hasIconClass + ' input-wrapper' : 'input-wrapper' + hasIconClass) + (props.className ? ' ' + props.className : '')}>
            {props.lineCount ? (
                <textarea
                    rows={props.lineCount}
                    disabled={props.disabled}
                    required={props.required}
                    value={props.value}
                    className={props.wrapperClass ? props.wrapperClass + ' ' + defaultClassName : defaultClassName}
                    // @ts-ignore
                    onChange={props.onChange}
                    onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
                    onWheel={props.onWheel as any}
                    onBlur={props.onBlur as any}
                    onFocus={props.onFocus as any}
                    placeholder={!outlinePlaceholder ? props.placeholder : ' '}
                    defaultValue={props.defaultValue}
                />
            ) : (
                <input
                    disabled={props.disabled}
                    required={props.required}
                    min={props.min}
                    max={props.max}
                    type={props.type ? props.type : 'text'}
                    value={props.value}
                    className={props.wrapperClass ? props.wrapperClass + ' ' + defaultClassName : defaultClassName}
                    onChange={props.onChange}
                    onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    placeholder={!outlinePlaceholder ? props.placeholder : ' '}
                    defaultValue={props.defaultValue}
                />
            )}

            {props.startIcon && (
                <span className="start-icon">
                    {isIconDefinition(props.startIcon) ? (
                        <FontAwesomeIcon icon={props.startIcon as IconDefinition} color={props.startIconColor} />
                    ) : (
                        <Icons type={props.startIcon as IconType} color={props.startIconColor} />
                    )}
                </span>
            )}
            {!props.startIcon && props.customIcon && <span className="start-icon">{props.customIcon}</span>}
            {outlinePlaceholder && (
                <span className="label">
                    {props.placeholder}
                    {props.required && <strong className="required"> *</strong>}
                </span>
            )}
            {props.endIcon && (
                <div className={`end-icon ${props.onClickEndIcon ? 'end-icon-button' : ''}`} onClick={props.onClickEndIcon}>
                    {isIconDefinition(props.endIcon) ? (
                        <PopoverItem popoverContent={endIconTooltipText}>
                            <FontAwesomeIcon icon={props.endIcon as IconDefinition} color={props.endIconColor} />
                        </PopoverItem>
                    ) : (
                        <PopoverItem popoverContent={endIconTooltipText}>
                            <Icons className="icon" type={props.endIcon as IconType} color={props.endIconColor} />
                        </PopoverItem>
                    )}
                </div>
            )}
            {props.endItem && <div className="end-item">{props.endItem}</div>}
        </div>
    )
}

export default Input
