import { Tooltip } from '@mui/material'
import { Fragment, FC, useState, useEffect } from 'react'
import Icons, { IconType } from '../../Icon'

interface Props {
    filename: string
    onChange: (filename: string) => void
}

const RenameFilename: FC<Props> = ({ filename, onChange }) => {
    const [renamedFilename, setRenamedFilename] = useState('')
    const [fileExtension, setFileExtension] = useState('')
    const [renameMode, setRenameMode] = useState(false)

    useEffect(() => {
        setRenamedFilename(filename?.substring(0, filename.lastIndexOf('.')) ?? '')
        setFileExtension(filename?.substring(filename.lastIndexOf('.') + 1, filename.length) ?? '')
    }, [filename])

    const onClickRightIcon = () => {
        if (renameMode) {
            onChange(`${renamedFilename}.${fileExtension}`)
            setRenameMode(false)
        } else {
            setRenameMode(true)
        }
    }

    const onCancel = () => {
        setRenamedFilename(filename?.substring(0, filename.lastIndexOf('.')) ?? '')
        setRenameMode(false)
    }

    return (
        <Fragment>
            <div className="left">
                {renameMode ? (
                    <Fragment>
                        <input style={{ border: 'none', outline: 'none' }} autoFocus type="text" value={renamedFilename} onChange={(e) => setRenamedFilename(e.target.value)} />
                        <span className="signika">{`.${fileExtension}`}</span>
                    </Fragment>
                ) : (
                    <span className="signika">{filename}</span>
                )}
            </div>
            <div style={{ display: 'flex', gap: '0.3rem' }}>
                <Tooltip title={renameMode ? 'Save' : 'Rename'}>
                    <div className="right" onClick={onClickRightIcon}>
                        {renameMode ? <Icons type={IconType.Check} /> : <Icons type={IconType.Pen} />}
                    </div>
                </Tooltip>
                {renameMode && (
                    <Tooltip title="Cancel">
                        <div className="right" onClick={onCancel}>
                            <Icons type={IconType.Close} color="gray" />
                        </div>
                    </Tooltip>
                )}
            </div>
        </Fragment>
    )
}

export default RenameFilename
