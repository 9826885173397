export interface CommonResponse {
    status: boolean
    message: string
    data?: {
        email?: string
    }
}

export interface PaginationOutputModel<T> {
    totalElements: number
    totalPage: number
    page: number
    size: number
    content: T[]
}

export enum EquipmentActionTypes {
    UPDATE_EQUIPMENT_TREE,
    CREATE_EQUIPMENT,
    UPDATE_EQUIPMENT,
    UPDATE,
    CREATE_EQUIPMENT_TAG,
    DELETE_EQUIPMENT_TAG,
    UPDATE_EQUIPMENT_IMG,
    UPDATE_EQUIPMENT_DOCUMENT,
    DELETE_EQUIPMENT,
    CREATE_ASSEMBLY,
    UPDATE_ASSEMBLY,
    UPDATE_ASSEMBLY_NOTE,
    DELETE_ASSEMBLY,
    LINK_DOCUMENTS,
    LINK_CUSTOMERS,
    CLONE_EQUIPMENT,
    UPDATE_CUSTOM_EQUIPMENT,
    UPDATE_MAPPING,
    UPDATE_PARTSLIST,
}

export enum CommonActionTypes {
    CREATE,
    UPDATE,
    DELETE,
}

export interface BaseOutputDto {
    message: string
    status: number
    messageDetail?: string
}

export interface BaseDto {
    creationDate: string
    isDeleted: boolean
}
