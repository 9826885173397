import { RefObject, useEffect, useRef, useState } from 'react'
import './LinkedDocuments.scss'
import { isValidEmail } from '../../../../../../../utils/helpers'
import SearchInput from '../../../../../../../components/SearchInput'
import Icons, { IconType } from '../../../../../../../Icon'
import PopoverItem from '../../../../../../../components/PopoverItem'
import Input from '../../../../../../../components/Input'
import { DocumentListModel } from '../../../../../../../models/New/Document'
import Checkbox from '../../../../../../../components/Checkbox'

interface IProps {
    documents: DocumentListModel[] | undefined
    selectedDocuments: DocumentListModel[]
    onChange: (documents: DocumentListModel[]) => void
}

function LinkedDocuments(props: IProps) {
    const [selectedDocuments, setSelectedDocuments] = useState<DocumentListModel[]>(props.selectedDocuments ?? [])
    const [documents, setDocuments] = useState<DocumentListModel[] | undefined>(undefined)
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentListModel[]>([])
    const [dropdownVisible, seDropdownVisible] = useState<boolean>(false)
    const [searchEmail, setSearchEmail] = useState<string>('')
    const [listVisible, setListVisible] = useState<boolean>(false)
    const popupBoxRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setDocuments(props.documents)
        if (props.documents) {
            setFilteredDocuments(props.documents)
        }
    }, [props.documents])

    useEffect(() => {
        document.addEventListener('mousedown', (e: MouseEvent) => {
            if (popupBoxRef.current && !popupBoxRef.current.contains(e.target as Node)) {
                seDropdownVisible(false)
            }
        })
    }, [])
    useEffect(() => {
        setDocuments(props.documents || [])
    }, [dropdownVisible, props.documents])

    const handleSubmit = async () => {
        props.onChange(selectedDocuments)
        seDropdownVisible(false)
    }

    const onHandleSearch = (value: DocumentListModel[]) => {
        setFilteredDocuments(value)
    }

    return (
        <div className="form linked-documents">
            <div className="form-line">
                <div ref={popupBoxRef} className="dropdown-container">
                    <div className="dropdown-input">
                        <div className="dropdown-selected">
                            {dropdownVisible ? (
                                <SearchInput
                                    onFocus={() => seDropdownVisible(true)}
                                    handleSearchTextValue={(value) => setSearchEmail(value)}
                                    placeHolder="Search or add"
                                    data={documents}
                                    handleSearch={onHandleSearch}
                                    searchKeys={['name']}
                                />
                            ) : (
                                <>
                                    <Input
                                        onFocus={() => seDropdownVisible(true)}
                                        placeholder="Search or add"
                                        value={!selectedDocuments || selectedDocuments.length === 0 ? '' : `${selectedDocuments?.length} Document${selectedDocuments.length > 1 ? 's' : ''} Selected`}
                                    />
                                    <Icons className="down-icon" type={IconType.ChevronDown} />
                                </>
                            )}
                        </div>
                        {dropdownVisible && !(filteredDocuments?.length === 0 && isValidEmail(searchEmail)) && (
                            <div onClick={handleSubmit} className="action-button">
                                <PopoverItem popoverContent="Done">
                                    <Icons type={IconType.Check} />
                                </PopoverItem>
                            </div>
                        )}
                    </div>

                    {dropdownVisible && (
                        <div className="dropdown-list-container">
                            <div className="action-container">
                                {filteredDocuments.length === 0 && (
                                    <div className="search-line">
                                        <span>Not found!</span>
                                    </div>
                                )}
                            </div>
                            <div className="contact-list">
                                {filteredDocuments?.map((item) => {
                                    return (
                                        <div className="contact">
                                            <Checkbox
                                                checked={!!selectedDocuments.find((document: DocumentListModel) => document === item)}
                                                onChange={() => {
                                                    if (!!selectedDocuments.find((document: DocumentListModel) => document === item)) {
                                                        setSelectedDocuments(selectedDocuments.filter((document) => document !== item))
                                                    } else {
                                                        setSelectedDocuments([...selectedDocuments, item])
                                                    }
                                                }}
                                            />
                                            <div className="contact-info">
                                                <span className="name">{item.name}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    {selectedDocuments.length > 0 && (
                        <div className="document-list-container">
                            <div onClick={() => setListVisible(!listVisible)} className="list-control">
                                <span className="title">{!listVisible ? 'View details' : 'Hide details'}</span>
                                <Icons type={IconType.ChevronDown} />
                            </div>
                            {listVisible && (
                                <div className="document-list">
                                    {selectedDocuments.map((document) => {
                                        return (
                                            <div className="document-title-container">
                                                <span className="title">{document.name}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default LinkedDocuments
