import React, { FC, useContext, useEffect } from 'react'
import './searchParts.scss'
import Loading from '../../../../components/Loading'
import { SearchPartContext } from '../../../../store/SearchPartContext'
import SearchEngine from './SearchEngine'
import SearchEquipmentsPartsTable from './SearchEquipmentsPartsTable'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import { ICartInformation, IQuickCartCustomer } from '../../../../store/models'
import CompanyService from '../../../../services/UiService/Company'
import Checkbox from '../../../../components/Checkbox'
import SearchableDropdown from '../../../../components/Dropdown/SearchableDropdown'

interface SearchPartsProps {}

const SearchParts: FC<SearchPartsProps> = () => {
    // const { lastSearchedText, loading, filteredOnes, isSearchMode } = useContext(SearchPartContext)
    const { loading, filteredSearchedEquipmentsParts, isSearchMode, customerOptions, onChangeSearchedCustomerId, searchedCustomerId, searchedCustomerName, setCustomerOptionsList } =
        useContext(SearchPartContext)
    const { handleCartInformation, setQuickCartInfo, purchaseWithoutCustomer, handlePurchaseWithoutCustomer } = useContext(CheckoutContext)

    // find the total part count in filteredOnes
    // const totalCount = useMemo(() => {
    //     return filteredOnes.map((item) => item.parts.length).reduce((a, b) => a + b, 0)
    // }, [filteredOnes])

    useEffect(() => {
        const loadComponent = async () => {
            const companyService = new CompanyService()
            const response = await companyService.getCustomersForActivity()
            setCustomerOptionsList(response)
        }
        loadComponent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClearSelectedCustomer = () => {
        onChangeSearchedCustomerId(null, '')
        handleCartInformation({
            purchaseForCustomerId: '',
            purchaseForCustomerName: '',
            isManuallyPurchaseForCustomer: true,
        } as ICartInformation)
        setQuickCartInfo({
            id: '',
            name: '',
        })
    }

    return (
        <>
            {loading && <Loading />}
            {/* <div className={`searchPartsContainer ${filteredOnes.length === 0 && 'emptySearchParts'}`}>
                {!isSearchMode && <div className="resultCount">{`${filteredOnes.length} results for "${lastSearchedText}"`}</div>}
                <SearchEngine />
                {filteredOnes.length !== 0 && <SearchPartsTable data={filteredOnes} />}
            </div> */}
            <div className={`searchPartsContainer ${isSearchMode && 'emptySearchParts'}`}>
                {!isSearchMode && <div className="resultCount">{`${filteredSearchedEquipmentsParts.length} results found!`}</div>}
                <div className="searchEngineAndDropdownContainer">
                    {!isSearchMode && (
                        <div className="customer-select-container">
                            <SearchableDropdown
                                label="SELECT CUSTOMER"
                                data={(customerOptions || []).map((customer) => ({
                                    id: customer.id,
                                    label: customer.name,
                                }))}
                                clearIconText="Clear selected"
                                selectedItem={{ id: searchedCustomerId ?? '', label: searchedCustomerName ?? '' }}
                                onChange={(event, values) => {
                                    if (values) {
                                        onChangeSearchedCustomerId(values.id, values.label)
                                        setQuickCartInfo({
                                            id: values.id,
                                            name: values.label,
                                        } as IQuickCartCustomer)
                                        handleCartInformation({
                                            purchaseForCustomerId: values.id,
                                            purchaseForCustomerName: values.label,
                                        } as ICartInformation)
                                    } else {
                                        onChangeSearchedCustomerId(null, '')
                                        handleCartInformation({
                                            purchaseForCustomerId: '',
                                            purchaseForCustomerName: '',
                                        } as ICartInformation)
                                        setQuickCartInfo({
                                            id: '',
                                            name: '',
                                        })
                                    }
                                }}
                                disabled={purchaseWithoutCustomer}
                            />
                            <div className="purchase-without-customer">
                                <Checkbox
                                    checked={purchaseWithoutCustomer}
                                    onChange={(val) => {
                                        if (val) {
                                            onClearSelectedCustomer()
                                        }
                                        onChangeSearchedCustomerId(null, '')
                                        handlePurchaseWithoutCustomer(val)
                                    }}
                                />
                                <span>Enter a Customer Manually</span>
                            </div>
                        </div>
                    )}
                    <SearchEngine />
                </div>
                {!isSearchMode && <SearchEquipmentsPartsTable data={filteredSearchedEquipmentsParts} />}
            </div>
        </>
    )
}

export default SearchParts
