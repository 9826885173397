import Modal from '../../Modal'
import './style.scss'

interface NoDataProp {
    visible: boolean
    onYesAction: () => void
    onNoAction: () => void
    partName: string
}
function DeleteMappingConfirmationModal(props: NoDataProp) {
    return (
        <Modal
            className="delete-mapping-confirmation-modal"
            visible={props.visible}
            title="Delete hotspot?"
            primaryButtonText="No"
            secondaryButtonText="Yes"
            onClickSecondary={props.onYesAction}
            onClickPrimary={props.onNoAction}
        >
            <span>Unlink this part?</span>
            <hr />
        </Modal>
    )
}

export default DeleteMappingConfirmationModal
