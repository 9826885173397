import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { BaseOutputDto } from '../../models/Ui/Common'
import { GetPartDto, SearchInput, UpdatePartModel } from '../../models/Ui/Part'

class PartService {
    async search(args: SearchInput): Promise<GetPartDto[]> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.SearchPart, args, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Search part error')
        }
    }

    async delete(id: string): Promise<BaseOutputDto> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeletePart + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Delete part error')
        }
    }

    async deleteGroup(id: string): Promise<BaseOutputDto> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeletePart + id + '/group', {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Delete part error')
        }
    }

    async update(id: string, data: UpdatePartModel): Promise<BaseOutputDto> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdatePart + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update part error')
        }
    }

    async updateGroup(id: string, data: UpdatePartModel): Promise<BaseOutputDto> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdatePart + id + '/group', data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update part error')
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PartService()
