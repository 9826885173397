import React, { FC, useEffect, useState } from 'react'
// import DOMPurify from 'dompurify'
// import { toast } from 'react-toastify'
import './Notepad.scss'
import { NewUpdateEquipmentModel } from '../../models/New/Equipment'

interface NotepadProps {
    className?: string
    id: string
    notes: string
    saveNote: (data: NewUpdateEquipmentModel) => void
    showSubTitle?: boolean
    placeholder?: string
    disableDebounce?: boolean
}

const DEBOUNCE_DELAY = 1500

const NewNotepad: FC<NotepadProps> = ({ disableDebounce = false, saveNote, id, className, notes, showSubTitle = true, placeholder = '' }) => {
    // const [loading, setLoading] = useState<boolean>(false)
    const [value, setValue] = useState('')
    const [timer, setTimer] = useState<any>(null)

    useEffect(() => {
        setValue(notes || '')
    }, [notes, id])

    // const debouncedChangeHandler = useMemo(() => {
    //     async function save(note: string) {
    //         try {
    //             setLoading(true)
    //             await saveNote({ notes: note })
    //         } catch (error: any) {
    //             console.error(error)
    //             toast.error(error)
    //         } finally {
    //             setLoading(false)
    //         }
    //     }
    //     return debounce((note) => save(note), DEBOUNCE_DELAY)
    // }, [saveNote])

    // useEffect(() => {
    //     const notepadElement = document.getElementById('notepad-content')
    //     if (!notepadElement) throw new Error('Notepad element not found!')

    //     function onNoteChangeHandler(event: Event) {
    //         const note = (event.target as HTMLElement).innerHTML
    //         debouncedChangeHandler(note)
    //     }

    //     notepadElement.addEventListener('input', onNoteChangeHandler, false)

    //     return () => {
    //         notepadElement.removeEventListener('input', onNoteChangeHandler)
    //         debouncedChangeHandler.cancel()
    //     }
    // }, [debouncedChangeHandler, id])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const debounceFn = useCallback(debounce(handleDebounceFn, disableDebounce ? 0 : DEBOUNCE_DELAY), [])

    // function handleDebounceFn(val: string) {
    //     saveNote({ notes: val })
    // }

    const onChange = (val: string) => {
        setValue(val)

        clearTimeout(timer)

        const newTimer = setTimeout(
            () => {
                saveNote({ notes: val })
            },
            disableDebounce ? 0 : DEBOUNCE_DELAY
        )

        setTimer(newTimer)
    }

    return (
        <div className={`new-notepad ${className}`}>
            <div className="new-notepad-header">
                <span className="title">Notepad</span>
                {showSubTitle && <span className="note">*Your customers will not see this note.</span>}
            </div>
            <div className="new-notepad-body">
                <div className="body-header">
                    {/* <span className='date'>Jan 15, 2022 • 1:50 PM PDT</span> */}
                    {/* {loading && (
                        <div className="save-container">
                            <span className="saving-info">Saving...</span>
                            <span className="loading-span" />
                        </div>
                    )} */}
                </div>
                <textarea value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder || 'Start typing to add a note...'} />
                {/* <div
                    id="notepad-content"
                    className="content"
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(notes || ''),
                    }}
                    contentEditable={true}
                    placeholder={placeholder || 'Start typing to create a note about this equipment'}
                ></div> */}
            </div>
        </div>
    )
}

export default NewNotepad
