import React from 'react'
import './index.scss'
import { priceFormatter } from '../../../../../../../utils/helpers'
import { ContractOutputModel } from '../../../../../../../models/Ui/Contract'
import dayjs from 'dayjs'
import moment from 'moment'

interface Props {
    contract: ContractOutputModel
    handleEdit: () => void
}

function ContractInfoCart(props: Props) {
    let contractStatus = 'Offer'
    let nextBillingPrice = 0
    let nextBillingDate: Date | undefined

    if (props.contract.startDate) {
        const today = new Date().getTime()

        const from = new Date(props.contract.startDate).getTime()
        const to = new Date(props.contract.endDate!).getTime()

        if (props.contract.totalPrice > 0 && props.contract.firstBillingDate && props.contract.startDate && props.contract.durationUnit && props.contract.duration) {
            const differenceDay = dayjs(props.contract.endDate).diff(props.contract.startDate, 'd', true)

            let stepUnitDays = 1

            switch (props.contract.billingFrequency) {
                case 'Weekly':
                    stepUnitDays = 7
                    break
                case 'Biweekly':
                    stepUnitDays = 14
                    break
                case 'Monthly':
                    stepUnitDays = 30
                    break
                case 'Quarterly':
                    stepUnitDays = Math.ceil(365 / 4)
                    break
                case 'Annually':
                    stepUnitDays = 365
                    break
            }

            const howManyTimesPay = Math.ceil(differenceDay / stepUnitDays)

            nextBillingPrice = props.contract.totalPrice / howManyTimesPay

            let nowDate = new Date()
            nextBillingDate = dayjs(props.contract.firstBillingDate).toDate()
            let findNextDate = dayjs(props.contract.firstBillingDate).toDate() > nowDate
            while (!findNextDate) {
                if (nowDate > nextBillingDate) {
                    findNextDate = true
                } else {
                    nextBillingDate = dayjs(nextBillingDate).add(stepUnitDays, 'd').toDate()
                }
            }
        }

        if ((today >= from && today <= to) || today < from) {
            contractStatus = 'Active'
        } else if (today > to) {
            nextBillingPrice = 0
            contractStatus = 'Expired'
        }
    }
    //     id: 1,
    //         text: 'Weekly',
    // },
    // {
    //     id: 2,
    //         text: 'Biweekly',
    // },
    // {
    //     id: 4,
    //         text: 'Monthly',
    // },
    // {
    //     id: 5,
    //         text: 'Quarterly',
    // },
    // {
    //     id: 6,
    //         text: 'Annually',
    // },
    //
    // {
    //     id: 7,
    //         text: 'Custom',

    return (
        <div className="product-info-cart-container">
            <div className={'left left-badge-' + contractStatus.toLowerCase()}>
                <span className="title">Contract Status</span>
                <div style={{ margin: '4px' }} className={'status-badge badge-' + contractStatus.toLowerCase()}>
                    {contractStatus}
                </div>

                {props.contract.startDate ? (
                    <>
                        <div className="content">
                            {moment(props.contract.startDate).format('ll')} - {moment(props.contract.endDate).format('ll')}
                        </div>
                    </>
                ) : (
                    <span className="add-button" onClick={props.handleEdit}>
                        +Add a start date to activate this contract
                    </span>
                )}
            </div>

            <div className="divider" />

            <div className="right">
                <span className="title">Total Contract Price</span>
                <div className="group">
                    {Number(props.contract.totalPrice) > 0 ? (
                        <>
                            <div className="content">{priceFormatter(props.contract.totalPrice)}</div>
                        </>
                    ) : (
                        <span className="add-button" onClick={props.handleEdit}>
                            +add
                        </span>
                    )}
                </div>
            </div>

            {nextBillingDate && (
                <>
                    <div className="divider" />

                    <div className="right">
                        <span className="title">Next Billing</span>

                        {contractStatus === 'Expired' ? '-' : <div className="content">{priceFormatter(nextBillingPrice)}</div>}

                        {contractStatus !== 'Expired' && (
                            <div className="group">
                                {moment(nextBillingDate).format('ll')}
                                <span className="dot" />

                                {props.contract.billingFrequency}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default ContractInfoCart
