import { FC, useState, useEffect, useContext, Fragment, Dispatch, SetStateAction } from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Divider } from '@mui/material'
import Input from '../../../../../../components/Input'
import Loading from '../../../../../../components/Loading'
import Modal from '../../../../../../components/Modal'
import Icons, { IconType } from '../../../../../../Icon'
import { CreatePartListModel, EquipmentDrawingInputModel, EquipmentDrawingModel, EquipmentModel, EquipmentPartsListModel, PartsModel } from '../../../../../../models/New/Equipment'
import { FileModel } from '../../../../../../services/NewServices/StorageService'
import './style.scss'
import DrawingModalUploadedImage from './UploadedImage'
import DrawingModalUploadSection from './UploadSection'
import { ConfirmationContext } from '../../../../../../store/ConfirmationContext'
import EquipmentService from '../../../../../../services/UiService/Equipment'
import PartListService from '../../../../../../services/UiService/PartList'
import { csvToJson } from '../../../../../../utils/helpers'
import { toast } from 'react-toastify'
import { CommonActionTypes } from '../../../../../../models/Ui/Common'

interface Props {
    visible: boolean
    closeModal: () => void
    equipment: EquipmentModel
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (actionType?: CommonActionTypes) => Promise<void>
    onError: (error: Error) => void
}

interface ExistDrawingsModel extends Pick<EquipmentDrawingModel, 'id' | 'url'> {}

interface ExistPartListFileModel extends Omit<EquipmentPartsListModel, 'equipmentId' | 'parts'> {}

const UploadDrawingPartsModal: FC<Props> = ({ visible, closeModal, equipment, ...props }) => {
    const { confirm } = useContext(ConfirmationContext)
    const templateLink = 'https://docs.google.com/spreadsheets/d/1hlFSrwoUEoK45CoA8L8WHol1UmKHes2gQqojKh0UEpw'
    const [uploadingFile, setUploadingFile] = useState<boolean>(false)
    const [newUploadedCSVs, setNewUploadedCSVs] = useState<FileModel[]>([])
    const [newUploadedImages, setNewUploadedImages] = useState<FileModel[]>([])
    const [newUploadedPdfFiles, setNewUploadedPdfFiles] = useState<FileModel[]>([])
    const [existImages, setExistImages] = useState<ExistDrawingsModel[]>([])
    const [existPdfs, setExistPdfs] = useState<ExistDrawingsModel[]>([])
    const [existCSVFiles, setExistCSVFiles] = useState<ExistPartListFileModel[]>([])
    const [newParts, setNewParts] = useState<Array<PartsModel[]>>([])

    useEffect(() => {
        const images = equipment.drawings?.filter((child) => !child.url.includes('.pdf?')) ?? []
        const pdfs = equipment.drawings?.filter((child) => child.url.includes('.pdf?')) ?? []
        setExistImages(images.map((child) => ({ id: child.id, url: child.url })))
        setExistPdfs(pdfs.map((child) => ({ id: child.id, url: child.url })))
    }, [equipment.drawings])

    useEffect(() => {
        setExistCSVFiles(equipment.partLists?.map((child) => ({ id: child.id, name: child.name })) ?? [])
    }, [equipment.partLists])

    const isFormValid = () => newUploadedImages.length || newUploadedPdfFiles.length || newUploadedCSVs.length

    const onSubmit = async () => {
        if (isFormValid()) {
            const uploadedDrawings: boolean = !!newUploadedImages.length || !!newUploadedPdfFiles.length
            try {
                props.onLoading()
                if (uploadedDrawings) {
                    const urls = [...newUploadedImages, ...newUploadedPdfFiles].map((child) => child.url)
                    const payload: EquipmentDrawingInputModel = { equipmentId: equipment.id, urls }
                    await EquipmentService.createEquipmentDrawing(equipment.id, payload)
                    setNewUploadedImages([])
                    setNewUploadedPdfFiles([])
                }
                if (newUploadedCSVs.length) {
                    const payload: CreatePartListModel[] = newUploadedCSVs.map((child, index) => ({ name: child.name, parts: newParts[index] }))
                    await EquipmentService.createEquipmentPartList(equipment.id, payload)
                    setNewUploadedCSVs([])
                    setNewParts([])
                }
                await props.onSuccess()
                props.onCompleted()
                closeModal()
            } catch (error) {
                props.onError(error as Error)
            } finally {
                props.onCompleted()
            }
        }
    }

    const convertCSVFileToPartsModelArray = async (file: File, onParsed: (parts: PartsModel[]) => void) => {
        try {
            csvToJson(file as any, async (json) => {
                if (json[0]['Part name'] && json[0]['Part number']) {
                    const newParts: PartsModel[] = await json
                        .map((p: any, index: number) => {
                            if (p['Part number'] && p['Part name']) {
                                // No.,Part name,Product category,Brand,Description,Qty. and unit,Packaging,Part number,Price,Kit no
                                return {
                                    number: p['No.'],
                                    name: p['Part name'],
                                    category: p['Product category'],
                                    brand: p['Brand'],
                                    description: p['Description'],
                                    quantity: p['Qty. and unit'],
                                    pkg: p['Packaging'],
                                    partNumber: p['Part number'],
                                    price: p['Price'] && p['Price'] !== '' && !isNaN(+p['Price'].replace('$', '').replaceAll(',', '')) ? +p['Price'].replace('$', '').replaceAll(',', '') : null,
                                    kitNo: p['Kit no'],
                                    soldPerSet: null,
                                    drawingUrl: null,
                                    listName: file?.name || '',
                                    uom: null,
                                    leadTime: null,
                                    index: index,
                                    notes: null,
                                    partListId: null,
                                }
                            } else {
                                return null
                            }
                        })
                        .filter((part: PartsModel) => part !== null)
                    onParsed(newParts)
                } else {
                    onParsed([])
                }
            })
        } catch (error) {
            console.log(error)
            onParsed([])
        }
    }

    const onCancel = () => {
        closeModal()
    }

    const onChangeFileName = (state: FileModel[], setState: Dispatch<SetStateAction<FileModel[]>>, value: string, index: number) => {
        let existState = state
        if (existState[index]) {
            existState[index].name = value
            setState([...existState])
        }
    }

    // Not required for now
    // const onChangePdfFileName = (value: string, index: number) => {
    //     onChangeFileName(newUploadedPdfFiles, setNewUploadedPdfFiles, value, index)
    // }

    const onChangePartName = (value: string, index: number) => {
        onChangeFileName(newUploadedCSVs, setNewUploadedCSVs, value, index)
    }

    const onDeleteDrawing = (id: string) => {
        confirm({
            func: async (successCallback) => {
                try {
                    props.onLoading()
                    await EquipmentService.deleteEquipmentDrawing(id)
                    equipment.drawings = equipment.drawings.filter((child) => child.id !== id)
                    await props.onSuccess(CommonActionTypes.DELETE)
                    successCallback('Drawing deleted successfully!', true)
                } catch (error) {
                    props.onError(error as Error)
                } finally {
                    props.onCompleted()
                }
            },
        })
    }

    const onDeletePdfFile = (index: number, isNew = false) => {
        if (isNew) {
            setNewUploadedPdfFiles((prevState) => prevState.filter((_child, i) => i !== index))
        } else {
            const { id } = existPdfs[index]
            if (id) {
                onDeleteDrawing(id)
            }
        }
    }

    const onDeleteImage = (index: number, isNew = false) => {
        if (isNew) {
            setNewUploadedImages((prevState) => prevState.filter((_child, i) => i !== index))
        } else {
            const { id } = existImages[index]
            if (id) {
                onDeleteDrawing(id)
            }
        }
    }

    const onDeletePart = (index: number, isNew = false) => {
        if (isNew) {
            setNewUploadedCSVs((prevState) => prevState.filter((_child, i) => i !== index))
            setNewParts((prevState) => prevState.filter((_child, i) => i !== index))
        } else {
            const { id } = existCSVFiles[index]
            if (id) {
                confirm({
                    modalClassName: 'confirm-delete-part-list-modal',
                    modalTitle: 'Delete Part List?',
                    primaryButtonText: 'Delete',
                    func: async (successCallback) => {
                        try {
                            props.onLoading()
                            await PartListService.delete(id)
                            successCallback('Part deleted successfully!', true)
                            equipment.partLists = equipment.partLists.filter((child) => child.id !== id)
                            await props.onSuccess()
                        } catch (error: any) {
                            props.onError(error as Error)
                        } finally {
                            props.onCompleted()
                        }
                    },
                    contentNode: (
                        <Fragment>
                            <div className="modal-line">
                                <span>
                                    If you are deleting a part list, all child level parts will be deleted as well.
                                    <span className="important-inline-note"> Deleting this part list will delete all parts linked to it as well. This action cannot be undone.</span>
                                </span>
                            </div>
                            <div className="modal-line">Are you sure you would like to proceed?</div>
                        </Fragment>
                    ),
                })
            }
        }
    }

    const seperateUploadedDrawingFiles = (val: FileModel[]) => {
        const images = val.filter((child) => child.type.includes('image'))
        const notImages = val.filter((child) => !child.type.includes('image'))
        return [images, notImages]
    }

    const onUploadDrawings = (val: FileModel[]) => {
        const [images, notImages] = seperateUploadedDrawingFiles(val)
        setNewUploadedImages((prevState) => [...prevState, ...images])
        setNewUploadedPdfFiles((prevState) => [...prevState, ...notImages])
    }

    const onUploadPart = (val: FileModel[], fileList?: FileList) => {
        for (let index = 0; index < val.length; index++) {
            const newFile = val[index]
            const fileBlob = fileList![index]
            convertCSVFileToPartsModelArray(fileBlob, (parts) => {
                if (parts.length) {
                    setNewParts((prevState) => [...prevState, parts])
                    setNewUploadedCSVs((prevState) => [...prevState, newFile])
                } else {
                    toast.error(`${newFile.name} is not saveable!`)
                }
            })
        }
    }

    return (
        <>
            {uploadingFile && <Loading />}
            <Modal
                className="upload-drawing-parts-modal"
                visible={visible}
                title="Upload drawing and parts"
                primaryButtonText="Save"
                onClickPrimary={onSubmit}
                primaryDisabled={!isFormValid()}
                secondaryButtonText="Cancel"
                onClickSecondary={onCancel}
            >
                <div className="modal-content-title">Drawing</div>
                <div className="modal-content-text">Upload one or more drawings of your equipment (Allowed formats are .pdf and image files like .jpg and .png)</div>
                <DrawingModalUploadSection accept="image/png, image/jpeg, application/pdf" onUploadingFile={(show) => setUploadingFile(show)} text="UPLOAD DRAWINGS" onUploadFiles={onUploadDrawings} />
                {(!!newUploadedImages.length || !!existImages.length) && (
                    <div className="images-section">
                        {existImages?.map((child, index) => (
                            <DrawingModalUploadedImage key={index} photoUrl={child.url} onDelete={() => onDeleteImage(index)} />
                        ))}
                        {newUploadedImages?.map((child, index) => (
                            <DrawingModalUploadedImage key={index} photoUrl={child.url} onDelete={() => onDeleteImage(index, true)} />
                        ))}
                    </div>
                )}
                {(!!newUploadedPdfFiles.length || !!existPdfs.length) && (
                    <div className="pdf-section">
                        {existPdfs?.map((child, index) => (
                            <Input
                                className="file-input"
                                key={index}
                                onChange={(e) => null}
                                placeholder="PDF FILE"
                                endIcon={faTrash}
                                onClickEndIcon={() => {
                                    onDeletePdfFile(index)
                                }}
                                value={child.url}
                                endIconTooltipText="Delete"
                            />
                        ))}
                        {newUploadedPdfFiles?.map((child, index) => (
                            <Input
                                className="file-input"
                                key={index}
                                onChange={(e) => null}
                                placeholder="PDF FILE"
                                endIcon={faTrash}
                                onClickEndIcon={() => {
                                    onDeletePdfFile(index, true)
                                }}
                                value={child.name}
                                endIconTooltipText="Delete"
                            />
                        ))}
                    </div>
                )}
                <div className="modal-content-text info-text-section">
                    <span>
                        <Icons type={IconType.Info} />
                    </span>
                    <span>
                        Uploading pdf files will not allow you to use Monittor’s interactive hotspots for parts. This feature allows you to tag parts on the equipment drawing, allowing your customers
                        and technicians to make purchases straight from the drawing. To use this feature, upload image files instead of pdf files.
                    </span>
                </div>
                <Divider />
                <div className="modal-content-title">Parts Lists</div>
                <div className="modal-content-text">Upload one or more lists relevant to your equipment. Name the lists as you would want your customers to see them.</div>
                <div className="modal-content-text template-text">
                    You can use{' '}
                    <span className="underlined-text">
                        <a href={templateLink} target="_blank" rel="noreferrer">
                            our template here.
                        </a>
                    </span>
                </div>
                <DrawingModalUploadSection accept=".csv" onUploadingFile={(show) => setUploadingFile(show)} text="UPLOAD LISTS" onUploadFiles={onUploadPart} />
                {(!!newUploadedCSVs.length || !!existCSVFiles.length) && (
                    <div className="uploaded-part-lists">
                        {existCSVFiles?.map((child, index) => (
                            <Input
                                className="file-input"
                                key={index}
                                onChange={(e) => null}
                                placeholder="LIST NAME"
                                endIcon={faTrash}
                                onClickEndIcon={() => {
                                    onDeletePart(index)
                                }}
                                value={child.name}
                                endIconTooltipText="Delete"
                            />
                        ))}
                        {newUploadedCSVs?.map((child, index) => (
                            <Input
                                className="file-input"
                                key={index}
                                onChange={(e) => onChangePartName(e.target.value, index)}
                                placeholder="LIST NAME"
                                endIcon={faTrash}
                                onClickEndIcon={() => {
                                    onDeletePart(index, true)
                                }}
                                value={child.name}
                                endIconTooltipText="Delete"
                            />
                        ))}
                    </div>
                )}
            </Modal>
        </>
    )
}

export default UploadDrawingPartsModal
