import { FC, RefObject, useEffect, useRef, useState } from 'react'
import Icon, { IconType } from '../../Icon'
import './addMore.scss'

interface AddMoreButtonProps {
    options: { title: string; onClick: () => void }[]
    menuPosition?: 'up' | 'down'
}

const AddMoreButton: FC<AddMoreButtonProps> = ({ options, menuPosition = 'down' }) => {
    const addMoreRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

    const [showDropdown, setShowDropdown] = useState(false)

    const closeDropdown = () => {
        setShowDropdown(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', (e: MouseEvent) => {
            if (addMoreRef.current && !addMoreRef.current.contains(e.target as Node)) {
                setShowDropdown(false)
            }
        })
    }, [])

    return (
        <div className="addMoreButtonContainer" ref={addMoreRef}>
            <div className="textContainer" onClick={() => setShowDropdown(true)}>
                <Icon type={IconType.BoldPlus} /> Add More <Icon type={IconType.ChevronDown} />
            </div>
            {showDropdown && (
                <div className={`addMoreDropdown ${menuPosition}`}>
                    {options.map((child) => (
                        <div className="option" onClick={() => [child.onClick(), closeDropdown()]}>
                            {child.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default AddMoreButton
