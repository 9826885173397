import './EquipmentDetailsTab.scss'
import { ManufacturerSummaryModel } from '../../../models/Ui/Manufacturer'
import { EquipmentModel } from '../../../models/New/Equipment'
import StorageService from '../../../services/NewServices/StorageService'
import { UpdateImageModel } from '../../../models/New/Image'
import ImageService from '../../../services/UiService/Image'
import { TechnicianViewType } from '../../../store/models'
import EquipmentImageBox from '../../../components/EquipmentImageBox'
import NewTags from '../../../components/NewTags'
import EquipmentInfoCart from './EquipmentInfoCart'
import { useNavigate } from 'react-router'
import React, { useContext } from 'react'
import { ConfirmationContext } from '../../../store/ConfirmationContext'

interface EquipmentDetailsTabProps {
    isGoToTemplateButton?: boolean
    isRootEquipment: boolean
    manufacturer?: ManufacturerSummaryModel
    equipment: EquipmentModel
    technicianViewType?: string
    flexWrap?: boolean
    onTagsUpdate: (id: string, name: string, updateType: string) => Promise<void>
    onLoading: () => void
    onCompleted: () => void
    onSuccess: () => void
    handleEdit: () => void
}

const descriptionLineRenderer = (value: string | undefined, handleEdit: () => void) => {
    return (
        <div className="line">
            <span className="title">Description</span>
            {(
                <span className="value" style={{ lineBreak: 'normal' }}>
                    {value}
                </span>
            ) || (
                <span className="add-button" onClick={handleEdit}>
                    +add description
                </span>
            )}
        </div>
    )
}
const vendorRenderer = (vendor: string | undefined, brand: string | undefined, handleEdit: () => void) => {
    return (
        <div className="line">
            <span className="title">Brand</span>
            {brand || (
                <span className="add-button" onClick={handleEdit}>
                    +add
                </span>
            )}
        </div>
    )
}

const templateLineRenderer = (templateId: string, handleNavigate: () => void) => {
    return (
        <div className="line">
            <span className="title">Template</span>
            <div className="go-to" onClick={() => handleNavigate()}>
                Go to template
            </div>
        </div>
    )
}

function EquipmentDetailsTab(props: EquipmentDetailsTabProps) {
    const { onLoading, onSuccess, onCompleted } = props
    const { confirm } = useContext(ConfirmationContext)
    const navigate = useNavigate()
    const imageService = new ImageService()
    async function createEquipmentImageHandler(file: FileList) {
        try {
            onLoading()
            if (file) {
                props.onLoading()
                const uploadFileService = new StorageService()
                const fileInfos = await uploadFileService.uploadMultiple(file as unknown as FileList)
                await imageService.createImage(
                    fileInfos.map((file) => {
                        return { url: file.url, equipmentId: props.equipment.id }
                    })
                )
                onSuccess()
            } /* else not needed */
        } catch (error: any) {
            console.log(error)
        } finally {
            onCompleted()
        }
    }

    async function updateEquipmentImageHandler(imageId: string, isFeatured: boolean) {
        try {
            onLoading()
            const newData: UpdateImageModel = {
                isFeatured: isFeatured,
            }
            await imageService.update(imageId, newData)
            onSuccess()
        } catch (error: any) {
            console.log(error)
        } finally {
            onCompleted()
        }
    }

    async function deleteCustomEquipmentImageHandler(imageId: string) {
        confirm({
            modalTitle: 'Delete Image',
            // titleColor: '#D84315',
            primaryButtonText: 'Cancel',
            doesPrimaryButtonSubmit: false,
            secondaryButtonText: 'Delete',
            func: async (successCallback, errorCallback) => {
                try {
                    onLoading()
                    await imageService.delete(imageId)
                    onSuccess()
                    successCallback()
                } catch (error: any) {
                    errorCallback(error)
                } finally {
                    onCompleted()
                }
            },
            contentNode: (
                <div className="modal-line">
                    <span className="warning-text">Are you sure you would like to delete?</span>
                </div>
            ),
        })
    }

    const handleNavigate = () => {
        navigate('/equipment/' + props.equipment.id)
    }

    return (
        <div className="new-details-tab-container">
            {props.isRootEquipment && (
                <div className="equipment-info-cart">
                    <EquipmentInfoCart flexWrap={props.flexWrap} handleEdit={props.handleEdit} equipment={props.equipment} />
                </div>
            )}
            <div className="equipment-info">
                <div className="top">
                    <EquipmentImageBox
                        title="Equipment"
                        updateEquipmentImageHandler={updateEquipmentImageHandler}
                        deleteEquipmentImageHandler={deleteCustomEquipmentImageHandler}
                        createEquipmentImageHandler={createEquipmentImageHandler}
                        images={props.equipment?.images?.sort((image) => (image.isFeatured ? -1 : 1))}
                    />
                    <div className="top-right">
                        <div className="tags-container">
                            <span className="title">Tags</span>
                            <NewTags
                                grid="vertical"
                                createItem={async (tags: string) => await props.onTagsUpdate(props.equipment.id, tags, 'CREATE_TAG')}
                                removeItem={async (id: string, name: string) => await props.onTagsUpdate(id, name, 'DELETE_TAG')}
                                items={props.equipment.tags || []}
                                canBeAdded={props.technicianViewType !== TechnicianViewType.ViewAs}
                                canBeDeleted={props.technicianViewType !== TechnicianViewType.ViewAs}
                            />
                        </div>
                        {props.flexWrap ? (
                            vendorRenderer(props.equipment.manufacturerName, props.equipment.brand, props.handleEdit)
                        ) : (
                            <>
                                {descriptionLineRenderer(props.equipment.description, props.handleEdit)}
                                {props.isGoToTemplateButton && templateLineRenderer(props.equipment.templateId, handleNavigate)}
                            </>
                        )}
                        {props.equipment.templateId && (
                            <div className="line">
                                <span className="title">Original Template</span>
                                <div className="go-to" onClick={() => navigate('/equipment/' + props.equipment.templateId)}>
                                    Go to original template
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="bottom">
                    {!props.flexWrap ? (
                        vendorRenderer(props.equipment.manufacturerName, props.equipment.brand, props.handleEdit)
                    ) : (
                        <>
                            {descriptionLineRenderer(props.equipment.description, props.handleEdit)}
                            {props.isGoToTemplateButton && templateLineRenderer(props.equipment.templateId, handleNavigate)}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EquipmentDetailsTab
