import React from 'react'
import { UserModel } from '../../../../models/Ui/User'
import TeamMemberList from '../../Tables/TeamMemberListTable'
import './style.scss'

interface Props {
    user: UserModel | undefined
    users: UserModel[]
    handleResendVerification: (userId: string) => void
    onClickEditTeamMember: (teamMemberId: string) => void
    onClickDeleteTeamMember: (teamMemberId: string) => void
}

function TeamTab(props: React.PropsWithChildren<Props>) {
    const isDisabledButton = (e: UserModel) => {
        if (e.id === props.user?.id || e.isPrimary === true) {
            return true
        } else return false
    }

    return (
        <TeamMemberList
            data={props.users.map((user) => {
                return {
                    id: user.id,
                    name: user.firstName + ' ' + user.lastName,
                    jobTitle: user.jobTitle,
                    role: user.role[0].toUpperCase() + user.role.slice(1),
                    status: user.status || 'Pending',
                    isPending: user.isPending,
                    isQuoteRequestReceiver: user.isQuoteRequestReceiver,
                    disabled: isDisabledButton(user),
                }
            })}
            handleResendVerification={props.handleResendVerification}
            onClickEditTeamMember={props.onClickEditTeamMember}
            onClickDeleteTeamMember={props.onClickDeleteTeamMember}
        />
    )
}

export default TeamTab
