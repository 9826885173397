import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { arraySort } from '../../../../../utils/helpers'
import Icons, { IconType } from '../../../../../Icon'
import { useEffect } from 'react'
import NoDataInfo from '../../../../../components/NoDataInfo'
import './style.scss'
import { InstallationModel } from '../../Tabs/InstallationsTab/InstallationsTab'

interface CustomerEquipmentLocationModel {
    street1: string
    street2: string
    city: string
    state: string
    postcode: string
    country: string
}
interface Data {
    installationAddress?: CustomerEquipmentLocationModel
    serialNumber?: string
    customerName?: string
    equipmentName?: string
    id: string
    customerId?: string
    isSubAssembly?: boolean
    topLevelEquipmentName?: string
    buttons: React.ReactNode
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface Props {
    onClickRow?: (e: InstallationModel) => void
    sortKey?: string
    data: Data[]
    setSearch: (key: string) => void
}

const columns: HeadCell[] = [
    {
        id: 'equipmentName',
        label: 'Custom Equipment Name',
    },
    {
        id: 'customerName',
        label: 'Customer',
    },
    {
        id: 'installationAddress',
        label: 'Installation Address',
    },
    {
        id: 'serialNumber',
        label: 'Serial Number',
    },
    {
        id: 'buttons',
        label: '',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    const { disable, active, direction, children, onclick } = props

    return !disable ? (
        <div className="sorting-menu">{children}</div>
    ) : (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            {disable && <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />}
        </div>
    )
}
interface TableHeadType {
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    const { orderBy, setOrderBy, direction, setDirection } = tableProps

    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'buttons' && headCell.id !== 'installationAddress' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
export default function EnhancedTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState(props.sortKey || 'name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    React.useEffect(() => {
        setPage(0)
    }, [props.data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    useEffect(() => {
        setPage(0)
    }, [props.data.length])

    if (props.data.length === 0) return <NoDataInfo />
    const handleChildElementClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
        e.stopPropagation()
    }
    return (
        <>
            <TableContainer className="monittor-table-container equipment-installation-table">
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
                    <EnhancedTableHead orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />
                    <TableBody>
                        {arraySort(props.data, orderBy, direction ? 'ascending' : 'descending')
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row: any) => {
                                return (
                                    <TableRow onClick={() => props.onClickRow!(row)} hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={row.id}>
                                        <TableCell style={{ width: '22%' }} align="left">
                                            <div className="equ">
                                                <div className="equipment-name-container">
                                                    <div className="sub-icon">{row.isSubAssembly && <Icons type={IconType.TreeViewSub} />}</div>
                                                    <div className="name-info">
                                                        <span className="equipment-name">{row.equipmentName}</span>
                                                        {row.topLevelEquipmentName && <span className="sub-top-equipment-name">{row.topLevelEquipmentName}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: '22%' }} align="left">
                                            {row.customerName}
                                        </TableCell>
                                        <TableCell style={{ width: '22%' }} align="left">
                                            {typeof row.address !== 'undefined' && (
                                                <>
                                                    {row?.address?.city && <span>{row.address.city} 1</span>}
                                                    {row?.address?.state && <span>, {row.address.state}</span>}
                                                    {row?.address?.country && <span>, {row.address.country}</span>}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell style={{ width: '22%' }} align="left">
                                            {row.serialNumber}
                                        </TableCell>
                                        <TableCell onClick={handleChildElementClick} tabIndex={10} style={{ width: '12%' }} align="right">
                                            {row.buttons}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
