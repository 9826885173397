import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button, { ButtonType } from '../../../../../components/Button'
import './style.scss'

function EmptyDta() {
    const navigate = useNavigate()

    return (
        <div className="empty-data-container">
            <span className="label">You currently do not have any transaction records on Monittor. Start by adding equipment parts or products to your cart</span>
            <div className="navigate-buttons">
                <Button type={ButtonType.Secondary} onClick={() => navigate('/products')}>
                    Go to products
                </Button>
                <Button onClick={() => navigate('/equipments')}>Go to equipment</Button>
            </div>
        </div>
    )
}

export default EmptyDta
