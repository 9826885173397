import * as React from 'react'
import { FC, useContext } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Icons from '../../../../../Icon'
import Icon, { IconType } from '../../../../../Icon'
import { arraySort, priceFormatter } from '../../../../../utils/helpers'
import './style.scss'
import Button from '../../../../../components/Button'
import { PartsModel } from '../../../../../models/New/Equipment'
import PopoverItem from '../../../../../components/PopoverItem'
import { CheckoutContext } from '../../../../../store/NewCheckoutContext/CheckoutContext'
import { TechnicianViewType } from '../../../../../store/models'
import { ignoreDashClassname } from '../../../../../hooks/useTableHook'
import NoDataInfo from '../../../../../components/NoDataInfo'
import { Color } from '../../../../../models/enums'
import { useParams } from 'react-router-dom'

interface Data {
    partName: string
    partNumber: string
    name: string
    partNo: string
    price: string
    action: string
}

interface HeadCell {
    id: keyof Data
    label: string
}

interface EnhancedTableProps {
    isPartialDetail?: boolean
    data: PartsModel[]
    deletePart: (id: string, partNumber: string) => void
    editPart: (data: any) => void
    addToCart: (data: PartsModel) => void
    showAddToCart?: boolean
    showEditDelete?: boolean
    partListName?: string
}
const columns: HeadCell[] = [
    { label: 'No.', id: 'partNo' },
    { label: 'Part name', id: 'name' },
    { label: 'Part Number', id: 'partNumber' },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    const { active, direction, children, onclick, disable } = props
    return (
        <div onClick={() => onclick()} className="sorting-menu">
            {children}
            {disable && <Icons className={`sorting-button ${active && direction}`} type={IconType.UpArrow} />}
        </div>
    )
}

interface TableHeadType {
    isPartialDetail?: boolean
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

interface RowProps {
    index: number
    data: PartsModel
    subRows: PartsModel[]
    addToCart: (data: PartsModel) => void
    deletePart: (id: string, partNumber: string) => void
    editPart: (data: any) => void
    showAddToCart?: boolean
    showEditDelete?: boolean
    partListName?: string
    isAddedCart?: boolean
}

const Row: FC<RowProps> = ({ data, subRows, index, addToCart, deletePart, editPart, showAddToCart = true, showEditDelete = false, partListName, isAddedCart }) => {
    const row = data
    const [open, setOpen] = React.useState(false)
    const checkoutContext = useContext(CheckoutContext)
    const anySubRow = subRows !== undefined && subRows.length > 0
    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                {anySubRow ? (
                    <TableCell style={{ width: '2%' }} className={ignoreDashClassname}>
                        <div onClick={() => setOpen(!open)} className="expand-button-row">
                            <Icons type={IconType.ChevronDown} />
                        </div>
                    </TableCell>
                ) : (
                    <TableCell style={{ width: '3%' }} className={ignoreDashClassname} />
                )}

                <TableCell style={{ width: '15%' }} key={index} align="left">
                    {row.number}
                </TableCell>

                <TableCell style={{ width: '30%' }} align="left">
                    <div className="name-info-container">
                        <span className="name">{row.name}</span>
                        <span className="sub-name">{partListName}</span>
                    </div>
                </TableCell>
                <TableCell style={{ width: '20%' }} align="left">
                    {row.partNumber}
                </TableCell>
                {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && (
                    <TableCell style={{ width: '10%', color: `${!row.price ? '#767676' : 'black'}` }} align="left">
                        {priceFormatter(row.price)}
                    </TableCell>
                )}
                {(checkoutContext.cartInformation.technicianViewType === TechnicianViewType.None || showEditDelete) && (
                    <TableCell style={{ width: '10%' }} align="left">
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <PopoverItem key="delete" popoverContent="Delete">
                                <Icon onClick={() => deletePart(row.id, row.partNumber)} type={IconType.Trash} />
                            </PopoverItem>
                            <PopoverItem key="edit" popoverContent="Edit">
                                <Icon onClick={() => editPart(row)} type={IconType.Pen} />
                            </PopoverItem>
                        </div>
                    </TableCell>
                )}
                {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && showAddToCart && (
                    <TableCell className="add-to-cart-button-cell" align="right">
                        <div className="add-button-container">
                            {isAddedCart ? (
                                <span className="green-tick" style={{ width: '100%' }}>
                                    <Icon type={IconType.GreenTick} color={Color.green900} />
                                </span>
                            ) : (
                                <Button onClick={() => checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && addToCart(row)}>
                                    <Icon type={IconType.AddToCart} />
                                </Button>
                            )}
                        </div>
                    </TableCell>
                )}
            </TableRow>
            {anySubRow && open
                ? subRows.map((sRow, index) => (
                      <TableRow className="parts-table-sub-row">
                          <TableCell className="child-tree-border">
                              <div className={`vertical-line ${subRows.length === index + 1 && 'last-child'}`} />
                              <div className="horizontal-container">
                                  <div className="horizontal-line" />
                              </div>
                          </TableCell>
                          <TableCell style={{ width: '5%' }} key={sRow.index} align="left" className={ignoreDashClassname}></TableCell>
                          <TableCell style={{ width: '20%' }} align="left">
                              {sRow.name}
                          </TableCell>
                          <TableCell style={{ width: '5%' }} align="left">
                              {sRow.partNumber}
                          </TableCell>
                          {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && <TableCell style={{ width: '10%' }}>{priceFormatter(sRow.price)}</TableCell>}
                          {checkoutContext.cartInformation.technicianViewType === TechnicianViewType.None || showEditDelete ? (
                              <TableCell style={{ width: '8%' }} align="left">
                                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                      <PopoverItem popoverContent="Delete">
                                          <Icon onClick={() => deletePart(row.id, row.partNumber)} type={IconType.Trash} />
                                      </PopoverItem>
                                      <PopoverItem popoverContent="Edit">
                                          <Icon onClick={() => editPart(sRow)} type={IconType.Pen} />
                                      </PopoverItem>
                                  </div>
                              </TableCell>
                          ) : (
                              <TableCell />
                          )}
                      </TableRow>
                  ))
                : null}
        </>
    )
}

interface TableBodyType {
    isPartialDetail?: boolean
    direction: boolean
    orderBy: string
    data: PartsModel[]
    rowsPerPage: number
    page: number
    addToCart: (data: PartsModel) => void
    deletePart: (id: string, partNumber: string) => void
    editPart: (data: any) => void
    showAddToCart?: boolean
    showEditDelete?: boolean
    partListName?: string
}

const EnhancedTableBody: FC<TableBodyType> = ({ showEditDelete, direction, orderBy, data, rowsPerPage, page, addToCart, deletePart, editPart, showAddToCart, partListName }) => {
    const filteringData = data.filter((x) => !x.kitNo)
    const { equipmentId } = useParams()
    const { contextEquipments } = useContext(CheckoutContext)
    const equipment = contextEquipments.find((contextEquipment) => contextEquipment.equipment.id === equipmentId)
    return (
        <TableBody>
            {arraySort(filteringData, orderBy, direction ? 'ascending' : 'descending')
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: PartsModel, index: number) => {
                    const subRows = data.filter((x) => x.kitNo && x.kitNo.trim() !== '' && x.kitNo.trim() === row.partNumber?.trim())
                    let isAddedCart
                    if (equipment) {
                        isAddedCart = equipment.parts.some((contextPart) => contextPart.part.id === row.id)
                    }
                    return (
                        <Row
                            partListName={partListName}
                            showEditDelete={showEditDelete}
                            showAddToCart={showAddToCart}
                            index={index}
                            data={row}
                            subRows={subRows}
                            deletePart={deletePart}
                            editPart={editPart}
                            addToCart={addToCart}
                            isAddedCart={isAddedCart}
                        />
                    )
                })}
        </TableBody>
    )
}

const EnhancedTableHead: FC<TableHeadType> = ({ isPartialDetail, direction, orderBy, setDirection, setOrderBy }) => {
    const checkoutContext = useContext(CheckoutContext)

    return (
        <TableHead className="m-table-head">
            <TableRow>
                <TableCell padding={'normal'} />
                {columns.map((headCell, index) => (
                    <TableCell key={index} align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={true}
                            children={headCell.label}
                            onclick={() => {
                                if (orderBy === headCell.id) {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy(headCell.id)
                                }
                            }}
                            active={orderBy === headCell.id}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
                {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && (
                    <TableCell key="price" align={'left'} padding={'normal'}>
                        <SortingMenu
                            disable={true}
                            children="Price"
                            onclick={() => {
                                if (orderBy === 'price') {
                                    setDirection(!direction)
                                } else {
                                    setOrderBy('price')
                                }
                            }}
                            active={orderBy === 'price'}
                            direction={direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                )}
                {checkoutContext.cartInformation.technicianViewType !== TechnicianViewType.None && <TableCell />}
                {checkoutContext.cartInformation.technicianViewType === TechnicianViewType.None && <TableCell align={'left'} padding={'normal'} />}
            </TableRow>
        </TableHead>
    )
}

const EnhancedTable: FC<EnhancedTableProps> = ({ isPartialDetail, data, addToCart, deletePart, editPart, showAddToCart, showEditDelete, partListName }) => {
    const [orderBy, setOrderBy] = React.useState('index')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const filteringData = data.filter((x) => !x.kitNo)

    React.useEffect(() => {
        setPage(0)
    }, [data.length])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    if (data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container partial-parts-table" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead isPartialDetail={isPartialDetail} orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />
                    <EnhancedTableBody
                        isPartialDetail={isPartialDetail}
                        partListName={partListName}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        deletePart={deletePart}
                        editPart={editPart}
                        addToCart={addToCart}
                        data={data}
                        direction={direction}
                        orderBy={orderBy}
                        showAddToCart={showAddToCart}
                        showEditDelete={showEditDelete}
                    />
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={filteringData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}

export default EnhancedTable
