import React from 'react'
import './Box.scss';

interface Props {
    className?: string;
}

function Box(props: React.PropsWithChildren<Props>) {
    return (
        <div className={props.className ? props.className + ' box': 'box'}>
            {props.children}
        </div>
    )
}

export default Box
