import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import FileInput from '../../../components/FileInput'
import Modal from '../../../components/Modal'
import StorageService from '../../../services/NewServices/StorageService'
import './MasterCatalog.scss'
import { FileModel } from './../../../services/NewServices/StorageService'

interface Props {
    visible: boolean
    selectedFile?: string
    submit: (drawingUrl: string) => void
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
}

function UploadImageModal(props: Props) {
    const [uploadFile, setUploadFile] = useState<FileModel[]>([])

    useEffect(() => {
        if (props.selectedFile) {
            setUploadFile([{ name: props.selectedFile, url: props.selectedFile, type: 'image' }])
        } else {
            setUploadFile([])
        }
    }, [props.selectedFile])

    const onChangeFile = async (file: File) => {
        try {
            if (file) {
                props.onLoading()
                const uploadFileService = new StorageService()
                const fileInfos = await uploadFileService.uploadMultiple(file as unknown as FileList)
                await setUploadFile(fileInfos)
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            props.onCompleted()
        }
    }

    return (
        <Modal
            visible={props.visible}
            primaryButtonText="Save"
            secondaryButtonText="Cancel"
            onClickSecondary={() => {
                props.closeModal()
            }}
            onClickPrimary={() => {
                if (uploadFile) {
                    props.submit(uploadFile[0].url)
                }
            }}
            title="Upload images"
        >
            <div className="modal-container">
                <FileInput
                    multiple
                    fileInputText="UPLOAD IMAGES"
                    handleChange={(event) => onChangeFile(event)}
                    isFileUploaded={uploadFile.length > 0}
                    name={uploadFile.map((value, index, array) => {
                        return value.name
                    })}
                />
            </div>
        </Modal>
    )
}

export default UploadImageModal
