import React from 'react'
import EquipmentCart from './EquipmentCart'
import './Equipments.scss'
import { NavLink } from 'react-router-dom'
import { EquipmentModel } from '../../../../../models/New/Equipment'

interface Props {
    data: EquipmentModel[]
}

function Equipments(props: Props) {
    return (
        <>
            {props.data.length > 0 ? (
                <div className="equipments-container">
                    <div className="equipments-content">
                        <div className="equipments-header">
                            <div className="title">My Equipment</div>
                            <NavLink to="/equipments">
                                <span className="go-to-all">View all equipment</span>
                            </NavLink>
                        </div>
                        <div className="equipments-body">
                            {props.data.length > 0 &&
                                props.data.map((item) => {
                                    return <EquipmentCart equipmentInfo={item} />
                                })}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}

export default Equipments
