import { useContext, useEffect, useRef, useState } from 'react'
import Button from '../../../../components/Button'
import PopoverItem from '../../../../components/PopoverItem'
import Icons, { IconType } from '../../../../Icon'
import { CustomerContactModel, UpdatePrimaryContact } from '../../../../models/New/Customer'
import HoverCard from './../../../../components/HoverCard/index'
import './ContactsMenu.scss'
import ContactModal from './Modals/ContactModal'
import { ContractActionTypes } from '..'
import { arraySort } from '../../../../utils/helpers'
import ContactService from './../../../../services/UiService/Contact'
import { ConfirmationContext } from '../../../../store/ConfirmationContext'

interface IProps {
    contacts: CustomerContactModel[]
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ContractActionTypes, data: CustomerContactModel) => void
}

function ContactsMenu(props: IProps) {
    const { confirm } = useContext(ConfirmationContext)
    const contactRef = useRef<CustomerContactModel>()
    const [contactModalVisible, setContactModalVisible] = useState<boolean>(false)

    async function setIsPrimary(isPrimary: boolean, id: string) {
        try {
            props.onLoading()
            const payload: UpdatePrimaryContact = {
                isPrimary: isPrimary,
            }
            const response = await ContactService.setIsPrimary(payload, id)
            props.onSuccess(ContractActionTypes.UPDATE_PRIMARY_CONTACT, response)
        } catch (error: any) {
            props.onCompleted() // Special case
        } finally {
            setContactModalVisible(false)
            // props.setContactVisible(false)
            props.onCompleted()
        }
    }

    const contactButtons = [
        {
            icon: IconType.Star,
            popoverContent: 'Remove primary contact',
            onClick: (contact: CustomerContactModel) => {
                setIsPrimary(!contact.isPrimary, contact.id)
            },
            visible: true,
            color: '#FDC00E',
        },
        {
            icon: IconType.StarBorder,
            popoverContent: 'Set as primary contact',
            onClick: (contact: CustomerContactModel) => {
                setIsPrimary(!contact.isPrimary, contact.id)
            },
            visible: false,
        },
        {
            icon: IconType.Pen,
            popoverContent: 'Edit',
            onClick: (contact: CustomerContactModel) => {
                setContactModalVisible(true)
                contactRef.current = contact
            },
        },
        {
            icon: IconType.NewTrash,
            popoverContent: 'Delete',
            onClick: (contact: CustomerContactModel) => {
                confirm({
                    modalTitle: 'Delete Contact?',
                    primaryButtonText: 'Delete',
                    buttonOrder: 'secondaryPrimary',
                    func: async (successCallback, errCallback) => {
                        try {
                            props.onLoading()
                            const contactId = contactRef.current?.id ? contactRef.current.id : ''
                            const response = await ContactService.delete(contactId)
                            successCallback()
                            props.onSuccess(ContractActionTypes.DELETE_CONTACT, response)
                        } catch (error: any) {
                            props.onCompleted() // Special case
                            errCallback(error)
                        } finally {
                            props.onCompleted()
                            contactRef.current = undefined
                        }
                    },
                })
                contactRef.current = contact
            },
        },
    ]

    useEffect(() => {}, [])

    const contactCard = (contact: CustomerContactModel) => {
        return (
            <HoverCard
                title={contact.firstName + ' ' + contact.lastName}
                rightMenu={contactButtons.map(
                    (button, index) =>
                        (button.visible === contact.isPrimary || (!button.visible && button.visible !== false)) && (
                            <div className="contact-cart-action">
                                <PopoverItem key={index} popoverContent={button.popoverContent}>
                                    <Icons
                                        color={button.color}
                                        onClick={() => {
                                            button.onClick(contact)
                                        }}
                                        type={button.icon}
                                    />
                                </PopoverItem>
                            </div>
                        )
                )}
            >
                <div className="card-row">
                    <div className="icon">
                        <Icons color={'#354A63'} type={IconType.UserReverse} />
                    </div>
                    <div className="row-content">
                        <span className="title">JOB TITLE</span>
                        <span className="value"> {contact.jobTitle || '-'}</span>
                    </div>
                </div>
                <div className="card-row">
                    <div className="icon">
                        <Icons color={'#354A63'} type={IconType.Phone} />
                    </div>
                    <div className="row-content">
                        <span className="title">PHONE</span>
                        <span className="value"> {contact.phone || '-'}</span>
                    </div>
                </div>
                <div className="card-row">
                    <div className="icon">
                        <Icons color={'#354A63'} type={IconType.Mail} />
                    </div>
                    <div className="row-content">
                        <span className="title">EMAIL</span>
                        <span className="value"> {contact.email || '-'}</span>
                    </div>
                </div>
                <div className="card-row">
                    <div className="icon">
                        <Icons color={'#354A63'} type={IconType.AddNote} />
                    </div>
                    <div className="row-content">
                        <span className="title">NOTES</span>
                        <span className="value"> {contact.notes || '-'}</span>
                    </div>
                </div>
            </HoverCard>
        )
    }

    return (
        <>
            <div className="contacts-container">
                <HoverCard
                    title={`Contacts (${props.contacts.length})`}
                    rightMenu={
                        <PopoverItem popoverContent="Add new">
                            <Button onClick={() => setContactModalVisible(true)} className="header-button">
                                <Icons type={IconType.BoldPlus} />
                            </Button>
                        </PopoverItem>
                    }
                >
                    {props.contacts.length === 0 ? (
                        <div className="no-data">
                            <span>Add your first contact!</span>
                            <Button onClick={() => setContactModalVisible(true)} className="add-button">
                                <Icons type={IconType.BoldPlus} />
                                Add contact
                            </Button>
                        </div>
                    ) : (
                        <div className="contacts">{arraySort(props.contacts, 'isPrimary', 'descending').map((item) => contactCard(item))}</div>
                    )}
                </HoverCard>
            </div>
            {contactModalVisible && (
                <ContactModal
                    onSuccess={props.onSuccess}
                    onLoading={props.onLoading}
                    onCompleted={props.onCompleted}
                    contact={contactRef.current ? contactRef.current : undefined}
                    visible={contactModalVisible}
                    closeModal={() => {
                        setContactModalVisible(false)
                        contactRef.current = undefined
                    }}
                    isAddPrimaryContact={false}
                />
            )}
        </>
    )
}

export default ContactsMenu
