import { useEffect, useState } from 'react'
import PopoverItem from '../../../../components/PopoverItem'
import { CustomerEquipmentLocationModel } from '../../../../models/Db/Customer'
import { EquipmentModel } from '../../../../models/New/Equipment'
import { calculateAge, standardDate } from '../../../../utils/helpers'
import './EquipmentInfoCart.scss'

interface Props {
    equipment: EquipmentModel
    flexWrap?: boolean
    handleEdit: () => void
}

function EquipmentInfoCart(props: Props) {
    const warrantyDate = props.equipment.warrantyStarts ? standardDate(props.equipment.warrantyStarts) : '-'
    const warrantyEndDate = props.equipment.warrantyEnds ? standardDate(props.equipment.warrantyEnds) : '-'
    const [address, setAddress] = useState<CustomerEquipmentLocationModel | undefined>(props.equipment.installationAddress || undefined)

    useEffect(() => {
        setAddress(props.equipment.installationAddress)
    }, [props.equipment.installationAddress])

    const warrantyInfo = () => {
        if (!props.equipment.warrantyStatus) {
            return (
                <div className="warranty-info">
                    <span className="add-button" onClick={props.handleEdit}>
                        +add warranty info
                    </span>
                </div>
            )
        } else {
            switch (props.equipment.warrantyStatus) {
                case 'Expired':
                    return (
                        <div className="warranty-info">
                            <div className="warranty expired">Warranty Expired</div>
                            <div className="date expired-date">
                                {warrantyDate}-{warrantyEndDate}
                            </div>
                        </div>
                    )
                case 'Active':
                    return (
                        <div className="warranty-info">
                            <div className="warranty active">Active Warranty</div>
                            <div className="date active-date">
                                {warrantyDate}-{warrantyEndDate}
                            </div>
                        </div>
                    )
            }
        }
    }
    const warrantyInfoClassName = () => {
        if (!props.equipment.warrantyStatus) {
            return 'no-info'
        } else {
            switch (props.equipment.warrantyStatus) {
                case 'Active':
                    return 'active'
                case 'Expired':
                    return 'expired'
            }
        }
    }

    return (
        <div className={`custom-equipment-info-cart-container ${props.flexWrap && 'custom-container-wrap'}`}>
            <div className="left-container">
                <div className="serial-box">
                    <span className="title">Serial number</span>
                    {props.equipment.serialNo ? (
                        <PopoverItem popoverContent={props.equipment.serialNo}>
                            <span className="value">{props.equipment.serialNo.slice(0, 15)}</span>
                        </PopoverItem>
                    ) : (
                        <span className="add-button" onClick={props.handleEdit}>
                            +add a serial number
                        </span>
                    )}
                </div>
                <div className="age-box">
                    <span className="title">
                        Equipment Age
                        <span className="add-button" onClick={props.handleEdit}>
                            +update
                        </span>
                    </span>
                    {props.equipment.installationDate ? (
                        <span className="value">{calculateAge(props.equipment.installationDate)}</span>
                    ) : (
                        <span className="add-button" onClick={props.handleEdit}>
                            +add
                        </span>
                    )}
                </div>
            </div>

            <div className="divider" />

            <div className="warranty-container">
                <div className={`warranty-box ${warrantyInfoClassName()}`}>
                    <span className="title">Warranty Info</span>
                    <div className="content">{warrantyInfo()}</div>
                    {props.equipment.warrantyStatus && (
                        <span className="add-button" onClick={props.handleEdit}>
                            +update
                        </span>
                    )}
                </div>
                <div className="installation">
                    <span className="title">Installation Date</span>
                    {props.equipment.installationDate ? (
                        <span className="value">{standardDate(props.equipment.installationDate)}</span>
                    ) : (
                        <span className="add-button" onClick={props.handleEdit}>
                            +add
                        </span>
                    )}
                </div>
            </div>

            {props.flexWrap ? <div className="horizontal-divider" /> : <div className="divider" />}

            <div className="address-box">
                <span className="title">Installation Address</span>
                {address?.street1 || address?.street1 || address?.city || address?.state || address?.country || address?.postcode ? (
                    <div className="content">
                        {address?.street1 && <span>{address?.street1}</span>}
                        {address?.street2 && <span>{address?.street2}</span>}
                        <div>
                            {address?.city && <span>{address?.city}, </span>}
                            {address?.state && <span>{address?.state}, </span>}
                            {address?.postcode && <span>{address?.postcode} </span>}
                        </div>
                        {address?.country && <span>{address?.country}</span>}
                    </div>
                ) : (
                    <span className="add-button" onClick={props.handleEdit}>
                        +add installation address
                    </span>
                )}
            </div>
        </div>
    )
}

export default EquipmentInfoCart
