import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { PaginationInputModel, PaginationResponseModel } from '../../models/BaseModel'
import { DocumentListModel } from '../../models/New/Document'
import { OrderModel } from '../../models/New/Order'
import { ActivityProductListModel, CreateProductModel, ProductCountWithActivityDateDto, ProductListModel, UpdateCustomersProductsModel, UpdateProductModel } from '../../models/New/Product'
import { ActivityModel } from '../../models/Ui/Activity'
import { CompanyModel } from '../../models/Ui/Company'

class ProductService {
    async getProductList(params: PaginationInputModel): Promise<PaginationResponseModel<ProductListModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetProductsList, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async getProductCustomers(id: string): Promise<CompanyModel[]> {
        try {
            const response = await MonitorApi.get(`/product/${id}/customers`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Customers not found!')
        }
    }

    async getProductDocuments(id: string): Promise<DocumentListModel[]> {
        try {
            const response = await MonitorApi.get(`/product/${id}/documents`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Documents not found!')
        }
    }

    async getProductActivities(id: string): Promise<ActivityModel[]> {
        try {
            const response = await MonitorApi.get(`/product/${id}/activities`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Activities not found!')
        }
    }

    async getProductOrders(id: string): Promise<OrderModel[]> {
        try {
            const response = await MonitorApi.get(`/product/${id}/orders`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Orders not found!')
        }
    }

    async getsForCustomerList(params: PaginationInputModel): Promise<PaginationResponseModel<ProductListModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.getsForCustomerList, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async createProducts(params: Omit<CreateProductModel, 'images' | 'documentIds'>): Promise<boolean> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateProducts, params, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async createProduct(params: CreateProductModel): Promise<boolean> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateProduct, params, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async delete(data: string[]): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteProduct, { data })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async update(data: UpdateProductModel, id: string): Promise<ProductListModel> {
        try {
            const response = await MonitorApi.patch(ServiceFunctionLookupTable.UpdateProduct + id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Update product model')
        }
    }

    async linkCustomersToProducts(data: UpdateCustomersProductsModel): Promise<boolean> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.LinkCustomersToProducts, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async getProductsForActivity(): Promise<ActivityProductListModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetProductsForActivity, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get products error')
        }
    }

    async getProductDetail(id: string): Promise<ProductListModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetProductDetail + id, {})
            return response as any
        } catch (error: any) {
            throw new Error('Get product detail error')
        }
    }

    async unlinkProductDocument(productId: string, documentId: string): Promise<ProductListModel> {
        try {
            const response = await MonitorApi.post(`/product/${productId}/document/${documentId}/unlink`, {}, {})
            return response as any
        } catch (error: any) {
            throw new Error('a problem of unlinking document for product')
        }
    }

    async getLinkedActivityCount(productId: string): Promise<ProductCountWithActivityDateDto[]> {
        try {
            const response = await MonitorApi.get(`product/${productId}/get-linked-activity-counts`, {})
            return response as any
        } catch (error: any) {
            throw new Error('get linked activity counts error for products')
        }
    }
}

const productService = new ProductService()
export default productService
