export interface DocumentSummaryModel {
    id: string
    name: string
    file: string
}

export interface DocumentModel {
    id: string
    name: string
    file: string
    manufacturer: DocumentManufacturerModel
}

interface DocumentManufacturerModel {
    id: string
    name: string
    email: string
}

export interface CreateDocumentModel {
    name: string
    file: string
}

export interface UpdateDocumentModel {
    documentId: string
    name: string
    url: string
    description?: string
    type?: string
}

export interface DeleteDocumentModel {
    documentId: string
}

export interface PushPullEquipmentDocumentModel {
    documentId: string
    pushEquipmentIds: string[]
    pullEquipmentIds: string[]
}

export interface PullEquipmentDocumentModel {
    documentId: string
    equipmentId: string
}

export interface PushPullDocumentModel {
    equipmentId: string
    pushDocumentIds: string[]
    pullDocumentIds: string[]
}

export enum FileType {
    Image = 'image',
    Pdf = 'pdf',
}
