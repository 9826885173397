import { useState } from 'react'
import CustomerOrderListTable, { ActionButtons } from '../../../../../components/CustomerOrderListTable'
import { IconType } from '../../../../../Icon'
import { OrderModel } from '../../../../../models/New/Order'
import SendFollowUpModal from '../../Components/SendFollowUpModal'
import './style.scss'

interface Props {
    orderData: OrderModel[]
    visible: boolean
}

interface SendFollowUpModalInfoModel {
    id: string
    refNo: string
    visible: boolean
}

function QuotesTab(props: React.PropsWithChildren<Props>) {
    const { orderData, visible } = props
    const [sendFollowUpModalInfo, setSendFollowUpModalInfo] = useState<SendFollowUpModalInfoModel | undefined>(undefined)

    const quotesActionButtons: ActionButtons[] = [
        {
            icon: IconType.RigthOk,
            popoverContent: 'Re-quote or Purchase',
            onClick: (data: OrderModel) => {
                setSendFollowUpModalInfo({ visible: true, id: data.id, refNo: data.transactionNumber })
            },
            type: 'link',
            tooltipPlacement: 'left',
        },
    ]

    return (
        <div style={{ display: visible ? 'block' : 'none' }}>
            {<CustomerOrderListTable actionButtons={quotesActionButtons} isQuote data={orderData} />}
            {sendFollowUpModalInfo && (
                <SendFollowUpModal
                    refNo={sendFollowUpModalInfo.refNo}
                    orderId={sendFollowUpModalInfo.id}
                    closeModal={() => setSendFollowUpModalInfo(undefined)}
                    visible={sendFollowUpModalInfo?.visible}
                />
            )}
        </div>
    )
}

export default QuotesTab
