import { CustomerDetailModel } from '../../../../models/New/Customer'
import './Overview.scss'
import { OrderModel } from '../../../../models/New/Order'
import { ActivityModel } from '../../../../models/Ui/Activity'
import ContactInfo from './Components/Cards/ContactInfo'
import AssignedManager from './Components/Cards/AssignedManager'
import NextActivity from './Components/Cards/NextActivity'
import Orders from './Components/Cards/Orders'
import InstallationCount from './Components/Cards/InstallationCount'
import { useNavigate } from 'react-router'
import { Tabs } from '../../../../models/Ui/Tab'
import { useContext } from 'react'
import { CheckoutContext } from '../../../../store/NewCheckoutContext/CheckoutContext'
import { SearchPartContext } from '../../../../store/SearchPartContext'
import { ICartInformation, IQuickCartCustomer, TechnicianViewType } from '../../../../store/models'
import { ContractBasicDto } from '../../../../models/Ui/Contract'

interface IProps {
    activities: ActivityModel[]
    orders: OrderModel[]
    customer: CustomerDetailModel
    contracts: ContractBasicDto[]
    handleUpdateCompany: () => void
    handleLinkEquipment: () => void
    handleCreateActivity: () => void
    handleUpdateAddress: () => void
}

function Overview(props: IProps) {
    const { activities, orders, customer, contracts, handleCreateActivity, handleUpdateCompany, handleUpdateAddress, handleLinkEquipment } = props
    const { onChangeSearchedCustomerId } = useContext(SearchPartContext)
    const { setQuickCartInfo, handleCartInformation } = useContext(CheckoutContext)
    const navigate = useNavigate()

    const handleSeeAll = (tabId: Tabs) => {
        navigate(`/customer/${customer.id}?activeTab=${tabId}`)
    }
    const handleQuickCart = async () => {
        await onChangeSearchedCustomerId(customer.id, customer.name)
        setQuickCartInfo({
            id: customer.id,
            name: customer.name,
        } as IQuickCartCustomer)
        handleCartInformation({
            technicianViewType: TechnicianViewType.Quick,
            quickCartShow: true,
            purchaseForCustomerId: customer.id,
            purchaseForCustomerName: customer.name,
        } as ICartInformation)
        navigate('/quick')
    }
    return (
        <div className="overview-container">
            <div className="overview-container-top">
                <div className="left">
                    <ContactInfo handleUpdateAddress={handleUpdateAddress} customer={customer} />
                </div>
                <div className="right">
                    <AssignedManager handleUpdateCompany={handleUpdateCompany} customer={customer} />
                </div>
            </div>
            <div className="overview-container-bottom">
                <NextActivity handleCreateActivity={handleCreateActivity} handleSeeAll={handleSeeAll} activities={activities} />
                <InstallationCount handleLinkEquipment={handleLinkEquipment} equipmentsCount={customer.equipments.length} contractsCount={contracts?.length} />
                <Orders handleQuickCart={handleQuickCart} handleSeeAll={handleSeeAll} orders={orders} />
            </div>
        </div>
    )
}

export default Overview
