import Modal from '../../../../../components/Modal'
import './style.scss'
import TextArea from './../../../../../components/TextArea/index'
import { toast } from 'react-toastify'
import { useState } from 'react'
import OrderService from './../../../../../services/UiService/Order'

interface Props {
    refNo: string
    orderId: string
    visible: boolean
    closeModal: () => void
}

function SendFollowUpModal(props: Props) {
    const [message, setMessage] = useState('')

    async function sendMessage() {
        try {
            await OrderService.sendFollowUp(message, props.orderId)
            toast.success('Action completed successfully!')
        } catch (error: any) {
            console.error(error)
        } finally {
            props.closeModal()
        }
    }

    const clearForm = () => {}

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    // const isValidForm = () => !message

    return (
        <Modal
            className="send-follow-up-modal"
            visible={props.visible}
            title="Send follow up?"
            primaryButtonText="Send"
            onClickPrimary={sendMessage}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            // primaryDisabled={!!isValidForm}
        >
            <div className="modal-line">
                <span>You are sending a follow up for RFQ {props.refNo}. Add a message for your vendor to let them know what you need.</span>
            </div>
            <br />
            <div className="modal-line">
                <TextArea autoSize value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Start typing to add a message" />
            </div>
        </Modal>
    )
}

export default SendFollowUpModal
