import React, { useCallback, useContext, useEffect, useState } from 'react'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import WelcomeBack from './Components/WelcomeBack'
import Network from './Components/Network'
import Activities from './Components/Tasks'
import Products from './Components/Products'
import Equipments from './Components/Equipments'
import Footer from './Components/Footer'
import CompanyService from './../../../services/UiService/Company'
import { CompanyDashboardModel } from '../../../models/Ui/Company'
import { toast } from 'react-toastify'
import { CustomerManufacturerContext } from '../../../store/CustomerDashboardContext'

function Home() {
    const { selectedManufacturerInfo } = useContext(CustomerManufacturerContext)
    const [loading, setLoading] = useState(false)
    const [dashboardInfo, setDashboardInfo] = useState<CompanyDashboardModel | null>(null)

    const getCustomerDashboardCallback = useCallback(async () => {
        try {
            const companyService = new CompanyService()
            if (selectedManufacturerInfo) {
                const response = await companyService.getCompanyDashboard(selectedManufacturerInfo.id)
                setDashboardInfo(response)
            }
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [selectedManufacturerInfo])

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getCustomerDashboardCallback()
            setLoading(false)
        }

        loadComponent()
    }, [getCustomerDashboardCallback])

    return (
        <PageWrapper>
            {loading && <Loading />}
            {dashboardInfo && (
                <>
                    <WelcomeBack userName={dashboardInfo.user.firstName} />
                    <Activities data={dashboardInfo.activities} />
                    <Network
                        notifications={dashboardInfo.notifications.filter((note) => !note.isRead).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime())}
                        accountManager={dashboardInfo.accountManager}
                    />
                    <Products data={dashboardInfo.products} />
                    <Equipments data={dashboardInfo.equipments} />
                    <Footer manufacturerInfo={selectedManufacturerInfo} />
                </>
            )}
        </PageWrapper>
    )
}

export default Home
