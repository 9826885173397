import { Radio } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import BasicDatePicker from '../../../../../../components/DatePicker'
import BasicDropdown from '../../../../../../components/Dropdown/BasicDropdown'
import Icons, { IconType } from '../../../../../../Icon'
import { ActivityFrequencyType, ActivityFrequencyUnitType } from '../../../../../../models/Ui/Activity'
import { formatQuantity } from '../../../../../../utils/helpers'
import './style.scss'

export const frequencyTypes = [
    {
        id: 1,
        text: ActivityFrequencyType.OneTime,
    },
    {
        id: 2,
        text: ActivityFrequencyType.Daily,
    },
    {
        id: 3,
        text: ActivityFrequencyType.Weekly,
    },
    {
        id: 4,
        text: ActivityFrequencyType.Monthly,
    },
    {
        id: 5,
        text: ActivityFrequencyType.Annually,
    },
    {
        id: 6,
        text: ActivityFrequencyType.Custom,
    },
]

export const activityFrequencyUnitList = [
    {
        id: 1,
        text: ActivityFrequencyUnitType.Days,
    },
    {
        id: 2,
        text: ActivityFrequencyUnitType.Weeks,
    },
    {
        id: 3,
        text: ActivityFrequencyUnitType.Months,
    },
    {
        id: 4,
        text: ActivityFrequencyUnitType.Years,
    },
]

enum RadioButtonsTypes {
    On = 'On',
    After = 'After',
}

export enum FrequencySideOptionPositions {
    Left = 'left',
    Right = 'right',
}

interface Props {
    freqType: ActivityFrequencyType | null
    onChangeFreqType: (value: ActivityFrequencyType) => void
    duration: number
    setDuration: (val: number) => void
    sideOptionPosition?: FrequencySideOptionPositions
    disabled?: boolean
    repeatCount: number
    setRepeatCount: (val: number) => void
    repeatUnit: ActivityFrequencyUnitType
    setRepeatUnit: (val: ActivityFrequencyUnitType) => void
    dueDate: Date | null
    setDueDate: (val: Date) => void
    startDate: Date | null
    onClickSave?: () => void
    onClickCancel?: () => void
    isSaveable?: boolean
    onChangeActivity?: (active: boolean) => void
}

const ActivityFrequency: FC<Props> = ({
    freqType,
    onChangeFreqType,
    duration,
    setDuration,
    sideOptionPosition = FrequencySideOptionPositions.Right,
    disabled,
    repeatCount,
    setRepeatCount,
    repeatUnit,
    setRepeatUnit,
    dueDate,
    setDueDate,
    startDate,
    onClickSave,
    onClickCancel,
    isSaveable,
    onChangeActivity,
}) => {
    const dropdownContainerRef = useRef<HTMLDivElement>(null)
    const [showOptions, setShowOptions] = useState(false)
    const [radioValue, setRadioValue] = useState<RadioButtonsTypes>(RadioButtonsTypes.After)

    useEffect(() => {
        if (onChangeActivity) onChangeActivity(showOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOptions])

    useEffect(() => {
        function handleClickOutside(event: any) {
            const muiDropdowns = document.querySelector('.MuiList-root')
            if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target) && !muiDropdowns?.contains(event.target)) {
                setShowOptions(false)
                if (onClickCancel) {
                    onClickCancel()
                }
            }
        }
        document.addEventListener('mousedown', (e) => handleClickOutside(e))
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [onClickCancel])

    const setSideOptionClassname = (defaultClassname: string) => {
        defaultClassname = `${defaultClassname} ${sideOptionPosition}`
        return defaultClassname
    }

    const setDateFilter = (datePickerValue: Date): boolean => {
        if (startDate) {
            return datePickerValue >= startDate
        }

        return true
    }

    const radioButtonsRenderer = () => {
        return (
            <Fragment>
                <div className="option-radio-group">
                    <Radio checked={radioValue === RadioButtonsTypes.On} onClick={() => setRadioValue(RadioButtonsTypes.On)} /> On
                    {radioValue === RadioButtonsTypes.On && (
                        <BasicDatePicker
                            filterDate={setDateFilter}
                            popperPlacement="left-start"
                            startDate={dueDate}
                            onDateChange={(val) => setDueDate(val)}
                            customInput={<Icons type={IconType.Calendar} />}
                        />
                    )}
                </div>
                <div className="option-radio-group">
                    <Radio checked={radioValue === RadioButtonsTypes.After} onClick={() => setRadioValue(RadioButtonsTypes.After)} /> After
                    {radioValue === RadioButtonsTypes.After && (
                        <Fragment>
                            <input
                                type="number"
                                onBlur={() => {
                                    if (!duration || duration < 1) {
                                        setDuration(1)
                                    }
                                }}
                                value={duration}
                                onChange={(e) => setDuration(formatQuantity(e.target.value))}
                            />
                            Times
                        </Fragment>
                    )}
                </div>
            </Fragment>
        )
    }

    const saveCancelButtonRenderer = () => {
        if (onClickSave || onClickCancel) {
            return (
                <div className="side-option-footer">
                    <div onClick={onClickSave} className={`save-button ${!isSaveable && 'disabled-button'}`}>
                        Save
                    </div>
                    <div onClick={onClickCancel} className="cancel-button">
                        Cancel
                    </div>
                </div>
            )
        }

        return <Fragment />
    }

    const optionSideRenderer = () => {
        let output = <Fragment />
        let sideOptionClassname = 'frequency-side-option'
        sideOptionClassname = setSideOptionClassname(sideOptionClassname)
        let headerText = freqType !== ActivityFrequencyType.Custom ? 'End Repeat' : 'Repeat every'

        if (freqType === ActivityFrequencyType.Daily) {
            output = (
                <div className={`${sideOptionClassname} daily-option`}>
                    <div className="side-option-header">{headerText}</div>
                    <div className="side-option-body">{radioButtonsRenderer()}</div>
                    {saveCancelButtonRenderer()}
                </div>
            )
        } else if (freqType === ActivityFrequencyType.Weekly) {
            output = (
                <div className={`${sideOptionClassname} weekly-option`}>
                    <div className="side-option-header">{headerText}</div>
                    <div className="side-option-body">{radioButtonsRenderer()}</div>
                    {saveCancelButtonRenderer()}
                </div>
            )
        } else if (freqType === ActivityFrequencyType.Monthly) {
            output = (
                <div className={`${sideOptionClassname} monthly-option`}>
                    <div className="side-option-header">{headerText}</div>
                    <div className="side-option-body">{radioButtonsRenderer()}</div>
                    {saveCancelButtonRenderer()}
                </div>
            )
        } else if (freqType === ActivityFrequencyType.Annually) {
            output = (
                <div className={`${sideOptionClassname} annually-option`}>
                    <div className="side-option-header">{headerText}</div>
                    <div className="side-option-body">{radioButtonsRenderer()}</div>
                    {saveCancelButtonRenderer()}
                </div>
            )
        } else if (freqType === ActivityFrequencyType.Custom) {
            output = (
                <div className={`${sideOptionClassname} custom-option`}>
                    <div className="side-option-header">{headerText}</div>
                    <div className="side-option-body">
                        <div className="frequency-setter">
                            <input
                                type="number"
                                onBlur={() => {
                                    if (!repeatCount || repeatCount < 1) {
                                        setRepeatCount(1)
                                    }
                                }}
                                value={repeatCount}
                                onChange={(e) => setRepeatCount(formatQuantity(e.target.value))}
                            />
                            <BasicDropdown
                                menuItems={activityFrequencyUnitList}
                                onChange={(data) => setRepeatUnit(data.text as ActivityFrequencyUnitType)}
                                selectedItem={{ text: repeatUnit, id: -1 }}
                                defaultValue={activityFrequencyUnitList[0].text}
                            />
                        </div>
                        {radioButtonsRenderer()}
                    </div>
                    {saveCancelButtonRenderer()}
                </div>
            )
        }

        return output
    }

    return (
        <div className="activity-frequency-container" ref={dropdownContainerRef}>
            <div
                className={`selected-one ${!freqType && !showOptions && 'empty-freq'} ${showOptions && 'focused-dropdown'} ${disabled ? 'disabled-dropdown' : 'enabled-dropdown'}`}
                onClick={() => {
                    if (!disabled) {
                        setShowOptions(!showOptions)
                    }
                }}
            >
                <Icons type={IconType.Recycle} />
                {freqType || 'Frequency'}
            </div>
            {showOptions && optionSideRenderer()}
            {showOptions && (
                <div className="freq-options">
                    {frequencyTypes.map((child) => (
                        <div className={`freq-option ${freqType === child.text && 'active-one'}`} key={child.id} onClick={() => onChangeFreqType(child.text)}>
                            {child.text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default ActivityFrequency
