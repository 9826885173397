// import dayjs from "dayjs"
import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { TeamActionTypes } from '../..'
import ChipButton from '../../../../components/ChipButton'
import HoverCard from '../../../../components/HoverCard'
import { CompanyModel } from '../../../../models/Ui/Company'
import { HubspotProviderLogModel, HubspotProviderModel } from '../../../../models/Ui/Hubspot'
import { QueueItemProgress } from '../../../../models/Ui/Queue'
import HubspotService from '../../../../services/UiService/HubspotService'
import QueueService from '../../../../services/UiService/Queue'
import { arraySort } from '../../../../utils/helpers'
import './style.scss'

interface Props {
    company: CompanyModel
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: TeamActionTypes, data?: any) => void
    onError: (error: any) => void
}

function HubspotIntegrationTab(props: React.PropsWithChildren<Props>) {
    const { onLoading, onError, onSuccess, onCompleted } = props
    const [logs, setLogs] = useState<HubspotProviderLogModel | null>(null)
    // const [errors, setErrors] = useState<string[]>([])
    const [providerLogs, setProviderLogs] = useState<HubspotProviderModel[]>([])
    const [isSyncButtonEnabled, setIsSyncButtonEnabled] = useState(false)

    const getQueues = async () => {
        try {
            const res = await QueueService.getAll()
            const last24HoursAndHasntErrorQueues = res.filter(
                (child) => child.progress !== QueueItemProgress.ErrorOccurred && new Date(child.creationDate).getTime() >= new Date().getTime() - 24 * 60 * 60 * 1000
            )
            const doneOnes = last24HoursAndHasntErrorQueues.filter((child) => child.progress === QueueItemProgress.Done)
            let percentage: string | number = 0
            if (last24HoursAndHasntErrorQueues.length) {
                percentage = ((doneOnes.length / last24HoursAndHasntErrorQueues.length) * 100).toFixed(2)
            }
            // eslint-disable-next-line eqeqeq
            toast.success(`${percentage}% is done. ${percentage != 100 ? 'Sync is still running.' : ''}`)
            setIsSyncButtonEnabled(!last24HoursAndHasntErrorQueues.some((child) => child.progress === QueueItemProgress.InProgress || child.progress === QueueItemProgress.Waiting))
        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    useEffect(() => {
        getQueues()
    }, [])

    // function downloadFile(filename: string, text: string) {
    //     var element = document.createElement('a');
    //     element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    //     element.setAttribute('download', filename);

    //     element.style.display = 'none';
    //     document.body.appendChild(element);

    //     element.click();

    //     document.body.removeChild(element);
    // }

    async function getUnitsHandler() {
        try {
            // onLoading()
            toast.success('Started synchronization')
            const response = await HubspotService.getUnits(props.company.hsLastSyncTime)
            if (response && response.lastSyncTime) {
                // if (response.logs) {
                //     setLogs(response.logs)
                // } /* else not needed */
                // if (response.errors) {
                //     setErrors(response.errors)
                // } /* else not needed */

                // if (response.logs?.length > 0 && response.errors?.length > 0) {
                //     downloadFile(`sync-report-${dayjs().toISOString()}.txt`, response?.errors.join().replaceAll('\n', '').replaceAll(',', '\n') + response?.logs.join().replaceAll('\n', '').replaceAll(',', '\n'))
                // } /* else not needed */
                getProviderLogCallback()
                onSuccess(TeamActionTypes.HUBSPOT_INTEGRATION_RESPONSE, response)
            } else {
                throw new Error(response)
            }
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }
    async function getProviderLog(id: string) {
        try {
            onLoading()
            const response = await HubspotService.getProviderLog(id)
            setLogs(response)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }
    const getProviderLogCallback = useCallback(async () => {
        try {
            onLoading()
            const response = await HubspotService.getByProviderType()
            setProviderLogs(arraySort(response, 'creationDate', 'descending'))
            onSuccess(TeamActionTypes.HUBSPOT_INTEGRATION_RESPONSE, response)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }, [onCompleted, onError, onLoading, onSuccess])

    useEffect(() => {
        getProviderLogCallback()
    }, [getProviderLogCallback])

    return (
        <div className="content-container">
            <HoverCard>
                <div className="hubspot-integration-tab-container">
                    {/* Last sync time: {props.company.hsLastSyncTime ? dayjs(props.company.hsLastSyncTime).format('DD MMM YYYY, HH:mm') : 'Not synced yet!'} */}
                    <ChipButton style={{ cursor: !isSyncButtonEnabled ? 'not-allowed' : 'pointer' }} className="green" onClick={() => getUnitsHandler()} disabled={!isSyncButtonEnabled}>
                        Start Sync
                    </ChipButton>
                    {/* {errors.length > 0 ? errors.map((error, index) => <React.Fragment key={index}><span>{error}</span><br /></React.Fragment>) : 'No errors found!'}
                    {logs.length > 0 ? logs.map((log, index) => <React.Fragment key={index}><span>{log}</span><br /></React.Fragment>) : 'No logs found!'} */}
                    <div className="log-name-container">
                        {providerLogs.map((p) => {
                            return (
                                <div className={`title ${logs?.id === p.id && 'selected'}`} onClick={() => getProviderLog(p.id)}>
                                    {p.name}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="hubspot-integration-log-container">
                    <div className="body">
                        <div className="body-item">
                            <span className="title title-logs">Logs</span>
                            {logs?.log && JSON.parse(logs?.log)?.logs.map((p: any) => <div className="value value-logs">{p}</div>)}
                        </div>
                        <div className="body-item">
                            <div className="title title-errors">Errors</div>
                            {logs?.log && JSON.parse(logs?.log)?.errors.map((p: any) => <div className="value value-errors">{p}</div>)}
                        </div>
                    </div>
                </div>
            </HoverCard>
        </div>
    )
}

export default HubspotIntegrationTab
