import { ReactNode, FC, createContext, useState, useEffect } from 'react'
import { EquipmentSubPartsModel, SearchPartsViewModel } from '../pages/ManufacturerPages/QuickCartPage/SearchParts/searchParts.types'
import PartService from '../services/UiService/Part'
import { GetPartDto, SearchInput } from '../models/Ui/Part'
import { ActivityCustomerListModel } from '../models/Ui/Company'

interface SearchPartContextProviderProps {
    children: ReactNode
}

const useContext = () => {
    const [searchedText, setSearchedText] = useState<string>('')
    const [filterText, setFilterText] = useState<string>('')
    const [isSearchMode, setIsSearchMode] = useState<boolean>(true)
    const [searchedOnes, setSearchedOnes] = useState<SearchPartsViewModel[]>([]) // came from serverside
    const [filteredOnes, setFilteredOnes] = useState<SearchPartsViewModel[]>([]) // filtered by client
    const [isFilteredByClient, setIsFilteredByClient] = useState(false)
    const [isEmptyResult, setIsEmptyResult] = useState(false)
    const [loading, setLoading] = useState(false)
    const [lastSearchedText, setLastSearchedText] = useState('')
    const [searchedEquipmentsParts, setSearchedEquipmentsParts] = useState<GetPartDto[]>([])
    const [filteredSearchedEquipmentsParts, setFilteredSearchedEquipmentsParts] = useState<GetPartDto[]>([])
    const [searchedCustomerId, setSearchedCustomerId] = useState<string | null>(null)
    const [searchedCustomerName, setSearchedCustomerName] = useState<string | null>(null)
    const [customerOptions, setCustomerOptions] = useState<ActivityCustomerListModel[]>([])

    const setCustomerOptionsList = async (customerList: ActivityCustomerListModel[]) => {
        setCustomerOptions([...customerList])
    }

    const onChangeSearchedCustomerId = async (id: string | null, name: string | null) => {
        setSearchedCustomerName(name)
        setSearchedCustomerId(id)
        searchByPartNameOrPartNumberOrCustomer({ searchKey: searchedText, customerId: id })
    }

    const searchByPartNameOrPartNumberOrCustomer = async (args: SearchInput) => {
        if (!args.searchKey) {
            setFilteredSearchedEquipmentsParts([])
            setSearchedEquipmentsParts([])
            setSearchedOnes([])
            setFilteredOnes([])
            setIsEmptyResult(false)
            setIsSearchMode(true)
            return
        }

        setLoading(true)
        PartService.search(args).then((response) => {
            setSearchedEquipmentsParts(response)
            setFilteredSearchedEquipmentsParts(response)
            let result = response.reduce(function (r, a) {
                r[a.equipment.name] = r[a.equipment.name] || []
                r[a.equipment.name].push(a)
                return r
            }, Object.create(null))

            let list: SearchPartsViewModel[]
            list = Object.keys(result).map((value) => {
                const items = result[value] as GetPartDto[]
                let item: SearchPartsViewModel = {
                    parts: items.map((partDto) => {
                        return {
                            partName: partDto.name,
                            customersCompanyName: partDto?.customer?.name,
                            drawing: partDto.equipment.drawingUrl,
                            partNumber: partDto.partNumber,
                            price: partDto.price,
                            refNo: '',
                            serialNumber: partDto.equipment.serialNo,
                            id: partDto.id,
                            equipmentId: partDto.equipment.id,
                        } as EquipmentSubPartsModel
                    }),
                    equipmentGroupName: value,
                }
                return item
            })

            setSearchedOnes([...list])
            setFilteredOnes([...list])
            setIsEmptyResult(list.length === 0)
            setIsSearchMode(list.length === 0 && !args.customerId)
            setLoading(false)
            setLastSearchedText(searchedText)
        })
    }

    // useEffect(() => {
    //     if (!isSearchMode) {
    //         setIsFilteredByClient(filteredOnes.length !== searchedOnes.length)
    //     }
    // }, [searchedOnes.length, filteredOnes.length, isSearchMode])

    useEffect(() => {
        if (!isSearchMode) {
            setIsFilteredByClient(filteredSearchedEquipmentsParts.length !== searchedEquipmentsParts.length)
        }
    }, [searchedEquipmentsParts.length, filteredSearchedEquipmentsParts.length, isSearchMode])

    const reset = async () => {
        setSearchedOnes([])
        setFilteredOnes([])
        setSearchedEquipmentsParts([])
        setFilteredSearchedEquipmentsParts([])
        setLoading(false)
        setIsEmptyResult(false)
        setIsSearchMode(true)
        setLastSearchedText('')
        setSearchedText('')
        setFilterText('')
        setIsFilteredByClient(false)
        setIsEmptyResult(false)
        setSearchedCustomerId(null)
        setSearchedCustomerName(null)
        setCustomerOptions([])
    }
    const handleFilter = (search: string) => {
        // RULES: ekipmanların içinde partlar dönülürse bunu kullan

        // const data = [...searchedOnes]

        // if (search) {
        //     const list: SearchPartsViewModel[] = []
        //     const toLower = search.toLowerCase()
        //     data.forEach((value) => {
        //         const parts = value.parts.filter(
        //             (x) =>
        //                 value.equipmentGroupName.toLowerCase().includes(toLower) ||
        //                 (x.partName && x.partName.toLowerCase().includes(toLower)) ||
        //                 (x.partNumber && x.partNumber.toString().toLowerCase().includes(toLower)) ||
        //                 (x.customersCompanyName && x.customersCompanyName.toString().toLowerCase().includes(toLower)) ||
        //                 (x.serialNumber && x.serialNumber.toString().toLowerCase().includes(toLower))
        //         )
        //         if (parts.length > 0) {
        //             list.push({
        //                 equipmentGroupName: value.equipmentGroupName,
        //                 parts,
        //             })
        //         }
        //     })
        //     setFilteredOnes([...list])
        //     setIsEmptyResult(list.length === 0)
        // } else {
        //     setFilteredSearchedEquipmentsParts([...searchedEquipmentsParts])
        //     setIsEmptyResult(searchedEquipmentsParts.length === 0)
        // }

        var data = [...searchedEquipmentsParts]

        if (search) {
            var list: GetPartDto[] = []
            const toLower = search.toLowerCase()

            const parts = data.filter(
                (x) =>
                    (x.name && x.name.toLowerCase().includes(toLower)) ||
                    (x.partNumber && x.partNumber.toString().toLowerCase().includes(toLower)) ||
                    (x.equipment.name && x.equipment.name.toString().toLowerCase().includes(toLower)) ||
                    (x.equipment.serialNo && x.equipment.serialNo.toString().toLowerCase().includes(toLower))
            )

            if (parts.length > 0) {
                list = parts
            }
            setFilteredSearchedEquipmentsParts([...list])
            setIsEmptyResult(list.length === 0)
        } else {
            setFilteredSearchedEquipmentsParts([...searchedEquipmentsParts])
            setIsEmptyResult(searchedEquipmentsParts.length === 0)
        }
    }

    const handleSearch = async () => {
        setFilterText('')
        await searchByPartNameOrPartNumberOrCustomer({ searchKey: searchedText, customerId: searchedCustomerId })
    }

    const handleFilterText = (value: string) => {
        if (isSearchMode) return
        setFilterText(value)
        handleFilter(value)
    }

    const handleSearchText = (value: string) => {
        setSearchedText(value)
    }

    return {
        customerOptions,
        searchedCustomerId,
        searchedCustomerName,
        onChangeSearchedCustomerId,
        searchedEquipmentsParts,
        lastSearchedText,
        handleSearch,
        handleFilterText,
        handleSearchText,
        filterText,
        isFilteredByClient,
        searchedText,
        setSearchedText,
        loading,
        setLoading,
        isEmptyResult,
        reset,
        handleFilter,
        filteredOnes,
        filteredSearchedEquipmentsParts,
        searchedOnes,
        isSearchMode,
        setIsSearchMode,
        setCustomerOptionsList,
    }
}

export const SearchPartContext = createContext({} as ReturnType<typeof useContext>)

const SearchPartContextProvider: FC<SearchPartContextProviderProps> = ({ children }) => {
    return <SearchPartContext.Provider value={useContext()}>{children}</SearchPartContext.Provider>
}

export default SearchPartContextProvider
