import { useEffect, useState } from 'react'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import TextArea from './../../../../../components/TextArea/index'
import FileInput from '../../../../../components/FileInput'
import './UpdateShippingModal.scss'
import { OrderDetailsModel, UpdateOrderDetailModel } from '../../../../../models/New/Order'
import OrderService from './../../../../../services/UiService/Order'
import CopyButton from '../../../../../components/CopyButton'

interface Props {
    data: OrderDetailsModel
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: () => void
    onError: (error: any) => void
}
interface DocumentFileProps {
    fileName: string
    fileUrl: string
}

function UpdateShippingModal(props: Props) {
    const { onLoading, onError, onSuccess, onCompleted, visible } = props

    const [trackingNumber, setTrackingNumber] = useState<string>('')
    const [notes, setNotes] = useState<string>('')
    const [documentFile, setDocumentFile] = useState<DocumentFileProps | undefined>(undefined)

    useEffect(() => {
        setTrackingNumber(props.data?.trackingNumber)
        setNotes(props.data?.shipmentNotes)
        setDocumentFile({ fileName: props.data?.shipmentAttachmentName, fileUrl: props.data.shipmentAttachmentUrl })
    }, [props.data])

    async function updateOrderHandler() {
        try {
            onLoading()
            const data: UpdateOrderDetailModel = {
                trackingNumber: trackingNumber,
                shipmentNotes: notes,
                shipmentAttachmentName: documentFile?.fileName,
                shipmentAttachmentUrl: documentFile?.fileUrl,
            }
            await OrderService.updateOrderDetail(props.data.id, data)
            onSuccess()
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        return trackingNumber?.length > 3 && (trackingNumber !== props.data.trackingNumber || notes !== props.data.shipmentNotes || documentFile?.fileUrl !== props.data.shipmentAttachmentUrl)
    }

    const clearForm = () => {
        setTrackingNumber('')
        setNotes('')
        setDocumentFile(undefined)
    }

    const clickPrimary = () => {
        updateOrderHandler()
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <Modal
            className="update-shipping-information-modal"
            visible={visible}
            title="View shipping information"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
            buttonOrder="secondaryPrimary"
        >
            <div className="modal-line">
                <span className="line-title">TRACKING NUMBER</span>
                <div className="line-value">
                    <Input value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                    <CopyButton className="update-shipping-copy" disabled={trackingNumber ? false : true} value={trackingNumber} />
                </div>
            </div>
            <div className="modal-line">
                <span className="line-title">NOTES</span>
                <TextArea value={notes} onChange={(event) => setNotes(event.target.value)} />
            </div>
            <div className="sub-title">Documents</div>
            <div className="modal-line">
                <span className="line-title">UPLOAD DOCUMENTS</span>
                <FileInput
                    handleChange={(event) =>
                        setDocumentFile({
                            fileName: event.name,
                            fileUrl: event,
                        })
                    }
                    isFileUploaded={documentFile ? true : false}
                    name={documentFile ? documentFile?.fileName : ''}
                />
            </div>
        </Modal>
    )
}

export default UpdateShippingModal
