import ProductInfoCart from './ProductInfoCart'
import './NewProductDetails.scss'
import { ProductImageModel, ProductListModel, UpdateProductModel } from '../../../../../../models/New/Product'
import ProductImageBox from '../../../ProductImageBox'
import ProductService from '../../../../../../services/UiService/Product'
import StorageService from '../../../../../../services/NewServices/StorageService'
import React, { useContext } from 'react'
import { ConfirmationContext } from '../../../../../../store/ConfirmationContext'

interface NewProductDetailsProps {
    product: ProductListModel
    onLoading: () => void
    onCompleted: () => void
    onSuccess: () => void
    onError: (error: any) => void
    handleEdit: () => void
    onAddDemand: () => void
    onClickManageCustomer: () => void
    activityLengthChanged: boolean
}

function NewProductDetails(props: NewProductDetailsProps) {
    const uploadFileServices = new StorageService()
    const { confirm } = useContext(ConfirmationContext)
    const deleteProductImageHandler = async (imageUrl: string) => {
        confirm({
            modalTitle: 'Deleting Product Image?',
            // titleColor: '#D84315',
            primaryButtonText: 'Cancel',
            doesPrimaryButtonSubmit: false,
            secondaryButtonText: 'Delete',
            func: async (successCallback, errorCallback) => {
                try {
                    props.onLoading()
                    const products: UpdateProductModel = props.product
                    const newImages = products.images && products?.images.filter((image) => image.imageUrl !== imageUrl)
                    await ProductService.update({ images: newImages }, props.product.id)
                    props.onCompleted()
                    props.onSuccess()
                    successCallback()
                } catch (error: any) {
                    props.onError(error)
                    errorCallback(error)
                } finally {
                    props.onCompleted()
                }
            },
            contentNode: (
                <div className="modal-line">
                    <span className="warning-text">Are you sure you would like to delete the product's image?</span>
                </div>
            ),
        })
    }

    const updateProductImageHandler = async (imageUrl: string, isFeatured: boolean) => {
        try {
            props.onLoading()
            const images: ProductImageModel[] = props.product.images ? props.product.images : []
            const newImages = images.map((image: ProductImageModel) => {
                if (isFeatured) {
                    if (image.imageUrl === imageUrl) return { ...image, isFeatured }
                    else return { ...image, isFeatured: !isFeatured }
                }
                return { ...image, isFeatured: false }
            })
            await ProductService.update({ images: newImages }, props.product.id)
            props.onSuccess()
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }

    const createProductImageHandler = async (fileList: FileList) => {
        try {
            props.onLoading()
            const uploadedFiles = await uploadFileServices.uploadMultiple(fileList)
            const newProductImages: ProductImageModel[] = uploadedFiles.map((file) => ({
                imageUrl: file.url,
                name: file.name,
                isFeatured: false,
            }))
            await ProductService.update(
                {
                    images: [...(props.product.images ?? []), ...newProductImages],
                },
                props.product.id
            )
            props.onSuccess()
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }
    return (
        <div className="product-details-tab-container">
            {typeof props.product.images !== 'undefined' && props.product.images.length > 0 ? (
                <>
                    <div className="product-info-cart">
                        <ProductImageBox
                            title="Images"
                            images={props.product.images}
                            updateProductImageHandler={updateProductImageHandler}
                            deleteProductImageHandler={deleteProductImageHandler}
                            createProductImageHandler={createProductImageHandler}
                        />
                        <ProductInfoCart
                            activityLengthChanged={props.activityLengthChanged}
                            onClickManageCustomer={props.onClickManageCustomer}
                            handleEdit={props.handleEdit}
                            product={props.product}
                            onAddDemand={props.onAddDemand}
                        />
                    </div>
                    <div className="detail-info">
                        <div className="detail-info__row">
                            <div className="detail-info__row__title">Product Category</div>
                            {props.product.category ? (
                                <span className="detail-info__row__value">{props.product.category}</span>
                            ) : (
                                <span className="detail-info__row__add" onClick={props.handleEdit}>
                                    +add
                                </span>
                            )}
                        </div>
                        <div className="detail-info__row">
                            <div className="detail-info__row__title">Description</div>
                            {props.product.description ? (
                                <span className="detail-info__row__value">{props.product.description}</span>
                            ) : (
                                <span className="detail-info__row__add" onClick={props.handleEdit} style={{ cursor: 'pointer' }}>
                                    +add
                                </span>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="product-info-cart">
                        <ProductInfoCart
                            activityLengthChanged={props.activityLengthChanged}
                            onClickManageCustomer={props.onClickManageCustomer}
                            handleEdit={props.handleEdit}
                            product={props.product}
                            onAddDemand={props.onAddDemand}
                        />
                    </div>
                    <div className="product-image-description">
                        <ProductImageBox
                            title="Images"
                            images={props.product.images || []}
                            updateProductImageHandler={updateProductImageHandler}
                            createProductImageHandler={createProductImageHandler}
                            deleteProductImageHandler={deleteProductImageHandler}
                        />
                        <div className="detail-info">
                            <div className="detail-info__row">
                                <div className="detail-info__row__title">Product Category</div>
                                {props.product.category ? (
                                    <span className="detail-info__row__value">{props.product.category}</span>
                                ) : (
                                    <span className="detail-info__row__add" onClick={props.handleEdit}>
                                        +add
                                    </span>
                                )}
                            </div>
                            <div className="detail-info__row">
                                <div className="detail-info__row__title">Description</div>
                                {props.product.description ? (
                                    <span className="detail-info__row__value">{props.product.description}</span>
                                ) : (
                                    <span className="detail-info__row__add" onClick={props.handleEdit} style={{ cursor: 'pointer' }}>
                                        +add
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default NewProductDetails
