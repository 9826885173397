import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { CreateTagModel } from '../../models/New/Tag'

export default class TagService {
    async createTag(data: CreateTagModel): Promise<CreateTagModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateTag, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async delete(id: string): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteTag + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
}
