import { useEffect, useState } from 'react'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import { NewUpdateEquipmentModel } from '../../../../../../models/Ui/Equipment'
import EquipmentService from '../../../../../../services/UiService/Equipment'
import { ParentModel } from '../EditAssemblyModal'
import './style.scss'
import TextArea from './../../../../../../components/TextArea/index'
import BasicDropdown, { IDropdownItem } from '../../../../../../components/Dropdown/BasicDropdown'
import { LinkTemplateFromAssemblyModel } from '../../../../../../models/New/Equipment'
import { EquipmentActionTypes } from '../../../../../../models/Ui/Common'
import SearchableDropdown from '../../../../../../components/Dropdown/SearchableDropdown'
import { IDropdownOption } from '../../../../../../components/Dropdown/dropdown.interface'

enum CreateSubEquipmentType {
    newEquipment = 'Add new',
    linkedEquipment = 'Link from template equipment',
}
interface Props {
    equipment: any
    parent: ParentModel
    rootEquipmentId: string
    visible: boolean
    parentList: ParentModel[]
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel) => void
    onError: (error: any) => void
}

function CreateAssemblyModal(props: Props) {
    const { parentList, onLoading, onError, onSuccess, onCompleted } = props

    const [partNumber, setPartNumber] = useState<string>('')
    const [equipmentName, setEquipmentName] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [brand, setBrand] = useState<string>('')
    const [parentId, setParentId] = useState<string>()
    const [templateEquipmentList, setTemplateEquipmentList] = useState<IDropdownOption[]>([])
    const [selectedTemplateEquipment, setSelectedTemplateEquipment] = useState<IDropdownOption | undefined>(undefined)
    const [selectedCreateSubEquipmentType, setSelectedCreateSubEquipmentType] = useState<IDropdownItem>({ id: 0, text: 'Add new' })

    const createSubEquipmentTypes: IDropdownItem[] = [
        { id: 0, text: CreateSubEquipmentType.newEquipment },
        { id: 1, text: CreateSubEquipmentType.linkedEquipment },
    ]
    useEffect(() => {
        if (selectedCreateSubEquipmentType.text === CreateSubEquipmentType.linkedEquipment && templateEquipmentList.length === 0) {
            getEquipments()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCreateSubEquipmentType])

    async function getEquipments() {
        onLoading()
        try {
            const equipments = await EquipmentService.getTemplatesForManufacturer({ page: 0, size: 9999 })
            const equipmentsRender = equipments.content
                .filter((equipment) => !equipment.parentId && equipment.id !== props.equipment.id)
                .map((equipment) => {
                    return { id: equipment.id, label: equipment.name }
                })
            setTemplateEquipmentList(equipmentsRender)
        } catch (error: any) {
            onError(error)
        } finally {
            onCompleted()
        }
    }

    async function createAssemblyHandler() {
        try {
            onLoading()
            const data = {
                name: equipmentName,
                partNumber: partNumber,
                description: description,
                brand: brand,
                parentId: parentId ?? props.parent.id,
                showList: false,
            }
            const newEquipmentTree = await EquipmentService.createEquipment(data)
            onSuccess(EquipmentActionTypes.UPDATE_ASSEMBLY, { ...props.equipment, equipmentTree: JSON.stringify(newEquipmentTree) })
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    async function linkedAssemblyHandler() {
        try {
            onLoading()
            const payload: LinkTemplateFromAssemblyModel = {
                parentId: parentId!,
                childId: selectedTemplateEquipment?.id as string,
            }
            const newEquipmentTree = await EquipmentService.linkTemplateToAssembly(props.rootEquipmentId, payload)
            onSuccess(EquipmentActionTypes.UPDATE_ASSEMBLY, { ...props.equipment, equipmentTree: JSON.stringify(newEquipmentTree) })
        } catch (error: any) {
            onError(error)
        } finally {
            props.closeModal()
            onCompleted()
        }
    }

    const isValidForm = () => {
        if (selectedCreateSubEquipmentType.text === CreateSubEquipmentType.newEquipment) {
            return equipmentName.length > 3 && partNumber.length !== 0 && parentId
        } else {
            return selectedTemplateEquipment?.id && parentId
        }
    }

    const clearForm = () => {
        setPartNumber('')
        setEquipmentName('')
        setDescription('')
        setParentId('')
    }

    const clickPrimary = () => {
        if (selectedCreateSubEquipmentType.text === CreateSubEquipmentType.newEquipment) {
            createAssemblyHandler()
        } else {
            linkedAssemblyHandler()
        }
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <Modal
            className="create-assembly-modal"
            visible={props.visible}
            title="Add sub-assembly"
            primaryButtonText="Save"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
        >
            <div className="modal-line">
                <BasicDropdown
                    label="SELECT SUB-ASSEMBLY"
                    menuItems={createSubEquipmentTypes}
                    onChange={(data) => {
                        setSelectedCreateSubEquipmentType(data)
                    }}
                    selectedItem={selectedCreateSubEquipmentType}
                    required
                />
            </div>
            {selectedCreateSubEquipmentType.text === CreateSubEquipmentType.newEquipment && (
                <>
                    <div className="modal-line">
                        <Input value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="PART NUMBER" required />
                    </div>
                    <div className="modal-line">
                        <Input value={equipmentName} onChange={(e) => setEquipmentName(e.target.value)} placeholder="EQUIPMENT NAME" required />
                    </div>
                    <div className="modal-line">
                        <Input value={brand} onChange={(e) => setBrand(e.target.value)} placeholder="BRAND" />
                    </div>
                </>
            )}
            {selectedCreateSubEquipmentType.text === CreateSubEquipmentType.linkedEquipment && (
                <div className="modal-line">
                    <SearchableDropdown
                        label="SELECT TEMPLATE"
                        data={templateEquipmentList}
                        onChange={(event, data) => {
                            setSelectedTemplateEquipment(data)
                        }}
                        selectedItem={selectedTemplateEquipment}
                        required
                    />
                </div>
            )}
            <div className="modal-line">
                <SearchableDropdown
                    label="PARENT"
                    data={parentList.map((parent) => {
                        return { id: parent.id, label: parent.text }
                    })}
                    onChange={(event, data) => {
                        setParentId(data?.id)
                    }}
                    selectedItem={{ id: parentId as string, label: parentList.find((item) => item.id === parentId)?.text || '' }}
                    required
                />
            </div>

            {selectedCreateSubEquipmentType.text === CreateSubEquipmentType.newEquipment && (
                <>
                    <div className="modal-line">
                        <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" />
                    </div>
                    <span className="label">This sub-assembly will also be saved as a template in your equipment list.</span>
                </>
            )}
        </Modal>
    )
}

export default CreateAssemblyModal
