import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { ActivityAssigneListModel, CreateUserModel, UpdateUserModel, UserModel } from '../../models/Ui/User'

export default class UserService {
    async get(): Promise<UserModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.User, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async getCompanyUsers(): Promise<UserModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetCompanyUsers, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async update(data: UpdateUserModel): Promise<UserModel> {
        try {
            let response
            if (data.id) {
                response = await MonitorApi.put(ServiceFunctionLookupTable.User + data.id, data, {})
            } else {
                response = await MonitorApi.put(ServiceFunctionLookupTable.User, data, {})
            }
            return response as any
        } catch (error: any) {
            console.error(error)
            throw error
        }
    }

    async create(data: CreateUserModel): Promise<UserModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.User, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw error
        }
    }

    async delete(userId: string): Promise<UserModel> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.User + userId, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw error
        }
    }

    async getUsersForActivity(): Promise<ActivityAssigneListModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.GetUsersForActivity, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Get users error')
        }
    }
    async resendVerification(id: string): Promise<void> {
        try {
            const response = await MonitorApi.post(`user/${id}/send-verification-email`, {}, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw error
        }
    }
}
