import { useCallback, useContext, useEffect, useState } from 'react'
import Icons, { IconType } from '../../../../Icon'
import './ProductPreview.scss'
import { Row, RowItem } from './components'
import { Color } from '../../../../models/enums'
import { NewUpdateProductModel, ProductImageModel, ProductListModel } from '../../../../models/New/Product'
import ProductService from '../../../../services/UiService/Product'
import { DocumentListModel } from '../../../../models/New/Document'
import { useNavigate } from 'react-router'
import ProductEditModal from '../../ProductDetail/Modals/ProductEditModal'
import DocumentService from '../../../../services/UiService/Document'
import { ProductActionTypes } from '../../ProductDetail/actiontypes.enum'
import { ConfirmationContext } from '../../../../store/ConfirmationContext'
import { priceFormatter } from '../../../../utils/helpers'
import PopoverItem from '../../../../components/PopoverItem'
import LabelWithLine from '../../../../components/LabelWithLine'

interface IProductPartialUpdateBox {
    closeModal: () => void
    selectedProductId: string
    onLoading: () => void
    onCompleted: () => void
    onError: (error: any) => void
    onSuccess: (action?: ProductActionTypes, data?: NewUpdateProductModel) => void
}

const ProductPreview = (props: IProductPartialUpdateBox) => {
    const { confirm } = useContext(ConfirmationContext)
    const [productDetail, setProductDetail] = useState<ProductListModel>()
    const [productImage, setProductImage] = useState<ProductImageModel | null>(null)
    const [, setDocuments] = useState<DocumentListModel[]>([])
    const navigate = useNavigate()
    const [editProductModalVisible, setEditProductModalVisible] = useState<boolean>(false)

    const getProductDetailCallback = useCallback(async () => {
        try {
            const productDetail = await ProductService.getProductDetail(props.selectedProductId)
            setProductDetail(productDetail)
            setProductImage(productDetail.images?.find((child) => child.isFeatured) ?? null)
        } catch (error: any) {
            console.log(error.message)
        }
    }, [props.selectedProductId])

    const getDocumentsCallback = useCallback(async () => {
        try {
            const documents = await DocumentService.getDocuments({ page: 0, size: 10000 })
            setDocuments(documents.content)
        } catch (error: any) {
            props.onError(error)
        }
    }, [props])

    useEffect(() => {
        getProductDetailCallback()
        getDocumentsCallback()
    }, [getProductDetailCallback, getDocumentsCallback, editProductModalVisible])

    const onClickDelete = () => {
        confirm({
            modalTitle: 'Deleting Product?',
            // titleColor: '#D84315',
            primaryButtonText: 'Cancel',
            doesPrimaryButtonSubmit: false,
            secondaryButtonText: 'Delete',
            func: async (successCallback, errorCallback) => {
                try {
                    props.onLoading()
                    await ProductService.delete([productDetail?.id!])
                    props.onCompleted()
                    successCallback()
                    navigate('/products')
                    props.onSuccess(ProductActionTypes.DELETE_PRODUCT)
                } catch (error: any) {
                    props.onError(error)
                    errorCallback(error)
                } finally {
                    props.closeModal()
                }
            },
            contentNode: (
                <div className="modal-line">
                    <span className="warning-text">Are you sure you would like to delete the product?</span>
                </div>
            ),
        })
    }

    return (
        <>
            <div className="product-partial-update">
                <div className="header">
                    <div className="top-section">
                        <div className={`icon-section ${productImage?.imageUrl && 'with-image'}`}>
                            <PopoverItem popoverContent="Edit">
                                <span onClick={() => setEditProductModalVisible(true)}>
                                    <Icons type={IconType.Pen} color={Color.grey600} />
                                </span>
                            </PopoverItem>
                            <PopoverItem popoverContent="Delete">
                                <span onClick={onClickDelete}>
                                    <Icons type={IconType.Trash} color={Color.grey600} />
                                </span>
                            </PopoverItem>
                            <PopoverItem popoverContent="View Details">
                                <span onClick={() => navigate(`/products/${productDetail?.id}`)}>
                                    <Icons type={IconType.ChevronRight} color={Color.grey600} />
                                </span>
                            </PopoverItem>
                            <PopoverItem popoverContent="Close">
                                <span onClick={props.closeModal}>
                                    <Icons type={IconType.Close} color={Color.grey600} />
                                </span>
                            </PopoverItem>
                        </div>
                    </div>
                    {productImage?.imageUrl && (
                        <div
                            className="image-section"
                            style={{
                                backgroundImage: productImage?.imageUrl ? `url(${productImage?.imageUrl})` : undefined,
                            }}
                        >
                            <a href={productImage?.imageUrl} target="_blank" rel="noreferrer">
                                <Icons type={IconType.ImageViewArrow} />
                            </a>
                        </div>
                    )}
                    <div className={`description-section ${!productImage && 'no-image'}`}>
                        <div className="product-name">{productDetail?.name}</div>
                    </div>
                </div>
                <div className="part-number-section">
                    {productDetail?.brand && <div className="brand">{productDetail?.brand}</div>}
                    {productDetail?.partNumber && (
                        <div className="part-number">
                            <span className="definition">Part Number:</span>
                            <b>{productDetail?.partNumber}</b>
                        </div>
                    )}
                </div>
                <div className="body">
                    <section className="body__section">
                        <LabelWithLine labelText="Details" />
                        {productDetail?.category && (
                            <Row>
                                <RowItem label="Category" value={productDetail?.category} />
                            </Row>
                        )}
                        {(productDetail?.uom || productDetail?.pkg || productDetail?.price) && (
                            <Row>
                                {productDetail.uom && <RowItem label="UOM/Pkg" value={`${productDetail?.uom}/${productDetail?.pkg}`} />}
                                {productDetail.price && (
                                    <RowItem
                                        classname="unit-price"
                                        label="Unit Price"
                                        value={
                                            <span>
                                                {priceFormatter(productDetail?.price ?? 0)} <span className="slash-price">/{productDetail?.pkg}</span>
                                            </span>
                                        }
                                    />
                                )}
                            </Row>
                        )}
                        {productDetail?.description && (
                            <Row>
                                <RowItem label="Description" value={productDetail?.description} />
                            </Row>
                        )}
                    </section>
                </div>
            </div>
            {editProductModalVisible && (
                <ProductEditModal
                    product={productDetail ?? ({} as ProductListModel)}
                    closeModal={() => setEditProductModalVisible(false)}
                    selectedProductId={productDetail?.id!}
                    visible={editProductModalVisible}
                    onLoading={props.onLoading}
                    onCompleted={props.onCompleted}
                    onError={props.onError}
                    onSuccess={props.onSuccess}
                />
            )}
        </>
    )
}

export default ProductPreview
