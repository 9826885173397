import React, { useEffect, useState } from 'react'
import Box from '../../components/Box'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Color, MaxCharacter, Size } from '../../models/enums'
import './index.scss'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import CompanyService from '../../services/UiService/Company'
import { InvitationStatus } from '../../models/Ui/Company'
import { isValidEmail, isValidPassword } from '../../utils/helpers'

const CompanyVerifitation: any = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
}

function CompanyVerifitationPage() {
    let { id } = useParams()
    const [params] = useSearchParams()
    const [isNew, setNew] = useState(params.get('isNew') === 'true')
    const [isAccept, setAccept] = useState(params.get('isAccept') === 'true')


    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ show: false, type: '', text: '' })
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [formData, setFormData] = useState(CompanyVerifitation)
    const companyService = new CompanyService()

    useEffect(() => {
        if (!isNew) {
            companyService.customerVerification({
                validationKey: id!,
                status: isAccept ? InvitationStatus.Accept : InvitationStatus.Reject, ...formData,
            }).then(() => {
                setLoading(true)
            })
        } else {
            if (!isAccept) {
                companyService.customerVerification({
                    validationKey: id!,
                    status: InvitationStatus.Reject, ...formData,
                }).then(() => {
                    setLoading(false)
                    setMessage({ show: true, type: 'success', text: 'Accep Invitation' })
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, type: string) {
        const data = { ...formData, [type]: e.target.value }

        setFormData(data)

        if (isValidEmail(data.email)
            && data.password === data.confirmPassword
            && isValidPassword(data.password)
            && data.firstName.length >= 2
            && data.lastName.length >= 2) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }

    }

    const handleAccept = () => {
        setLoading(true)

        companyService.customerVerification({
            validationKey: id,
            status: isAccept ? InvitationStatus.Accept : InvitationStatus.Reject, ...formData,
        }).then(() => {
            setLoading(false)
            setAccept(true)
            setNew(false)
            setMessage({ show: true, type: 'success', text: 'Accep Invitation' })
        })
    }
    return (
        <div className='sign-up-page sign-up-container'>
            <div className='form-wrapper'>
                <Box className='login-form'>
                    {!isAccept ? (
                        <>
                            <span className='brand-title' style={{ color: Color.red500 }}>
                                Invitation Rejected
                            </span>
                            <Button>
                                <Link to='/login'>Back to login</Link>
                            </Button>
                        </>
                    ) : (
                        <>
                            {isNew ? (
                                <>
                                    <span className='brand-title'>Create Account</span>
                                    <div className='description'>Please fill in the information to accept the
                                        invitation.
                                    </div>
                                    <div className='group-label'>User information</div>
                                    <Input type='email' value={formData.email}
                                        onChange={(e) => handleOnChange(e, 'email')} placeholder='EMAIL ADDRESS'
                                        max={MaxCharacter.email}
                                        size={Size.lg} />
                                    <Input type='text' value={formData.firstName}
                                        onChange={(e) => handleOnChange(e, 'firstName')} placeholder='FIRST NAME'
                                        max={MaxCharacter.name}
                                        size={Size.lg} />
                                    <Input type='text' value={formData.lastName}
                                        onChange={(e) => handleOnChange(e, 'lastName')} placeholder='LAST NAME'
                                        max={MaxCharacter.name}
                                        size={Size.lg} />
                                    <Input type='password' value={formData.password}
                                        onChange={(e) => handleOnChange(e, 'password')} placeholder='PASSWORD'
                                        size={Size.lg} />


                                    <Input
                                        type='password'
                                        value={formData.confirmPassword}
                                        onChange={(e) => handleOnChange(e, 'confirmPassword')}
                                        placeholder='CONFIRM PASSWORD'
                                        size={Size.lg}
                                    />


                                    <div className={'sign-up-password-hint'}>
                                        <ul>
                                            <li className={'password-hint-item'}>At least 8 characters
                                            </li>
                                            <li className={'password-hint-item'}>A mixture of both uppercase and
                                                lowercase
                                                letters.
                                            </li>
                                            <li className={'password-hint-item'}>A mixture of letters and numbers.
                                            </li>
                                            <li className={'password-hint-item'}>Inclusion of at least one special
                                                character
                                                (!,@,#,?)
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    {loading && <span className='loading-span' />}
                                    {message.show &&
                                        (message.type === 'success' ? (
                                            <div className='message success'>{message.text || 'Success...'}</div>
                                        ) : (
                                            message.type === 'error' &&
                                            <div className='message error'>{message.text || 'Error'}</div>
                                        ))}
                                    <Button disabled={buttonDisabled} onClick={handleAccept}>
                                        Save and accept
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <span className='email-message'>
                                        <p><strong> Almost done...</strong></p>
                                        <span>We just send you an email at <strong>{formData.email}</strong>. </span>
                                        <span>Please go to your email and verify your account.</span>
                                    </span>
                                    <Button>
                                        <Link to='/login'>Back to login</Link>
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </div>
        </div>
    )
}

export default CompanyVerifitationPage
