import { getApiUrl } from './helper'
import Interceptors from './Interceptors'
import HttpClient from './HttpClient'

// const url = 'https://monittor-dev.ep65l0igu8bne.us-west-2.cs.amazonlightsail.com/'
const url = getApiUrl()
// const url = 'http://localhost:4000';

const options = {
    requestInterceptors: [Interceptors.spinnerForRequest, Interceptors.manufacturerIdForRequest, Interceptors.bugsnagRequest],
    responseInterceptors: [Interceptors.spinnerForResponse, Interceptors.errorHandlerForResponse],
}

export { url }

export const MonitorApi = new HttpClient(url, options)
