import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import './ReCaptcha.scss'

interface IProps {
    referance: any
    handleClick: () => void
    className?: string
}

function ReCaptcha(props: React.PropsWithChildren<IProps>) {
    let defaultClassName = 're-captcha'
    const siteKey = process.env.REACT_APP_GOOGLE_SITE_KEY!

    return <ReCAPTCHA className={props.className ? props.className : defaultClassName} sitekey={siteKey} ref={props.referance} onChange={props.handleClick} />
}

export default ReCaptcha
