import { FC } from 'react'
import SearchableDropdown from '../../../../../../components/Dropdown/SearchableDropdown'
import { ActivityCustomerListModel } from '../../../../../../models/Ui/Company'
import './style.scss'

interface Props {
    selectedCustomerId: string
    onChangeCustomer: (val: string) => void
    customerList: ActivityCustomerListModel[]
    disabled?: boolean
}

const ActivityCustomer: FC<Props> = ({ selectedCustomerId, onChangeCustomer, customerList, disabled = false }) => {
    const setSelectedItem = () =>
        selectedCustomerId
            ? {
                  id: selectedCustomerId,
                  label: customerList.find((child) => child.id === selectedCustomerId)?.name ?? '',
              }
            : {
                  id: '',
                  label: '',
              }

    const setSelectedCustomerLogoUrl = () => customerList.find((child) => child.id === selectedCustomerId)?.logoUrl

    return (
        <div className={`activity-customer-container ${setSelectedCustomerLogoUrl() && 'customer-with-logo'}`}>
            {setSelectedCustomerLogoUrl() && <div className="customer-logo" style={{ backgroundImage: `url(${setSelectedCustomerLogoUrl()})` }} />}
            <SearchableDropdown
                className={`activity-customer-dropdown ${selectedCustomerId && 'disabled-list-menu'}`}
                label={!selectedCustomerId ? 'SELECT A CUSTOMER TO LINK' : undefined}
                data={
                    !selectedCustomerId
                        ? customerList.map((customer) => ({
                              id: customer.id,
                              label: customer.name,
                          }))
                        : []
                }
                onChange={(event, data) => {
                    onChangeCustomer(data?.id ?? '')
                }}
                selectedItem={setSelectedItem()}
                disabled={disabled}
            />
        </div>
    )
}

export default ActivityCustomer
