import React, { useContext, useEffect, useRef, useState } from 'react'
import './CustomerPageWrapper.scss'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { BreadcrumbModel } from '../../components/Breadcrumb'
// import AuthService from '../../services/UiService/Auth'
import NotificationPopup from './NotificationPopup'
import { CustomerManufacturerContext } from '../../store/CustomerDashboardContext'
import Icons, { IconType } from '../../Icon'
import DropdownButton from '../../components/DropdownButton'
import Button from '../../components/Button'
import { Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from '@mui/material'
import { STATIC_LOGO } from '../CustomerPages/Home/const'
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import CompanyService from '../../services/UiService/Company'
import CustomerCart from '../../components/CustomerCart/CustomerCart'
import { CustomerCheckoutContext } from '../../store/CustomerCheckoutContext/CustomerCheckoutContext'

interface Props {
    breadcrumb?: BreadcrumbModel[]
    headerInfo?: string
    name: string
    onLogout: () => void
    onSetting: () => void
}

function CustomerPageWrapper(props: React.PropsWithChildren<Props>) {
    const checkoutContext = useContext(CustomerCheckoutContext)
    const { handleSelect, handleManufacturerList, manufacturerList, selectedManufacturerInfo } = useContext(CustomerManufacturerContext)
    const [visibleAccountSwitcher, setVisibleAccountSwitcher] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [visibleAccountMenu, setVisibleAccountMenu] = useState(false)
    // const auth = new AuthService().getAuthUser()
    const accountWrapperRef = useRef<any>(null)

    useEffect(() => {
        async function loadComponent() {
            const customerService = new CompanyService()
            if (manufacturerList.length === 0) {
                const response = await customerService.getManufacturers()
                handleManufacturerList(response)
            }
        }
        loadComponent()
    }, [handleManufacturerList, handleSelect, manufacturerList.length])

    const location = useLocation()

    useEffect(() => {
        if (selectedManufacturerInfo) {
            localStorage.setItem('selectedManufacturer', JSON.stringify(selectedManufacturerInfo))
        }
        function changeVisibilityAccountSwitcherHandler(event: any) {
            if (event.ctrlKey && event.altKey && event.key.toLowerCase() === 'l') {
                setVisibleAccountSwitcher(!visibleAccountSwitcher)
            } /* else not needed */
        }

        function handleClickOutside(event: any) {
            if (accountWrapperRef.current && !accountWrapperRef.current.contains(event.target)) {
                setVisibleAccountMenu(false)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('click', handleClickOutside)
        document.addEventListener('keydown', changeVisibilityAccountSwitcherHandler)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('keydown', changeVisibilityAccountSwitcherHandler)
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('click', handleClickOutside)
        }
    }, [visibleAccountSwitcher, selectedManufacturerInfo])

    return (
        <div className="customer-page">
            <div className="customer-page-header">
                <div className="header-left">
                    <Link to="/">
                        {/* {props.headerInfo && <h1>{props.headerInfo}</h1>}
                        {props.headerInfo && (
                            <div className="left-bottom">
                                <span className="powered">POWERED BY</span>
                                <img
                                    className={props.headerInfo ? 'logo-mini' : 'logo-margin'}
                                    height={props.headerInfo ? '' : 24}
                                    src={props.headerInfo ? '/mini-logo.png' : '/logo.png'}
                                    alt="monittor"
                                /> */}
                        {/* {/* <img className='logo-margin' height={24} src={"/logo.png"} alt="monittor" /> */}
                        {/* </div> */}
                        {/* )} */}
                        <Avatar src={selectedManufacturerInfo?.logoUrl ?? STATIC_LOGO} alt="Dashboard" />
                    </Link>
                    {manufacturerList?.length === 1 ? (
                        <span className="manufacturer-name">{selectedManufacturerInfo?.name}</span>
                    ) : (
                        <DropdownButton
                            disabled={location.pathname === '/checkoutPage'}
                            selected={selectedManufacturerInfo?.name || ''}
                            className="manufacturer-name"
                            actions={manufacturerList.map((p, index) => {
                                return {
                                    text: p.name,
                                    onClick: () => handleSelect(p),
                                }
                            })}
                        />
                    )}
                </div>
                <div className="header-right">
                    <div className="right-notification">
                        <NotificationPopup />
                    </div>
                    <div className="cart-button-container" onClick={() => checkoutContext.toggleCartView()}>
                        <Button>
                            <Icons className="icon" type={IconType.Cart} />
                        </Button>
                        {checkoutContext.getCount() > 0 && <div className="order-counter">{checkoutContext.getCount()}</div>}
                    </div>
                    <NavLink to="/settings">
                        <Tooltip title="Settings">
                            <div className="action-button">
                                <FontAwesomeIcon className="icon" icon={faCog} />
                            </div>
                        </Tooltip>
                    </NavLink>
                    <NavLink onClick={props.onLogout} to="/login">
                        <Tooltip title="Log out">
                            <div className="action-button">
                                <FontAwesomeIcon className="icon" icon={faSignOutAlt} />
                            </div>
                        </Tooltip>
                    </NavLink>
                </div>
            </div>
            <div className="customer-page-tab">
                <div className="group">
                    <NavLink to="/">
                        <span>Home</span>
                    </NavLink>
                    <NavLink to="/equipments">
                        <span>My Equipment</span>
                    </NavLink>
                    <NavLink to="/products">
                        <span>My Products</span>
                    </NavLink>
                </div>
                <div className="group">
                    <NavLink to="/activity">
                        <span>Activities</span>
                    </NavLink>
                    <NavLink to="/orders">
                        <span>Transactions</span>
                    </NavLink>
                </div>
            </div>
            <div className="page-content">
                <div style={{ width: checkoutContext.cartInformation.cartShow ? '72%' : '100%' }} className="page-main animation">
                    {props.children}
                </div>
                <div style={{ width: checkoutContext.cartInformation.cartShow ? '28%' : 0 }} className="cart-content-container animation">
                    <div className="cart-content-wrapper">
                        <CustomerCart />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerPageWrapper
