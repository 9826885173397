export enum ServiceFunctionLookupTable {
    // Auth
    Login = 'auth/login',
    SignUp = 'auth/register/company',
    Register = 'auth/register/user',
    ForgotPassword = 'auth/forgot-password',
    ChangePassword = 'auth/change-password',
    ResetPassword = '/auth/reset-password',
    MailVerification = '/auth/mail-verification',

    //Company
    Company = '/company/',
    GetCompanyUsers = '/company/users?isDeleted=false',
    isMailExist = '/company/exists',
    CompanyCustomer = '/company/customer',
    UpdateCompanyCustomer = '/company/customer/',
    CustomerForManufacturer = '/company/customerForManufacturer',
    InviteCustomer = '/company/inviteCustomer/',
    RemindeCustomerInvite = '/company/remindeCustomerInvite/',
    CustomerVerification = '/company/customer-verification',
    GetManufacturers = 'company/manufacturers',
    GetCustomersForActivity = 'company/activity/customers',
    CompanyDashboard = '/company/dashboard',
    LinkToEquipment = '/company/linkToEquipment',

    // Customer
    GetCustomersForManufacturer = '/company/customersForManufacturer',
    CustomerDetailForManufacturer = '/company/customerDetailForManufacturer/',
    CustomerDetailForCustomer = '/company/customerDetailForCustomer/',
    updateCustomerInformationForManufacturer = '/company/customerInformationForManufacturer/',
    //User
    User = '/user/',
    UserVerification = '/user/verification',
    GetUsersForActivity = '/user/activity/assignees',

    // Manufacturer

    // CustomEquipment
    GetCustomEquipment = '/equipment/',

    // Assembly

    // Tag
    CreateTag = '/tag',
    DeleteTag = '/tag/',

    // Image
    CreateImage = '/image',
    UpdateImage = '/image/',
    DeleteImage = '/image/',

    // Equipment
    getTemplatesForManufacturer = '/equipment/templatesForManufacturer',
    getCustomsForManufacturer = '/equipment/customsForManufacturer',
    CreateEquipment = '/equipment',
    FindEquipmentById = '/equipment/',
    UpdateEquipment = '/equipment/template/',
    CustomForCustomer = '/equipment/customsForCustomer',
    UpdateCustom = '/equipment/custom/',
    DeleteEquipment = '/equipment/',
    GetEquipments = 'V0/equipment/getEquipments',
    // CreateEquipment = 'V0/equipment/createEquipment',
    CloneEquipment = 'V0/equipment/cloneEquipment',
    UpdateDocumentsEquipment = 'V0/equipment/updateDocumentsEquipment',
    GetCustomerEquipments = 'V0/equipment/getCustomerEquipments',

    // Contact
    GetCompanyContacts = '/contact/company/',
    UpdateContact = '/contact/',
    GetContact = '/contact',
    CreateContact = '/contact/',
    DeleteContact = '/contact/',
    SetIsPrimary = '/contact/setPrimary/',
    GetContacts = '/contact/list/for-manufacturer',

    // Product
    GetProductsList = '/product/gets',
    getsForCustomerList = '/product/getsForCustomer',
    DeleteProduct = '/product',
    CreateProducts = '/product/bulk',
    CreateProduct = '/product',
    LinkCustomersToProducts = '/product/linkCustomers',
    UpdateProduct = '/product/',
    GetCustomerProducts = 'V0/product/getCustomerProducts',
    GetProductsForActivity = '/product/activity/products',
    GetProductDetail = '/product/',

    // Document
    GetDocuments = '/document/gets',
    CreateDocument = '/document',
    UpdateDocument = '/document',
    DeleteDocument = '/document',
    UpdateEquipmentsDocument = `/document/linkEquipments`,
    GetDocument = '/document/',
    //GetDocumentWithEquipment = '/document/getsWithEquipments',

    // GetDocuments = 'V0/document/getDocuments',
    // CreateDocument = 'V0/document/createDocument',
    // PartList
    DeletePartList = '/partList/',
    // Part
    DeletePart = '/part/',
    UpdatePart = '/part/',
    SearchPart = '/part/search',

    // Orders
    GetOrder = '/order/',
    GetCustomerOrdersForManufacturer = '/order/customer/',
    GetCustomerOrdersForCustomer = '/order/customer',
    GetManufacturerOrders = '/order/manufacturer',
    GetSavedCarts = '/order/saved-carts',
    GetVendorQuotes = '/order/vendor/quotes',
    GetCustomerQuotes = '/order/customer/quotes',
    GetEquipmentOrders = '/order/equipment/',
    GetPurchaseRequests = '/order/purchase-requests',
    CreateOrder = '/order',
    CreatePurchaseRequest = '/order/purchase-request',
    CreateRFQ = '/order/rfq',
    CreateQuote = '/order/quote',
    SaveOrder = '/order/save',
    UpdateSavedOrder = '/order/saved/',
    UpdateOrder = '/order/',
    UpdateOrderDetail = '/orderDetail/',
    GetNumberOfOrders = '/order/total-orders',

    // Notifications

    // Product

    // Address
    Address = 'address/',

    // Hubspot
    GetHubspotUnitsWithDetails = 'hubspot/getUnitsWithDetails',
    GetByProviderType = '/providerLog',
    GetByProviderLog = '/providerLog/',

    // Activity
    CreateActivity = '/activity',
    CreateActivityTask = '/activity/',
    GetActivities = '/activity',
    GetActivitiesForCustomer = '/activity/customer/',
    GetVisibleActivitiesForCustomer = '/activity/filter-by/customer',
    UpdateActivity = '/activity/',
    UpdateActivityGroup = '/activity/task/',
    GetActivityDetail = '/activity/',
    DeleteActivity = '/activity/',
    DeleteActivityTask = '/activity/task/',
    GetActivityByEquipmentId = '/activity/equipment/',
    GetActivityByContractId = '/activity/contract/',
    GetActivityByProductId = '/activity/product/',

    // Health Check
    HealthCheck = '/api/hc',
}
