import { FC, Fragment, ReactNode } from 'react'
import Icons, { IconType } from '../../Icon'
import './style.scss'

export interface PopupSidebarProps {
    children: ReactNode
    visible: boolean
    onClose: () => void
    classname?: string
}

const PopupSidebar: FC<PopupSidebarProps> = ({ children, visible, onClose, classname }) => {
    if (!visible) {
        return <Fragment />
    }

    return (
        <div className={`popup-sidebar-container ${classname}`}>
            <div className="left-side">
                <div className="close" onClick={onClose}>
                    <Icons type={IconType.Close} />
                </div>
            </div>
            <div className="right-side">{children}</div>
        </div>
    )
}

export default PopupSidebar
