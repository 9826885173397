import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import SearchInput from '../../../../../components/SearchInput'
import { NewUpdateEquipmentModel } from '../../../../../models/Ui/Equipment'
import EquipmentService from '../../../../../services/UiService/Equipment'
import { ConfirmationContext } from '../../../../../store/ConfirmationContext'
import InstallationsListTable from '../../Tables/InstallationsListTable'
import { toast } from 'react-toastify'
import PopoverItem from '../../../../../components/PopoverItem'
import './Installations.scss'
import Icons, { IconType } from '../../../../../Icon'
import { useNavigate } from 'react-router'
import { Color } from '../../../../../models/enums'
import { EquipmentActionTypes } from '../../../../../models/Ui/Common'
import { InstallationAddressModel } from './../../../../../models/New/Customer'
import { CustomerEquipmentLocationModel } from '../../../../../models/Db/Customer'

interface Props {
    installations: InstallationModel[]
    visible: boolean
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel) => void
    onError: (error: any) => void
}

export interface InstallationModel {
    id: string
    equipmentName?: string
    customerName?: string
    installationAddress?: InstallationAddressModel
    serialNumber?: string
    topLevelEquipmentName?: string
    isVisible: boolean
    isSubAssembly: boolean
}

function InstallationsTab(props: React.PropsWithChildren<Props>) {
    const { confirm } = useContext(ConfirmationContext)
    const currentItems = props.installations
    const [filteredData, setFilteredData] = useState<InstallationModel[]>([])
    const [searchKey, setSearchKey] = useState<string>('')
    const navigate = useNavigate()

    const updateEquipmentVisible = async (equipmentId: string, visible: boolean) => {
        try {
            props.onLoading()
            await EquipmentService.updateCustomEquipment(equipmentId, { isVisible: visible })
            props.onSuccess(EquipmentActionTypes.UPDATE_CUSTOM_EQUIPMENT)
        } catch (error: any) {
            props.onError(error)
        } finally {
            props.onCompleted()
        }
    }

    useEffect(() => {
        setFilteredData(currentItems)
    }, [currentItems])

    function onSearchHandler(data: InstallationModel[]) {
        setFilteredData(data)
    }

    const handleDeleteEquipment = (id: string) => {
        confirm({
            modalClassName: 'delete-equipment-modal',
            modalTitle: 'Delete equipment?',
            primaryButtonText: 'Delete',
            func: async (successCallback, errCallback) => {
                try {
                    props.onLoading()
                    await EquipmentService.delete(id)
                    setFilteredData((prevState) => [...prevState.filter((child) => child.id !== id)])
                    successCallback()
                } catch (error: any) {
                    errCallback(error)
                } finally {
                    props.onCompleted()
                }
            },
        })
    }

    return (
        <div>
            <div className="equipment-search-box">
                <SearchInput
                    className="documents-search-input"
                    data={currentItems}
                    handleSearch={onSearchHandler}
                    searchKeys={[
                        'partNumber',
                        'name',
                        'serialNo',
                        'tagName',
                        'customerName',
                        'manufacturerName',
                        'installationAddress.city',
                        'installationAddress.country',
                        'installationAddress.state',
                    ]}
                    setDefaultSearch={searchKey}
                />
            </div>
            <InstallationsListTable
                onClickRow={(e: InstallationModel) => navigate('/custom-equipment/' + e.id)}
                setSearch={(e) => {
                    if (searchKey === e) {
                        setSearchKey('')
                    } else {
                        setSearchKey(e)
                    }
                }}
                data={filteredData.map((p) => {
                    return {
                        isSubAssembly: p.isSubAssembly,
                        topLevelEquipmentName: p.topLevelEquipmentName,
                        equipmentName: p.equipmentName,
                        address: p.installationAddress as CustomerEquipmentLocationModel,
                        serialNumber: p.serialNumber,
                        customerName: p.customerName,
                        id: p.id,
                        customerId: p.customerName,
                        buttons: (
                            <div className="installation-buttons">
                                <PopoverItem popoverContent={!p.isVisible ? 'Hidden from customer' : 'Visible to customer'}>
                                    <Switch
                                        className="show-to-customer"
                                        checked={p.isVisible}
                                        onChange={() => {
                                            try {
                                                updateEquipmentVisible(p.id, !p.isVisible)
                                                toast.success('Updated successfully!')
                                            } catch (error) {
                                                console.log(error)
                                            }
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </PopoverItem>
                                <PopoverItem popoverContent="Delete">
                                    <FontAwesomeIcon icon={faTrash} color="var(--grey-600)" onClick={() => handleDeleteEquipment(p.id)} />
                                </PopoverItem>
                                <div className="action-cell" onClick={() => navigate('/custom-equipment/' + p.id)}>
                                    <PopoverItem popoverContent="View Details">
                                        <Icons type={IconType.ChevronRight} color={Color.grey600} />
                                    </PopoverItem>
                                </div>
                            </div>
                        ),
                    }
                })}
            />
        </div>
    )
}

export default InstallationsTab
