import React from "react"
import HoverCard from "../../../../components/HoverCard"
import Icons, { IconType } from "../../../../Icon"
import { UserModel } from "../../../../models/Ui/User"
import './style.scss'

interface Props {
    user: UserModel
}

function AccountInfoTab(props: React.PropsWithChildren<Props>) {

    function buildSection(title: string, content: React.ReactNode, icon: IconType) {
        return (
            <div className='section'>
                <Icons className='section-icon' type={icon} />
                <div className='section-container'>
                    <div className='title'>{title}</div>
                    <div className='content'>{content}</div>
                </div>
            </div>
        )
    }

    return (
        <div className='content-container'>
            <HoverCard>
                <div className='account-info-tab-container'>
                    {
                        buildSection(
                            'FULL NAME',
                            <div className='content-row'>
                                <div>
                                    <span className='title'>First name</span>
                                    <span className='content'>{props.user.firstName}</span>
                                </div>

                                <div>
                                    <span className='title'>Last name</span>
                                    <span className='content'>{props.user.lastName}</span>
                                </div>
                            </div>,
                            IconType.Phone,
                        )
                    }

                    {buildSection('EMAIL ADDRESS', props.user.email, IconType.Mail)}

                    {buildSection('JOB TITLE / POSITION', props.user.jobTitle, IconType.Mail)}

                    {buildSection('ROLE', props.user.role, IconType.Mail)}
                </div>
            </HoverCard>
        </div>
    )
}

export default AccountInfoTab