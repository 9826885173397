import PopoverItem from '../../../../../../components/PopoverItem'
import Icon, { IconType } from '../../../../../../Icon'
import { standardDate } from '../../../../../../utils/helpers'
import './ActivityCart.scss'

interface Props {
    title: string
    assignee?: string
    status: string
    dueDate: Date
    type?: string
    isRecurring: boolean
}

function ActivityCart(props: Props) {
    const tableTypeIconRenderer = (type?: string) => {
        switch (type) {
            case 'Ticket':
                return (
                    <PopoverItem popoverContent="Ticket">
                        <Icon className="recurring-icon" type={IconType.Ticket} />
                    </PopoverItem>
                )
            case 'Service':
                return (
                    <PopoverItem popoverContent="Service">
                        <Icon className="recurring-icon" type={IconType.Maintenance} />
                    </PopoverItem>
                )

            case 'Product Replenishment':
                return (
                    <PopoverItem popoverContent="Product Replenishment">
                        <Icon className="recurring-icon" type={IconType.Replenishment} />
                    </PopoverItem>
                )
        }
    }
    const getClassName = (type: string) => {
        let className = ''

        if (type === 'Open') {
            className += ' open'
        }

        if (type === 'On Hold') {
            className += ' onHold'
        }
        if (type === 'In Progress') {
            className += ' inProgress'
        }
        if (type === 'Closed') {
            className += ' closed'
        }

        return className
    }

    return (
        <div className={`task-cart-container ${getClassName(props.status)}`}>
            <div className={`content ${getClassName(props.status)}`}>
                <div className="description">{props.title}</div>
                <div className="footer">
                    <div className="assigne">{props?.assignee}</div>
                    <div className="type-icon">{tableTypeIconRenderer(props.type)}</div>
                </div>
            </div>
            <div className="footer">
                <div className={`status  ${getClassName(props.status)}`}>{props.status}</div>
                <div className={`date-info ${new Date(props.dueDate) < new Date() && 'red'}`}>
                    <span>{standardDate(props.dueDate)}</span>
                    {props.isRecurring && (
                        <PopoverItem key="rec" popoverContent="Recurring">
                            <Icon type={IconType.Reset} />
                        </PopoverItem>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ActivityCart
