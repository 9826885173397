import { useState } from 'react'
import './BulkUploadModal.scss'
import Modal from '../../../../components/Modal'
import Icons, { IconType } from '../../../../Icon'
import FileInput from '../../../../components/FileInput'
import { Color } from '../../../../models/enums'
import { FileInputType } from '../../../../components/FileInput/enum'

interface Props {
    visible: boolean
    csvFileName?: string
    submit: (csv: File, isCsvUploaded: boolean) => void
    closeModal: () => void
}

function BulkUploadModal(props: Props) {
    const [csv, setCsv] = useState<File | null>(null)
    const [isCsvUploaded, setIsCsvUploaded] = useState<boolean>(false)

    const isValidForm = () => {
        return csv
    }

    const clearForm = () => {
        setCsv(null)
        setIsCsvUploaded(false)
    }

    const clickPrimary = () => {
        props.submit(csv!, isCsvUploaded)
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    const onChangeCsv = (file: File) => {
        setCsv(file)
        setIsCsvUploaded(true)
    }

    return (
        <Modal
            className="upload-product-modal"
            visible={props.visible}
            title="Upload product list"
            primaryButtonText="Submit"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
        >
            <div className="download-row">
                <Icons type={IconType.Info} color={Color.grey600} />
                <a className="download-row__link" target="_blank" href="/assets/excel/template.xlsx">
                    Download template
                </a>
                <span>and upload a csv file of your product list.</span>
            </div>
            <FileInput
                fileInputText="PRODUCTS"
                name={csv ? csv.name : props.csvFileName}
                handleChange={(e) => onChangeCsv(e)}
                fileTypes={['csv']}
                multiple={false}
                inputType={FileInputType.PRODUCT_BULK_UPLOAD}
            />
            <div className="divider"></div>
        </Modal>
    )
}

export default BulkUploadModal
