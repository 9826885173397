import React from 'react'
import { MaxCharacter } from '../../models/enums'
import Input from '../Input'
import TextArea from '../TextArea'
import './AddressBox.scss'
import { IAddress } from '../../store/models'

interface Props {
    className?: string
    currentAddress: IAddress
    handleAddress: Function
    contactInfo?: boolean
}

function AdressBox(props: React.PropsWithChildren<Props>) {
    const { currentAddress, handleAddress, contactInfo } = props
    return (
        <div className={props.className ? props.className + ' form-container' : 'form-container'}>
            <div className="form-container-line">
                <Input max={MaxCharacter.address} value={currentAddress.site_address1} placeholder="Street Address 1" onChange={(e) => handleAddress({ site_address1: e.target.value })} />
            </div>
            <div className="form-container-line">
                <Input max={MaxCharacter.address} value={currentAddress.site_address2} placeholder="Street Address 2" onChange={(e) => handleAddress({ site_address2: e.target.value })} />
            </div>
            <div className="form-container-line">
                <Input max={MaxCharacter.subAddress} value={currentAddress.city} placeholder="City" onChange={(e) => handleAddress({ city: e.target.value })} />
                <Input max={MaxCharacter.subAddress} value={currentAddress.state} placeholder="State" onChange={(e) => handleAddress({ state: e.target.value })} />
            </div>
            <div className="form-container-line">
                <Input max={MaxCharacter.zipCode} value={currentAddress.postal_code} placeholder="Postal code" onChange={(e) => handleAddress({ postal_code: e.target.value })} />
                <Input max={MaxCharacter.subAddress} value={currentAddress.country} placeholder="Country" onChange={(e) => handleAddress({ country: e.target.value })} />
            </div>
            <div className="form-container-line">
                <TextArea autoSize value={currentAddress.note} placeholder="Add note" onChange={(e) => handleAddress({ note: e.target.value })} />
            </div>
            {contactInfo && (
                <div className="form-container-line">
                    <Input value={currentAddress.contact_person} placeholder="Contact person" onChange={(e) => handleAddress({ contact_person: e.target.value })} />
                    <Input value={currentAddress.phone_number} placeholder="Phone number" onChange={(e) => handleAddress({ phone_number: e.target.value })} />
                </div>
            )}
        </div>
    )
}

export default AdressBox
