import { CSSProperties, FC } from 'react'
import Button from '../Button'
import './style.scss'

interface Props {
    color?: 'green' | 'default'
    className?: string
    onClick?: () => void
    disabled?: boolean
    title?: string
    style?: CSSProperties
}

const ChipButton: FC<Props> = ({ className, color, disabled = false, onClick, children, title = '', style = {} }) => {
    return (
        <Button style={style} title={title} disabled={disabled} className={`${className || ''} ${color || ''} chip-button`} onClick={onClick}>
            {children}
        </Button>
    )
}

export default ChipButton
