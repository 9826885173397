import React, { FC } from 'react'
import './FileInput.scss'
import { FileUploader } from 'react-drag-drop-files'
import Icons, { IconType } from '../../Icon'
import { FileInputType } from './enum'
import { FileModel } from '../../services/NewServices/StorageService'
import { Box } from '@mui/material'
import { Color } from '../../models/enums'
import { ProductImageModel } from '../../models/New/Product'

interface Props {
    headerIcon?: React.ReactNode
    fileInputText?: string
    defaultName?: string
    name?: string | string[]
    handleChange?: (fileList: any) => void
    fileTypes?: string[]
    disabled?: boolean
    multiple?: boolean
    isFileUploaded?: boolean
    uploadedFileText?: string
    inputType?: FileInputType
    files?: FileModel[] | ProductImageModel[]
}

const FileInput: FC<Props> = ({
    headerIcon,
    fileInputText,
    defaultName,
    name,
    handleChange,
    fileTypes,
    disabled,
    multiple = false,
    isFileUploaded,
    uploadedFileText = 'Remove and upload new',
    inputType = FileInputType.DEFAULT,
    files,
}) => {
    const renderDropBox = () => {
        return (
            <div className={disabled ? 'file-input-el disabled' : 'file-input-el'}>
                <div className="file-input-el-box file-input-mini">
                    <div className="header-container">
                        <div className="header-start">
                            {headerIcon}
                            <div className="title-container">
                                <span className="file-input-el-title">{fileInputText ? fileInputText : 'UPLOAD FILE'}</span>
                                {name && isFileUploaded && typeof name !== 'string' ? (
                                    name.map((value) => <span className="file-input-el-subtitle">{value}</span>)
                                ) : (
                                    <span className="file-input-el-subtitle">{name}</span>
                                )}
                            </div>
                        </div>
                        {isFileUploaded && <span className="remove-upload-text">{uploadedFileText}</span>}
                    </div>
                    {!isFileUploaded && (
                        <div className="file-input-el-content">
                            <div className="file-input-el-content-container">
                                <Icons className="icon" type={IconType.UploadCloud} />
                                <div>
                                    <span className="file-input-el-text">{name ? name + ' ' : 'Drag and drop file(s) to upload or '}</span>
                                    <span className="file-input-browse-file-text">Browse Files</span>
                                </div>
                            </div>
                            {/*props.name &&
                            <div className='file-input-el-name'>{props.name}</div>*/}
                            {/*(!props.name && props.defaultName )&&
                                <div className='file-input-el-name'>{props.defaultName}</div>*/}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const ProductBulkUpload = () => {
        return (
            <div className="upload-mini">
                <Icons className="upload-mini__icon upload-mini__icon--left" type={IconType.UploadCloud} />
                <div className="upload-mini__text">
                    <span>{name ? name : 'UPLOAD'}</span>
                </div>
                <Icons className="upload-mini__icon upload-mini__icon--right" type={IconType.Plus} />
            </div>
        )
    }

    const NewProductUploadImage = () => {
        return (
            <Box className="product-image">
                <Icons type={IconType.Plus} color={Color.grey600} />
            </Box>
        )
    }

    const UploadByDocuments = () => {
        return (
            <div className="upload-mini">
                <Icons className="upload-mini__icon upload-mini__icon--left" type={IconType.UploadCloud} />
                <div className="upload-mini__text">
                    <span>{name}</span>
                </div>
            </div>
        )
    }

    switch (inputType) {
        case FileInputType.NEW_PRODUCT_UPLOAD:
            return <FileUploader multiple={multiple} disabled={disabled} className="file-input" handleChange={handleChange} name="file" children={<NewProductUploadImage />} types={fileTypes} />
        case FileInputType.PRODUCT_BULK_UPLOAD:
            return <FileUploader multiple={multiple} disabled={disabled} className="file-input" handleChange={handleChange} name="file" children={<ProductBulkUpload />} types={fileTypes} />
        case FileInputType.DOCUMENT_UPLOAD:
            return <FileUploader multiple={multiple} disabled={disabled} className="file-input" handleChange={handleChange} name="file" children={<UploadByDocuments />} types={fileTypes} />
        default:
            return <FileUploader multiple={multiple} disabled={disabled} className="file-input" handleChange={handleChange} name="file" children={renderDropBox()} types={fileTypes} />
    }
}

export default FileInput
