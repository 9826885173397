import { FC, RefObject, useEffect, useState } from 'react'
import { Tooltip, TooltipProps } from '@mui/material'
import './style.scss'

interface Props {
    popoverContent: string
    disabled?: boolean
    placement?: TooltipProps['placement']
    overflowTipRef?: RefObject<HTMLDivElement>
}

const OverflowTooltip: FC<Props> = ({ children, popoverContent, placement = 'bottom', overflowTipRef }) => {
    const [isTooltip, setIsTooltip] = useState<boolean>(false)

    useEffect(() => {
        if (overflowTipRef && overflowTipRef.current) {
            setIsTooltip(!(overflowTipRef.current.scrollWidth > overflowTipRef.current.clientWidth))
        }
    }, [overflowTipRef])

    return !isTooltip ? (
        <Tooltip title={popoverContent} placement={placement}>
            <div className="grid-container">{children}</div>
        </Tooltip>
    ) : (
        <>{children}</>
    )
}

export default OverflowTooltip
