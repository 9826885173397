import { Fragment, useCallback, useEffect, useState } from 'react'
import { BreadcrumbModel } from '../../components/Breadcrumb'
import ContentBox from '../../components/ContentBox'
import EasyIcon from '../../components/EasyIcon'
import Loading from '../../components/Loading'
import Tab from '../../components/Tab'
import ThreeDotButton, { ActionModel } from '../../components/ThreeDotButton'
import Icons, { IconType } from '../../Icon'
import { TabItem, Tabs } from '../../models/Ui/Tab'
import PageWrapper from '../PageWrapper'
import CompanyInfoTab from './Tabs/CompanyInfoTab'
import './style.scss'
import AccountInfoTab from './Tabs/AccountInfoTab'
import TeamTab from './Tabs/TeamTab'
import PaymentTab from './Tabs/PaymentTab'
import AddressesTab from './Tabs/AddressesTab'
import UpdateCompanyDetailsModal from './Modals/UpdateCompanyDetailsModal'
import { toast } from 'react-toastify'
import UpdateAccountDetailsModal from './Modals/UpdateAccountDetailsModal'
import UpdatePasswordModal from './Modals/UpdatePasswordModal'
import CreateTeamMemberModal from './Modals/CreateTeamMemberModal'
import CreateAddressModal from './Modals/CreateAddressModal'
import { AddressModel } from '../../models/Ui/Address'
import AddressService from '../../services/UiService/Address'
import CompanyService from '../../services/UiService/Company'
import { CompanyModel } from '../../models/Ui/Company'
import { UserModel } from '../../models/Ui/User'
import UserService from '../../services/UiService/User'
import AuthService from '../../services/UiService/Auth'
import { useNavigate } from 'react-router'
import { UserRoleType } from '../../models/Ui/UserRoleType'
import UpdateTeamMemberModal from './Modals/UpdateTeamMemberModal'
import UpdateAddressModal from './Modals/UpdateAddressModal'
import { useContext } from 'react'
import HubspotIntegrationTab from './Tabs/HubspotIntegrationTab'
import { ConfirmationContext } from '../../store/ConfirmationContext'

export enum TeamActionTypes {
    CREATE_TEAM_MEMBER,
    CREATE_ADDRESS,
    UPDATE_COMPANY_DETAILS,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_PASSWORD,
    UPDATE_TEAM_MEMBER,
    UPDATE_ADDRESS,
    DELETE_TEAM_MEMBER,
    DELETE_ADDRESS,
    HUBSPOT_INTEGRATION_RESPONSE,
    UPDATE_COMPANY_LOGO,
}

function Settings() {
    const { confirm } = useContext(ConfirmationContext)
    const navigate = useNavigate()

    // const { getAddressCallBack } = useContext(CheckoutContext)
    const [company, setCompany] = useState<CompanyModel>()
    const [user, setUser] = useState<UserModel>()
    const [teamMember, setTeamMember] = useState<UserModel>()
    const [users, setUsers] = useState<UserModel[]>([])
    const [addresses, setAddresses] = useState<AddressModel[]>([])
    const [address, setAddress] = useState<AddressModel>()

    const tabItems: TabItem[] = [
        {
            id: Tabs.Company,
            name: 'Company',
            customIcon: <Icons type={IconType.Handbag} />,
            permissions: [UserRoleType.Admin, UserRoleType.Manager, UserRoleType.Technician],
        },
        {
            id: Tabs.Account,
            name: 'Account',
            customIcon: <Icons type={IconType.ManageAccount} />,
            permissions: [UserRoleType.Admin, UserRoleType.Manager, UserRoleType.Technician],
        },
        {
            id: Tabs.Team,
            name: 'Team',
            customIcon: <Icons type={IconType.Team} />,
            permissions: [UserRoleType.Admin, UserRoleType.Manager],
        },
        {
            id: Tabs.Payments,
            name: 'Payments',
            customIcon: <Icons type={IconType.Payments} />,
            permissions: [UserRoleType.Admin, UserRoleType.Manager],
        },
        {
            id: Tabs.Addresses,
            name: 'Addresses',
            customIcon: <Icons type={IconType.Addresses} />,
            permissions: [UserRoleType.Admin, UserRoleType.Manager],
        },
        {
            id: Tabs.HubspotIntegration,
            name: 'Hubspot Integration',
            customIcon: <Icons type={IconType.Link} />,
            permissions: [UserRoleType.Admin, UserRoleType.Manager],
            hidden: !company?.hsApiKey,
        },
    ].filter((item) => user?.role && item.permissions?.includes(user.role) && !item.hidden)

    const [loading, setLoading] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>()
    const [updateCompanyDetailsModalVisible, setUpdateCompanyDetailsModalVisible] = useState<boolean>(false)
    const [updateAccountDetailsModalVisible, setUpdateAccountDetailsModalVisible] = useState<boolean>(false)
    const [updatePasswordModalVisible, setUpdatePasswordModalVisible] = useState<boolean>(false)
    const [createTeamMemberModalVisible, setCreateTeamMemberModalVisible] = useState<boolean>(false)
    const [createAddressModalVisible, setCreateAddressModalVisible] = useState<boolean>(false)
    const [updateTeamMemberModalVisible, setUpdateTeamMemberModalVisible] = useState<boolean>(false)
    const [updateAddressModalVisible, setUpdateAddressModalVisible] = useState<boolean>(false)

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Settings',
            url: '/',
        },
    ]

    const rightMenuActionList: ActionModel[] = [
        {
            text: 'Edit company details',
            onClick: () => setUpdateCompanyDetailsModalVisible(true),
            permissions: [UserRoleType.Admin],
        },
        {
            text: 'Edit account details',
            onClick: () => setUpdateAccountDetailsModalVisible(true),
            permissions: [UserRoleType.Admin, UserRoleType.Manager],
        },
        {
            text: 'Change password',
            onClick: () => setUpdatePasswordModalVisible(true),
            permissions: [UserRoleType.Admin, UserRoleType.Manager, UserRoleType.Technician],
        },
        {
            text: 'Add new team member',
            onClick: () => setCreateTeamMemberModalVisible(true),
            permissions: [UserRoleType.Admin, UserRoleType.Manager],
        },
        {
            text: 'Add address',
            onClick: () => setCreateAddressModalVisible(true),
            permissions: [UserRoleType.Admin, UserRoleType.Manager],
        },
    ].filter((item) => user?.role && item.permissions?.includes(user.role))

    const getCompanyCallback = useCallback(async () => {
        try {
            const companyService = new CompanyService()
            const companyResponse = await companyService.getCompany()
            setCompany(companyResponse)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [])

    const getUserCallback = useCallback(async () => {
        try {
            const userService = new UserService()
            const userResponse = await userService.get()
            setUser(userResponse)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [])

    const getUsersCallback = useCallback(async () => {
        try {
            const userService = new UserService()
            const usersResponse = await userService.getCompanyUsers()
            setUsers(usersResponse)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [])

    const getAddressesCallback = useCallback(async () => {
        try {
            const addressService = new AddressService()
            const addressResponse = await addressService.getAll()
            setAddresses(addressResponse)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [])

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            if (user?.role === UserRoleType.Admin || user?.role === UserRoleType.Manager) {
                await getAddressesCallback()
                await getUsersCallback()
            }
            setLoading(false)
        }

        loadComponent()
    }, [getAddressesCallback, getUsersCallback, user?.role])

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getUserCallback()
            await getCompanyCallback()
            setLoading(false)
        }

        loadComponent()
    }, [getCompanyCallback, getUserCallback])

    useEffect(() => {
        if (activeTab === undefined && tabItems.length > 0) {
            setActiveTab(tabItems[0].id)
        } /* else not needed */
    }, [activeTab, tabItems])

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onSuccessCallback = useCallback(
        async (action: TeamActionTypes, data?: any) => {
            switch (action) {
                case TeamActionTypes.CREATE_TEAM_MEMBER:
                    setUsers((prevUsers) => [...prevUsers, data])
                    break

                case TeamActionTypes.CREATE_ADDRESS:
                    setAddresses((prevAddresses) => [...prevAddresses, data])
                    //getAddressCallBack()
                    break

                case TeamActionTypes.UPDATE_COMPANY_DETAILS:
                    setCompany({
                        ...data,
                        billingAddress: addresses.find((address) => address.id === data.billingAddress?.id) || company?.billingAddress,
                        shippingAddress: addresses.find((address) => address.id === data.shippingAddress?.id) || company?.shippingAddress,
                    })
                    //getAddressCallBack()
                    break

                case TeamActionTypes.UPDATE_ACCOUNT_DETAILS:
                    if (company) {
                        setUsers((prevUsers) => prevUsers.map((user) => (user.id === data.id ? data : user)))
                        setCompany({ ...company, user: { ...company.user, ...data } })
                        setUser({ ...data })
                    } /* else not needed */
                    break

                case TeamActionTypes.UPDATE_PASSWORD:
                    if (data.status) {
                        toast.success('Password updated successfully')
                        new AuthService().logout()
                        navigate('/login')
                    } else {
                        toast.error(data.message)
                    }
                    break

                case TeamActionTypes.UPDATE_TEAM_MEMBER:
                case TeamActionTypes.DELETE_TEAM_MEMBER:
                    setUsers((prevUsers) => prevUsers.map((user) => (user.id === data.id ? data : user)).filter((user) => user.isDeleted === false))
                    if (company && user?.id === data.id) {
                        setCompany({ ...company, user: { ...company.user, ...data } })
                        setUser({ ...data })
                    }
                    break

                case TeamActionTypes.DELETE_ADDRESS:
                    setAddresses((prevAddresses) => prevAddresses.filter((address) => address.id !== data.id))
                    //getAddressCallBack()
                    break

                case TeamActionTypes.UPDATE_ADDRESS:
                    setAddresses((prevAddresses) => prevAddresses.map((address) => (address.id === data.id ? data : address)))
                    //getAddressCallBack()
                    break

                case TeamActionTypes.HUBSPOT_INTEGRATION_RESPONSE:
                    if (company && data.lastSyncTime) {
                        setCompany({ ...company, hsLastSyncTime: data.lastSyncTime })
                    } /* else not needed */
                    break
                case TeamActionTypes.UPDATE_COMPANY_LOGO:
                    if (data) {
                        setCompany(data)
                    } /* else not needed */
                    break
                default:
                    break
            }
        },
        [addresses, company, navigate, user?.id]
    )

    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    async function handleResendVerification(id: string) {
        try {
            setLoading(true)
            const userService = new UserService()
            await userService.resendVerification(id)
            toast.success('Successfully sent ')
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    function updateTeamMemberHandler(id: string) {
        const teamMember = users.find((user) => user.id === id)
        setTeamMember(teamMember)
        setUpdateTeamMemberModalVisible(true)
    }

    function deleteTeamMemberHandler(id: string) {
        const teamMember = users.find((user) => user.id === id)
        confirm({
            modalClassName: 'delete-team-member-modal',
            modalTitle: 'Delete team member?',
            primaryButtonText: 'Delete',
            func: async (successCallback, errCallback) => {
                try {
                    onLoadingCallback()
                    const userService = new UserService()
                    const updateUserResponse = await userService.delete(teamMember?.id ?? '')
                    onSuccessCallback(TeamActionTypes.DELETE_TEAM_MEMBER, updateUserResponse)
                    successCallback()
                } catch (error: any) {
                    onErrorCallback(error)
                    errCallback(error)
                } finally {
                    onCompletedCallback()
                }
            },
            contentNode: (
                <Fragment>
                    <div className="modal-line">
                        <span>You are deleting the team member.</span>
                    </div>
                    <div className="modal-line">Are you sure you would like to proceed?</div>
                </Fragment>
            ),
        })
    }

    function deleteAddressHandler(id: string) {
        const address = addresses.find((address) => address.id === id)
        confirm({
            modalClassName: 'delete-address-modal',
            modalTitle: 'Delete address?',
            primaryButtonText: 'Delete',
            func: async (successCallback, errCallback) => {
                try {
                    onLoadingCallback()
                    const addressService = new AddressService()
                    const deleteAddressResponse = await addressService.delete(address?.id ?? '')
                    onSuccessCallback(TeamActionTypes.DELETE_ADDRESS, deleteAddressResponse)
                    successCallback()
                } catch (error: any) {
                    onErrorCallback(error)
                    errCallback(error)
                } finally {
                    onCompletedCallback()
                }
            },
            contentNode: (
                <Fragment>
                    <div className="modal-line">
                        <span>You are deleting the address.</span>
                    </div>
                    <div className="modal-line">Are you sure you would like to proceed?</div>
                </Fragment>
            ),
        })
    }

    function updateAddressHandler(id: string) {
        const address = addresses.find((address) => address.id === id)
        setAddress(address)
        setUpdateAddressModalVisible(true)
    }

    async function updateCompanyLogoHandler(id: string, url: string) {
        try {
            onLoadingCallback()
            const companyService = new CompanyService()
            const payload = {
                id: id,
                name: company?.name,
                website: company?.website,
                about: company?.about,
                phone: company?.phone,
                email: company?.email!,
                shippingAddressId: company?.shippingAddress?.id,
                billingAddressId: company?.billingAddress?.id,
                logoUrl: url,
            }
            await companyService.update(payload)
            const newCompany = { ...company, logoUrl: url }
            onSuccessCallback(TeamActionTypes.UPDATE_COMPANY_LOGO, newCompany)
        } catch (error: any) {
            onErrorCallback(error)
        } finally {
            onCompletedCallback()
        }
    }

    return (
        <PageWrapper className="settings-page" breadcrumb={breadcrumb}>
            {loading && <Loading />}
            <ContentBox title={'Settings'} headerIcon={<EasyIcon icon={IconType.Cog} />}>
                <div className="tab-container">
                    {activeTab && (
                        <Tab
                            activeTab={activeTab}
                            tabs={tabItems}
                            onTabClick={(id: number) => {
                                setActiveTab(id)
                            }}
                        />
                    )}
                    <ThreeDotButton actions={rightMenuActionList} />
                </div>

                {activeTab === Tabs.Company && company && (
                    <CompanyInfoTab onClickUpdateLogoUrl={updateCompanyLogoHandler} onLoading={onLoadingCallback} onCompleted={onCompletedCallback} onError={onErrorCallback} company={company} />
                )}

                {activeTab === Tabs.Account && user && <AccountInfoTab user={user} />}

                {activeTab === Tabs.Team && users && (
                    <TeamTab
                        user={user}
                        users={users}
                        handleResendVerification={handleResendVerification}
                        onClickEditTeamMember={updateTeamMemberHandler}
                        onClickDeleteTeamMember={deleteTeamMemberHandler}
                    />
                )}

                {activeTab === Tabs.Payments && company && <PaymentTab company={company} />}

                {activeTab === Tabs.Addresses && company && (
                    <AddressesTab
                        company={company}
                        addresses={addresses}
                        onClickUpdateAddress={updateAddressHandler}
                        onClickDeleteAddress={deleteAddressHandler}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}

                {activeTab === Tabs.HubspotIntegration && company && (
                    <HubspotIntegrationTab company={company} onLoading={onLoadingCallback} onCompleted={onCompletedCallback} onSuccess={onSuccessCallback} onError={onErrorCallback} />
                )}

                {updateCompanyDetailsModalVisible && company && (
                    <UpdateCompanyDetailsModal
                        company={company}
                        closeModal={() => setUpdateCompanyDetailsModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}

                {updateAccountDetailsModalVisible && user && (
                    <UpdateAccountDetailsModal
                        user={user}
                        closeModal={() => setUpdateAccountDetailsModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}

                {updatePasswordModalVisible && (
                    <UpdatePasswordModal
                        closeModal={() => setUpdatePasswordModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}

                {createTeamMemberModalVisible && company && (
                    <CreateTeamMemberModal
                        company={company}
                        closeModal={() => setCreateTeamMemberModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}

                {updateTeamMemberModalVisible && company && teamMember && (
                    <UpdateTeamMemberModal
                        loginUser={user}
                        company={company}
                        user={teamMember}
                        closeModal={() => setUpdateTeamMemberModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}

                {createAddressModalVisible && (
                    <CreateAddressModal
                        closeModal={() => setCreateAddressModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}

                {updateAddressModalVisible && address && (
                    <UpdateAddressModal
                        address={address}
                        closeModal={() => setUpdateAddressModalVisible(false)}
                        onLoading={onLoadingCallback}
                        onCompleted={onCompletedCallback}
                        onSuccess={onSuccessCallback}
                        onError={onErrorCallback}
                    />
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default Settings
