import React, { useEffect, useRef, useState } from 'react'
import { Size } from '../../models/enums'
import Icons, { IconType } from '../../Icon'
import { NavLink } from 'react-router-dom'
import './DropdownInput.scss'

interface Props {
    className?: string
    wrapperClass?: string
    size?: Size
    required?: boolean
    disabled?: boolean
    selected: string
    actions: {
        text: string
        onClick: () => void
    }[]
    multiple?: boolean
}

function DropdownInput(props: React.PropsWithChildren<Props>) {
    const [visible, setVisible] = useState<boolean>(false)
    const toggleDropdown = () => setVisible(!visible)
    const inputRef = useRef<HTMLDivElement>(null)
    let defaultClassName: string = `${'input input-'}` + (props.size ? props.size : Size.lg)

    const handleClickOutside = (event: any) => {
        if (visible && inputRef.current && !inputRef.current.contains(event.target)) {
            toggleDropdown()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        document.addEventListener('keydown', handleClickOutside)

        return () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('keydown', handleClickOutside)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef])

    return (
        <div className={'input-wrapper dropdown-input' + (props.className ? ' ' + props.className : '')} ref={inputRef}>
            <input
                multiple={props.multiple === true}
                type={'button'}
                onClick={toggleDropdown}
                disabled={props.disabled}
                required={props.required}
                value={props.selected}
                className={props.wrapperClass ? props.wrapperClass + ' ' + defaultClassName : defaultClassName}
            />
            {!props.disabled && (
                <div className="end-icon">
                    <Icons type={IconType.ChevronDown} />
                </div>
            )}

            {visible && !props.disabled && (
                <div className="dropdownbutton-transparent-container">
                    <div onClick={toggleDropdown} className="dropdownbutton-content">
                        {props.actions.map((action, index) => {
                            return (
                                <NavLink key={index} className={props.selected === action.text ? 'dropdownbutton-content--selected' : ''} to="#" onClick={action.onClick}>
                                    {action.text}
                                </NavLink>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DropdownInput
