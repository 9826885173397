import React from 'react'
import { Size } from '../../models/enums'
import './ContentBox.scss'

interface Props {
    className?: string
    title?: React.ReactNode
    headerIcon?: React.ReactNode
    description?: React.ReactNode
    subTitle?: React.ReactNode
    subDescription?: React.ReactNode
    size?: Size
    headerRight?: React.ReactNode
    treeViewMenu?: React.ReactNode
}

function ContentBox(props: React.PropsWithChildren<Props>) {
    let defaultClassName: string = 'content-box content-box-' + (props.size ? props.size : Size.md)
    return (
        <>
            <div className={props.className ? props.className + ' ' + defaultClassName : defaultClassName}>
                <div className="content-header">
                    <div className="content-header-child">
                        {props.treeViewMenu && <div className="content--child">{props.treeViewMenu}</div>}
                        <div className="content-header-right">
                            <div className="content-header-right-top">
                                {props.headerIcon}
                                {props.title && <h1>{props.title}</h1>}
                                {props.description && <div className="description">{props.description}</div>}
                            </div>
                            <div className="content-header-right-bottom">
                                {props.subTitle && <h5>{props.subTitle}</h5>}
                                {props.subDescription && <span className="description navigate">{props.subDescription}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="content--child">{props.headerRight}</div>
                </div>
                <div className="content-body">{props.children}</div>
            </div>
        </>
    )
}

export default ContentBox
