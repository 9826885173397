import ViewAsCustomer from '../../../../../components/ViewAsCustomer'
import { EquipmentModel, NewUpdateEquipmentModel } from '../../../../../models/New/Equipment'
import { EquipmentActionTypes } from '../../../../../models/Ui/Common'
import EquipmentService from '../../../../../services/UiService/Equipment'
import NewNotepad from './../../../../../components/NewNotepad/index'
import NewEquipmentDetails from './NewEquipmentDetailsTab'

interface Props {
    isGoToTemplateButton?: boolean
    isRootEquipment: boolean
    equipment: EquipmentModel
    installationCount: number
    flexWrap?: boolean
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel) => void
    onError: (error: any) => void
    handleLinkCustomers: () => void
    handleEdit: () => void
}

function DetailsTab(props: React.PropsWithChildren<Props>) {
    async function saveNoteHandler(data: NewUpdateEquipmentModel) {
        try {
            await EquipmentService.update(props.equipment.id, data)
        } catch (error: any) {
            props.onError(error)
        }
    }

    return (
        <div className="content-container">
            <NewEquipmentDetails
                isGoToTemplateButton={props.isGoToTemplateButton}
                flexWrap={props.flexWrap}
                isRootEquipment={props.isRootEquipment}
                handleEdit={props.handleEdit}
                handleLinkCustomers={props.handleLinkCustomers}
                installationCount={props.installationCount}
                equipment={props.equipment}
                onLoading={props.onLoading}
                onCompleted={props.onCompleted}
                onSuccess={props.onSuccess}
                onError={props.onError}
            />
            <div className="notepad-container">
                <ViewAsCustomer>
                    <NewNotepad id={props.equipment.id as string} notes={props.equipment.notes as string} saveNote={(data: NewUpdateEquipmentModel) => saveNoteHandler(data)} />
                </ViewAsCustomer>
            </div>
        </div>
    )
}

export default DetailsTab
