import { FC } from 'react'
import './style.scss'

interface Props {
    labelText: string
    left?: number
    classname?: string
}

const LabelWithLine: FC<Props> = ({ labelText, left = 0, classname }) => {
    return (
        <div className={`label-with-line ${classname}`}>
            <div className="label-text" style={{ left: left }}>
                {labelText}
            </div>
        </div>
    )
}

export default LabelWithLine
