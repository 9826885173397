/* eslint-disable @typescript-eslint/no-unused-vars */
import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import { useContext, useEffect, useRef, useState } from 'react'
import PdfViewer from '../../../../../components/PdfViewer'
import SearchInput from '../../../../../components/SearchInput'
import ZoomButton from '../../../../../components/ZoomButton'
import { IconType } from '../../../../../Icon'
import { DocumentModel } from '../../../../../models/New/Document'
import { EquipmentModel } from '../../../../../models/New/Equipment'
import { EquipmentActionTypes } from '../../../../../models/Ui/Common'
import { NewUpdateEquipmentModel } from '../../../../../models/Ui/Equipment'
import EquipmentService from '../../../../../services/UiService/Equipment'
import { ConfirmationContext } from '../../../../../store/ConfirmationContext'
import { UploadFileType } from '../../../../../utils/helpers'
import DocumentListTable from '../../Tables/DocumentListTable'
import './DocumentsTab.scss'

interface Props {
    equipment: EquipmentModel
    visible: boolean
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel) => void
    onError: (error: any) => void
}

function DocumentsTab(props: React.PropsWithChildren<Props>) {
    const { confirm } = useContext(ConfirmationContext)
    const currentItem = props.equipment

    const panzoom = useRef<PanzoomObject | null>(null)
    const imageContainer = useRef<HTMLDivElement>(null)
    const [documentDetailVisible, setDocumentDetailVisible] = useState<boolean>(false)
    const [documentFileType] = useState<any>(UploadFileType.Image)
    const [equipmentDocument] = useState<DocumentModel>()
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentModel[]>([])
    const [searchKey, setSearchKey] = useState<string>('')

    useEffect(() => {
        if (imageContainer.current !== null) {
            panzoom.current = Panzoom(imageContainer.current, { startScale: '1' })
        } /* else not needed */
    }, [])

    useEffect(() => {
        setFilteredDocuments(currentItem.documents)
    }, [currentItem.documents])

    function onSearchDocumentHandler(data: any[]) {
        setFilteredDocuments(data as DocumentModel[])
    }

    return (
        <div style={{ display: props.visible && currentItem.documents ? 'block' : 'none' }}>
            <>
                <div style={{ display: !documentDetailVisible ? 'block' : 'none' }}>
                    <div className="equipment-search-box">
                        <SearchInput
                            className="documents-search-input"
                            data={currentItem.documents}
                            handleSearch={onSearchDocumentHandler}
                            searchKeys={['name', 'category', 'type']}
                            setDefaultSearch={searchKey}
                        />
                    </div>
                    <DocumentListTable
                        setSearch={(e) => {
                            if (searchKey === e) {
                                setSearchKey('')
                            } else {
                                setSearchKey(e)
                            }
                        }}
                        data={filteredDocuments.map((document) => {
                            return {
                                name: document.name,
                                fileType: document.type,
                                onClick: () => window.open(document.url),
                                buttons: [
                                    {
                                        icon: IconType.Unlink,
                                        tooltip: 'Unlink',
                                        onClick: async () => {
                                            // UNLINK
                                            confirm({
                                                modalClassName: 'unlink-document-modal',
                                                modalTitle: 'Unlink document?',
                                                titleColor: '#D84315',
                                                primaryButtonText: 'Cancel',
                                                doesPrimaryButtonSubmit: false,
                                                secondaryButtonText: 'Unlink',
                                                func: async (successCallback, errCallback) => {
                                                    try {
                                                        props.onLoading()
                                                        await EquipmentService.unlinkEquipmentFromDocument(document.id, currentItem.id)
                                                        props.onSuccess(EquipmentActionTypes.UPDATE_EQUIPMENT_DOCUMENT)
                                                        successCallback()
                                                    } catch (error: any) {
                                                        props.onError(error)
                                                        errCallback(error)
                                                    } finally {
                                                        props.onCompleted()
                                                    }
                                                },
                                                contentNode: (
                                                    <div className="modal-line">
                                                        <span className="warning-text">
                                                            When you unlink a document from your equipment, all customers who have access to the equipment will no longer be able to see this document.
                                                            <br />
                                                            <br />
                                                            Are you sure you would like to proceed?
                                                            <br />
                                                            <br />
                                                            You could always link the document again if you need to.
                                                        </span>
                                                    </div>
                                                ),
                                            })
                                        },
                                    },
                                ],
                            }
                        })}
                    />
                </div>
                <div style={{ display: documentDetailVisible ? 'block' : 'none' }}>
                    <div className="yellow-wrapper" style={{ display: documentFileType === UploadFileType.Pdf ? 'block' : 'none' }}>
                        {equipmentDocument && documentFileType === UploadFileType.Pdf && (
                            <PdfViewer file={equipmentDocument.url} fileName={equipmentDocument.name} onClose={() => setDocumentDetailVisible(false)} layout={'scroll'} />
                        )}
                    </div>

                    <div className="yellow-wrapper" style={{ display: documentFileType === UploadFileType.Image ? 'block' : 'none' }}>
                        <ZoomButton onClickPlus={() => panzoom.current?.zoomIn()} onClickMinus={() => panzoom.current?.zoomOut()} onClickReset={() => panzoom.current?.reset()} />

                        <div style={{ display: 'flex' }} ref={imageContainer}>
                            <img src={equipmentDocument?.url} alt={equipmentDocument?.name} />
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default DocumentsTab
