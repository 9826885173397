import { useCallback, useEffect, useState } from 'react'
import './ProductInfoCart.scss'
import { ProductCountWithActivityDateDto, ProductListModel } from '../../../../../../../models/New/Product'
import { priceFormatter } from '../../../../../../../utils/helpers'
import ProductService from '../../../../../../../services/UiService/Product'
import SearchableDropdown from '../../../../../../../components/Dropdown/SearchableDropdown'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

interface Props {
    product: ProductListModel
    handleEdit: () => void
    onAddDemand: () => void
    onClickManageCustomer: () => void
    activityLengthChanged: boolean
}

enum DemandOptionType {
    AllTime = 'AllTime',
    Today = 'Today',
    LastWeek = 'LastWeek',
    NextWeek = 'NextWeek',
    ThisWeek = 'ThisWeek',
    LastMonth = 'LastMonth',
    NextMonth = 'NextMonth',
    ThisMonth = 'ThisMonth',
    ThisYear = 'ThisYear',
    NextYear = 'NextYear',
}

type DemandOptionModel = {
    label: string
    id: DemandOptionType
}

function ProductInfoCart(props: Props) {
    const [customerAmount, setCustomerAmount] = useState<number>(0)
    const [linkedActivityCounts, setLinkedActivityCounts] = useState<number>(0)

    const demandOptions: DemandOptionModel[] = [
        { id: DemandOptionType.AllTime, label: 'All-Time' },
        { id: DemandOptionType.Today, label: 'Today' },
        { id: DemandOptionType.LastWeek, label: 'Last Week' },
        { id: DemandOptionType.NextWeek, label: 'Next Week' },
        { id: DemandOptionType.ThisWeek, label: 'This Week' },
        { id: DemandOptionType.LastMonth, label: 'Last Month' },
        { id: DemandOptionType.NextMonth, label: 'Next Month' },
        { id: DemandOptionType.ThisMonth, label: 'This Month' },
        { id: DemandOptionType.ThisYear, label: 'This Year' },
        { id: DemandOptionType.NextYear, label: 'Next Year' },
    ]

    const [selactedDemand, setSelactedDemand] = useState<DemandOptionModel>(demandOptions[0])
    const [demandActivities, setDemandActivities] = useState<ProductCountWithActivityDateDto[]>([])
    const [filteredDemandActivities, setFilteredDemandActivities] = useState<ProductCountWithActivityDateDto[]>([])

    const getCustomerAmountCallback = useCallback(async () => {
        const response = await ProductService.getProductCustomers(props.product.id)
        setCustomerAmount(response.length)
    }, [props.product.id])

    const getLinkedActivityCountsCallback = useCallback(async () => {
        let response = await ProductService.getLinkedActivityCount(props.product.id)
        setDemandActivities(response)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.product.id, props.activityLengthChanged])

    useEffect(() => {
        getCustomerAmountCallback()
        getLinkedActivityCountsCallback()
    }, [getCustomerAmountCallback, getLinkedActivityCountsCallback])

    useEffect(() => {
        let output = demandActivities
        if (selactedDemand.id === DemandOptionType.Today) {
            output = output.filter((child) => {
                const clientDate = `${dayjs().get('date')}${dayjs().get('month')}${dayjs().get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).get('date')}${dayjs(child.activity.dueDate).get('month')}${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.LastWeek) {
            output = output.filter((child) => {
                dayjs.extend(weekOfYear)
                const currentDate = dayjs()
                const lastWeek = currentDate.subtract(1, 'week')
                const clientDate = `${lastWeek.week()}${lastWeek.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).week()}${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.NextWeek) {
            output = output.filter((child) => {
                dayjs.extend(weekOfYear)
                const currentDate = dayjs()
                const nextWeek = currentDate.add(1, 'week')
                const clientDate = `${nextWeek.week()}${nextWeek.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).week()}${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.ThisWeek) {
            output = output.filter((child) => {
                dayjs.extend(weekOfYear)
                const currentDate = dayjs()
                const clientDate = `${currentDate.week()}${currentDate.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).week()}${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.LastMonth) {
            output = output.filter((child) => {
                const currentDate = dayjs()
                const lastMonth = currentDate.subtract(1, 'month')
                const clientDate = `${lastMonth.get('month')}${lastMonth.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).get('month')}${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.NextMonth) {
            output = output.filter((child) => {
                const currentDate = dayjs()
                const nextMonth = currentDate.add(1, 'month')
                const clientDate = `${nextMonth.get('month')}${nextMonth.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).get('month')}${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.ThisMonth) {
            output = output.filter((child) => {
                const currentDate = dayjs()
                const clientDate = `${currentDate.get('month')}${currentDate.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).get('month')}${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.ThisYear) {
            output = output.filter((child) => {
                const currentDate = dayjs()
                const clientDate = `${currentDate.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).get('year')}`
            })
        } else if (selactedDemand.id === DemandOptionType.NextYear) {
            output = output.filter((child) => {
                const currentDate = dayjs()
                const nextYear = currentDate.add(1, 'year')
                const clientDate = `${nextYear.get('year')}`
                return clientDate === `${dayjs(child.activity.dueDate).get('year')}`
            })
        }
        setFilteredDemandActivities([...output])
    }, [demandActivities, selactedDemand.id])

    useEffect(() => {
        let total = 0
        for (let i = 0; i < filteredDemandActivities.length; i++) {
            total += filteredDemandActivities[i].quantity
        }
        setLinkedActivityCounts(total)
    }, [filteredDemandActivities])
    return (
        <>
            {props.product.images && props.product.images?.length > 0 ? (
                <div className="product-image-card">
                    <div className="product-image-card__column">
                        <div className="product-image-card__column__line">
                            <div className="product-image-card__column__line__title">Unit of Measure</div>
                            {props.product.uom ? (
                                <div className="product-image-card__column__line__value">{props.product.uom}</div>
                            ) : (
                                <span className="product-image-card__column__line__add">+add</span>
                            )}
                        </div>
                        <div className="product-image-card__column__line">
                            <div className="product-image-card__column__line__title">Packaging</div>
                            {props.product.pkg ? (
                                <div className="product-image-card__column__line__value">{props.product.pkg}</div>
                            ) : (
                                <span className="product-image-card__column__line__add">+add</span>
                            )}
                        </div>
                        <div className="product-image-card__column__line">
                            <div className="product-image-card__column__line__title">Unit Price</div>
                            {props.product.price && props.product.price.toString() !== '0' ? (
                                <div className="product-image-card__column__line__value">
                                    <div className="price-section">
                                        {priceFormatter(props.product.price)} <span className="slash-package"> / {props.product.pkg}</span>
                                    </div>
                                </div>
                            ) : (
                                <span className="product-image-card__column__line__add" onClick={props.handleEdit}>
                                    +add
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="product-image-card__column product-image-card__column--center">
                        <div className="product-image-card__column__header">Demand</div>
                        <div>
                            <SearchableDropdown
                                data={demandOptions}
                                selectedItem={selactedDemand}
                                onChange={(_event, data) => {
                                    setSelactedDemand(demandOptions.find((child) => child.id === data.id)!)
                                }}
                            />
                        </div>
                        <div className="product-image-card__column__content">{linkedActivityCounts}</div>
                        <div className="product-image-card__column__footer" onClick={props.onAddDemand}>
                            {linkedActivityCounts ? 'details' : '+add'}
                        </div>
                    </div>
                    <div className="product-image-card__column product-image-card__column--center">
                        <div className="product-image-card__column__header">No. of Customers</div>
                        <div className="product-image-card__column__content">{customerAmount}</div>
                        <div className="product-image-card__column__footer" onClick={props.onClickManageCustomer}>
                            +manage
                        </div>
                    </div>
                </div>
            ) : (
                <div className="product-info-card">
                    <div className="product-card product-card--left">
                        <div className="product-card__line">
                            <div className="product-card__line__title">Unit of Measure</div>
                            {props.product.uom ? (
                                <span className="product-card__line__value">{props.product.uom}</span>
                            ) : (
                                <span className="product-card__line__add" onClick={props.handleEdit}>
                                    +add
                                </span>
                            )}
                        </div>
                        <div className="product-card__line">
                            <div className="product-card__line__title">Packaging</div>
                            {props.product.pkg ? (
                                <span className="product-card__line__value">{props.product.pkg}</span>
                            ) : (
                                <span className="product-card__line__add" onClick={props.handleEdit}>
                                    +add
                                </span>
                            )}
                        </div>
                        <div className="product-card__line product-card__line--unit-price">
                            <div className="product-card__line__title">Unit Price</div>
                            {props.product.price && props.product.price.toString() !== '0' ? (
                                <span className="product-card__line__value">
                                    <div className="price-section">
                                        {priceFormatter(props.product.price)} <span className="slash-package">/{props.product.pkg}</span>
                                    </div>
                                </span>
                            ) : (
                                <span className="product-card__line__add" onClick={props.handleEdit}>
                                    +add
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="product-card product-card--right">
                        <div className="product-card__line">
                            <div className="product-card__line--header">
                                <span className="product-card__line--header__title">Demand</span>
                            </div>
                            <div>
                                <SearchableDropdown
                                    data={demandOptions}
                                    selectedItem={selactedDemand}
                                    onChange={(_event, data) => {
                                        setSelactedDemand(demandOptions.find((child) => child.id === data.id)!)
                                    }}
                                />
                            </div>
                            <div className="product-card__line--content">{linkedActivityCounts}</div>
                            <div className="product-card__line--footer" onClick={props.onAddDemand}>
                                {linkedActivityCounts ? 'details' : '+add'}
                            </div>
                        </div>
                        <div className="product-card__line">
                            <div className="product-card__line--header">
                                <span className="product-card__line--header__title" style={{ justifyContent: 'center', width: '100%' }}>
                                    No. of Customers
                                </span>
                            </div>
                            <div className="product-card__line--content">{customerAmount}</div>
                            <div className="product-card__line--footer" onClick={props.onClickManageCustomer}>
                                +manage
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ProductInfoCart
