import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Button from '../Button'
import CartItem from '../CartItem'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'
import AdditionalForm from '../AdditionalForm'
import './Cart.scss'
import Icons, { IconType } from '../../Icon'
import PopoverItem from '../PopoverItem'

function Cart() {
    const {
        contextEquipments,
        contextProducts,
        contextCustomParts,
        cartInformation,
        contextCustomServices,
        removePart,
        addPart,
        setPartCount,
        getTotalPrice,
        removeProduct,
        setProductCount,
        addProduct,
        clearPart,
        clearProduct,
        toggleCartView,
        addCustomPart,
        removeCustomPart,
        removeAdditionalItem,
        setAdditionalItemCount,
        removeAdditionalServiceItem,
        addCustomService,
        removeCustomService,
        setAdditionalServiceItemCount,
        addAdditionalItem,
        addAdditionalServiceItem,
        purchaseWithoutCustomer,
        customerNameOfPurchaseWithoutCustomer,
        reOrderNotes,
    } = useContext(CheckoutContext)
    const { savedCartInformation } = useContext(CheckoutContext)
    return (
        <div className="shopping-cart-container">
            <div className="cart-header">
                <div className="cart-title">
                    <Icons type={IconType.Transactions} />
                    <h4>{savedCartInformation?.name ? savedCartInformation.name : 'Cart'}</h4>
                </div>
                <div onClick={() => toggleCartView()} className="cart-close">
                    <PopoverItem popoverContent="Collapse cart">
                        <Icons type={IconType.Close} />
                    </PopoverItem>
                </div>
            </div>
            <div className="cart-body">
                {cartInformation.purchaseForCustomerName && (
                    <div className="cart-wrapper">
                        <div className="cart-content-name">CUSTOMER</div>
                        <span className="selected-customer-name">{cartInformation.purchaseForCustomerName}</span>
                        {reOrderNotes && <div className="reorder-notes">{reOrderNotes}</div>}
                    </div>
                )}

                {contextEquipments.map((e, i) => {
                    return (
                        <div key={'eq' + i.toString()} className="cart-wrapper">
                            {e.equipment.parent ? (
                                <>
                                    <Link to={`/customer/${cartInformation.purchaseForCustomerId}/equipment/${e.equipment.parent.id}?activeTab=1`}>
                                        <div className="cart-parent-name">{e.equipment.parent.name}</div>
                                    </Link>
                                    <Link to={'/equipment/' + e.equipment.parent.id + '/' + e.equipment.id}>
                                        <div className="cart-content-name">{e.equipment.name}</div>
                                        <div className="cart-content-description">{e.equipment.serialNumber}</div>
                                    </Link>
                                </>
                            ) : (
                                <Link to={`/customer/${cartInformation.purchaseForCustomerId}/equipment/${e.equipment.id}?activeTab=1`}>
                                    <div className="cart-content-name">{e.equipment.name}</div>
                                    <div className="cart-content-description">{e.equipment.serialNumber}</div>
                                </Link>
                            )}
                            {e.parts.map((p, i) => {
                                return (
                                    <CartItem
                                        key={'part' + i.toString()}
                                        name={p.part.name}
                                        count={p.count}
                                        price={p.part.price || '-'}
                                        description={p.part?.part_no}
                                        onClickPlus={() => addPart(e.equipment, p.part)}
                                        onClickMinus={() => removePart(e.equipment, p.part)}
                                        onChangeInput={(value) => setPartCount(e.equipment, p.part, value)}
                                        onClickTrash={() => clearPart(e.equipment, p.part)}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
                {contextProducts.length > 0 && (
                    <div className="cart-wrapper">
                        <Link to="/products">
                            <div className="cart-content-name">PRODUCTS</div>
                        </Link>
                        {contextProducts.map((p, i) => {
                            return (
                                <CartItem
                                    key={'part' + i.toString()}
                                    name={p.product.name}
                                    price={p.product.price || '-'}
                                    count={p.count}
                                    onClickPlus={() => addProduct(p.product)}
                                    onClickMinus={() => removeProduct(p.product)}
                                    onChangeInput={(count) => setProductCount(p.product, count)}
                                    description={p.product.note}
                                    onClickTrash={() => clearProduct(p.product)}
                                />
                            )
                        })}
                    </div>
                )}
                {contextCustomParts?.length > 0 && (
                    <div className="cart-wrapper">
                        <div className="cart-content-name">CUSTOM PARTS</div>
                        {contextCustomParts.map((p, i) => {
                            return (
                                <CartItem
                                    key={'part' + i.toString()}
                                    name={p.customPart.name}
                                    price={p.customPart.price || '-'}
                                    count={p.count}
                                    onClickPlus={() => addCustomPart(p.customPart)}
                                    onClickMinus={() => removeCustomPart(p.customPart)}
                                    onChangeInput={(count) => setAdditionalItemCount(p.customPart, count)}
                                    description={p.customPart.note}
                                    onClickTrash={() => removeAdditionalItem(p.customPart)}
                                />
                            )
                        })}
                    </div>
                )}
                {contextCustomServices.length > 0 && (
                    <div className="cart-wrapper">
                        <div className="cart-content-name">CUSTOM SERVICES</div>
                        {contextCustomServices.map((p, i) => {
                            return (
                                <CartItem
                                    key={'part' + i.toString()}
                                    name={p.customService.name}
                                    price={p.customService.price || '-'}
                                    count={p.count}
                                    description={p.customService.note}
                                    onClickTrash={() => removeAdditionalServiceItem(p.customService)}
                                    onClickPlus={() => addCustomService(p.customService)}
                                    onClickMinus={() => removeCustomService(p.customService)}
                                    onChangeInput={(count) => setAdditionalServiceItemCount(p.customService, count)}
                                />
                            )
                        })}
                    </div>
                )}
            </div>
            <AdditionalForm addAdditionalItem={addAdditionalItem} addAdditionalServiceItem={addAdditionalServiceItem} className="cart-add-more" />
            <div className="cart-footer">
                <div className="total-wrapper">
                    <div className="total-price">
                        <div className="total-price-title">Total Price:</div>
                        <div className="total-price-value"> {getTotalPrice()}</div>
                    </div>
                </div>
            </div>
            {(purchaseWithoutCustomer || cartInformation.purchaseForCustomerId) && (
                <div className="cart-footer">
                    <Link to="/checkoutPage">
                        <Button disabled={purchaseWithoutCustomer && !customerNameOfPurchaseWithoutCustomer} onClick={() => toggleCartView(false)} className="button">
                            Checkout
                        </Button>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default Cart
