import React from 'react';
import NoDataInfo from '../NoDataInfo';

export interface MListProps {
    type?: 'vertical' | 'horizontal',
    children?: MListProps[],
    customStyle?: React.CSSProperties,
    element?: React.ReactNode,
    text?: {
        title: string,
        color?: 'blue' | 'black' | 'gray',
        fontSize?: 'small' | 'medium' | 'large'
        bold?: boolean
    }
};

interface Props {
    items: MListProps[]
    mobileOnDesktop?: boolean,
}

function itemGenerator(props: MListProps, index?: number | string) {
    if (props.text) {
        return <div key={'text-' + index} className={`m-list-text${props.text.bold ? ' bold' : ''}${props.text.color ? ' ' + props.text.color : ''}${props.text.fontSize ? ' ' + props.text.fontSize : ''}`}>
            {props.text.title}
        </div>
    } else if (props.type && props.children) {
        return <div className="m-list-obj-wrapper" style={{ flexDirection: props.type === 'horizontal' ? 'row' : 'column', ...props.customStyle }} key={'obj-' + index}>
            {props.children.map((p, i) => {
                return itemGenerator(p, index + 'child-' + i)
            })}
        </div>
    } else if (props.element) {
        return <React.Fragment key={index + '-elem'}>{props.element}</React.Fragment>
    }
}

function MList(props: Props) {
    if (props.items.length === 0)
        return <NoDataInfo />

    return <div key={'m-list-container'} className={`m-list-container ${props.mobileOnDesktop && 'desktop-view'}`}>
        {props.items.map((p, i) => <div className="m-list-item" key={'item-' + i}>{itemGenerator(p, i)}</div>)}
    </div>;
}

export default MList;
