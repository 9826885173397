import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAnalytics } from "firebase/analytics";
import config from './firebase-config.js';
import configDev from './firebase-dev-config.js';

const production_host_names = ["app.monittor.com"];
export const firebaseApp = initializeApp(production_host_names.includes(window.location.hostname) ? config : configDev);
// const app = initializeApp(configDev);

initializeAnalytics(firebaseApp);

export const db = getFirestore();