import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { AddAddressModel, AddressModel, UpdateAddressModel } from '../../models/Ui/Address'

export default class AddressService {
    async addAddress(data: AddAddressModel): Promise<AddressModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.Address, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error in AddressService.AddAddress')
        }
    }

    async getAll(): Promise<AddressModel[]> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.Address, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return []
        }
    }

    async delete(id: string): Promise<AddressModel> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.Address + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error in AddressService.delete')
        }
    }

    async update(data: UpdateAddressModel): Promise<AddressModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.Address + data.id, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Error in AddressService.update')
        }
    }
}
