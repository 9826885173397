import { Tooltip } from '@mui/material'
import { FC } from 'react'
import SearchableDropdown from '../../../../../../components/Dropdown/SearchableDropdown'
import Icons, { IconType } from '../../../../../../Icon'
import { ContractOutputModel } from '../../../../../../models/Ui/Contract'
import './style.scss'

interface Props {
    contractList: ContractOutputModel[]
    selectedContractId: string
    onChangeContractId: (val: string) => void
    selectedContract?: { id: string; text: string }
    disabled?: boolean
}

const ActivityContract: FC<Props> = ({ contractList, selectedContractId, onChangeContractId, selectedContract, disabled = false }) => {
    const setSelectedContract = () =>
        selectedContractId
            ? {
                  id: selectedContract?.id || contractList.find((child) => child.id === selectedContractId)?.id || '',
                  label: selectedContract?.text || contractList.find((child) => child.id === selectedContractId)?.title || '',
                  number: contractList.find((child) => child.id === selectedContractId)?.contractNumber ?? '',
              }
            : undefined

    return (
        <div className="activity-contract-container">
            <SearchableDropdown
                label={!selectedContractId ? 'SELECT A CONTRACT TO LINK' : undefined}
                data={contractList.map((contract) => ({ id: contract.id, label: contract.title }))}
                onChange={(event, data) => {
                    onChangeContractId(data?.id ?? '')
                }}
                selectedItem={setSelectedContract()}
                disabled={disabled}
            />
            {setSelectedContract() && (
                <div className="selected-contract-cart">
                    <div className="left-side">
                        <div className="contract-title">{setSelectedContract()?.label}</div>
                        <div className="contract-number">{setSelectedContract()?.number}</div>
                    </div>
                    {!disabled && (
                        <Tooltip title="Unlink">
                            <div className="unlink-button-wrapper">
                                <div className="unlink-button" onClick={() => onChangeContractId('')}>
                                    <Icons type={IconType.Close} />
                                </div>
                            </div>
                        </Tooltip>
                    )}
                </div>
            )}
        </div>
    )
}

export default ActivityContract
