import React from 'react'
import { OrderModel } from '../../../../../../models/New/Order'
import { Tabs } from '../../../../../../models/Ui/Tab'
import { calculateTotalPrice, standardDate } from '../../../../../../utils/helpers'
import './Card.scss'

interface IProps {
    orders: OrderModel[]
    handleSeeAll: (id: Tabs) => void
    handleQuickCart: () => void
}

function Orders(props: IProps) {
    const { orders, handleSeeAll, handleQuickCart } = props

    return (
        <div className="overview-card">
            <div className="item">
                <div className="item-header">
                    <span className="title">Orders</span>
                    {orders.length > 0 && (
                        <div onClick={() => handleSeeAll(Tabs.Orders)} className="action-button">
                            See All
                        </div>
                    )}
                </div>
                {orders.length > 0 ? (
                    orders.slice(0, 4).map((order) => {
                        return (
                            <div className="line">
                                <span className="value">{order.transactionNumber}</span>
                                <span className="date">{standardDate(order.creationDate)}</span>
                                <div className="dot" />
                                <span className="price-value">{calculateTotalPrice(order)}</span>
                            </div>
                        )
                    })
                ) : (
                    <>
                        <span className="value">You currently do not have any orders from this customer.</span>
                        <div onClick={handleQuickCart} className="action-button center">
                            +open quick cart
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Orders
