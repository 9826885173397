import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import './BasicDropdown.scss'
import Icons, { IconType } from '../../../Icon'
import PopoverItem from '../../PopoverItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

export interface IDropdownItem {
    id: any
    text: string
}

interface BasicDropdownProps {
    menuItems: IDropdownItem[]
    defaultValue?: string
    leftIcon?: IconType
    label?: string
    selectedItem: { id: any | string; text: string } | undefined
    wrapperClass?: string
    disabled?: boolean
    isClearButton?: boolean
    isClearLabel?: string
    required?: boolean
    handleClearInput?: () => void
    onChange: (e: IDropdownItem) => void
    classname?: string
}

const BasicDropdown = (props: BasicDropdownProps) => {
    const clearInput = () => {
        props.handleClearInput!()
    }

    const onChange = (event: SelectChangeEvent) => {
        const selectItem = props.menuItems.find((item) => item.id === event.target.value) || { id: event.target.value, text: event.target.name }
        props.onChange(selectItem)
    }
    const ITEM_HEIGHT = 90
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }

    return (
        <FormControl className={`basic-dropdown-container ${props.classname}`}>
            <div className="basic-input-container">
                <InputLabel className="basic-dropdown" id="basic-dropdown-id">
                    {props.label}
                    {props.required && <strong className="required"> *</strong>}
                </InputLabel>
                {props.isClearButton && props.selectedItem?.text && (
                    <div onClick={clearInput} className="clear-icon">
                        <PopoverItem popoverContent={props.isClearLabel || ''}>
                            <FontAwesomeIcon icon={faClose} />
                        </PopoverItem>
                    </div>
                )}
            </div>
            <Select
                disabled={props.disabled}
                labelId="basic-dropdown-id"
                label={props.label}
                value={props.selectedItem?.id}
                onChange={onChange}
                renderValue={(value) => {
                    return (
                        <Box sx={{ display: 'flex', gap: '0.25rem', alignItems: 'center', paddingTop: '0.5rem' }}>
                            {props.leftIcon && <Icons type={props.leftIcon} />}
                            <span style={{ fontSize: 'var(--body-text-size-md)' }}>{props.selectedItem?.text || props.menuItems.find((item) => item.id === value)?.text}</span>
                        </Box>
                    )
                }}
                MenuProps={MenuProps}
            >
                {props.menuItems.map((menuItem: IDropdownItem) => (
                    <MenuItem value={menuItem.id} style={{ height: '2.5rem' }}>
                        {menuItem.text.slice(0, 50)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default BasicDropdown
