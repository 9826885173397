import { MonitorApi } from '../../api'
import { ServiceFunctionLookupTable } from '../../api/serviceFunctionLookupTable'
import { PaginationInputModel, PaginationResponseModel } from '../../models/BaseModel'
import {
    CustomEquipmentListModel,
    EquipmentListModel,
    CreateEquipmentModel,
    EquipmentModel,
    UpdateCustomEquipmentModel,
    LinkToCustomerModel,
    LinkTemplateFromAssemblyModel,
    UpdateTemplateFromAssemblyModel,
} from '../../models/New/Equipment'
import { PushPullDocumentModel } from '../../models/Ui/Document'
import { NewUpdateEquipmentModel, EquipmentDrawingModel, EquipmentDrawingInputModel } from '../../models/New/Equipment'

class EquipmentService {
    async getTemplatesForManufacturer(params: PaginationInputModel): Promise<PaginationResponseModel<EquipmentListModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.getTemplatesForManufacturer, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async getCustomsForManufacturer(params: PaginationInputModel): Promise<PaginationResponseModel<CustomEquipmentListModel>> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.getCustomsForManufacturer, { params })
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }
    async getCustomsForTemplate(id: string): Promise<CustomEquipmentListModel[]> {
        try {
            const response = await MonitorApi.get(`/equipment/template/${id}/custom-equipments`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async createEquipment(data: CreateEquipmentModel): Promise<EquipmentListModel> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.CreateEquipment, data, {})
            return response as any
        } catch (error: any) {
            throw new Error(error)
        }
    }

    async get(id: string): Promise<EquipmentModel> {
        try {
            const response = await MonitorApi.get(ServiceFunctionLookupTable.FindEquipmentById + id, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }
    async getParentEquipmentTreeView(id: string, assemblyId: string): Promise<EquipmentModel> {
        try {
            const response = await MonitorApi.get('/equipment/' + id + '/assembly/' + assemblyId, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not found!')
        }
    }

    async update(equipmentId: string, data: NewUpdateEquipmentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateEquipment + equipmentId, { ...data }, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
    async createEquipmentPartList(equipmentId: string, data: NewUpdateEquipmentModel[]): Promise<boolean> {
        try {
            const response = await MonitorApi.post(`/equipment/${equipmentId}/partList`, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async updateCustomEquipment(equipmentId: string, data: UpdateCustomEquipmentModel): Promise<UpdateCustomEquipmentModel> {
        try {
            const response = await MonitorApi.put(ServiceFunctionLookupTable.UpdateCustom + equipmentId, { ...data }, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Equipment not updated!')
        }
    }

    async delete(equipmentId: string): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(ServiceFunctionLookupTable.DeleteEquipment + equipmentId, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async unlinkEquipmentFromDocument(documentId: string, equipmentId: string): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(`/equipment/${equipmentId}/document/${documentId}`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async deleteEquipmentDrawing(drawingId: string): Promise<boolean> {
        try {
            const response = await MonitorApi.delete(`/equipment/drawing/${drawingId}`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async updateDocumentsEquipment(data: PushPullDocumentModel): Promise<boolean> {
        try {
            const response = await MonitorApi.post(ServiceFunctionLookupTable.UpdateDocumentsEquipment, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async linkDocument(equipmentId: string, documentIds: string[]): Promise<boolean> {
        try {
            await MonitorApi.post(`/equipment/${equipmentId}/linkDocuments`, { documentIds }, {})
            return true
        } catch (error: any) {
            console.error(error)
            return false
        }
    }

    async linkCustomer(param: LinkToCustomerModel): Promise<boolean> {
        try {
            await MonitorApi.post(`/equipment/linkToCustomer`, param, {})
            return true
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
    async pushPartListsToCustomEquipments(id: string): Promise<boolean> {
        try {
            await MonitorApi.post(`/equipment/template/` + id + `/partLists/push-to-custom`, {}, {})
            return true
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
    async pushTemplatesEverythingToCustomEquipment(id: string): Promise<boolean> {
        try {
            await MonitorApi.post(`/equipment/template/` + id + `/push-everything-to-custom`, {}, {})
            return true
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
    async pushTemplateToCustomEquipment(id: string): Promise<boolean> {
        try {
            await MonitorApi.post(`/equipment/template/` + id + `/push-to-custom`, {}, {})
            return true
        } catch (error: any) {
            console.error(error)
            return false
        }
    }
    async linkTemplateToAssembly(topEquipmentId: string, payload: LinkTemplateFromAssemblyModel): Promise<EquipmentModel> {
        try {
            const response = await MonitorApi.post(`/equipment/${topEquipmentId}/link-template-to-assembly`, payload, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Successfully linked')
        }
    }

    async updateTemplateToAssembly(payload: UpdateTemplateFromAssemblyModel): Promise<EquipmentModel> {
        try {
            const response = await MonitorApi.post(`/equipment/update-templates-parent-in-assembly-tree`, payload, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('Successfully linked')
        }
    }

    async unLinkTemplateFromAssembly(topEquipmentId: string, payload: LinkTemplateFromAssemblyModel): Promise<EquipmentModel> {
        try {
            const response = await MonitorApi.post(`/equipment/${topEquipmentId}/unlink-template-from-assembly`, payload, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error()
        }
    }

    async createEquipmentDrawing(equipmentId: string, data: EquipmentDrawingInputModel): Promise<EquipmentDrawingModel> {
        try {
            const response = await MonitorApi.post(`equipment/${equipmentId}/drawings`, data, {})
            return response as any
        } catch (error: any) {
            throw new Error('create equipment drawing')
        }
    }

    async updateDrawingMappingData(equipmentDrawingId: string, data: EquipmentDrawingInputModel): Promise<EquipmentDrawingModel> {
        try {
            const response = await MonitorApi.put(`/equipment/drawing/${equipmentDrawingId}`, data, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error('set drawing mapping data')
        }
    }
    async getTemplateLinkedAsAssembly(templateId: string): Promise<EquipmentModel[] | any> {
        try {
            const response = await MonitorApi.get(`/equipment/template/${templateId}/as-assembly`, {})
            return response as any
        } catch (error: any) {
            console.error(error)
            throw new Error()
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EquipmentService()
