import { useContext, useEffect, useState } from 'react'
import Button from '../../../components/Button'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Table from '../../../components/Table'
import { arraySort, isAnyExistsPriceInParts } from '../../../utils/helpers'
import { MListProps } from '../../../components/Table/MList'
import './ProductList.scss'
import SearchInput from '../../../components/SearchInput'
import EasyIcon from '../../../components/EasyIcon'
import Icons, { IconType } from '../../../Icon'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import ProductListTable from './ProductListTable'
import { toast } from 'react-toastify'
import ProductService from '../../../services/UiService/Product'
import { ProductListModel } from '../../../models/New/Product'
import { ProductModel } from '../../../models/Ui/Product'
import { priceFormatter } from './../../../utils/helpers'
import { CustomerManufacturerContext } from '../../../store/CustomerDashboardContext'
import { CustomerCheckoutContext } from '../../../store/CustomerCheckoutContext/CustomerCheckoutContext'
import Icon from '../../../Icon'
function ProductList() {
    const checkoutContext = useContext(CustomerCheckoutContext)
    const { selectedManufacturerInfo: selectedManufacturer } = useContext(CustomerManufacturerContext)
    const [products, setProducts] = useState([] as ProductListModel[])
    const [filteredData, setFilteredData] = useState([] as ProductListModel[])
    const [selectedView, setSelectedView] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [searchKey, setSearchKey] = useState<string>('')

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Products',
        },
    ]

    useEffect(() => {
        async function getData() {
            setLoading(true)
            try {
                const productList = await ProductService.getsForCustomerList({ page: 0, size: 9999 })
                setProducts(productList.content)
            } catch (e: any) {
                toast.error(e)
            }

            setLoading(false)
        }
        getData()
    }, [selectedManufacturer])

    const onAddToCart = (product: ProductListModel) => {
        const newData: ProductModel = {
            id: product.id,
            part_no: product.partNumber,
            name: product.name,
            category: product.category,
            vendor: product.brand,
            uom: product.uom,
            pkg: product.pkg,
            price: product.price ? product.price : '-',
            adjustedPrice: product.price ? product.price : 0,
            qty: product.quantity,
            manufacturer: {
                name: '',
                id: product.companyId,
                email: '',
            },
            attachments: [],
        }
        checkoutContext.addProduct(newData)
    }

    const categoryImageRender = (categoryName: string) => {
        return (
            <div className="category-image" style={{ display: 'none' }}>
                <img src={`/assets/images/${categoryName ? (categoryName === 'Chemicals' ? 'chem' : categoryName) : 'default'}.png`} alt="category" />
            </div>
        )
    }

    const getListObj = (): MListProps[] | undefined =>
        arraySort(products, 'name').map((product: ProductListModel) => {
            return {
                type: 'horizontal',
                children: [
                    {
                        element: categoryImageRender(product.category),
                    },
                    {
                        type: 'vertical',
                        children: [
                            {
                                type: 'horizontal',
                                customStyle: {
                                    justifyContent: 'space-between',
                                },
                                children: [
                                    {
                                        element: (
                                            <div className="element-wrapper">
                                                <div className="card-wrapper">
                                                    <div className="column-card">
                                                        <div className="title">{product.name}</div>
                                                        <div className="desc-wrapper">
                                                            <div className="desc-column">
                                                                <div>
                                                                    <div className="desc-alg">
                                                                        <span className="label">Product Category: </span>
                                                                        <span className="desc"> {product.category}</span>
                                                                    </div>
                                                                    <div className="desc-alg">&bull;</div>
                                                                    <div className="desc-alg">
                                                                        <span className="label">Part number:</span>
                                                                        <span className="desc">{product.partNumber}</span>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="desc-alg">
                                                                        <span className="label">Brand:</span>
                                                                        <span className="desc"> {product.brand}</span>
                                                                    </div>
                                                                    <div className="desc-alg">&bull;</div>
                                                                    <div className="desc-alg">
                                                                        <span className="label">Manufacturer:</span>
                                                                        <span className="desc">{product.brand}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Button onClick={() => onAddToCart(product)}>Add to Cart</Button>
                                                </div>
                                                <div className="long-text">{product.description}</div>
                                            </div>
                                        ),
                                    },
                                ],
                            },
                            {},
                        ],
                    },
                ],
            }
        })

    const handleSearchedData = (data: ProductListModel[]) => {
        setFilteredData(data)
    }

    return (
        <PageWrapper breadcrumb={breadcrumb}>
            {loading && <Loading />}
            <ContentBox
                className="customer-product-list-wrapper"
                title="Products"
                headerIcon={<EasyIcon icon={IconType.Product} />}
                headerRight={
                    <div className="header-right">
                        <SearchInput data={products} handleSearch={handleSearchedData} searchKeys={['name', 'description', 'category', 'partNumber', 'brand', 'brand']} setDefaultSearch={searchKey} />
                        <Button className="toggle-button" onClick={() => setSelectedView(!selectedView)}>
                            {!selectedView ? <Icons type={IconType.Menus} /> : <Icons type={IconType.CardView} />}
                        </Button>
                    </div>
                }
            >
                {selectedView ? (
                    isAnyExistsPriceInParts(products) ? (
                        <Table
                            titles={['Name', 'Description', 'Brand', 'Category', 'Uom', 'Pkg.', 'Part number', 'Vendor', 'Price', '']}
                            data={filteredData.map((p, i) => {
                                return {
                                    name: p.name,
                                    description: p.description,
                                    brand: p.brand,
                                    category: p.category,
                                    uom: p.uom,
                                    pkg: p.pkg,
                                    partNo: p.partNumber,
                                    vendor: p.brand,
                                    price: priceFormatter(p.price),
                                    action: <Button onClick={() => onAddToCart(p)}>Add to Cart</Button>,
                                }
                            })}
                            align={['center', 'center', 'left', 'center', 'center', 'center']}
                            persistMobile={true}
                            width={['68px', '68px', 'auto', '168px', '68px']} // 68px + 32px(left-right padding) = 100px
                            listObject={getListObj()}
                        />
                    ) : (
                        <Table
                            titles={['Name', 'Description', 'Brand', 'Category', 'Uom', 'Pkg.', 'Part number', 'Vendor', '']}
                            data={filteredData.map((p, i) => {
                                return {
                                    name: p.name,
                                    description: p.description,
                                    brand: p.brand,
                                    category: p.category,
                                    uom: p.uom,
                                    pkg: p.pkg,
                                    partNo: p.partNumber,
                                    vendor: p.brand,
                                    action: <Button onClick={() => onAddToCart(p)}>Add to Cart</Button>,
                                }
                            })}
                            align={['center', 'center', 'left', 'center', 'center']}
                            width={['68px', '68px', 'auto', '68px']} // 68px + 32px(left-right padding) = 100px
                            listObject={getListObj()}
                        />
                    )
                ) : (
                    <ProductListTable
                        setSearch={(e) => {
                            if (searchKey === e) {
                                setSearchKey('')
                            } else {
                                setSearchKey(e)
                            }
                        }}
                        data={filteredData.map((p) => {
                            return {
                                partNumber: p.partNumber,
                                name: p.name,
                                category: p.category,
                                brand: p.brand,
                                description: p.description,
                                uom: p.uom,
                                pkg: p.pkg,
                                price: priceFormatter(p.price),
                                id: p.id,
                                button: (
                                    <Button className="add-to-cart" onClick={() => onAddToCart(p)}>
                                        <Icon type={IconType.AddToCart} />
                                    </Button>
                                ),
                            }
                        })}
                    />
                )}
            </ContentBox>
        </PageWrapper>
    )
}

export default ProductList
