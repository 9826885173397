import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Icon, { IconType } from '../../Icon'
import { Color } from '../../models/enums'
import { priceFormatter } from '../../utils/helpers'
import './CartItem.scss'

interface Props {
    name: string
    subName?: string
    partNumber?: string
    serialNumber?: string
    count: number
    price: number | '-'
    onClickPlus?: React.MouseEventHandler<HTMLDivElement>
    onClickMinus?: React.MouseEventHandler<HTMLDivElement>
    onClickTrash?: React.MouseEventHandler<HTMLDivElement>
    onChangeInput?: (value: number) => void
}

function CustomerCartItem(props: Props) {
    return (
        <div className="customer-cart-item">
            <div className="customer-cart-header">
                <h1 className="name">{props.name}</h1>
                <span className="cart-price">{priceFormatter(props.price)}</span>
            </div>

            <div className="cart-label">
                {props.subName && <span>{props.subName}</span>}
                {props.partNumber && (
                    <div className="label-line">
                        <span>Part number:</span>
                        <span>{props.partNumber}</span>
                    </div>
                )}

                {props.serialNumber && (
                    <div className="label-line">
                        <span>Serial number:</span>
                        <span>{props.serialNumber}</span>
                    </div>
                )}
            </div>

            <div className="action">
                <div className="counter">
                    <div onClick={props.onClickMinus} className="count-changer">
                        <Icon type={IconType.Minus} />
                    </div>
                    <input
                        onChange={(e) => props.onChangeInput && props.onChangeInput(e.target.valueAsNumber)}
                        onBlur={() => {
                            if (!props.count && props.onChangeInput) {
                                props.onChangeInput(1)
                            }
                        }}
                        type="number"
                        min={1}
                        value={props.count}
                    />
                    <div onClick={props.onClickPlus} className="count-changer">
                        <Icon type={IconType.Plus} />
                    </div>
                </div>
                <div onClick={props.onClickTrash} className="remove-wrapper">
                    <FontAwesomeIcon size="xs" color={Color.blue900} icon={faTrash} />
                </div>
            </div>
        </div>
    )
}

export default CustomerCartItem
