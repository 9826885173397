import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Color } from '../../models/enums'
import './Checkbox.scss'

interface Props {
    radioButton?: boolean
    checked?: boolean
    disabled?: boolean
    onChange: (val: boolean) => void
}

function Checkbox(props: React.PropsWithChildren<Props>) {
    return (
        <div className={`checkbox-container ${props.disabled ? 'disabled' : ''}`} onClick={() => props.onChange(!props.checked)}>
            {props.radioButton ? (
                <div className={props.checked ? 'radio-checked radio-checkbox' : 'radio-checkbox'}>{props.checked && <span className="radio-button-content"></span>}</div>
            ) : (
                <div className={props.checked ? 'checked checkbox' : 'checkbox'}>{props.checked && <FontAwesomeIcon icon={faCheck} color={Color.white} size="xs" />}</div>
            )}
        </div>
    )
}

export default Checkbox
