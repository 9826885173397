import { useCallback, useContext, useEffect, useState } from 'react'
import ContentBox from '../../components/ContentBox'
import Loading from '../../components/Loading'
import PageWrapper from '../PageWrapper'
import PurchaseTitle from './Components/Container/PurchaseTitle'
import './CheckoutPage.scss'
import Order from './Components/Order/Order'
import OrderRequirements from './Components/OrderRequirements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ConfirmationContext } from '../../store/ConfirmationContext'
import Input from '../../components/Input'
import ContactService from '../../services/UiService/Contact'
import { CustomerContactModel, CustomerEquipmentsModel } from '../../models/New/Customer'
import { toast } from 'react-toastify'
import { CheckoutContext } from '../../store/NewCheckoutContext/CheckoutContext'
import OrderService from './../../services/UiService/Order'
import { CreateOrderOrQuoteInput } from '../../models/Ui/Order'
import Modal from '../../components/Modal'
import { CheckoutType } from '../../models/Ui/Equipment'
import AuthService from '../../services/UiService/Auth'
import { useNavigate } from 'react-router'
import { ICartInformation, ISavedCartInformation, TechnicianViewType } from '../../store/models'
import CompanyService from '../../services/UiService/Company'
import { CompanyType } from '../../models/Ui/CompanyType'
import PopoverItem from '../../components/PopoverItem'
import { Tooltip } from '@mui/material'
import { SearchPartContext } from './../../store/SearchPartContext'

export enum CartActionTypes {
    SAVE_CART,
    PURCHASE,
    SEND_QUOTE,
}

enum SaveType {
    CreateCart = 'createCart',
    EditTitle = 'editTitle',
}

interface SaveModalInfoModel {
    modalVisible: boolean
    saveType?: SaveType
}

function CheckoutPage() {
    const [loading, setLoading] = useState<boolean>(false)
    const [contacts, setContacts] = useState<CustomerContactModel[]>()
    const [equipmentCount, setEquipmentCount] = useState<number>(0)
    const [equipmentList, setEquipmentList] = useState<CustomerEquipmentsModel[]>([])
    const [saveModalInfo, setSaveModalInfo] = useState<SaveModalInfoModel>({
        modalVisible: false,
    })

    const { confirm } = useContext(ConfirmationContext)
    const checkoutContext = useContext(CheckoutContext)
    const searchPartContext = useContext(SearchPartContext)
    const auth = new AuthService().getAuthUser()
    const navigate = useNavigate()
    const [title, setTitle] = useState(checkoutContext.savedCartInformation.name)

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onSuccessCallback = useCallback(
        async (action: CartActionTypes, data?: any) => {
            switch (action) {
                case CartActionTypes.SAVE_CART:
                    setSaveModalInfo({ modalVisible: false })
                    handleResetContext()
                    navigate('/orders')
                    break
                case CartActionTypes.PURCHASE:
                    handleResetContext()
                    navigate('/orders')
                    break
                case CartActionTypes.SEND_QUOTE:
                    handleResetContext()
                    navigate('/orders')
                    break
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate]
    )

    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    const getContactsCallback = useCallback(async () => {
        try {
            if (checkoutContext.cartInformation.purchaseForCustomerId) {
                const response = await ContactService.getCompanyContacts(checkoutContext.cartInformation.purchaseForCustomerId)
                setContacts(response)
            }
        } catch (e: any) {
            onErrorCallback(e)
        }
    }, [checkoutContext.cartInformation.purchaseForCustomerId, onErrorCallback])

    const getCustomerCallback = useCallback(async () => {
        const companyService = new CompanyService()
        try {
            if (checkoutContext.cartInformation.purchaseForCustomerId) {
                const response = await companyService.getEquipmentCount(checkoutContext.cartInformation.purchaseForCustomerId)
                setEquipmentCount(response)
                if (response === 1) {
                    const response = await companyService.getEquipmentList(checkoutContext.cartInformation.purchaseForCustomerId)
                    setEquipmentList(response)
                }
            }
        } catch (e: any) {
            toast.error(e)
        }
    }, [checkoutContext.cartInformation.purchaseForCustomerId])

    useEffect(() => {
        async function loadComponent() {
            onLoadingCallback()
            await getContactsCallback()
            await getCustomerCallback()
            onCompletedCallback()
        }

        loadComponent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getContactsCallback, getCustomerCallback, onCompletedCallback, onLoadingCallback])

    const handleResetContext = () => {
        checkoutContext.handleCartInformation({
            quickCartShow: false,
            technicianViewType: TechnicianViewType.None,
            purchaseForCustomerId: '',
            purchaseForCustomerName: '',
        } as ICartInformation)
        checkoutContext.setQuickCartInfo({
            id: '',
            name: '',
        })
        searchPartContext.reset()
        checkoutContext.reset()
        checkoutContext.quickCartReset()
    }

    const handleDelete = () => {
        confirm({
            modalTitle: 'Delete cart?',
            contentText: 'This action cannot be undone. Are you sure you want to proceed?',
            primaryButtonText: 'Yes',
            secondaryButtonText: 'No',
            buttonOrder: 'primarySecondary',
            func: async (successCallback, errCallback) => {
                try {
                    onLoadingCallback()
                    await OrderService.delete(checkoutContext.cartInformation.orderId)
                    successCallback()
                    handleResetContext()
                    navigate('/orders')
                } catch (error: any) {
                    errCallback(error)
                } finally {
                    onCompletedCallback()
                }
            },
        })
    }
    const handleCartTitleEdit = () => {
        try {
            onLoadingCallback()
            //checkoutContext.handleSavedCartName(savedCartName)
            setSaveModalInfo({ modalVisible: false })
        } catch (error: any) {
            onErrorCallback(error)
        } finally {
            onCompletedCallback()
        }
    }
    const handleCreateSavedCart = async () => {
        try {
            onLoadingCallback()
            if (checkoutContext.savedCartInformation.id) {
                const data = checkoutContext.createCheckoutPayload(auth.id, CheckoutType.UpdateCart) as CreateOrderOrQuoteInput
                await OrderService.updateSave(checkoutContext.savedCartInformation.id, data)
            } else {
                const saveData = checkoutContext.createCheckoutPayload(auth.id, CheckoutType.DraftCart) as CreateOrderOrQuoteInput
                await OrderService.save(saveData)
            }
            onSuccessCallback(CartActionTypes.SAVE_CART)
        } catch (error: any) {
            onErrorCallback(error)
        } finally {
            onCompletedCallback()
        }
    }

    const renderCartActions = () => {
        return (
            <div className="cart-action-container">
                <div
                    onClick={() => {
                        setSaveModalInfo({ modalVisible: true, saveType: SaveType.CreateCart })
                    }}
                    className="action-button"
                >
                    <PopoverItem popoverContent="Save and Exit Cart">
                        <FontAwesomeIcon icon={faSave} />
                    </PopoverItem>
                </div>
                {checkoutContext.savedCartInformation.id !== '' && (
                    <div onClick={handleDelete} className="action-button">
                        <PopoverItem popoverContent="Delete Cart">
                            <FontAwesomeIcon icon={faTrash} />
                        </PopoverItem>
                    </div>
                )}
            </div>
        )
    }

    const renderCartTitleActions = () => {
        return (
            <div className="cart-title-action-container">
                <span>{checkoutContext.savedCartInformation.name}</span>
                <Tooltip title="Edit cart name">
                    <div onClick={() => setSaveModalInfo({ modalVisible: true, saveType: SaveType.EditTitle })} className="edit-button">
                        <FontAwesomeIcon icon={faPencil} />
                    </div>
                </Tooltip>
            </div>
        )
    }

    return (
        <PageWrapper className="checkout-page">
            {loading && <Loading />}
            <PurchaseTitle disabled={auth.company.type === CompanyType.Customer} title={checkoutContext.cartInformation.purchaseForCustomerName}>
                <div className="checkout-page-container">
                    <div className="left-box">
                        <ContentBox
                            title={auth.company.type !== CompanyType.Customer && renderCartTitleActions()}
                            subTitle={auth.company.type !== CompanyType.Customer && checkoutContext.cartInformation.purchaseForCustomerName}
                            headerRight={auth.company.type !== CompanyType.Customer && renderCartActions()}
                        >
                            <Order
                                manufacturers={undefined}
                                customer={undefined}
                                customerEquipmentCount={equipmentCount}
                                customerEquipmentList={equipmentList}
                                customerId={checkoutContext.cartInformation.purchaseForCustomerId}
                                onCompleted={onCompletedCallback}
                                onLoading={onLoadingCallback}
                            />
                        </ContentBox>
                    </div>
                    <div className="right-box">
                        <OrderRequirements contacts={contacts} onLoading={onLoadingCallback} onCompleted={onCompletedCallback} onSuccess={onSuccessCallback as any} onError={onErrorCallback} />
                    </div>
                </div>
            </PurchaseTitle>
            {saveModalInfo.modalVisible && (
                <Modal
                    visible={saveModalInfo.modalVisible}
                    title={saveModalInfo.saveType === SaveType.EditTitle ? 'Edit cart name?' : 'Save and exit cart'}
                    primaryButtonText={saveModalInfo.saveType === SaveType.EditTitle ? 'Save' : 'Save and Exit'}
                    onClickPrimary={() => {
                        setTitle(checkoutContext.savedCartInformation.name)
                        saveModalInfo.saveType === SaveType.EditTitle ? handleCartTitleEdit() : handleCreateSavedCart()
                    }}
                    secondaryButtonText="Cancel"
                    onClickSecondary={() => {
                        checkoutContext.setSavedCartInfo({
                            name: title,
                        } as ISavedCartInformation)
                        setSaveModalInfo({ modalVisible: false, saveType: SaveType.EditTitle })
                    }}
                    buttonOrder="secondaryPrimary"
                >
                    <div className="modal-line">
                        <Input
                            value={checkoutContext.savedCartInformation.name}
                            onChange={(e) =>
                                checkoutContext.setSavedCartInfo({
                                    name: e.target.value,
                                } as ISavedCartInformation)
                            }
                            placeholder="NAME"
                        />
                    </div>
                </Modal>
            )}
        </PageWrapper>
    )
}

export default CheckoutPage
