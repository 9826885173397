import { useCallback, useEffect, useState } from 'react'
import Input from '../../../../../components/Input'
import Modal from '../../../../../components/Modal'
import { isValidEmail } from '../../../../../utils/helpers'
import { CustomerDetailModel, UpdateCompanyCustomer } from '../../../../../models/New/Customer'
import './CompanyModal.scss'
import { ContractActionTypes } from '../..'
import CompanyService from '../../../../../services/UiService/Company'
import Icons, { IconType } from '../../../../../Icon'
import { MaxCharacter } from '../../../../../models/enums'
import UserService from '../../../../../services/UiService/User'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import BasicDropdown from '../../../../../components/Dropdown/BasicDropdown'

interface Props {
    company?: CustomerDetailModel
    visible: boolean
    closeModal: () => void
    onLoading: () => void
    onCompleted: () => void
    onSuccess: (action: ContractActionTypes, data?: any) => void
}
interface UserModel {
    fullName: string
    id: string
}

function CompanyModal(props: Props) {
    const { onLoading, onCompleted } = props
    const [companyName, setCompanyName] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [webSite, setWebSite] = useState<string>('')
    const [streetAddress1, setStreetAddress1] = useState<string>('')
    const [streetAddress2, setStreetAddress2] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [zipCode, setZipCode] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [accountManagerId, setAccountManagerId] = useState<string>('')
    const [users, setUsers] = useState<UserModel[]>([])
    const navigate = useNavigate()

    const getUsersCallback = useCallback(async () => {
        try {
            const userService = new UserService()
            const usersResponse = await userService.getCompanyUsers()
            setUsers(
                usersResponse.map((item) => {
                    return { fullName: item.firstName + ' ' + item.lastName, id: item.id }
                })
            )
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [])

    useEffect(() => {
        async function loadComponent() {
            await getUsersCallback()
        }
        loadComponent()
    }, [getUsersCallback])

    useEffect(() => {
        if (props.company) {
            setCompanyName(props.company?.name)
            setPhone(props.company?.phone)
            setEmail(props.company?.email)
            setWebSite(props.company.website)
            setStreetAddress1(props.company?.streetAddress1)
            setStreetAddress2(props.company?.streetAddress2)
            setCity(props.company?.city)
            setState(props.company.state)
            setZipCode(props.company?.zipCode)
            setCountry(props.company.country)
            setAccountManagerId(props.company.accountManager?.id || '')
        }
    }, [props.company])

    const isValidForm = () => {
        if (props.company) {
            return (
                (props.company.name !== companyName ||
                    props.company.phone !== phone ||
                    props.company.email !== email ||
                    props.company.website !== webSite ||
                    props.company.streetAddress1 !== streetAddress1 ||
                    props.company.streetAddress2 !== streetAddress2 ||
                    props.company.city !== city ||
                    props.company.state !== state ||
                    props.company.zipCode !== zipCode ||
                    props.company.accountManager?.id !== accountManagerId ||
                    props.company.country !== country) &&
                companyName.length > 0 &&
                email.length > 0 &&
                isValidEmail(email)
            )
        } else {
            return companyName.length > 0 && email.length > 0 && isValidEmail(email)
        }
    }

    const clearForm = () => {
        setAccountManagerId('')
        setCompanyName('')
        setEmail('')
        setPhone('')
        setWebSite('')
        setStreetAddress1('')
        setStreetAddress2('')
        setCity('')
        setState('')
        setZipCode('')
        setCountry('')
    }

    async function updateCompany() {
        const companyService = new CompanyService()
        try {
            onLoading()
            if (props.company) {
                const newData: UpdateCompanyCustomer = {
                    name: companyName,
                    email: email,
                    phone: phone,
                    website: webSite,
                    streetAddress1: streetAddress1,
                    streetAddress2: streetAddress2,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    country: country,
                    accountManagerId: accountManagerId || undefined,
                }
                const response = await companyService.updateCustomer(newData, props.company.id)
                props.onSuccess(ContractActionTypes.UPDATE_COMPANY_CUSTOMER, response)
            } else {
                const newData: UpdateCompanyCustomer = {
                    name: companyName,
                    email: email,
                    phone: phone,
                    website: webSite,
                    streetAddress1: streetAddress1,
                    streetAddress2: streetAddress2,
                    city: city,
                    state: state,
                    zipCode: zipCode,
                    country: country,
                    accountManagerId: accountManagerId || undefined,
                }
                const response = await companyService.createCustomer(newData)
                toast.success('Action completed successfully!')
                navigate(`/customer/${response.id}`)
                // props.onSuccess(ActionTypes.UPDATE_COMPANY_CUSTOMER)
            }
        } catch (error: any) {
        } finally {
            props.closeModal()
            clearForm()
            onCompleted()
        }
    }
    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }
    return (
        <>
            <Modal
                visible={props?.visible}
                title={props.company ? 'Edit Customer Details' : 'Add New Customer'}
                className="contact-modal"
                primaryButtonText={props.company ? 'Save' : 'Create'}
                onClickPrimary={updateCompany}
                primaryDisabled={!isValidForm()}
                secondaryButtonText="Cancel"
                onClickSecondary={onSecondary}
                buttonOrder="secondaryPrimary"
            >
                <span>Company Details</span>
                <div className="modal-line">
                    <Input value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder="COMPANY NAME" required max={MaxCharacter.companyName} />
                </div>
                <div className="modal-line">
                    <Input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="PHONE" max={MaxCharacter.phone} />
                </div>
                <div className="modal-line">
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="EMAIL" required max={MaxCharacter.email} />
                </div>
                <div className="modal-line">
                    <Input value={webSite} onChange={(e) => setWebSite(e.target.value)} placeholder="WEBSITE" />
                </div>
                <span>Company Address</span>
                <div className="modal-line">
                    <Input value={streetAddress1} onChange={(e) => setStreetAddress1(e.target.value)} placeholder="STREET ADDRESS 1" max={MaxCharacter.address} />
                </div>
                <div className="modal-line">
                    <Input value={streetAddress2} onChange={(e) => setStreetAddress2(e.target.value)} placeholder="STREET ADDRESS 2" max={MaxCharacter.address} />
                </div>
                <div className="modal-line">
                    <Input max={MaxCharacter.subAddress} value={city} onChange={(e) => setCity(e.target.value)} placeholder="CITY" />
                    <Input max={MaxCharacter.subAddress} value={state} onChange={(e) => setState(e.target.value)} placeholder="STATE" />
                </div>
                <div className="modal-line">
                    <Input max={MaxCharacter.zipCode} value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="ZIP CODE" />
                    <Input max={MaxCharacter.subAddress} value={country} onChange={(e) => setCountry(e.target.value)} placeholder="COUNTRY" />
                </div>
                <span>Account Management</span>
                {users.length > 0 && (
                    <div className="modal-line">
                        <BasicDropdown
                            label="ASSIGNEE"
                            menuItems={users.map((user) => ({
                                id: user.id,
                                text: user.fullName,
                            }))}
                            onChange={(data) => {
                                setAccountManagerId(data.id)
                            }}
                            selectedItem={{
                                id: accountManagerId,
                                text: users.find((item) => item.id === accountManagerId)?.fullName || '',
                            }}
                        />
                    </div>
                )}
                {!props.company && (
                    <label>
                        <Icons type={IconType.Info} /> You will be able to add contacts later.
                    </label>
                )}
            </Modal>
        </>
    )
}

export default CompanyModal
