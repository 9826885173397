import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Icons, { IconType } from '../../../../../Icon'
import { arraySort, calculateTotalPrice, priceFormatter, standardDate } from '../../../../../utils/helpers'
import { OrderModel } from '../../../../../models/New/Order'
import PopoverItem from '../../../../../components/PopoverItem'
import Button from '../../../../../components/Button'
import NoDataInfo from '../../../../../components/NoDataInfo'
import TablePagination from '@mui/material/TablePagination/TablePagination'
import { useEffect, useState } from 'react'

export interface ActionButtons {
    icon: IconType
    popoverContent: string
    className?: string
    onClick: (data: OrderModel) => void
    type: 'button' | 'link'
}
interface HeadCell {
    id: string
    label: string
}

interface Props {
    actionButtons?: ActionButtons[]
    data: OrderModel[]
    handleEditShippingModal?: () => void
    setModalInfo?: (e: any) => void
    onLoading?: () => void
    onCompleted?: () => void
    onClick?: (id: any) => void
}

const columns: HeadCell[] = [
    {
        id: 'savedAs',
        label: 'Title',
    },
    {
        id: 'userName',
        label: 'Customer Name',
    },
    {
        id: 'by',
        label: 'By',
    },
    {
        id: 'creationDate',
        label: 'Date Created',
    },
    {
        id: 'price',
        label: 'Value',
    },
]

interface SortingMenuType {
    active: boolean
    direction: string
    children: string
    onclick: () => void
    disable: boolean
}

function SortingMenu(props: SortingMenuType) {
    return !props.disable ? (
        <div className="sorting-menu">{props.children}</div>
    ) : (
        <div onClick={() => props.onclick()} className="sorting-menu">
            {props.children}
            {props.disable && <Icons className={`sorting-button ${props.active && props.direction}`} type={IconType.UpArrow} />}
        </div>
    )
}

interface TableHeadType {
    actionButtons?: ActionButtons[]
    orderBy: string
    setOrderBy: (id: string) => void
    direction: boolean
    setDirection: (direction: boolean) => void
}

function EnhancedTableHead(tableProps: TableHeadType) {
    return (
        <TableHead className="m-table-head">
            <TableRow>
                {columns.map((headCell) => (
                    <TableCell key={headCell.id} align="left" padding={'normal'}>
                        <SortingMenu
                            disable={headCell.id !== 'buttons' && true}
                            children={headCell.label}
                            onclick={() => {
                                if (tableProps.orderBy === headCell.id) {
                                    tableProps.setDirection(!tableProps.direction)
                                } else {
                                    tableProps.setOrderBy(headCell.id)
                                }
                            }}
                            active={tableProps.orderBy === headCell.id}
                            direction={tableProps.direction ? 'asc' : 'dsc'}
                        />
                    </TableCell>
                ))}
                {tableProps.actionButtons && <TableCell key="action" align="left" padding={'normal'}></TableCell>}
            </TableRow>
        </TableHead>
    )
}

function Row(props: {
    onClick?: (id: any) => void
    actionButtons?: ActionButtons[]
    handleEditShippingModal?: () => void
    setModalInfo?: (e: any) => void
    onLoading?: () => void
    onCompleted?: () => void
    index: number
    data: OrderModel
}) {
    const orderModel = props.data
    const index = props.index
    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} style={{ cursor: 'pointer' }} key={index}>
                <TableCell
                    onClick={() => {
                        props.onClick && props.onClick(orderModel)
                    }}
                    style={{ width: '20%' }}
                    align="left"
                >
                    {orderModel.savedAs}
                </TableCell>
                <TableCell
                    onClick={() => {
                        props.onClick && props.onClick(orderModel)
                    }}
                    style={{ width: '20%' }}
                    align="left"
                >
                    {orderModel.customer || 'No customer information'}
                </TableCell>
                <TableCell
                    onClick={() => {
                        props.onClick && props.onClick(orderModel)
                    }}
                    style={{ width: '12%' }}
                    align="left"
                >
                    {orderModel.userName}
                </TableCell>
                <TableCell
                    onClick={() => {
                        props.onClick && props.onClick(orderModel)
                    }}
                    style={{ width: '12%' }}
                    align="left"
                >
                    {standardDate(orderModel.creationDate)}
                </TableCell>
                <TableCell
                    onClick={() => {
                        props.onClick && props.onClick(orderModel)
                    }}
                    style={{ width: '15%' }}
                    align="left"
                >
                    {priceFormatter(orderModel.price!)}
                </TableCell>
                {props.actionButtons && (
                    <TableCell style={{ width: '5%' }} align="left">
                        <div className="action-menu">
                            {props.actionButtons.map((button, index) => {
                                return (
                                    <PopoverItem disabled={button.type !== 'button' && !orderModel.poAttachment} key={index} popoverContent={button.popoverContent}>
                                        {button.type === 'button' ? (
                                            <Button
                                                className={`action-button ${button?.className}`}
                                                onClick={() => {
                                                    button.onClick(orderModel)
                                                }}
                                            >
                                                <Icons type={button.icon} />
                                            </Button>
                                        ) : (
                                            <a rel="noreferrer" target="_blank" href={orderModel.poAttachment}>
                                                <Button
                                                    disabled={!orderModel.poAttachment}
                                                    className={`action-button ${button?.className}`}
                                                    onClick={() => {
                                                        button.onClick(orderModel)
                                                    }}
                                                >
                                                    <Icons type={button.icon} />
                                                </Button>
                                            </a>
                                        )}
                                    </PopoverItem>
                                )
                            })}
                        </div>
                    </TableCell>
                )}
            </TableRow>
        </>
    )
}

export default function OrderListTable(props: Props) {
    const [orderBy, setOrderBy] = React.useState('name')
    const [direction, setDirection] = React.useState(true)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const [sortedData, setSortedData] = useState<OrderModel[]>([])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    useEffect(() => {
        const newData = props.data.map((order) => ({
            ...order,
            price: calculateTotalPrice(order),
        }))
        setSortedData(arraySort(newData, orderBy, direction ? 'ascending' : 'descending'))
    }, [direction, orderBy, props.data])

    if (props.data.length === 0) return <NoDataInfo />
    return (
        <>
            <TableContainer className="monittor-table-container" style={{ overflowX: 'initial' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
                    <EnhancedTableHead actionButtons={props.actionButtons} orderBy={orderBy} setOrderBy={setOrderBy} direction={direction} setDirection={setDirection} />

                    <TableBody>
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orderModel: OrderModel, index) => {
                            return (
                                <Row
                                    onClick={props.onClick}
                                    actionButtons={props.actionButtons}
                                    onLoading={props.onLoading}
                                    onCompleted={props.onCompleted}
                                    setModalInfo={props.setModalInfo}
                                    handleEditShippingModal={props.handleEditShippingModal}
                                    index={index}
                                    data={orderModel}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}
