import { Tooltip } from '@mui/material'
import { FC, Fragment, useEffect, useState } from 'react'
import Icons, { IconType } from '../../Icon'
import Input from '../Input'
import ChecklistItem, { ChecklistItemType } from './ChecklistItem'
import './style.scss'

interface Props {
    list: ChecklistItemType[]
    onChange: (list: ChecklistItemType[]) => void
    divideCompletedOnes?: boolean
    hideAddButton?: boolean
    editItemWithoutButton?: boolean
    showAddInputAlways?: boolean
    disabled?: boolean
}

interface ChecklistItemWithId extends ChecklistItemType {
    id: string
}

const Checklist: FC<Props> = ({ disabled, list, onChange, divideCompletedOnes = false, hideAddButton = false, editItemWithoutButton = false, showAddInputAlways = false }) => {
    const [listItems, setListItems] = useState<ChecklistItemWithId[]>([])
    const [editingListItemId, setEditingListItemId] = useState('')
    const [addNew, setAddNew] = useState('')

    useEffect(() => {
        setListItems(list.map((child, index) => ({ text: child.text, checked: child.checked, id: `${index}-${child.text}` })))
    }, [list])

    useEffect(() => {
        setEditingListItemId(listItems?.find((child) => !child.text)?.id ?? '')
    }, [listItems])

    const onChangeListItems = (id: string, item: ChecklistItemType, entered: boolean) => {
        let existItems = listItems
        const index = listItems.findIndex((child) => child.id === id)
        if (index !== -1) {
            const isCreationModeAndEntered = !existItems[index].text && entered
            existItems[index] = { ...existItems[index], ...item }
            if (isCreationModeAndEntered && !showAddInputAlways) {
                existItems = [...existItems, { text: '', checked: false, id: '' }]
            }
            onChange(existItems.map((child) => ({ text: child.text, checked: child.checked })))
        }
    }

    const onClickAddNew = () => {
        if (listItems.every((child) => child.text !== '')) {
            onChange([...list, { text: '', checked: false }])
        }
    }

    const onDelete = (id: string) => {
        onChange(listItems.filter((child) => child.id !== id).map((child) => ({ text: child.text, checked: child.checked })))
    }

    const onChangeEditMode = (editMode: boolean, id: string) => {
        if (listItems.every((child) => child.text !== '')) {
            if (editMode) {
                setEditingListItemId(id)
            } else {
                setEditingListItemId('')
            }
        }
    }

    const onAddNew = () => {
        if (addNew) {
            let existItems = listItems
            existItems = [...existItems, { text: addNew, checked: false, id: '' }]
            onChange(existItems.map((child) => ({ text: child.text, checked: child.checked })))
            setAddNew('')
        }
    }

    return (
        <div className="checklistContainer">
            {divideCompletedOnes ? (
                <Fragment>
                    <div className="unCompletedOnes">
                        {listItems
                            ?.filter((child) => !child.checked)
                            ?.map((child, index) => (
                                <ChecklistItem
                                    onChangeEditMode={(editMode) => onChangeEditMode(editMode, child.id)}
                                    onEditMode={editingListItemId === child.id}
                                    key={index}
                                    onClickTrashIcon={() => onDelete(child.id)}
                                    item={child}
                                    onChange={(args, entered) => onChangeListItems(child.id, args, entered)}
                                    editItemWithoutButton={editItemWithoutButton}
                                />
                            ))}
                        {!hideAddButton && (
                            <div className="addNewListItem" onClick={onClickAddNew}>
                                <Icons type={IconType.Plus} color="#5F9074" />
                                add
                            </div>
                        )}
                        {showAddInputAlways && (
                            <div className="add-new-todo-section">
                                <Input
                                    outlinePlaceholder={!addNew}
                                    value={addNew}
                                    onChange={(e) => setAddNew(e.target.value)}
                                    placeholder="Add new"
                                    className={`add-new-todo ${addNew ? 'filled-input' : 'empty-input'}`}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onAddNew()
                                        }
                                    }}
                                    disabled={!!editingListItemId}
                                />
                                {addNew && !editingListItemId && (
                                    <div className="add-new-todo-buttons">
                                        <Tooltip title="Save" placement="left">
                                            <span>
                                                <Icons type={IconType.Check} onClick={onAddNew} />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Cancel" placement="left">
                                            <span>
                                                <Icons type={IconType.Close} color="gray" onClick={() => setAddNew('')} />
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {listItems?.some((child) => child.checked) && (
                        <div className="completedOnes">
                            <div className="completedTaskTitle">Completed Tasks</div>
                            {listItems
                                ?.filter((child) => child.checked)
                                ?.map((child, index) => (
                                    <ChecklistItem
                                        onChangeEditMode={(editMode) => onChangeEditMode(editMode, child.id)}
                                        onEditMode={editingListItemId === child.id}
                                        key={index}
                                        onClickTrashIcon={() => onDelete(child.id)}
                                        item={child}
                                        onChange={(args, entered) => onChangeListItems(child.id, args, entered)}
                                        editItemWithoutButton={editItemWithoutButton}
                                    />
                                ))}
                        </div>
                    )}
                </Fragment>
            ) : (
                <div className="unCompletedOnes">
                    {listItems?.map((child, index) => (
                        <ChecklistItem
                            onChangeEditMode={(editMode) => onChangeEditMode(editMode, child.id)}
                            onEditMode={editingListItemId === child.id}
                            key={index}
                            onClickTrashIcon={() => onDelete(child.id)}
                            item={child}
                            onChange={(args, entered) => onChangeListItems(child.id, args, entered)}
                            editItemWithoutButton={editItemWithoutButton}
                        />
                    ))}
                    {!hideAddButton && (
                        <div className="addNewListItem" onClick={onClickAddNew}>
                            <Icons type={IconType.Plus} color="#5F9074" />
                            add
                        </div>
                    )}
                    {showAddInputAlways && (
                        <div className="add-new-todo-section">
                            <Input
                                outlinePlaceholder={!addNew}
                                value={addNew}
                                onChange={(e) => setAddNew(e.target.value)}
                                placeholder="Add new"
                                className={`add-new-todo ${addNew ? 'filled-input' : 'empty-input'}`}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onAddNew()
                                    }
                                }}
                                disabled={!!editingListItemId}
                            />
                            {addNew && !editingListItemId && (
                                <div className="add-new-todo-buttons">
                                    <Tooltip title="Save" placement="left">
                                        <span>
                                            <Icons type={IconType.Check} onClick={onAddNew} />
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Cancel" placement="left">
                                        <span>
                                            <Icons type={IconType.Close} color="gray" onClick={() => setAddNew('')} />
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Checklist
