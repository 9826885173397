import { Tooltip } from '@mui/material'
import { FC, Fragment, useState } from 'react'
import DropdownWithQuantity, { DropdownWithQuantityValueModel } from '../../../../../../components/DropdownWithQuantity'
import Icons, { IconType } from '../../../../../../Icon'
import { ProductBasicDto } from '../../../../../../models/Ui/Product'
import { formatQuantity } from '../../../../../../utils/helpers'
import './style.scss'

interface Props {
    productList: ProductBasicDto[]
    value: DropdownWithQuantityValueModel[]
    onChange: (val: DropdownWithQuantityValueModel[]) => void
    showAddButton?: boolean
}

const ActivityProducts: FC<Props> = ({ productList, value, onChange, showAddButton = true }) => {
    const [editingProduct, setEditingProduct] = useState<DropdownWithQuantityValueModel>({ id: '', quantity: 0 })

    const setProductInfo = (productId: string | number) => productList.find((child) => child.id === productId)

    const onClickDelete = (productId: string | number) => onChange(value.filter((child) => child.id !== productId))

    const onClickSave = (id: string | number) => {
        let existValue = value
        existValue = existValue.filter((child) => child.id !== id)
        existValue = [editingProduct, ...existValue]
        onChange(existValue)
        setEditingProduct({ id: '', quantity: 0 })
    }

    return (
        <div className="activity-product-container">
            <DropdownWithQuantity
                placeholder="SELECT PRODUCTS TO LINK"
                options={productList?.map((child) => ({ id: child.id, label: child.name, subText: child.partNumber })) ?? []}
                value={value}
                onChange={(val) => {
                    onChange(val)
                }}
                selectionDescription="products"
            />
            <div className="product-list-container">
                <div className="product-list">
                    {value.map((child) => (
                        <Fragment>
                            {editingProduct.id !== child.id ? (
                                <div className={`product-list-item ${(value.length === 1 || !showAddButton) && 'full-width'}`} key={child.id}>
                                    <Tooltip title="Update quantity">
                                        <div className="quantity" onClick={() => setEditingProduct(child)}>
                                            {child.quantity}
                                        </div>
                                    </Tooltip>
                                    <div className="list-item-content">
                                        <Tooltip title={setProductInfo(child.id)?.name ?? ''}>
                                            <b>{setProductInfo(child.id)?.name}</b>
                                        </Tooltip>
                                        <div className="model-part-number">{setProductInfo(child.id)?.partNumber}</div>
                                    </div>
                                    <div className="close-section">
                                        <Tooltip title="Unlink" placement="left">
                                            <span onClick={() => onClickDelete(child.id)}>
                                                <Icons type={IconType.Close} />
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            ) : (
                                <div className={`product-list-item full-width editing-item`} key={child.id}>
                                    <div className="quantity">
                                        <input
                                            type="number"
                                            value={editingProduct.quantity}
                                            onBlur={() => {
                                                if (!editingProduct.quantity || editingProduct.quantity < 1) {
                                                    setEditingProduct((prevState) => ({ ...prevState, quantity: 1 }))
                                                } else {
                                                    setEditingProduct((prevState) => ({ ...prevState, quantity: Math.ceil(prevState.quantity) }))
                                                }
                                            }}
                                            onChange={(e) => setEditingProduct((prevState) => ({ ...prevState, quantity: formatQuantity(e.target.value) }))}
                                        />
                                    </div>
                                    <div className="list-item-content">
                                        <b>{setProductInfo(child.id)?.name}</b>
                                        <div className="model-part-number">{setProductInfo(child.id)?.partNumber}</div>
                                    </div>
                                    <div className="editing-icon-section">
                                        <Tooltip title="Save" placement="left">
                                            <span onClick={() => onClickSave(child.id)}>
                                                <Icons type={IconType.Check} />
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Cancel" placement="left">
                                            <span onClick={() => setEditingProduct({ id: '', quantity: 0 })}>
                                                <Icons type={IconType.Close} />
                                            </span>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ActivityProducts
