import { FC } from 'react'
import Icons, { IconType } from '../../../../../../Icon'
import { ActivityStatusType } from '../../../../../../models/Ui/Activity'
import './style.scss'

interface Props {
    isAddNewActivityModal?: boolean
    value: ActivityStatusType | null
    onChange: (value: ActivityStatusType | null) => void
}

const ActivityStatus: FC<Props> = ({ value, onChange, isAddNewActivityModal }) => {
    return (
        <div className="activity-status-container">
            <div onClick={() => onChange(ActivityStatusType.Open)} className={`activity-status open-status ${value === ActivityStatusType.Open && 'active'}`}>
                Open
            </div>
            <div onClick={() => onChange(ActivityStatusType.InProgress)} className={`activity-status in-progress ${value === ActivityStatusType.InProgress && 'active'}`}>
                In Progress
            </div>
            <div onClick={() => onChange(ActivityStatusType.OnHold)} className={`activity-status on-hold ${value === ActivityStatusType.OnHold && 'active'}`}>
                On Hold
            </div>
            <div onClick={() => onChange(ActivityStatusType.Closed)} className={`activity-status closed ${value === ActivityStatusType.Closed && 'active'}`}>
                {isAddNewActivityModal ? 'Closed' : 'Mark as Closed'}
                <Icons type={IconType.Lock} />
            </div>
        </div>
    )
}

export default ActivityStatus
