import { useState } from 'react'
import Input from '../../../components/Input'
import Modal from '../../../components/Modal'
import { WarrantyModel } from '../../../models/Ui/Equipment'
import './EquipmentList.scss'
import dayjs from 'dayjs'
import TokenService from '../../../services/NewServices/TokenService'
import { CreateEquipmentModel } from '../../../models/New/Equipment'
import TextArea from './../../../components/TextArea/index'
import BasicDropdown from '../../../components/Dropdown/BasicDropdown'

interface Props {
    visible: boolean
    createEquipment: (equipment: CreateEquipmentModel) => void
    closeModal: () => void
}

function CreateEquipmentModal(props: Props) {
    const [equipmentName, setEquipmentName] = useState('')
    const [partNumber, setPartNumber] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [warranty, setWarranty] = useState<WarrantyModel>({} as WarrantyModel)
    const [brand, setBrand] = useState<string>('')
    const [selectedUnit, setSelectedUnit] = useState({ id: '', text: '' })

    const warrantyDurationUnitList = [
        {
            id: 1,
            text: 'Week(s)',
        },
        {
            id: 2,
            text: 'Month(s)',
        },
        {
            id: 3,
            text: 'Year(s)',
        },
    ]

    const isValidForm = () => {
        // if(!!selectedUnit.id || warranty.duration > 0)
        return equipmentName.length > 3 && partNumber.length !== 0 && (!!selectedUnit.id || warranty.duration > 0 ? !!selectedUnit.id && warranty.duration > 0 : true)
    }

    const clearForm = () => {
        setPartNumber('')
        setEquipmentName('')
        setDescription('')
        setWarranty({} as WarrantyModel)
        setSelectedUnit({ id: '', text: '' })
    }

    const clickPrimary = () => {
        const auth = TokenService.getAuth()

        props.createEquipment({
            description: description,
            name: equipmentName,
            partNumber: partNumber,
            brand,
            warrantyDuration: warranty?.duration,
            warrantyDurationUnit: warranty?.unit,
            isTemplate: true,
        })

        //@ts-ignore
        window.dataLayer.push({
            event: 'new equipment added',
            equipment: `${equipmentName}-${partNumber}`,
            user: `${auth.company.type}-${auth.company.name}`,
            date: dayjs().format('MM/DD/YYYY HH:mm:ss'),
        })

        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    return (
        <Modal
            className="create-equipment-modal"
            visible={props.visible}
            title="Add new equipment"
            primaryButtonText="Add equipment"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
        >
            <div className="modal-line">
                <Input value={partNumber} onChange={(e) => setPartNumber(e.target.value)} placeholder="PART NUMBER" required />
            </div>
            <div className="modal-line">
                <Input value={equipmentName} onChange={(e) => setEquipmentName(e.target.value)} placeholder="EQUIPMENT NAME" required />
            </div>
            <div className="modal-line">
                <Input value={brand} onChange={(e) => setBrand(e.target.value)} placeholder="BRAND" />
            </div>
            <span className="modal-line-title">Warranty</span>
            <div className="modal-line">
                <Input
                    required={!!selectedUnit.id || warranty.duration > 0}
                    value={warranty?.duration?.toString() || ''}
                    type={'number'}
                    onChange={(e) => setWarranty({ ...warranty, duration: Number.parseInt(e.target.value) })}
                    placeholder="STANDARD WARRANTY DURATION"
                    min={0}
                />
                <BasicDropdown
                    isClearButton
                    required={!!selectedUnit.id || warranty.duration > 0}
                    label="UNIT"
                    menuItems={warrantyDurationUnitList}
                    onChange={(data) => {
                        setSelectedUnit(data)
                        setWarranty({ ...warranty, unit: data.text })
                    }}
                    selectedItem={selectedUnit}
                    isClearLabel="Clear"
                    handleClearInput={() => setSelectedUnit({ id: '', text: '' })}
                />
            </div>
            <div className="modal-line">
                <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" lineCount={2} />
            </div>
        </Modal>
    )
}

export default CreateEquipmentModal
