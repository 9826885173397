import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '../Input'
import './SearchDropdown.scss'

interface IClickableListItem {
  id: string
  text: string
  onClick: (id: string) => void
}
interface Props {
  onSearchTextChange: (searchText: string) => void
  items: IClickableListItem[]
}

function SearchDropdown(props: React.PropsWithChildren<Props>) {
  const [searchText, setSearchText] = useState<string>('')
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  useEffect(() => {
    if (searchText) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }, [searchText])

  return (
    <div className="search-dropdown">
      <div className="search-dropdown-input-container">
        <Input
          value={searchText}
          onFocus={() => {
            if (searchText.length > 1) {
              setShowDropdown(true)
            } /* else not needed */
          }}
          //onBlur={() => setShowDropdown(false)}
          onChange={(e) => {
            setSearchText(e.target.value)
            props.onSearchTextChange(e.target.value)
          }}
          placeholder="Search for customer"
        />
      </div>
      <div className="search-dropdown-transparent-container">
        <div className="search-dropdown-content" style={{ display: showDropdown ? "block" : "none" }}        >
          {props.items.map((data, index) => {
            return (
              <Link
                key={index}
                to="#"
                onClick={() => {
                  setSearchText('')
                  data.onClick(data.id)
                  setShowDropdown(false)
                }}
              >
                {data.text}
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SearchDropdown
