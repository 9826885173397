import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import Button from '../../../components/Button'
import ContentBox from '../../../components/ContentBox'
import EasyIcon from '../../../components/EasyIcon'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import dayjs from 'dayjs'
import Icons, { IconType } from '../../../Icon'
import { ManufacturersCustomerModel, ManufacturerCustomerContact } from '../../../models/Ui/Manufacturer'
import CustomerList from './CustomerList'
import SearchInput from '../../../components/SearchInput'
import CustomerListTable from './CustomerListTable'
import TokenService from '../../../services/NewServices/TokenService'
import CompanyService from '../../../services/UiService/Company'
import CompanyModal from '../CustomerDetail/Modals/CompanyUpdateModal/CompanyModal'
import { CustomerContactModel } from '../../../models/New/Customer'
import CustomerService from '../../../services/UiService/Customer'
import Tab from '../../../components/Tab'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import ContactListTable from './ContactListTable'
import NoData from '../../../components/NoData'
import NoDataInfo from '../../../components/NoDataInfo'
import PopoverItem from '../../../components/PopoverItem'

const tabItems: TabItem[] = [
    { id: Tabs.Company, name: 'Companies', customIcon: <Icons type={IconType.Company} /> },
    { id: Tabs.Customer, name: 'Contacts', customIcon: <Icons type={IconType.Phone} /> },
]

function CustomerListPage() {
    const [customers, setCustomers] = useState<ManufacturersCustomerModel[]>([])
    const [contacts, setContacts] = useState<ManufacturerCustomerContact[]>([])
    const [filteredCustomers, setFilteredCustomers] = useState<ManufacturersCustomerModel[]>([])
    const [filteredContacts, setFilteredContacts] = useState<ManufacturerCustomerContact[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [modalVisible, setModalVisible] = useState<boolean>(false)
    const [selectedView, setSelectedView] = useState<boolean>(false)
    const [searchFoundItem, setSearchFoundItem] = useState<CustomerContactModel[]>([])
    const [activeTab, setActiveTab] = useState<number>(Tabs.Company)

    const companyService = new CompanyService()

    const customerService = new CustomerService()

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Customers',
        },
    ]

    useEffect(() => {
        if (activeTab === Tabs.Company) {
            getCustomers()
        } else getContacts()
        // getContacts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    async function getCustomers() {
        setLoading(true)
        try {
            const customers = await customerService.getCustomersForManufacturer({ page: 0, size: 9999, search: '' })
            setCustomers(customers.content as ManufacturersCustomerModel[])
            setFilteredCustomers(customers.content as ManufacturersCustomerModel[])
        } catch (e: any) {
            toast.error(e)
        } finally {
            setLoading(false)
        }
    }

    async function getContacts() {
        setLoading(true)
        try {
            const contacts = await customerService.getContacts()
            setContacts(contacts as ManufacturerCustomerContact[])
        } catch (error: any) {
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    const sendReminder = async (customer: ManufacturersCustomerModel) => {
        setLoading(true)
        companyService
            .remindeCustomerInvite(customer.id)
            .then(() => {
                setLoading(false)
                toast.success('Reminder sent successfully!')
            })
            .catch((e) => {
                setLoading(false)
                toast.error(e)
            })
    }

    const inviteCustomer = async (customer: ManufacturersCustomerModel) => {
        setLoading(true)
        companyService
            .inviteCustomer(customer.id)
            .then(() => {
                setLoading(false)
                getCustomers()
                toast.success('Invite successfully sent!')
            })
            .catch((e) => {
                setLoading(false)
                toast.error(e)
            })
    }

    const invokeShared = async (customer: ManufacturersCustomerModel) => {
        setLoading(true)
        await companyService.removeAccess(customer.id)
        await getCustomers()
        setLoading(false)
    }

    const openModal = () => {
        setModalVisible(true)
        const auth = TokenService.getAuth()

        // @ts-ignore
        window.dataLayer.push({
            event: 'new customer modal open',
            user: `${auth.company.id} ${auth.company.type} ${auth.company.name} ${auth.company.email} `,
            date: dayjs().format('MM/DD/YYYY HH:mm:ss'),
        })
    }

    const onHandleSearch = (value: any[]) => {
        if (activeTab === Tabs.Company) {
            setFilteredCustomers(value as ManufacturersCustomerModel[])
        } else {
            setFilteredContacts(value)
        }
    }

    const onTabClick = (id: number) => setActiveTab(id)
    const searchKey = {
        company: ['email', 'name', 'status', 'contacts.fullName', 'contacts.lastName', 'contacts.email'],
        contact: ['firstName', 'lastName', 'company.name', 'jobTitle', 'phone', 'email'],
    }

    if (customers.length === 0) {
        return (
            <>
                {loading && <Loading />}
                <NoData
                    pageTitle="Customers"
                    title="You currently do not have any customers on Monittor.  Start by adding one now!"
                    secondTitle="Note: Adding a customer will not automatically invite them on Monittor. You can do this later."
                    handleClick={() => setModalVisible(true)}
                />
                <CompanyModal
                    visible={modalVisible}
                    closeModal={() => {
                        setModalVisible(false)
                    }}
                    onSuccess={() => {
                        getCustomers()
                    }}
                    onLoading={() => setLoading(true)}
                    onCompleted={() => setLoading(false)}
                />
            </>
        )
    }

    return (
        <PageWrapper breadcrumb={breadcrumb}>
            <ContentBox
                className="customer-page"
                title="Customers"
                headerIcon={<EasyIcon icon={IconType.Customers} />}
                headerRight={
                    <div className="customer-header-right">
                        <SearchInput
                            foundItem={(data) => setSearchFoundItem(data)}
                            data={activeTab === Tabs.Company ? customers : contacts}
                            handleSearch={onHandleSearch}
                            searchKeys={activeTab === Tabs.Company ? searchKey.company : searchKey.contact}
                        />
                        <PopoverItem popoverContent={selectedView ? 'List View' : 'Card View'}>
                            <Button className="toggle-button" onClick={() => setSelectedView(!selectedView)} disabled={activeTab === Tabs.Customer}>
                                {!selectedView ? <Icons type={IconType.Menus} /> : <Icons type={IconType.CardView} />}
                            </Button>
                        </PopoverItem>
                        <PopoverItem popoverContent="Add new">
                            <Button className="customer-cta" onClick={openModal}>
                                <Icons type={IconType.PlusCircle} />
                            </Button>
                        </PopoverItem>
                    </div>
                }
            >
                <Tab activeTab={activeTab} tabs={tabItems} onTabClick={onTabClick} />
                {activeTab === Tabs.Company && (
                    <>
                        {loading && <Loading />}
                        {filteredCustomers.length > 0 ? (
                            selectedView ? (
                                <CustomerList customers={filteredCustomers} sendReminder={sendReminder} inviteCustomer={inviteCustomer} unlinkCustomer={invokeShared} />
                            ) : (
                                <CustomerListTable
                                    sendReminder={sendReminder}
                                    inviteCustomer={inviteCustomer}
                                    unlinkCustomer={invokeShared}
                                    navigate={'/customer/'}
                                    sortKey="name"
                                    data={filteredCustomers.map((p) => {
                                        return {
                                            name: p.name,
                                            searchFoundItem: searchFoundItem.find((e) => e.companyId === p.id),
                                            city: p.city,
                                            state: p.state,
                                            country: p.country,
                                            zipCode: p.zipCode,
                                            status: p.status,
                                            equipmentCount: p.equipmentCount || 0,
                                            id: p.id,
                                            customer: p,
                                            contacts: p.contacts,
                                        }
                                    })}
                                />
                            )
                        ) : (
                            <NoDataInfo />
                        )}
                    </>
                )}
                {activeTab === Tabs.Customer && (
                    <>
                        <ContactListTable
                            sortKey="firstName"
                            data={(filteredContacts || []).map((data) => ({
                                firstName: data.firstName,
                                lastName: data.lastName,
                                companyName: data.company.name,
                                jobTitle: data.jobTitle,
                                phone: data.phone,
                                email: data.email,
                                isPrimary: data.isPrimary,
                            }))}
                        />
                    </>
                )}
            </ContentBox>
            <CompanyModal
                visible={modalVisible}
                closeModal={() => {
                    setModalVisible(false)
                }}
                onSuccess={() => {
                    getCustomers()
                }}
                onLoading={() => setLoading(true)}
                onCompleted={() => setLoading(false)}
            />
        </PageWrapper>
    )
}

export default CustomerListPage
