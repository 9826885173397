import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Tab from '../../../components/Tab'
import ThreeDotButton from '../../../components/ThreeDotButton'
import Icons, { IconType } from '../../../Icon'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import EquipmentService from '../../../services/UiService/Equipment'
import DetailsTab from './Tabs/DetailsTab/DetailsTab'
import './EquipmentDetails.scss'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import { CustomerLinkToCustomer, EquipmentCustomerTableModel, EquipmentModel, NewUpdateEquipmentModel, TreeViewModel } from '../../../models/New/Equipment'
import { TagModel } from '../../../models/New/Tag'
import DrawingPartsTab from './Tabs/DrawingPartsTab/DrawingPartsTab'
import DocumentsTab from './Tabs/DocumentsTab/DocumentsTab'
import LinkDocumentModal from '../modals/LinkDocument'
import LinkCustomersModal from './Modals/Equipment/LinkCustomersModal'
import { CustomerSummaryModel } from '../../../models/Ui/Customer'

import CustomersTab from './Tabs/CustomerTab/CustomersTab'
import Button, { ButtonType } from '../../../components/Button'
import PopoverItem from '../../../components/PopoverItem'
import InstallationsTab, { InstallationModel } from './Tabs/InstallationsTab/InstallationsTab'
import CustomerService from '../../../services/UiService/Customer'
import { NodeParentModel } from '../../../components/TreeView/Node'
import EditEquipmentModal from './Modals/Equipment/EditEquipmentModal'
import CreateAssemblyModal from './Modals/Assembly/CreateAssemblyModal'
import EditAssemblyModal from './Modals/Assembly/EditAssemblyModal'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import { useNavigate } from 'react-router'
import PushEquipmentToCustomModal from './Modals/Equipment/PushEquipmentToCustomModal'
import TreeViewButton from '../../../components/NewTreeView/TreeViewButton/TreeViewButton'
import TreeView from '../../../components/NewTreeView/TreeView'
import EquipmentTitle from '../../../components/EquipmentDetailPageTitle'
import SearchInput from '../../../components/SearchInput'
import { CheckoutContext } from '../../../store/NewCheckoutContext/CheckoutContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import EquipmentCreateDocumentModal from '../../../components/EquipmentCreateDocumentModal'
import { CommonActionTypes, EquipmentActionTypes } from '../../../models/Ui/Common'
import UploadDrawingPartsModal from './Modals/Equipment/UploadDrawingPartsModal'

const tabItems: TabItem[] = [
    {
        id: Tabs.DrawingAndParts,
        name: 'Drawing and Parts',
        customIcon: <Icons type={IconType.Parts} />,
    },
    {
        id: Tabs.Details,
        name: 'Details',
        customIcon: <Icons type={IconType.Info} />,
    },
    {
        id: Tabs.Documents,
        name: 'Documents',
        customIcon: <Icons type={IconType.Folder} />,
    },
    {
        id: Tabs.Customers,
        name: 'Customers',
        customIcon: <Icons type={IconType.Customers} />,
    },
    {
        id: Tabs.Installations,
        name: 'Installations',
        customIcon: <Icons type={IconType.Equipment} />,
    },
]

// interface EquipmentCustomerModel {
//   equipment: CustomEquipmentModel
//   customer: ManufacturersCustomerModel
// }

interface ParentModel {
    id: string
    text: string
}

function EquipmentDetailForManufacturer() {
    let { equipmentId, assemblyId } = useParams()
    let [searchParams, setSearchParams] = useSearchParams()
    const activeTabIndex = parseInt(searchParams.get('action') ? searchParams.get('action')!.toString() : '0')
    const { confirm } = useContext(ConfirmationContext)
    const checkoutContext = useContext(CheckoutContext)
    const navigate = useNavigate()

    const noteRef = useRef<string>()
    const parentRef = useRef<ParentModel>()
    const [assembly] = useState<EquipmentModel>()
    const [loading, setLoading] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(tabItems[activeTabIndex].id)
    const [equipment, setEquipment] = useState<EquipmentModel>()
    const [customInstallations, setCustomInstallations] = useState<InstallationModel[]>([])
    const [customers, setCustomers] = useState<CustomerSummaryModel[] | null>(null)
    const [linkCustomersModalVisible, setLinkCustomersModalVisible] = useState<boolean>(false)
    const [linkDocumentModalVisible, setLinkDocumentModalVisible] = useState<boolean>(false)
    const [editEquipmentDrawingPartsModalVisible, setEditEquipmentDrawingPartsModalVisible] = useState<boolean>(false)
    const [editEquipmentModalVisible, setEditEquipmentModalVisible] = useState<boolean>(false)
    const [editAssemblyModalVisible, setEditAssemblyModalVisible] = useState<boolean>(false)
    const [createAssemblyModalVisible, setCreateAssemblyModalVisible] = useState<boolean>(false)
    const [pushEquipmentToCustomModalVisible, setPushEquipmentToCustomModalVisible] = useState<boolean>(false)
    const [editAssemblyDrawingPartsModalVisible, setEditAssemblyDrawingPartsModalVisible] = useState<boolean>(false)
    const [treeViewVisible, setTreeViewVisible] = useState<boolean>(false)
    const [customersData, setCustomersData] = useState<EquipmentCustomerTableModel[]>([])
    const [filteredCustomers, setFilteredCustomers] = useState<EquipmentCustomerTableModel[]>([])
    const [createDocumentModalVisible, setCreateDocumentModalVisible] = useState<boolean>(false)
    const [isDrawingTabHortizalRow, setIsDrawingTabHortizalRow] = useState<boolean>(false)
    const [isLinkedTemplate, setIsLinkedTemplate] = useState<boolean>(true)
    const [selectedEquipmentTreeInfo, setSelectedEquipmentTreeInfo] = useState<TreeViewModel>({} as TreeViewModel)
    const [parentList, setParentList] = useState<ParentModel[]>([])
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(searchParams?.get('selectedImageIndex') ? Number(searchParams?.get('selectedImageIndex')) : 0)
    let parentModels: ParentModel[] = []

    const onClickPreviousImage = () => {
        if (selectedImageIndex !== 0 && selectedImageIndex > 0) {
            setSelectedImageIndex((prevState) => prevState - 1)
            searchParams.set('selectedImageIndex', (selectedImageIndex - 1).toString())
            setSearchParams(searchParams)
        }
    }

    const onClickNextImage = () => {
        if (!equipment) return
        if (equipment.drawings.length - 1 !== selectedImageIndex && selectedImageIndex < equipment.drawings.length - 1) {
            setSelectedImageIndex((prevState) => prevState + 1)
            searchParams.set('selectedImageIndex', (selectedImageIndex + 1).toString())
            setSearchParams(searchParams)
        }
    }

    const getBreadcrumbTabName = () => {
        switch (activeTab) {
            case Tabs.DrawingAndParts:
                return 'Drawing and Parts'
            case Tabs.Documents:
                return 'Documents'
            case Tabs.Customers:
                return 'Customers'
            case Tabs.Details:
                return 'Details'
            case Tabs.Installations:
                return 'Installations'
            default:
                return ''
        }
    }

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Equipment',
            url: '/equipments',
        },
        {
            name: equipment?.name!,
            url: '/equipment/' + equipment?.id,
        },
        {
            name: getBreadcrumbTabName(),
        },
    ]

    const rightMenuActionListEquipment = [
        {
            text: 'Edit',
            onClick: () => setEditEquipmentModalVisible(true),
        },
        {
            text: 'Push to custom equipment',
            onClick: () => setPushEquipmentToCustomModalVisible(true),
        },
        {
            text: 'Link customers',
            onClick: async () => {
                if (!customers) {
                    await getCustomers()
                }
                setLinkCustomersModalVisible(true)
            },
        },
        // {
        //     text: 'Add sub-assembly',
        //     onClick: () => setCreateAssemblyModalVisible(true),
        // },
        {
            text: 'Delete',
            onClick: (id: string, text: string, parent: NodeParentModel) => {
                confirm({
                    modalClassName: 'delete-equipment-modal',
                    modalTitle: 'Delete equipment?',
                    primaryButtonText: 'Delete',
                    func: async (successCallback, errCallback) => {
                        try {
                            onLoadingCallback()
                            await EquipmentService.delete(equipment!.id)
                            successCallback()
                            navigate(-1)
                        } catch (error: any) {
                            errCallback(error)
                            onErrorCallback(error)
                        } finally {
                            onCompletedCallback()
                        }
                    },
                    contentNode: (
                        <Fragment>
                            {!equipment?.parentId ? (
                                <div className="modal-line">Are you sure you would like to proceed?</div>
                            ) : (
                                <div className="delete-modal-content">
                                    <span>You are attempting to delete an equipment record that is linked as a sub-assembly of other equipment.</span>
                                    <span>
                                        <b>Deleting this record will also remove it as a sub-assembly from all other records.</b>
                                    </span>
                                    <span>This cannot be undone and will affect customer records that are already in use.</span>
                                </div>
                            )}
                        </Fragment>
                    ),
                })
            },
        },
    ]

    const rightMenuActionList: any[] = rightMenuActionListEquipment

    const treeviewHeaderActionButtons = [
        {
            icon: IconType.BoldPlus,
            popoverContent: 'Add sub-assembly',
            onClick: () => setCreateAssemblyModalVisible(true),
        },
        {
            icon: IconType.Close,
            popoverContent: 'Hide sub-assemblies',
            onClick: () => setTreeViewVisible(false),
        },
    ]

    async function getCustomers() {
        try {
            setLoading(true)
            const customerService = new CustomerService()
            const customers = await customerService.getCustomersForManufacturer({ page: 0, size: 9999 })
            setCustomers(customers.content)
            setLoading(false)
        } catch (error: any) {
            console.error(error)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const getEquipmentCallback = useCallback(async () => {
        try {
            const equipmentResponse = await EquipmentService.getParentEquipmentTreeView(equipmentId!, assemblyId ?? equipmentId!)
            noteRef.current = equipmentResponse.notes
            setEquipment(equipmentResponse)
            if (equipmentResponse) {
                setCustomersData(
                    equipmentResponse.customers.map((item) => ({
                        id: item.customerid,
                        name: item.customername,
                        installationCount: item.installcount,
                        email: item.email,
                    }))
                )
            }
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [assemblyId, equipmentId])

    const getCustomEquipmentCallback = useCallback(async () => {
        try {
            const customEquipmentForTemplate = await EquipmentService.getCustomsForTemplate(assemblyId ?? equipmentId!).then((data) =>
                data.map((equipment) => {
                    return {
                        id: equipment.id,
                        customerName: equipment.customerName,
                        equipmentName: equipment.name,
                        installationAddress: equipment.installationAddress,
                        serialNumber: equipment.serialNo,
                        isSubAssembly: false,
                        isVisible: equipment.isVisible,
                    } as InstallationModel
                })
            )
            const templateLinkedAsAssembly = await EquipmentService.getTemplateLinkedAsAssembly(assemblyId ?? equipmentId!).then((data) =>
                data.map((equipment: EquipmentModel | any) => {
                    return {
                        id: equipment.id,
                        customerName: equipment.customer?.name,
                        equipmentName: equipment.name,
                        installationAddress: equipment.address,
                        serialNumber: equipment.serialNo,
                        // topLevelEquipmentName: 'top level',
                        isSubAssembly: true,
                        isVisible: equipment.isVisible,
                    } as InstallationModel
                })
            )

            setCustomInstallations(customEquipmentForTemplate.concat(templateLinkedAsAssembly))
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [assemblyId, equipmentId])

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])

    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])

    const onSuccessCallback = useCallback(
        async (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel | EquipmentModel | TreeViewModel) => {
            switch (action) {
                case EquipmentActionTypes.UPDATE_EQUIPMENT:
                    if (equipment && data) {
                        delete (data as any).id // Added to prevent wrong equipment id bug!
                        setEquipment({ ...equipment, ...(data as EquipmentModel) })
                    } /* else not needed */
                    break
                case EquipmentActionTypes.UPDATE_EQUIPMENT_TREE:
                    if (equipment && data) {
                        delete (data as any).id // Added to prevent wrong equipment id bug!
                        setEquipment({ ...equipment, tree: data as TreeViewModel })
                    } /* else not needed */
                    break
                case EquipmentActionTypes.CREATE_EQUIPMENT_TAG:
                    if (equipment && data) {
                        setEquipment((prevState: any) => ({
                            ...prevState,
                            tags: equipment.tags.concat([data as TagModel]),
                        }))
                    } /* else not needed */
                    break
                case EquipmentActionTypes.DELETE_EQUIPMENT_TAG:
                    if (equipment && data) {
                        delete (data as any).id // Added to prevent wrong equipment id bug!
                        const newData = equipment.tags.filter((item: TagModel) => item.name !== data.name)
                        setEquipment((prevState: any) => ({
                            ...prevState,
                            tags: newData,
                        }))
                    } /* else not needed */
                    break
                case EquipmentActionTypes.UPDATE_EQUIPMENT_IMG:
                    setLoading(true)
                    await getEquipmentCallback()
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_EQUIPMENT_DOCUMENT:
                    setLoading(true)
                    await getEquipmentCallback()
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_ASSEMBLY:
                    await getEquipmentCallback()
                    break

                case EquipmentActionTypes.UPDATE:
                    await getEquipmentCallback()
                    break
                case EquipmentActionTypes.LINK_DOCUMENTS:
                    setLoading(true)
                    // if (assembly) {
                    //   await getAssemblyCallback()
                    // } else {
                    await getEquipmentCallback()
                    // }
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_CUSTOM_EQUIPMENT:
                    await getCustomEquipmentCallback()
                    break

                case EquipmentActionTypes.DELETE_EQUIPMENT:
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_MAPPING:
                    await getEquipmentCallback()
                    break
                case EquipmentActionTypes.UPDATE_PARTSLIST:
                    await getEquipmentCallback()
                    break
            }
        },
        [equipment, getCustomEquipmentCallback, getEquipmentCallback]
    )

    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    useEffect(() => {
        async function loadComponent() {
            setLoading(true)
            await getEquipmentCallback()
            await getCustomEquipmentCallback()
            setLoading(false)
        }

        loadComponent()
        setActiveTab(tabItems[0].id)
    }, [assemblyId, getCustomEquipmentCallback, getEquipmentCallback])

    useEffect(() => {
        if (equipment) {
            const selectEquipmentId = assemblyId ?? equipmentId
            const parentList = purgeAll(equipment.tree)
            setParentList(parentList)
            setIsLinkedTemplate(!parentList.find((parent) => parent.id === selectEquipmentId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipment, assemblyId, equipmentId])

    const purgeAll = (object: TreeViewModel, isParentATemplate?: boolean) => {
        if ((object.isTemplate || isParentATemplate) && object.level !== 0) {
            parentModels.push({
                text: object.name,
                id: object.id,
            })
        }

        if (object.children && (object.isTemplate || isParentATemplate) && object.level !== 0) {
            for (const child of object.children) {
                purgeAll(child, (isParentATemplate && !object.isTemplate) || object.id === equipmentId)
            }
        }
        return parentModels
    }

    const linkDocumentHandler = async (selectedIds: string[]) => {
        setLoading(true)
        await EquipmentService.linkDocument(assemblyId ?? equipmentId!, selectedIds)
        await getEquipmentCallback()
        setLinkDocumentModalVisible(false)
        setLoading(false)
    }

    const linkCustomerHandler = async (param: CustomerLinkToCustomer[]) => {
        setLoading(true)
        await EquipmentService.linkCustomer({ templateId: assemblyId ?? equipmentId!, customerLinkToCustomer: param })
        await getEquipmentCallback()
        await getCustomEquipmentCallback()
        setLinkDocumentModalVisible(false)
        setLoading(false)
    }

    const treeViewHandleClick = () => {
        if (equipment?.tree.children?.length) {
            setTreeViewVisible(!treeViewVisible)
        } else {
            setCreateAssemblyModalVisible(true)
        }
    }

    const onCustomersHandleSearch = (data: EquipmentCustomerTableModel[]) => {
        setFilteredCustomers(data)
    }

    const afterDeletingDrawing = () => {
        setSelectedImageIndex(0)
        searchParams.set('selectedImageIndex', '0')
        setSearchParams(searchParams)
    }

    return (
        <PageWrapper className="new-manufacturer-equipment-detail-page" breadcrumb={breadcrumb}>
            {loading && <Loading />}
            {equipment && (
                <ContentBox
                    treeViewMenu={<TreeViewButton isCreate={!equipment?.tree.children?.length} visible={treeViewVisible} onClickButton={treeViewHandleClick} />}
                    title={<EquipmentTitle equipment={equipment} />}
                    subTitle={
                        <div>
                            <span className="equipment-type">Template</span>
                            <span className="part-number">
                                Part Number: <b>{equipment?.partNumber}</b>
                            </span>
                        </div>
                    }
                >
                    {
                        <div className="detail-page-container">
                            <div className={`tree-view-box ${checkoutContext.cartInformation.show && 'absolute'}`}>
                                <TreeView
                                    setSelectedItem={(data) => setSelectedEquipmentTreeInfo(data)}
                                    baseId={assemblyId!}
                                    rootBaseId={equipmentId!}
                                    visible={treeViewVisible}
                                    setVisible={() => setTreeViewVisible(false)}
                                    rootBaseURL={'/equipment/'}
                                    root={{ id: equipment.tree.id, name: equipment.tree.name }}
                                    baseURL={'/equipment/' + equipment.tree.id + '/assembly/'}
                                    nodes={equipment.tree.children}
                                    headerActionButtons={treeviewHeaderActionButtons}
                                    onLoading={onLoadingCallback}
                                    onCompleted={onCompletedCallback}
                                    onSuccessCallBack={() => {
                                        getEquipmentCallback()
                                    }}
                                />
                            </div>
                            <div className="detail-page">
                                <div className="tab-container">
                                    <Tab
                                        activeTab={activeTab}
                                        tabs={tabItems.filter((item) => (assemblyId ? item.id !== Tabs.Customers && item.id !== Tabs.Installations : item))}
                                        onTabClick={(id: number) => {
                                            setActiveTab(id)
                                        }}
                                    />

                                    <div style={{ display: 'flex', gap: '0.3rem' }}>
                                        {activeTab === Tabs.DrawingAndParts && (
                                            <>
                                                <Button
                                                    type={isDrawingTabHortizalRow ? ButtonType.Quaternary : ButtonType.Secondary}
                                                    className={`toggle-button ${!isDrawingTabHortizalRow && 'disabled'}`}
                                                    onClick={() => setIsDrawingTabHortizalRow(!isDrawingTabHortizalRow)}
                                                >
                                                    <PopoverItem key="update" popoverContent="Vertical split view">
                                                        <Icons type={IconType.Menus} />
                                                    </PopoverItem>
                                                </Button>
                                                <Button
                                                    type={!isDrawingTabHortizalRow ? ButtonType.Quaternary : ButtonType.Secondary}
                                                    className={`toggle-button ${isDrawingTabHortizalRow && 'disabled'}`}
                                                    onClick={() => setIsDrawingTabHortizalRow(!isDrawingTabHortizalRow)}
                                                >
                                                    <PopoverItem key="update" popoverContent="Horizontal split view">
                                                        <Icons type={IconType.HortizalRow} />
                                                    </PopoverItem>
                                                </Button>

                                                <div className="divider" />

                                                <Button
                                                    disabled={isLinkedTemplate && selectedEquipmentTreeInfo.level !== 0}
                                                    className="upload-button"
                                                    onClick={() => setEditEquipmentDrawingPartsModalVisible(true)}
                                                >
                                                    <PopoverItem key="update" popoverContent="Update drawing and parts">
                                                        <Icons type={IconType.Upload} />
                                                    </PopoverItem>
                                                </Button>
                                            </>
                                        )}
                                        {assemblyId ? (
                                            <Button
                                                disabled={isLinkedTemplate && selectedEquipmentTreeInfo.level !== 0}
                                                className="edit-assembly-button"
                                                type={ButtonType.Secondary}
                                                onClick={() => setEditAssemblyModalVisible(!editAssemblyModalVisible)}
                                            >
                                                <FontAwesomeIcon icon={faPencil} />
                                            </Button>
                                        ) : (
                                            <PopoverItem popoverContent="Actions">
                                                <ThreeDotButton actions={rightMenuActionList} />
                                            </PopoverItem>
                                        )}
                                        {activeTab === Tabs.Customers && (
                                            <div className="activity-right-buttons">
                                                <SearchInput data={customersData} handleSearch={onCustomersHandleSearch} searchKeys={['name', 'email']} />
                                            </div>
                                        )}
                                        {activeTab === Tabs.Documents && (
                                            <Button className="create-document-button" onClick={() => setCreateDocumentModalVisible(true)}>
                                                <Icons type={IconType.PlusCircle} />
                                                <span>Add Document</span>
                                            </Button>
                                        )}
                                    </div>
                                </div>

                                {activeTab === Tabs.Details && (
                                    <DetailsTab
                                        isGoToTemplateButton={isLinkedTemplate}
                                        flexWrap={treeViewVisible}
                                        isRootEquipment={!assemblyId}
                                        equipment={equipment}
                                        installationCount={customInstallations?.length}
                                        // assembly={assembly}
                                        handleEdit={() => (assemblyId ? setEditAssemblyModalVisible(true) : setEditEquipmentModalVisible(true))}
                                        handleLinkCustomers={async () => {
                                            if (!customers) {
                                                await getCustomers()
                                            }
                                            setLinkCustomersModalVisible(true)
                                        }}
                                        onLoading={onLoadingCallback}
                                        onCompleted={onCompletedCallback}
                                        onSuccess={onSuccessCallback}
                                        onError={onErrorCallback}
                                    />
                                )}

                                {activeTab === Tabs.DrawingAndParts && (
                                    <DrawingPartsTab
                                        isHortizalRow={isDrawingTabHortizalRow}
                                        pushChangesButtonInvisible={!assemblyId || equipmentId === assemblyId}
                                        visible={activeTab === Tabs.DrawingAndParts}
                                        equipment={equipment}
                                        onLoading={onLoadingCallback}
                                        onCompleted={onCompletedCallback}
                                        onSuccess={onSuccessCallback}
                                        onError={onErrorCallback}
                                        selectedImageIndex={selectedImageIndex}
                                        onClickNextImage={onClickNextImage}
                                        onClickPreviousImage={onClickPreviousImage}
                                    />
                                )}

                                <DocumentsTab
                                    equipment={equipment}
                                    visible={activeTab === Tabs.Documents}
                                    onLoading={onLoadingCallback}
                                    onCompleted={onCompletedCallback}
                                    onSuccess={onSuccessCallback}
                                    onError={onErrorCallback}
                                />

                                {activeTab === Tabs.Customers && <CustomersTab customers={filteredCustomers} />}
                                {activeTab === Tabs.Installations && (
                                    <InstallationsTab
                                        installations={customInstallations}
                                        visible={activeTab === Tabs.Installations}
                                        onLoading={onLoadingCallback}
                                        onCompleted={onCompletedCallback}
                                        onSuccess={onSuccessCallback}
                                        onError={onErrorCallback}
                                    />
                                )}
                            </div>
                        </div>
                    }

                    {linkDocumentModalVisible && (
                        <LinkDocumentModal
                            equipment={equipment}
                            selectedIds={equipment.documents?.map((d: any) => d.id)}
                            visible={linkDocumentModalVisible}
                            close={() => setLinkDocumentModalVisible(false)}
                            onSave={linkDocumentHandler}
                        />
                    )}
                    {customers && (
                        <LinkCustomersModal
                            customers={customers!}
                            selectedCustomers={[]}
                            visible={linkCustomersModalVisible}
                            closeModal={() => setLinkCustomersModalVisible(false)}
                            onSave={linkCustomerHandler}
                        ></LinkCustomersModal>
                    )}
                    {editEquipmentModalVisible && (
                        <EditEquipmentModal
                            equipment={equipment}
                            parent={parentRef.current || ({} as ParentModel)}
                            visible={editEquipmentModalVisible}
                            closeModal={() => setEditEquipmentModalVisible(false)}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onSuccess={onSuccessCallback}
                            onError={onErrorCallback}
                        />
                    )}

                    {editEquipmentDrawingPartsModalVisible && (
                        <UploadDrawingPartsModal
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onSuccess={async (actionType) => {
                                if (actionType === CommonActionTypes.DELETE) {
                                    afterDeletingDrawing()
                                }
                                await getEquipmentCallback()
                            }}
                            onError={onErrorCallback}
                            equipment={equipment}
                            visible={editEquipmentDrawingPartsModalVisible}
                            closeModal={() => setEditEquipmentDrawingPartsModalVisible(false)}
                        />
                    )}

                    {createAssemblyModalVisible && (
                        <CreateAssemblyModal
                            parentList={parentList}
                            rootEquipmentId={equipmentId!}
                            equipment={equipment}
                            parent={parentRef.current || ({} as ParentModel)}
                            visible={createAssemblyModalVisible}
                            closeModal={() => setCreateAssemblyModalVisible(false)}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onSuccess={onSuccessCallback}
                            onError={onErrorCallback}
                        />
                    )}

                    {editAssemblyModalVisible && (
                        <EditAssemblyModal
                            isLinkedTemplate={isLinkedTemplate}
                            isRootEquipmentId={equipmentId!}
                            parentList={parentList}
                            equipment={equipment}
                            assemblyId={assemblyId!}
                            parentId={selectedEquipmentTreeInfo.parentId || ''}
                            visible={editAssemblyModalVisible}
                            closeModal={() => setEditAssemblyModalVisible(false)}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onSuccess={onSuccessCallback}
                            onError={onErrorCallback}
                        />
                    )}

                    {editAssemblyDrawingPartsModalVisible && assembly && (
                        <UploadDrawingPartsModal
                            equipment={assembly}
                            visible={editAssemblyDrawingPartsModalVisible}
                            closeModal={() => setEditAssemblyDrawingPartsModalVisible(false)}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onSuccess={async (actionType) => {
                                if (actionType === CommonActionTypes.DELETE) {
                                    afterDeletingDrawing()
                                }
                                await getEquipmentCallback()
                            }}
                            onError={onErrorCallback}
                        />
                    )}

                    {pushEquipmentToCustomModalVisible && (
                        <PushEquipmentToCustomModal
                            isMoleaerUser={equipment.manufacturerId === '140542f7-a3bc-40ca-879e-176a0a223b11'}
                            type="assembly"
                            warningTitle={
                                equipment.manufacturerId
                                    ? '(Warning: this will overwrite sub-assemblies, part-lists, parts, and documents of custom equipment and replace them to match this template. This cannot be undone).'
                                    : '(Warning: this will overwrite part-lists, parts, and documents of custom equipment and replace them to match this template.This cannot be undone).'
                            }
                            id={equipment.id}
                            visible={pushEquipmentToCustomModalVisible}
                            closeModal={() => setPushEquipmentToCustomModalVisible(false)}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onError={onErrorCallback}
                            onSuccess={() => getCustomEquipmentCallback()}
                        />
                    )}

                    {createDocumentModalVisible && (
                        <EquipmentCreateDocumentModal
                            id={assemblyId! ?? equipmentId}
                            visible={createDocumentModalVisible}
                            documents={equipment?.documents!}
                            closeModal={() => setCreateDocumentModalVisible(false)}
                            onLoading={onLoadingCallback}
                            onCompleted={onCompletedCallback}
                            onError={onErrorCallback}
                            onSuccess={() => getEquipmentCallback()}
                        />
                    )}

                    {/* {
            linkDocumentModalVisible &&
            <LinkDocumentModal
              equipment={equipment}
              assembly={assembly}
              documents={manufacturerDocuments}
              visible={linkDocumentModalVisible}
              closeModal={() => setLinkDocumentModalVisible(false)}
              onLoading={onLoadingCallback}
              onCompleted={onCompletedCallback}
              onSuccess={onSuccessCallback}
              onError={onErrorCallback}
            />
          } */}
                </ContentBox>
            )}
        </PageWrapper>
    )
}

export default EquipmentDetailForManufacturer
