import React, { useEffect, useRef, useState } from 'react'
import Box from '../../components/Box'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { MaxCharacter, Size } from '../../models/enums'

import { Link, useParams } from 'react-router-dom'
import AuthService from '../../services/UiService/Auth'
import './forgot-password.scss'
import { isValidEmail, isValidPassword } from '../../utils/helpers'
import ReCAPTCHA from 'react-google-recaptcha'
import ReCaptcha from './../../components/ReCAPTCHA/index'
import { toast } from 'react-toastify'

const ForgetPassword = {
    email: '',
    newPassword: '',
    newPasswordAgain: '',
}

function ForgotPassword() {
    let { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({ show: false, type: '', text: '' })
    const [formData, setFormData] = useState(ForgetPassword)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [reButton, setReButton] = useState(false)
    const reRef = useRef<ReCAPTCHA>()

    useEffect(() => {
        if (id) {
            if (isValidPassword(formData.newPassword) && formData.newPassword === formData.newPasswordAgain) {
                setButtonDisabled(false)
            } else {
                setButtonDisabled(true)
            }
        } else {
            if (isValidEmail(formData.email) && reButton) {
                setButtonDisabled(false)
            } else {
                setButtonDisabled(true)
            }
        }
    }, [formData, id, reButton])

    const changePassword = async () => {
        setLoading(true)

        if (id) {
            const response = await new AuthService().resetPassword({
                newPassword: formData.newPassword,
                validationKey: id,
            })
            if (response.status) {
                setMessage({ show: true, type: 'success', text: 'Congratulations! You are all set. Please login with your new password.' })
            } else {
                setMessage({ show: true, type: 'error', text: response.message })
            }
            setButtonDisabled(true)
        } else {
            await new AuthService()
                .forgotPassword(formData.email)
                .then(() => {
                    setMessage({
                        show: true,
                        type: 'success',
                        text: `We sent you instructions to reset your password. Please check your email.`,
                    })
                    //toast.success(`Almost done...  We just send you an email at ${formData.email}.Please go to your email and verify your account.`)
                })
                .catch((error) => {
                    setMessage({ show: true, type: 'error', text: error.response.data.message })
                    toast.error(error)
                })
                .finally(() => {
                    reRef.current?.reset()
                    setReButton(false)
                    setTimeout(() => {
                        setMessage({ show: false, type: '', text: '' })
                    }, 10000)
                })
        }
        setLoading(false)
    }

    function handleOnChange(e: React.ChangeEvent<HTMLInputElement>, type: string) {
        setFormData({ ...formData, [type]: e.target.value })
    }

    return (
        <div className="sign-up-page form-container">
            <div className="form-wrapper">
                <Box className="login-form">
                    <span className="brand-title">Forgot Password</span>
                    {!id && (
                        <>
                            <div className="description">Enter the email address associated with your account and we’ll send you a link to reset your password.</div>
                            <div className="group-label">User information</div>
                        </>
                    )}
                    {id ? (
                        <>
                            <Input type="password" value={formData.newPassword} onChange={(e) => handleOnChange(e, 'newPassword')} placeholder="NEW PASSWORD" size={Size.lg} />
                            <Input type="password" value={formData.newPasswordAgain} onChange={(e) => handleOnChange(e, 'newPasswordAgain')} placeholder="CONFIRM PASSWORD" size={Size.lg} />

                            <div className={'sign-up-password-hint'}>
                                <ul>
                                    <li className={'password-hint-item'}>At least 8 characters</li>
                                    <li className={'password-hint-item'}>A mixture of both uppercase and lowercase letters.</li>
                                    <li className={'password-hint-item'}>A mixture of letters and numbers.</li>
                                    <li className={'password-hint-item'}>Inclusion of at least one special character (!,@,#,?)</li>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <>
                            <Input type="email" value={formData.email} onChange={(e) => handleOnChange(e, 'email')} placeholder="EMAIL ADDRESS" max={MaxCharacter.email} size={Size.lg} />
                            <ReCaptcha referance={reRef} handleClick={() => setReButton(true)} />
                        </>
                    )}
                    {loading && <span className="loading-span" />}
                    <Button disabled={loading || buttonDisabled} onClick={changePassword}>
                        Change Password
                    </Button>
                    <div className="message-wrapper">
                        {message.show &&
                            (message.type === 'success' ? (
                                <div className="message success">{message.text || 'Success'}</div>
                            ) : (
                                message.type === 'error' && <div className="message error">{message.text || 'Error'}</div>
                            ))}
                    </div>

                    <div className="foot-note">
                        <Link to="/login">Sign in</Link>
                        <span>or</span>
                        <Link to="/sign-up">Sign up</Link>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default ForgotPassword
