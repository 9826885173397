import { BaseDto } from './Common'

export enum QueueItemProgress {
    Waiting = 'waiting',
    InProgress = 'inProgress',
    Done = 'done',
    Cancelled = 'cancelled',
    ErrorOccurred = 'errorOccurred',
}

export interface QueueDto extends BaseDto {
    id: string
    log: string
    groupKey: string
    provider: string
    progress: QueueItemProgress
}
