import Icon, { IconType } from '../../Icon'
import { AddressModel, OrderModel, snapShotModel } from '../../models/New/Order'
import { calculateTotalPrice, priceFormatter, standardDate } from '../../utils/helpers'
import './OrderCardList.scss'
import CopyButton from './../CopyButton/index'
import { CheckoutType } from '../../models/Ui/Equipment'
import Icons from '../../Icon'

interface OrderListExpandItemForManufacturersProps {
    shippingInfo?: boolean
    order: OrderModel
    handleEditShippingModal?: () => void
    setModalInfo?: (e: OrderModel) => void
    isQuote?: boolean
    forEmail?: boolean
}

interface OrderRowItemProps {
    itemList: snapShotModel
}

const WarrantyInfo = ({ snapShot }: { snapShot: snapShotModel }) => {
    if (snapShot.warranty_ends && new Date(snapShot.warranty_ends).getTime() > new Date().getTime()) {
        return <span className="warranty active">Active Warranty</span>
    }
    return null
}

const RequestedItemRow = (props: OrderRowItemProps) => {
    let { price, adjust_price } = props.itemList
    adjust_price = adjust_price || 0
    const priceCondition = price !== 0 && price !== adjust_price && price > adjust_price
    return (
        <div className="order-item">
            <div className="requested-item">
                <span className="header-name">{props.itemList.name}</span>
                {props.itemList.category ||
                    (props.itemList.brand && (
                        <div className="sub-row">
                            {props.itemList.category && (
                                <span className="title">
                                    Category: <span className="value">{props.itemList.category}</span>
                                </span>
                            )}
                            {props.itemList.brand && (
                                <>
                                    <span className="orange-circle"></span>
                                    <span className="title">
                                        Brand: <span className="value">{props.itemList.brand}</span>
                                    </span>
                                </>
                            )}
                        </div>
                    ))}

                {props.itemList.equipment_id ? (
                    <div className="sub-row">
                        {props.itemList.part_number && (
                            <>
                                <span className="title">
                                    Part Number: <span className="value">{props.itemList.part_number}</span>
                                </span>
                                <span className="orange-circle"></span>
                            </>
                        )}
                        {props.itemList.quantity && (
                            <span className="title">
                                Qty: <span className="value">{props.itemList.quantity}</span>
                            </span>
                        )}
                        {props.itemList.uom && (
                            <>
                                <span className="orange-circle"></span>
                                <span className="title">
                                    UOM: <span className="value">{props.itemList.uom}</span>
                                </span>
                            </>
                        )}
                        {props.itemList.pkg && (
                            <>
                                <span className="orange-circle"></span>
                                <span className="title">
                                    Pkg: <span className="value">{props.itemList.pkg}</span>
                                </span>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="sub-row">
                        {props.itemList.part_number && (
                            <>
                                <span className="title">
                                    Part Number: <span className="value">{props.itemList.part_number}</span>
                                </span>
                                <span className="orange-circle"></span>
                            </>
                        )}
                        {props.itemList.quantity && (
                            <>
                                <span className="title">
                                    Qty: <span className="value">{props.itemList.quantity}</span>
                                </span>
                            </>
                        )}
                        {props.itemList.serial_no && (
                            <>
                                <span className="title">
                                    Qty: <span className="value">{props.itemList.serial_no}</span>
                                </span>
                            </>
                        )}
                    </div>
                )}
                {props.itemList.equipment_id && (
                    <div className="sub-row">
                        {props.itemList.equipment_name && (
                            <span className="title">
                                Equipment:<span className="value">{props.itemList.equipment_name}</span>
                            </span>
                        )}
                        {props.itemList.serial_no && (
                            <span className="title">
                                Serial Number:<span className="value">{props.itemList.serial_no}</span>
                            </span>
                        )}
                        {props.itemList && (
                            <div className="line">
                                <WarrantyInfo snapShot={props.itemList} />
                            </div>
                        )}
                    </div>
                )}
                {props.itemList.notes && (
                    <div className="sub-row">
                        <>
                            <span className="title">
                                Notes: <span className="value">{props.itemList.notes}</span>
                            </span>
                        </>
                    </div>
                )}
                {props.itemList.attachments.length && (
                    <div className="sub-row">
                        <>
                            <span className="title">
                                Attachments:
                                {props.itemList.attachments.map((attachment) => (
                                    <a rel="noreferrer" target="_blank" href={attachment.fileUrl} className="attachment-value">
                                        {attachment.fileName} <Icons type={IconType.GoToPage} />
                                    </a>
                                ))}
                            </span>
                        </>
                    </div>
                )}
            </div>
            <div className="requested-item-price">
                <div className="isWarranty">
                    {priceCondition && <span className={price > adjust_price ? 'green crossed-out' : ''}>{priceFormatter(props.itemList.quantity * price)}</span>}
                    {price === 0 && adjust_price === 0 ? (
                        <span>-</span>
                    ) : (
                        <span className={price !== adjust_price && price > adjust_price ? 'green' : ''}>{priceFormatter(props.itemList.quantity * adjust_price)}</span>
                    )}
                </div>
                <div className="unit-price">
                    <span>Unit price: {priceFormatter(price && adjust_price && price !== adjust_price ? adjust_price : price)}</span>
                </div>
            </div>
        </div>
    )
}

function OrderListExpandItem(props: OrderListExpandItemForManufacturersProps) {
    function renderOrderAddress(title: string, address: string, notes: string) {
        const newAddress: AddressModel = JSON.parse(address)
        const isFieldsCheck = newAddress.site_address1 || newAddress.site_address2 || newAddress.city || newAddress.state || newAddress.country || newAddress.postal_code || notes
        if (isFieldsCheck) {
            return (
                <div className="content">
                    {isFieldsCheck && (
                        <div className="line">
                            <span className="title signika">{title}</span>
                        </div>
                    )}
                    <div className="line">
                        <span className="value">{newAddress?.site_address1 && newAddress?.site_address1}</span>
                    </div>
                    <div className="line">
                        <span className="value">{newAddress?.site_address2 && newAddress?.site_address2}</span>
                    </div>
                    <div className="line">
                        <span className="value">{newAddress?.city && newAddress?.city + ','}</span>
                        <span className="value">{newAddress?.state && newAddress?.state + ','}</span>
                        <span className="value">{newAddress?.country && newAddress?.country + ','}</span>
                        <span className="value">{newAddress?.postal_code && newAddress?.postal_code}</span>
                    </div>
                    {notes && (
                        <div className="notes-line">
                            <span className="title">Notes:</span>
                            <span className="value">{notes}</span>
                        </div>
                    )}
                </div>
            )
        }
        return null
    }

    const shippingAddress = props.order.shippingAddress ? JSON.parse(props.order.shippingAddress) : {}
    return (
        <div className="order-list-expand-item">
            <div className="order-list-expand-item-wrapper">
                <div className="order-list-expand-item-top">
                    <div>
                        <div className="header-line">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span className="title signika">{props.order.checkoutType === CheckoutType.Quote ? 'Quote created by' : 'Order created by'}</span>
                                {props.forEmail && (
                                    <div className="flex flex-gap-0-5">
                                        <span className="signika" style={{ fontWeight: 'bold' }}>
                                            Ref. no:
                                        </span>
                                        <span>{props.order.transactionNumber}</span>
                                    </div>
                                )}
                            </div>
                            <span className="value">{props.order.userName && props.order.userName}</span>
                            <span className="value">{props.order.userCompany && props.order.userCompany}</span>
                        </div>
                        <div className="address-line">
                            {props.order.checkoutType === CheckoutType.Quote ? (
                                <>{renderOrderAddress('Ship to', props.order.shippingAddress, props.order.shippingAddressNote)}</>
                            ) : (
                                <>
                                    {renderOrderAddress('Bill to', props.order.billingAddress, props.order.billingAddressNote)}
                                    {renderOrderAddress('Ship to', props.order.shippingAddress, props.order.shippingAddressNote)}
                                </>
                            )}
                            {(shippingAddress.contact_person || shippingAddress.phone_number) && (
                                <div className="content">
                                    <div className="line">
                                        <span className="title signika">Contact Person</span>
                                    </div>
                                    <span className="line">{shippingAddress.contact_person}</span>
                                    <span className="line">{shippingAddress.phone_number}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="order-list-expand-item-body">
                    <div className="header-title">
                        <span className="title">Requested Items</span>
                    </div>
                    <div className="body">
                        {props.order?.orderDetails?.map((e) => {
                            return JSON.parse(e.snapShot).map((p: snapShotModel, index: number) => {
                                if (p.part_id && p.part_id.length > 1) {
                                    return <RequestedItemRow key={index} itemList={p} />
                                } else {
                                    return <RequestedItemRow key={index} itemList={p} />
                                }
                            })
                        })}
                        {props.order.checkoutType !== CheckoutType.Quote && (
                            <div className="total-price">
                                <span className="title signika">Total price:</span>
                                <span className="value signika">{priceFormatter(calculateTotalPrice(props.order))}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="order-list-expand-item-footer">
                    {props.order.checkoutType !== CheckoutType.Quote && props.order.paymentMethod && props.order.poNumber && props.order.paymentNotes && (
                        <div className="item">
                            <span className="title signika">Payment</span>
                            <div className="item-body">
                                {props.order.paymentMethod && (
                                    <div className="line">
                                        <span className="line-title signika">Payment method</span>
                                        <span className="value">{props.order.paymentMethod.toLowerCase() === 'billtopo' ? 'Bill to PO' : props.order.paymentMethod}</span>
                                    </div>
                                )}
                                {props.order.poNumber && (
                                    <div className="line">
                                        <span className="line-title">Attachment</span>
                                        <a className="value attachment" rel="noreferrer" target="_blank" href={props.order.poAttachment}>
                                            {props.order.poNumber}
                                        </a>
                                    </div>
                                )}
                            </div>
                            {props.order.paymentNotes && (
                                <div className="notes-line">
                                    <span className="notes-title">Notes:</span>
                                    <span className="value">{props.order.paymentNotes}</span>
                                </div>
                            )}
                        </div>
                    )}
                    <div className="item">
                        {props.order.shippingType && (
                            <>
                                <span className="title signika">Shipping Method</span>
                                <div className="item-body">
                                    {props.order.shippingCost && props.order.checkoutType === CheckoutType.Quote && (
                                        <div className="line">
                                            <span className="line-title signika">Shipping Cost </span>
                                            <span className="value">{priceFormatter(props.order.shippingCost)}</span>
                                        </div>
                                    )}
                                    {props.order.shipVia && (
                                        <div className="line">
                                            <span className="line-title signika">Ship via</span>
                                            <span className="value">{props.order.shipVia}</span>
                                        </div>
                                    )}
                                    {props.order.shippingType && (
                                        <div className="line">
                                            <span className="line-title signika">Type</span>
                                            <span className="value">{props.order.shippingType}</span>
                                        </div>
                                    )}
                                    {props.order.dateNeeded && (
                                        <div className="line">
                                            <span className="line-title signika">Date Needed</span>
                                            <span className="value">{standardDate(props.order.dateNeeded)}</span>
                                        </div>
                                    )}
                                </div>
                                {props.order.shippingNotes && (
                                    <div className="notes-line">
                                        <span className="notes-title">Notes:</span>
                                        <span className="value">{props.order.shippingNotes}</span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {props.shippingInfo && (
                    <div className="order-list-expand-item-footer">
                        {(props.order.paymentMethod || props.order.poNumber) && (
                            <div className="item">
                                <span className="title">
                                    Shipping Information{' '}
                                    {props.setModalInfo && props.handleEditShippingModal && (
                                        <Icon
                                            className="edit-shipping-button"
                                            onClick={() => {
                                                if (props.setModalInfo && props.handleEditShippingModal) {
                                                    props.setModalInfo(props.order)
                                                    props.handleEditShippingModal()
                                                }
                                            }}
                                            type={IconType.Pen}
                                        />
                                    )}
                                </span>
                                <div className="item-body">
                                    {props.order.orderDetails[0].trackingNumber && (
                                        <div className="line">
                                            <span className="line-title">Tracking Number</span>
                                            <span className="value">
                                                {props.order.orderDetails[0].trackingNumber} <CopyButton value={props.order.orderDetails[0].trackingNumber} />{' '}
                                            </span>
                                        </div>
                                    )}
                                    {props.order.orderDetails[0].shipmentAttachmentName && (
                                        <div className="line">
                                            <span className="line-title">Attachments</span>
                                            <a className="value attachment" rel="noreferrer" target="_blank" href={props.order.orderDetails[0].shipmentAttachmentUrl}>
                                                {props.order.orderDetails[0].shipmentAttachmentName}
                                            </a>
                                        </div>
                                    )}
                                </div>
                                {props.order.orderDetails[0].shipmentNotes && (
                                    <div className="notes-line">
                                        <span className="notes-title">Notes:</span>
                                        <span className="value">{props.order.orderDetails[0].shipmentNotes}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderListExpandItem
