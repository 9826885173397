import React, { FC, useContext } from 'react'
import { GetPartDto } from '../../../../../models/Ui/Part'
import { SearchPartsTableColumnModel, SearchPartsTableColumns } from '../searchParts.types'
import './searchPartsTable.scss'
import SearchPartsTableBody from './SearchPartsTableBody'
import SearchPartsTableHead from './SearchPartsTableHead'
import { CheckoutContext } from '../../../../../store/NewCheckoutContext/CheckoutContext'
import NoDataInfo from '../../../../../components/NoDataInfo'

interface SearchPartsTableProps {
    data: GetPartDto[]
}

const SearchEquipmentsPartsTable: FC<SearchPartsTableProps> = ({ data }) => {
    const { cartInformation } = useContext(CheckoutContext)
    const customerEquipmentColumns: SearchPartsTableColumnModel[] = [
        {
            columnName: 'Part Name',
            width: '40%',
            columnType: SearchPartsTableColumns.PartName,
        },
        {
            columnName: 'Equipment',
            width: '40%',
            columnType: SearchPartsTableColumns.EquipmentName,
        },
        {
            columnName: 'Price',
            width: '10%',
            columnType: SearchPartsTableColumns.Price,
        },
        {
            columnName: '',
            width: '10%',
            columnType: SearchPartsTableColumns.Action,
        },
    ]
    const templateEqupmentColumns: SearchPartsTableColumnModel[] = [
        {
            columnName: 'Part Name',
            width: '45.32%',
            columnType: SearchPartsTableColumns.PartName,
        },
        {
            columnName: 'Part Number',
            width: '17.94%',
            columnType: SearchPartsTableColumns.PartNumber,
        },
        {
            columnName: 'Equipment',
            width: '17.94%',
            columnType: SearchPartsTableColumns.EquipmentName,
        },
        {
            columnName: 'Price',
            width: '6.95%',
            columnType: SearchPartsTableColumns.Price,
        },
        {
            columnName: '',
            width: '10.30%',
            columnType: SearchPartsTableColumns.Action,
        },
    ]
    const columns: SearchPartsTableColumnModel[] = cartInformation.purchaseForCustomerId ? customerEquipmentColumns : templateEqupmentColumns

    return (
        <div className="searchPartsTableContainer" style={{ overflowX: 'initial' }}>
            <SearchPartsTableHead columns={columns} />
            {data.length === 0 ? <NoDataInfo style={{ marginTop: '25px' }} /> : <SearchPartsTableBody columns={columns} data={data} />}
        </div>
    )
}

export default SearchEquipmentsPartsTable
