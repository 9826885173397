import React, { useEffect, useState } from 'react'
import NoDataInfo from '../NoDataInfo';
import ListCardItem, { ListCardItemData } from './ListCardItem';
import './style.scss'

interface Props {
  className?: string
  data: React.PropsWithChildren<ListCardItemData>[]
}

function ListCard(props: Props) {
  const [data, setData] = useState<React.PropsWithChildren<ListCardItemData>[]>([])

  useEffect(() => {
    setData(props.data)
  }, [props.data]);

  return (
    data.length > 0 ?
      <div className={`list-card-container ${props.className ? props.className : ''}`}>
        {
          data.map(
            (d: React.PropsWithChildren<ListCardItemData>, index: number) => {
              return <ListCardItem key={index} {...d} />
            }
          )
        }
      </div>
      :
      <NoDataInfo />
  )
}

export default ListCard
