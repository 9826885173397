import { FC } from 'react'
import { GetPartDto } from '../../../../../../models/Ui/Part'
import { SearchPartsTableColumnModel } from '../../searchParts.types'
import SearchPartsTableSubRow from './SeachPartsTableSubRow'
import './searchPartsTableBody.scss'

interface SearchPartsTableBodyProps {
    columns: SearchPartsTableColumnModel[]
    data: GetPartDto[]
}

const SearchPartsTableBody: FC<SearchPartsTableBodyProps> = ({ columns, data }) => {
    return (
        <div className="searchPartsTableBodyContainer">
            {data.map((child, index) => (
                <SearchPartsTableSubRow key={index} columns={columns} isLastOne={false} data={child} />
            ))}
        </div>
    )
}

export default SearchPartsTableBody
